import React from 'react';
import PropTypes from 'prop-types';
import { BLACK_BORDER_COLOR } from 'constants/index';

const IcPlayAudio = ({
  fillcolor,
  width,
  height,
}) => (
  <svg width={width} height={height} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M17.5411 36C17.0333 36 16.5254 35.9028 16.0496 35.7008C14.8006 35.1713 14 34.0285 14 32.7721V14.2242C14 12.9693 14.8006 11.825 16.0496 11.2955C17.2986 10.7749 18.7748 10.9499 19.8271 11.7592L31.8626 21.0331C32.6586 21.6464 33.1176 22.5483 33.1176 23.4997C33.1176 24.4495 32.6586 25.3514 31.8626 25.9632L19.8271 35.2371C19.1744 35.7397 18.3616 36 17.5411 36Z" fill={fillcolor} />
  </svg>
);
IcPlayAudio.propTypes = {
  fillcolor: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

IcPlayAudio.defaultProps = {
  fillcolor: BLACK_BORDER_COLOR,
  width: '24',
  height: '24',
};
export default IcPlayAudio;
