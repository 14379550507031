class ServerSideService {
  constructor() {
    this.lang = null;
    this.accessToken = null;
    this.loginSession = null;
  }

  setAccessToken(accessToken) {
    this.accessToken = accessToken;
  }

  getAccessToken() {
    return this.accessToken;
  }

  setLoginSession(loginSession) {
    this.accessToken = loginSession;
  }

  getLoginSession() {
    return this.loginSession;
  }

  setLang(lang) {
    this.lang = lang;
  }

  getLang() {
    return this.lang;
  }
}

export default new ServerSideService();
