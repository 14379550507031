import React from 'react';

const IconWarning = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M10 17.7727C5.7142 17.7727 2.22727 14.2862 2.22727 10C2.22727 5.71377 5.7142 2.22727 10 2.22727C14.2858 2.22727 17.7727 5.71377 17.7727 10C17.7727 14.2862 14.2858 17.7727 10 17.7727ZM10 0.5C4.76161 0.5 0.5 4.76161 0.5 10C0.5 15.2384 4.76161 19.5 10 19.5C15.2384 19.5 19.5 15.2384 19.5 10C19.5 4.76161 15.2384 0.5 10 0.5Z" fill="white" />
    <path fillRule="evenodd" clipRule="evenodd" d="M10.864 14.3182C10.864 14.7949 10.4771 15.1818 10.0004 15.1818C9.52363 15.1818 9.13672 14.7949 9.13672 14.3182C9.13672 13.8415 9.52363 13.4546 10.0004 13.4546C10.4771 13.4546 10.864 13.8415 10.864 14.3182Z" fill="white" />
    <path fillRule="evenodd" clipRule="evenodd" d="M9.13672 4.8182H10.864V11.7273H9.13672V4.8182Z" fill="white" />
    <path fillRule="evenodd" clipRule="evenodd" d="M9.13672 13.4546H10.864V15.1818H9.13672V13.4546Z" fill="white" />
  </svg>

);
export default IconWarning;
