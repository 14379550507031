import React from 'react';
import PropTypes from 'prop-types';
import { BLACK_BORDER_COLOR } from 'constants/index';

const IcNextAudio = ({
  fillcolor,
  width,
  height,
}) => (
  <svg width={width} height={height} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M15.5411 36C15.0333 36 14.5254 35.9028 14.0496 35.7008C12.8006 35.1713 12 34.0285 12 32.7721V14.2242C12 12.9693 12.8006 11.825 14.0496 11.2955C15.2986 10.7749 16.7748 10.9499 17.8271 11.7592L29.8626 21.0331C30.6586 21.6464 31.1176 22.5483 31.1176 23.4997C31.1176 24.4495 30.6586 25.3514 29.8626 25.9632L17.8271 35.2371C17.1744 35.7397 16.3616 36 15.5411 36Z" fill={fillcolor} />
    <rect x="31" y="11" width="4" height="25" rx="2" fill={fillcolor} />
  </svg>
);
IcNextAudio.propTypes = {
  fillcolor: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

IcNextAudio.defaultProps = {
  fillcolor: BLACK_BORDER_COLOR,
  width: '24',
  height: '24',
};
export default IcNextAudio;
