/* eslint-disable consistent-return */
import Axios from 'src/services/Axios/Axios';
import { API_ROUTES, DEFAULT_AGREE_STATUS } from 'constants/index';
import * as types from './constants';
// eslint-disable-next-line import/prefer-default-export
export const applyUserMiddleware = (dispatch) => (action) => {
  switch (action.type) {
    case types.GET_CREDIT_SUMMARY:
      dispatch({ type: types.SET_CREDIT_SUMMARY_LOADING });
      dispatch({
        type: types.SET_VIEW,
        payload: action.payload.view,
      });
      return Axios.get(`${API_ROUTES.GET_CREDIT_SUMMARY}?view=${action.payload.view}`, (status, data) => {
        dispatch({
          type: types.SET_CREDIT_SUMMARY,
          payload: data.content,
        });
      });
    case types.GET_NOTI_LIST:
      dispatch({ type: types.SET_LOADING_NOTI });
      return Axios.get(`${API_ROUTES.GET_NOTI_LIST}?limit=${action.payload.limitNoti}`, (status, data) => {
        dispatch({
          type: types.SET_NOTI_LIST,
          payload: data.content,
        });
      });
    case types.GET_AGREE_STATUS:
      dispatch({
        type: types.SET_ACTION,
        payload: action.payload.actionGet,
      });
      return Axios.post(`${API_ROUTES.AGEEMENT_STATUS}?action=${action.payload.actionGet}`, (''), (status, data) => {
        dispatch({
          type: types.SET_AGREE_STATUS,
          payload: data.content.agreement_status ?? DEFAULT_AGREE_STATUS,
        });
      });
    case types.SET_AGREE_STATUS:
      dispatch({
        type: types.SET_AGREE_STATUS,
        payload: action.payload.actionUpdate,
      });
      break;
    case types.GET_ALL_LIKE_BOX:
      return Axios.get(`${API_ROUTES.GET_ALL_LIKE_BOX}`, (status, data) => {
        dispatch({
          type: types.GET_ALL_LIKE_BOX,
          payload: data?.content,
        });
      });
    case types.GET_ALL_MY_COLLECTION:
      dispatch({ type: types.SET_LOADING_MY_COLLECTION });
      return Axios.get(`${API_ROUTES.GET_LIKE_BOX}${action?.payload?.data}&groupid=0`, (status, data) => {
        dispatch({
          type: types.GET_ALL_MY_COLLECTION,
          payload: data?.content,
        });
      });
    case types.SET_ALL_MY_COLLECTION:
      return dispatch({
        type: types.SET_ALL_MY_COLLECTION,
      });
    case types.DELETE_ALL_MY_COLLECTION:
      dispatch({ type: types.SET_LOADING_DELETE });
      return Axios.delete(`${API_ROUTES.DELETE_LIKE_BOX}?lb_id=${action?.payload?.data}`, (status, data) => {
        dispatch({
          type: types.DELETE_ALL_MY_COLLECTION,
          payload: data?.content,
        });
      });
    case types.GET_DETAIL_COLLECTION:
      return Axios.get(`${API_ROUTES.GET_DETAIL_LIKE_BOX}${action?.payload?.data}`, (status, data) => {
        dispatch({
          type: types.GET_DETAIL_COLLECTION,
          payload: data?.content?.data,
        });
      });
    case types.GET_USER_DETAILS:
      return Axios.get(API_ROUTES.GET_USER_PROFILE, (status, data) => {
        dispatch({
          type: types.GET_USER_DETAILS,
          payload: data?.content?.data,
        });
      });
    default: dispatch(action);
  }
};
