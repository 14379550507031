import PropTypes from 'prop-types';
import { ICON_COLOR_DEFAULT } from 'constants/index';

const IcCompanyInfo = ({
  fillcolor,
  width,
  height,
}) => (
  <svg width={width} height={height} viewBox="0 0 24 25" fill={fillcolor} xmlns="http://www.w3.org/2000/svg">
    <path d="M22.3981 21.4493H21.7032V13.6339C21.7032 13.2187 21.496 12.6569 21.0083 12.4493L14.7668 8.35842V2.81432L14.6937 2.67999C14.4133 1.98392 13.7916 1.642 13.0358 1.77633L3.2712 4.69492C2.71044 4.90251 2.29596 5.46425 2.29596 6.08705V21.4493H1.60111C1.18663 21.4493 0.90625 21.7913 0.90625 22.1454C0.90625 22.5606 1.18663 22.8415 1.60111 22.8415H22.3981C22.8125 22.8415 23.0929 22.5606 23.0929 22.1454C23.0929 21.718 22.8125 21.4493 22.3981 21.4493ZM20.3135 13.5606V21.3394H14.7668V9.94593L20.3135 13.5606ZM3.68568 6.08705L13.3893 3.16846V21.3394H3.68568V6.08705Z" fill={fillcolor} />
    <path d="M10.6096 8.60254H6.45267C6.03819 8.60254 5.75781 8.95668 5.75781 9.2986C5.75781 9.7138 6.03819 9.99467 6.45267 9.99467H10.6096C11.0241 9.99467 11.3045 9.7138 11.3045 9.2986C11.3045 8.8712 11.0241 8.60254 10.6096 8.60254ZM10.6096 12.7667H6.45267C6.03819 12.7667 5.75781 13.0476 5.75781 13.4628C5.75781 13.878 6.03819 14.1588 6.45267 14.1588H10.6096C11.0241 14.1588 11.3045 13.878 11.3045 13.4628C11.3045 13.0476 11.0241 12.7667 10.6096 12.7667ZM10.6096 16.9309H6.45267C6.03819 16.9309 5.75781 17.2118 5.75781 17.627C5.75781 18.0422 6.03819 18.323 6.45267 18.323H10.6096C11.0241 18.323 11.3045 18.0422 11.3045 17.627C11.3045 17.1995 11.0241 16.9309 10.6096 16.9309ZM16.8511 18.323H18.2409C18.6553 18.323 18.9357 18.0422 18.9357 17.627C18.9357 17.2118 18.6553 16.9309 18.2409 16.9309H16.8511C16.4367 16.9309 16.1563 17.2728 16.1563 17.627C16.1563 18.0422 16.4367 18.323 16.8511 18.323Z" fill={fillcolor} />
  </svg>

);

IcCompanyInfo.propTypes = {
  fillcolor: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

IcCompanyInfo.defaultProps = {
  fillcolor: ICON_COLOR_DEFAULT,
  width: '24',
  height: '24.04',
};
export default IcCompanyInfo;
