/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import { Button } from 'antd';
import { getTransText } from 'common/translate';
import { HIDE_FREE_IMAGE_RESEARCH_INTRODUCTION, ROUTES } from 'constants/index';
import * as cookie from 'helpers/cookies';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { useCallback, useEffect } from 'react';

const FreeResearchImage = ({
  freeImageResearchActivated,
  changeFreeImageResearchStatus,
  children,
}) => {
  // const [showIntroduction, setShowOIntroduction] = useState(false);
  useEffect(() => {
    // const hideIntroductionStatus = cookie.getCookies(HIDE_FREE_IMAGE_RESEARCH_INTRODUCTION) === 'true';
    // // setShowIntroduction(!hideIntroductionStatus);
    // changeFreeImageResearchStatus(!hideIntroductionStatus);
  }, []);
  const hideIntroduction = useCallback(() => {
    // setShowIntroduction(false);
    // changeFreeImageResearchStatus(false);
    // cookie.setCookie(HIDE_FREE_IMAGE_RESEARCH_INTRODUCTION, true, { expires: 30 });
  }, []);
  const router = useRouter();
  const onAccept = () => {
    hideIntroduction();
    router.push(ROUTES.IMAGE_RESEARCH_SERVICE);
  };

  return [ROUTES.HOME, ROUTES.AUDIO, ROUTES.FOOTAGE, ROUTES.VECTOR].includes(router?.pathname) ? (
    <div
      className={`ai-search-introduction-container${
        freeImageResearchActivated ? ' show-layer' : ''
      }`}
    >
      {children}

      {freeImageResearchActivated && (
        <>
          {
            <>
              {' '}
              <div className="arrow" />
              <div className="ai-search-introduction-dialog">
                {/* <p className="new-feature-notice">{getTransText('New')}</p> */}
                <p className="ai-powered-search free-image-research">
                  {getTransText('newService')}
                </p>
                <p className="ai-search-introduction-des">
                  {getTransText('freeImageResearchIntroduction')}
                </p>
                <div className="action-btn-container">
                  <Button
                    onClick={hideIntroduction}
                    className="global-btn secondary-btn ai-search-introduction-btn"
                  >
                    {getTransText('later')}
                  </Button>
                  <Button
                    onClick={onAccept}
                    className="global-btn primary-btn ai-search-introduction-btn"
                  >
                    {getTransText('letTryIt')}
                  </Button>
                </div>
              </div>
            </>
          }
        </>
      )}
    </div>
  ) : (
    children
  );
};

FreeResearchImage.propTypes = {
  freeImageResearchActivated: PropTypes.bool,
  changeFreeImageResearchStatus: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

FreeResearchImage.defaultProps = {
  children: <></>,
  freeImageResearchActivated: true,
  changeFreeImageResearchStatus: () => {},
};

export default FreeResearchImage;
