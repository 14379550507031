import * as types from './constants';

// eslint-disable-next-line import/prefer-default-export
export const useActions = (state, dispatch) => ({
  getSettings: (data) => {
    dispatch({
      type: types.GET_SETTING,
      payload: {
        data,
      },
    });
  },
  // get recommended photos and vectors
  getRecommendedImage: (data) => {
    dispatch({
      type: types.GET_RECOMMENDED_IMAGES,
      payload: {
        data,
      },
    });
  },
  // get recommended footages and audios
  getRecommendedFootage: (data) => {
    dispatch({
      type: types.GET_RECOMMENDED_FOOTAGE,
      payload: {
        data,
      },
    });
  },
  // get trending keywords
  getTrendingKeywords: (data) => {
    dispatch({
      type: types.GET_TRENDING_KEYWORDS,
      payload: {
        data,
      },
    });
  },
  setDefaultLb: (data) => {
    dispatch({
      type: types.SET_DEFAULT_LIKEBOX,
      payload: {
        data,
      },
    });
  },
  setDisplayBtn: (data) => {
    dispatch({
      type: types.SET_DISPLAY_BTN,
      payload: {
        data,
      },
    });
  },
});
