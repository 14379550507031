/* eslint-disable no-param-reassign */
/* eslint-disable react-hooks/rules-of-hooks */
import * as cookies from 'helpers/cookies';
import {
  ACCESS_TOKEN,
  LANG,
  SORTBY_DOWNLOADHISTORY,
  SORTBY_FILTER,
  SORTBY_GEORANK,
  ROUTES,
  SEARCH_BAR_AUDIO,
  SEARCH_BAR_VECTOR,
  SEARCH_BAR_ALL,
  SEARCH_BAR_PHOTOS,
  SEARCH_BAR_FOOTAGE,
  STRING_VALUE_TRUE,
  STRING_VALUE_FALSE,
  MAX_WIDTH_IMAGE_UPLOAD,
  MAX_HEIGHT_IMAGE_UPLOAD,
} from 'constants/index';
import { useRouter } from 'next/router';
import { LANGUAGE_CODE_DEFAULT } from 'constants/intl';
import queryString from 'query-string';
import * as store from 'helpers/storage';
import { message } from 'antd';

export const setResponse = (httpCode = '', messages = '') => ({
  httpCode,
  messages,
});

export const changeLanguage = (lang, router) => {
  const localeCookie = cookies.getCookies(LANG);
  if (lang && lang !== localeCookie) {
    // eslint-disable-next-line no-param-reassign
    router.query.lang = lang;
    cookies.setCookie(LANG, lang);
    router.push(router);
  }
};

// Append language parameter for routes to legacy
export const routeLangToLegacy = (route, router = null) => {
  const routerTmp = router ?? useRouter();
  const locale = routerTmp.query?.lang ?? cookies.getCookies(LANG) ?? LANGUAGE_CODE_DEFAULT;
  let routeToLegacy;
  const langParam = queryString.parse(route)?.lang;
  // Check if url include params
  if (route.includes('?')) {
    if (langParam) {
      routeToLegacy = route.replace(langParam, locale);
    } else {
      routeToLegacy = route.concat(`&lang=${locale}`);
    }
  } else {
    routeToLegacy = route.concat(`?lang=${locale}`);
  }
  return routeToLegacy;
};

// Append language parameter for routes
export const getLangRoute = (route, router = null) => {
  const routerTmp = router ?? useRouter();
  const locale = routerTmp.query?.lang ?? cookies.getCookies(LANG) ?? LANGUAGE_CODE_DEFAULT;
  let langRoute = route;
  if (!route.includes('lang=') && locale !== LANGUAGE_CODE_DEFAULT) {
    const langParam = `?lang=${locale}`;
    langRoute = route.concat(langParam);
  }
  return langRoute;
};

// Intial sort by values in filter bar for search page
export const intialSortBy = () => {
  let sortByFilter = SORTBY_FILTER;
  if (store?.getStorage('getSetting')?.show_georank_filter) {
    sortByFilter = [...sortByFilter, SORTBY_GEORANK];
  }
  if (store?.getStorage('getSetting')?.show_dlHistory_filter && cookies.getCookies(ACCESS_TOKEN)) {
    sortByFilter = [...sortByFilter, SORTBY_DOWNLOADHISTORY];
  }
  return sortByFilter;
};

export const checkResponseCode = (httpCode) => httpCode > 0;

export const setValueDefaultDropdown = (router) => {
  let value;
  switch (router.pathname) {
    case ROUTES.VECTOR:
      value = SEARCH_BAR_VECTOR;
      break;
    case ROUTES.FOOTAGE:
    case ROUTES.DETAIL_FOOTAGE_PAGE:
    case ROUTES.SEARCH_FOOTAGE:
      value = SEARCH_BAR_FOOTAGE;
      break;
    case ROUTES.SEARCH_IMAGE:
      if (router?.query?.mediaType === '1') {
        value = SEARCH_BAR_PHOTOS;
      } else if (router?.query?.mediaType === '2') {
        value = SEARCH_BAR_VECTOR;
      } else {
        value = SEARCH_BAR_ALL;
      }
      break;
    case ROUTES.AUDIO:
    case ROUTES.DETAIL_AUDIO_PAGE:
    case ROUTES.SEARCH_AUDIO:
      value = SEARCH_BAR_AUDIO;
      break;
    case ROUTES.HOME:
    default:
      value = SEARCH_BAR_ALL;
      break;
  }
  return value;
};

export const handleOneButtonChoice = (router, key, array, defaultValue = undefined, number = 1) => {
  const paramLen = Array.from({ length: array.length }, (_, i) => (i + number).toString());
  return paramLen?.includes(router?.query?.[key])
    ? { [key]: array[parseInt(router?.query?.[key], 10) - number] }
    : { [key]: defaultValue };
};

export const handleMultiButtonChoice = (router, key, array, number = 0) => {
  let data = {};
  if (router?.query[key] !== undefined) {
    const keyNum = [];
    const keyParam = router.query[key].split('|');
    array.map(
      (item, index) => keyParam?.includes((index + number).toString()) && keyNum.push(item),
    );
    data = { [key]: keyNum };
  } else {
    data = { [key]: [] };
  }
  return data;
};

export const handleParamsOneChoiceFilter = (router, key, value, array, allowZero) => {
  const param = array.indexOf(value) !== -1 ? `${array.indexOf(value) + (allowZero ? 0 : 1)}` : undefined;
  if (param) {
    router.query[key] = param;
  } else if (router.query[key]) {
    delete router.query[key];
  }
};

export const handleParamsMultiChoiceFilter = (router, key, value, array, number = 0) => {
  const arrParams = [];
  if (value?.length > 0) {
    array.map((item, index) => value?.includes(item) && arrParams.push(`${index + number}`));
    router.query[key] = arrParams.join('|');
  } else if (router.query[key] !== undefined) {
    delete router.query[key];
  }
};

export const handleParamsCheckbox = (router, key, value, compareValue = STRING_VALUE_TRUE) => {
  if (value !== undefined) {
    router.query[key] = value === true ? STRING_VALUE_TRUE : STRING_VALUE_FALSE;
    if (router.query[key] === compareValue) {
      delete router.query[key];
    }
  }
};

// Handle event for copy link button
export const handleCopyLink = (link) => {
  navigator.clipboard.writeText(link);
};

export const openSuccessNotification = (messages, container) => {
  message.destroy();
  message.config({
    getContainer: () => container,
  });
  message.open({
    className: 'small-notification-box-wrapper',
    content: <div className="small-notification-box">{messages}</div>,
    duration: 2,
  });
};

// Format the current time of audio playback
export const formatTime = (seconds = 0) => {
  const m = Math.floor((seconds % 3600) / 60);
  const s = Math.floor((seconds % 3600) % 60);
  const mDisplay = m > 0 ? `${m.toString().length > 1 ? `${m}` : `${0}${m}`}` : '00';
  const sDisplay = s > 0 ? `${s.toString().length > 1 ? `${s}` : `${0}${s}`}` : '00';
  return `${mDisplay}:${sDisplay}`;
};

// Get the current date and time
export const getCurrentDatetime = (time) => {
  const date = new Date(time);
  const day = date.getDate();
  const month = date.getMonth();
  const year = date.getFullYear();
  const hour = date.getHours();
  const min = date.getMinutes();
  return {
    day,
    month,
    year,
    hour,
    min,
  };
};
// Get base64 of image
export const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
};

// Reduce size of image before uploading
export const resizeImage = (
  base64Str,
  maxWidth = MAX_WIDTH_IMAGE_UPLOAD,
  maxHeight = MAX_HEIGHT_IMAGE_UPLOAD,
) => new Promise((resolve) => {
  const img = new Image();
  img.src = base64Str;
  img.onload = () => {
    const canvas = document.createElement('canvas');
    let { width, height } = img;
    if (width > height) {
      if (width > maxWidth) {
        height *= maxWidth / width;
        width = maxWidth;
      }
    } else if (height > maxHeight) {
      width *= maxHeight / height;
      height = maxHeight;
    }
    canvas.width = width;
    canvas.height = height;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(img, 0, 0, width, height);
    resolve(canvas.toDataURL());
  };
});

export const convertFootageSizeDisplay = (size) => {
  switch (size) {
    case '4000':
    case 4000:
      return '4K';
    case 'LELFOOT':
      return 'LL';
    default:
      return `${size}P`;
  }
};
