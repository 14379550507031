import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'antd';
import { getTransText } from 'common/translate';
import IconWarning from 'components/icons/IconWarning';
import ReactDOM from 'react-dom';

const checkMessage = (message) => {
  if (message.endsWith('.')) return message;
  return `${message}.`;
};

const PopUpMessageForCollectionsCreated = ({
  isCreated, likeboxName, handleTimeout, isFailed,
}) => {
  let timeoutPopUp = null;
  const autoClosePopUpMsg = () => {
    handleTimeout(false);
  };
  useEffect(() => {
    if (isCreated) {
      timeoutPopUp = setTimeout(autoClosePopUpMsg, 4000);
    }
    return () => {
      clearTimeout(timeoutPopUp);
    };
  }, [isCreated]);
  return (
    <>
      {isCreated
        ? ReactDOM.createPortal(
          <div className="message-created-likebox">
            {isFailed ? (
              <Alert
                message={getTransText('errorMesServer')}
                type="warning"
                showIcon
                closable
                icon={<IconWarning />}
              />
            ) : (
              <Alert
                message={checkMessage(`${likeboxName}${getTransText('createCollection')}`)}
                type="success"
                showIcon
                closable
              />
            )}
          </div>,
          document.body,
        )
        : null}
    </>
  );
};
PopUpMessageForCollectionsCreated.propTypes = {
  isCreated: PropTypes.bool,
  likeboxName: PropTypes.string,
  handleTimeout: PropTypes.func,
  isFailed: PropTypes.bool,
};

PopUpMessageForCollectionsCreated.defaultProps = {
  isCreated: null,
  likeboxName: null,
  handleTimeout: null,
  isFailed: false,
};
export default PopUpMessageForCollectionsCreated;
