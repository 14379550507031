/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import { routeLangToLegacy } from 'common/common';

const LinkLegacy = ({ href, children, className }) => (
  <a
    href={routeLangToLegacy(href)}
  >
    <span className={className}>{children}</span>
  </a>
);
LinkLegacy.propTypes = {
  href: PropTypes.string,
  children: PropTypes.any ?? PropTypes.element,
  className: PropTypes.string,
};

LinkLegacy.defaultProps = {
  href: '',
  children: null,
  className: null,
};
export default LinkLegacy;
