/* eslint-disable react/jsx-props-no-spreading */
import { Form, Input } from 'antd';
import IcWarning from 'components/icons/IcWarning';
import { PropTypes } from 'prop-types';

const errorMessage = (message, customLoginPage) => (
  <div className="input-error-container">
    <span className="input-error-icon">{customLoginPage ? <IcWarning /> : <IcWarning />}</span>
    {customLoginPage ? <p>{message}</p> : message}
  </div>
);

const CustomInputFormItem = ({
  name,
  label,
  formItemClassName,
  inputClassName,
  rules,
  clearable,
  type,
  initialValue,
  spaceForError,
  customLoginPage,
  ...restProps
}) => {
  const rulesTransform = rules.map((rule) => ({
    ...rule,
    message: errorMessage(rule.message, customLoginPage),
  }));

  return (
    <Form.Item
      name={name}
      label={label}
      className={`custom-input-form-item ${formItemClassName || ''} ${
        spaceForError ? 'error-space' : ''
      }`}
      initialValue={initialValue}
      rules={[...rulesTransform]}
    >
      {type === 'textArea' ? (
        <Input.TextArea
          rows={3}
          className={`custom-input-form-input ${inputClassName}`}
          {...restProps}
        />
      ) : (
        <Input
          className={`custom-input-form-input ${inputClassName}`}
          allowClear={clearable}
          {...restProps}
        />
      )}
    </Form.Item>
  );
};
CustomInputFormItem.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  formItemClassName: PropTypes.string,
  inputClassName: PropTypes.string,
  rules: PropTypes.arrayOf(PropTypes.any),
  clearable: PropTypes.bool,
  type: PropTypes.string,
  initialValue: PropTypes.string,
  spaceForError: PropTypes.bool,
  customLoginPage: PropTypes.bool,
};

CustomInputFormItem.defaultProps = {
  label: '',
  formItemClassName: '',
  inputClassName: '',
  rules: [],
  clearable: false,
  type: 'input',
  initialValue: '',
  spaceForError: false,
  customLoginPage: false,
};

export default CustomInputFormItem;
