const ru = {
  // Header
  photos: 'Фото',
  Photos: 'Фото',
  vectors: 'векторы',
  Vectors: 'векторы',
  footageupper: 'ВИДЕОСЮЖЕТЫ',
  footage: 'Видеосюжет',
  video: 'Video',
  audio: 'Аудио',
  signin: 'Войти',
  imageLogo:
    'https://static-cdn.assetsdelivery.com/images/new_translate_corpplus_logo/logo_Pyccknn.png',
  language: 'Dil',
  // Footer
  contact: 'Контакты',
  contactUs: 'Свяжитесь с нами',
  privacy: 'Политика конфиденциальности',
  terms: 'Условия использования',
  cookie: 'ПОЛИТИКА ИСПОЛЬЗОВАНИЯ COOKIE',
  corporateAgreement: 'License Agreement',
  // ForgotPassword
  forgotPwdTitle2: 'Забыли ваш пароль?',
  forgotPwdDesc2:
    "That's OK, it happens to all of us. Enter your username below and we'll send you a link to reset your password",
  forgotPwdFail: 'Пожалуйста, введите email адрес',
  emailCannotEmpty: 'Пожалуйста, введите адрес электронной почты.',
  returnToLogin: 'Вернуться к Логину',
  email: 'E-mail',

  // share likebox
  duplicateCollection: 'Duplicate collection',
  removeFromCollection: 'Remove from collection',
  addToCollection: 'Add to collection',
  totalContentInThisCollection: 'Total content in this collection',
  duplicate: 'Duplicate',
  collectionDescription: 'Collection description',
  descriptionHere: 'Description here',
  duplicateCollectionSuccess:
    'You have successfully duplicated this collection to your personal collections.',

  // Login
  username: 'Имя пользователя',
  pw: 'Пароль',
  rememberMe: 'Напомнить мне',
  login: 'Логин',
  forgotPassword: 'Забыли пароль',
  loginAsGuest: 'Гостевой Логин',
  // ResetPassword
  resetPwdDesc: 'Пожалуйста, введите новый пароль, и  вы осуществите вход на сайт.',
  newPw: 'Новый пароль',
  confirmPwd: 'Подтвердить пароль',
  resetPwdButton: 'Сброс пароля',
  resetPwdTitle: 'Сбросить пароль',
  enterYourNewPw: 'Пожалуйста, введите ваш новый пароль',
  pwMiniMumChars: 'Минимум 8 символов',
  pwMaxChar20: 'Максимальное количество символов не может превышать 20',
  pwLowerCase: 'Как минимум одну строчную букву',
  pwUpperCase: 'По крайней мере одну заглавную букву',
  pwNumeral: 'Как минимум одну цифру',
  pwdSpecialChar: 'At least one special character',
  confirmPwNotMatch: 'Подтверждение пароля не совпадает с введенным паролем',
  resetPwdInvalidLink: 'та ссылка не действительна.',
  pwHasBeenReset: 'Пароль был сброшен. Войдите, чтобы продолжить.',
  // Admin index
  companyName: 'Наименование Компании',
  phone: 'Телефон',
  fax: 'Факс',
  address: 'Адрес',
  idd: 'IDD',
  // Dashboard Admin Credits
  myBalance: 'My balance',
  remaining: 'remaining',
  creditsRemaining: 'Credits remaining',
  topUp: 'Top up',
  groupBalance: 'Group balance',
  totalGroupBalance: 'Total group balance',
  manageGroupBalance: 'Manage group balance',
  individualBalance: 'Individual balance',
  manageIndividualBalance: 'Manage individual balance',
  totalDownloads: 'Total downloads',
  totalItems: 'Всего файлов',
  // Update Password
  updatePassTitle: 'Update Password',
  updatePassDes: 'Update your password before you go!',
  oldPassword: 'Старый пароль',
  confirmNewPassword: 'Подтвердите новый пароль',
  returnToHome: 'Возврат домой',
  // Cookie Policy
  langCookie01:
    'Когда Вы посещаете %host%, мы помещаем небольшой текстовый файл, называемый cookie-файлом на жестком диске Вашего компьютера.',
  langCookie02:
    'Этот файл позволяет нам отслеживать Вас, чтобы мы могли предоставить Вам базовый функционал, такой как «корзина». Он также используется для обеспечения расширенного онлайн-интерфейса и предоставления Вам дополнительного функционала, такого как «лайкбокс».',
  langCookie03:
    'Кроме того, он позволяет нам анализировать Ваше взаимодействие с нашим сайтом (включая посещенные страницы, поисковые запросы и просмотренные изображения). Это не влияет на то, хотите ли Вы совершить покупку или нет.',
  langCookie04:
    'Cookies, на которые вы согласились, также используются для анализа Ваших интересов, чтобы мы могли доставлять Вам только целевую рекламу.',
  langCookie05:
    'Большинство браузеров позволяют отклонять файлы cookie; но если Вы это сделаете, некоторые функции могут быть недоступны, и некоторые веб-страницы могут отображаться неправильно.',

  downloadModalTitle: 'Скачать изображение',
  bulkDownloadTitle: 'Bulk Download',
  messageFileNotSupported1stHalf:
    'Изображения TIFF, видеосюжет и аудио не поддерживаются. Следующие медиафайлы:',
  messageFileNotSupported2ndHalf: 'были удалены из массовой загрузки.',
  licenseOnBehalf: 'Лицензия от имени',
  customizedPortal: 'Customized portal',
  normalDownload: 'Нормальное скачивание',
  myCredit: 'Мой кредит',
  lowCreditsReminder: 'Низкий кредит',
  downloadModalId: 'ID изображения:',
  downloadModalPrice: 'Кредит:',
  clearAll: 'Очистить все',
  myActivities: 'Моя активность',
  info: 'Информация',
  idCapitialization: 'ID',
  licenseOnBehalfOfYourClient: 'License on behalf of your client (Your client owns the license)',
  downloadUsing: 'скачать этот контент, используя',
  // homepage page
  home3Title: 'Free Browsing',
  toSearchKeyword: 'Введите свой поиск здесь',
  toSearchAIKeyword: 'Введите ключевые слова, название статьи или блога',
  allImages: 'Все изображения',
  freeImages: 'Free Images',
  categoryImage: 'Category',
  recentViewed: 'Недавно Просмотренные',
  latestIMG: 'Latest Images',
  // Setting
  adminControlPanel: 'Панель Администратора Корпоративный+',
  downloadHistory: 'История загрузок',
  hruc: 'HRUC',
  changePassword: 'Изменить пароль',
  hello: 'Здравствуйте,',
  viewAccount: 'View Account',
  myAccount: 'Моя учетная запись',
  myCollections: 'Мои коллекции',
  personalCredits: 'Персональные кредиты',
  groupTotal: 'Итого по группе',
  logOut: 'Выйти',
  toTalUnpaidLicense: 'Итого неоплаченных лицензий',
  highRUnwatermarkComp: 'Hi-Res без ватермарка',
  monthSelection: 'Month selection',

  // Menu
  categories: 'Категории',
  myLikebox: 'Мои Лайтбоксы',
  settings: 'Настройки',
  notification: 'Уведомления',
  clear: 'Очистить',
  likebox: '收藏夹',
  credits: 'Кредиты',
  // Credit summary at header
  downloadAndUsege: 'Загрузка и Использование',
  totalDlCredit: 'Итог Загрузок',
  allocatedDlCredit: 'Распределить кредиты для скачивания',
  personalAvailableCre: 'Персональные Кредиты',
  groupAvailableCre: 'Кредиты Группы',
  availableDlSlot: 'Доступно загрузок (штук)',
  uNPLD: 'Используй сейчас, плати потом - Загрузки.',
  hiResHRUC: 'Hi-Res без ватермарка (HRUC)',
  monthlyAllocation: 'Ежемесячное распределение',
  remainingQuota: 'Оставшаяся квота',
  remainingHRUCQ: 'Доступные квоты Hi-Res без ватермарков',
  useNowPayLater: 'Use-Now-Pay-Later (UNPL)',
  totalPendingLicense: 'Total Pending License Downloads',
  enterprisePlan: 'Corporate+ Enterprise Plan',
  unallocatedCredits: 'Unallocated Credits',
  unutilizedAllocatedCredits: 'Unutilized Allocated Credits',
  usersTitleTotalCredits: 'Users',
  groupsTitleTotalCredits: 'Группы',
  titleTotalCreditsAvailable: 'Total Available',
  // live chat
  liveChatLookIngHelp: 'Пожалуйста, сообщите, как мы можем помочь вам',
  liveChat: 'Живой чат',
  whatsApp: 'WhatsApp',
  hiThere: 'Привет,',
  // email response
  emailIsNotAvailable: 'E-mail не доступен.',
  anErrorHaSoccured: 'Произошла ошибка. Попробуйте еще раз.',
  emailHasBeenSent:
    'Сообщение было отправлено успешно. Пожалуйста, проверьте свой электронный ящик для продолжения. Мы направили инструкции на ваш адрес электронной почты __email. Если сообщение электронной почты не пришло к вам в течение 5 минут, пожалуйста, проверьте папку Спам.',
  tooManyAttempts: 'Слишком много попыток. Пожалуйста, попробуйте позже.',
  // INVALIDPASSWORD
  inValidPassword: 'Неверный пароль! Проверьте, не нажата ли клавиша Caps.',
  userIsBlocked: 'Слишком много неверных попыток, вы временно заблокированы!',
  blockWarrings: 'Вы будете временно заблокированы после 10 попыток!',
  // Update Password
  resetPwdUpdated: 'Ваш пароль был обновлен.',
  updatePwDfailed: 'Ошибка обновления пароля!',
  // notification
  // Notification Side Bar - user
  notificationShareLikebox: 'shared a Likebox named [{VAR1}] with you.',
  notificationOutofCredits: 'ran out of download credits.',
  notificationAllcateCredit: 'assigned [{VAR1}] download credits to you.',
  notificationRemoveCredits: 'removed [{VAR1}] download credits from your account.',
  notificationAssignGroups: 'assigned you into the [{VAR1}] group.',
  notificationRemoveFromGroup: 'removed you from the [{VAR1}] group.',
  purcahsedCredits: 'You have just purchased [{VAR1}] credits.',
  notificationAssignAdmin: 'assigned you as the administrator.',
  notificationRemoveAdmin: 'removed you as the administrator.',
  notificationAssignGroupAdmin: 'assigned you as the group administrator of [{VAR1}].',
  notificationRemoveGroupAdmin: 'removed you as the group administrator of [{VAR1}].',
  // Notification Side Bar - admin
  adminNotificationShareLikebox: 'shared a Likebox named [{VAR1}] with [{RECEIVER}].',
  adminNotificationAllcateCredits: 'assigned [{VAR1}] download credits to [{RECEIVER}].',
  adminNotificationRemoveCredist: "removed [{VAR1}] download credits from [{RECEIVER}]'s account.",
  adminNotificationAssignGroup: 'assigned [{RECEIVER}] into the [{VAR1}] group.',
  adminNotificationRemoveFromGroup: 'removed [{RECEIVER}] from the [{VAR1}] group.',
  adminPurchasedCredits: '[{RECEIVER}] have just purchased [{VAR1}] credits.',
  adminNotificationAssignAdmin: 'assigned [{RECEIVER}] as the administrator.',
  adminNotificationRemoveAdmin: 'removed [{RECEIVER}] as the administrator.',
  adminNotificationAssignGroudAdmin:
    'assigned [{RECEIVER}] as the group administrator of [{VAR1}].',
  adminNotificationremoveGroupAdmin: 'removed [{RECEIVER}] as the group administrator of [{VAR1}].',
  // datetime
  year: 'year',
  month: 'month',
  week: 'week',
  day: 'day',
  hour: 'hour',
  minute: 'minute',
  second: 'second',
  ago: 'ago',
  justNow: 'just now',
  // datetimes
  years: 'years',
  months: 'months',
  weeks: 'weeks',
  days: 'days',
  hours: 'hours',
  minutes: 'минут',
  seconds: 'seconds',
  // LicenseAgreement
  licenseAgreement: 'Лицензионное Соглашение',
  licenseAgreementCaption:
    'PLEASE READ ALL THE TERMS PROVIDED IN THIS CORPORATE+ CONTENT LICENSING PLATFORM. YOUR ACCEPTANCE OF THESE TERMS IS AN ABSOLUTE CONDITION OF YOUR USE OF THE CORPORATE+ CONTENT LICENSING PLATFORM AND THE CONTENT, AND FORMS A LEGAL AGREEMENT. BY ACCESSING ANY SERVICE OR PART THEREOF UNDER THE CORPORATE+ CONTENT LICENSING PLATFORM, YOU AGREE TO BE BOUND BY THE TERMS OF THESE LEGAL DOCUMENTS. 123RF RESERVES THE RIGHT TO CHANGE THESE TERMS AT ANY TIME WITHOUT PRIOR WRITTEN NOTICE, AND YOU AGREE TO BE BOUND BY SUCH CHANGES.',
  corpplusAgreement: 'Corporate+ Agreement',
  // login
  usernameCannotEmpty: 'Имя пользователя не может быть пустым',
  passWordCannotEmpty: 'Пароль не может быть пустым',
  oldPasswordCannotEmpty: 'Старый пароль не может быть пустым',
  confirmPasswordCannotBeEmpty: 'Подтверждение пароля не может быть пустым',
  // update password
  newpasswordDifferent: 'The new pasword and old pasword must be different.',
  // sent email not 20 times
  forgotNot20: 'Невозможно отправить больше писем, пожалуйста, проверьте свой почтовый ящик.',
  // errorMesServer
  errorMesServer: 'An error has occured, please try again or contact your administrators',
  // white space
  canNotContainSpaces: 'Password cannot contain spaces',
  // username is not available
  userNameNotAvailable: 'Username is not available.',
  // Username cannot contain spaces
  usernameCanNotContainSpaces: 'Username cannot contain spaces',
  // old browsers
  usingOlderBrowsers: 'Вы используете старую версию браузера?',
  upgradeBrowser:
    'Обновите свой браузер до новой версии, чтобы насладиться более быстрой работой нашей платоформы!',
  clickToDownload: 'Нажмите, чтобы загрузить любой из них:',
  googleChrome: 'Google Chrome',
  mozillaFirefox: 'Mozilla Firefox',
  microsoftEdge: 'Microsoft Edge',
  safari: 'Safari',
  // Contact Us
  nameUnavailable: 'Name unavailable',
  addressUnavailable: 'Company address unavailable',
  phoneUnavailable: 'Phone number unavailable',
  faxUnavailable: 'Fax unavailable',
  iddUnavailable: 'IDD unavailable',
  emailUnavailable: 'Email unavailable',
  agreeLicense: 'I AGREE to abide by all the Terms and Conditions above',
  btnAgree: 'AGREE',
  btnDecline: 'DECLINE',
  messageCheckbox:
    'Please read the License Agreement above carefully and signify your agreement by clicking the "I AGREE" check box.',
  // forgot page new description
  forgotPwdDescNew: "Enter your username below and we'll send you a link to reset your password.",
  // Notification
  readAll: 'Read All',
  viewAll: 'Смотреь все',
  // Filter bar
  aiGenerate: 'ИИ-сгенерированное изображение',
  aiContent: 'Включает ИИ-сгенерированный контент',
  filter: 'Фильтр',
  safeSearch: 'Безопасный поиск',
  aiAssist: 'AI помощник',
  freshness: 'Новинки',
  gender: 'Пол',
  sortBy: 'Сортировать по',
  mediaType: 'Тип',
  orientation: 'Ориентация',
  modelPreferences: 'Предпочтение с моделью',
  people: 'Люди',
  more: 'Еще',
  numOfPeople: 'Количество людей',
  age: 'Возраст',
  ethnicity: 'Этническая принадлежность',
  collection: 'Коллекции',
  uploaded: 'Uploaded',
  style: 'Стиль',
  cutOut: 'Сплошной/прозрачный фон',
  doNotIncludeWords: 'Не включайте эти слова',
  doNotIncludeWords_ex: 'Например, сыр',
  color: 'Цветные',
  max1Color: 'Максимум 1 цвет',
  license: 'Тип лицензии',
  fps: 'FPS',
  resolution: 'Разрешение',
  clipDuration: 'Продолжительность клипа (минуты)',
  loop: 'Закольцованность',
  category: 'Категория',
  genre: 'Жанр',
  tempo: 'Тепм (bpm)',
  // Filter button text
  Relevance: 'Случайные',
  New: 'Новый',
  Georank: 'Геометки',
  DownloadHistory: 'Загрузить историю',
  Popularity: 'Популярность',
  Photography: 'Фотографии',
  VectorIllustration: 'Векторная иллюстрация',
  Horizontal: 'Горизонтальный',
  Landscape: 'Пейзаж',
  Portrait: 'Портрет',
  Square: 'Квадрат',
  Panorama: 'Панорама',
  numPpl: 'Количество людей',
  WithoutPeople: 'Без людей',
  FilterNumberPeople1: '1 person',
  FilterNumberPeople2: '2 People',
  FilterNumberPeople3: '3 People',
  FilterNumberPeople4: '4 people or more',
  Exclude: 'Исключать',
  SelectiveFocus: 'Selective Focus',
  Pattern: 'Pattern',
  Vibrance: 'Vibrance',
  Infants: 'Infants',
  Toddlers: 'Toddlers',
  Teens: 'Teens',
  PastWeek: 'За неделю',
  LastMonth: 'Last month',
  PastThreeMonths: 'За 3 месяца',
  PastTwodays: 'За 2 дня',
  Male: 'Мужчина',
  Female: 'Женщина',
  Standard: 'Стандартные / Подписка',
  extended: 'Расширенная',
  Editorial: 'Редакционные',
  Commercial: 'Коммерческая',
  Black: 'Black',
  Caucasian: 'Европеоиды',
  AfricanAmerican: 'Афроамериканец',
  Asian: 'Азиатский',
  EastAsian: 'East Asian',
  Hispanic: 'Латиноамериканец',
  Indian: 'Indian',
  MiddleEastern: 'Middle Eastern',
  SoutheastAsian: 'Southeast Asian',
  Children: 'Дети',
  Teenagers: 'Подростки',
  Adults: 'Взрослые',
  Seniors: 'Пожилые',
  FPS1: '23.98/24',
  FPS2: '25',
  FPS3: '29.7/30',
  FPS4: '60',
  FPS5: '> 60',
  Off: 'Off',
  ZeroToOne: '0-1',
  OneToTwo: '1-2',
  TwoToThree: '2-3',
  LagerThree: '>3',
  Loopable: 'Да',
  Nonloopable: 'Нет',
  Music: 'Музыка',
  SoundEffects: 'Звуковые эффекты',
  LogoAndIdents: 'Логотипы и Фирменные знаки',
  Veryslow: 'Очень медленно',
  VeryslowSubText: '(0-79)',
  Slow: 'Медленно',
  SlowSubText: '(80-119)',
  Medium: 'Средне',
  MediumSubText: '(120-139)',
  Fast: 'Быстро',
  FastSubText: '(140-159)',
  Veryfast: 'Очень быстро',
  VeryfastSubText: '(160>)',
  TwoToEight: '2 - 8',
  TwentyToThirTy: '12 - 30',
  ThirtySixToSixty: '30 - 60',
  OneHundredAndTwenty: '120',
  Popular: 'Популярное',
  MostDownload: 'Часто скачиваемое',
  Credits: 'Кредиты',
  Freshness: 'Новинки',
  pickAGenre: 'Выбрать жанр',
  // Paginations
  Of: 'из',
  Page: 'Страница',
  // home
  recommended: 'Рекомендованное для Вас',
  bestSellingHome: 'Browse best-selling royalty photos',
  trendingKeyWord: 'Популярные ключевые слова',
  FAQ: 'Frequently Asked Questions (FAQ)',
  // audio
  categoriesAudio: 'HD stock audios',

  // genre list audios
  gameSounds: 'Игровые звуки',
  cartoonSounds: 'Мультипликационные звуки',
  soundPacks: 'Звуковые пакеты',
  interfaceSounds: 'Звуки интерфейса',
  transitionsAndMovementsSounds: 'Переходы и движения',
  natureSounds: 'Звуки природы',
  domesticSounds: 'Внутренние звуки',
  urbanSounds: 'Городские звуки',
  industrialSounds: 'Индустриальные звуки',
  futuristicSounds: 'Футуристические звуки',
  humanSounds: 'Человеческие звуки',
  othersSounds: 'Другие',
  ambientSounds: 'Окружающие',
  childrenSounds: 'Детские',
  cinematicSounds: 'Кинематографические',
  classicalSounds: 'Классическая',
  corporateSounds: 'Корпоративные',
  electronicaSounds: 'Электронная',
  soulSounds: 'Soul & R&B',
  jazzSounds: 'Джаз',
  popSounds: 'Поп',
  worldBeatSounds: 'Мировой бит',

  countrySounds: 'Кантри и Вестерн',
  drumSounds: 'Drum & Bass',

  folkSounds: 'Народные и акустические',
  funkSounds: 'Funk & Groove',

  hiphopSounds: 'Хип Хоп',
  holidaySounds: 'Праздничные и Сезонные',

  rockSounds: 'Рок',

  individualSounds: 'Индивидуальные',
  introSounds: 'Intro / Outro',
  ascSounds: 'По возрастанию / По убыванию',
  businessSounds: 'Бизнес / Медиа',
  orchestralSounds: 'Оркестровая',
  festiveSounds: 'Праздничная',
  percussiveSounds: 'Ударная',
  miscellaneousSounds: 'Разная',
  packsSounds: 'Пакеты',
  percussionSounds: 'Звуки ударных',
  sampleLoopSounds: 'Образец закольцованности',

  // Search images
  searchImage: 'Фото и векторы',
  StockPhotosAndImages: 'Стоковые фото и изображения',
  StockVectorClipartAndIllustration: 'Набор Векторов и Иллюстраций',

  matches: 'матчи',
  editorial: 'Редакционные',
  downloaded: 'Это изображение было загружено до',
  to: 'to',
  relatedSearches: 'Похожие запросы',
  similarTo: 'Похожие на',
  searchByImage: 'Поиск по изображению',
  options: 'Функции',
  searchByImageMsg:
    'Ищите на123RF с изображением вместо текста. Попробуйте перетащить изображение в поле поиска.',
  mustBeJPGorPNG: 'Изображение должно быть JPG / PNG',
  sizeLessThan5MB: 'Размер изображения должен быть менее 5 МБ',
  sizeLessThan4MB: 'Размер изображения должен быть менее 4 МБ',
  dragOrDropFile: 'Перетащите файл или',
  browse: 'просмотрите',
  uploadedImage: 'Загруженное',
  similarStockPhotos: 'Похожие стоковые фото',
  // Search footage
  searchFootage: 'Видеосюжет',
  StockFootage: 'Стоковый видеоролик',
  businessFootage: 'Hacks to make the most of your business videos',
  // Details pages
  audioDetail: 'Audio Detail',
  imageDetail: 'Image Detail',
  footageProperties: 'Свойства видеофутажа',
  audioProperties: 'Свойства аудио',
  modalDownloadTitle: 'Параметры скачивания файла',
  credit: 'Кредиты',
  saveAs: 'Сохранить как',
  ownLicense: 'Обычное скачивание (собственная лицензия)',
  downloadAs: 'Скачать как',
  insufficientCredits: 'Недостаточно кредитов',
  custom: 'Пользовательский',
  personalAndCom:
    'Личное и коммерческое, рекламное использование, презентации, социальные сети, веб.',
  licenseType: 'Тип лицензии',
  requiredForResale:
    'Требуется для перепродажи или распространения в театральных постановках, в платном доступе, в потоковом видео, в играх и приложениях.',
  footageDetail: 'Детали видеосюжета',
  stockAudioKeywords: 'Ключевые слова стокового аудио',
  stockFootageKeywords: 'Ключевые слова стокового видеосюжета',
  stockPhotoKeywords: 'Stock photo keywords',
  limitedLicenseSizes: 'Limited License Sizes',
  stereo: 'стерео',
  fileSize: 'Размеры файла',
  footageId: 'ID видеосюжета',
  audioId: 'Audio ID',
  imageId: 'Image ID',
  // Search audio
  searchAudio: 'Аудио (Музыка и звуковые эффекты)',
  StockAudio: 'Стоковое аудио',
  similarTracks: 'Similar Tracks',
  viewAlbum: 'View Album',
  copyLink: 'Скопируйте ссылку',
  preview: 'Предварительный просмотр',

  bestSellingFootage: 'Просмотрите самые продаваемые стоковые видеоматериалы',
  featuresAudio: 'Featured audio',
  readmore: 'Read More',
  // justified layout
  similarImage: 'Похожие изображения',
  addToLikebox: 'Add to Likebox',

  // recently view empty data
  rVPhoto: 'Ваши оследние просмотренные изображения будут отображаться здесь',
  rVVectors: 'Ваши оследние просмотренные векторы будут отображаться здесь',
  rVFootage: 'Ваши оследние просмотренные видео будут отображаться здесь',
  rVAudio: 'Ваши оследние просмотренные аудио будут отображаться здесь',
  // recently likes empty data
  rLPhoto: 'Your recently liked images will show here',
  rLVectors: 'Your recently liked vectors will show here',
  rLFootage: 'Your recently liked video will show here',
  rLAudio: 'Your recently liked audio will show here',
  // Content Unavailable
  contentUnavailable: 'Content Unavailable',
  contentError: 'We’re sorry, there’s some error displaying this content',
  emptySearchResultTitle: 'К сожалению, ваш поиск с этими фильтрами не дал результатов.',
  emptySearchResultTitleWithoutFilter: 'Oops, your search did not return any results.',
  emptySearchResultSubTitle:
    'Попробуйте удалить несколько фильтров или попробуйте другое ключевое слово.',
  emptyReverseSearchTitle: 'Результатов не найдено!',
  emptyReverseSearchSubTitle1:
    'Загруженное изображение содержит ошибки. Удостоверьтесь, что изображение соответствует следующим критериям:',
  emptyReverseSearchSubTitle2: 'Уточните параметры поиска ключевым словом',
  emptyReverseSearchSubTitle3: 'Изображение должно быть сохранено в формате JPEG/PNG',
  emptyReverseSearchSubTitle4: 'Размер изображения не должен превышать 5 Мб.',
  emptySearchResultClearFilter: 'Очистить все фильтры',
  emptyDownloadHistoryResult: 'Your download history will show here',
  // Please enter a keyword
  keywordEmpty: 'Пожалуйста, введите ключевое слово',
  // admin redirect to
  plansAndPricing: 'Plans and Pricing ',
  // Footer langding page
  contentTermLicense1:
    'This Agreement shall be governed by the laws of Singapore, without regard to the conflict of laws principles. ',
  contentTermLicense2:
    'the parties agree that the dispute shall be settled by arbitration in Singapore under the Singapore International Arbitration Centre (“SIAC”) administered SIAC Arbitration Rules in force when the notice of arbitration is submitted in accordance with these rules. The number of arbitrators shall be one (1). The language of the arbitration shall be English.',
  contentTermLicense3:
    'that damages and remedies under Singapore Law for any breach of any such covenant would be inadequate. You further acknowledge and agree that the arbitral tribunal may order any interim measure it deems necessary or proper in accordance with the applicable laws of Singapore,',
  contentTermUsLicense1:
    'This Agreement shall be governed by the laws of the state of Delaware, United States of America, without regard to the conflict of laws principles',
  contentTermLicenseUs2:
    ' the parties agree first to try in good faith to settle the dispute by mediation administered by the American Arbitration Association under its Commercial Mediation Procedures before resorting to arbitration, litigations or some other dispute resolution procedure. In the event if the dispute is settled via arbitration, the number of arbitrators shall be one (1) and the language of the arbitration shall be English.',
  contentTermUsLisence3:
    ' that damages and remedies under applicable law for any breach of any such covenant would be inadequate. You further acknowledge and agree that the arbitral tribunal may order any interim measure it deems necessary or proper in accordance with the applicable law,',
  contentCorporateUs:
    'This Agreement shall be governed by the laws of the State of Delaware in the United States of America, without regard to the conflict of laws principles. All disputes arising out of or in connection with the performance of this Agreement shall be settled through negotiations and if the dispute cannot be settled through negotiation, the parties agree first to try in good faith to settle the dispute by mediation administered by the American Arbitration under its Commercial Mediation Procedures before resorting to arbitration, litigation, or some other dispute resolution procedure. In the event if the dispute is settled via arbitration, the number of arbitrators shall be one (1), the language of the arbitration shall be English and the arbitral award shall be final and binding upon parties.',
  contentCorporate:
    'This Agreement shall be governed by, and shall be construed in accordance with, the laws of Singapore. All disputes arising out of or in connection with the performance of this Agreement shall be settled through friendly negotiations. If the parties are unable to resolve any such dispute within thirty (30) days after the commencement of negotiations, the parties agree that the dispute shall be settled by arbitration in Singapore under the Singapore International Arbitration Centre (“<b>SIAC</b>”) administered by SIAC Arbitration Rules in force when the notice of arbitration is submitted in accordance with the rules. The number of arbitrators shall be one (1). The decision of the arbitrator shall, except in the case of manifest error, be final and binding upon the parties hereto. The arbitration proceedings shall be conducted in English.',
  // collection search result page
  searchCollection: 'Искать коллекции',
  personal: 'Личные',
  groups: 'Группы',
  createNew: 'Создать новое',
  createNewCollection: 'Создать новую коллекцию',
  descriptionPlaceholder: 'Add an optional description for your collection here.',
  titleItem: 'ЗАГОЛОВОК',
  selectGroup: 'Выбрать группу',
  description: 'Описание',
  charactersRemaining: 'Осталось 180 символов',
  cancel: 'Отменить',
  create: 'Создать',
  or: 'ИЛИ',
  defaultCollection: 'Коллекция по умолчанию',
  addToMyCollections: 'Добавить в Лайкбоксs',
  addedToCollection: 'Добавлено в коллекцию',
  removedFromCollection: 'Удалено из коллекции',
  collectionModalPlaceholder: 'Например, Природа',
  groupNameShowsHere: 'Имя группы показано здесь',
  // like box
  btnViewCollection: 'View Collection',
  shareWithMe: 'Поделиться со мной',
  addLikebox: 'Add New Likebox',
  notImageLikeBox: 'You do not have any images in your likebox yet',
  owner: 'Владелец',
  images: 'Изображения',
  likeboxName: 'Likebox Name',
  likboxNameMaxLength50: 'The maximum characters allowed is 50',
  addLikeBoxTo: 'Add likebox to',
  personalLikebox: 'Персональные',
  btAdd: 'ADD',
  btCancel: 'CANCEL',
  btSave: 'SAVE',
  editLikebox: 'Edit Likebox',
  share: 'Поделиться',
  // reset password
  resetPwdTitle2: 'Reset Password',
  noFoundLikebox: 'У вас нет коллекций.',
  btnUnDo: 'Отменить',
  // my collection
  dateAdded: 'Дата добавлена',
  collectionName: 'Название коллекции',
  selectAll: 'Выбрать все',
  delete: 'Удалить',
  seeAll: 'Смотреть все',
  deleteCollection: 'Удалить коллекцию',
  deleteWarning:
    'Выполнение этого действия навсегда удалит ваши коллекции вместе с их содержимым. Имейте в виду, что это действие нельзя отменить.',
  createCollection: ' было создано',
  shareCollection: 'Поделиться коллекцией',
  shareWithTeam: 'Share in team',
  selectUsers: 'Выбрать пользователей',
  searchUserName: 'Искать имена пользователей',
  copy: 'Скопировать',
  titleCopyModal: 'Копировать выбранные элементы в',
  collectionUppercase: 'КОЛЛЕКЦИЮ',
  groupCollection: 'Группа',
  moveImage: 'Переместить выбранные элементы в',
  defaultSelect: 'Имя коллекции здесь',
  moveLikebox: 'Move',
  messsageDeleteLikebox:
    'Выполнение этого действия навсегда удалит ваши коллекции вместе с их содержимым. Имейте в виду, что это действие нельзя отменить.',
  areYouSure: 'Вы уверены?',
  editCollection: 'Редактировать коллекцию',
  collectionNameUpper: 'Название коллекции',
  deleteSuccessful: 'has been successfully deleted',
  edit: 'Редактировать',
  messageRemoveImage: 'Вы уверены, что хотите удалить выбранные элементы из коллекции?',
  titleRemove: 'Удалить выбранные элементы',
  popupActionMove: ' item(s) has been moved to ',
  popupActionCopy: ' item(s)has been copied to ',
  popupActionRemove: ' item(s) has been removed from this Collection',
  popupFailed: 'Image already exists',
  popupShared: 'Your collection has been successfully shared',
  popupActionDelete: 'Your collection has been successfully removed',
  popupActionEdit: 'Your edit has been successfully updated',
  btnSave: 'Сохранить',
  shareLikebox: 'Поделиться со мной',
  remove: 'Удалить',
  moveTo: 'Переместить',
  createNewPlaceholder: 'Например, Природа',
  shareViaEmail: 'Поделиться по e-mail',
  sharePublicly: 'Share publicly',
  receipnetEmail: 'ПОЛУЧАТЕЛЬ EMAIL',
  message: 'СООБЩЕНИЕ',
  insertReceipmentEmail: 'Вставьте адрес электронной почты получателя',
  messageText: 'Тест сообщения',
  exportCollection: 'Экспортировать коллекцию',
  pdf: 'PDF',
  csv: 'CSV',
  addNewCollection: 'Добавить новую коллекцию',
  likeboxEditSuccessful: 'You have successfully made changes to your collection.',
  format: 'ФОРМАТ',
  lowCredit: 'Низкий кредит',
  downloadSuccess: 'Спасибо за скачивание! Ваша загрузка начнется через несколько секунд.',
  downloadFailed: 'Ошибка загрузки: Произошла ошибка. Попробуйте еще раз.',
  downloadedLabel: 'Загруженные',
  any: 'Любой',
  resetFilter: 'Сбросить фильтр',
  enterCustomFieldTag: 'Enter custom field tags',
  // Portfolio
  follow: 'Перейти',
  name: 'Имя',
  location: 'Местоположение',
  since: 'Начиная с',

  // Моя учетная запись
  updated: 'Update',
  userName: 'ИМЯ ПОЛЬЗОВАТЕЛЯ',
  firstName: 'ИМЯ',
  lastName: 'ФАМИЛИЯ',
  myProfile: 'My Profile',
  changeProfiledetail: 'Измените данные своего профиля здесь',
  userPrivileges: 'User Privileges',
  viewAllDownload: 'View all download history',
  viewDownloadCost: 'View download cost',
  accountManagerInfo: 'Account manager info',
  describeAccountmanager: 'Describe what this account manager do',
  creditsPlan: 'Credits plan',
  myPlan: 'Мой план',
  myOrganisation: 'My organisation plan',
  myAccountProfileDetails:
    'This are your profile details set by the organisation admin. If you wish to change your credentials, kindly contact admin.',
  usePayLater: 'Используй сейчас, плати потом',
  bodyUNPL:
    '«Используй сейчас, плати потом» (UNPL) — это тип краткосрочного финансирования, который позволяет потребителям совершать покупки и оплачивать их в будущем, часто беспроцентно. Также упоминается как «кредиты в рассрочку в точках продаж».',
  firstnameCannotEmpty: 'First name cannot be empty.',
  lastnameCannotEmpty: 'Lastname cannot be empty.',

  noSpecialCharacters: 'This field cannot contain special characters',
  messageUpdateProfile: 'You had successfully updated the user information',
  bodyCredit:
    'Vitae morbi tristique luctus sed eget donec nibh in duis neque donec lectus turpis turpis aliquam facilisis mattis laoreet enim',
  contactYourAccount: 'Contact your account manager for further assistane',
  emailMaxLength75: 'The maximum characters allowed is 75',

  // audio player
  categoryGenre: 'Категория / Жанр',
  duration: 'Длина',
  seeMore: 'Показать больше',
  seeLess: 'Показать меньше',
  audioID: 'Аудио ID',
  // category
  landscape: 'Пейзаж',
  Babies: 'Дети',
  business: 'Бизнес',
  food: 'Еда',
  animals: 'Животные',
  technology: 'Технологии',
  healthcare: 'Здравоохранение',
  nature: 'Природа',
  romance: 'Романтика',
  // category vector
  graphic: 'Графика',
  advertising: 'PubРекламаlicidade',
  flower: 'Цветы',
  abstract: 'Абстрактный',
  Illustration: 'Иллюстрация',
  pop: 'Поп',
  bird: 'Птица',
  pixel: 'Пиксель',
  threeD: '3D',

  // footage category
  education: 'Образование',
  communication: 'Коммуникация',
  beauty: 'Красота',
  concept: 'Концепция',
  fitness: 'Фитнес',
  lifestyle: 'Стиль Жизни',
  celebration: 'Празднование',
  family: 'Семья',
  fashion: 'Мода',

  // Download-history modal
  reDownload: 'Повторная загрузка',
  chooseCustomTagOrCreate: 'Выберите пользовательский тег(и) или создайте новый',
  downloadDetails: 'Сведения о загрузке',
  image: 'Image',
  downloadType: 'Тип загрузки',
  groupName: 'Имя группы',
  size: 'Размер',
  dateDownloaded: 'Дата загрузки',
  reLicense: 'Повторное лицензирование',
  customFields: 'Настраиваемые пол',
  saveAndClose: 'Сохранить и закрыть',
  characterMaxChar100: 'The maximum characters allowed is 100',
  characterNotAllow:
    'The maximum characters allowed is 100 and the minimum characters allowed is 1',
  dlwByMember: 'Dowloaded By Members',
  groupInactive: 'Group inactive',
  personalInactive: 'Personal inactive',
  date: 'ДАТА',
  export: 'Экспортировать',
  selectRange: 'select range',
  fileType: 'file type',
  downloadedLabelUNPL: 'Licensed',
  HRUCTab: 'Бесплатный кредит',
  searchById: 'Искать по ID',
  pleaseSelect: 'Пожалуйста, выберите',
  totalOutstanding: 'Всего неоплаченных',
  viewInvoices: 'Посмотреть счета',
  // Image detail page - select download size sidebar
  standardSizes: 'Стандартные размеры',
  extendedLicenseSizes: 'Extended License Sizes',
  crop: 'Автообрез',
  // empty data
  opsNothingToSeeHere: 'Упс! Здесь ничего нет.',
  // search image
  authentic: 'Аутентичный',
  // search audio
  mostDownloaded: 'Самые загружаемые',
  download: 'Скачать',
  // audio category
  soulAndRB: 'Soul & R&B',
  corporate: 'Корпоративные',
  electronica: 'Электронные',
  classical: 'Классические',
  funkAndGrooveMusic: 'Funk & Groove музыка',
  worldBeat: 'Мировой бит',
  cinematic: 'Кинематографические',
  jazz: 'Джаз',
  flamenco: 'Flamenco',
  mothersDayLove: 'Mothers Day Love',
  happyUplifting: 'Happy Uplifting',
  epicHeroAdventure: 'Epic Hero Adventure',

  // search page
  nextPage: 'Следующая страница',
  reverseImageSearch: 'Обратный Поиск По Изображению',

  group: 'Группа',
  needHelp: 'Нужна помощь?',
  copied: 'Скопировано!',
  copyPortfolioLinkSuccess: 'Ссылка портфеля участников скопирована в буфер обмена.',

  // my activities
  myActivity: 'Моя активность',
  recentLike: 'Мои последние понравившиеся',
  recentView: 'Мои последние просмотренные',
  myActivityViewDesc: 'Недавняя история просмотров будет храниться только до 3 месяцев.',
  myActivityLikeDesc: 'Отображение истории только за последние 3 месяца',

  // dashboard
  profile: 'Профиль',

  // Pricing
  pricingCreditText: 'Кредит',

  // Dashboard - Credit Usage
  user: 'User',
  creditUsage: 'Credit usage',
  searchPlaceholder: 'Search name, username, role, group',
  all: 'ВСЕ',
  active: 'Active',
  deleted: 'Deleted',
  reset: 'Сбросить',
  totalAllocated: 'Total allocated',
  totalSpent: 'Total spent',
  balance: 'Balance',
  groupLowerCase: 'group',
  roleDefinitions: 'Role definitions',
  superAdmin: 'Super admin',
  admin: 'Admin',
  historySearchPlaceholder: 'Search history',
  somethingWentWrong: 'Something went wrong',
  information: 'Information',
  maximumBulkDownloadWarning: 'Bulk download allows maximum 50 items',
  tooltipCustomLogin: 'Please use at least 8 characters',
  maxLengthTitleCustom: 'Title should not be more than 25 characters',
  maxLengthDescCustom: 'Description should not be more than 50 characters',
  selectdItems: 'Selected media',
  aiPoweredSearch: 'Поиск с использованием искусственного интеллекта',
  aiPoweredSearchOn: 'Поиск с искусственным интеллектом включен',
  aiSearchIntroduction:
    'Попробуйте нашу более быструю и мощную поисковую систему. Наш поиск с искусственным интеллектом позаботится обо всем остальном!',
  ok: 'OK',
  // Filter bar ai
  bstract: 'Абстракция',
  macroCloseUp: 'Макро/крупный план',
  object: 'Объект',
  watercolor: 'Акварель',
  flat: 'Плоский',
  cartoon: 'Мультипликационный',
  geometric: 'Геометрический',
  gradient: 'Градиент',
  isometric: 'Изометрический',
  '3d': '3d',
  handDrawn: 'Нарисованный от руки',
  natural: 'Естественный',
  warm: 'Теплый',
  dramatic: 'Драматическое',
  vividAndBold: 'Яркие и смелые',
  blackAndWhite: 'Черное и белое',
  // introduce Free image research
  imageResearchContentTitle:
    'Представляем бесплатный сервис 123RF для поиска изображений: Идеальные изображения, ноль хлопот',
  imageResearchContentDesc:
    'Мы революционизируем поиск изображений для корпоративных клиентов, позволяя без особых усилий найти идеальное изображение и воплотить вашу идею в жизнь.',
  submitRequest: 'Отправить запрос',
  imageResearchContentInfoTitle1: 'Поиск изображений - это просто',
  imageResearchContentInfoDesc1:
    'Начать очень легко. Просто предоставьте нам подробное описание желаемого изображения, включая стиль, настроение, цвета и специфические элементы. Чем больше деталей вы укажете, тем лучше мы сможем удовлетворить ваши потребности.',
  imageResearchContentInfoTitle2: 'Как это работает?',
  imageResearchContentInfoTitle3: 'Быстрый, надежный сервис',
  imageResearchContentInfoTitle4: 'Позвольте нам сделать работу за вас',
  imageResearchContentInfoDesc4:
    'Мы подберем для вас идеальные изображения, чтобы вы могли сосредоточиться на своем проекте. Доверьтесь нам, чтобы воплотить ваше видение в жизнь с помощью высококачественных, тщательно подобранных изображений.',
  requestForm: 'Форма запроса',
  whatDoYouWantThisImageFor: 'Для чего вы хотите использовать это изображение?',
  websiteBanner: 'Баннер для сайта',
  socialMedia: 'Социальная сеть',
  blogPost: 'Пост в блоге',
  advertisement: 'Реклама',
  print: 'Печать',
  others: 'Другое',
  othersOutputPlaceholder: 'Укажите другие типы выходных данных',
  whatIsPrimaryMessage:
    'Какое основное сообщение или тему вы хотите передать с помощью изображения?',
  whatIsPrimaryMessagePlaceholder: 'Например, собака с шерстью бежит рядом с женщиной в парке.',
  areThereSubjectYouWantInThePicture:
    'Какие конкретные объекты или элементы вы хотите включить в изображение?',
  egPeopleNatureTechnologyAbstract: 'Например, люди, природа, техника, абстрактные понятия.',
  areThereImageOrStyleReference:
    'Есть ли какие-либо изображения или стили, которые вы уже видели, и которые вы хотели бы использовать в качестве вдохновения или ссылки?',
  ifPeopleIncludeWhatTheirDemographic:
    'Если необходимо включить людей, то каковы должны быть их демографические характеристики?',
  egAgeGenderEthnicityOccupation: 'Например, возраст, пол, этническая принадлежность, род занятий.',
  areThereAnyThingToAvoid:
    'Есть ли элементы, предметы или темы, которых следует избегать в изображении?',
  whoisYourTargetAudience: 'Кто является вашей целевой аудиторией?',
  whoisYourTargetAudiencePlaceholder: 'Например, молодые люди, которые любят читать.',
  typeOfImage: 'Тип изображения',
  whatMoodShouldImageEvoke: 'Какое настроение или эмоции должно вызывать изображение?',
  happy: 'Счастливый',
  sad: 'Грустный',
  calm: 'Спокойный',
  excited: 'Возбужденный',
  angry: 'Сердитый',
  enterCustomMood: 'Введите собственные пожелания настроения',
  areThereAnyOrientationForImage: 'Нужна ли какая-либо конкретная ориентация изображения?',
  enterCustomOrientation: 'Введите собственную ориентацию',
  isThereLocationYouWantImageToBeIn:
    'Есть ли определенное место или обстановка, в которой вы хотите, чтобы изображение было?',
  no: 'Нет',
  yes: 'Да',
  shareWithUsAReference: 'Предоставьте нам ссылку (только в формате JPG или PDF. Не более 4 МБ)',
  replace: 'Заменить',
  areThereAnySpecificRequirements:
    'Существуют ли какие-либо другие особые требования или предпочтения?',
  enterOtherComments: 'Введите другие комментарии',
  submit: 'Отправить',
  fieldCannotEmpty: 'Это поле не может быть пустым',
  selectAtLeastOne: 'Пожалуйста, выберите хотя бы одно',
  fileMustBeJpgOrPdf: 'Файл должен быть в формате JPG или PDF',
  fileSizeLessThan4MB: 'Размер файла должен быть менее 4 МБ',
  maximumFileNameAllow255: 'Максимально допустимое количество символов - 255',
  updateSuccessfully: 'Мы получили ваш запрос.',
  freeImageResearch: 'Бесплатный поиск изображений',
  newService: 'Новый сервис!',
  freeImageResearchIntroduction:
    'Попробуйте наш сервис по поиску изображений и убедитесь, что наши изображения идеально соответствуют вашим требованиям.',
  later: 'Позже',
  letTryIt: 'Давайте попробуем!',
  viewPdfOfSampleOutput: 'Просмотр PDF образца выходных данных',
  imageResearchContentInfoDesc2: (
    <>
      Наша команда объединяет
      {' '}
      <strong>технологии искусственного интеллекта</strong>
      {' '}
      и опыт, чтобы
      найти для вас идеальные изображения, а передовые алгоритмы сужают круг вариантов из нашей
      обширной библиотеки на основе ваших данных. Наша
      {' '}
      <strong>команда опытных и преданных своему делу ресечеров</strong>
      {' '}
      дорабатывает результаты,
      чтобы они полностью соответствовали вашим критериям.
    </>
  ),
  imageResearchContentInfoDesc3: (
    <>
      Мы предоставляем результаты в течение
      {' '}
      <strong>2 рабочего дня</strong>
      , давая исчерпывающие
      пояснения к выбранным изображениям. Сосредоточьтесь на своем бизнесе, а мы позаботимся обо
      всем остальном.
    </>
  ),
  characterMaxChar255: 'Максимально допустимое количество символов - 255',

  imageResearchContentInfoDesc2Normal1: 'Our team combines',
  imageResearchContentInfoDesc2Strong1: 'AI technology',
  imageResearchContentInfoDesc2Normal2:
    'and experience to find ideal images for you and advanced algorithms narrow down options from our vast library based on your input. Our',
  imageResearchContentInfoDesc2Strong2: 'team of dedicated and experienced researchers',
  imageResearchContentInfoDesc2Normal3: 'refine the results to meet your criteria perfectly.',
  imageResearchContentInfoDesc3Normal1: 'We deliver results within',
  imageResearchContentInfoDesc3Strong1: '1 working day',
  imageResearchContentInfoDesc3Normal2:
    'providing a comprehensive explanation for image selections. Focus on your business while we take care of the rest.',
  office: 'Office',
  beach: 'Beach',
  city: 'City',
  mountains: 'Mountains',
  enterCustomLocation: 'Enter custom location',
  doYouNeedImgWithSpaceForTextOrOtherElement:
    'Do you need an image with space for text or other elements? If so, where should this space be located?',
  center: 'Center',
  topLeft: 'Top left',
  topRight: 'Top Right',
  bottomLeft: 'Bottom Left',
  bottomRight: 'Bottom Right',
  characterMin30: 'A minimum of 30 characters is required',
  required: 'требуется',

  relatedSearch: 'Related searches',
  showMore: 'Показать больше',
  showLess: 'Показать меньше',

  requiredWithVariable: 'требуется "{{var}}"',
  mood: 'Настроение',
  person: 'человек',
  doNotIncludeWords_ex2: 'Например, зеленый цвет, прямые волосы',

  // Text to Image TTI page
  /// Landing page
  aiImageGenerator: 'Генератор изображений ИИ',
  aiImageGeneratorDesc: 'Обеспечивает креативность со скоростью вашего воображения. ',
  generate: 'Генерирровать',
  exploreVisualsCreatedWithThePowerOfAI:
    'Исследуйте визуальные эффекты, созданные с помощью искусственного интеллекта',
  turnYourImaginationIntoRealityIn3SimpleSteps:
    'Превратите свое воображение в реальность за 3 простых шага',
  startWithGoodTextPrompt: (
    <>
      Начните с
      {' '}
      <span>
        хорошей
        <br />
        текстовой подсказки
      </span>
    </>
  ),
  startWithGoodTextPromptDesc:
    'Хорошая подсказка гарантирует отличный результат. Для достижения наилучших результатов подсказка должна включать перспективу, тему, действие, ключевые слова, освещение и стиль.',
  reviewYourAIGeneratedImages: (
    <>
      Просмотрите свои изображения,
      {' '}
      <br />
      <span>созданные искусственным интеллектом</span>
    </>
  ),
  reviewYourAIGeneratedImagesDesc:
    'Наш генератор искусственного интеллекта сгенерирует и покажет 3 предварительных изображения, которые наиболее точно соответствуют вашему запросу.',
  downloadAndLicenseVisuals: (
    <>
      Загружайте и лицензируйте
      {' '}
      <br />
      <span>визуальные материалы</span>
    </>
  ),
  downloadAndLicenseVisualsDesc:
    'Теперь вы можете загружать и лицензировать эти изображения для личного и коммерческого использования с юридическим покрытием в размере 25 000 долларов США.',
  needSomeIdeas: 'Нужны идеи? Начните с этих образцов и генерируйте свои идеи!',
  tryPrompt: 'Попробуйте воспользоваться подсказкой',
  creativityAtTheSpeedOfYourImagination: 'Творчество на скорости вашего воображения',
  genYourOwnAIImgFromTxtPromptsWCorpInSeconds:
    'Создавайте собственные ИИ-изображения на основе текстовых подсказок с помощью Corporate+ за считанные секунды!',
  tryNow: 'Попробуйте сейчас',

  /// Random prompt
  prompt1:
    'Фотореалистичный портрет астронавта, парящего в космосе, с Землей на заднем плане. Используйте камеру с объективом 200 мм при диафрагме F 1,2, чтобы размыть фон и выделить объект съемки. Освещение должно быть драматичным и мечтательным, солнце должно освещать лицо и скафандр астронавта. Включите режим фотореализма, чтобы создать ультрареалистичное изображение, передающее необъятность и красоту космоса.',
  prompt2:
    'Произведение смешанной техники, изображающее заклинателя в драматической позе, со струящимся плащом и волшебной палочкой, испускающей искры магии, на фоне мистического и потустороннего пейзажа, с использованием различных текстур и материалов.',
  prompt3:
    'Игривая цифровая иллюстрация малыша с румяными щечками и в пушистом комбинезоне, окруженного разноцветными воздушными шарами и конфетти, на веселом фоне пастельных тонов',
  prompt4:
    'Графитовый рисунок слепого провидца с третьим глазом на лбу, вглядывающегося вдаль с безмятежным и мистическим выражением лица, со сложными деталями и штриховкой.',
  prompt5:
    'Сюрреалистический коллаж из смешанных материалов, изображающий ковбоя верхом на гигантском механическом быке в пустынной пустоши, сделанный из винтажных журналов, старых карт и ржавых металлических обрезков, с оттенком юмора и абсурда.',
  prompt6:
    'Цифровая иллюстрация королевы вампиров с бледной кожей и кроваво-красными глазами, в струящемся платье и в окружении летучих мышей.',
  prompt7:
    'Портрет слепого провидца, держащего хрустальный шар, снятый при драматическом освещении кьяроскуро, чтобы подчеркнуть черты лица провидца и отражения в хрустале.',
  prompt8:
    'Фантазийная иллюстрация королевы-русалки с длинными волосами и короной из ракушек, плавающей в мистическом океане, наполненном биолюминесцентными существами.',
  prompt9:
    'Уличный портрет испаноязычного уличного торговца, продающего фрукты и овощи, снятый в откровенном и зернистом черно-белом цвете с малой глубиной резкости, чтобы подчеркнуть выражение лица торговца.',
  prompt10:
    'Причудливая акварельная картина с изображением русалки, отдыхающей на скалистом берегу в окружении сверкающих океанских волн и игривых морских обитателей.',
  prompt11:
    'маленькая девочка в стиле 3d, 4k, 8k, octane render photorealistic, hdr, фотография, высокое разрешение, симметричное лицо, объемное освещение, пыльная дымка, фото, octane render, 24mm, 4k, 24mm, DSLR, высокое качество, 60 fps, ультра реалистичный',
  prompt12:
    'Гостиная с французскими окнами, выходящими в сад, сервант в стиле прованс, растения, джутовый ковер, паркет, диваны с подушками сверху, хрустальная люстра-капля, журнал по дизайну интерьера, теплая атмосфера, фотореалистично, реалистичный свет, широкий угол, много деталей',
  prompt13:
    'Живописная горная хижина на склоне горы, большие панорамные окна, геометрическая хижина, полный вид, живописный вид в сумерках, драматическое сумеречное небо, вершины под снегом, звезды, заснеженная горная пустыня, красивый вид, настроение, туман, объемное освещение, атмосфера гор, фотореализм, объемное освещение, реалистичный, октановый рендер, ультра детализированный, сюрреалистичный, фотореалистичный, ультрареалистичный, 8k, кинематографическое освещение',
  prompt14:
    'Акварельная картина с изображением крутой бабушки, она улыбается, потому что знает, что она крутая',
  prompt15:
    'Дорога посреди темно-синего леса, густой лес, потолок из листьев, ночью, стиль комиксов, детская иллюстрация',
  prompt16:
    'Астронавт, плывущий через туманность в космосе + космос, астронавт, мечтательный, символизм, сюрреализм, символ, сюрреалистический, абстрактный, текстура, концепт-арт, 8k, затененный, лучи света, атмосферный',

  /// Tool list
  // ratio
  ratio: 'Соотношение',
  square1_1: 'Квадрат (1:1)',
  square: 'Квадрат',
  portrait2_3: 'Портрет (2:3)',
  portrait: 'Портрет',
  landscape3_2: 'Горизонталь (3:2)',
  wide16_9: 'Широкоформатный (16:9)',
  wide: 'Широкоформатный',
  // style
  none: 'Нет',
  enhance: 'Улучшенный',
  anime: 'Аниме',
  photographic: 'Фотографический',
  digitalArt: 'Цифровое искусство',
  comicBook: 'Комикс',
  fantasyArt: 'Фэнтези',
  analogFilm: 'Аналоговый фильм',
  neonPunk: 'Неон панк',
  lowPoly: 'Низкополигональный',
  origami: 'Оригами',
  lineArt: 'Пиксельное искусство',
  tileTexture: 'Текстура плитки',
  threeDModel: '3D модель',
  pixelArt: 'Пиксельное искусство',
  craftClay: 'Крафтовый',
  // color
  coolTone: 'Холодный тон',
  mutedColors: 'Приглушенные цвета',
  pastelColors: 'Пастельные тона',
  vibrantColors: 'Яркие цвета',
  warmTone: 'Теплый тон',
  // lighting
  lighting: 'Освещение',
  backlight: 'Подсветка',
  crepuscularRays: 'Полутеневые лучи',
  dimlyLit: 'Тусклое освещение',
  goldenHour: 'Золотой час',
  lowLight: 'Слабый свет',
  rimLighting: 'Окантовочное освещение',
  studio: 'Студия',
  sunlight: 'Солнечный свет',
  volumetric: 'Объемное',
  // composition
  composition: 'Состав',
  blurryBackground: 'Размытый фон',
  closeUp: 'Крупный план',
  macrophotograph: 'Макрофотография',
  narrowDepth: 'Узкая глубина',
  shotFromAbove: 'Снимок сверху',
  shotFromBelow: 'Снимок снизу',
  wideAngle: 'Широкий угол',

  /// FQAs
  allAIImageQuestionsAnswered:
    'Ответы на все ваши вопросы об изображениях, созданных искусственным интеллектом.',
  TTI_Q1: 'Что такое изображения, созданные ИИ?',
  TTI_A1:
    'Изображения, сгенерированные искусственным интеллектом, - это изображения, созданные с помощью алгоритмов искусственного интеллекта (ИИ). Эти алгоритмы предназначены для имитации человеческого творчества и могут создавать изображения, похожие на те, которые создают художники-люди, но с уникальными, часто сюрреалистическими или абстрактными элементами.',
  TTI_Q2:
    'Существует ли ограничение на количество изображений, которые я могу генерировать в день?',
  TTI_A2:
    'Да, существует ограничение на количество изображений, которые вы можете генерировать ежедневно. Это ограничение гарантирует, что все пользователи будут иметь справедливый доступ и хороший пользовательский опыт при обращении к услуге генерации изображений ИИ.',
  TTI_Q3: 'Do you support text prompts in languages other than English?',
  TTI_A3: 'We support only the English language at the moment.',
  TTI_Q4: 'В чем разница между созданием и лицензированием изображения?',
  TTI_A4:
    'При генерации изображения создается набор ИИ-изображений для просмотра. При лицензировании на Corporate+ вы получаете официальный доступ к загрузке версии ИИ-изображения с высоким разрешением и собственной лицензией на использование.',
  TTI_Q5:
    'Созданное мной изображение содержит узнаваемый товарный знак, достопримечательность или публичную личность. Могу ли я по-прежнему использовать его?',
  TTI_A5:
    'Если на изображении присутствует узнаваемый человек или предмет, или оно в основном посвящено какой-либо достопримечательности, необходимо получить разрешение непосредственно у лица, изображенного на изображении, особенно если вы собираетесь использовать изображение в коммерческих целях.',
  TTI_Q6: 'Кто эти люди, которые появляются на созданных изображениях?',
  TTI_A6:
    'Люди, которые появляются на изображениях, созданных искусственным интеллектом, - это не настоящие люди, а цифровые творения.',
  TTI_Q7: 'Что произойдет с созданным мной контентом? Будут ли они доступны другим пользователям?',
  TTI_A7:
    'Созданный вами контент может быть доступен и другим пользователям. Для получения дополнительной информации вы можете обратиться к нашей лицензии.',
  TTI_Q8: 'Почему, когда я пытаюсь сгенерировать изображение, оно долго загружается?',
  TTI_A8:
    'Для генерации изображения странице потребуется несколько секунд. Затраченное время зависит от сложности запроса, доступных аппаратных и вычислительных ресурсов, а также от количества запросов, которые ИИ должен обрабатывать одновременно.',
  TTI_Q9: 'Как я могу лицензировать созданные мной изображения ИИ?',
  TTI_A9:
    'Вы сможете лицензировать созданные вами изображения ИИ, загрузив их из своей учетной записи Corporate+. При каждой загрузке вы получаете доступную версию созданного изображения с высоким разрешением и юридическую гарантию в размере 25 000 долларов США.',
  TTI_Q10: 'Могу ли я просмотреть прошлые генерации?',
  TTI_A10:
    'Да, вы можете сделать это, добавив в закладки и повторно просматривая URL-адреса ваших прошлых генераций. Вы также можете поделиться результатами генераций со своими друзьями.',
  TTI_Q11: 'Как создавать высококачественные изображения с помощью искусственного интеллекта?',
  TTI_A11: `1. Начните с четкой цели: "Высококачественное изображение горного пейзажа для блога о путешествиях".

2. Укажите желаемый стиль и настроение: "Изображение должно быть ярким и живым, с естественным, органическим настроением".

3. Опишите тематику: Укажите предмет изображения, включая такие детали, как обстановка, освещение и любые другие важные особенности или элементы. Например, "Изобразите заснеженную горную вершину с извилистой рекой на переднем плане и ясным голубым небом на заднем плане".

4. Приведите примеры: Приведите примеры других изображений, которые имеют схожий стиль или настроение с тем, которое вы ищете. Это поможет направить алгоритм ИИ и убедиться, что результат соответствует вашим ожиданиям.

5. Используйте релевантные ключевые слова: "горный пейзаж", "заснеженная вершина" или "яркий и энергичный".

6. Избегайте двусмысленности: Будьте максимально четкими и конкретными при предоставлении инструкций, избегайте использования расплывчатых или двусмысленных формулировок, которые могут привести к неожиданным результатам.`,

  /// Generating page
  bringingYourImagesToLife: 'Оживить ваши изображения...',
  cplusAiGenerator: 'ИИ Генератор',
  generatedImages: 'Сгенерированные изображения',
  promptSettings: 'Настройки подсказок',
  pastGeneration: 'Прошлые генерации',
  generateVariation: 'Сгенерировать вариацию',
  iLikeThis: 'Мне нравится это',
  iDislikeThis: 'Мне не нравится это',
  shareOptions: 'Делитесь вариантами',
  variations: 'Вариации',
  copiedSharedUrl: 'Общий URL-адрес скопирован в буфер обмена',

  galleryPrompt1: 'Эфирная скромная русалка, под водой, корона из ракушек в окружении рыб, портрет',
  galleryPrompt2: 'сытное воскресное жаркое на столе, много еды и свечей',
  galleryPrompt3: 'Симпатичный пушистый лисенок улыбается в лесу, высокая детализация, увеличенное изображение с другими лесными животными',
  galleryPrompt4: 'высокое качество, 8K Ultra HD, музыкальные ноты, формы инструментов внутри тыквы из хрусталя, высокая детализация, волшебная лампа из тыквы, ощущение осени, спокойствие',
  galleryPrompt5: 'Шотландия панорамный кинематографический пейзаж, яркий красивый день',
  galleryPrompt6: 'Маленькая девочка в стиле 3d, 4k, 8k, octane render photorealistic, hdr, photography, high definition, симметричное лицо, объемное освещение, пыльная дымка, фото, octane render, 24mm, 4k, 24mm, DSLR, high quality, 60 fps, ultra realistic',
  galleryPrompt7: 'смуглокожий парень, герой, бьющий по планете Юпитер в космосе, лицом вперед',
  galleryPrompt8: 'печенье в теплом кофе, в рождественском окружении',
  galleryPrompt9: 'Художественная бутылка с водой на белом минималистском фоне с листьями Монтсерра',
  galleryPrompt10: 'Эвансентный алмаз фэнтези дракон',
  galleryPrompt11: 'планеты в каплях росы, планетарные капли росы, на восходе солнца, крупный план, гипердетализация, резкий фокус, студийная фотография, сложные детали, высокая детализация',
  galleryPrompt12: 'сорока, сидящая на сундуке с золотом, в стиле реалистического фэнтези, драгоценный камень, темные сказки, таинственное',
  galleryPrompt13: 'гора снега и ледяной след на вершине горы, идущий в другую сторону, ultra hd, реалистичный, яркие цвета, высокая детализация, рисунок UHD, перо и тушь, идеальная композиция, красивый детальный сложный безумно детальный октановый рендер, тренды на artstation, художественная фотография 8k, фотореалистичный концепт-арт, мягкий естественный объемный кинематографический идеальный свет',
  galleryPrompt14: 'Межзвездная цивилизация эпохи, стоя высоко вниз с видом на таинственный цифровой космос супер sci-fi инопланетных зданий города, Изысканный красивый экстерьер супер небоскреб башня здания, улица, небо мост, небо плавает супер sci-fi архитектуры, военная машина, высокие технологии, бесчисленные электронные подсветки экрана, супернаучный визуальный праздник космос, научная фантастика, тайна, научная фантастика, сюрреализм, сверхчеткие фотографии, HD пиксели, ультра-HD, 5D, 8K разрешение, идеальные пиксели, идеальные сверхтонкие детали, резкий фокус, студийная фотография, сложные детали, высокая детализация',
  galleryPrompt15: 'Красивый пляж с океаном на заднем плане во Флорида-Кис ночью, неоновые цвета, черный фон, спокойные волны, естественный оттенок, закат, спокойствие, расслабляющее увеличение волн, пляж, выпрямленный горизонт, розовый, голубой, оранжевый, фиолетовый',
  galleryPrompt16: 'лучшее качество, двойная экспозиция, мейл арт, арт на потрескавшейся бумаге, крупный план, милый котенок играет с клубком шерсти, детальный интерьер уютной комнаты, блеск, солнечный свет, солнечные лучи, динамичная поза, иллюстрация из книги, 2d, плоский, милый, очаровательный, винтаж, сказка, пэчворк, витраж, детальная иллюстрация из книги, кинематографический, сверхвысокая детализация, мелкие детали, красивые детали, мистика, яркие цвета, сложный фон',
  galleryPrompt17:
    'пирамида на воде с огненными отблесками, горы, мрачный сюрреализм, атмосферные портреты, резкий фокус',
  galleryPrompt18: 'красная панда в лесу, кинематографично',
  galleryPrompt19: 'море, луна, рыбы, звездное небо, лодка, красочная иллюстрация',
  galleryPrompt20:
    'Яркие городские отражения танцуют на фоне жирных зданий и мерцающей воды в минималистском абстрактном стиле, передавая живую энергию вечера, геометрическая абстракция, современный стиль поп-арт с неоновым освещением и техникой акриловой заливки',
  galleryPrompt21:
    'Вид сзади на силуэт женщины со светящимся мечом, круговой портал в Арктике, ведущий к айсбергам, кинематографический средний снимок, монументальная фигура',

  /// error page
  copyrightDetected:
    'Обнаружены термины/персоналии или торговые марки, защищенные авторским правом',
  copyrightDetectedDesc:
    'Мы стремимся быть ответственным генератором искусственного интеллекта и не будем выдавать результаты по защищенным авторским правом торговым маркам, терминам, брендам или персоналиям, включенным в подсказки, чтобы предотвратить потенциальное нарушение прав. Наши условия предоставления услуг предусматривают, что вы НЕ должны использовать услуги таким образом, чтобы нарушать или незаконно присваивать любые права интеллектуальной собственности. Пожалуйста, внесите изменения в подсказку и повторите попытку.',
  errorMesTTI: 'Упс! Мы сожалеем, но что-то пошло не так.',
  errorMesTTIDesc:
    'Проблема была зарегистрирована для расследования. Пожалуйста, повторите попытку позже.',
  tryAgain: 'Попробовать еще раз',
  // hruc bulk download
  hrucBulkDownloadTitle: 'HRUC bulk download',
  hrucDownload: 'HRUC download',
  hrucBulkdownloadNotSupported: 'HRUC bulk download is not supported',
  // download feature
  downloadQuotaFinished:
    'You have reached the maximum quota available. Please contact your system administrator.',

  // search bar placeholder
  searchBarPlaceholderAllImageDesktop: 'Найти потрясающие изображения. Поиск по ключевым словам или фразам.',
  searchBarPlaceholderAllImageTablet: 'Найти потрясающие изображения.',
  searchBarPlaceholderAllImageMobile: 'Найти изображения.',
  searchBarPlaceholderPhotoDesktop: 'Найти потрясающие фото. Поиск по ключевым словам или фразам.',
  searchBarPlaceholderPhotoTablet: 'Найти потрясающие фото.',
  searchBarPlaceholderPhotoMobile: 'Найти фото.',
  searchBarPlaceholderVectorDesktop: 'Найти потрясающие векторы. Поиск по ключевым словам или фразам.',
  searchBarPlaceholderVectorTablet: 'Найти потрясающие векторы.',
  searchBarPlaceholderVectorMobile: 'Найти векторы.',
  searchBarPlaceholderFootageDesktop: 'Найти потрясающие видео. Поиск по ключевым словам или фразам.',
  searchBarPlaceholderFootageTablet: 'Найти потрясающие видео.',
  searchBarPlaceholderFootageMobile: 'Найти видео.',
  searchBarPlaceholderAudioDesktop:
    'Найти потрясающие аудио и музыкальные файлы. Поиск по ключевым словам или фразам.',
  searchBarPlaceholderAudioTablet: 'Найти потрясающие аудио и музыкальные файлы.',
  searchBarPlaceholderAudioMobile: 'Найти аудио и музыкальные файлы.',
  searchBarPlaceholderFreeDesktop: 'Найти потрясающие бесплатные изображения. Поиск по ключевым словам или фразам.',
  searchBarPlaceholderFreeTablet: 'Найти потрясающие бесплатные изображения.',
  searchBarPlaceholderFreeMobile: 'Найти бесплатные изображения.',
  // editorial warning
  forEditorialUseOnly: 'Только для редакционного использования Права собственности на логотип',
  forEditorialUseOnlyDesc:
    'Использование этого изображения в рекламе или рекламных целях запрещено без дополнительных разрешений',

  // download comp
  downloadHiResComp: 'СКАЧАТЬ ХАЙРЕЗ',
  downloadComp: 'СКАЧАТЬ ПРЕВЬЮ',

  generateTerm: ({ callback }) => (
    <>
      By clicking “Generate”, you hereby agree that the generated Output is solely governed by the
      {' '}
      <span aria-hidden onClick={callback}>
        AI Generator Terms of Service.
      </span>
      {' '}
    </>
  ),

  textToImage: 'Текст в изображении',
  descTourTTI: 'Дайте волю своему воображению и создавайте изображения с текстом!',
  colorAndTone: 'Цвет и тон',
  titleSuccessDownload: 'Мы рады, что вы нашли то, что вам нужно.',
  hangInThere: 'Разместить там! Это не займет много времени.',
  adultContentTitle: 'Обнаружены термины, потенциально содержащие контент для взрослых',
  adultContentDesc:
    'Мы стремимся к ответственному подходу к искусственному интеллекту и поэтому не генерируем результаты для некоторых фраз, концепций и/или идей. Пожалуйста, измените запрос (описание, которое вы предоставили для создания изображения) и повторите попытку.',
  reachedTheLimit: 'О нет! Вы достигли лимита для генерации изображений ИИ на сегодня.',
  backTomorrow: 'Приходите завтра за новыми генерациями!',
  generateTermService: 'AI Generator Terms of Service',
  endUserLicenseAgreement: 'End user license agreement',
  aiPromptPlaceholder: 'Для начала опишите свое видение здесь, и мы воплотим его в жизнь!',

  downloadAgain: 'Download again',
  btnClose: 'Закрыть',

  variousTitle: 'Откройте для себя наши различные модели',
  variousSDXL: 'Оживите людей и сцены с потрясающей фотореалистичностью.',
  variousEimisAnime:
    'Генеративная модель искусственного интеллекта, предназначенная для создания изображений в стиле аниме.',
  variousDynaVisionXL: 'Создание трехмерных стилизованных мультипликационных персонажей.',
  variousJuggernautXL: 'В центре внимания - архитектурные элементы и пейзажи/фоны.',
  variousDreamShaperXL:
    'Масштабирование в высоком разрешении, улучшенные поколения персонажей, уменьшенное размытие краев.',
  variousRealismEngineSDXL:
    'Эта модель специализируется на создании высокодетализированных, реалистичных изображений.',
  variousDALLE:
    'DALL-E 3 - универсальная модель, обеспечивающая точную трансформацию концепции в визуальные образы.',
  aiEngine: 'Двигатель ИИ',
  'DALL-E': 'DALL-E 3',

  langText: 'Текст',
  langElements: 'Элементы',
  langAuto: 'Авто',
  langBgRemoval: 'Удаление фона',
  resetAll: 'Сбросить все',
  facebookImage: 'Изображение в Facebook',
  facebookLink: 'Ссылка на Facebook',
  facebookCover: 'Обложка Facebook',
  facebookStory: 'История Facebook',
  instagramPost: 'Пост в инстаграм',
  instagramStory: 'История в инстаграм',
  twitterPost: 'Сообщение в Twitter',
  twitterHeader: 'Заголовок Twitter',
  pinterestPost: 'Сообщение Pinterest',
  emailHeader: 'Заголовок электронного письма',
  presentation: 'Презентация',
  presentationWide: 'Презентация широкая',
  ebookCover: 'Обложка электронной книги',
  linkedinPost: 'Сообщение в LinkedIn',
  brochureCover: 'Обложка брошюры (A4)',
  postcard: 'Открытка',
  photoPrint: 'Печать фотографий',
  youtubeProfileImage: 'Изображение профиля YouTube',
  youtubeChannelCoverPhoto: 'Фотография обложки канала YouTube',
  langApplyBgRemoval: 'Применить удаление фона',
  autoBnW: 'Auto B/W',
  autoAdjust: 'Auto Adjust',
  autoPop: 'Auto Pop',
  original: 'Original',
  addHeader: 'Добавить заголовок',
  addTitle: 'Добавить заголовок',
  addBody: 'Добавить основной текст',
  addAssistive: 'Добавьте вспомогательный текст',
  langCompare: 'Сравнить',
  langEnableEditor: 'Редактировать изображение',
  langSimilarStock: 'Похожие стоковые изображения',
  langTextUndo: 'Отменить',
  langTextRedo: 'Повторить',
  langTextFont: 'Шрифт',
  langTextFontSize: 'Размер шрифта',
  langTextColor: 'Цвет текста',
  langTextHighlight: 'Выделять',
  langTextAlignment: 'Выравнивание',
  langTextBold: 'Прерывистый',
  langTextItalic: 'Курсив',
  langTextUnderline: 'Подчеркнутый',
  langTextLineSpacing: 'Межстрочный интервал',
  langTextLetterSpacing: 'Межбуквенное расстояние',
  langBringForward: 'На передний план',
  langBringBackward: 'На задний план',
  langDuplicate: 'Дублировать',
  langOpacity: 'Непрозрачность',
  langDelete: 'Удалить',
  imageProperties: 'Свойства изображения',
  noEditCompareMsg: 'Для сравнения не было сделано никаких правок.',
  stockPhoto: 'Фото со стока',
  basicShape: 'Основная форма',
  chitChat: 'Чат',
  shapes: 'Формы',
  elementChitChat: 'Элемент чата',
  organicAbstractShapes: 'Органические абстрактные формы',
  elementShapes: 'Элемент форм',
  langApplyingEdit: 'Применение правок...',
  langTextAllFonts: 'Все шрифты',
  langSearchFontType: 'Искать шрифт',
  editorCompareImageBeforeTitle: 'До',
  editorCompareImageAfterTitle: 'После',
  langSizePx:
    'Пиксель - это основная единица цифрового изображения. Размер изображения, измеряемый в пикселях, определяет уровень детализации и размер загрузки.',
  langSizeDpi:
    'Количество точек на дюйм определяет разрешение печати. Более высокое dpi означает большую детализацию. Для печати рекомендуемое разрешение для всех изображений составляет 300 dpi.',
  cautionTTI:
    'Предупреждение: Мы обнаружили использование в подсказке фраз, связанных с торговыми марками, авторскими правами, известными достопримечательностями и/или личностями.  Обратите внимание, что вы несете полную ответственность за использование Контента, в том числе за то, чтобы он не нарушал действующее законодательство и не ущемлял никаких прав.',

  addImageReference: 'Добавить ссылку на изображение (необязательно)',
  uploadImageOnly: 'Загружайте только изображения в формате JPG или PNG. Не более 4МБ.',
  dragAndDrop: 'Перетащите файл или',
  errorFileSize: 'Этот файл слишком велик. Максимально допустимый размер - 4МБ.',
  adjustScale: 'Настройте этот масштаб, чтобы получить очень похожие или отличные от загруженного изображения результаты. Рекомендуемый диапазон - от 7 до 15.',
  similar: 'Похожие',
  different: 'Другое',
  uploadNew: 'Загрузить новое',

  imageGuideGeneration: 'Ваш образ будет направлять ваши подсказки во время генерации.',
  notAvailableForEngine: 'Недоступно для выбранного движка AI Engine.',

  imagePromptDescribe: 'Для начала дайте подсказку, какой образ вы хотите, чтобы сгенерировал наш ИИ, описав то, что у вас на уме.',
  fileTypeNotSp: 'Тип файла не поддерживается.',
  theFileSizeTooSmall: 'Размер файла слишком мал. Допустимый минимальный размер 512px.',

  relatedSearchPhoto: 'Похожие запросы стоковых фотографий',

  STOCK_PHOTO_PATH: 'Фото-со-стока',
  STOCK_FOOTAGE_PATH: 'stock-footage',
  STOCK_AUDIO_PATH: 'stock-audio',
  CLIPART_VECTOR_PATH: 'Векторы',
  ALL_IMAGES: 'Все изображения',
  PHOTO: 'Photo',
  PHOTOS: 'Фото',
  VECTORS: 'Векторы',
  FOOTAGE: 'Видеосюжеты',
  AUDIO: 'Аудио',
  LANG_MORETHANMILLION: 'СТОКОВЫЕ ФОТОГРАФИИ РОЯЛТИ ФРИ',
  LANG_SVG_OR_EPS: 'SVG Или EPS',
  LANG_OR: 'Или',
  STANDARD_LICENSE: 'Стандартная лицензия',
  EXTENDED_LICENSE: 'Расширенная лицензия',
  CREDIT_PACK: 'Пакет кредитов',
  DOWNLOAD_PACK: 'Пакеты',
  SUBSCRIPTION_PLAN: 'Подписка',
  LANG_DOWNLOAD: 'Скачать',
  STOCKPHOTO: 'Фото со стока',
  STOCK_PHOTO: 'Стоковая фотография',
  STOCK_VECTOR: 'Стоковый вектор',
  PREMIUM_STOCKPHOTO: 'PREMIUM Стоковые Фотографии',
  PLUS_STOCK_PHOTO: 'Стоковые Фотографии PLUS',
  PLUS_STOCK_VECTOR: 'Стоковые Векторы PLUS',
  MOBILE_STOCKPHOTO: 'Мобильные стоковые фотографии',
  STOCKFOOTAGE: 'Стоковые видеозаписи',
  IMAGEID: 'ID изображения',
  FONTID: 'ID',
  MEDIATYPE: 'Тип носителя',
  COPYRIGHT_TEXT: 'Авторское право',
  KEYWORDS: 'Ключевые слова',
  LANG_CANCEL: 'Отменить',
  LANG_PURCHASE: 'Приобрести',
  LANG_STOCKVECTORSANDILLUS: 'Набор Векторов и Иллюстраций',
  LANG_STOCKPHOTOSANDIMAGES: 'Стоковые фото, картинки и изображения',
  LANG_MATCHES: 'совпадениям',
  LANG_VECTORRESULTS: '{number} {keyword} royalty-free векторных изображений найдено для Вас.',
  LANG_PHOTORESULTS: '{number} {keyword} royalty-free стоковые фотографий и изображений найдено для Вас.',
  LANG_SIMILARIMGS: 'Похожие Royalty-Free фотографии',
  LANG_COLLECTIONS: 'Коллекции',
  LANG_ALL: 'Все',
  LANG_STD: 'Стандартная',
  LANG_EXTD: 'Расширенная',
  LANG_COMMERCIAL: 'Коммерческая',
  LANG_SUBSCRIPTION: 'Подписка',
  LANG_EDITORIAL: 'Редакционное',
  LANG_UPLOADED: 'Uploaded',
  LANG_FRESHNESS: 'Новинки',
  LANG_ANYTIME: 'За всё время',
  LANG_2DAYS: '2 days',
  LANG_PAST2DAYS: 'За 2 дня',
  LANG_PASTWEEK: 'За неделю',
  LANG_PRICE_PLUS_MONTH: "<span class='price'>{price}</span> долларов в месяц",
  LANG_PRICE_PLUS_YEAR: "<span class='price'>{price}</span> в год",
  LANG_PASTMONTH: 'За месяц',
  LANG_PAST3MONTHS: 'За 3 месяцa',
  LANG_NUMOFPPL: 'Количество людей',
  LANG_NOT_RECEIVE_VERIFICATION_EMAIL: 'Разве не получили электронное письмо с проверкой?',
  LANG_WITHOUTPEOPLE: 'Без людей',
  LANG_PERSON: 'человек',
  LANG_PEOPLE: 'людей',
  LANG_INFANTS: 'Младенцы',
  LANG_TODDLERS: 'Toddlers',
  LANG_TEENS: 'Подростки',
  LANG_20S: '20s',
  LANG_30S: '30s',
  LANG_40S: '40s',
  LANG_50S: '50s',
  LANG_60S: '60s',
  LANG_70S: '70 +',
  LANG_CAUCASIAN: 'Европеоиды',
  LANG_BLACK: 'Чернокожие',
  LANG_HISPANIC: 'Латиноамериканцы',
  LANG_EAST_ASIAN: 'Уроженцы Восточной Азии',
  LANG_SOUTHEAST_ASIAN: 'Уроженцы Юго-Восточной Азии',
  LANG_INDIAN: 'Indian',
  LANG_MIDDLE_EASTERN: 'Уроженцы Ближнего Востока',
  LANG_AGE: 'Возраст',
  LANG_ALL_SHAPE: 'Смотреть все',
  LANG_ETHNICITY: 'Этническая принадлежность',
  LANG_PEOPLEORMORE: '4 человека или больше',
  LANG_COLOR: 'Цвет',
  LANG_OTHEROPTION: 'Другие Настройки',
  SOLID_OR_TRANSPARENT_BACKGROUNDS: 'Сплошной/прозрачный фон',
  LANG_FILTER_BY: 'Фильтровать по',
  LANG_FILTER: 'Фильтр',
  LANG_PHOTOGRAPHY: 'Фотография',
  LANG_VECTORILLUST: 'Векторная Иллюстрация',
  LANG_SORTBY: 'Сортировать по',
  LANG_MORE: 'Больше',
  LANG_RELEVANCE: 'значимость',
  LANG_RESEND: 'Отправить',
  LANG_RESEND_VERIFICATION: 'Отправить письмо с подтверждением',
  LANG_NEWEST: 'Новый',
  LANG_GEORANK: 'Georank',
  LANG_ORIENTATION: 'Ориентация',
  LANG_HORIZONTAL: 'Горизонтальный',
  LANG_PORTRAIT: 'Портрет',
  LANG_SQUARE: 'Квадратный',
  LANG_PANORAMA: 'Панорама',
  LANG_SELECTIVE_FOCUS: 'В фокусе',
  LANG_PATTERN: 'Повторяющийся',
  LANG_VIBRANCE: 'Насыщенный ',
  LANG_VERIFICATION_DESCRIPTION: 'Вам нужно будет проверить свою учетную запись перед загрузкой контента с 123RF. Пожалуйста, проверьте свою электронную почту или нажмите на ссылку, указанную ниже, если вы хотите его отправить.',
  LANG_VERIFICATION_TITLE: 'Пожалуйста, проверьте свою электронную почту.',
  VERIFICATIONRESETUNABLE: 'Упс! Ваше письмо не удалось отправить. Пожалуйста, свяжитесь с нашей ',
  LANG_CHOOSEUPTO3COLORS: 'Вы можете выбрать до 3 цветов',
  LANG_ISOLATED: 'Изолировано',
  LANG_STYLE: 'Style',
  LANG_SAFESEARCH: 'Безопасный поиск',
  LANG_ONLY_AUTHENTIC: 'Только достоверные',
  LANG_EXCLUDE_PLACEHOLDER: 'ex. cheese',
  LANG_EXCLUDEWORDS: 'Не включать эти слова',
  LANG_EXCLUDE: 'Исключать',
  LANG_INCLUDE_AICONTENT: 'Включает ИИ-сгенерированный контент',
  VISUAL_SEARCH: 'Визуальный поиск',
  VISUAL_SEARCH_TOOLTIP_TITLE: '3 простых способа настроить поиск по изображению!',
  VISUAL_SEARCH_TOOLTIP_INFO_1: '1. Кликните на точки изображения, чтобы идентифицировать найденный элемент.',
  VISUAL_SEARCH_TOOLTIP_INFO_2: '2. Перетащите поле окна, чтобы увеличить или уменьшить область поиска.',
  VISUAL_SEARCH_TOOLTIP_INFO_3: '3. Нажмите «X», чтобы вернуться к поиску.',
  VISUAL_SEARCH_NO_RESULTS: 'Ваш поиск не дал результатов.',
  LANG_RIS_TITLE: 'Обратный поиск изображений',
  LANG_RIS_NOTFOUND: 'Результат не найден!',
  LANG_RIS_ERROR_HEADER: 'Загруженное изображение содержит ошибки, убедитесь, что изображение соответствует следующим критериям:',
  LANG_RIS_REFINE_SRCH_MSG: 'Уточните поиск с помощью ключевого слова',
  LANG_RIS_UPLOADED_IMAGE: 'Загруженное изображение',
  LANG_TELL_ME_MORE: 'Расскажите мне больше',
  LANG_ENTERKEYWORD: 'Пожалуйста, введите ключевое слово',
  LANG_SIZE: 'Размер',
  LANG_STANDARD_SIZE: 'Стандартный размер',
  LANG_EXTENDED_SIZE: 'РАСШИРЕННЫЕ РАЗМЕРЫ',
  LANG_SIZE_PX: 'Пиксель - это основная единица цифрового изображения. Размер изображения, измеряемый в пикселях, определяет уровень детализации и размер загрузки.',
  LANG_SIZE_DPI: 'Количество точек на дюйм определяет разрешение печати. Более высокое dpi означает большую детализацию. Для печати рекомендуемое разрешение для всех изображений составляет 300 dpi.',
  LANG_SOCMED_SIZE: 'Размеры для социальных сетей',
  LANG_COMPARE: 'Сравнить',
  LANG_AUTO_ENHANCE: 'Автоулучшение',
  LANG_AUTO: 'Авто',
  LANG_BG_REMOVAL: 'Удаление фона',
  LANG_TEXT: 'Текст',
  LANG_ELEMENTS: 'Элементы',
  LANG_IMAGE_INFO: 'Информация об изображении',
  LANG_FOLLOW: 'Следуйте',
  LANG_FOLLOWED: 'Следовать',
  LANG_FOLLOWING: 'Следующий',
  FACEBOOK_IMAGE: 'Изображение в Facebook',
  FACEBOOK_LINK: 'Ссылка на Facebook',
  FACEBOOK_COVER: 'Обложка Facebook',
  FACEBOOK_STORY: 'История Facebook',
  INSTAGRAM_POST: 'Пост в инстаграм',
  INSTAGRAM_STORY: 'История в инстаграм',
  TWITTER_POST: 'Сообщение в Twitter',
  TWITTER_HEADER: 'Заголовок Twitter',
  PINTEREST_POST: 'Сообщение Pinterest',
  EMAIL_HEADER: 'Заголовок электронного письма',
  PRESENTATION: 'Презентация',
  PRESENTATION_WIDE: 'Презентация широкая',
  EBOOK_COVER: 'Обложка электронной книги',
  LINKEDIN_POST: 'Сообщение в LinkedIn',
  BROCHURE_COVER: 'Обложка брошюры (A4)',
  POSTCARD: 'Открытка',
  PHOTO_PRINT: 'Печать фотографий',
  YOUTUBE_PROFILE_IMAGE: 'Изображение профиля YouTube',
  YOUTUBE_CHANNEL_COVER_PHOTO: 'Фотография обложки канала YouTube',
  AUTO_COLOR: 'Автоцвет',
  BALANCE: 'Остаток средств',
  AUTO_LEVEL: 'Автоуровень',
  AUTO_TONE: 'Автоматический тон',
  ENRICH: 'Обогатить',
  RESET: 'Сброс настроек',
  RESET_ALL: 'Сбросить все',
  FILE_DOWNLOAD_OPTIONS: 'Варианты загрузки файла',
  FILE_DOWNLOAD_INITIATED: 'Осуществляется загрузка файла',
  IMAGE_PROPERTIES: 'Свойства изображения',
  DOWNLOAD_DETAILS: 'Скачать свойства',
  FILE_SIZE: 'Размер файла',
  LICENSE: 'Лицензия',
  FILEFORMAT: 'Тип файла',
  CHANGE: 'Изменить',
  PRICING: 'Цены',
  FIND_QUALITY_IMAGE: 'Find quality images now',
  SAVE_AS: 'Сохранить как',
  IMAGE_USAGE: 'Использование изображения',
  PRINT: 'Печать',
  ELECTRONIC: 'Электронные материалы',
  COMPREHENSIVE: 'Полная расширенная лицензия',
  DOWNLOAD_CREDITS: '<b>{quota}</b> кредитов Пакета кредитов',
  BASIC_SHAPE: 'Основная форма',
  CHIT_CHAT: 'Чат',
  SHAPES: 'Формы',
  ELEMENT_CHIT_CHAT: 'Элемент чата',
  ORGANIC_ABSTRACT_SHAPES: 'Органические абстрактные формы',
  ELEMENT_SHAPES: 'Элемент форм',
  BUY_SINGLE_IMAGE: 'Купить одно изображение <br/><b>{quota} Кредитов</b>',
  BUY_SINGLE_IMAGE_CREDIT: 'Купить пакет кредитов <br/><b>{quota} кредитов</b>',
  BUY_CREDIT_PACK: 'Приобретите пакет <br/>кредитов и сэкономьте',
  BUY_CREDIT_PACK_POINT_1: '{quota} пакет кредитов по <b>{price}</b>',
  BUY_CREDIT_PACK_POINT_2: 'Без ежемесячных обязательств.',
  BUY_CREDIT_PACK_POINT_3: 'Срок действия 1 год.',
  BUY_SINGLE_IMAGE_PSUB: 'Купить одно изображение <br/><b>{quota} слот для загрузки</b>',
  BUY_DOWNLOAD_PACK: 'Купите пакет загрузок <br/><b>и сэкономьте больше</b>',
  BUY_DOWNLOAD_PACK_POINT_1: 'Пакет загрузки {quota} изображений по цене <b>{price}</b>',
  BUY_DOWNLOAD_PACK_POINT_2: 'Без ежемесячных обязательств.',
  BUY_DOWNLOAD_PACK_POINT_3: 'Срок действия 1 год.',
  LOWEST_PRICE: 'Самая низкая цена <br/>с планом подписки',
  LOWEST_PRICE_POINT_1: 'Попробуйте один месяц за <b>{price}</b>',
  LOWEST_PRICE_POINT_2: 'Загрузите 10 фото или иллюстраций.',
  LOWEST_PRICE_POINT_3: 'без ограничений по дневному скачиванию, неиспользованные загрузки переносятся на следующий месяц.',
  PER_IMAGE_SIZE: 'Изображение <b>{size}</b>',
  PER_IMAGE_ANY: 'Изображение <b>любого размера</b>',
  PER_IMAGE: 'Изображение',
  VIEW_PRICING: 'Больше планов и цен',
  DOWNLOAD_BEGIN: 'Загрузка файла начнется через минуту. Пожалуйста, подождите.',
  DOWNLOAD_NOT_STARTING: 'Загрузка не началась?',
  SELECT_MIRROR: '[Выберите источник]',
  SHOW_MORE: 'Показать больше',
  SHOW_LESS: 'Показать меньше',
  LICENSE_TYPE: 'Тип лицензии',
  LICENSE_SUMMARY: 'Обзор лицензий',
  BACK: 'Назад',
  LANG_VECTOR: 'Вектор',
  PREMIUM_VECTOR: 'PREMIUM Стоковые Векторы',
  SCALE_ANY_SIZE: 'Любой масштаб',
  LANDINGPAGE_VECTOR_HEADLINE: 'Векторные стоковые изображения - бесплатные иллюстрации, клипарты и графика без роялти.',
  LANDINGPAGE_PHOTO_HEADLINE: 'Стоковые фотографии - фотографии, изображения и картинки без роялти',
  CATEGORIES: 'Категории',
  LANDINGPAGE_PRICING_1: 'Кредиты',
  LANDINGPAGE_PRICING_2: 'Купить подписку',
  LANDINGPAGE_AS_LOW_AS: 'Стоимость от',
  EMPTY_SEARCH_TITLE: 'К сожалению, ваш поиск не дал результатов.',
  LANG_SMALL: 'Небольшой',
  LANG_MEDIUM: 'Средний',
  LANG_LARGE: 'Большой',
  LANG_EXTRA_LARGE: 'Очень большой',
  LANG_LOW_CREDITS: 'Похоже, у вас заканчиваются кредиты на скачивание!',
  LANG_TOPUP: 'Пополнить',
  LANG_TOPUP_NOW: 'Пополнить сейчас',
  SEO_DETAILS_TITLE_STOCKPHOTO: '{description} Фотография, картинки, изображения и сток-фотография без роялти. Image {mediaId}',
  SEO_DETAILS_TITLE_CLIPARTVECTOR: '{description} Клипарты, SVG, векторы, и Набор Иллюстраций Без Оплаты Отчислений. Image {mediaId}',
  SEO_DETAILS_METADESC_STOCKPHOTO: 'Картинка {description} стоковых фотографий и изображений. Image {mediaId}',
  SEO_DETAILS_METADESC_CLIPARTVECTOR: 'Иллюстрация {description} векторной графики, клипарта и набор векторов. Image {mediaId}',
  SEO_DETAILS_TITLE_FREE_PHOTO: '{description} Бесплатное Изображение и фотография {mediaId}. ',
  SEO_DETAILS_METADESC_FREE_PHOTO: 'Бесплатная картинка {description}. Бесплатное Изображение  {mediaId}.',
  SEO_DETAILS_TITLE_FREE_VECTOR: '{description} Бесплатный Вектор и графика {mediaId}.  ',
  SEO_DETAILS_METADESC_FREE_VECTOR: 'Бесплатный Вектор {description}. Бесплатная графика {mediaId}.',
  SEO_DETAILS_OGDESCRIPTION: '123RF - Миллионы стоковых фото, векторов, видео и музыки для Ваших проектов.',
  LIVECHAT_LOOKINGHELP: 'Нужна помощь?',
  LIVECHAT_CHATWITHUS: 'ЧАТ С НАМИ!',
  FREE_TRIAL: '<b>Get 10 Free Images</b> <br/>with Subscription Plan',
  LANG_RELATEDSEARCH: 'Похожие запросы',
  LANG_RELATED_STOCKPHOTO_SEARCH: 'Похожие запросы стоковых фотографий',
  LANG_PROPERATTRIBUTE: 'Вот наша атрибуция:',
  LANG_ATTRIBUTEDESC: 'Пожалуйста, соотнесите это изображение а его автором, разместив эту линию ниже в любой области в пределах вашего продукта, веб-сайта или обеспечения',
  LANG_ATTRIBUTE_TITLE: 'Атрибуция необязательна.',
  LANG_ATTRIBUTE_CREDIT: 'Если вы хотите атрибутировать автора, пожалуйста, воспользуйтесь примером ниже:',
  LANG_ATTRIBUTE_COPYLINK: 'Копировать ссылку',
  LANG_ATTRIBUTE_COPYTEXT: 'Копировать текст',
  LANG_LICENSE_AGREEMENT: '<a href={url}><b>Вот</b></a> лицензионное соглашение для этой загрузки.',
  LANG_NEXTPAGE: 'Следующая страница',
  LANG_IMAGE_NO_AVAILABLE: 'Изображение № {mediaId} больше не доступно.',
  LANG_PHOTOGRAPHY_METAIMG_DESC: 'Найдите и скачайте из миллионов изображений HD-качества, стоковые фотографии, роялти фри изображения и иллюстрации',
  LANG_VECTOR_METAIMG_DESC: 'Найдите и скачайте из миллионов изображений HD-качества, стоковые фотографии, роялти фри изображения и иллюстрации',
  LANG_ANOTHERMETAKWDEFAULT: 'фотобанк, микросток, фото банк, стоковые фото, стоковая фотография, фото бесплатно, бесплатный фотобанк, лицензия на фото, купить фото, купить видео, купить видеоролики, купить фото дешево, дешевые фото, банк фото, банк фотографий, фотоархив, видеофрагменты, стоковые имиджи, микростоки, фотосток, стокфото, сток фото, картинки, фотографии, роялти фри',
  LANG_ANOTHERMETADESCDEFAULT: 'Фотобанк 123RF - более 16 миллионов качественных фотографий, векторных иллюстраций и видеороликов, стоимостью от 6 рублей!',
  EMPTY_FILTERED_RESULT_TITLE: 'К сожалению, ваш поиск с этими фильтрами не дал результатов.',
  EMPTY_FILTERED_RESULT_SUBTITLE: 'Попробуйте удалить несколько фильтров или попробуйте другое ключевое слово.',
  CLEAR_ALL_FILTER: 'Очистить все фильтры',
  PROMOBAR_FREE10_TEXT: 'Get <b>10 FREE Images</b> when you get started on our 1 Month-Free Trial.',
  PROMOBAR_FREE10_BTNLABEL: 'START NOW',
  PROMOBAR_MASSIVE_MARKDOWN_TEXT: 'Массовые скидки на все подписки.',
  PROMOBAR_MASSIVE_MARKDOWN_BTNLABEL: 'Просмотреть планы',
  LANG_MODEL_RELEASED: 'С разрешением модели',
  LANG_PROPERTY_RELEASED: 'С разрешением собственника',
  LANG_MODEL_RELEASE: 'с Модельным Релизом',
  LANG_PROPERTY_RELEASE: 'с Релизом Собственности',
  LANG_YES: 'Да',
  LANG_NO: 'Нет',
  LANG_MREXPLAIN: 'Релиз модели - это юридический документ, подписанный моделью, который предоставляет фотографу разрешение на публикацию / распространение / использование фотографий или образа модели в той или иной форме.',
  LANG_PREXPLAIN: 'Релиз собственности - это юридический документ, подписанный владельцем объекта, предмета или помещения, в котором была сделана фотография. Релиз предоставляет разрешение на публикацию / распространение / использование фотографии в той или иной форме.',
  LANG_DOWNLOADS: 'Загрузки',
  LANG_OLDFILENAME: 'Старое имя файла',
  LANG_DOWNLOAD_PREVIEW: 'Скачать превью',
  LANG_SHARE: 'Поделиться',
  LANG_RESTRICTIONS: 'Ограничения',
  LANG_READ_MORE: 'Подробнее',
  LANG_EDITORIAL_USE: 'Только для редакционного использования Права собственности на логотип',
  LANG_EDITORIALDESCP: 'Только для редакционного использования: это изображение может быть использовано только в редакционных целях. Использование данного изображения в рекламных, коммерческих или любых других целях запрещено без получения дополнительных разрешений у правообладателей. 123RF.com не предоставляет услуги по оформлению данных разрешений.',
  LANG_LOGO_OWNERSHIP: 'Права собственности на логотип',
  LANG_PHOTO_SHOT_SMARTPHONE: 'Это фото было снято на смартфон.',
  LANG_VECTORNOLOGO: 'Если вы хотите использовать этот логотип для вашей компании, мы можем организовать <b>покупку прав</b> от вашего имени. Пожалуйста, свяжитесь с нами по адресу <b>{phonenum}</b>, чтобы узнать больше!',
  LANG_DOWNLOAD_QUOTA: '<b>{quota}</b> квота скачиваний из вашего План подписки',
  LANG_DOWNLOAD_SLOT: '<b>{quota}</b> скачиваний Пакеты',
  LANG_LOGO: 'ЛОГОТИПЫ',
  USING_OLDER_BROWSER: 'Вы используете старую версию браузера?',
  UPGRADE_NEWER_BROWSER: 'Обновите свой браузер до новой версии, чтобы насладиться более быстрой работой нашей платоформы!',
  CLICK_TO_DOWNLOAD: 'Нажмите, чтобы загрузить любой из них:',
  LANG_VECTOR_EDITABLE: 'Редактируемые файлы в SVG и EPS',
  PROMOBAR_GOOD15_TEXT1: 'Платите меньше, скачивайте еще больше. ',
  PROMOBAR_GOOD15_TEXT2: 'Скидка 15% на подписки с промокодом:',
  PROMOBAR_GOOD15_BTNLABEL: 'GOOD15',
  PROMOBAR_SUMMER25_TEXT1: 'Платите меньше, скачивайте еще больше. ',
  PROMOBAR_SUMMER25_TEXT2: 'Скидка 25% на все планы с <b>промокодом</b>:',
  PROMOBAR_SUMMER25_BTNLABEL: 'SUMMER25',
  PROMOBAR_HALLOW30_TEXT: 'Скидка 30% на все планы с <b>промокодом</b>:',
  NO_EDIT_COMPARE_MSG: 'Для сравнения не было сделано никаких правок.',
  SWITCH_TO_VECTOR_MSG: 'Фоторедактор не поддерживаются для изображений в векторном размере. Все изменения будут потеряны, если вы переключитесь в векторный формат.',
  SWITCH_TO_XL_MSG: 'Фоторедактор не поддерживаются для изображений размера XL. Все изменения будут потеряны, если вы переключитесь на формат XL.',
  UNAVAILABLE_FOR_THIS_SIZE: 'Нет в наличии в размере {size}',
  SWITCH: 'ПЕРЕКЛЮЧИТЬСЯ',
  SWITCH_LICENSE_EXT_MSG: 'Вы хотите загрузить это изображение сначала в формате {type}? Вы потеряете все правки, если перейдете на расширенную лицензию.',
  LANG_SESSION_EXPIRED: 'Время сеанса истекло. Пожалуйста <a href={url}>войти здесь</a>',
  LANG_TEXT_COLOR: 'Цвет текста',
  LANG_SHAPE_ADD: 'Add Shape',
  LANG_TEXT_HIGHLIGHT: 'Выделять',
  LANG_TEXT_ALIGNMENT: 'Выравнивание',
  LANG_TEXT_BOLD: 'Прерывистый',
  LANG_TEXT_ITALIC: 'Курсив',
  LANG_TEXT_UNDERLINE: 'Подчеркнутый',
  LANG_TEXT_BULLET: 'Маркер',
  LANG_TEXT_LINE_SPACING: 'Межстрочный интервал',
  LANG_TEXT_LETTER_SPACING: 'Межбуквенное расстояние',
  LANG_EDIT_COLOR: 'Edit color',
  LANG_BRING_FORWARD: 'На передний план',
  LANG_BRING_BACKWARD: 'На задний план',
  LANG_DUPLICATE: 'Дублировать',
  LANG_OPACITY: 'Непрозрачность',
  LANG_DELETE: 'Удалить',
  LANG_TEXT_ADD: 'Добавить текст',
  LANG_TEXT_EDIT: 'Править текст',
  LANG_TEXT_FONT_TYPE: 'Тип шрифта',
  LANG_TEXT_FONT_SIZE: 'Размер шрифта',
  LANG_TEXT_ALL_FONTS: 'Все шрифты',
  LANG_TEXT_UNDO: 'Отменить',
  LANG_TEXT_REDO: 'Повторить',
  LANG_TEXT_FONT: 'Шрифт',
  LANG_SEARCH_FONT_TYPE: 'Искать шрифт',
  LANG_SEARCH_SHAPE_TYPE: 'Искать элементы',
  LANG_DONE: 'Готово',
  ADD_HEADER: 'Добавить заголовок',
  ADD_TITLE: 'Добавить заголовок',
  ADD_BODY: 'Добавить основной текст',
  ADD_ASSISTIVE: 'Добавьте вспомогательный текст',
  STOCK_PHOTO_BANNER_TITLE: 'Миллионы Стоковые Изображения RF Для того, чтобы создать все!',
  STOCK_PHOTO_BANNER_DESC: 'Найдите все потрясающие изображения, необходимые для создания визуальных визуальных визуалов, чтобы иллюстрировать свои большие идеи в жизнь сейчас.',
  STOCK_PHOTO_SUBSCRIBE_TITLE: 'Визуализируйте свои истории с Правый образ',
  STOCK_PHOTO_SUBSCRIBE_DESC: 'Получите мгновенный доступ к более чем на 180 миллионов изображений, чтобы получить расширение вашего рассказчика сейчас. Наши визуальные эффекты и ваша история, это идеальная комбинация, чтобы максимизировать ваш творческий потенциал!',
  STOCK_PHOTO_CARD_DOWNLOAD_PACK: 'Скачать Pack.',
  STOCK_PHOTO_CARD_FROM_AS_LOW_AS: 'Так же низко, как',
  STOCK_PHOTO_CARD_CREDIT: 'credit',
  STOCK_PHOTO_CARD_SUBSCRIPTION_PLAN: 'Abonnementplan',
  STOCK_PHOTO_CARD_IMAGE: 'изображение',
  STOCK_PHOTO_CARD_SUBSCRIBE_NOW: 'Подпишись сейчас',
  STOCK_PHOTO_CARD_BUY_NOW: 'Купить сейчас',
  STOCK_PHOTO_POPULAR_ROYALTY: 'Популярные роялти-фотографии категории изображения',
  STOCK_PHOTO_BROWSE_BEST_SELLING: 'Browse best-selling royalty free images',
  STOCK_PHOTO_DISCOVER_AFFORABLE: 'Откройте для себя доступные планы и цены на фотографии',
  STOCK_PHOTO_DISCOVER_NOW: 'Проверьте это',
  STOCK_PHOTO_VECTORS_FOOTAGE_AUDIO: 'Vectors, Footage, Audio for you',
  STOCK_PHOTO_VECTORS: 'Vectors',
  STOCK_PHOTO_ILLUSTRATION: 'Illustration',
  STOCK_PHOTO_PATTERN: 'Pattern',
  STOCK_PHOTO_CARTOON: 'Cartoon',
  STOCK_PHOTO_BABY: 'Baby',
  STOCK_PHOTO_FOOTAGE: 'Footage',
  STOCK_PHOTO_FOOTAGES: 'Footages',
  STOCK_PHOTO_BUILDING: 'Building',
  STOCK_PHOTO_HOTEL: 'Hotel',
  STOCK_PHOTO_CHILDREN: 'Children',
  STOCK_PHOTO_ABSTRACT: 'Abstract',
  STOCK_PHOTO_AUDIO: 'Audio',
  STOCK_PHOTO_AUDIOS: 'Audios',
  STOCK_PHOTO_TECHNO: 'Techno',
  STOCK_PHOTO_HOLIDAY: 'Holiday',
  STOCK_PHOTO_ACCOUSTIC: 'Accoustic',
  STOCK_PHOTO_LANDSCAPES: 'Пейзаж',
  STOCK_PHOTO_BABIES: 'Дети',
  STOCK_PHOTO_BUSINESS: 'Бизнес',
  STOCK_PHOTO_FOOD: 'Еда',
  STOCK_PHOTO_ANIMALS: 'Животные',
  STOCK_PHOTO_TECHNOLOGY: 'Технологии',
  STOCK_PHOTO_HEALTHCARE: 'Здравоохранение',
  STOCK_PHOTO_NATURE: 'Природа',
  STOCK_PHOTO_ROMANCE: 'Романтика',
  CLIPART_VECTOR_EXPLORE_MILLIONS: 'Создайте с миллионами роялти-роялти-векторов у вас под рукой.',
  CLIPART_VECTOR_NEED_AN_EYE: 'Найти классные векторные иллюстрации, инфографика, клипарт, иконы и намного больше, которые помогают преобразовать ваши удивительные идеи в реальность',
  CLIPART_VECTOR_FIND_VECTOR: 'Найдите правильные векторы, чтобы оживить ваши проекты.',
  CLIPART_VECTOR_FIND_VECTOR_DESC: 'Будь то крутая инфографика, иллюстрации, мультфильмы, клипарт, фоны, значки или все, что вам нужно создавать потрясающие визуальные эффекты; У нас есть их прямо здесь. Векторные дизайны в жизнь сейчас!',
  CLIPART_VECTOR_ON_DEMAND: 'On Demand',
  CLIPART_VECTOR_FROM_AS_LOW_AS: 'From as low as',
  CLIPART_VECTOR_PER_CREDIT: '/ credit',
  CLIPART_VECTOR_BUY_SUBSCRIPTION: 'Buy Subscription',
  CLIPART_VECTOR_PER_IMAGE: '/ image',
  CLIPART_VECTOR_SUBSCRIBE_NOW: 'Subscribe Now',
  CLIPART_VECTOR_POPULAR_ROYALTY: 'Популярные Royalty Free векторные категории',
  CLIPART_VECTOR_DISCOVER_AFFORDABLE: 'Откройте для себя доступные планы и цены для векторов',
  CLIPART_VECTOR_DISCOVER_NOW: 'Проверьте это',
  SPEND_AND_WIN_SUBTITLE: 'Каждые потраченные {currency} = 1 вход',
  SPEND_AND_WIN_IMGALT: 'Потратьте и выиграйте iPhone 13 Pro',
  CLIPART_VECTOR_GRAPHIC: 'Графика',
  CLIPART_VECTOR_ADVERTISING: 'Реклама',
  CLIPART_VECTOR_FLOWER: 'Цветы',
  CLIPART_VECTOR_ABSTRACT: 'Абстрактный',
  CLIPART_VECTOR_POP: 'Поп',
  CLIPART_VECTOR_BIRD: 'Птица',
  CLIPART_VECTOR_ILLUSTRATION: 'Иллюстрация',
  CLIPART_VECTOR_PIXEL: 'Пиксель',
  CLIPART_VECTOR_3D: '3D',
  LANG_VECTOR_SVG_EPS: 'Вектор, SVG и EPS',
  FREE_UPGRADE: 'Бесплатное улучшение',
  FREE_UPGRADE_MESSAGE: 'БЕСПЛАТНОЕ обновление до XL для всей подписки или пакета загрузки. Купить сейчас.',
  LANG_FREE_IMAGE: '123RF Бесплатно',
  LANG_IMAGE_FREE: 'Это сток -фото бесплатно для коммерческого и личного использования. Атрибуция требуется.',
  LANG_HOW_TO_ATTRIBUTE: 'Как мне указать автора?',
  LANG_FREE_DOWNLOAD: 'Скачать бесплатно',
  LANG_FREE_DOWNLOAD_BUTTON: 'FREE Скачать',
  LANG_GO_PLUS_BUTTON: 'Выбрать PLUS',
  LANG_ATTRIBUTION_IS_KEY: 'Атрибуция является важным элементом для создателей контента, которые делают свою работу общедоступной. Наши создатели контента ценят экспозицию, полученную от их творений, которые используются, разделяют и зачисляют миллионами таких пользователей, как вы.',
  LANG_GET_ATTRIBUTION_LINK: 'Скопируйте эту ссылку и поместите ее в место рядом с тем, где используется контент.',
  LANG_CREDIT_OWNER: 'Пожалуйста, причитайте создателя:',
  LANG_USE_ATTRIBUTION: 'Используйте ссылку на атрибуцию ниже.',
  LANG_ATTRIBUTION_LOCATION: 'Если это не выполнимо, другие хорошие места находятся в нижнем колонтитуле веб -сайта, блога или информационного бюллетеня или даже конкретного раздела кредитов сайта, будет достаточно, чтобы заслужить владельца {media_type}.',
  LANG_GOT_A_QUESTION: "Есть вопрос? <a href={url} target='_blank'><u>Связаться с нами.</u></a>",
  LANG_ATTR_FREE_IMG_TITLE: 'Вам нужно будет кредитовать Владелец этого бесплатного изображения.',
  LANG_ATTR_FREE_DOWNLOADING: 'Повесить там! Это не займет много времени.',
  LANG_PLUS_CONTENT_1: 'Вы получите неограниченное количество загрузок',
  LANG_PLUS_CONTENT_2: 'Доступ к более чем 100 миллионам фотографий и векторов',
  LANG_PLUS_CONTENT_3: 'Эксклюзивные 3D иллюстрации только на 123RF!',
  LANG_PLUS_CONTENT_4: 'Неограниченные загрузки',
  LANG_PLUS_CONTENT_5: 'Неограниченная творческая свобода',
  FREE_VECTOR: 'FREE Стоковые Векторы',
  FREE_STOCKPHOTO: 'FREE Стоковые Фото',
  FREE_PUBLIC_DOMAIN_CC0: 'Общественное достояние CC0',
  BUY_5_DOWNLOAD_PACK: "<b>Ограниченное по времени предложение!</b><br/>3 + <b><span style='color:red'>2 БЕСПЛАТНО</span></b> = 5 фотографий или векторов",
  BUY_5_DOWNLOAD_PACK_POINT_2: 'Загружайте в любое время (до года)',
  BUY_5_GET_2_FREE: 'Получите 2 БЕСПЛАТНО!',
  LANG_ASK_DOWNLOAD_PLUS: 'Хотите скачать этот PLUS контент?',
  LANG_SIGN_UP_PLUS: 'Зарегистрируйтесь на нашем плане 123RF PLUS',
  LANG_FOR_ONLY: "<span class='text'>всего за</span> <span class='price'>{price}</span><span class='text'> долларов</span> в месяц",
  LANG_FOR_ONLY_2: "<span class='text'>всего за</span> <span class='price'>{price}</span><span class='text'> долларов</span>",
  LANG_BENEFIT_PLUS: 'Получите выгоду от нашего плана PLUS',
  LANG_RELEASES: 'Cвойства',
  LANG_UNAVAILABLE: 'Недоступно',
  LANG_ATTR_FREE_IMG_TITLE2: 'Мы рады, что вы нашли то, что вам нужно. 🎉',
  LANG_PLEASE_NOTE: 'Обратите внимание, что данное изображение не содержит никаких разрешений на использование модели или собственности. Коммерческое использование осуществляется на ваш страх и риск.',
  LANG_PLEASE_ADVISED: 'Пожалуйста, имейте в виду, что изображения, содержащие узнаваемые люди, не поставляются ни с какими выпусками. Чтобы использовать эти изображения на коммерчески, разрешение должно быть получено от соответствующих сторон. Если изображение не содержит узнаваемых людей, не должно быть проблем с авторским правом при его коммерческом использовании.',
  LANG_REACHED_LIMIT: 'Опс! Вы достигли ежедневного лимита загрузки.',
  LANG_COME_TOMORROW: 'Мы очень рады, что вам нравятся наши бесплатные изображения! Зайдите завтра, чтобы обновить лимит загрузки. Спасибо. 😊',
  LANG_DOWNLOAD_ERROR: 'Опс! Ваше изображение не удалось загрузить из-за ошибки.',
  LANG_REFRESH_AND_TRY_AGAIN: "Пожалуйста, обновите браузер и повторите попытку. <a href={url} target='_blank'> Сообщите нам</a> если эта проблема сохранится.",
  LANG_REACHED_LIMIT_PLUS: 'Ежедневная загрузка лимита достигается',
  LANG_COME_TOMORROW_PLUS: 'Ух ты! Вы достигли своего лимита загрузки на сегодня. Пожалуйста, вернитесь завтра, чтобы скачать больше. Спасибо за поддержку!',
  SEE_YOU_TOMORROW: 'Увидимся завтра!',
  ORIGINAL: 'Original',
  'AUTO_B&W': 'Auto B/W',
  AUTO_ADJUST: 'Auto Adjust',
  AUTO_POP: 'Auto Pop',
  NONE: 'None',
  LANG_CROP: 'Автообрез',
  LANG_ENABLE_EDITOR: 'Редактировать изображение',
  LANG_DISABLE_EDITOR: 'Закрыть',
  LANG_IMAGE_FREE_CC0: "Это изображение CC0. Атрибуция не требуется. Вы можете использовать его в соответствии с <a class='linkCC0' href={url} target='_blank'>условиями лицензии CC0</a>.",
  LANG_PREVIEW: 'Предварительный просмотр',
  LANG_ATTRIBUTION_CC0_TITLE: 'Изображение CCO: Атрибуция не требуется',
  LANG_ATTRIBUTION_TITLE: 'Требуется указание авторства',
  LANG_ATTRIBUTION_CONTENT: 'Ссылка на авторство будет предоставлена после того, как вы загрузите файл.',
  LANG_ATTRIBUTION_CC0_CONTENT: 'Атрибуция не требуется, так как это изображение CCO.',
  LANG_SUBCRIBE_PREMIUM: 'You’ve reached your daily download limit! To download more, subscribe to 123RF PREMIUM.',
  LANG_REMOVE_ATTRIBUTION: 'Не хотите указывать информацию об авторе?',
  LANG_SUBCRIBE_PLUS_ATTRIBUTION: "Подпишитесь на <img src={image} alt='123RF Plus Logo'>, чтобы получить неограниченный доступ к 1000 миллионам стоковых фотографий и иллюстраций без указания авторства.",
  LANG_CREATOR_ATTRIBUTION: 'Пожалуйста, укажите информацию об авторе, используя ссылку ниже.',
  LANG_GET_ATTRIBUTION_LINK_2: 'Скопируйте эту ссылку и поместите ее рядом с тем местом, где используется контент, в нижнем колонтитуле веб-сайта или в определенном разделе сайта.',
  LANG_APPLY_BG_REMOVAL: 'Применить удаление фона',
  LANG_APPLYING_EDIT: 'Применение правок...',
  LANG_APPLYING_EDIT_ERROR: 'Что-то погшло не так.',
  LANG_TRY_AGAIN: 'Попробовать снова?',
  LANG_SEARCHCOLLECTION_PREMIUM: 'Вся коллекция',
  LANG_SEARCHCOLLECTION_PLUS: 'Основная коллекция',
  LANG_SEARCHCOLLECTION_FREE: 'Базовая коллекция',
  LANG_SIMILARIMAGESPAGE_TITLE: 'Фотобанк 123RF - Стоковые Фото, Векторы, Видеоролики. Подписка на Фото. Royalty Free контент',
  LANG_SIMILARIMAGESPAGE_STOCKPHOTOS: 'Похожие изображения',
  LANG_SIMILARIMAGESPAGE_EMPTYRESULTS: 'Уп! Здесь нечего смотреть.',
  LANG_SIMILARIMAGESPAGE_UNAVAILABLEIMAGE: 'Изображение недоступно',
  LANG_PER_IMAGE: '/изображение',
  LANG_PLANS_AND_PRICING: 'Планы и цены',
  LANG_CHOOSE_BEST_PLAN: 'Выберите лучший план, который соответствует вашим потребностям.',
  LANG_WANT_MORE_SAVING_1: 'Хотите сэкономить больше?',
  LANG_WANT_MORE_SAVING_2: 'Смотрите больше планов от {price} за изображение',
  LANG_BEST_VALUE: 'Лучшее предложение, сэкономьте {percentage}%',
  LANG_YEARLY_PLAN: 'Годовой план',
  LANG_WITH_MONTHLY_PAYMENTS: 'С ежемесячными платежами',
  LANG_GRAB_YEARLY_PLAN: 'Возьмите годовой план и сэкономьте {percentage}%.',
  LANG_PRICE_PER_MONTH: '{price} в месяц',
  LANG_PRICE_PER_YEAR: '{price} в год',
  LANG_GET_X_EVERY_MONTH: 'Получите {quota} фотографий или векторов каждый месяц',
  LANG_X_EVERY_MONTH: '{quota} фотографий или векторов<br>каждый месяц',
  LANG_SINGLE_IMAGE_PURCHASE: 'Покупка одного изображения',
  LANG_GET_ONLY_WHAT_YOU_NEED: 'Получите только то, что вам нужно на данный момент.',
  LANG_TOTAL_PRICE: 'Всего {price}',
  LANG_DOWNLOAD_IMMEDIATELY: 'Скачайте изображение немедленно после оформления заказа.',
  LANG_NEXT: 'Следующее',
  LANG_TITLE: 'Фотобанк 123RF - Стоковые Фото, Векторы, Видеоролики. Подписка на Фото. Royalty Free контент',
  SIMILAR_STOCK_PHOTOS: 'Похожие изображения',
  EMPTY_SIMILAR_SEARCH_RESULTS: 'Уп! Здесь нечего смотреть.',
  UNAVAILABLE_IMAGE: 'Изображение недоступно',
  LANG_DOWNLOAD_PACK: 'Пакет скачиваний',
  LANG_GET_X_ANY_TIME: '{quota} фотографий или векторов в любое время.',
  LANG_X_CREDITS: '{total} кредитов',
  LANG_PER_CREDIT: '/кредитов',
  LANG_GET_X_PHOTOS_AND_Y_VIDEOS_ANY_TIME: '{photoCount} фотографии{videoCount, plural, =0 {} =1 { или # видео} other { или # Видеоролики}} в любое время',
  INCLUDES_STANDARD_LICENSE: 'Включает стандартную лицензию',
  LANG_MEDIUM_SIZE: 'Средний размер',
  LANG_LARGE_SIZE: 'Большой размер',
  LANG_EXTRA_LARGE_SIZE: 'Очень большой размер',
  LANG_STANDARD_SIZES: 'Стандартный размер',
  LANG_ROYALTY_FREE: 'Royalty-Free',
  LANG_OPTIONS: 'Параметры',
  LANG_BENEFIT_FROM_PLUS_PLAN: 'Получите выгоду от нашего плана PLUS',
  LANG_RELATED_FONT_SEARCH: 'Похожие Запросы',
  AI_GENERATED_PHOTO: 'ИИ-сгенерированное изображение',
  FONT_PRICING_CREDIT: 'кредитов',
  '3_FONTS_3_IMGS': '3 шрифта <b>или</b> 3 изображений <b>или</b> 1 видео',
  '9_FONTS_9_IMG_3_VIDEOS': '9 шрифтов <b>или</b> 9 изображений <b>или</b> 3 видео',
  PURCHASE_AND_DOWNLOAD: 'Купить и скачать',
  FONT_PATH: 'fonts',
  FONT_ZIP: 'Шрифт (OTF, TTF)',
  FONT_PACKS_LICENSE: 'Лицензия на пакеты 123RF',
  FONT_META_DESC: 'Скачать {product_title} шрифт или типографика для создания потрясающих дизайнов для печати и web-использования.',
  FONT_META_TITLE: 'Шрифт - {product_title} | 123rf',
  FONT_SIMILAR: 'Подобные шрифты',
  LANG_PLANS: 'ПЛАНЫ',
  LANG_I_WANT_THIS: 'I want this',
  LANG_AI_VARIATION_TOOLTIP_TITLE: 'Попробуйте создать вариации',
  LANG_AI_VARIATION_TOOLTIP_DESCRIPTION: 'Генерируйте новые изображения на основе исходного изображения. Плюс ко всему, это новый способ вознаграждать наших авторов 123RF каждый раз, когда изображение, сгенерированное искусственным интеллектом, лицензируется!',
  LANG_SIZEBAR_TOOLTIP_DESCRIPTION: 'Наше сообщество благодарит вас. Лицензионные сборы будут распределены между авторами 123RF.',
  LANG_CONTRIBUTORS_AI_VARIATION: 'Соавторы 123RF, предоставившие оригинальное изображение, получают компенсацию за каждый лицензированный ИИ-вариант.',
  LANG_AI_VARIATION_LIMIT: 'О нет! Вы достигли предела для генерации изображений ИИ на сегодня.',
  LANG_GENERATE_COMEBACK: 'Вернитесь завтра за новыми генерациями!',
  LANG_LICENSE_AND_DOWNLOAD: 'Лицензия и скачивание',
  LANG_CONFIRM_OK: 'OK',
  LANG_AI_SWITCH_CONTENT: 'Фоторедактор не поддерживается для вариаций ИИ. Все правки будут потеряны, если вы включите на ИИ-вариации.',
  LANG_GENERATE_VARIATIONS: 'Генерировать варианты',
  LANG_GENERATE_WITH_AI: 'Создание изображений с помощью искусственного интеллекта',
  LANG_NEW: 'Новое',
  LANG_AI_GENERATOR_LICENSE: '123RF AI Generator Условия предоставления услуг (“Условия”)',
  LANG_I_UNDERSTAND: 'Я понимаю',
  LANG_WHAT_IS_THIS: 'Что это такое?',
  LANG_AUTHENTIC: 'Аутентичный',
  LANG_ABSTRACT: 'Абстракция',
  LANG_MACRO_CLOSEUP: 'Макро/крупный план',
  LANG_OBJECT: 'Объект',
  LANG_WATERCOLOR: 'Акварель',
  LANG_FLAT: 'Плоский',
  LANG_CARTOON: 'Мультипликационный',
  LANG_GEOMETRIC: 'Геометрический',
  LANG_GRADIENT: 'Градиент',
  LANG_ISOMETRIC: 'Изометрический',
  LANG_3D: '3d',
  LANG_HAND_DRAWN: 'Нарисованный от руки',
  LANG_NATURAL: 'Естественный',
  LANG_WARM: 'Теплый',
  LANG_DRAMATIC: 'Драматический',
  LANG_VIVID_BOLD: 'Яркие и смелые',
  LANG_BLACK_WHITE: 'Черное и белое',
  LANG_ASIAN: 'Азиатский',
  LANG_AFRICAN_AMERICAN: 'Афроамериканский',
  LANG_STANDARD: 'Стандартная',
  LANG_EXTENDED: 'Расширенная',
  LANG_STANDARD_CONTENT_1: 'Неограниченные просмотры для социальных сетей, веб-сайтов, электронной почты и мобильных устройств.',
  LANG_STANDARD_CONTENT_2: 'До 500 000 копий.',
  LANG_STANDARD_CONTENT_3: 'Не использовать на товарах для продажи.',
  LANG_EXTENDED_CONTENT_1: 'Неограниченные просмотры для социальных сетей, веб-сайтов, электронной почты и мобильных устройств.',
  LANG_EXTENDED_CONTENT_2: 'Неограниченный тираж.',
  LANG_EXTENDED_CONTENT_3: 'Можно использовать на товарах для продажи.',
  LANG_ORIGINAL_IMAGE: 'Оригинальное изображение',
  LANG_IMPORTANT_NOTE: 'Важное примечание',
  LANG_AI_ORIGINAL_ALTERED: 'Вариации ИИ - это вариации оригинального изображения, измененные с помощью технологии ИИ. Это похоже на то, как если бы редактор ИИ подправил выбранное вами изображение.',
  LANG_GENERATE_VARIATIONS_NOTE: 'Поскольку в настоящее время мы находимся на стадии бета-тестирования вариаций изображений с помощью ИИ, созданные изображения могут давать неожиданные результаты. Мы прилагаем все усилия для того, чтобы такие результаты и вариации соответствовали этическим и ответственным принципам ИИ.',
  LANG_LICENSE_AI_EDITED: 'Когда вы лицензируете одну из этих отредактированных ИИ версий, правообладателю оригинального изображения выплачивается лицензионный сбор.',
  LANG_MOOD: 'Настроение',
  LANG_AI_EXCLUDE_PLACEHOLDER: 'Например, зеленый цвет, прямые волосы',
  LANG_SIMILAR_STOCK: 'Похожие стоковые изображения',
  LANG_PREMIUM_TAG_TOOLTIP: 'PREMIUM subscription only',

  WITH_MODEL_RELEASE: 'с Модельным Релизом',
  WITH_PROPERTY_RELEASE: 'с Релизом Собственности',
  MODEL_RELEASE_CONTENT: 'Релиз модели - это юридический документ, подписанный моделью, который предоставляет фотографу разрешение на публикацию / распространение / использование фотографий или образа модели в той или иной форме.',
  PROPERTY_RELEASE_CONTENT: 'Релиз собственности - это юридический документ, подписанный владельцем объекта, предмета или помещения, в котором была сделана фотография. Релиз предоставляет разрешение на публикацию / распространение / использование фотографии в той или иной форме.',

  variousStockGAI: 'Соответствующая требованиям законодательства модель искусственного интеллекта, обученная на миллионах стоковых фотографий и оптимизированная для корпоративных нужд, обеспечивает спокойствие и страхование ответственности.',
  extendedLicense: 'Расширенная Лицензия',
  sizes: 'Размеры',

  downloadMail1st: 'Наш поставщик услуг по увеличению изображений отправит вам по электронной почте',
  downloadMail2nd: 'инструкции по загрузке окончательного увеличенного файла в течение следующего рабочего дня',

  aiGenarated: 'ИИ-сгенерированное изображение',

  variousOpenJourney: 'AI Model генерирует искусство, напоминающее характерный стиль Midjourney.',
  variousTurboVision: 'Высокоскоростная генерация при "нормальном" разрешении XL, повышающая общее качество.',
  variousRealVisXL: 'Цель модели - добиться фотореалистичных результатов с точностью и аккуратностью.',
  variousPortraitPlus: 'Модель с равномерной портретной композицией, идеально подходящая для соотношения сторон 1:1.',
  enterVirifiedCode: 'Введите проверочный код',
  verify: 'Проверить',
  didNotGetCode: 'Не получили код?',
  clickToResend: 'Нажмите, чтобы отправить повторно',
  otpCannotEmpty: 'OTP не может быть пустым.',
  sentCodeToMail: 'Мы отправили код на ваш зарегистрированный адрес электронной почты.',
  invalidCode: 'Неверный код',
  expiredOTP: 'OTP истек, пожалуйста, отправьте еще раз.',
  sendEmail: 'Электронное письмо успешно отправлено, пожалуйста, проверьте вашу почту, чтобы продолжить.',
  remainingTime: 'Оставшееся время: ',
};
export default ru;
