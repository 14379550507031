import React from 'react';
import PropTypes from 'prop-types';

const ICMessage = ({ width, height }) => (
  <svg width={width} height={height} className="" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M40 0H0V40H40V0Z" fill="white" fillOpacity="0.01" />
    <path d="M27.4999 31.6666H18.3333V24.9999H29.9999V18.3333H36.6666V31.6666H32.4999L29.9999 34.1666L27.4999 31.6666Z" stroke="#212121" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M3.33325 5H29.9999V25H14.1666L10.8333 28.3333L7.49992 25H3.33325V5Z" stroke="#212121" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M15.8333 15H16.6666" stroke="#212121" strokeWidth="2.5" strokeLinecap="round" />
    <path d="M10 15H10.8333" stroke="#212121" strokeWidth="2.5" strokeLinecap="round" />
    <path d="M21.6667 15H22.5001" stroke="#212121" strokeWidth="2.5" strokeLinecap="round" />
  </svg>
);
ICMessage.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};
ICMessage.defaultProps = {
  width: '25',
  height: '25',
};
export default ICMessage;
