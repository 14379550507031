import PropTypes from 'prop-types';

const IcHeart = ({ fillColor, solid, solidFillColor }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    {!solid ? (
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.17721 5.1302C7.17781 5.1302 6.23446 5.53774 5.52244 6.27666C4.79569 7.0325 4.39669 8.03794 4.4 9.10637C4.4 10.1757 4.80139 11.1802 5.53004 11.9375L11.9972 18.6569L18.4752 11.9506C19.2 11.1976 19.6 10.1917 19.6 9.12C19.6033 8.04593 19.2057 7.04143 18.4804 6.28888C17.7689 5.54902 16.825 5.14102 15.8228 5.14102C14.8201 5.14102 13.8753 5.54996 13.1614 6.29264L12.0052 7.49315L10.842 6.28418C10.1261 5.54056 9.17946 5.1302 8.17721 5.1302ZM12.3368 19.0094L12.3382 19.0113C12.3377 19.0103 12.3372 19.0103 12.3368 19.0094ZM11.6518 19.0141C11.6518 19.0141 11.6504 19.015 11.6499 19.016L11.6518 19.0141ZM11.9943 20.75C11.6081 20.75 11.2315 20.5888 10.9607 20.3067L4.15397 13.2339C3.08712 12.126 2.5 10.6618 2.5 9.10919C2.49575 7.55661 3.08047 6.08958 4.14637 4.98073C5.22129 3.86436 6.65246 3.25 8.17721 3.25C9.60648 3.25 10.955 3.79056 12.0062 4.77767C13.0535 3.79714 14.3973 3.26081 15.8228 3.26081C17.3504 3.26081 18.783 3.87611 19.857 4.99248C20.9205 6.09804 21.5043 7.56413 21.5 9.12282C21.5 10.6773 20.9134 12.1434 19.8494 13.2485L13.0255 20.3133C12.76 20.5897 12.3833 20.75 11.9943 20.75Z"
        fill={fillColor}
      />
    ) : (
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 5.881C12.981 4.729 14.484 4 16.05 4C18.822 4 21 6.178 21 8.95C21 12.3492 17.945 15.1195 13.3164 19.3167L13.305 19.327L12 20.515L10.695 19.336L10.6595 19.3037C6.04437 15.1098 3 12.3433 3 8.95C3 6.178 5.178 4 7.95 4C9.516 4 11.019 4.729 12 5.881Z"
        fill={solidFillColor}
      />
    )}
  </svg>
);

IcHeart.propTypes = {
  fillColor: PropTypes.string,
  solidFillColor: PropTypes.string,
  solid: PropTypes.bool,
};

IcHeart.defaultProps = {
  fillColor: '#212121',
  solidFillColor: '#DC3545',
  solid: false,
};

export default IcHeart;
