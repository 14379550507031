const es = {
  // Header
  photos: 'Fotos',
  Photos: 'Fotos',
  vectors: 'Vectores',
  Vectors: 'Vectores',
  footageupper: 'VIDEO',
  footage: 'Vídeo',
  video: 'Video',
  audio: 'Audio',
  signin: 'Identificarse',
  imageLogo:
    'https://static-cdn.assetsdelivery.com/images/new_translate_corpplus_logo/logo_Espanol.png',
  language: 'Idioma',
  // Footer
  contact: 'Contacto',
  contactUs: 'Contáctanos',
  privacy: 'Política de privacidad',
  terms: 'Licencia de uso',
  cookie: 'Política de cookies',
  corporateAgreement: 'License Agreement',
  // ForgotPassword
  forgotPwdTitle2: '¿Has olvidado tu contraseña',
  forgotPwdDesc2:
    "That's OK, it happens to all of us. Enter your username below and we'll send you a link to reset your password",
  forgotPwdFail: 'Por favor, introduce un e-mail',
  emailCannotEmpty: 'El campo de correo electrónico no puede estar vacío.',
  returnToLogin: 'Reestablecer contraseña',
  email: 'Correo electrónico',

  // share likebox
  duplicateCollection: 'Duplicate collection',
  removeFromCollection: 'Remove from collection',
  addToCollection: 'Add to collection',
  totalContentInThisCollection: 'Total content in this collection',
  duplicate: 'Duplicate',
  collectionDescription: 'Collection description',
  descriptionHere: 'Description here',
  duplicateCollectionSuccess:
    'You have successfully duplicated this collection to your personal collections.',

  // Login
  username: 'Nombre de usuario',
  pw: 'Contraseña',
  rememberMe: 'Recordar mis datos',
  login: 'Entrar',
  forgotPassword: '¿Has olvidado la contraseña',
  loginAsGuest: 'Acceso invitado',
  // ResetPassword
  resetPwdDesc: 'Introduce tu nueva contraseña para continuar.',
  newPw: 'Nueva contraseña',
  confirmPwd: 'Confirmar contraseña',
  resetPwdButton: 'Reestablecer contraseña',
  resetPwdTitle: 'Reestablece tu contraseña',
  enterYourNewPw: 'Ingresa tu nueva contraseña.',
  pwMiniMumChars: 'Mínimo 8 caracteres',
  pwMaxChar20: 'Máximo 20 caracteres',
  pwLowerCase: 'Mínimo una letra minúscula',
  pwUpperCase: 'Mínimo una letra mayúscula',
  pwNumeral: 'Mínimo un número',
  pwdSpecialChar: 'At least one special character',
  confirmPwNotMatch: 'Las dos contraseñas no coinciden',
  resetPwdInvalidLink: 'Link incorrecto o caducado.',
  pwHasBeenReset: 'La contraseña ha sido restablecida, ingresa nuevamente para continuar.',
  // Admin index
  companyName: 'Nombre de la compañía',
  phone: 'Telefon',
  fax: 'Fax',
  address: 'Domicilio',
  idd: 'IDD',
  // Dashboard Admin Credits
  myBalance: 'My balance',
  remaining: 'remaining',
  creditsRemaining: 'Credits remaining',
  topUp: 'Top up',
  groupBalance: 'Group balance',
  totalGroupBalance: 'Total group balance',
  manageGroupBalance: 'Manage group balance',
  individualBalance: 'Individual balance',
  manageIndividualBalance: 'Manage individual balance',
  totalDownloads: 'Total downloads',
  totalItems: 'Artículos totales',
  // Update Password
  updatePassTitle: 'Update Password',
  updatePassDes: 'Update your password before you go!',
  oldPassword: 'Antigua contraseña',
  confirmNewPassword: 'Confirmar la nueva contraseña',
  returnToHome: 'Volver a Inicio',
  // Cookie Policy
  langCookie01:
    'Cuando visita %host%, almacenamos un pequeño archivo de texto llamado cookie en el disco duro de su equipo.',
  langCookie02:
    'Este archivo nos permite realizar un seguimiento, además de poder proporcionarle funciones básicas como, por ejemplo, el uso de la cesta de la compra en línea. También se utilizan para conceder una experiencia de usuario en línea mejorada y para ofrecerle funcionalidades como «likebox».',
  langCookie03:
    'Además, nos permite analizar su interacción con nuestro sitio web, incluyendo las páginas visitadas, los términos de búsqueda y las imágenes visualizadas. Esto se hace independiente de que decida efectuar una compra o no.',
  langCookie04:
    'Las cookies que has aceptado también se utilizan para clasificar la audiencia. De esta manera podemos enriquecer tu experiencia de navegación y podremos enviarte publicidad adaptada a tus intereses.',
  langCookie05:
    'La mayoría de los navegadores le permiten rechazar las cookies. Si lo hace, es posible que algunas funciones no estén disponibles y que algunas páginas web no se muestren correctamente.',

  downloadModalTitle: 'Image download',
  bulkDownloadTitle: 'Bulk Download',
  messageFileNotSupported1stHalf: 'Archivos TIFF, vídeos y audios no soportados. Los siguientes:',
  messageFileNotSupported2ndHalf: 'han sido eliminados de tu lista de descarga.',
  licenseOnBehalf: 'License on Behalf',
  customizedPortal: 'Customized portal',
  normalDownload: 'Normal download',
  myCredit: 'My credit',
  lowCreditsReminder: 'Low credits reminder',
  downloadModalId: 'Identificador de la imagen:',
  downloadModalPrice: 'Crédito:',
  clearAll: 'Clear all',
  myActivities: 'My activities',
  info: 'Información',
  licenseOnBehalfOfYourClient: 'License on behalf of your client (Your client owns the license)',
  idCapitialization: 'ID',
  downloadUsing: 'download this content using',
  // homepage page
  home3Title: 'Free Browsing',
  toSearchKeyword: 'Escriba su búsqueda aquí',
  toSearchAIKeyword: 'Ingrese palabras clave, título del artículo o título de blog',
  allImages: 'Todas las imágenes',
  freeImages: 'Free Images',
  categoryImage: 'Category',
  recentViewed: 'Vistas recientemente',
  latestIMG: 'Latest Images',
  // Setting
  hello: 'Hola,',
  adminControlPanel: 'Panel de control Cop + Admin',
  downloadHistory: 'Historial de descargas',
  hruc: 'HRUC',
  changePassword: 'Cambiar contraseña',
  logOut: 'Desconectar',
  viewAccount: 'View Account',
  myAccount: 'Cuenta',
  myCollections: 'Mis colecciones',
  personalCredits: 'Créditos Personales',
  groupTotal: 'Grupo Total',
  toTalUnpaidLicense: 'Licencia no pagada',
  highRUnwatermarkComp: 'Imágenes en alta resolución para boceto',
  monthSelection: 'Month selection',

  // Menu
  categories: 'Categorias',
  myLikebox: 'Mi mesa de luz',
  settings: 'Ajustes',
  notification: 'Notificaciones',
  clear: 'limpiar',
  likebox: '收藏夹',
  credits: 'Créditos',
  // Credit summary at header
  downloadAndUsege: 'Descarga y uso',
  totalDlCredit: 'Créditos Disponibles',
  allocatedDlCredit: 'Créditos asignados',
  personalAvailableCre: 'Créditos personales disponibles',
  groupAvailableCre: 'Créditos del grupo disponibles',
  availableDlSlot: 'Descargas disponibles',
  uNPLD: 'Descargas de UNPL (use now pay later)',
  hiResHRUC: 'Imágenes en alta resolución para boceto (HRUC)',
  monthlyAllocation: 'Asignación mensual',
  remainingQuota: 'Saldo disponible',
  remainingHRUCQ: 'Saldo de bocetos HRUC',
  useNowPayLater: 'Use-Now-Pay-Later (UNPL)',
  totalPendingLicense: 'Total Pending License Downloads',
  enterprisePlan: 'Corporate+ Enterprise Plan',
  unallocatedCredits: 'Unallocated Credits',
  unutilizedAllocatedCredits: 'Unutilized Allocated Credits',
  usersTitleTotalCredits: 'Users',
  groupsTitleTotalCredits: 'Grupos',
  titleTotalCreditsAvailable: 'Total Available',
  // live chat
  liveChatLookIngHelp: 'Please let us know how we can help you.',
  liveChat: 'Live chat',
  whatsApp: 'WhatsApp',
  hiThere: 'Hi there,',
  // email response
  emailIsNotAvailable: 'Correo electrónico no disponible.',
  anErrorHaSoccured: 'Se ha producido un error, por favor intenta de nuevo.',
  emailHasBeenSent:
    'El correo electrónico ha sido enviado. Revisa tu bandeja de entrada para continuar. Hemos enviado un correo electrónico a __email con las instrucciones para recuperarla. Si no recibes el correo en 5 minutos por favor revisa en tu bandeja de correo no deseado.',
  // INVALIDPASSWORD
  inValidPassword: '¡Contraseña inválida! comprueba que el bloqueo de mayúsculas no eté activado.',
  userIsBlocked: 'Demasiados intentos fallidos, has sido bloqueado temporalmente.',
  blockWarrings: 'Será bloqueado temporalmente después de 10 intentos.',
  // Update Password
  resetPwdUpdated: 'Tu contraseña ha sido actualizada.',
  updatePwDfailed: 'No se ha podido actualizar la contraseña.',
  // notification
  // Notification Side Bar - user
  notificationShareLikebox: 'shared a Likebox named [{VAR1}] with you.',
  notificationOutofCredits: 'ran out of download credits.',
  notificationAllcateCredit: 'assigned [{VAR1}] download credits to you.',
  notificationRemoveCredits: 'removed [{VAR1}] download credits from your account.',
  notificationAssignGroups: 'assigned you into the [{VAR1}] group.',
  notificationRemoveFromGroup: 'removed you from the [{VAR1}] group.',
  purcahsedCredits: 'You have just purchased [{VAR1}] credits.',
  notificationAssignAdmin: 'assigned you as the administrator.',
  notificationRemoveAdmin: 'removed you as the administrator.',
  notificationAssignGroupAdmin: 'assigned you as the group administrator of [{VAR1}].',
  notificationRemoveGroupAdmin: 'removed you as the group administrator of [{VAR1}].',
  // Notification Side Bar - admin
  adminNotificationShareLikebox: 'shared a Likebox named [{VAR1}] with [{RECEIVER}].',
  adminNotificationAllcateCredits: 'assigned [{VAR1}] download credits to [{RECEIVER}].',
  adminNotificationRemoveCredist: "removed [{VAR1}] download credits from [{RECEIVER}]'s account.",
  adminNotificationAssignGroup: 'assigned [{RECEIVER}] into the [{VAR1}] group.',
  adminNotificationRemoveFromGroup: 'removed [{RECEIVER}] from the [{VAR1}] group.',
  adminPurchasedCredits: '[{RECEIVER}] have just purchased [{VAR1}] credits.',
  adminNotificationAssignAdmin: 'assigned [{RECEIVER}] as the administrator.',
  adminNotificationRemoveAdmin: 'removed [{RECEIVER}] as the administrator.',
  adminNotificationAssignGroudAdmin:
    'assigned [{RECEIVER}] as the group administrator of [{VAR1}].',
  adminNotificationremoveGroupAdmin: 'removed [{RECEIVER}] as the group administrator of [{VAR1}].',
  // datetime
  year: 'year',
  month: 'month',
  week: 'week',
  day: 'day',
  hour: 'hour',
  minute: 'minute',
  second: 'second',
  ago: 'ago',
  justNow: 'just now',
  // datetimes
  years: 'years',
  months: 'months',
  weeks: 'weeks',
  days: 'days',
  hours: 'hours',
  minutes: 'minutos',
  seconds: 'seconds',
  // LicenseAgreement
  licenseAgreement: 'Acuerdo de licencia',
  licenseAgreementCaption:
    'PLEASE READ ALL THE TERMS PROVIDED IN THIS CORPORATE+ CONTENT LICENSING PLATFORM. YOUR ACCEPTANCE OF THESE TERMS IS AN ABSOLUTE CONDITION OF YOUR USE OF THE CORPORATE+ CONTENT LICENSING PLATFORM AND THE CONTENT, AND FORMS A LEGAL AGREEMENT. BY ACCESSING ANY SERVICE OR PART THEREOF UNDER THE CORPORATE+ CONTENT LICENSING PLATFORM, YOU AGREE TO BE BOUND BY THE TERMS OF THESE LEGAL DOCUMENTS. 123RF RESERVES THE RIGHT TO CHANGE THESE TERMS AT ANY TIME WITHOUT PRIOR WRITTEN NOTICE, AND YOU AGREE TO BE BOUND BY SUCH CHANGES.',
  corpplusAgreement: 'Corporate+ Agreement',
  // login
  usernameCannotEmpty: 'Debes introducir tu nombre de usuario',
  passWordCannotEmpty: 'Debes introducir tu contraseña',
  oldPasswordCannotEmpty: 'El campo de contraseña anterior no puede estar vacío',
  confirmPasswordCannotBeEmpty: 'El campo de confirmación de contraseña no puede estar vacío',
  // update password
  newpasswordDifferent: 'The new pasword and old pasword must be different.',
  // sent email not 20 times
  forgotNot20: 'No se pueden enviar más correos electrónicos, por favor revisa tu buzón.',
  // errorMesServer
  errorMesServer: 'An error has occured, please try again or contact your administrators',
  // white space
  canNotContainSpaces: 'Password cannot contain spaces',
  // username is not available
  userNameNotAvailable: 'Username is not available.',
  // Username cannot contain spaces
  usernameCanNotContainSpaces: 'Username cannot contain spaces',
  // old browsers
  usingOlderBrowsers: '¿Estás usando un navegador más antiguo?',
  upgradeBrowser:
    'Actualizar a una nueva versión del navegador para disfrutar de una experiencia más suave!',
  clickToDownload: 'Haga clic para descargar cualquiera de estos:',
  googleChrome: 'Google Chrome',
  mozillaFirefox: 'Mozilla Firefox',
  microsoftEdge: 'Microsoft Edge',
  safari: 'Safari',
  // forgot page new description
  forgotPwdDescNew: "Enter your username below and we'll send you a link to reset your password.",
  // Notification
  readAll: 'Read All',
  viewAll: 'Ver todo',
  // Filter bar
  aiGenerate: 'Imagen generada por IA',
  aiContent: 'Incluir contenido generado por IA',
  filter: 'Filtros',
  safeSearch: 'Búsqueda segura',
  aiAssist: 'Asistencia  IA',
  freshness: 'Frescura',
  gender: 'Sexo',
  sortBy: 'Ordenar por',
  mediaType: 'Tipo de archivo',
  orientation: 'Orientación',
  modelPreferences: 'Preferencia de modelo',
  people: 'Gente',
  more: 'Más',
  numOfPeople: 'Número de Personas',
  age: 'Edad',
  ethnicity: 'Origen étnico',
  collection: 'Colecciones',
  uploaded: 'Uploaded',
  style: 'Estilo',
  cutOut: 'Fondos sólidos/transparentes',
  doNotIncludeWords: 'No incluir estas palabras',
  doNotIncludeWords_ex: 'ex. queso',
  color: 'Color',
  max1Color: 'Máximo 1 color',
  license: 'Tipo de licencia',
  fps: 'FPS',
  resolution: 'Resolución',
  clipDuration: 'Duracion Clip (minutos)',
  loop: 'Loop',
  category: 'Categoría',
  genre: 'Género',
  tempo: 'Tempo (bpm)',
  // Filter button text
  Relevance: 'Aleatorio',
  New: 'Nuevo',
  Georank: 'Georank',
  DownloadHistory: 'Historial de descargas',
  Popularity: 'Popularidad',
  Photography: 'Fotografía',
  VectorIllustration: 'Ilustración vectorial',
  Horizontal: 'Horizontal',
  Landscape: 'Paisaje',
  Portrait: 'Retrato',
  Square: 'Cuadrado',
  Panorama: 'Panorámica',
  numPpl: 'Número de Personas',
  WithoutPeople: 'Sin gente',
  FilterNumberPeople1: '1 person',
  FilterNumberPeople2: '2 People',
  FilterNumberPeople3: '3 People',
  FilterNumberPeople4: '4 people or more',
  Exclude: 'Excluir',
  SelectiveFocus: 'Selective Focus',
  Pattern: 'Pattern',
  Vibrance: 'Vibrance',
  Infants: 'Infants',
  Toddlers: 'Toddlers',
  Teens: 'Teens',
  PastWeek: 'Última semana',
  LastMonth: 'Last month',
  PastThreeMonths: 'Último 3 meses',
  PastTwodays: 'Últimos 2 días',
  Male: 'Hombre',
  Female: 'Mujer',
  Standard: 'Estándar',
  extended: 'Extendido',
  Editorial: 'Editorial',
  Commercial: 'Comercial',
  Black: 'Black',
  Caucasian: 'Caucásico',
  AfricanAmerican: 'Afroamericano',
  Asian: 'Asiático',
  EastAsian: 'East Asian',
  Hispanic: 'Hispano',
  Indian: 'Indian',
  MiddleEastern: 'Middle Eastern',
  SoutheastAsian: 'Southeast Asian',
  Children: 'Niños',
  Teenagers: 'Adolescentes',
  Adults: 'Adultos',
  Seniors: 'Mayores',
  FPS1: '23.98/24',
  FPS2: '25',
  FPS3: '29.7/30',
  FPS4: '60',
  FPS5: '> 60',
  Off: 'Off',
  ZeroToOne: '0-1',
  OneToTwo: '1-2',
  TwoToThree: '2-3',
  LagerThree: '>3',
  Loopable: 'Sí',
  Nonloopable: 'No',
  Music: 'Música',
  SoundEffects: 'Efecto de sonido',
  LogoAndIdents: 'Logos & Idents',
  Veryslow: 'Muy lento',
  VeryslowSubText: '(0-79)',
  Slow: 'Lento',
  SlowSubText: '(80-119)',
  Medium: 'Medio',
  MediumSubText: '(120-139)',
  Fast: 'Rápido',
  FastSubText: '(140-159)',
  Veryfast: 'Muy rápido',
  VeryfastSubText: '(160>)',
  TwoToEight: '2 - 8',
  TwentyToThirTy: '12 - 30',
  ThirtySixToSixty: '30 - 60',
  OneHundredAndTwenty: '120',
  Popular: 'Popular',
  MostDownload: 'Most Download',
  Credits: 'Créditos',
  Freshness: 'Frescura',
  pickAGenre: 'Elija un género',
  // Paginations
  Of: 'de',
  Page: 'Página',
  // home
  recommended: 'Recomendado para ti',
  bestSellingHome: 'Browse best-selling royalty photos',
  trendingKeyWord: 'Palabras clave de tendencia',
  FAQ: 'Frequently Asked Questions (FAQ)',
  // audio
  categoriesAudio: 'HD stock audios',

  // genre list audios
  gameSounds: 'Sonido de Juegos',
  cartoonSounds: 'Sonidos de Dibujos Animados',
  soundPacks: 'Pack de sonidos',
  interfaceSounds: 'Sonidos de Interface',
  transitionsAndMovementsSounds: 'Transiciones y Movimientos',
  natureSounds: 'Sonidos Naturaleza',
  domesticSounds: 'Sonidos Domesticos',
  urbanSounds: 'Sonidos Urbanos',
  industrialSounds: 'Sonidos Industria',
  futuristicSounds: 'Sonidos Futurista',
  humanSounds: 'Sonidos Humanos',
  othersSounds: 'Otros',
  ambientSounds: 'Ambiente',
  childrenSounds: 'Infantil',
  cinematicSounds: 'Cinematico',
  classicalSounds: 'Clasico',
  corporateSounds: 'Corporativo',
  electronicaSounds: 'Electrónica',
  soulSounds: 'Soul & R&B',
  jazzSounds: 'Jazz',
  popSounds: 'Pop',
  worldBeatSounds: 'World Beat',

  countrySounds: 'Country & Western',
  drumSounds: 'Bombo y Contrabajo',

  folkSounds: 'Popular & Acustico',
  funkSounds: 'Funk & Groove',

  hiphopSounds: 'Hip Hop',
  holidaySounds: 'Vacaciones y Estaciones del año',

  rockSounds: 'Rock',

  individualSounds: 'Individual',
  introSounds: 'Intro y Outro',
  ascSounds: 'Creciente y Decreciente',
  businessSounds: 'Negocios & Medios',
  orchestralSounds: 'Orquesta',
  festiveSounds: 'Fiesta',
  percussiveSounds: 'Percusión',
  miscellaneousSounds: 'Miscelánea',
  packsSounds: 'Packs',
  percussionSounds: 'Zvuky perkusí',
  sampleLoopSounds: 'Zvuková smyčka',

  // Search images
  searchImage: 'Fotos y vectores',
  StockPhotosAndImages: 'Fotos e imágenes de stock',
  StockVectorClipartAndIllustration: 'Ilustraciones y ficheros vectoriales de archivo',

  matches: 'resultados',
  editorial: 'Editorial',
  downloaded: 'Esta imagen se ha descargado con anterioridad',
  to: 'to',
  relatedSearches: 'Búsquedas relacionadas',
  similarTo: 'Similares',
  searchByImage: 'Busca a través de imágenes',
  options: 'Opciones',
  searchByImageMsg:
    'Buscar en 123RF con una imagen en lugar de texto.ntente arrastrar una imagen al cuadro de búsqueda.',
  mustBeJPGorPNG: 'La imagen debe ser JPG / PNG',
  sizeLessThan5MB: 'El tamaño de la imagen debe ser inferior a 5 MB',
  sizeLessThan4MB: 'El tamaño de la imagen debe ser inferior a 4 MB',
  dragOrDropFile: 'Arrastrar y soltar el archivo o',
  browse: 'Buscar',
  uploadedImage: 'Imagen cargada',
  similarStockPhotos: 'Imágenes similares',
  // Search footage
  searchFootage: 'Vídeos',
  StockFootage: 'Vídeos de stock',
  businessFootage: 'Hacks to make the most of your business videos',
  // Details pages
  audioDetail: 'Audio Detail',
  imageDetail: 'Image Detail',
  footageDetail: 'Material de archivo Detalle',
  footageProperties: 'Propiedades de la grabación',
  audioProperties: 'Propiedades de audio',
  modalDownloadTitle: 'Opciones de descarga de archivos',
  credit: 'Crédito',
  saveAs: 'Guardar como',
  ownLicense: 'Descarga normal (licencia propia)',
  downloadAs: 'Descargar como',
  insufficientCredits: 'Créditos insuficientes',
  custom: 'Personalizado',
  personalAndCom: 'Uso personal y comercial Anuncios, presentaciones, redes sociales, web.',
  licenseType: 'Tipo de licencia',
  requiredForResale:
    'Requerido para la reventa o distribución en presentaciones teatrales, acceso de pago, video en streaming, juegos y aplicaciones.',
  stockAudioKeywords: 'Palabras clave de audio de stock',
  stockFootageKeywords: 'Palabras clave',
  stockPhotoKeywords: 'Stock photo keywords',
  limitedLicenseSizes: 'Limited License Sizes',
  stereo: 'Estéreo',
  fileSize: 'Tamaños de archivo',
  footageId: 'ID Vídeo',
  audioId: 'Audio ID',
  imageId: 'Image ID',
  // Search audio
  searchAudio: 'Audio (Música y efectos especiales)',
  StockAudio: 'Audio de stock',
  similarTracks: 'Similar Tracks',
  viewAlbum: 'View Album',
  copyLink: 'Copiar enlace',
  preview: 'Pré-visualização',

  bestSellingFootage: 'Explorar las imágenes de pago más vendidas',
  featuresAudio: 'Featured audio',
  readmore: 'Read More',
  // justified layout
  similarImage: 'Imágenes similares',
  addToLikebox: 'Add to Likebox',

  // recently view empty data
  rVPhoto: 'Las imágenes que hayas visto recientemente se mostrarán aquí',
  rVVectors: 'Sus vectores vistos recientemente se mostrarán aquí',
  rVFootage: 'Su video recientemente visto se mostrará aquí',
  rVAudio: 'El audio que has visto recientemente se mostrará aquí',
  // recently likes empty data
  rLPhoto: 'Your recently liked images will show here',
  rLVectors: 'Your recently liked vectors will show here',
  rLFootage: 'Your recently liked video will show here',
  rLAudio: 'Your recently liked audio will show here',
  // Content Unavailable
  contentUnavailable: 'Content Unavailable',
  contentError: 'We’re sorry, there’s some error displaying this content',
  emptySearchResultTitle: 'Oops, tu búsqueda con esos filtros no ha dado ningún resultado.',
  emptySearchResultTitleWithoutFilter: 'Oops, your search did not return any results.',
  emptySearchResultSubTitle: 'Intente eliminar algunos filtros o pruebe con otra palabra clave.',
  emptyReverseSearchTitle: 'No se han encontrado resultados',
  emptyReverseSearchSubTitle1:
    'La imagen cargada contiene errores, asegúrate de que la imagen cumple con las siguientes características:',
  emptyReverseSearchSubTitle2: 'Controla tu búsqueda con palabras claves',
  emptyReverseSearchSubTitle3: 'La imagen tiene que ser JPG/PNG',
  emptyReverseSearchSubTitle4: 'El tamaño de la imagen debe ser inferior a 5MB',
  emptySearchResultClearFilter: 'Borrar todos los filtros',
  emptyDownloadHistoryResult: 'Your download history will show here',
  // Please enter a keyword
  keywordEmpty: 'Introduce palabras clave',
  // admin redirect to
  plansAndPricing: 'Plans and Pricing ',
  // Footer langding page
  nameUnavailable: 'Name unavailable',
  addressUnavailable: 'Company address unavailable',
  phoneUnavailable: 'Phone number unavailable',
  faxUnavailable: 'Fax unavailable',
  iddUnavailable: 'IDD unavailable',
  emailUnavailable: 'Email unavailable',
  contentTermLicense1:
    'This Agreement shall be governed by the laws of Singapore, without regard to the conflict of laws principles. ',
  contentTermLicense2:
    'the parties agree that the dispute shall be settled by arbitration in Singapore under the Singapore International Arbitration Centre (“SIAC”) administered SIAC Arbitration Rules in force when the notice of arbitration is submitted in accordance with these rules. The number of arbitrators shall be one (1). The language of the arbitration shall be English.',
  contentTermLicense3:
    'that damages and remedies under Singapore Law for any breach of any such covenant would be inadequate. You further acknowledge and agree that the arbitral tribunal may order any interim measure it deems necessary or proper in accordance with the applicable laws of Singapore,',
  contentTermUsLicense1:
    'This Agreement shall be governed by the laws of the state of Delaware, United States of America, without regard to the conflict of laws principles',
  contentTermLicenseUs2:
    ' the parties agree first to try in good faith to settle the dispute by mediation administered by the American Arbitration Association under its Commercial Mediation Procedures before resorting to arbitration, litigations or some other dispute resolution procedure. In the event if the dispute is settled via arbitration, the number of arbitrators shall be one (1) and the language of the arbitration shall be English.',
  contentTermUsLisence3:
    ' that damages and remedies under applicable law for any breach of any such covenant would be inadequate. You further acknowledge and agree that the arbitral tribunal may order any interim measure it deems necessary or proper in accordance with the applicable law,',
  contentCorporateUs:
    'This Agreement shall be governed by the laws of the State of Delaware in the United States of America, without regard to the conflict of laws principles. All disputes arising out of or in connection with the performance of this Agreement shall be settled through negotiations and if the dispute cannot be settled through negotiation, the parties agree first to try in good faith to settle the dispute by mediation administered by the American Arbitration under its Commercial Mediation Procedures before resorting to arbitration, litigation, or some other dispute resolution procedure. In the event if the dispute is settled via arbitration, the number of arbitrators shall be one (1), the language of the arbitration shall be English and the arbitral award shall be final and binding upon parties.',
  contentCorporate:
    'This Agreement shall be governed by, and shall be construed in accordance with, the laws of Singapore. All disputes arising out of or in connection with the performance of this Agreement shall be settled through friendly negotiations. If the parties are unable to resolve any such dispute within thirty (30) days after the commencement of negotiations, the parties agree that the dispute shall be settled by arbitration in Singapore under the Singapore International Arbitration Centre (“<b>SIAC</b>”) administered by SIAC Arbitration Rules in force when the notice of arbitration is submitted in accordance with the rules. The number of arbitrators shall be one (1). The decision of the arbitrator shall, except in the case of manifest error, be final and binding upon the parties hereto. The arbitration proceedings shall be conducted in English.',
  agreeLicense: 'I AGREE to abide by all the Terms and Conditions above',
  btnAgree: 'AGREE',
  btnDecline: 'DECLINE',
  messageCheckbox:
    'Please read the License Agreement above carefully and signify your agreement by clicking the "I AGREE" check box.',
  // collection search result page
  searchCollection: 'Buscar colecciones',
  personal: 'Personal',
  groups: 'Grupos',
  createNew: 'Crear nueva',
  createNewCollection: 'Crear una nueva colección',
  descriptionPlaceholder: 'Add an optional description for your collection here.',
  titleItem: 'Título',
  selectGroup: 'Seleccionar grupo',
  description: 'Descripción',
  charactersRemaining: '180 characters remaining',
  cancel: 'Cancelar',
  create: 'Crear',
  defaultCollection: 'Colección por default',
  addToMyCollections: 'Agregar a la mesa de luz',
  addedToCollection: 'Añadido a la colección',
  removedFromCollection: 'Eliminado de la colección',
  collectionModalPlaceholder: 'por ejemplo, Naturaleza',
  groupNameShowsHere: 'Group name shows here',
  // like box
  btnViewCollection: 'View Collection',
  shareWithMe: 'Compartido conmigo',
  addLikebox: 'Add New Likebox',
  notImageLikeBox: 'You do not have any images in your likebox yet',
  owner: 'Owner',
  images: 'Imágenes',
  likeboxName: 'Likebox Name',
  likboxNameMaxLength50: 'The maximum characters allowed is 50',
  addLikeBoxTo: 'Add likebox to',
  personalLikebox: 'Personal',
  btAdd: 'ADD',
  btCancel: 'CANCEL',
  btSave: 'SAVE',
  editLikebox: 'Edit Likebox',
  share: 'Compartir',
  // reset password
  resetPwdTitle2: 'Reset Password',
  noFoundLikebox: 'No tienes ninguna colección.',
  btnUnDo: 'Deshacer',
  // my collection
  dateAdded: 'Date Added',
  collectionName: 'Collection Name',
  selectAll: 'Seleccionar todo',
  delete: 'Borrar',
  seeAll: 'See All',
  deleteCollection: 'Delete Collection',
  deleteWarning:
    'Completing this action will permanently delete your Collection along with its contents. Be aware that this action cannot be undone.',
  createCollection: ' ha sido creada',
  shareCollection: 'Share Collection',
  shareWithTeam: 'Share in team',
  selectUsers: 'Select users',
  searchUserName: 'Search a users name',
  copy: 'Copiar',
  titleCopyModal: 'Copiar los artículos seleccionados a',
  collectionUppercase: 'COLLECTION',
  groupCollection: 'Grupo',
  moveImage: 'Mover los artículos seleccionados a',
  defaultSelect: 'Collection Name Here',
  moveLikebox: 'Move',
  messsageDeleteLikebox:
    'Completing this action will permanently delete your Collection(s) along with its contents. Be aware that this action cannot be undone.',
  areYouSure: 'Are you sure?',
  editCollection: 'Edit collection',
  collectionNameUpper: 'COLLECTION NAME',
  deleteSuccessful: 'has been successfully deleted',
  edit: 'Editar',
  messageRemoveImage: 'Are you sure you want to remove the selected the item from the collection?',
  titleRemove: 'Eliminar los artículos seleccionados',
  popupActionMove: ' item(s) has been moved to ',
  popupActionCopy: ' item(s)has been copied to ',
  popupActionRemove: ' item(s) has been removed from this Collection',
  popupFailed: 'Image already exists',
  popupShared: 'Your collection has been successfully shared',
  popupActionDelete: 'Your collection has been successfully removed',
  popupActionEdit: 'Your edit has been successfully updated',
  btnSave: 'Guardar',
  shareLikebox: 'Compartido conmigo',
  remove: 'Eliminar',
  moveTo: 'Mover a',
  createNewPlaceholder: 'e.g. Nature',
  shareViaEmail: 'Compartir vía email',
  sharePublicly: 'Share publicly',
  receipnetEmail: 'Email del destinatario',
  message: 'Mensaje',
  insertReceipmentEmail: 'Insert receipment email',
  messageText: 'Message text.',
  exportCollection: 'Export collection',
  pdf: 'PDF',
  csv: 'CSV',
  addNewCollection: 'Add New Collection',
  likeboxEditSuccessful: 'You have successfully made changes to your collection.',
  format: 'Formato',
  lowCredit: 'low credit',
  downloadSuccess: 'Gracias. Tu descarga comenzará en un momento.',
  downloadFailed: 'Error de descarga: Ha ocurrido un error. Inténtalo de nuevo.',
  downloadedLabel: 'Descargadas',
  any: 'Cualquier',
  resetFilter: 'Reset filter',
  enterCustomFieldTag: 'Enter custom field tags',
  // Portfolio
  follow: 'Seguir',
  name: 'Nombre',
  location: 'Lugar',
  since: 'Desde',

  // Cuenta
  updated: 'Update',
  userName: 'user name',
  firstName: 'Nombre',
  lastName: 'Apellido',
  myProfile: 'My Profile',
  changeProfiledetail: 'Change your profile details here',
  userPrivileges: 'User Privileges',
  viewAllDownload: 'View all download history',
  viewDownloadCost: 'View download cost',
  accountManagerInfo: 'Account manager info',
  describeAccountmanager: 'Describe what this account manager do',
  creditsPlan: 'Credits plan',
  myPlan: 'Mis planes',
  myOrganisation: 'My organisation plan',
  myAccountProfileDetails:
    'This are your profile details set by the organisation admin. If you wish to change your credentials, kindly contact admin.',
  usePayLater: 'Use Now, Pay Later',
  bodyUNPL:
    'Use Now, Pay Later (UNPL) is a type of short-term financing that allows consumers to make purchases and pay for them at a future date, often interest-free. Also referred to as "point of sale installment loans.',
  firstnameCannotEmpty: 'First name cannot be empty.',
  lastnameCannotEmpty: 'Lastname cannot be empty.',

  noSpecialCharacters: 'This field cannot contain special characters',
  messageUpdateProfile: 'You had successfully updated the user information',
  bodyCredit:
    'Vitae morbi tristique luctus sed eget donec nibh in duis neque donec lectus turpis turpis aliquam facilisis mattis laoreet enim',
  contactYourAccount: 'Contact your account manager for further assistane',
  emailMaxLength75: 'The maximum characters allowed is 75',
  // audio player
  categoryGenre: 'Categoría / Género',
  duration: 'Duración',
  seeMore: 'Mostrar más',
  seeLess: 'Mostrar menos',
  audioID: 'Identificador del Audio',
  // category
  landscape: 'Paisaje',
  Babies: 'Babés',
  business: 'Negocio',
  food: 'Comida',
  animals: 'Animales',
  technology: 'Tecnologiá',
  healthcare: 'Cuidado de la salud',
  nature: 'Naturaleza',
  romance: 'Romance',
  // category vector
  graphic: 'Gráfico',
  advertising: 'Publicidad',
  flower: 'Flor',
  abstract: 'Abstracto',
  Illustration: 'Ilustración',
  pop: 'Música pop',
  bird: 'Pájaro',
  pixel: 'Pixel',
  threeD: '3D',

  // footage category
  education: 'Educacion',
  communication: 'Comunicación',
  beauty: 'Belleza',
  concept: 'Concepto',
  fitness: 'Fitness',
  lifestyle: 'Lifestyle',
  celebration: 'Celebracion',
  family: 'Familia',
  fashion: 'Moda',

  // Download-history modal
  reDownload: 'Re-download',
  chooseCustomTagOrCreate: 'Choose custom tag(s) or create a new one',
  downloadDetails: 'Download details',
  image: 'Image',
  downloadType: 'Tipo de descargas',
  groupName: 'Group name',
  size: 'Tamaño',
  dateDownloaded: 'Fecha de desçcarga',
  reLicense: 'Re-license',
  customFields: 'Custom fields',
  saveAndClose: 'Save and close',
  characterMaxChar100: 'The maximum characters allowed is 100',
  characterNotAllow:
    'The maximum characters allowed is 100 and the minimum characters allowed is 1',
  dlwByMember: 'Dowloaded By Members',
  groupInactive: 'Group inactive',
  personalInactive: 'Personal inactive',
  date: 'Fecha',
  or: 'O',
  export: 'Exportar',
  selectRange: 'select range',
  fileType: 'file type',
  downloadedLabelUNPL: 'Licensed',
  HRUCTab: 'Hi-res comp',
  searchById: 'Search by ID',
  pleaseSelect: 'Por favor seleccionar',
  totalOutstanding: 'Total Outstanding',
  viewInvoices: 'Ver Factura',
  // Image detail page - select download size sidebar
  standardSizes: 'Tamaños estándar',
  extendedLicenseSizes: 'Extended License Sizes',
  crop: 'Recortar',
  // empty data
  opsNothingToSeeHere: '¡Ups! Nada que ver aqui.',
  // search image
  // search audio
  mostDownloaded: 'Lo más descargado',
  download: 'Descarga',
  // audio category
  soulAndRB: 'Soul & R&B',
  corporate: 'Corporativo',
  electronica: 'Electrónica',
  classical: 'Clásico',
  funkAndGrooveMusic: 'Funk Groove',
  worldBeat: 'World Beat',
  cinematic: 'Cinemático',
  jazz: 'Jazz',
  flamenco: 'Flamenco',
  mothersDayLove: 'Mothers Day Love',
  happyUplifting: 'Happy Uplifting',
  epicHeroAdventure: 'Epic Hero Adventure',

  // search page
  nextPage: 'Página siguiente',
  reverseImageSearch: 'Resultado De La Búsqueda De Imágenes',

  group: 'Grupo',
  needHelp: 'Need help?',
  copied: 'Copied!',
  copyPortfolioLinkSuccess: 'Enlace de cartera de contribuyentes copiado al portapapeles.',

  // my activities
  myActivity: 'My activities',
  recentLike: 'My Recent Likes',
  recentView: 'My Recent Views',
  myActivityViewDesc: 'Recent view history will only store up to 3 months only',
  myActivityLikeDesc: 'Only showing history in the recent 3 months',

  // dashboard
  profile: 'Perfil',

  // Pricing
  pricingCreditText: 'Crédito',

  // Dashboard - Credit Usage
  user: 'User',
  creditUsage: 'Credit usage',
  searchPlaceholder: 'Search name, username, role, group',
  all: 'Todos',
  active: 'Active',
  deleted: 'Deleted',
  reset: 'Restablecer',
  totalAllocated: 'Total allocated',
  totalSpent: 'Total spent',
  balance: 'Balance',
  groupLowerCase: 'group',
  roleDefinitions: 'Role definitions',
  superAdmin: 'Super admin',
  admin: 'Admin',
  historySearchPlaceholder: 'Search history',
  somethingWentWrong: 'Something went wrong',
  information: 'Information',
  maximumBulkDownloadWarning: 'Bulk download allows maximum 50 items',
  tooltipCustomLogin: 'Please use at least 8 characters',
  maxLengthTitleCustom: 'Title should not be more than 25 characters',
  maxLengthDescCustom: 'Description should not be more than 50 characters',
  selectdItems: 'Selected media',
  aiPoweredSearch: 'Búsqueda por IA',
  aiPoweredSearchOn: 'La búsqueda con IA está en marcha',
  aiSearchIntroduction:
    'Pruebe nuestro motor de búsqueda más rápido y potente. ¡Nuestra búsqueda de IA se encargará del resto!',
  ok: 'OK',
  // Filter bar ai
  authentic: 'Auténticas',
  macroCloseUp: 'Macro/ primer plano',
  object: 'Objeto',
  watercolor: 'Acuarela',
  flat: 'Plano',
  cartoon: 'Dibujos animados',
  geometric: 'Geométrico',
  gradient: 'Gradiente',
  isometric: 'Isométrico',
  '3d': '3d',
  handDrawn: 'Dibujado a mano',
  natural: 'Natural',
  warm: 'Cálido',
  dramatic: 'Dramático',
  vividAndBold: 'Vivo y atrevido',
  blackAndWhite: 'Blanco y negro',
  // introduce Free image research
  imageResearchContentTitle:
    'Presentamos el servicio de búsqueda de imágenes gratuito de 123RF: imágenes perfectas, cero complicaciones',
  imageResearchContentDesc:
    'Revolucionamos la búsqueda de imágenes para clientes corporativos, facilitando la búsqueda de la imagen perfecta y llevar a cabo tu idea.',
  submitRequest: 'Enviar solicitud',
  imageResearchContentInfoTitle1: 'Búsqueda de imágenes simplificada',
  imageResearchContentInfoDesc1:
    'Comenzar es fácil. Simplemente proporciónanos una descripción detallada de la imagen que deseas, incluyendo estilo, estado de ánimo, colores y elementos específicos. Cuantos más detalles nos des, mejor podremos satisfacer tus necesidades.',
  imageResearchContentInfoTitle2: '¿Cómo funciona?',
  imageResearchContentInfoTitle3: 'Servicio rápido y fiable',
  imageResearchContentInfoTitle4: 'Déjanos hacer el trabajo por ti',
  imageResearchContentInfoDesc4:
    'Encontramos las imágenes perfectas para ti, para que puedas concentrarte en tu proyecto. Confía en nosotros para dar vida a tu visión con imágenes de alta calidad y cuidadosamente elegidas.',
  requestForm: 'Formulario de solicitud',
  whatDoYouWantThisImageFor: '¿Para qué quieres usar esta imagen?',
  websiteBanner: 'Banner del sitio web',
  socialMedia: 'Redes sociales',
  blogPost: 'Publicación en el blog',
  advertisement: 'Publicidad',
  print: 'Impresión',
  others: 'Otros',
  othersOutputPlaceholder: 'Introducir otro tipo de resultados',
  whatIsPrimaryMessage: '¿Cuál es el mensaje principal o tema que quieres que la imagen transmita?',
  whatIsPrimaryMessagePlaceholder: 'Ej. Perro con pelo corriendo con mujer en el parque',
  areThereSubjectYouWantInThePicture:
    '¿Hay temas o elementos específicos que quieres que se incluyan en la imagen?',
  egPeopleNatureTechnologyAbstract: 'Ej. personas, naturaleza, tecnología, conceptos abstractos',
  areThereImageOrStyleReference:
    '¿Hay imágenes o estilos que hayas visto antes que te gustaría que usemos como inspiración o referencia?',
  ifPeopleIncludeWhatTheirDemographic:
    'Si se van a incluir personas, ¿cuál debería ser su procedencia?',
  egAgeGenderEthnicityOccupation: 'Ej. edad, género, etnia, ocupación',
  areThereAnyThingToAvoid: '¿Hay elementos, temas o temas que quieres evitar en la imagen?',
  whoisYourTargetAudience: '¿Quién es tu público objetivo?',
  whoisYourTargetAudiencePlaceholder: 'Ej. Jóvenes adultos amantes de la lectura',
  typeOfImage: 'Tipo de imagen',
  whatMoodShouldImageEvoke: '¿Qué estado de ánimo o emoción debería evocar la imagen?',
  happy: 'Feliz',
  sad: 'Triste',
  calm: 'Calma',
  excited: 'Emocionado',
  angry: 'Enojado',
  enterCustomMood: 'Introduce una emoción personalizada',
  areThereAnyOrientationForImage: '¿Hay orientaciones específicas necesarias para la imagen?',
  enterCustomOrientation: 'Introduce una orientación personalizada',
  isThereLocationYouWantImageToBeIn:
    '¿Hay un lugar o entorno en particular que quieres que tenga la imagen?',
  no: 'No',
  yes: 'Sí',
  shareWithUsAReference: 'Comparte con nosotros una referencia (solo JPG o PDF. Máx. 4MB)',
  replace: 'Reemplazar',
  areThereAnySpecificRequirements: '¿Hay otros requisitos o preferencias específicas?',
  enterOtherComments: 'Introduce otros comentarios',
  submit: 'Enviar',
  fieldCannotEmpty: 'Este campo no puede estar vacío',
  selectAtLeastOne: 'Por favor selecciona al menos uno',
  fileMustBeJpgOrPdf: 'El archivo debe ser un JPG o un PDF',
  fileSizeLessThan4MB: 'El tamaño del archivo debe ser menor a 4MB',
  maximumFileNameAllow255: 'El número máximo de caracteres permitidos es 255',
  updateSuccessfully: 'Hemos recibido tu solicitud.',
  freeImageResearch: 'Búsqueda gratuita de imágenes',
  newService: '¡Nuevo servicio!',
  freeImageResearchIntroduction:
    'Prueba nuestro servicio de búsqueda de imágenes y descubre por qué nuestras imágenes se ajustan perfectamente a tus requisitos.',
  later: 'Más tarde',
  letTryIt: '¡Vamos a probarlo!',
  viewPdfOfSampleOutput: 'Ver PDF de muestra de resultados',
  characterMaxChar255: 'El número máximo de caracteres permitidos es 255',
  imageResearchContentInfoDesc2Normal1: 'Our team combines',
  imageResearchContentInfoDesc2Strong1: 'AI technology',
  imageResearchContentInfoDesc2Normal2:
    'and experience to find ideal images for you and advanced algorithms narrow down options from our vast library based on your input. Our',
  imageResearchContentInfoDesc2Strong2: 'team of dedicated and experienced researchers',
  imageResearchContentInfoDesc2Normal3: 'refine the results to meet your criteria perfectly.',
  imageResearchContentInfoDesc3Normal1: 'We deliver results within',
  imageResearchContentInfoDesc3Strong1: '1 working day',
  imageResearchContentInfoDesc3Normal2:
    'providing a comprehensive explanation for image selections. Focus on your business while we take care of the rest.',
  office: 'Office',
  beach: 'Beach',
  city: 'City',
  mountains: 'Mountains',
  enterCustomLocation: 'Enter custom location',
  doYouNeedImgWithSpaceForTextOrOtherElement:
    'Do you need an image with space for text or other elements? If so, where should this space be located?',
  center: 'Center',
  topLeft: 'Top left',
  topRight: 'Top Right',
  bottomLeft: 'Bottom Left',
  bottomRight: 'Bottom Right',
  characterMin30: 'A minimum of 30 characters is required',
  required: 'Se requiere',

  requiredWithVariable: 'Se requiere "{{var}}"',
  imageResearchContentInfoDesc2: (
    <>
      Nuestro equipo combina
      {' '}
      <strong>tecnología de IA</strong>
      {' '}
      y experiencia para encontrar las
      imágenes ideales para ti y nuestros algoritmos avanzados reducen las opciones de nuestra vasta
      biblioteca en función de tu entrada. Nuestro
      {' '}
      <strong>equipo de investigadores dedicados y experimentados</strong>
      {' '}
      refinan los resultados
      para cumplir perfectamente con tus criterios.
    </>
  ),
  imageResearchContentInfoDesc3: (
    <>
      Entregamos resultados en
      {' '}
      <strong>dos días laborable</strong>
      , proporcionando una explicación
      completa para la selección de imágenes. Concéntrate en tu negocio mientras nosotros nos
      encargamos del resto.
    </>
  ),
  mood: 'Estado de ánimo',
  person: 'Persona',
  doNotIncludeWords_ex2: 'Por ejemplo, color verde, cabello liso',

  // Text to Image TTI page
  /// Landing page
  aiImageGenerator: 'Generador de imágenes AI',
  aiImageGeneratorDesc: 'Creatividad a la velocidad de tu imaginación. ',
  generate: 'Generar',
  exploreVisualsCreatedWithThePowerOfAI: 'Explore imágenes creadas con el poder de la IA',
  turnYourImaginationIntoRealityIn3SimpleSteps:
    'Convierta su imaginación en realidad en 3 sencillos pasos',
  startWithGoodTextPrompt: (
    <>
      Comience con un
      {' '}
      <br />
      {' '}
      <span>buen texto</span>
    </>
  ),
  startWithGoodTextPromptDesc:
    'Un buen estímulo garantiza grandes resultados. Para obtener los mejores resultados, debe incluir la perspectiva, el sujeto, la acción, las palabras clave, la iluminación y el estilo.',
  reviewYourAIGeneratedImages: (
    <>
      Revise sus
      {' '}
      <br />
      <span>imágenes generadas por IA</span>
    </>
  ),
  reviewYourAIGeneratedImagesDesc:
    'Nuestro generador de IA generará y mostrará 3 imágenes de vista previa que coinciden más con tu indicación.',
  downloadAndLicenseVisuals: (
    <>
      Descarga y
      {' '}
      <br />
      <span>licencia de imágenes</span>
    </>
  ),
  downloadAndLicenseVisualsDesc:
    'Ahora puedes descargar y obtener licencia para estas imágenes para uso personal y comercial con cobertura legal de $25,000.',
  needSomeIdeas: '¿Necesitas algunas ideas? ¡Empieza desde estos ejemplos y regenera tus ideas!',
  tryPrompt: 'Prueba la indicación',
  creativityAtTheSpeedOfYourImagination: 'Creatividad a la velocidad de tu imaginación',
  genYourOwnAIImgFromTxtPromptsWCorpInSeconds:
    '¡Genera tus propias imágenes AI a partir de textos con Corporate+ en segundos!',
  tryNow: 'Pruebe ahora',

  /// Random prompt
  prompt1:
    'Retrato fotorrealista de un astronauta flotando en el espacio con la Tierra de fondo. Utiliza una cámara con un objetivo de 200 mm y una apertura de F 1,2 para desenfocar el fondo y aislar al sujeto. La iluminación debe ser dramática y de ensueño, con el sol iluminando la cara y el traje espacial del astronauta. Activa el modo fotorrealismo para crear una imagen ultrarrealista que capte la inmensidad y la belleza del espacio.',
  prompt2:
    'Una obra de técnica mixta que representa a un hechicero en una pose dramática, con una capa fluida y una varita que emite chispas de magia, en un paisaje místico y de otro mundo, con una variedad de texturas y materiales.',
  prompt3:
    'Juguetona ilustración digital de un bebé con mejillas sonrosadas y un esponjoso body, rodeado de globos de colores y confeti, sobre un fondo alegre y en tonos pastel.',
  prompt4:
    'Dibujo a grafito de un vidente ciego con un tercer ojo en la frente, mirando a lo lejos con expresión serena y mística, con detalles y sombreados intrincados.',
  prompt5:
    'Un collage surrealista de técnica mixta de un vaquero montando un toro mecánico gigante en un páramo desértico, hecho de revistas vintage, mapas antiguos y restos de metal oxidado, con un toque de humor y absurdo.',
  prompt6:
    'Ilustración digital de una reina vampiro de piel pálida y ojos rojo sangre, con un vestido vaporoso y rodeada de murciélagos.',
  prompt7:
    'Retrato de un vidente ciego que sostiene una bola de cristal, captado con una dramática iluminación de claroscuro para acentuar los rasgos faciales del vidente y los reflejos del cristal.',
  prompt8:
    'Ilustración fantástica de una reina sirena con pelo largo y una corona de conchas marinas, nadando en un océano místico lleno de criaturas bioluminiscentes.',
  prompt9:
    'Retrato callejero de un vendedor ambulante hispano que vende frutas y verduras, capturado en un cándido y descarnado blanco y negro, con poca profundidad de campo para resaltar la expresión facial del vendedor.',
  prompt10:
    'Una acuarela caprichosa de una sirena descansando en una orilla rocosa, rodeada de olas oceánicas centelleantes y criaturas marinas juguetonas.',
  prompt11:
    'niña estilo 3d, 4k, 8k, octane render fotorrealista, hdr, fotografía, alta definición, cara simétrica, iluminación volumétrica, neblina polvorienta, foto, octane render, 24mm, 4k, 24mm, DSLR, alta calidad, 60 fps, ultra realista',
  prompt12:
    'Salón con ventanas francesas que dan al jardín, aparador de estilo provenzal, plantas, alfombra de yute, parqué, sofás con cojines encima, lámpara de araña con gota de cristal, revista de interiorismo, ambiente cálido, fotorrealista, luz realista, gran angular, muchos detalles',
  prompt13:
    'Pintoresca cabaña de montaña en la ladera de la montaña, grandes ventanas panorámicas, cabaña geométrica, vista completa, vista panorámica en el crepúsculo, dramático cielo crepuscular, picos bajo la nieve, estrellas, desierto montañoso nevado, hermosa vista, temperamental, niebla, iluminación volumétrica, ambiente de montaña, fotorrealismo, iluminación volumétrica, realista, octane render, ultra detallado, surrealista, fotorrealista, ultrarrealista, 8k, iluminación cinematográfica',
  prompt14: 'Pintura en acuarela de una abuela guay, está sonriendo porque sabe que es guay',
  prompt15:
    'Carretera en medio de un bosque azul profundo, bosque denso, techo de hojas, de noche, estilo cómic, ilustración infantil',
  prompt16:
    'Un astronauta nadando a través de una nebulosa en el espacio + espacio, astronauta, onírico, simbolismo, surrealismo, símbolo, surrealista, abstracto, textura, arte conceptual, 8k, sombreado, rayos de luz, atmosférico',

  /// Tool list
  // ratio
  ratio: 'Relación',
  square1_1: 'Cuadrado (1:1)',
  square: 'Cuadrado',
  portrait2_3: 'Retrato (2:3)',
  portrait: 'Retrato',
  landscape3_2: 'Paisaje (3:2)',
  wide16_9: 'Ancho (16:9)',
  wide: 'Ancho',
  // style
  none: 'Ninguno',
  enhance: 'Mejorar',
  anime: 'Anime',
  photographic: 'Fotográfico',
  digitalArt: 'Arte digital',
  comicBook: 'Cómic',
  fantasyArt: 'Arte fantástico',
  analogFilm: 'Película analógica',
  neonPunk: 'Punk neon',
  lowPoly: 'Bajo poli',
  origami: 'Origami',
  lineArt: 'Arte lineal',
  tileTexture: 'Textura de mosaico',
  threeDModel: 'Modelo 3D',
  pixelArt: 'Pixel art',
  craftClay: 'Arcilla artesanal',
  // color
  coolTone: 'Tono frío',
  mutedColors: 'Colores suaves',
  pastelColors: 'Colores pastel',
  vibrantColors: 'Colores vibrantes',
  warmTone: 'Tono cálido',
  // lighting
  lighting: 'Iluminación',
  backlight: 'Contraluz',
  crepuscularRays: 'Rayos crepusculares',
  dimlyLit: 'Débilmente iluminado',
  goldenHour: 'Hora dorada',
  lowLight: 'Poca luz',
  rimLighting: 'Iluminación de borde',
  studio: 'Estudio',
  sunlight: 'Luz del sol',
  volumetric: 'Volumétrico',
  // composition
  composition: 'Composición',
  blurryBackground: 'Fondo borroso',
  closeUp: 'Primer plano',
  macrophotograph: 'Macrofotografía',
  narrowDepth: 'Profundidad estrecha',
  shotFromAbove: 'Tomada desde arriba',
  shotFromBelow: 'Tomada desde abajo',
  wideAngle: 'Gran angular',

  /// FQAs
  allAIImageQuestionsAnswered: 'Todas sus preguntas de imagen generadas por IA respondieron.',
  TTI_Q1: '¿Qué son las imágenes generadas por IA?',
  TTI_A1:
    'Las imágenes generadas por IA son imágenes creadas mediante algoritmos de inteligencia artificial (IA). Estos algoritmos están diseñados para simular la creatividad humana y pueden generar imágenes similares a las producidas por artistas humanos, pero con elementos únicos y a menudo surrealistas o abstractos.',
  TTI_Q2: '¿Hay algún límite en el número de imágenes que puedo generar al día?',
  TTI_A2:
    'Sí, hay un límite en el número de imágenes que puede generar diariamente. El límite garantiza que todos los usuarios tengan un acceso justo y una buena experiencia de usuario al acceder al servicio de generación de imágenes AI.',
  TTI_Q3: '¿Admite indicaciones de texto en idiomas distintos al inglés?',
  TTI_A3: 'Por el momento, sólo admitimos el idioma inglés.',
  TTI_Q4: '¿Cuál es la diferencia entre generar y licenciar una imagen?',
  TTI_A4:
    'Cuando genera una imagen, crea un conjunto de imágenes de IA para ver. Cuando las licencia en Corporate+, se le otorga oficialmente acceso para descargar una versión de alta resolución de la imagen de IA que viene con su propia licencia de uso.',
  TTI_Q5:
    'Una imagen que he generado contiene una marca comercial, un punto de referencia o una personalidad pública reconocibles. ¿Puedo utilizarla?',
  TTI_A5:
    'Si contiene una persona u objeto reconocible, o se centra principalmente en un punto de referencia distintivo, tendría que obtener permiso directamente de la parte representada en la imagen, especialmente si pretende usarla en un entorno comercial.',
  TTI_Q6: '¿Quiénes son las personas que aparecen en las imágenes generadas?',
  TTI_A6:
    'Las personas que aparecen en las imágenes generadas por IA no son personas reales, sino creaciones digitales.',
  TTI_Q7:
    '¿Qué ocurre con los contenidos que he generado? ¿Se pondrán a disposición de otros usuarios?',
  TTI_A7:
    'Los contenidos que usted genere también podrán ponerse a disposición de otros usuarios. Puede consultar nuestra licencia para obtener más información.',
  TTI_Q8: '¿Por qué tarda tanto en cargarse cuando intento generar una imagen?',
  TTI_A8:
    'La página tardará unos segundos en generar la imagen. El tiempo empleado depende de la complejidad de la solicitud, de los recursos informáticos y de hardware disponibles, así como de la cantidad de solicitudes que la IA tenga que gestionar simultáneamente.',
  TTI_Q9: '¿Cómo puedo obtener licencias para las imágenes AI que he generado?',
  TTI_A9:
    'Podrá licenciar las imágenes de IA que haya creado descargándolas desde su cuenta de Corporate+. Con cada descarga recibirá la versión de alta resolución de la imagen generada y una garantía legal de $25,000.',
  TTI_Q10: '¿Puedo ver las generaciones anteriores?',
  TTI_A10:
    'Sí, puede hacerlo marcando y volviendo a marcar la URL de sus generaciones anteriores. También puedes compartir los resultados de las generaciones con tus amigos.',
  TTI_Q11: '¿Cómo puedo generar imágenes de alta calidad con IA?',
  TTI_A11: `1. Empieza con un objetivo claro: "Imagen de alta calidad de un paisaje de montaña para un blog de viajes".

2. Especifica el estilo y el ambiente deseados: "La imagen debe ser brillante y vibrante con un toque natural y orgánico".

3. Describa el tema: Especifique el tema de la imagen, incluyendo detalles como el entorno, la iluminación y cualquier otra característica o elemento importante. Por ejemplo: "Describe un pico de montaña nevado con un río serpenteante en primer plano y un cielo azul despejado de fondo."

4. Proporcione ejemplos: Incluye ejemplos de otras imágenes que tengan un estilo o ambiente similar al que buscas. Esto puede ayudar a guiar al algoritmo de IA y garantizar que el resultado cumpla tus expectativas.

5. Utilice palabras clave relevantes: "paisaje de montaña", "pico nevado" o "brillante y vibrante".

6. Evite la ambigüedad: Sea lo más claro y específico posible al dar instrucciones, y evite utilizar un lenguaje vago o ambiguo que pueda dar lugar a resultados inesperados.
`,

  /// Generating page
  bringingYourImagesToLife: 'Dé vida a sus imágenes...',
  cplusAiGenerator: 'Generador Corporate+ AI',
  generatedImages: 'Imágenes generadas',
  promptSettings: 'Configuración',
  pastGeneration: 'Generaciones anteriores',
  generateVariation: 'Generar variación',
  iLikeThis: 'Me gusta esto',
  iDislikeThis: 'No me gusta esto',
  shareOptions: 'Opciones de compartir',
  variations: 'Variaciones',
  copiedSharedUrl: 'URL compartida copiada al portapapeles.',

  galleryPrompt1: 'Sirena etérea y modesta, bajo el agua, corona de conchas rodeada de peces, retrato',
  galleryPrompt2: 'Asado dominical abundante en la mesa, lleno de comida y velas',
  galleryPrompt3: 'Zorro esponjoso y lindo sonriendo en un bosque, muy detallado, con otros animales del bosque',
  galleryPrompt4: 'Alta calidad, 8K Ultra HD, formas de instrumentos de notas musicales dentro de una calabaza de cristal, muy detallado, lámpara mágica de calabaza, sensación de otoño, calma',
  galleryPrompt5: 'Paisaje panorámico cinematográfico de Escocia, día luminoso y hermoso',
  galleryPrompt6: 'Niña en estilo 3D, 4k, 8k, renderizado octano fotorrealista, HDR, fotografía, alta definición, cara simétrica, iluminación volumétrica, neblina polvorienta, foto, renderizado octano, 24mm, 4k, 24mm, DSLR, alta calidad, 60 fps, ultra realista',
  galleryPrompt7: 'Chico de piel morena, héroe golpeando el planeta Júpiter en el espacio, de frente',
  galleryPrompt8: 'Galleta en café caliente, en un ambiente navideño',
  galleryPrompt9: 'Botella de agua artística sobre un fondo minimalista blanco con hojas de Montserra',
  galleryPrompt10: 'Dragón de fantasía de diamante evanescente',
  galleryPrompt11: 'Planetas en gotas de rocío, gotas de rocío planetarias, al amanecer, primer plano, hiperdetallado, enfoque nítido, foto de estudio, detalles intrincados, muy detallado',
  galleryPrompt12: 'Una urraca sentada en un cofre de oro, al estilo de arte fantástico realista, piedra preciosa, cuentos de hadas oscuros, misterioso.',
  galleryPrompt13: 'Una montaña de nieve y un sendero de hielo en la cima de la montaña yendo en la dirección contraria, ultra hd, realista, colores vivos, altamente detallado, dibujo UHD, pluma y tinta, composición perfecta, hermoso detallado intrincado locamente detallado render octane tendencia en artstation, 8k fotografía artística, arte conceptual fotorrealista, luz perfecta natural volumétrica suave cinematográfica',
  galleryPrompt14: 'Era de la civilización interestelar, de pie en lo alto y mirando hacia abajo el misterioso espacio digital: edificios de ciudades alienígenas superciencia, Exquisito hermoso exterior de torre rascacielos, calle, puente del cielo, arquitectura superciencia flotante en el cielo, máquina de guerra, alta tecnología, innumerables luces de pantallas electrónicas',
  galleryPrompt15: 'Hermosa playa con el océano de fondo en los Cayos de Florida por la noche, colores neón, fondo negro, olas tranquilas, tonalidad natural, puesta de sol, calma, relajante acercamiento a las olas, playa, horizonte rectificado, rosa, azul, naranja, púrpura',
  galleryPrompt16: 'Mejor calidad, doble exposición, arte postal, arte en papel agrietado, un primer plano de un lindo gatito jugando con una bola de lana interior detallado y acogedor, brillante, luz solar, rayos de sol, pose dinámica, ilustración de cuento, 2D, plano, lindo, adorable, vintage, cuento de hadas, patchwork, vidriera, ilustración de cuento detallada, cinemático, ultra altamente detallado, pequeños detalles, hermosos detalles, místico, colores vibrantes, fondo complejo',
  galleryPrompt17:
    'pirámide sobre el agua con reflejos de fuego, montañas, surrealismo oscuro, retratos atmosféricos, enfoque nítido',
  galleryPrompt18: 'panda rojo en el bosque, cinematográfico',
  galleryPrompt19: 'mar, luna, pez, cielo estrellado, barco, ilustración colorida',
  galleryPrompt20:
    'Reflejos urbanos vibrantes bailan entre edificios audaces y agua reluciente en estilo abstracto minimalista, capturando la energía animada de la noche, abstracción geométrica, estilo de arte pop contemporáneo con iluminación de neón y técnica de vertido acrílico',
  galleryPrompt21:
    'vista trasera de una mujer silueta con espada brillante, portal circular en el Ártico que conduce a icebergs, toma en medio plano, figura monumental',

  /// error page
  copyrightDetected: 'Términos / Personalidades o Marcas Registradas Detectadas',
  copyrightDetectedDesc:
    'Nos comprometemos a ser un generador de IA responsable y no generaremos resultados para marcas registradas, términos, marcas o personalidades incluidas en los avisos para prevenir posibles infracciones. Nuestros Términos de Servicio estipulan que NO debes usar los servicios de una manera que infrinja, se apropie indebidamente o viole cualquier derecho de propiedad intelectual. Por favor, modifica tu aviso y vuelve a intentarlo.',
  errorMesTTI: '¡Uy! Lo sentimos, pero algo ha ido mal.',
  errorMesTTIDesc:
    'El problema se ha registrado para su investigación. Vuelva a intentarlo más tarde.',
  tryAgain: 'Inténtalo de nuevo',
  // hruc bulk download
  hrucBulkDownloadTitle: 'HRUC bulk download',
  hrucDownload: 'HRUC download',
  hrucBulkdownloadNotSupported: 'HRUC bulk download is not supported',
  // download feature
  downloadQuotaFinished:
    'You have reached the maximum quota available. Please contact your system administrator.',

  // search bar placeholder
  searchBarPlaceholderAllImageDesktop: 'Encuentra impresionantes imágenes. Busca por palabras clave o frases.',
  searchBarPlaceholderAllImageTablet: 'Encuentra impresionantes imágenes.',
  searchBarPlaceholderAllImageMobile: 'Encuentra imágenes.',
  searchBarPlaceholderPhotoDesktop: 'Encuentra impresionantes fotos. Busca por palabras clave o frases.',
  searchBarPlaceholderPhotoTablet: 'Encuentra impresionantes fotos.',
  searchBarPlaceholderPhotoMobile: 'Encuentra fotos.',
  searchBarPlaceholderVectorDesktop: 'Encuentra impresionantes vectores. Busca por palabras clave o frases.',
  searchBarPlaceholderVectorTablet: 'Encuentra impresionantes vectores.',
  searchBarPlaceholderVectorMobile: 'Encuentra vectores.',
  searchBarPlaceholderFootageDesktop: 'Encuentra impresionantes vídeo. Busca por palabras clave o frases.',
  searchBarPlaceholderFootageTablet: 'Encuentra impresionantes vídeo.',
  searchBarPlaceholderFootageMobile: 'Encuentra vídeo.',
  searchBarPlaceholderAudioDesktop:
    'Encuentra impresionantes archivos de audio y música. Busca por palabras clave o frases.',
  searchBarPlaceholderAudioTablet: 'Encuentra impresionantes archivos de audio y música.',
  searchBarPlaceholderAudioMobile: 'Encuentra archivos de audio y música.',
  searchBarPlaceholderFreeDesktop: 'Encuentra impresionantes imágenes gratis. Busca por palabras clave o frases.',
  searchBarPlaceholderFreeTablet: 'Encuentra impresionantes imágenes gratis.',
  searchBarPlaceholderFreeMobile: 'Encuentra imágenes gratis.',
  // editorial warning
  forEditorialUseOnly: 'Sólo para uso editorial',
  forEditorialUseOnlyDesc:
    'El uso de esta imagen para publicidad o para promociones está prohibido a no ser que se adquiera una licencia adicional.',

  // download comp
  downloadHiResComp: 'DESCARGAR BOCETO ALTA RES',
  downloadComp: 'DESCARGAR BOCETO',

  generateTerm: ({ callback }) => (
    <>
      By clicking “Generate”, you hereby agree that the generated Output is solely governed by the
      {' '}
      <span aria-hidden onClick={callback}>
        AI Generator Terms of Service.
      </span>
      {' '}
    </>
  ),
  textToImage: 'Texto a imagen',
  descTourTTI: '¡Deja volar tu imaginación y crea imágenes con texto!',
  colorAndTone: 'Color y tono',
  titleSuccessDownload: 'Nos alegramos de que haya encontrado lo que necesitaba.',
  hangInThere: '¡Aguanta! No tardará mucho.',
  adultContentTitle: 'Términos de contenido potencialmente adulto detectados',
  adultContentDesc:
    'Nos comprometemos con una IA responsable y, por lo tanto, no generamos resultados para ciertas frases, conceptos y/o ideas. Por favor, modifica tu descripción (la descripción que proporcionaste para generar la imagen) y vuelve a intentarlo.',
  reachedTheLimit: '¡Oh, no! Has alcanzado el límite para generar imágenes AI por hoy.',
  backTomorrow: 'Vuelva mañana para ver más generaciones.',
  generateTermService: 'AI Generator Terms of Service',
  endUserLicenseAgreement: 'End user license agreement',
  aiPromptPlaceholder: 'Para comenzar, describe tu visión aquí y la haremos realidad.',

  downloadAgain: 'Download again',
  btnClose: 'Cerrar',

  variousTitle: 'Descubre nuestros diversos modelos',
  variousSDXL: 'Dale vida a personas y escenas fotorealistas impresionantes.',
  variousEimisAnime: 'Modelo de IA generativa diseñado para crear imágenes al estilo anime.',
  variousDynaVisionXL: 'Generar personajes de dibujos animados estilizados en 3D.',
  variousJuggernautXL: 'Con énfasis en elementos arquitectónicos y paisajes/fondos.',
  variousDreamShaperXL:
    'Escalado de alta resolución, caracteres mejorados y generaciones de dragones, borde reducido.',
  variousRealismEngineSDXL: 'Este modelo se especializa en imágenes detalladas y realistas.',
  variousDALLE:
    'DALL·E 3, un modelo todoterreno sobresale en transformaciones precisas de concepto a visual.',
  aiEngine: 'Motor de IA',
  'DALL-E': 'DALL-E 3',

  langText: 'Texto',
  langElements: 'Elements',
  langAuto: 'Auto',
  langBgRemoval: 'Eliminación del fondo',
  resetAll: 'Resetear todo',
  facebookImage: 'Imagen de Facebook',
  facebookLink: 'Enlace de Facebook',
  facebookCover: 'Portada de Facebook',
  facebookStory: 'Historia de Facebook',
  instagramPost: 'Post de Instagram',
  instagramStory: 'Historia de Instagram',
  twitterPost: 'Publicación en Twitter',
  twitterHeader: 'Encabezado de Twitter',
  pinterestPost: 'Post de Pinterest',
  emailHeader: 'Encabezado del correo electrónico',
  presentation: 'Presentación',
  presentationWide: 'Presentación amplia',
  ebookCover: 'Portada del libro electrónicor',
  linkedinPost: 'LinkedIn post',
  brochureCover: 'Cubierta del folleto (A4)',
  postcard: 'Tarjeta postal',
  photoPrint: 'Impresión de la foto',
  youtubeProfileImage: 'Imagen de perfil de YouTube',
  youtubeChannelCoverPhoto: 'Foto de la portada del canal de YouTube',
  langApplyBgRemoval: 'Aplicar la eliminación del fondo',
  autoBnW: 'Auto B&N',
  autoAdjust: 'Auto ajuste',
  autoPop: 'Auto Pop',
  original: 'Original',
  addHeader: 'Añadir una cabecera',
  addTitle: 'Añadir un título',
  addBody: 'Añadir un cuerpo de texto',
  addAssistive: 'Añadir un texto de ayuda',
  langCompare: 'Compara',
  langEnableEditor: 'Editar imagen',
  langSimilarStock: 'Imágenes similares',
  langTextUndo: 'Deshacer',
  langTextRedo: 'Rehacer',
  langTextFont: 'Fuente',
  langTextFontSize: 'Tamaño de la fuente',
  langTextColor: 'Color del texto',
  langTextHighlight: 'Resaltar',
  langTextAlignment: 'Alineación',
  langTextBold: 'Negrita',
  langTextItalic: 'Cursiva',
  langTextUnderline: 'Subrayado',
  langTextLineSpacing: 'Interlineado',
  langTextLetterSpacing: 'Espacio entre letras',
  langBringForward: 'Llevar al frente',
  langBringBackward: 'Llevar a la parte posterior',
  langDuplicate: 'Duplicar',
  langOpacity: 'Opacidad',
  langDelete: 'Borrar',
  imageProperties: 'Propiedades de la imagen',
  showMore: 'Mostrar más',
  showLess: 'Mostrar menos',
  noEditCompareMsg: 'No se han realizado ediciones para comparar.',
  stockPhoto: 'Foto de stock',
  basicShape: 'Basic Shapes',
  chitChat: 'Chit Chat',
  shapes: 'Shapes',
  elementChitChat: 'Element Chit Chat',
  organicAbstractShapes: 'Organic Abstract Shapes',
  elementShapes: 'Element Shapes',
  langApplyingEdit: 'Aplicando ediciones..',
  langTextAllFonts: 'Todas las fuentes',
  langSearchFontType: 'Buscar tipo de fuente',
  editorCompareImageBeforeTitle: 'Antes',
  editorCompareImageAfterTitle: 'Después',
  langSizePx:
    'Un píxel es la unidad básica de una imagen digital. El tamaño de la imagen, medido en píxeles, determina el nivel de detalle y el tamaño de la descarga.',
  langSizeDpi:
    'Los puntos por pulgada miden la resolución de impresión. Un dpi más alto significa más detalle. Para la impresión, la resolución recomendada para todas las imágenes es de 300 dpi.',
  cautionTTI:
    'Precaución: Hemos detectado el uso de frases en el mensaje que están asociadas con marcas registradas, derechos de autor, monumentos famosos y/o individuos. Tenga en cuenta que Usted es el único responsable del uso del Contenido, incluyendo que no viole ninguna ley aplicable o infrinja derechos.',

  addImageReference: 'Añadir referencia de imagen (Opcional)',
  uploadImageOnly: 'Solo subir JPG o PNG. Máximo 4MB.',
  dragAndDrop: 'Arrastrar y soltar archivo o',
  errorFileSize: 'Este archivo es demasiado grande. Máximo permitido de 4MB.',
  adjustScale: 'Ajusta esta escala para generar resultados muy similares o diferentes de tu imagen subida. El rango recomendado es de 7 a 15.',
  similar: 'Similar',
  different: 'Diferente',
  uploadNew: 'Subir nuevo',

  imageGuideGeneration: 'Tu imagen guiará tu prompt durante la generación.',
  notAvailableForEngine: 'No disponible para el motor de IA seleccionado.',

  imagePromptDescribe: 'Para comenzar, proporciona un prompt de la imagen que quieres que nuestra IA genere describiendo lo que tienes en mente.',
  fileTypeNotSp: 'El tipo de archivo no es compatible.',
  theFileSizeTooSmall: 'El tamaño del archivo es demasiado pequeño. Mínimo de 512px permitido.',

  relatedSearchPhoto: 'Búsquedas de fotos de stock',

  STOCK_PHOTO_PATH: 'imagenes-de-archivo',
  STOCK_FOOTAGE_PATH: 'archivo-de-video',
  STOCK_AUDIO_PATH: 'sobre-audio',
  CLIPART_VECTOR_PATH: 'clipart-vectorizado',
  ALL_IMAGES: 'Todas las imágenes',
  PHOTO: 'Photo',
  PHOTOS: 'Fotos',
  VECTORS: 'Vectores',
  FOOTAGE: 'Vídeo',
  AUDIO: 'Audio',
  LANG_MORETHANMILLION: 'de imágenes de stock libres de derechos',
  LANG_SVG_OR_EPS: 'SVG o EPS',
  LANG_OR: 'O',
  STANDARD_LICENSE: 'Licencia estándar',
  EXTENDED_LICENSE: 'Licencia extendida',
  CREDIT_PACK: 'Paquete de crédito',
  DOWNLOAD_PACK: 'Paquete de descarga',
  SUBSCRIPTION_PLAN: 'Plan de Suscripción',
  LANG_DOWNLOAD: 'Descargar',
  STOCKPHOTO: 'Foto de archivo',
  STOCK_PHOTO: 'Foto de stock',
  STOCK_VECTOR: 'Vector de stock',
  PREMIUM_STOCKPHOTO: 'Foto de archivo PREMIUM',
  PLUS_STOCK_PHOTO: 'PLUS Foto de Stock',
  PLUS_STOCK_VECTOR: 'Vector de stock PLUS',
  MOBILE_STOCKPHOTO: 'Fotografía móvil de stock',
  STOCKFOOTAGE: 'Archivo de Video',
  IMAGEID: 'Identificador de la imagen',
  FONTID: 'ID',
  MEDIATYPE: 'Tipo de medio',
  COPYRIGHT_TEXT: 'Derecho de autor',
  KEYWORDS: 'Palabras clave',
  LANG_CANCEL: 'Cancelar',
  LANG_PURCHASE: 'Comprar',
  LANG_STOCKVECTORSANDILLUS: 'Ilustraciones y ficheros vectoriales de archivo',
  LANG_STOCKPHOTOSANDIMAGES: 'Imágenes y Fotos de Stock',
  LANG_MATCHES: 'coincidencias',
  LANG_VECTORRESULTS: '{number} {keyword} imágenes vectoriales libres de derechos encontradas para usted.',
  LANG_PHOTORESULTS: '{number} {keyword} fotos e imágenes de stock libres de derechos encontradas para usted.',
  LANG_SIMILARIMGS: 'Fotos libres de derechos similares',
  LANG_COLLECTIONS: 'Colecciones',
  LANG_ALL: 'Todos',
  LANG_STD: 'Estándar',
  LANG_EXTD: 'Extendida',
  LANG_COMMERCIAL: 'Comercial',
  LANG_SUBSCRIPTION: 'Subscripción',
  LANG_EDITORIAL: 'Editorial',
  LANG_UPLOADED: 'Subido a',
  LANG_FRESHNESS: 'Frescura',
  LANG_ANYTIME: 'En cualquier momento',
  LANG_2DAYS: '2 days',
  LANG_PAST2DAYS: '< 2 días',
  LANG_PASTWEEK: 'Última semana',
  LANG_PRICE_PLUS_MONTH: "<span class='price'>{price}</span>/mes",
  LANG_PRICE_PLUS_YEAR: "<span class='price'>{price}</span>/año",
  LANG_PASTMONTH: 'Último mes',
  LANG_PAST3MONTHS: '3 meses',
  LANG_NUMOFPPL: 'Número de Personas',
  LANG_NOT_RECEIVE_VERIFICATION_EMAIL: '¿No ha recibido el correo electrónico de verificación?',
  LANG_WITHOUTPEOPLE: 'Sin personas',
  LANG_PERSON: 'Persona',
  LANG_PEOPLE: 'Personas',
  LANG_INFANTS: 'Bebés',
  LANG_TODDLERS: 'Niños pequeños',
  LANG_TEENS: 'Adolescentes',
  LANG_20S: '20s',
  LANG_30S: '30s',
  LANG_40S: '40s',
  LANG_50S: '50s',
  LANG_60S: '60s',
  LANG_70S: '70 +',
  LANG_CAUCASIAN: 'Caucásico',
  LANG_BLACK: 'Negro',
  LANG_HISPANIC: 'Hispano',
  LANG_EAST_ASIAN: 'Asiático oriental',
  LANG_SOUTHEAST_ASIAN: 'Sudeste Asiático',
  LANG_INDIAN: 'Indio',
  LANG_MIDDLE_EASTERN: 'Oriente Medio',
  LANG_AGE: 'Edad',
  LANG_ALL_SHAPE: 'Ver todo',
  LANG_ETHNICITY: 'Origen étnico',
  LANG_PEOPLEORMORE: 'Muchas Personas',
  LANG_COLOR: 'Color',
  LANG_OTHEROPTION: 'Otras opciones',
  SOLID_OR_TRANSPARENT_BACKGROUNDS: 'Fondos sólidos/transparentes',
  LANG_FILTER_BY: 'Filtrar por',
  LANG_FILTER: 'Filtrar',
  LANG_PHOTOGRAPHY: 'Fotografía',
  LANG_VECTORILLUST: 'Ilustraciones vectoriales',
  LANG_SORTBY: 'Ordenar por',
  LANG_MORE: 'Más',
  LANG_RELEVANCE: 'Relevancia',
  LANG_RESEND: 'Vuelva a enviar',
  LANG_RESEND_VERIFICATION: 'Reenviar correo electrónico de verificación',
  LANG_NEWEST: 'Nuevo',
  LANG_GEORANK: 'Georank',
  LANG_ORIENTATION: 'Orientación',
  LANG_HORIZONTAL: 'Horizontal',
  LANG_PORTRAIT: 'Retrato',
  LANG_SQUARE: 'Cuadrado',
  LANG_PANORAMA: 'Panorama',
  LANG_SELECTIVE_FOCUS: 'Enfoque selectivo',
  LANG_PATTERN: 'Patrón',
  LANG_VIBRANCE: 'Vibrance',
  LANG_VERIFICATION_DESCRIPTION: 'Necesitas verificar tu cuenta antes de descargar contenido de 123RF. Por favor, compruebe su correo electrónico o haga clic en el enlace proporcionado a continuación si desea reenviarlo.',
  LANG_VERIFICATION_TITLE: 'Por favor, verifique su correo electrónico.',
  VERIFICATIONRESETUNABLE: '¡Ups! Su correo electrónico no se ha podido enviar. Por favor, póngase en contacto con nuestro ',
  LANG_CHOOSEUPTO3COLORS: 'Máximo 3 colores',
  LANG_ISOLATED: 'Aislado',
  LANG_STYLE: 'Estilo',
  LANG_SAFESEARCH: 'Búsqueda segura',
  LANG_ONLY_AUTHENTIC: 'Solo auténticas',
  LANG_EXCLUDE_PLACEHOLDER: 'ej. queso',
  LANG_EXCLUDEWORDS: 'No incluya estas palabras',
  LANG_EXCLUDE: 'Excluir',
  LANG_INCLUDE_AICONTENT: 'Incluir contenido generado por IA',
  VISUAL_SEARCH: 'Buscador visual',
  VISUAL_SEARCH_TOOLTIP_TITLE: '3 fáciles formas de personalizar una búsqueda  dentro de una imagen',
  VISUAL_SEARCH_TOOLTIP_INFO_1: '1. Haz click en los punto de la imagen para identificar tu búsqueda.',
  VISUAL_SEARCH_TOOLTIP_INFO_2: '2. Arrastra el botón de recortar para ampliar o reducir el área de búsqueda',
  VISUAL_SEARCH_TOOLTIP_INFO_3: "3. Haz click en 'X' para volver a la búsqueda",
  VISUAL_SEARCH_NO_RESULTS: 'Tu búsqueda no tiene ningún resultado.',
  LANG_RIS_TITLE: 'Búsqueda inversa de imágenes',
  LANG_RIS_NOTFOUND: '¡No se encontraron resultados!',
  LANG_RIS_ERROR_HEADER: 'La imagen subida contiene errores, asegúrate de que la imagen cumpla con los siguientes criterios:',
  LANG_RIS_REFINE_SRCH_MSG: 'Verifica tu búsqueda refinada con la palabra clave',
  LANG_RIS_UPLOADED_IMAGE: 'Imagen subida',
  LANG_TELL_ME_MORE: '¡Dime más',
  LANG_ENTERKEYWORD: 'Por favor, introduce una palabra clave.',
  LANG_SIZE: 'Tamaño',
  LANG_STANDARD_SIZE: 'Tamaños estándar',
  LANG_EXTENDED_SIZE: 'TALLAS EXTENDIDAS',
  LANG_SIZE_PX: 'Un píxel es la unidad básica de una imagen digital. El tamaño de la imagen, medido en píxeles, determina el nivel de detalle y el tamaño de la descarga.',
  LANG_SIZE_DPI: 'Los puntos por pulgada miden la resolución de impresión. Un dpi más alto significa más detalle. Para la impresión, la resolución recomendada para todas las imágenes es de 300 dpi.',
  LANG_SOCMED_SIZE: 'Tamaños para las redes sociales',
  LANG_COMPARE: 'Compara',
  LANG_AUTO_ENHANCE: 'Auto-mejora',
  LANG_AUTO: 'Auto',
  LANG_BG_REMOVAL: 'Eliminación del fondo',
  LANG_TEXT: 'Texto',
  LANG_ELEMENTS: 'Elements',
  LANG_IMAGE_INFO: 'Información de la imagen',
  LANG_FOLLOW: 'Sigue a',
  LANG_FOLLOWED: 'Siguiendo a',
  LANG_FOLLOWING: 'Siguiendo',
  FACEBOOK_IMAGE: 'Imagen de Facebook',
  FACEBOOK_LINK: 'Enlace de Facebook',
  FACEBOOK_COVER: 'Portada de Facebook',
  FACEBOOK_STORY: 'Historia de Facebook',
  INSTAGRAM_POST: 'Post de Instagram',
  INSTAGRAM_STORY: 'Historia de Instagram',
  TWITTER_POST: 'Publicación en Twitter',
  TWITTER_HEADER: 'Encabezado de Twitter',
  PINTEREST_POST: 'Post de Pinterest',
  EMAIL_HEADER: 'Encabezado del correo electrónico',
  PRESENTATION: 'Presentación',
  PRESENTATION_WIDE: 'Presentación amplia',
  EBOOK_COVER: 'Portada del libro electrónicor',
  LINKEDIN_POST: 'LinkedIn post',
  BROCHURE_COVER: 'Cubierta del folleto (A4)',
  POSTCARD: 'Tarjeta postal',
  PHOTO_PRINT: 'Impresión de la foto',
  YOUTUBE_PROFILE_IMAGE: 'Imagen de perfil de YouTube',
  YOUTUBE_CHANNEL_COVER_PHOTO: 'Foto de la portada del canal de YouTube',
  AUTO_COLOR: 'Auto-color',
  BALANCE: 'Balance',
  AUTO_LEVEL: 'Autonivelante',
  AUTO_TONE: 'Auto-tono',
  ENRICH: 'Enriquecer',
  RESET: 'Reiniciar',
  RESET_ALL: 'Resetear todo',
  FILE_DOWNLOAD_OPTIONS: 'Opciones de descarga de archivos',
  FILE_DOWNLOAD_INITIATED: 'La descarga del archivo ha comenzado',
  IMAGE_PROPERTIES: 'Propiedades de la imagen',
  DOWNLOAD_DETAILS: 'Descargar propiedades',
  FILE_SIZE: 'Tamaño del archivo',
  LICENSE: 'Licencia',
  FILEFORMAT: 'Tipo de archivo',
  CHANGE: 'Cambiar',
  PRICING: 'Precios',
  FIND_QUALITY_IMAGE: 'Find quality images now',
  SAVE_AS: 'Guardar como',
  IMAGE_USAGE: 'Uso de la imagen',
  PRINT: 'Impresión',
  ELECTRONIC: 'Electrónica',
  COMPREHENSIVE: 'Completa',
  DOWNLOAD_CREDITS: '<b>{quota}</b> Créditos de tu Paquete de Créditos',
  BASIC_SHAPE: 'Formas básicas',
  CHIT_CHAT: 'Charla casual',
  SHAPES: 'Formas',
  ELEMENT_CHIT_CHAT: 'Charla casual de elementos',
  ORGANIC_ABSTRACT_SHAPES: 'Formas abstractas orgánicas',
  ELEMENT_SHAPES: 'Formas de elementos',
  BUY_SINGLE_IMAGE: 'Comprar una única imagen<br/><b>{quota} créditos</b>',
  BUY_SINGLE_IMAGE_CREDIT: 'Comprar paquete de crédito <br/><b>{quota} créditos</b>',
  BUY_CREDIT_PACK: 'Compra un paquete de <br/>créditos y ahorra',
  BUY_CREDIT_PACK_POINT_1: '{quota} créditos a <b>{price}</b>',
  BUY_CREDIT_PACK_POINT_2: 'Sin compromiso mensual.',
  BUY_CREDIT_PACK_POINT_3: '<b>1 año</b> de validez.',
  BUY_SINGLE_IMAGE_PSUB: 'Comprar una única imagen<br/><b>{quota} plaza de descarga</b>',
  BUY_DOWNLOAD_PACK: 'Compre el paquete de descarga <br/><b>y ahorre más</b>',
  BUY_DOWNLOAD_PACK_POINT_1: 'Pack de descarga de {quota} imágenes a <b>{price}</b>',
  BUY_DOWNLOAD_PACK_POINT_2: 'Sin compromiso mensual.',
  BUY_DOWNLOAD_PACK_POINT_3: '<b>1 año</b> de validez.',
  LOWEST_PRICE: '<b>El mejor precio</b><br/> por imagen con el Plan de Suscripción',
  LOWEST_PRICE_POINT_1: 'Prueba 1 mes a <b>{price}</b>',
  LOWEST_PRICE_POINT_2: 'Descarga 10 fotos o vectores.',
  LOWEST_PRICE_POINT_3: 'Sin límite de descargas diarias, las descargas no utilizadas se transferirán al siguiente mes.',
  PER_IMAGE_SIZE: 'en cada imagen <b>{size} tamaño</b>',
  PER_IMAGE_ANY: 'a cualquier tamaño',
  PER_IMAGE: 'por imagen',
  VIEW_PRICING: 'Más planes y precios',
  DOWNLOAD_BEGIN: 'Tu descarga comenzará en unos instantes. Por favor, espera.',
  DOWNLOAD_NOT_STARTING: '¿Aún no se ha iniciado la descarga?',
  SELECT_MIRROR: '[Selecciona tu región]',
  SHOW_MORE: 'Mostrar más',
  SHOW_LESS: 'Mostrar menos',
  LICENSE_TYPE: 'Tipo de licencia',
  LICENSE_SUMMARY: 'Resumen de la licencia',
  BACK: 'Volver',
  LANG_VECTOR: 'Vector',
  PREMIUM_VECTOR: 'Vector de stock PREMIUM',
  SCALE_ANY_SIZE: 'Escalar a cualquier tamaño',
  LANDINGPAGE_VECTOR_HEADLINE: 'Archivos Vectorizados: Vectores, Ilustraciones y Gráficos - 123RF',
  LANDINGPAGE_PHOTO_HEADLINE: 'Banco de Fotos: Imágenes Libres de Derechos Fotos de Stock - 123RF',
  CATEGORIES: 'Categorías',
  LANDINGPAGE_PRICING_1: 'Créditos',
  LANDINGPAGE_PRICING_2: 'Suscribirse',
  LANDINGPAGE_AS_LOW_AS: 'Desde solo',
  EMPTY_SEARCH_TITLE: 'Uy, tu búsqueda no ha dado ningún resultado.',
  LANG_SMALL: 'Pequeño',
  LANG_MEDIUM: 'Mediano',
  LANG_LARGE: 'Gran',
  LANG_EXTRA_LARGE: 'Extra grande',
  LANG_LOW_CREDITS: '¡Parece que te estás quedando sin créditos de descarga!',
  LANG_TOPUP: 'Top up',
  LANG_TOPUP_NOW: 'Recarga ahora',
  SEO_DETAILS_TITLE_STOCKPHOTO: '{description} Fotos, retratos, imágenes y fotografía de archivo libres de derecho. Image {mediaId}',
  SEO_DETAILS_TITLE_CLIPARTVECTOR: '{description} Ilustraciones svg, vectoriales, clip art vectorizado libre de derechos. Image {mediaId}',
  SEO_DETAILS_METADESC_STOCKPHOTO: 'Compra imágenes y fotos : {description}. Image {mediaId}',
  SEO_DETAILS_METADESC_CLIPARTVECTOR: 'Compra imágenes y fotos : {description}. Image {mediaId}',
  SEO_DETAILS_TITLE_FREE_PHOTO: '{description} Imagen y Fotografía Gratis {mediaId}.',
  SEO_DETAILS_METADESC_FREE_PHOTO: 'Imagen gratuita de {description}. Imagen gratuita {mediaId}.',
  SEO_DETAILS_TITLE_FREE_VECTOR: '{description} Vector y gráfico gratuito {mediaId}.',
  SEO_DETAILS_METADESC_FREE_VECTOR: 'Vector libre de {description}. Gráfico gratuito de {mediaId}.',
  SEO_DETAILS_OGDESCRIPTION: '123RF- Millones de fotos, vectores, vídeos y archivos de música para inspirar tus proyectos.',
  LIVECHAT_LOOKINGHELP: '¿Necesitas ayuda?',
  LIVECHAT_CHATWITHUS: '¡Chatea con nosotros!',
  FREE_TRIAL: '<b>Obten 10 imágenes gratis</b> <br/>con plan de suscripción',
  LANG_RELATEDSEARCH: 'Búsquedas relacionadas',
  LANG_RELATED_STOCKPHOTO_SEARCH: 'Búsquedas de fotos de stock',
  LANG_PROPERATTRIBUTE: 'Aquí está nuestra atribución:',
  LANG_ATTRIBUTEDESC: 'Por favor, atribuya esta imagen a su fotógrafo colocando esta línea de crédito a continuación en cualquier área dentro de su producto, sitio web o colateral',
  LANG_ATTRIBUTE_TITLE: 'La atribución es opcional.',
  LANG_ATTRIBUTE_CREDIT: 'Si desea atribuir al creador, utilice la línea de crédito a continuación:',
  LANG_ATTRIBUTE_COPYLINK: 'Copiar link',
  LANG_ATTRIBUTE_COPYTEXT: 'Copiar texto',
  LANG_LICENSE_AGREEMENT: '<a href={url}><b>Aquí</b></a> está el acuerdo de licencia para esta descarga',
  LANG_NEXTPAGE: 'Próxima página',
  LANG_IMAGE_NO_AVAILABLE: 'La imagen #{mediaId} ya no está disponible',
  LANG_PHOTOGRAPHY_METAIMG_DESC: 'Encuentra imágenes libres de derechos, editoriales, fotografías, en nuestro banco de fotos con millones de archivos. Compra y descarga online.',
  LANG_VECTOR_METAIMG_DESC: 'Encuentra vectores, ilustraciones, gráficos y cliparts, en nuestro banco de fotos con millones de archivos. Compra y descarga online.',
  LANG_ANOTHERMETAKWDEFAULT: 'banco de imagen, banco de fotos, fotos de archivo, libres de derechos, fotografía de archivo, imágenes, dibujos, fotografías, banco de imágenes, libre de derechos, fotografías, vectores, ilustraciones, vídeos, videoclips, archivos vectorizados, música libre de derechos, efectos de sonido, audio, editorial, iconos, fotos de stock',
  LANG_ANOTHERMETADESCDEFAULT: 'Encuentra imágenes libres de derechos, fotos, editoriales, vectores, ilustraciones, vídeos, licencias de música y audio en nuestro banco de imágenes. 35.000 nuevos archivos cada día, compra y descarga online.',
  EMPTY_FILTERED_RESULT_TITLE: 'Oops, tu búsqueda con esos filtros no ha dado ningún resultado.',
  EMPTY_FILTERED_RESULT_SUBTITLE: 'Intente eliminar algunos filtros o pruebe con otra palabra clave.',
  CLEAR_ALL_FILTER: 'Borrar todos los filtros',
  PROMOBAR_FREE10_TEXT: 'Obtenga <b>10 Imágenes GRATIS</b> cuando se inscriba en nuestra prueba de 1 mes gratis.',
  PROMOBAR_FREE10_BTNLABEL: 'EMPEZAR AHORA',
  PROMOBAR_MASSIVE_MARKDOWN_TEXT: 'Rebajas espectaculares en todas las suscripciones.',
  PROMOBAR_MASSIVE_MARKDOWN_BTNLABEL: 'Ver planes',
  LANG_MODEL_RELEASED: 'Con autorización del modelo',
  LANG_PROPERTY_RELEASED: 'Con autorización de la propiedad',
  LANG_MODEL_RELEASE: 'con Liberación de Modelo',
  LANG_PROPERTY_RELEASE: 'con Liberación de la Propiedad',
  LANG_YES: 'Sí',
  LANG_NO: 'No',
  LANG_MREXPLAIN: 'Una documento de Cesión de Modelos es un documento firmado por el/la persona que aparece en la foto, autorizando a que su imagen sea utilizada a nivel publicitario y/o editorial en amplitud de formas.',
  LANG_PREXPLAIN: 'Un Documento de Cesión de Derechos de Propiedad es un Documento firmado por el propietario de la vivienda donde se han tomado las fotografías autorizando expresamente a que dichas fotografías se utilicen a nivel publicitario y/o editorial en formas diversas.',
  LANG_DOWNLOADS: 'Descargas',
  LANG_OLDFILENAME: 'Antigua referencia',
  LANG_DOWNLOAD_PREVIEW: 'Descargar vista previa',
  LANG_SHARE: 'Compartir',
  LANG_RESTRICTIONS: 'Restricciones',
  LANG_READ_MORE: 'Más información',
  LANG_EDITORIAL_USE: 'Sólo para uso editorial',
  LANG_EDITORIALDESCP: 'Uso editorial: esta imagen puede ser utilizada únicamente con fines editoriales. El uso de esta imagen con fines publicitarios, promocionales o comerciales no está permitido, a no ser que el licenciatario cuente con permisos adicionales. 123RF no ofrece ningún servicio para tramitar permisos adicionales.',
  LANG_LOGO_OWNERSHIP: 'Derechos de propiedad del logotipo',
  LANG_PHOTO_SHOT_SMARTPHONE: 'Esta foto fue tomada con un teléfono inteligente.',
  LANG_VECTORNOLOGO: 'Si piensa usar esto como el logo de su compañía, podemos gestionar una <b>venta de derechos</b> a su nombre. Para mayor información, contáctenos en el <b>{phonenum}</b>',
  LANG_DOWNLOAD_QUOTA: '<b>{quota}</b> Descarga de tu Plan de suscripción',
  LANG_DOWNLOAD_SLOT: '<b>{quota}</b> Descarga de tu Paquete de descarga',
  LANG_LOGO: 'Logos',
  USING_OLDER_BROWSER: '¿Estás usando un navegador más antiguo?',
  UPGRADE_NEWER_BROWSER: 'Actualizar a una nueva versión del navegador para disfrutar de una experiencia más suave!',
  CLICK_TO_DOWNLOAD: 'Haga clic para descargar cualquiera de estos:',
  LANG_VECTOR_EDITABLE: 'Archivos editables en SVG y EPS',
  PROMOBAR_GOOD15_TEXT1: 'Paga menos, descarga más. ',
  PROMOBAR_GOOD15_TEXT2: '15% de descuento en todos los planes con:',
  PROMOBAR_GOOD15_BTNLABEL: 'GOOD15',
  PROMOBAR_SUMMER25_TEXT1: 'Paga menos, descarga más. ',
  PROMOBAR_SUMMER25_TEXT2: '25% de descuento en todos los planes con',
  PROMOBAR_SUMMER25_BTNLABEL: 'SUMMER25',
  PROMOBAR_HALLOW30_TEXT: '30% de descuento en todos los planes con',
  NO_EDIT_COMPARE_MSG: 'No se han realizado ediciones para comparar.',
  SWITCH_TO_VECTOR_MSG: 'El editor de fotos no es compatible con las imágenes de tamaño vectorial. Todas las ediciones se perderán si se cambia al formato Vectorial.',
  SWITCH_TO_XL_MSG: 'El editor de fotos no es compatible con las imágenes de tamaño XL. Todas las ediciones se perderán si se cambia al formato XL.',
  UNAVAILABLE_FOR_THIS_SIZE: 'No disponible para el tamaño {size}.',
  SWITCH: 'CAMBIAR',
  SWITCH_LICENSE_EXT_MSG: '¿Desea descargar esta imagen primero como {type}? Perderá todas las ediciones si cambia a la licencia extendida.',
  LANG_SESSION_EXPIRED: 'Su sesión ha expirado. Por favor, <a href={url}>inicie sesión aquí</a>',
  LANG_TEXT_COLOR: 'Color del texto',
  LANG_SHAPE_ADD: 'Add Shape',
  LANG_TEXT_HIGHLIGHT: 'Resaltar',
  LANG_TEXT_ALIGNMENT: 'Alineación',
  LANG_TEXT_BOLD: 'Negrita',
  LANG_TEXT_ITALIC: 'Cursiva',
  LANG_TEXT_UNDERLINE: 'Subrayado',
  LANG_TEXT_BULLET: 'Viñeta',
  LANG_TEXT_LINE_SPACING: 'Interlineado',
  LANG_TEXT_LETTER_SPACING: 'Espacio entre letras',
  LANG_EDIT_COLOR: 'Edit color',
  LANG_BRING_FORWARD: 'Llevar al frente',
  LANG_BRING_BACKWARD: 'Llevar a la parte posterior',
  LANG_DUPLICATE: 'Duplicar',
  LANG_OPACITY: 'Opacidad',
  LANG_DELETE: 'Borrar',
  LANG_TEXT_ADD: 'Añadir texto',
  LANG_TEXT_EDIT: 'Editar texto',
  LANG_TEXT_FONT_TYPE: 'Tipo de fuente',
  LANG_TEXT_FONT_SIZE: 'Tamaño de la fuente',
  LANG_TEXT_ALL_FONTS: 'Todas las fuentes',
  LANG_TEXT_UNDO: 'Deshacer',
  LANG_TEXT_REDO: 'Rehacer',
  LANG_TEXT_FONT: 'Fuente',
  LANG_SEARCH_FONT_TYPE: 'Buscar tipo de fuente',
  LANG_SEARCH_SHAPE_TYPE: 'Elementos de búsqueda',
  LANG_DONE: 'Hecho',
  ADD_HEADER: 'Añadir una cabecera',
  ADD_TITLE: 'Añadir un título',
  ADD_BODY: 'Añadir un cuerpo de texto',
  ADD_ASSISTIVE: 'Añadir un texto de ayuda',
  STOCK_PHOTO_BANNER_TITLE: 'Millones de imágenes de stock libres de derechos para crear todo.',
  STOCK_PHOTO_BANNER_DESC: 'Encuentra todas las imágenes impresionantes que necesita para crear visuales de parada de desplazamiento para ilustrar sus grandes ideas a la vida ahora.',
  STOCK_PHOTO_SUBSCRIBE_TITLE: 'Visualiza tus historias con el imagenes correctos',
  STOCK_PHOTO_SUBSCRIBE_DESC: 'Obten acceso instantáneo a más de 180 millones de imágenes para capacitar su narración ahora. ¡Nuestras imágenes y su historia, es la combinación perfecta para maximizar su potencial creativo!',
  STOCK_PHOTO_CARD_DOWNLOAD_PACK: 'Paquete de descargas',
  STOCK_PHOTO_CARD_FROM_AS_LOW_AS: 'Por tan bajo como',
  STOCK_PHOTO_CARD_CREDIT: 'credit',
  STOCK_PHOTO_CARD_SUBSCRIPTION_PLAN: 'Abonnementplan',
  STOCK_PHOTO_CARD_IMAGE: 'imagen',
  STOCK_PHOTO_CARD_SUBSCRIBE_NOW: 'Suscríbase ahora',
  STOCK_PHOTO_CARD_BUY_NOW: 'Comprar ahora',
  STOCK_PHOTO_POPULAR_ROYALTY: 'Populares categorías de imágenes libres de derechos',
  STOCK_PHOTO_BROWSE_BEST_SELLING: 'Browse best-selling royalty free images',
  STOCK_PHOTO_DISCOVER_AFFORABLE: 'Descubre planes y precios asequibles para fotos.',
  STOCK_PHOTO_DISCOVER_NOW: 'Echale un vistazo',
  STOCK_PHOTO_VECTORS_FOOTAGE_AUDIO: 'Vectors, Footage, Audio for you',
  STOCK_PHOTO_VECTORS: 'Vectors',
  STOCK_PHOTO_ILLUSTRATION: 'Illustration',
  STOCK_PHOTO_PATTERN: 'Pattern',
  STOCK_PHOTO_CARTOON: 'Cartoon',
  STOCK_PHOTO_BABY: 'Baby',
  STOCK_PHOTO_FOOTAGE: 'Footage',
  STOCK_PHOTO_FOOTAGES: 'Footages',
  STOCK_PHOTO_BUILDING: 'Building',
  STOCK_PHOTO_HOTEL: 'Hotel',
  STOCK_PHOTO_CHILDREN: 'Children',
  STOCK_PHOTO_ABSTRACT: 'Abstract',
  STOCK_PHOTO_AUDIO: 'Audio',
  STOCK_PHOTO_AUDIOS: 'Audios',
  STOCK_PHOTO_TECHNO: 'Techno',
  STOCK_PHOTO_HOLIDAY: 'Holiday',
  STOCK_PHOTO_ACCOUSTIC: 'Accoustic',
  STOCK_PHOTO_LANDSCAPES: 'Paisaje',
  STOCK_PHOTO_BABIES: 'Bebés',
  STOCK_PHOTO_BUSINESS: 'Negocio',
  STOCK_PHOTO_FOOD: 'Comida',
  STOCK_PHOTO_ANIMALS: 'Animales',
  STOCK_PHOTO_TECHNOLOGY: 'Tecnología',
  STOCK_PHOTO_HEALTHCARE: 'Cuidado de la salud',
  STOCK_PHOTO_NATURE: 'Naturaleza',
  STOCK_PHOTO_ROMANCE: 'Romance',
  CLIPART_VECTOR_EXPLORE_MILLIONS: 'Crea con millones de vectores libres de derechos al alcance de tu mano.',
  CLIPART_VECTOR_NEED_AN_EYE: 'Encuentra geniales ilustraciones vectoriales, infografías, imágenes prediseñadas, iconos y mucho más que te ayudarán a transformar tus increíbles ideas en realidad',
  CLIPART_VECTOR_FIND_VECTOR: 'Encuentra los vectores adecuados para especificar tus proyectos.',
  CLIPART_VECTOR_FIND_VECTOR_DESC: 'Ya sean infografías, ilustraciones, dibujos animados, imágenes prediseñadas, fondos, iconos o lo que sea que necesites para crear imágenes increíbles, lo tenemos aquí. Haz que tus diseños cobren vida ahora.',
  CLIPART_VECTOR_ON_DEMAND: 'On Demand',
  CLIPART_VECTOR_FROM_AS_LOW_AS: 'From as low as',
  CLIPART_VECTOR_PER_CREDIT: '/ credit',
  CLIPART_VECTOR_BUY_SUBSCRIPTION: 'Buy Subscription',
  CLIPART_VECTOR_PER_IMAGE: '/ image',
  CLIPART_VECTOR_SUBSCRIBE_NOW: 'Subscribe Now',
  CLIPART_VECTOR_POPULAR_ROYALTY: 'Popular imágenes libres de derechos',
  CLIPART_VECTOR_DISCOVER_AFFORDABLE: 'Descubre planes y precios asequibles para vectores.',
  CLIPART_VECTOR_DISCOVER_NOW: 'Echale un vistazo',
  SPEND_AND_WIN_SUBTITLE: 'Cada {currency} gastados = 1 entrada',
  SPEND_AND_WIN_IMGALT: 'Gasta y gana un iPhone 13 Pro',
  CLIPART_VECTOR_GRAPHIC: 'Gráfico',
  CLIPART_VECTOR_ADVERTISING: 'Publicidad',
  CLIPART_VECTOR_FLOWER: 'Flor',
  CLIPART_VECTOR_ABSTRACT: 'Abstracto',
  CLIPART_VECTOR_POP: 'Música pop',
  CLIPART_VECTOR_BIRD: 'Pájaro',
  CLIPART_VECTOR_ILLUSTRATION: 'Ilustración',
  CLIPART_VECTOR_PIXEL: 'Pixel',
  LANG_VECTOR_SVG_EPS: 'Vector, SVG y EPS',
  CLIPART_VECTOR_3D: '3D',
  FREE_UPGRADE: 'Actualización gratuita',
  FREE_UPGRADE_MESSAGE: 'Actualización XL GRATUITA para todo el paquete de suscripción o descarga. Compra ahora.',
  LANG_FREE_IMAGE: '123RF Gratis',
  LANG_IMAGE_FREE: 'Esta foto de archivo es gratuita para uso comercial y personal. Se requiere atribución.',
  LANG_HOW_TO_ATTRIBUTE: '¿Cómo atribuir?',
  LANG_FREE_DOWNLOAD: 'Descarga gratis',
  LANG_FREE_DOWNLOAD_BUTTON: 'FREE Descarga',
  LANG_GO_PLUS_BUTTON: 'Ir PLUS',
  LANG_ATTRIBUTION_IS_KEY: 'La atribución es un elemento importante para los creadores de contenido que hacen su trabajo públicamente accesible. Nuestros creadores de contenido aprecian la exposición obtenida de sus creaciones utilizadas, compartidas y acreditadas por los millones de usuarios como usted.',
  LANG_GET_ATTRIBUTION_LINK: 'Copie este enlace y colóquelo en una ubicación cercana a donde se usa el contenido.',
  LANG_CREDIT_OWNER: 'Por favor, acredite al Creador:',
  LANG_USE_ATTRIBUTION: 'Use el enlace de atribución a continuación.',
  LANG_ATTRIBUTION_LOCATION: 'Si esto no es factible, otras buenas ubicaciones están en el pie de página del sitio web, el blog o el boletín o incluso una sección de créditos específicos del Sitio será suficiente para acreditar al propietario del {media_type}',
  LANG_GOT_A_QUESTION: "¿Tengo una pregunta? <a href={url} target='_blank'><u>Contáctenos</u></a>",
  LANG_ATTR_FREE_IMG_TITLE: 'Necesitaría acreditar el propietario de esta imagen gratuita.',
  LANG_ATTR_FREE_DOWNLOADING: '¡Aguanta! No tardará mucho.',
  LANG_PLUS_CONTENT_1: 'Tendrás descargas ilimitadas',
  LANG_PLUS_CONTENT_2: 'Licencia PLUS - No se necesita atribución',
  LANG_PLUS_CONTENT_3: 'Atención al cliente exclusiva 24/7',
  LANG_PLUS_CONTENT_4: 'Descargas ilimitadas',
  LANG_PLUS_CONTENT_5: 'Libertad creativa ilimitada',
  FREE_VECTOR: 'Vetores FREE',
  FREE_STOCKPHOTO: 'Fotos FREE',
  FREE_PUBLIC_DOMAIN_CC0: 'Dominio público CC0',
  BUY_5_DOWNLOAD_PACK: "<b>¡Oferta por tiempo limitado!</b><br/>3 + <b><span style='color:red'>2 GRATIS</span></b> = 5 fotos o vectores",
  BUY_5_DOWNLOAD_PACK_POINT_2: 'Descarga en cualquier momento (hasta un año)',
  BUY_5_GET_2_FREE: '¡Obtenga 2 GRATIS!',
  LANG_ASK_DOWNLOAD_PLUS: '¿Quieres descargar este contenido PLUS?',
  LANG_SIGN_UP_PLUS: 'Regístrese en nuestro plan 123RF PLUS desde tan bajo como',
  LANG_FOR_ONLY: "<span class='text'>por solo</span> <span class='price'>{price}</span>/mes",
  LANG_FOR_ONLY_2: "<span class='text'>por solo</span> <span class='price'>{price}</span>",
  LANG_BENEFIT_PLUS: 'Benefíciese de nuestro plan PLUS desde sólo',
  LANG_RELEASES: 'Liberación de derechos',
  LANG_UNAVAILABLE: 'No disponible',
  LANG_ATTR_FREE_IMG_TITLE2: 'Nos alegra que hayas encontrado lo que necesitabas. 🎉',
  LANG_PLEASE_NOTE: 'Por favor, tenga en cuenta que esta imagen no tiene ningún modelo o liberación de la propiedad adjunta. El uso comercial es bajo su propio riesgo.',
  LANG_PLEASE_ADVISED: 'Tenga en cuenta que las imágenes que contienen personas reconocibles no vienen con ningún lanzamiento. Para usar estas imágenes comercialmente, se debe obtener permiso de las partes relevantes. Si la imagen no contiene individuos reconocibles, no debe haber problemas de derechos de autor al usarla comercialmente.',
  LANG_REACHED_LIMIT: '¡Ops! Has alcanzado tu límite de descargas diarias.',
  LANG_COME_TOMORROW: 'Estamos encantados de que te gusten nuestras imágenes gratuitas. Vuelve mañana para refrescar tu límite de descargas. Gracias. 😊',
  LANG_DOWNLOAD_ERROR: '¡Ops! Su imagen no pudo ser descargada debido a un error.',
  LANG_REFRESH_AND_TRY_AGAIN: "Por favor, actualice su navegador e inténtelo de nuevo. <a href={url} target='_blank'>Háganos saber</a> si este problema persiste.",
  LANG_REACHED_LIMIT_PLUS: 'Límite de descarga diaria alcanzada',
  LANG_COME_TOMORROW_PLUS: '¡Guau! Has alcanzado su límite de descarga para hoy. Vuelve mañana para descargar más. Gracias por el apoyo!',
  SEE_YOU_TOMORROW: '¡Te veo mañana!',
  ORIGINAL: 'Original',
  'AUTO_B&W': 'Auto B&N',
  AUTO_ADJUST: 'Auto ajuste',
  AUTO_POP: 'Auto Pop',
  NONE: 'Ninguno',
  LANG_CROP: 'Recortar',
  LANG_ENABLE_EDITOR: 'Editar imagen',
  LANG_DISABLE_EDITOR: 'Cerrar',
  LANG_IMAGE_FREE_CC0: "Esta es una imagen CC0. No se requiere atribución. Puede usarlo de acuerdo con los <a class='linkCC0' href={url} target='_blank'>términos y condiciones de la licencia de CC0</a>.",
  LANG_PREVIEW: 'Vista previa',
  LANG_ATTRIBUTION_CC0_TITLE: 'Imagen de CCO: atribución no requerida',
  LANG_ATTRIBUTION_TITLE: 'Atribución requerida',
  LANG_ATTRIBUTION_CONTENT: 'El enlace de atribución se proporcionará después de que haya descargado el archivo.',
  LANG_ATTRIBUTION_CC0_CONTENT: 'La atribución no es necesaria ya que esta es una imagen CCO.',
  LANG_SUBCRIBE_PREMIUM: '¡Has alcanzado tu límite de descargas diarias! Para descargar más, suscríbase a 123RF PREMIUM.',
  LANG_REMOVE_ATTRIBUTION: 'Remove Attribution?',
  LANG_SUBCRIBE_PLUS_ATTRIBUTION: "Subscribe to <img src={image} alt='123RF Plus Logo'> for unlimited access to 100m stock photos and illustrations with no attributions required.",
  LANG_CREATOR_ATTRIBUTION: 'Please credit the Creator with the attribution link below.',
  LANG_GET_ATTRIBUTION_LINK_2: 'Copy this link and place it in a location close to where the content is used, the footer of the website or a specific credits section of the site.',
  LANG_APPLY_BG_REMOVAL: 'Aplicar la eliminación del fondo',
  LANG_APPLYING_EDIT: 'Aplicando ediciones..',
  LANG_APPLYING_EDIT_ERROR: 'Algo ha salido mal.',
  LANG_TRY_AGAIN: '¿Intentar de nuevo?',
  LANG_SEARCHCOLLECTION_PREMIUM: 'Colección completa',
  LANG_SEARCHCOLLECTION_PLUS: 'Colección Esencial',
  LANG_SEARCHCOLLECTION_FREE: 'Colección Básica',
  LANG_SIMILARIMAGESPAGE_TITLE: 'Banco de Imágenes: Fotos Libres de Derechos y Vectores - 123RF',
  LANG_SIMILARIMAGESPAGE_STOCKPHOTOS: 'Imágenes similares',
  LANG_SIMILARIMAGESPAGE_EMPTYRESULTS: '¡Ups! Nada que ver aqui.',
  LANG_SIMILARIMAGESPAGE_UNAVAILABLEIMAGE: 'Imagen no disponible',
  LANG_PER_IMAGE: '/imagen',
  LANG_PLANS_AND_PRICING: 'Planes y precios',
  LANG_CHOOSE_BEST_PLAN: 'Elija el mejor plan que se adapte a sus necesidades.',
  LANG_WANT_MORE_SAVING_1: '¿Quieres más ahorros?',
  LANG_WANT_MORE_SAVING_2: ' Ver más planes de {price}/imagen',
  LANG_BEST_VALUE: 'Mejor valor, ahorrar {percentage}%',
  LANG_YEARLY_PLAN: 'Plan anual',
  LANG_WITH_MONTHLY_PAYMENTS: 'Con pagos mensuales',
  LANG_GRAB_YEARLY_PLAN: 'Tome un plan anual y ahorre un {percentage}%.',
  LANG_PRICE_PER_MONTH: '{price} por mes',
  LANG_PRICE_PER_YEAR: '{price} por año',
  LANG_GET_X_EVERY_MONTH: 'Obtenga {quota} fotos o vectores cada mes',
  LANG_X_EVERY_MONTH: '{quota} fotos o vectores<br>cada mes',
  LANG_SINGLE_IMAGE_PURCHASE: 'Compra de una sola imagen',
  LANG_GET_ONLY_WHAT_YOU_NEED: 'Obtenga solo lo que necesita por el momento.',
  LANG_TOTAL_PRICE: 'Total {price}',
  LANG_DOWNLOAD_IMMEDIATELY: 'Descargue la imagen inmediatamente Después de la salida.',
  LANG_NEXT: 'próximo',
  LANG_TITLE: 'Banco de Imágenes: Fotos Libres de Derechos y Vectores - 123RF',
  SIMILAR_STOCK_PHOTOS: 'Imágenes similares',
  EMPTY_SIMILAR_SEARCH_RESULTS: '¡Ups! Nada que ver aqui.',
  UNAVAILABLE_IMAGE: 'Imagen no disponible',
  LANG_DOWNLOAD_PACK: 'Descargar Pack',
  LANG_GET_X_ANY_TIME: '{quota} fotos o vectores en cualquier momento',
  LANG_X_CREDITS: '{total} Créditos',
  LANG_PER_CREDIT: '/Crédito',
  LANG_GET_X_PHOTOS_AND_Y_VIDEOS_ANY_TIME: '{photoCount} fotos o {videoCount, plural, =0 {} =1 { o # video} other { o # videos}} en cualquier momento',
  INCLUDES_STANDARD_LICENSE: 'Incluir licencia estándar',
  LANG_MEDIUM_SIZE: 'Talla mediana',
  LANG_LARGE_SIZE: 'Talla grande',
  LANG_EXTRA_LARGE_SIZE: 'Tamaño extra grande',
  LANG_STANDARD_SIZES: 'Tamaños estándar',
  LANG_ROYALTY_FREE: 'libres de derechos',
  LANG_OPTIONS: 'Opción',
  LANG_BENEFIT_FROM_PLUS_PLAN: 'Benefíciese de nuestro plan PLUS desde sólo',
  LANG_RELATED_FONT_SEARCH: 'búsquedas relacionadas',
  AI_GENERATED_PHOTO: 'Imagen generada por IA',
  FONT_PRICING_CREDIT: 'Créditos',
  '3_FONTS_3_IMGS': '3 fuentes <b>o</b> 3 imágenes <b>o</b> 1 video',
  '9_FONTS_9_IMG_3_VIDEOS': '9 fuentes <b>o</b> 9 imágenes <b>o</b> 3 videos',
  PURCHASE_AND_DOWNLOAD: 'Comprar y descargar',
  FONT_PATH: 'fonts',
  FONT_ZIP: 'Fuente (OTF, TTF)',
  FONT_PACKS_LICENSE: '123RF Packs License',
  FONT_META_DESC: 'Descargar {product_title} Font, Tyeface o Typography para crear diseños impresionantes para la impresión y la web.',
  FONT_META_TITLE: 'Font - {product_title} | 123RF',
  FONT_SIMILAR: 'Fuentes similares',
  LANG_PLANS: 'PLANES',
  LANG_I_WANT_THIS: 'I want this',
  LANG_AI_VARIATION_TOOLTIP_TITLE: 'Prueba a crear variaciones',
  LANG_AI_VARIATION_TOOLTIP_DESCRIPTION: 'Genera nuevas imágenes basadas en la imagen original aportada. Además, ¡es una nueva forma de recompensar a nuestros colaboradores de 123RF cada vez que una imagen generada con IA obtiene una licencia!',
  LANG_SIZEBAR_TOOLTIP_DESCRIPTION: 'Nuestra comunidad se lo agradece. Las tarifas de licencia se compartirán con el Colaborador 123RF.',
  LANG_CONTRIBUTORS_AI_VARIATION: 'Los colaboradores 123RF de la imagen original son compensados por cada variación AI licenciada.',
  LANG_AI_VARIATION_LIMIT: 'Has llegado al límite de generación de imágenes AI por hoy.',
  LANG_GENERATE_COMEBACK: '¡Vuelve mañana para más generaciones!',
  LANG_LICENSE_AND_DOWNLOAD: 'Licencia y descarga',
  LANG_CONFIRM_OK: 'Vale',
  LANG_AI_SWITCH_CONTENT: 'Editor de fotos no es compatible con las variaciones AI. Todas las ediciones se perderán si cambia a variaciones AI.',
  LANG_GENERATE_VARIATIONS: 'Generar variaciones',
  LANG_GENERATE_WITH_AI: 'Generar imágenes con IA',
  LANG_NEW: 'Nuevo',
  LANG_AI_GENERATOR_LICENSE: 'Términos de Servicio del Generador AI 123RF (“Términos”)',
  LANG_I_UNDERSTAND: 'Comprendo',
  LANG_WHAT_IS_THIS: '¿En qué consiste?',
  LANG_AUTHENTIC: 'Auténticas',
  LANG_ABSTRACT: 'Abstracto',
  LANG_MACRO_CLOSEUP: 'Macro/ primer plano',
  LANG_OBJECT: 'Objeto',
  LANG_WATERCOLOR: 'Acuarela',
  LANG_FLAT: 'Plano',
  LANG_CARTOON: 'Dibujos animados',
  LANG_GEOMETRIC: 'Geométrico',
  LANG_GRADIENT: 'Gradiente',
  LANG_ISOMETRIC: 'Isométrico',
  LANG_3D: '3d',
  LANG_HAND_DRAWN: 'Dibujado a mano',
  LANG_NATURAL: 'Natural',
  LANG_WARM: 'Cálido',
  LANG_DRAMATIC: 'Dramático',
  LANG_VIVID_BOLD: 'Vivo y atrevido',
  LANG_BLACK_WHITE: 'Blanco y negro',
  LANG_ASIAN: 'Asiático',
  LANG_AFRICAN_AMERICAN: 'Afroamericano',
  LANG_STANDARD: 'Estándar',
  LANG_EXTENDED: 'Extendido',
  LANG_STANDARD_CONTENT_1: 'Vistas ilimitadas para redes sociales, web, correo electrónico y móvil.',
  LANG_STANDARD_CONTENT_2: 'Hasta 500.000 copias',
  LANG_STANDARD_CONTENT_3: 'No utilizar para merchandising.',
  LANG_EXTENDED_CONTENT_1: 'Vistas ilimitadas para redes sociales, web, correo electrónico y móvil.',
  LANG_EXTENDED_CONTENT_2: 'Impresiones ilimitadas',
  LANG_EXTENDED_CONTENT_3: 'Puede ser utilizado para merchandising y reventa',
  LANG_ORIGINAL_IMAGE: 'Imagen original',
  LANG_IMPORTANT_NOTE: 'Nota importante',
  LANG_AI_ORIGINAL_ALTERED: 'Las variaciones de IA son variantes de la imagen original que han sido alteradas utilizando tecnología de IA. Es similar a tener un editor de IA que retoca tu imagen elegida.',
  LANG_GENERATE_VARIATIONS_NOTE: 'Como actualmente estamos en la etapa Beta de Variaciones de Imagen impulsadas por IA, las imágenes generadas pueden producir resultados inesperados. Estamos trabajando duro para asegurar que dichos resultados y variaciones cumplan con las directrices de IA éticas y responsables.',
  LANG_LICENSE_AI_EDITED: 'Cuando licencias una de estas versiones editadas por IA, se paga una tarifa de licencia al titular de los derechos de autor de la imagen original.',
  LANG_MOOD: 'Estado de ánimo',
  LANG_AI_EXCLUDE_PLACEHOLDER: 'Por ejemplo, color verde, cabello liso',
  LANG_SIMILAR_STOCK: 'Imágenes de stock similares',
  LANG_PREMIUM_TAG_TOOLTIP: 'PREMIUM subscription only',

  WITH_MODEL_RELEASE: 'con Liberación de Modelo',
  WITH_PROPERTY_RELEASE: 'con Liberación de la Propiedad',
  MODEL_RELEASE_CONTENT: 'Una documento de Cesión de Modelos es un documento firmado por el/la persona que aparece en la foto, autorizando a que su imagen sea utilizada a nivel publicitario y/o editorial en amplitud de formas.',
  PROPERTY_RELEASE_CONTENT: 'Un Documento de Cesión de Derechos de Propiedad es un Documento firmado por el propietario de la vivienda donde se han tomado las fotografías autorizando expresamente a que dichas fotografías se utilicen a nivel publicitario y/o editorial en formas diversas.',

  variousStockGAI: 'Un modelo de IA legalmente conforme, entrenado con millones de fotos de stock y optimizado para las necesidades empresariales, brindando tranquilidad con cobertura de responsabilidad.',
  extendedLicense: 'Licencia Extendida',
  sizes: 'Tallas',

  downloadMail1st: 'Nuestro proveedor del servicio de ampliación de imágenes le enviará por correo electrónico a',
  downloadMail2nd:
    'las instrucciones para descargar el archivo final ampliado en el siguiente día laborable',

  aiGenarated: 'Imagen generada por IA',

  variousOpenJourney: 'El modelo de IA genera arte que se asemeja al estilo distintivo de Midjourney.',
  variousTurboVision: 'Generación de alta velocidad en resoluciones "normales" XL, mejorando la calidad general.',
  variousRealVisXL: 'El modelo apunta a lograr resultados fotorealistas con precisión y exactitud.',
  variousPortraitPlus: 'Modelo con composición de retrato uniforme, ideal para una relación de aspecto de 1:1.',
  enterVirifiedCode: 'Introduce el código de verificación',
  verify: 'Verificar',
  didNotGetCode: '¿No recibiste un código?',
  clickToResend: 'Haz clic para reenviar',
  otpCannotEmpty: 'OTP no puede estar vacío.',
  sentCodeToMail: 'Hemos enviado un código a tu correo electrónico registrado.',
  invalidCode: 'Código inválido',
  expiredOTP: 'El OTP ha caducado, por favor, envíelo de nuevo.',
  sendEmail: 'El correo electrónico se ha enviado correctamente, por favor, revise su bandeja de entrada para continuar.',
  remainingTime: 'Tiempo restante: ',
  tooManyAttempts: 'Demasiados intentos. Por favor, inténtelo de nuevo más tarde.',
};

export default es;
