import PropTypes from 'prop-types';

const IcAddedToLikebox = ({
  fillcolor,
  width,
  height,
}) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.410156 1C0.410156 0.447716 0.857872 0 1.41016 0H23.4102C23.9624 0 24.4102 0.447715 24.4102 1V23C24.4102 23.5523 23.9624 24 23.4102 24H1.41016C0.857872 24 0.410156 23.5523 0.410156 23V1Z" fill="#FFD44D" />
    <path fillRule="evenodd" clipRule="evenodd" d="M9.22385 6.27521C8.39102 6.27521 7.60489 6.61482 7.01154 7.23059C6.40592 7.86045 6.07342 8.69832 6.07619 9.58868C6.07619 10.4798 6.41067 11.3169 7.01787 11.9479L12.4071 17.5474L17.8055 11.9589C18.4095 11.3314 18.7428 10.4931 18.7428 9.60003C18.7456 8.70498 18.4143 7.8679 17.8098 7.24077C17.2169 6.62422 16.4304 6.28422 15.5952 6.28422C14.7596 6.28422 13.9723 6.625 13.3773 7.2439L12.4139 8.24433L11.4445 7.23685C10.8479 6.61717 10.0591 6.27521 9.22385 6.27521ZM12.4056 19.2916C12.0838 19.2916 11.7699 19.1573 11.5442 18.9223L5.87196 13.0282C4.98292 12.105 4.49367 10.8848 4.49367 9.59097C4.49011 8.29715 4.97738 7.07463 5.86563 6.15059C6.76139 5.22028 7.95404 4.70831 9.22466 4.70831C10.4157 4.70831 11.5395 5.15878 12.4155 5.98137C13.2883 5.16426 14.4081 4.71732 15.596 4.71732C16.869 4.71732 18.0628 5.23007 18.9578 6.16038C19.844 7.08168 20.3305 8.30342 20.327 9.60233C20.327 10.8977 19.8381 12.1195 18.9514 13.0404L13.2649 18.9277C13.0436 19.1581 12.7298 19.2916 12.4056 19.2916Z" fill={fillcolor} />
  </svg>

);
IcAddedToLikebox.propTypes = {
  fillcolor: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

IcAddedToLikebox.defaultProps = {
  fillcolor: '#212121',
  width: '24',
  height: '24',
};
export default IcAddedToLikebox;
