export const HOME_MODULE = 'HOME_MODULE';

export const SET_LOADING = 'SET_LOADING';

export const GET_IMAGES = 'GET_IMAGES';
export const SET_IMAGES = 'SET_IMAGES';
export const SET_CLICK = 'SET_CLICK';

export const SET_SETTING_LOADING = 'SET_SETTING_LOADING';
export const GET_SETTING = 'GET_SETTING';
export const SET_SETTING = 'SET_SETTING';

export const GET_RECOMMENDED_IMAGES = 'GET_RECOMMENDED_IMAGES';
export const SET_RECOMMENDED_IMAGES = 'SET_RECOMMENDED_IMAGES';
export const SET_RECOMMENDED_LOADING_IMAGES = 'SET_RECOMMENDED_LOADING_IMAGES';

export const GET_RECOMMENDED_FOOTAGE = 'GET_RECOMMENDED_FOOTAGE';
export const SET_RECOMMENDED_FOOTAGE = 'SET_RECOMMENDED_FOOTAGE';
export const SET_RECOMMENDED_LOADING_FOOTAGE = 'SET_RECOMMENDED_LOADING_FOOTAGE';
export const SET_DEFAULT_LIKEBOX = 'SET_DEFAULT_LIKEBOX';
export const SET_DISPLAY_BTN = 'SET_DISPLAY_BTN';

export const GET_TRENDING_KEYWORDS = 'GET_TRENDING_KEYWORDS';
