import PropTypes from 'prop-types';
import { MAIN_COLOR } from 'constants/index';

const IcDashboard = ({
  fillcolor,
  width,
  height,
}) => (
  <svg width={width} height={height} viewBox="0 0 24 25" fill={fillcolor} xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_4787_39273" maskUnits="userSpaceOnUse" x="3" y="2" width="17" height="20">
      <path d="M3.5 2.83643H19.9592V21.2618H3.5V2.83643Z" fill="white" />
    </mask>
    <g mask="url(#mask0_4787_39273)">
      <path fillRule="evenodd" clipRule="evenodd" d="M9.53158 19.539H6.60672C5.84075 19.539 5.21974 18.917 5.21974 18.1497V5.94854C5.21974 5.18125 5.84075 4.55923 6.60672 4.55923H16.8525C17.6184 4.55923 18.2394 5.18125 18.2394 5.94854V9.8304C18.2394 10.3062 18.6245 10.6918 19.0993 10.6918C19.5742 10.6918 19.9592 10.3062 19.9592 9.8304V5.94854C19.9592 4.22982 18.5683 2.83643 16.8525 2.83643H6.60672C4.8909 2.83643 3.5 4.22982 3.5 5.94854V18.1497C3.5 19.8684 4.8909 21.2618 6.60672 21.2618H9.53158C10.0065 21.2618 10.3914 20.8761 10.3914 20.4004C10.3914 19.9247 10.0065 19.539 9.53158 19.539Z" fill={fillcolor} />
    </g>
    <path fillRule="evenodd" clipRule="evenodd" d="M15.3575 8.71489H8.52539C8.05185 8.71489 7.66797 8.33034 7.66797 7.85598C7.66797 7.38162 8.05185 6.99707 8.52539 6.99707H15.3575C15.8311 6.99707 16.215 7.38162 16.215 7.85598C16.215 8.33034 15.8311 8.71489 15.3575 8.71489Z" fill={fillcolor} />
    <path fillRule="evenodd" clipRule="evenodd" d="M15.3575 12.9415H8.52539C8.05185 12.9415 7.66797 12.5569 7.66797 12.0825C7.66797 11.6082 8.05185 11.2236 8.52539 11.2236H15.3575C15.8311 11.2236 16.215 11.6082 16.215 12.0825C16.215 12.5569 15.8311 12.9415 15.3575 12.9415Z" fill={fillcolor} />
    <path fillRule="evenodd" clipRule="evenodd" d="M11.7305 17.1675H8.52539C8.05185 17.1675 7.66797 16.783 7.66797 16.3086C7.66797 15.8343 8.05185 15.4497 8.52539 15.4497H11.7305C12.204 15.4497 12.5879 15.8343 12.5879 16.3086C12.5879 16.783 12.204 17.1675 11.7305 17.1675Z" fill={fillcolor} />
    <mask id="mask1_4787_39273" maskUnits="userSpaceOnUse" x="12" y="12" width="9" height="9">
      <path d="M12.3477 12.5474H20.3331V20.9306H12.3477V12.5474Z" fill="white" />
    </mask>
    <g mask="url(#mask1_4787_39273)">
      <path fillRule="evenodd" clipRule="evenodd" d="M15.9814 20.9306C15.8678 20.9306 15.7525 20.908 15.6421 20.8603L12.8657 19.6605C12.4309 19.4725 12.2304 18.9672 12.418 18.5316C12.6057 18.0961 13.1104 17.8955 13.545 18.0831L15.5919 18.9677L18.7164 13.0072C18.9366 12.5872 19.4547 12.4254 19.8742 12.6461C20.2934 12.8665 20.4548 13.3858 20.2346 13.8058L16.7409 20.4709C16.5885 20.7618 16.2911 20.9306 15.9814 20.9306Z" fill={fillcolor} />
    </g>
  </svg>

);
IcDashboard.propTypes = {
  fillcolor: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

IcDashboard.defaultProps = {
  fillcolor: MAIN_COLOR,
  width: '24',
  height: '24.04',
};
export default IcDashboard;
