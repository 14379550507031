import PropTypes from 'prop-types';

const IcLogout = ({
  fillColor,
  width,
  height,
}) => (
  <svg width={width} height={height} viewBox="0 0 19 15.55" fill={fillColor} xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M18.6362 8.63548H6.54528C6.06855 8.63548 5.68164 8.249 5.68164 7.77184C5.68164 7.29468 6.06855 6.9082 6.54528 6.9082H18.6362C19.1129 6.9082 19.4998 7.29468 19.4998 7.77184C19.4998 8.249 19.1129 8.63548 18.6362 8.63548Z" fill={fillColor} />
    <path fillRule="evenodd" clipRule="evenodd" d="M16.0452 11.2275C15.8241 11.2275 15.6034 11.1432 15.4346 10.9744C15.0973 10.6371 15.0973 10.0905 15.4346 9.7532L17.415 7.77285L15.4346 5.7925C15.0973 5.45525 15.0973 4.90856 15.4346 4.5713C15.7719 4.23405 16.3186 4.23405 16.6558 4.5713L19.2469 7.16225C19.5841 7.49951 19.5841 8.0462 19.2469 8.38345L16.6558 10.9744C16.487 11.1432 16.2663 11.2275 16.0452 11.2275Z" fill={fillColor} />
    <path fillRule="evenodd" clipRule="evenodd" d="M8.27244 15.5455C3.9868 15.5455 0.5 12.059 0.5 7.77273C0.5 3.4865 3.9868 0 8.27244 0C10.0329 0 11.7584 0.605409 13.1306 1.70525C13.5029 2.0032 13.5633 2.54686 13.2649 2.91909C12.967 3.29261 12.4221 3.35264 12.0511 3.05295C10.9682 2.1863 9.66155 1.72727 8.27244 1.72727C4.93893 1.72727 2.22721 4.43952 2.22721 7.77273C2.22721 11.1059 4.93893 13.8182 8.27244 13.8182C9.66284 13.8182 10.9686 13.36 12.0498 12.4934C12.4212 12.1937 12.9648 12.2537 13.2645 12.6264C13.5624 12.9986 13.5033 13.5414 13.1311 13.8402C11.7597 14.94 10.0342 15.5455 8.27244 15.5455Z" fill={fillColor} />
  </svg>

);
IcLogout.propTypes = {
  fillColor: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

IcLogout.defaultProps = {
  fillColor: 'white',
  width: '20',
  height: '20',
};
export default IcLogout;
