import React from 'react';
import PropTypes from 'prop-types';
import { BLACK_BORDER_COLOR } from 'constants/index';

const IcMuted = ({
  fillcolor,
  width,
  height,
}) => (
  <svg width={width} height={height} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M5.26474 16.7087H12.5386C12.8642 16.7087 13.1753 16.5852 13.4093 16.3667L22.6656 7.71938C23.4712 6.9665 24.8024 7.52937 24.8024 8.62187V39.6085C24.8024 40.7485 23.3771 41.29 22.6005 40.4469L14.7767 31.961C14.538 31.6997 14.1979 31.5525 13.841 31.5525H5.26474C4.56533 31.5525 4.00098 30.9944 4.00098 30.308V17.9533C4.00098 17.2669 4.56533 16.7087 5.26474 16.7087Z" fill={fillcolor} />
  </svg>

);
IcMuted.propTypes = {
  fillcolor: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

IcMuted.defaultProps = {
  fillcolor: BLACK_BORDER_COLOR,
  width: '24',
  height: '24',
};
export default IcMuted;
