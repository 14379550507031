/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {
  useState, useContext, useMemo,
} from 'react';
import {
  Tabs, Form, Button, Input, Modal, Select,
} from 'antd';
import { getTransText } from 'common/translate';
import IcChecked from 'components/icons/CustomTag/IcChecked';
import MainContext from 'stores/context/MainContexts';
import Axios from 'services/Axios/Axios';
import { API_ROUTES } from 'constants/routes';
import { MAX_LENGTH_255, SUCCESSFUL } from 'constants/index';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { useForm } from 'antd/lib/form/Form';
import IcCreateNewLikebox from 'components/icons/MyCollection/IcCreateNewLikebox';
import CustomInputFormItem from 'components/atoms/CustomInputFormItem/CustomInputFormItem';
import IcLinkToCollection from 'components/icons/MyCollection/IcLinkToCollection';
import { useRouter } from 'next/router';
import IcArrowLeft from '../../icons/MyCollection/IcArrowLeft';

const { Search } = Input;
const { TabPane } = Tabs;
const { Option, OptGroup } = Select;
const COLLECTION_TYPE = {
  PERSONAL: 'PERSONAL',
  GROUP: 'GROUP',
};

const Collection = ({
  onChangeVisible,
  onCreateNewLikebox,
  haveBackBtn,
  onBtnBackClicked,
  setDefaultLikebox,
  selectedLB,
  likeboxGroups,
  likeboxData,
}) => {
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [createButtonDisable, setCreateButtonDisable] = useState(true);
  const {
    actionsUser, actionsSearchResult, actions,
  } = useContext(MainContext);

  const [keyword, setKeyword] = useState('');
  const [form] = useForm();
  const router = useRouter();

  const filteredLikeboxData = useMemo(
    () => likeboxData?.sort((a, b) => new Date(b.datecreated) - new Date(a.datecreated)).filter((v) => new RegExp(keyword, 'gi').test(v?.lightbox_name)),
    [keyword],
  );

  const filteredLikeboxGroupData = useMemo(
    () => likeboxGroups?.sort((a, b) => new Date(b.datecreated) - new Date(a.datecreated))
      .map((v) => ({
        ...v,
        likebox: v?.likebox?.filter((lb) => new RegExp(keyword, 'gi').test(lb?.lb_name)),
      })),
    [keyword],
  );

  // Call the function to search and set the default likebox
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    form.resetFields();
    setIsModalVisible(false);
  };
  const handleOnCreate = async () => {
    setLoading(true);
    onChangeVisible(false);
    await Axios.post(
      API_ROUTES.ADD_LIKE_BOX,
      {
        likebox_name: form.getFieldValue('createNewCollection'),
        group_id: form?.getFieldValue('select_group') ?? 0,
        description: form.getFieldValue('description'),
      },
      (status, data) => {
        setLoading(false);
        if (data.status === SUCCESSFUL) {
          if (onCreateNewLikebox !== null) {
            onCreateNewLikebox(true, form.getFieldValue('createNewCollection'), false, true);
          }
          actionsSearchResult.setDataIsAddedAudioDetail(0);
          actions.setDefaultLb(data?.content);

          actionsUser.getAllLikeBox();
          form?.resetFields();
        } else if (onCreateNewLikebox !== null) {
          onCreateNewLikebox(true);
        }
        handleCancel();
      },
    );
  };
  const checkCreateDisable = () => {
    form
      .validateFields()
      .then(() => setCreateButtonDisable(false))
      .catch(() => setCreateButtonDisable(true));
  };
  const navigateToCollection = ({
    type, likeboxId, LbName, groupName,
  }) => {
    switch (type) {
      case COLLECTION_TYPE.PERSONAL:
        router.push(`/dashboard/detailMyCollection?type=1&likeboxId=${likeboxId}&LbName=${LbName}`);
        break;
      case COLLECTION_TYPE.GROUP:
        router.push(`/dashboard/detailMyCollection?type=2&likeboxId=${likeboxId}
      &LbName=${LbName}&groupName=${groupName}`);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <>
        <Form className="collection-likebox">
          {haveBackBtn && (
            <>
              <span
                aria-hidden
                role="button"
                className="collection-likebox-back-btn"
                onClick={onBtnBackClicked}
              >
                <IcArrowLeft />
              </span>
              <div className="collection-likebox-header">
                <span
                  aria-hidden
                  role="button"
                  className="collection-likebox-header-close-icon"
                  onClick={onBtnBackClicked}
                >
                  <IcArrowLeft />
                </span>
                <div className="collection-likebox-header-title">{getTransText('collection')}</div>
              </div>
            </>
          )}
          <div className="collection-likebox-search">
            <Search
              placeholder={getTransText('searchCollection')}
              allowClear
              onChange={(e) => {
                setKeyword(e.target.value.toLowerCase());
              }}
            />
          </div>
          <Tabs centered defaultActiveKey="1">
            <TabPane tab={getTransText('personal')} key="1">
              <>
                {filteredLikeboxData?.map((item) => (
                  // eslint-disable-next-line jsx-a11y/no-static-element-interactions
                  <div className="item-likebox-name" key={item?.lightbox_id} onClick={() => setDefaultLikebox(item)}>
                    <span
                      className="link-to-collection-icon"
                      aria-hidden="true"
                      role="button"
                      onClick={() => navigateToCollection({
                        type: COLLECTION_TYPE.PERSONAL,
                        likeboxId: item?.lightbox_id,
                        LbName: item?.lightbox_name,
                      })}
                    >
                      <IcLinkToCollection />
                    </span>
                    <div className="item-likebox-item-name body">
                      {item?.lightbox_name}
                      {item?.lightbox_id === selectedLB?.lightbox_id ? <IcChecked fillcolor="#06874e" /> : <></>}
                    </div>
                    <p className="item-likebox-item-count">{item?.total_images}</p>
                  </div>
                ))}
              </>
              <>
                {isEmpty(likeboxData) && (
                  <div className="body mt-4">{getTransText('noFoundLikebox')}</div>
                )}
              </>
            </TabPane>
            <TabPane tab={getTransText('groups')} key="2">
              <>
                {filteredLikeboxGroupData?.map((item) => (
                  <div key={item?.group_id}>
                    <div className="group-name mt-4 ml-2" key={item?.group_id}>
                      {item?.group_desc}
                    </div>
                    {item?.likebox?.map((data) => (
                      <div
                        className="item-likebox-name"
                        key={data?.lb_id}
                        onClick={() => setDefaultLikebox({
                          description: data.description,
                          lightbox_id: data?.lb_id,
                          lightbox_name: data?.lb_name,
                          total_images: data?.total_images,
                          groupid: item?.group_id,
                        })}
                      >
                        <span
                          className="link-to-collection-icon"
                          aria-hidden="true"
                          role="button"
                          onClick={() => navigateToCollection({
                            type: COLLECTION_TYPE.GROUP,
                            likeboxId: +data?.lb_id,
                            LbName: data?.lb_name,
                            groupName: item?.group_desc,
                          })}
                        >
                          <IcLinkToCollection />
                        </span>
                        <div className="item-likebox-item-name body">
                          {data?.lb_name}
                          {data?.lb_id === selectedLB?.lightbox_id ? <IcChecked fillcolor="#06874e" /> : ''}
                        </div>
                        <p className="item-likebox-item-count">{data?.total_images}</p>
                      </div>
                    ))}
                  </div>
                ))}
                <>
                  {isEmpty(likeboxGroups) && (
                    <div className="body mt-4">{getTransText('noFoundLikebox')}</div>
                  )}
                </>
              </>
            </TabPane>
          </Tabs>
          <div className="likebox-collection-add-new">
            <Button
              type="primary"
              shape="round"
              className="global-btn primary-btn btn-with-icon"
              icon={<IcCreateNewLikebox width="18" height="18" />}
              onClick={showModal}
            >
              {getTransText('createNew')}
            </Button>
          </div>
        </Form>
        <Modal
          visible={isModalVisible}
          className="new-collection search-page my-collection-add-modal"
          title={getTransText('createNewCollection')}
          onCancel={handleCancel}
        >
          <Form className="new-collection" form={form}>
            <CustomInputFormItem
              placeholder={getTransText('createNewPlaceholder')}
              onChange={checkCreateDisable}
              label={getTransText('collectionNameUpper')}
              formItemClassName="item-collection"
              rules={[
                {
                  required: true,
                  message: getTransText('fieldCannotEmpty'),
                },
                {
                  max: 50,
                  message: getTransText('likboxNameMaxLength50'),
                },
              ]}
              name="createNewCollection"
            />
            <CustomInputFormItem
              name="description"
              label={getTransText('description')}
              formItemClassName="item-collection"
              placeholder={getTransText('descriptionPlaceholder')}
              rules={[
                {
                  max: MAX_LENGTH_255,
                  message: getTransText('characterMaxChar255'),
                },
              ]}
              onChange={checkCreateDisable}
              type="textArea"
              inputClassName="text-area"
            />
            <Form.Item
              label={getTransText('selectGroup')}
              className="item-collection"
              name="select_group"
            >
              <Select defaultValue="0" name="select_group">
                <Option value="0" className="option-collection">
                  {getTransText('personal')}
                </Option>
                <OptGroup label="Groups" className="option-collection">
                  {likeboxGroups?.map((item) => (
                    <Option key={item?.group_id} value={item?.group_id}>
                      {item?.group_desc}
                    </Option>
                  ))}
                </OptGroup>
              </Select>
            </Form.Item>
            <Form.Item className="btn-collection">
              <Button className="global-btn secondary-btn mr-12" onClick={handleCancel}>
                {getTransText('cancel')}
              </Button>
              <Button
                loading={loading}
                disabled={createButtonDisable}
                className="global-btn primary-btn"
                onClick={handleOnCreate}
              >
                {getTransText('create')}
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </>
    </>
  );
};
Collection.propTypes = {
  onChangeVisible: PropTypes.func,
  onCreateNewLikebox: PropTypes.func,
  setDefaultLikebox: PropTypes.func,
  haveBackBtn: PropTypes.bool,
  selectedLB: PropTypes.shape({
    lightbox_id: PropTypes.string,
  }),
  onBtnBackClicked: PropTypes.func,
  likeboxData: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
  })),
  likeboxGroups: PropTypes.arrayOf(PropTypes.shape({
    groupId: PropTypes.number,
    groupName: PropTypes.string,
  })),
};

Collection.defaultProps = {
  onChangeVisible: null,
  onCreateNewLikebox: null,
  setDefaultLikebox: () => {},
  haveBackBtn: false,
  selectedLB: null,
  onBtnBackClicked: () => {},
  likeboxData: [],
  likeboxGroups: [],
};
export default Collection;
