import PropTypes from 'prop-types';

const IcLinkToCollection = ({ fillcolor, width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.2389 9.7525C10.4013 9.91792 10.6134 10 10.8267 10C11.0367 10 11.2475 9.91958 11.4079 9.75916L15.3947 5.78138L16.0272 6.4138C16.1924 6.57907 16.4129 6.66667 16.6372 6.66667C16.7485 6.66667 16.8607 6.64552 16.9677 6.60108C17.2903 6.46774 17.5 6.15274 17.5 5.80365V3.36302C17.5 3.36208 17.5 3.36113 17.5 3.36019C17.5 3.35609 17.4999 3.352 17.4999 3.34792C17.5037 3.13135 17.4241 2.91353 17.2608 2.74741C17.0943 2.57786 16.8741 2.49553 16.6554 2.50019C16.6493 2.50006 16.6433 2.5 16.6372 2.5H14.1964C13.8474 2.5 13.5325 2.71058 13.3992 3.03248C13.2654 3.35525 13.3392 3.72635 13.5864 3.97318L14.2208 4.60754L10.2455 8.57415C9.92095 8.8979 9.91765 9.42541 10.2389 9.7525Z"
      fill={fillcolor}
    />
    <path
      d="M2.5 14.2358C2.5 16.0358 3.96417 17.5 5.76417 17.5H12.5692C14.3692 17.5 15.8333 16.0358 15.8333 14.2358V9.16667C15.8333 8.70625 15.4604 8.33333 15 8.33333C14.5396 8.33333 14.1667 8.70625 14.1667 9.16667V14.2358C14.1667 15.1162 13.4496 15.8333 12.5692 15.8333H5.76417C4.88375 15.8333 4.16667 15.1162 4.16667 14.2358V7.43083C4.16667 6.55042 4.88375 5.83333 5.76417 5.83333H10.8333C11.2937 5.83333 11.6667 5.46042 11.6667 5C11.6667 4.53958 11.2937 4.16667 10.8333 4.16667H5.76417C3.96417 4.16667 2.5 5.63083 2.5 7.43083V14.2358Z"
      fill={fillcolor}
    />
  </svg>
);
IcLinkToCollection.propTypes = {
  fillcolor: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

IcLinkToCollection.defaultProps = {
  fillcolor: '#979797',
  width: '20',
  height: '20',
};
export default IcLinkToCollection;
