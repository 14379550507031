/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
import Axios from 'services/Axios/Axios';
import React, {
  forwardRef, memo, useContext, useEffect, useImperativeHandle, useState,
} from 'react';
import { API_ROUTES, ROUTES, ROUTES_SEARCH_PAGE } from 'constants/routes';
import {
  ACCESS_TOKEN, MAIN_COLOR, SHARED_SESSION, SUCCESSFUL,
} from 'constants/index';
import IcAddedToLikebox from 'components/icons/MyCollection/IcAddedToLikebox';
import PropTypes from 'prop-types';
import IcLoadingCircle from 'components/icons/Page/IcLoadingCircle';
import * as cookie from 'helpers/cookies';
import { cloneDeep, isEmpty } from 'lodash';
import router from 'next/router';
import IcAddToLikebox from 'components/icons/MyCollection/IcAddToLikebox';
import MainContext from 'stores/context/MainContexts';
import { getTransText } from 'common/translate';
import { Tooltip } from 'antd';

const IconAdd = memo(forwardRef(
  (
    {
      image,
      onChangeAdd,
      onChangeRemove,
      description,
      width,
      height,
      audioIconAdd,
      stickyIconAdd,
      detailPage,
      itemId,
      dataClone,
      setDataClone,
      myActivitiesPage,
      iconLike,
      selectedLB,
      isLiked,
      onAddToLikebox,
      onRemoveFromLikebox,
    },
    ref,
  ) => {
    const [loading, setLoading] = useState(false);
    const {
      actionsSearchResult, initDataSearchResultProvider, initDataUserProvider, actions,
    } = useContext(MainContext);
    const [isAdded, setIsAdded] = useState(false);
    const isCollectionLiked = isLiked;
    const IconLike = iconLike;

    let arrayDataClone = {};
    if (typeof window !== 'undefined') {
      arrayDataClone = cloneDeep(dataClone);
    }

    // set isAdded for likebox Sticky audio
    useEffect(() => {
      if (stickyIconAdd && router?.pathname !== ROUTES.DETAIL_AUDIO_PAGE) {
        setIsAdded(initDataSearchResultProvider?.isAdded === 1);
      }
    }, [
      initDataSearchResultProvider?.isAdded,
      loading,
      image,
      initDataSearchResultProvider?.currentStickyPlaying?.audioId,
      dataClone,
    ]);
    // set isAdded for likebox  audio frame
    useEffect(() => {
      if (audioIconAdd) {
        setIsAdded(image?.added === 1);
      }
    }, [loading, initDataSearchResultProvider?.dataAudio]);
    // set isAdded for likebox not audio page
    useEffect(() => {
      if (!audioIconAdd && !stickyIconAdd) {
        setIsAdded(image?.added === 1);
      }
    }, [dataClone]);

    // change added likebox of item onlick
    useEffect(() => {
      if (
        initDataSearchResultProvider?.dataIsAdded !== undefined
        && ROUTES_SEARCH_PAGE.includes(router.pathname)
      ) {
        const index = arrayDataClone.findIndex(
          (item) => +item?.id === initDataUserProvider?.imageIdCurrent,
        );
        if (index !== -1) {
          arrayDataClone[index].added = initDataSearchResultProvider?.dataIsAdded;
        }
        if (!audioIconAdd && !stickyIconAdd) {
          setDataClone(arrayDataClone);
        } else {
          actionsSearchResult.setDataAudioClone(arrayDataClone);
        }
      }
    }, [
      initDataSearchResultProvider?.dataIsAdded,
      initDataUserProvider?.imageIdCurrent,
      initDataUserProvider.dataAddedDefaultCollection,
    ]);

    // change added likebox of all item
    useEffect(() => {
      if (ROUTES_SEARCH_PAGE.includes(router.pathname)) {
        const data = dataClone?.map((item) => {
          if (!isEmpty(initDataUserProvider.dataAddedDefaultCollection)) {
            initDataUserProvider.dataAddedDefaultCollection.forEach((element) => {
              if (item.id === element.image_id) {
                item.added = element.added;
              }
            });
          }
          return item;
        });
        if (!audioIconAdd && !stickyIconAdd) {
          setDataClone(data);
        } else {
          actionsSearchResult.setDataAudioClone(data);
        }
      }
    }, [initDataUserProvider.dataAddedDefaultCollection]);

    // set isAdded for icon likebox in detail audio page
    useEffect(() => {
      if (detailPage || (stickyIconAdd && router?.pathname === ROUTES.DETAIL_AUDIO_PAGE)) {
        setIsAdded(initDataSearchResultProvider?.dataIsAdded === 1);
      }
    }, [
      initDataSearchResultProvider?.dataIsAdded,
      loading,
      initDataSearchResultProvider?.currentStickyPlaying?.audioId,
    ]);

    const checkAuthentication = () => {
      const checkToken = cookie.getCookies(ACCESS_TOKEN);
      const checkLoginSession = cookie.getCookies(SHARED_SESSION);
      if (!checkToken || isEmpty(checkToken) || isEmpty(checkLoginSession)) {
        return false;
      }
      return true;
    };

    const handleAddtoLikebox = async (imageId, isUndo) => {
      actions.setDisplayBtn(false);
      if (checkAuthentication()) {
        try {
          setLoading(true);
          const payload = {
            image_id: imageId,
          };
          if (selectedLB?.lightbox_id) {
            payload.lb_id = selectedLB.lightbox_id;
          }
          await Axios.post(
            API_ROUTES.ADD_IMAGE_LIKEBOX,
            payload,
            (status1, data) => {
              if (data.status === SUCCESSFUL) {
                // change status added for new data clone from old data
                // when click icon likebox on audioFrame
                onAddToLikebox(description);
                if (audioIconAdd) {
                  const index = arrayDataClone.findIndex(
                    (item) => item?.id?.toString() === imageId?.toString(),
                  );
                  if (audioIconAdd && index !== -1) {
                    arrayDataClone[index].added = 1;
                    actionsSearchResult.setDataAudioClone(arrayDataClone);
                  }
                }
                // change status added for new data clone from old data
                // when click icon likebox on stickyAudio
                if (stickyIconAdd) {
                  const index = arrayDataClone.findIndex((item) => +item?.id
                    === initDataSearchResultProvider?.currentStickyPlaying?.audioId);
                  if (index !== -1) {
                    arrayDataClone[index].added = 1;
                    actionsSearchResult.setDataAudioClone(arrayDataClone);
                  } else {
                    actionsSearchResult.setIsAddedAudio(1);
                  }
                }
                // detail audio
                if (detailPage || stickyIconAdd) {
                  actionsSearchResult.setDataIsAddedAudioDetail(1);
                }
                if (isUndo) {
                  onChangeAdd(true, imageId);
                }
                setIsAdded((curr) => !curr);
              }
            },
          );
        } catch {
          //
        } finally {
          setLoading(false);
        }
      } else {
        router.push(ROUTES.LOGIN);
      }
    };

    const handleRemoveToLikebox = async (imageId, isUndo) => {
      if (checkAuthentication()) {
        actions.setDisplayBtn(false);
        try {
          setLoading(true);
          await Axios.post(
            API_ROUTES.REMOVE_IMAGE_LIKEBOX,
            {
              lb_id: selectedLB.lightbox_id,
              selected_images: [imageId],
            },
            (status1, data) => {
              if (data.status === SUCCESSFUL) {
                onRemoveFromLikebox(description);
                // change status added for new data clone from old data
                // when click icon likebox on audioFrame
                if (audioIconAdd) {
                  const index = arrayDataClone.findIndex(
                    (item) => item?.id?.toString() === imageId?.toString(),
                  );
                  if (index !== -1) {
                    arrayDataClone[index].added = 0;
                    actionsSearchResult.setDataAudioClone(arrayDataClone);
                  }
                }
                // change status added for new data clone from old data
                // when click icon likebox on stickyAudio
                if (stickyIconAdd) {
                  const index = arrayDataClone.findIndex((item) => +item?.id
                  === initDataSearchResultProvider?.currentStickyPlaying?.audioId);
                  if (index !== -1) {
                    arrayDataClone[index].added = 0;
                    actionsSearchResult.setDataAudioClone(arrayDataClone);
                  } else {
                    actionsSearchResult.setIsAddedAudio(0);
                  }
                }
                // detail audio
                if (detailPage || stickyIconAdd) {
                  actionsSearchResult.setDataIsAddedAudioDetail(0);
                }
                if (isUndo) {
                  onChangeRemove(true, imageId);
                }
                setIsAdded(!isAdded);
              }
            },
          );
        } catch {
          //
        } finally {
          setLoading(false);
        }
      } else {
        router.push(ROUTES.LOGIN);
      }
    };

    useImperativeHandle(ref, () => ({
      handleAddtoLikebox,
      handleRemoveToLikebox,
    }));

    return (
      <>
        <Tooltip
          title={isCollectionLiked ? getTransText('removedFromCollection') : getTransText('addToMyCollections')}
        >
          <div
            role="button"
            className={`likebox-padding-item ${myActivitiesPage ? 'no-padding-right' : ''}`}
            tabIndex="0"
            aria-hidden="true"
            onClick={
              !isCollectionLiked
                ? () => handleAddtoLikebox(
                  image?.id ?? image?.audioid ?? itemId ?? image?.image_id,
                  true,
                )
                : () => handleRemoveToLikebox(
                  image?.id ?? image?.audioid ?? itemId ?? image?.image_id,
                  true,
                )
            }
          >
            {loading ? (
              <div className="loading-icon-add">
                <IcLoadingCircle
                  width={width ?? '24'}
                  height={height ?? '24'}
                  fillcolor={MAIN_COLOR}
                />
              </div>
            ) : (
              <>
                {iconLike ? (
                  <IconLike
                    fillColor={isCollectionLiked ? '#FFD44D' : '#212121'}
                    solid={isCollectionLiked}
                    solidFillColor="#FFD44D"
                    isAdded={isCollectionLiked}
                  />
                ) : !isCollectionLiked ? (
                  <IcAddToLikebox width={width ?? '24'} height={height ?? '24'} />
                ) : (
                  <IcAddedToLikebox width={width ?? '24'} height={height ?? '24'} />
                )}
              </>
            )}
          </div>
        </Tooltip>
      </>
    );
  },
));

IconAdd.propTypes = {
  image: PropTypes.objectOf(PropTypes.any),
  onChangeAdd: PropTypes.func,
  onChangeRemove: PropTypes.func,
  description: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  audioIconAdd: PropTypes.bool,
  stickyIconAdd: PropTypes.bool,
  dataClone: PropTypes.arrayOf(PropTypes.any),
  detailPage: PropTypes.bool,
  itemId: PropTypes.string,
  setDataClone: PropTypes.func,
  myActivitiesPage: PropTypes.bool,
  iconLike: PropTypes.any,
  selectedLB: PropTypes.any,
  isLiked: PropTypes.bool,
  onAddToLikebox: PropTypes.func,
  onRemoveFromLikebox: PropTypes.func,
};

IconAdd.defaultProps = {
  image: null,
  onChangeAdd: null,
  onChangeRemove: null,
  description: null,
  width: null,
  height: null,
  audioIconAdd: false,
  stickyIconAdd: false,
  dataClone: null,
  detailPage: false,
  itemId: null,
  setDataClone: null,
  myActivitiesPage: false,
  iconLike: false,
  selectedLB: null,
  isLiked: false,
  onAddToLikebox: null,
  onRemoveFromLikebox: null,
};
export default IconAdd;
