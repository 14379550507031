import PropTypes from 'prop-types';
import { ICON_COLOR_DEFAULT } from 'constants/index';

const IcMyActivities = ({
  fillcolor,
  width,
  height,
}) => (
  <svg width={width} height={height} viewBox="0 0 24 24.04" fill={fillcolor} xmlns="http://www.w3.org/2000/svg">
    <rect y="0.333984" width="24" height="24" fill="white" fillOpacity="0.01" />
    <path fillRule="evenodd" clipRule="evenodd" d="M4.361 12.1286C5.07478 13.2647 7.48041 16.4476 11.9998 16.4476C16.5183 16.4476 18.9235 13.2664 19.639 12.1286C18.9252 10.9916 16.5247 7.81125 11.9998 7.81125C7.47523 7.81125 5.07435 10.9916 4.361 12.1286ZM11.9998 18.1749C5.53424 18.1749 2.71062 12.7508 2.59316 12.5198C2.46923 12.2745 2.4688 11.9852 2.59273 11.7399C2.70975 11.5085 5.52474 6.08398 11.9998 6.08398C18.4753 6.08398 21.2902 11.5085 21.4073 11.7399C21.5312 11.9852 21.5308 12.2745 21.4068 12.5198C21.2894 12.7508 18.4658 18.1749 11.9998 18.1749Z" fill={fillcolor} />
    <path fillRule="evenodd" clipRule="evenodd" d="M12.0005 7.81125C9.61946 7.81125 7.68232 9.74838 7.68232 12.1294C7.68232 14.5105 9.61946 16.4476 12.0005 16.4476C14.3816 16.4476 16.3187 14.5105 16.3187 12.1294C16.3187 9.74838 14.3816 7.81125 12.0005 7.81125ZM12.0005 18.1749C8.6669 18.1749 5.95508 15.4626 5.95508 12.1294C5.95508 8.79624 8.6669 6.08398 12.0005 6.08398C15.3342 6.08398 18.046 8.79624 18.046 12.1294C18.046 15.4626 15.3342 18.1749 12.0005 18.1749Z" fill={fillcolor} />
    <path fillRule="evenodd" clipRule="evenodd" d="M13.728 12.1296C13.728 13.0835 12.9546 13.8569 12.0007 13.8569C11.0468 13.8569 10.2734 13.0835 10.2734 12.1296C10.2734 11.1757 11.0468 10.4023 12.0007 10.4023C12.9546 10.4023 13.728 11.1757 13.728 12.1296Z" fill={fillcolor} />
  </svg>

);

IcMyActivities.propTypes = {
  fillcolor: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

IcMyActivities.defaultProps = {
  fillcolor: ICON_COLOR_DEFAULT,
  width: '24',
  height: '24.04',
};
export default IcMyActivities;
