const hu = {
  // Header
  photos: 'Photos',
  Photos: 'Photos',
  vectors: 'Vectors',
  Vectors: 'Vectors',
  footageupper: 'FOOTAGE',
  footage: 'Footage',
  video: 'Video',
  audio: 'Audio',
  signin: 'Sign In',
  imageLogo:
    'https://static-cdn.assetsdelivery.com/images/new_translate_corpplus_logo/logo_English.png',
  language: 'Language',
  // Footer
  contact: 'Contact',
  contactUs: 'Contact Us',
  privacy: 'Privacy Policy',
  terms: 'Terms of Use',
  cookie: 'Cookie Policy',
  corporateAgreement: 'License Agreement',
  // ForgotPassword
  forgotPwdTitle2: 'Forgot Your Password',
  forgotPwdDesc2:
    "That's OK, it happens to all of us. Enter your username below and we'll send you a link to reset your password",
  forgotPwdFail: 'Please enter a valid email address.',
  emailCannotEmpty: 'E-mail cannot be empty.',
  returnToLogin: 'Return to Login',
  email: 'Email',

  // share likebox
  duplicateCollection: 'Duplicate collection',
  removeFromCollection: 'Remove from collection',
  addToCollection: 'Add to collection',
  totalContentInThisCollection: 'Total content in this collection',
  duplicate: 'Duplicate',
  collectionDescription: 'Collection description',
  descriptionHere: 'Description here',
  duplicateCollectionSuccess:
    'You have successfully duplicated this collection to your personal collections.',

  // Login
  username: 'Username',
  pw: 'Password',
  rememberMe: 'Remember Me',
  login: 'Log In',
  forgotPassword: 'Forgot Password',
  loginAsGuest: 'Continue as Guest',
  // ResetPassword
  resetPwdDesc: "Please enter your new password, and we'll log you in right away.",
  newPw: 'New password',
  confirmPwd: 'Confirm Password',
  resetPwdButton: 'Reset My Password',
  resetPwdTitle: 'Reset Your Password',
  enterYourNewPw: 'Please enter your new password.',
  pwMiniMumChars: 'A minimum of 8 characters',
  pwMaxChar20: 'The maximum characters allowed is 20',
  pwLowerCase: 'At least one lower case letter',
  pwUpperCase: 'At least one upper case letter',
  pwNumeral: 'At least one numeral',
  pwdSpecialChar: 'At least one special character',
  confirmPwNotMatch: 'Confirm Password does not match with Password',
  resetPwdInvalidLink: 'This link is invalid or has expired.',
  pwHasBeenReset: 'Password has been reset, please login to proceed.',
  // Admin index
  companyName: 'Company Name',
  phone: 'Phone',
  fax: 'Fax',
  address: 'Address',
  idd: 'IDD',
  // Dashboard Admin Credits
  myBalance: 'My balance',
  remaining: 'remaining',
  creditsRemaining: 'Credits remaining',
  topUp: 'Top up',
  groupBalance: 'Group balance',
  totalGroupBalance: 'Total group balance',
  manageGroupBalance: 'Manage group balance',
  individualBalance: 'Individual balance',
  manageIndividualBalance: 'Manage individual balance',
  totalDownloads: 'Total downloads',
  totalItems: 'Total items',
  // Update Password
  updatePassTitle: 'Update Password',
  updatePassDes: 'Update your password before you go!',
  oldPassword: 'Old password',
  confirmNewPassword: 'Confirm new password',
  returnToHome: 'Return to Home',
  // Cookie Policy
  langCookie01:
    'When you visit %host%, we place a small text file called a cookie in your computer hard disk.',
  langCookie02:
    'This file allows us to track you so that we are able to provide basic functionality to you such as shopping carts. They are also used to provide an enhanced online user experience and provide you with functionality such as "likebox".',
  langCookie03:
    'In addition, it allows us to analyse your interaction with our website (including pages visited, search terms and images viewed). This is irrespective of whether you choose to make a purchase or not.',
  langCookie04:
    'The cookies you consent to, are also used to profile you into an audience list so that we can deliver targeted advertising tailored to your interests.',
  langCookie05:
    'Most browsers allow you to reject cookies; but if you do so, certain functionality may be unavailable and certain webpages may not display properly.',

  // My Collections
  dashboard: 'Dashboard',
  users: 'Users',
  creditUsage: 'Credit usage',
  invoices: 'Invoices',
  licenseOnBehalf: 'License on Behalf',
  customizedPortal: 'Customized portal',
  companyInfo: 'Company info',
  myActivities: 'My activities',
  group: 'GROUP',
  likeboxEditSuccessful: 'You have successfully made changes to your collection.',
  addNewCollection: 'Add New Collection',
  downloadModalTitle: 'Image download',
  bulkDownloadTitle: 'Bulk Download',
  messageFileNotSupported1stHalf:
    'Image TIFF file, footage media and audio media are not supported. The following media:',
  messageFileNotSupported2ndHalf: 'has been removed from Bulk Download.',
  normalDownload: 'Normal download',
  myCredit: 'My credit',
  lowCreditsReminder: 'Low credits reminder',
  downloadModalId: 'Image ID:',
  downloadModalPrice: 'Credit:',
  clearAll: 'Clear all',
  info: 'Info',
  idCapitialization: 'ID',
  licenseOnBehalfOfYourClient: 'License on behalf of your client (Your client owns the license)',
  downloadUsing: 'download this content using',
  // homepage page
  home3Title: 'Free Browsing',
  toSearchKeyword: 'Írja ide amire keresni szeretne',
  toSearchAIKeyword: 'Kulcsszavak, cikkcím vagy blogcím megadása',
  allImages: 'All Images',
  freeImages: 'Free Images',
  categoryImage: 'Category',
  recentViewed: 'Recently Viewed',
  latestIMG: 'Latest Images',
  // Setting
  hello: 'Hello,',
  viewAccount: 'View Account',
  adminControlPanel: 'Admin Dashboard',
  downloadHistory: 'Download history',
  myAccount: 'My account',
  myCollections: 'My collections',
  hruc: 'HRUC',
  changePassword: 'Change password',
  logOut: 'Logout',
  personalCredits: 'Personal Credits',
  groupTotal: 'Group Total',
  toTalUnpaidLicense: 'Total Unpaid License',
  highRUnwatermarkComp: 'High-Res Unwatermark Comps',
  monthSelection: 'Month selection',

  // Menu
  categories: 'Categories',
  myLikebox: 'My Likebox',
  settings: 'Settings',
  notification: 'Notification',
  clear: 'Clear',
  likebox: 'Likebox',
  credits: 'Credits',
  // Credit summary at header
  downloadAndUsege: 'Download and Usage',
  totalDlCredit: 'Total Download Credits',
  allocatedDlCredit: 'Allocated Download Credits',
  personalAvailableCre: 'Personal Available Credit',
  groupAvailableCre: 'Group Available Credit',
  availableDlSlot: 'Available Download Slot(s)',
  uNPLD: 'Use Now, Pay Later Downloads',
  hiResHRUC: 'Hi-Res Unwatermarked Comps (HRUC)',
  monthlyAllocation: 'Monthly Allocation',
  remainingQuota: 'Remaining quota',
  remainingHRUCQ: 'Remaining Hi-Res Unwatermarked Comps Quota',
  useNowPayLater: 'Use-Now-Pay-Later (UNPL)',
  totalPendingLicense: 'Total Pending License Downloads',
  enterprisePlan: 'Corporate+ Enterprise Plan',
  unallocatedCredits: 'Unallocated Credits',
  unutilizedAllocatedCredits: 'Unutilized Allocated Credits',
  usersTitleTotalCredits: 'Users',
  groupsTitleTotalCredits: 'Csoportok',
  titleTotalCreditsAvailable: 'Total Available',
  // live chat
  liveChatLookIngHelp: 'Please let us know how we can help you.',
  liveChat: 'Live chat',
  whatsApp: 'WhatsApp',
  needHelp: 'Need help?',
  hiThere: 'Hi there,',
  // email response
  emailIsNotAvailable: 'E-mail is not available.',
  anErrorHaSoccured: 'An error has occured, please try again.',
  emailHasBeenSent:
    "E-mail has been sent successfully, please check your mailbox to proceed. We have sent an email to __email with reset instructions. If the email doesn't appear in your inbox within 5 minutes, please check your Spam folder.",
  // INVALIDPASSWORD
  inValidPassword: 'Invalid password! Check if your caps lock is on.',
  userIsBlocked: 'Too many failed attempts, you have been temporarily blocked.',
  blockWarrings: 'You will be temporarily blocked after 10 attempts!',
  // Update Password
  resetPwdUpdated: 'Your password has been updated!',
  updatePwDfailed: 'Failed to update password!',
  // notification
  // Notification Side Bar - user
  notificationShareLikebox: 'shared a Likebox named [{VAR1}] with you.',
  notificationOutofCredits: 'ran out of download credits.',
  notificationAllcateCredit: 'assigned [{VAR1}] download credits to you.',
  notificationRemoveCredits: 'removed [{VAR1}] download credits from your account.',
  notificationAssignGroups: 'assigned you into the [{VAR1}] group.',
  notificationRemoveFromGroup: 'removed you from the [{VAR1}] group.',
  purcahsedCredits: 'You have just purchased [{VAR1}] credits.',
  notificationAssignAdmin: 'assigned you as the administrator.',
  notificationRemoveAdmin: 'removed you as the administrator.',
  notificationAssignGroupAdmin: 'assigned you as the group administrator of [{VAR1}].',
  notificationRemoveGroupAdmin: 'removed you as the group administrator of [{VAR1}].',
  // Notification Side Bar - admin
  adminNotificationShareLikebox: 'shared a Likebox named [{VAR1}] with [{RECEIVER}].',
  adminNotificationAllcateCredits: 'assigned [{VAR1}] download credits to [{RECEIVER}].',
  adminNotificationRemoveCredist: "removed [{VAR1}] download credits from [{RECEIVER}]'s account.",
  adminNotificationAssignGroup: 'assigned [{RECEIVER}] into the [{VAR1}] group.',
  adminNotificationRemoveFromGroup: 'removed [{RECEIVER}] from the [{VAR1}] group.',
  adminPurchasedCredits: '[{RECEIVER}] have just purchased [{VAR1}] credits.',
  adminNotificationAssignAdmin: 'assigned [{RECEIVER}] as the administrator.',
  adminNotificationRemoveAdmin: 'removed [{RECEIVER}] as the administrator.',
  adminNotificationAssignGroudAdmin:
    'assigned [{RECEIVER}] as the group administrator of [{VAR1}].',
  adminNotificationremoveGroupAdmin: 'removed [{RECEIVER}] as the group administrator of [{VAR1}].',
  // datetime
  year: 'year',
  month: 'month',
  week: 'week',
  day: 'day',
  hour: 'hour',
  minute: 'minute',
  second: 'second',
  ago: 'ago',
  justNow: 'just now',
  // datetimes
  years: 'years',
  months: 'months',
  weeks: 'weeks',
  days: 'days',
  hours: 'hours',
  minutes: 'perc',
  seconds: 'seconds',
  // LicenseAgreement
  licenseAgreement: 'License Agreement',
  licenseAgreementCaption:
    'PLEASE READ ALL THE TERMS PROVIDED IN THIS CORPORATE+ CONTENT LICENSING PLATFORM. YOUR ACCEPTANCE OF THESE TERMS IS AN ABSOLUTE CONDITION OF YOUR USE OF THE CORPORATE+ CONTENT LICENSING PLATFORM AND THE CONTENT, AND FORMS A LEGAL AGREEMENT. BY ACCESSING ANY SERVICE OR PART THEREOF UNDER THE CORPORATE+ CONTENT LICENSING PLATFORM, YOU AGREE TO BE BOUND BY THE TERMS OF THESE LEGAL DOCUMENTS. 123RF RESERVES THE RIGHT TO CHANGE THESE TERMS AT ANY TIME WITHOUT PRIOR WRITTEN NOTICE, AND YOU AGREE TO BE BOUND BY SUCH CHANGES.',
  corpplusAgreement: 'Corporate+ Agreement',
  // login
  usernameCannotEmpty: 'Username cannot be empty',
  passWordCannotEmpty: 'Password cannot be empty',
  oldPasswordCannotEmpty: 'Old password cannot be empty',
  confirmPasswordCannotBeEmpty: 'Confirm new password cannot be empty',
  // update password
  newpasswordDifferent: 'The new pasword and old pasword must be different.',
  // sent email not 20 times
  forgotNot20: 'Cannot send more emails, please check your mailbox.',
  // errorMesServer
  errorMesServer: 'An error has occured, please try again or contact your administrators',
  // white space
  canNotContainSpaces: 'Password cannot contain spaces',
  // username is not available
  userNameNotAvailable: 'Username is not available.',
  // Username cannot contain spaces
  usernameCanNotContainSpaces: 'Username cannot contain spaces',
  // old browsers
  usingOlderBrowsers: 'Are you using an older browser?',
  upgradeBrowser: 'Upgrade to a newer browser version to enjoy a smoother experience!',
  clickToDownload: 'Click to download any of these:',
  googleChrome: 'Google Chrome',
  mozillaFirefox: 'Mozilla Firefox',
  microsoftEdge: 'Microsoft Edge',
  safari: 'Safari',
  // forgot page new description
  forgotPwdDescNew: "Enter your username below and we'll send you a link to reset your password.",
  // Notification
  readAll: 'Read All',
  viewAll: 'View all',
  // Filter bar
  aiGenerate: 'AI-generated image',
  aiContent: 'Include AI-generated content',
  filter: 'Filter',
  safeSearch: 'Safe Search',
  aiAssist: 'AI Assist',
  authentic: 'Authentic',
  freshness: 'Freshness',
  gender: 'Gender',
  sortBy: 'Sort by',
  mediaType: 'Media type',
  orientation: 'Orientation',
  modelPreferences: 'Model Preferences',
  people: 'People',
  more: 'More',
  numOfPeople: 'Number of people',
  age: 'Age',
  ethnicity: 'Ethnicity',
  collection: 'Collections',
  uploaded: 'Uploaded',
  style: 'Style',
  cutOut: 'Solid/Transparent Backgrounds',
  doNotIncludeWords: 'Do not include these words',
  doNotIncludeWords_ex: 'ex. cheese',
  color: 'Color',
  max1Color: 'Max 1 color',
  license: 'License Type',
  fps: 'FPS',
  resolution: 'Resolution',
  clipDuration: 'Clip duration (minutes)',
  loop: 'Loop',
  category: 'Category',
  genre: 'Genre',
  tempo: 'Tempo (bpm)',
  downloadedBy: 'Get downloaded by',
  // Filter button text
  Relevance: 'Relevance',
  New: 'New',
  Georank: 'Georank',
  DownloadHistory: 'Download history',
  Popularity: 'Popularity',
  Photography: 'Photography',
  VectorIllustration: 'Vector Illustration',
  Horizontal: 'Horizontal',
  Landspace: 'Landspace',
  Portrait: 'Portrait',
  Square: 'Square',
  Panorama: 'Panorama',
  numPpl: 'Number of People',
  WithoutPeople: 'Without People',
  FilterNumberPeople1: '1 person',
  FilterNumberPeople2: '2 People',
  FilterNumberPeople3: '3 People',
  FilterNumberPeople4: '4 people or more',
  Exclude: 'Exclude',
  SelectiveFocus: 'Selective Focus',
  Pattern: 'Pattern',
  Vibrance: 'Vibrance',
  Infants: 'Infants',
  Toddlers: 'Toddlers',
  Teens: 'Teens',
  PastWeek: 'Past week',
  LastMonth: 'Last month',
  PastThreeMonths: 'Past 3 months',
  PastTwodays: 'Past 2 days',
  Male: 'Male',
  Female: 'Female',
  Standard: 'Standard',
  extended: 'Extended',
  Editorial: 'Editorial',
  Black: 'Black',
  Caucasian: 'Kaukázusi',
  AfricanAmerican: 'Afroamerikai',
  Asian: 'Ázsiai',
  EastAsian: 'East Asian',
  Hispanic: 'Hispanic',
  Indian: 'Indian',
  MiddleEastern: 'Middle Eastern',
  SoutheastAsian: 'Southeast Asian',
  Babies: 'Babies',
  Children: 'Children',
  Teenagers: 'Teenagers',
  Adults: 'Adults',
  Seniors: 'Seniors',
  FPS1: '23.98/24',
  FPS2: '25',
  FPS3: '29.7/30',
  FPS4: '60',
  FPS5: '> 60',
  Off: 'Off',
  ZeroToOne: '0-1',
  OneToTwo: '1-2',
  TwoToThree: '2-3',
  LagerThree: '>3',
  Loopable: 'Yes',
  Nonloopable: 'No',
  Music: 'Music',
  SoundEffects: 'Sound Effects',
  LogoAndIdents: 'Logos & Idents',
  Veryslow: 'Very Slow',
  VeryslowSubText: '(0-79)',
  Slow: 'Slow',
  SlowSubText: '(80-119)',
  Medium: 'Medium',
  MediumSubText: '(120-139)',
  Fast: 'Fast',
  FastSubText: '(140-159)',
  Veryfast: 'Very Fast',
  VeryfastSubText: '(>160)',
  TwoToEight: '2 - 8',
  TwentyToThirTy: '12 - 30',
  ThirtySixToSixty: '30 - 60',
  OneHundredAndTwenty: '120',
  Popular: 'Popular',
  MostDownload: 'Most Download',
  Credits: 'Credits',
  Freshness: 'Freshness',
  pickAGenre: 'Pick a genre',
  // Paginations
  Of: 'of',
  Page: 'Page',
  // home
  recommended: 'Recommended for you',
  bestSellingHome: 'Browse best-selling royalty photos',
  trendingKeyWord: 'Trending Keywords',
  FAQ: 'Frequently Asked Questions (FAQ)',
  // audio
  categoriesAudio: 'HD stock audios',

  // genre list audios
  gameSounds: 'Game Sounds',
  cartoonSounds: 'Cartoon Sounds',
  soundPacks: 'Sound Packs',
  interfaceSounds: 'Interface Sounds',
  transitionsAndMovementsSounds: 'Transitions & Movement',
  natureSounds: 'Nature Sounds',
  domesticSounds: 'Domestic Sounds',
  urbanSounds: 'Urban Sounds',
  industrialSounds: 'Industrial Sounds',
  futuristicSounds: 'Futuristic Sounds',
  humanSounds: 'Human Sounds',
  othersSounds: 'Others',
  ambientSounds: 'Ambient',
  childrenSounds: "Children's",
  cinematicSounds: 'Cinematic',
  classicalSounds: 'Classical',
  corporateSounds: 'Corporate',
  countrySounds: 'Country & Western',
  drumSounds: 'Drum & Bass',
  electronicaSounds: 'Electronica',
  folkSounds: 'Folk & Acoustic',
  funkSounds: 'Funk & Groove',
  soulSounds: 'Soul & R&B',
  hiphopSounds: 'Hip Hop',
  holidaySounds: 'Holiday & Seasonal',
  jazzSounds: 'Jazz',
  popSounds: 'Pop',
  rockSounds: 'Rock',
  worldBeatSounds: 'World Beat',
  individualSounds: 'Individual',
  introSounds: 'Intro & Outro',
  ascSounds: 'Ascending & Descending',
  businessSounds: 'Business & Media',
  orchestralSounds: 'Orchestral',
  festiveSounds: 'Festive',
  percussiveSounds: 'Percussive',
  miscellaneousSounds: 'Miscellaneous',
  packsSounds: 'Packs',
  percussionSounds: 'Percussion Sounds',
  sampleLoopSounds: 'Sample Loop',

  // Search images
  searchImage: 'Photos & Vectors',
  StockPhotosAndImages: 'Stock Photos And Images',
  StockVectorClipartAndIllustration: 'stock-vektorok és illusztrációk',

  matches: 'matches',
  editorial: 'Editorial',
  downloaded: 'This image has been downloaded before',
  to: 'to',
  relatedSearches: 'Related Searches',
  similarTo: 'Similar to',
  similarStockPhotos: 'Similar Stock Photos',
  searchByImage: 'Search by image',
  options: 'Options',
  searchByImageMsg:
    'Search 123RF with an image instead of text.Try dragging an image to the search box.',
  mustBeJPGorPNG: 'Image must be JPG / PNG',
  sizeLessThan5MB: 'Image size must be less than 5 MB',
  sizeLessThan4MB: 'Image size must be less than 4 MB',
  dragOrDropFile: 'Drag and drop file or',
  browse: 'Browse',
  uploadedImage: 'Uploaded Image',
  // Search footage
  searchFootage: 'Footage & Videos',
  StockFootage: 'Stock Footage',
  businessFootage: 'Hacks to make the most of your business videos',

  // Search audio
  searchAudio: 'Audio (Music & Sound Effects)',
  StockAudio: 'Audio',
  similarTracks: 'Similar Tracks',
  viewAlbum: 'View Album',
  copyLink: 'Copy Link',
  preview: 'Preview',
  download: 'Download',
  copied: 'Copied!',

  bestSellingFootage: 'Browse best-selling royalty footage',
  featuresAudio: 'Featured audio',
  readmore: 'Read More',
  // justified layout
  similarImage: 'Similar images',
  addToLikebox: 'Add to Likebox',

  // recently view empty data
  rVPhoto: 'Your recently viewed images will show here',
  rVVectors: 'Your recently viewed vectors will show here',
  rVFootage: 'Your recently viewed video will show here',
  rVAudio: 'Your recently viewed audio will show here',
  // recently likes empty data
  rLPhoto: 'Your recently liked images will show here',
  rLVectors: 'Your recently liked vectors will show here',
  rLFootage: 'Your recently liked video will show here',
  rLAudio: 'Your recently liked audio will show here',
  // Content Unavailable
  contentUnavailable: 'Content Unavailable',
  contentError: 'We’re sorry, there’s some error displaying this content',
  emptySearchResultTitle: 'Oops, your search with those filters did not return with any results.',
  emptySearchResultTitleWithoutFilter: 'Oops, your search did not return any results.',
  emptySearchResultSubTitle: 'Try remove some filters or try a different keyword.',
  emptyReverseSearchTitle: 'No Result Found!',
  emptyReverseSearchSubTitle1:
    'Uploaded Image contains errors, make sure the image has the following criteria:',
  emptyReverseSearchSubTitle2: 'Check your refine search with keyword',
  emptyReverseSearchSubTitle3: 'Image must be JPG / PNG',
  emptyReverseSearchSubTitle4: 'Image size must be less than 5 MB',
  emptySearchResultClearFilter: 'Clear all filters',
  emptyDownloadHistoryResult: 'Your download history will show here',
  // Please enter a keyword
  keywordEmpty: 'Please enter a keyword',
  // admin redirect to
  plansAndPricing: 'Plans and Pricing ',
  // Footer langding page
  nameUnavailable: 'Name unavailable',
  addressUnavailable: 'Company address unavailable',
  phoneUnavailable: 'Phone number unavailable',
  faxUnavailable: 'Fax unavailable',
  iddUnavailable: 'IDD unavailable',
  emailUnavailable: 'Email unavailable',
  contentTermLicense1:
    'This Agreement shall be governed by the laws of Singapore, without regard to the conflict of laws principles. ',
  contentTermLicense2:
    'the parties agree that the dispute shall be settled by arbitration in Singapore under the Singapore International Arbitration Centre (“SIAC”) administered SIAC Arbitration Rules in force when the notice of arbitration is submitted in accordance with these rules. The number of arbitrators shall be one (1). The language of the arbitration shall be English.',
  contentTermLicense3:
    'that damages and remedies under Singapore Law for any breach of any such covenant would be inadequate. You further acknowledge and agree that the arbitral tribunal may order any interim measure it deems necessary or proper in accordance with the applicable laws of Singapore,',
  contentTermUsLicense1:
    'This Agreement shall be governed by the laws of the state of Delaware, United States of America, without regard to the conflict of laws principles',
  contentTermLicenseUs2:
    ' the parties agree first to try in good faith to settle the dispute by mediation administered by the American Arbitration Association under its Commercial Mediation Procedures before resorting to arbitration, litigations or some other dispute resolution procedure. In the event if the dispute is settled via arbitration, the number of arbitrators shall be one (1) and the language of the arbitration shall be English.',
  contentTermUsLisence3:
    ' that damages and remedies under applicable law for any breach of any such covenant would be inadequate. You further acknowledge and agree that the arbitral tribunal may order any interim measure it deems necessary or proper in accordance with the applicable law,',
  contentCorporateUs:
    'This Agreement shall be governed by the laws of the State of Delaware in the United States of America, without regard to the conflict of laws principles. All disputes arising out of or in connection with the performance of this Agreement shall be settled through negotiations and if the dispute cannot be settled through negotiation, the parties agree first to try in good faith to settle the dispute by mediation administered by the American Arbitration under its Commercial Mediation Procedures before resorting to arbitration, litigation, or some other dispute resolution procedure. In the event if the dispute is settled via arbitration, the number of arbitrators shall be one (1), the language of the arbitration shall be English and the arbitral award shall be final and binding upon parties.',
  contentCorporate:
    'This Agreement shall be governed by, and shall be construed in accordance with, the laws of Singapore. All disputes arising out of or in connection with the performance of this Agreement shall be settled through friendly negotiations. If the parties are unable to resolve any such dispute within thirty (30) days after the commencement of negotiations, the parties agree that the dispute shall be settled by arbitration in Singapore under the Singapore International Arbitration Centre (“<b>SIAC</b>”) administered by SIAC Arbitration Rules in force when the notice of arbitration is submitted in accordance with the rules. The number of arbitrators shall be one (1). The decision of the arbitrator shall, except in the case of manifest error, be final and binding upon the parties hereto. The arbitration proceedings shall be conducted in English.',
  agreeLicense: 'I AGREE to abide by all the Terms and Conditions above',
  btnAgree: 'AGREE',
  btnDecline: 'DECLINE',
  messageCheckbox:
    'Please read the License Agreement above carefully and signify your agreement by clicking the "I AGREE" check box.',
  // collection search result page
  searchCollection: 'Search collections',
  personal: 'Personal',
  groups: 'Csoportok',
  createNew: 'Create new',
  createNewCollection: 'Create a new collection',
  descriptionPlaceholder: 'Add an optional description for your collection here.',
  titleItem: 'TITLE',
  selectGroup: 'SELECT GROUP',
  description: 'DESCRIPTION',
  charactersRemaining: '180 characters remaining',
  cancel: 'Cancel',
  create: 'Create',
  defaultCollection: 'Default Collection',
  addToMyCollections: 'Add to my collections',
  addedToCollection: 'Added to collection',
  removedFromCollection: 'Removed from collection',
  collectionModalPlaceholder: 'e.g. Nature',
  groupNameShowsHere: 'Group name shows here',
  // like box
  shareWithMe: 'SHARED WITH ME',
  addLikebox: 'Add New Likebox',
  notImageLikeBox: 'You do not have any images in your likebox yet',
  owner: 'Owner',
  images: 'images',
  likeboxName: 'Likebox Name',
  likboxNameMaxLength50: 'The maximum characters allowed is 50',
  addLikeBoxTo: 'Add likebox to',
  personalLikebox: 'személyes adatok',
  btAdd: 'ADD',
  btCancel: 'CANCEL',
  btSave: 'SAVE',
  editLikebox: 'Edit Likebox',
  // reset password
  resetPwdTitle2: 'Reset Password',
  noFoundLikebox: "You don't have any collections.",
  btnUnDo: 'UNDO',
  // my collection
  btnViewCollection: 'View Collection',
  dateAdded: 'Date Added',
  collectionName: 'Collection Name',
  selectAll: 'Select all',
  delete: 'Delete',
  seeAll: 'See all',
  deleteCollection: 'Delete Collection',
  deleteWarning:
    'Completing this action will permanently delete your Collection along with its contents. Be aware that this action cannot be undone.',
  createCollection: ' has been created',
  shareCollection: 'Share Collection',
  shareWithTeam: 'Share in team',
  selectUsers: 'Select users',
  searchUserName: 'Search a users name',
  copy: 'Copy',
  titleCopyModal: 'Copy Selected Items to',
  collectionUppercase: 'COLLECTION',
  groupCollection: 'Group',
  moveImage: 'Move Selected Items to',
  defaultSelect: 'Collection Name Here',
  moveLikebox: 'Move',
  messsageDeleteLikebox:
    'Completing this action will permanently delete your Collection(s) along with its contents. Be aware that this action cannot be undone.',
  areYouSure: 'Are you sure?',
  editCollection: 'Edit collection',
  collectionNameUpper: 'COLLECTION NAME',
  deleteSuccessful: 'has been successfully deleted',
  edit: 'Edit',
  messageRemoveImage: 'Are you sure you want to remove the selected the item from the collection?',
  titleRemove: 'Remove Selected Item',
  popupActionMove: ' item(s) has been moved to ',
  popupActionCopy: ' item(s)has been copied to ',
  popupActionRemove: ' item(s) has been removed from this Collection',
  popupFailed: 'Image already exists',
  popupShared: 'Your collection has been successfully shared',
  popupActionDelete: 'Your collection has been successfully removed',
  popupActionEdit: 'Your edit has been successfully updated',
  btnSave: 'Save',
  shareLikebox: 'Shared with me',
  remove: 'Remove',
  moveTo: 'Move to',
  shareViaEmail: 'Share via Email',
  sharePublicly: 'Share publicly',
  receipnetEmail: "Recipient's email",
  message: 'Message',
  insertReceipmentEmail: 'Insert receipment email',
  messageText: 'Message text.',
  exportCollection: 'Export collection',
  pdf: 'PDF',
  csv: 'CSV',
  format: 'FORMAT',
  lowCredit: 'low credit',
  downloadSuccess: 'Köszönjük a letöltést. A letöltés hamarosan elindul.',
  downloadFailed: 'Letöltési hiba: Hiba lépett fel, kérjük próbálja újra.',
  downloadedLabel: 'Downloaded',
  any: 'Any',
  resetFilter: 'Reset filter',
  enterCustomFieldTag: 'Enter custom field tags',
  // Portfolio
  follow: 'Follow',
  name: 'Name',
  location: 'Location',
  since: 'Since',

  // my activities
  recentLike: 'My Recent Likes',
  recentView: 'My Recent Views',
  myActivity: 'My activities',
  myActivityViewDesc: 'Recent view history will only store up to 3 months only',
  myActivityLikeDesc: 'Only showing history in the recent 3 months',

  // My account
  updated: 'Update',
  myProfile: 'My Profile',
  userName: 'user name',
  firstName: 'first name',
  lastName: 'last name',
  changeProfiledetail: 'Change your profile details here',
  userPrivileges: 'User Privileges',
  viewAllDownload: 'View all download history',
  viewDownloadCost: 'View download cost',
  accountManagerInfo: 'Account manager info',
  describeAccountmanager: 'Describe what this account manager do',
  creditsPlan: 'Credits plan',
  myPlan: 'My Plan',
  myOrganisation: 'My organisation plan',
  myAccountProfileDetails:
    'This are your profile details set by the organisation admin. If you wish to change your credentials, kindly contact admin.',
  usePayLater: 'Use Now, Pay Later',
  bodyUNPL:
    'Use Now, Pay Later (UNPL) is a type of short-term financing that allows consumers to make purchases and pay for them at a future date, often interest-free. Also referred to as "point of sale installment loans.',
  firstnameCannotEmpty: 'First name cannot be empty.',
  lastnameCannotEmpty: 'Lastname cannot be empty.',

  noSpecialCharacters: 'This field cannot contain special characters',
  messageUpdateProfile: 'You had successfully updated the user information',
  bodyCredit:
    'Vitae morbi tristique luctus sed eget donec nibh in duis neque donec lectus turpis turpis aliquam facilisis mattis laoreet enim',
  contactYourAccount: 'Contact your account manager for further assistane',
  emailMaxLength75: 'The maximum characters allowed is 75',

  // audio player
  categoryGenre: 'Category / Genre',
  duration: 'Duration',
  seeMore: 'See more',
  seeLess: 'See less',

  // Download-history modal
  reDownload: 'Re-download',
  chooseCustomTagOrCreate: 'Choose custom tag(s) or create a new one',
  downloadDetails: 'Download details',
  image: 'Image',
  downloadType: 'Download type',
  groupName: 'Group name',
  size: 'Size',
  dateDownloaded: 'Date downloaded',
  reLicense: 'Re-license',
  customFields: 'Custom fields',
  saveAndClose: 'Save and close',
  characterMaxChar100: 'The maximum characters allowed is 100',
  characterNotAllow:
    'The maximum characters allowed is 100 and the minimum characters allowed is 1',
  dlwByMember: 'Dowloaded By Members',
  groupInactive: 'Group inactive',
  personalInactive: 'Personal inactive',
  date: 'date',
  export: 'Export',
  selectRange: 'select range',
  fileType: 'file type',
  downloadedLabelUNPL: 'Licensed',
  or: 'OR',
  // Details pages
  audioDetail: 'Audio Detail',
  imageDetail: 'Image Detail',
  footageProperties: 'Footage properties',
  audioProperties: 'Audio properties',
  modalDownloadTitle: 'File download options',
  credit: 'Credit',
  saveAs: 'Save as',
  ownLicense: 'Normal download (own license)',
  downloadAs: 'Download as',
  insufficientCredits: 'Insufficient Credits',
  footageDetail: 'Footage Detail',
  custom: 'custom',
  personalAndCom: 'Personal and Commercial Use Ads, presentations, social media, web.',
  requiredForResale:
    'Required for resale or distribution in Theatrical performance, paid-access, streaming video, games and apps.',
  share: 'Share',
  stockAudioKeywords: 'Stock audio keywords',
  stockFootageKeywords: 'Stock footage keywords',
  stockPhotoKeywords: 'Stock photo keywords',
  limitedLicenseSizes: 'Limited License Sizes',
  stereo: 'Stereo',
  fileSize: 'File Sizes',
  footageId: 'Footage ID',
  audioId: 'Audio ID',
  imageId: 'Image ID',
  licenseType: 'License type',
  imageUsage: 'Image Usage',
  normalDl: 'Normal download (Own License)',
  HRUCTab: 'Hi-res comp',
  searchById: 'Search by ID',
  pleaseSelect: 'Please Select',
  totalOutstanding: 'Total Outstanding',
  viewInvoices: 'View invoices',
  // Image detail page - select download size sidebar
  standardSizes: 'Standard sizes',
  extendedLicenseSizes: 'Extended License Sizes',
  crop: 'Crop',
  // empty data
  opsNothingToSeeHere: 'Hoppá! Nincs itt semmi látnivaló.',
  // search audio
  mostDownloaded: 'Legtöbbet letöltött',
  audioID: 'Audio-ID',
  // category
  landscape: 'Tájkép',
  business: 'Üzleti élet',
  food: 'Ételek',
  animals: 'Állatok',
  technology: 'Technológia',
  healthcare: 'Egészségügy',
  nature: 'Természet',
  romance: 'Romantika',
  // category vector
  graphic: 'Grafika',
  advertising: 'Hirdetés',
  flower: 'Virág',
  abstract: 'Absztrakt',
  Illustration: 'Illusztráció',
  pop: 'Pop',
  bird: 'Madár',
  pixel: 'Pixel',
  threeD: '3D',

  // footage category
  education: 'Education',
  communication: 'Közlés',
  beauty: 'Szépség',
  concept: 'Koncepció',
  fitness: 'Alkalmasság',
  lifestyle: 'Életmód',
  celebration: 'Ünneplés',
  family: 'Család',
  fashion: 'Divat',

  // audio category
  soulAndRB: 'Soul & R&B',
  corporate: 'Vállalati',
  electronica: 'Electronica',
  classical: 'Klasszikus',
  funkAndGrooveMusic: 'Funk & Groove',
  worldBeat: 'Világzene',
  cinematic: 'Filmzene',
  jazz: 'Jazz',
  flamenco: 'Flamenco',
  mothersDayLove: 'Mothers Day Love',
  happyUplifting: 'Happy Uplifting',
  epicHeroAdventure: 'Epic Hero Adventure',

  // search page
  nextPage: 'Next Page',
  reverseImageSearch: 'Reverse Image Search',
  copyPortfolioLinkSuccess: 'A Clipboard -ra másolt közreműködő portfólió link.',

  // Pricing
  pricingCreditText: 'credit',

  // Dashboard - Credit Usage
  user: 'User',
  searchPlaceholder: 'Search name, username, role, group',
  all: 'All',
  active: 'Active',
  deleted: 'Deleted',
  reset: 'Reset',
  totalAllocated: 'Total allocated',
  totalSpent: 'Total spent',
  balance: 'Balance',
  groupLowerCase: 'group',
  roleDefinitions: 'Role definitions',
  superAdmin: 'Super admin',
  admin: 'Admin',
  historySearchPlaceholder: 'Search history',
  somethingWentWrong: 'Something went wrong',
  information: 'Information',
  maximumBulkDownloadWarning: 'Bulk download allows maximum 50 items',
  tooltipCustomLogin: 'Please use at least 8 characters',
  maxLengthTitleCustom: 'Title should not be more than 25 characters',
  maxLengthDescCustom: 'Description should not be more than 50 characters',
  selectdItems: 'Selected media',
  aiPoweredSearch: 'AI alapú keresés',
  aiPoweredSearchOn: 'Az AI Powered Search elindult',
  aiSearchIntroduction:
    'Próbálja ki gyorsabb és hatékonyabb keresőmotorunkat. A többiről AI Search gondoskodik!',
  ok: 'OKÉ',
  // Filter bar ai
  bstract: 'Absztrakt',
  macroCloseUp: 'Makró/nagy felbontás',
  object: 'Objektum',
  watercolor: 'Akvarell',
  flat: 'Flat',
  cartoon: 'Rajzfilm',
  geometric: 'Geometrikus',
  gradient: 'Gradiens',
  isometric: 'Izometrikus',
  '3d': '3d',
  handDrawn: 'Kézzel rajzolt',
  natural: 'Természetes',
  warm: 'Meleg',
  dramatic: 'Drámai',
  vividAndBold: 'Élénk és merész',
  blackAndWhite: 'Fekete/fehér',
  // introduce Free image research
  freeImageResearch: 'Free image research',
  newService: 'New service!',
  freeImageResearchIntroduction:
    'Try our image research service and see why our images match your requirements perfectly.',
  later: 'Later',
  letTryIt: 'Let’s try it!',
  characterMin30: 'A minimum of 30 characters is required',
  required: 'is required',

  requiredWithVariable: '{{var}} is required',

  // image research page
  imageResearchContentTitle:
    "Introducing 123RF's free image research service: Perfect images, Zero hassle",
  imageResearchContentDesc:
    ' We revolutionize image research for corporate customers, making it effortless to find the perfect image and bring your vision to life.',
  submitRequest: 'Submit request',
  viewPdfOfSampleOutput: 'View PDF of sample output',
  imageResearchContentInfoTitle1: 'Image research made simple',
  imageResearchContentInfoDesc1:
    'Getting started is easy. Just provide us with a detailed description of your desired image, including style, mood, colors, and specific elements. The more details you give, the better we can match your needs.',
  imageResearchContentInfoTitle2: 'How does it work?',
  imageResearchContentInfoDesc2Normal1: 'Our team combines',
  imageResearchContentInfoDesc2Strong1: 'AI technology',
  imageResearchContentInfoDesc2Normal2:
    'and experience to find ideal images for you and advanced algorithms narrow down options from our vast library based on your input. Our',
  imageResearchContentInfoDesc2Strong2: 'team of dedicated and experienced researchers',
  imageResearchContentInfoDesc2Normal3: 'refine the results to meet your criteria perfectly.',
  imageResearchContentInfoTitle3: 'Fast, reliable service',
  imageResearchContentInfoDesc3Normal1: 'We deliver results within',
  imageResearchContentInfoDesc3Strong1: '1 working day',
  imageResearchContentInfoDesc3Normal2:
    'providing a comprehensive explanation for image selections. Focus on your business while we take care of the rest.',
  imageResearchContentInfoTitle4: 'Let us do the work for you',
  imageResearchContentInfoDesc4:
    'We find the perfect images for you, so you can focus on your project. Trust us to bring your vision to life with high-quality, carefully chosen images. Start now!',
  requestForm: 'Request form',
  whatDoYouWantThisImageFor: 'What do you want to use this image for?',
  websiteBanner: 'Website banner',
  socialMedia: 'Social media',
  blogPost: 'Blog post',
  advertisement: 'Advertisement',
  print: 'Print',
  others: 'Others',
  othersOutputPlaceholder: 'Enter other types of output',
  whatIsPrimaryMessage: 'What is the primary message or theme you want the image to convey?',
  whatIsPrimaryMessagePlaceholder: 'Eg. Dog with fur running with woman in the park',
  areThereSubjectYouWantInThePicture:
    'Are there specific subjects or elements you want to be included in the image?',
  egPeopleNatureTechnologyAbstract: 'E.g. people, nature, technology, abstract concepts',
  areThereImageOrStyleReference:
    "Are there any images or styles you've seen before that you'd like us to use as inspiration or reference?",
  ifPeopleIncludeWhatTheirDemographic:
    'If people are to be included, what should their demographic be?',
  egAgeGenderEthnicityOccupation: 'E.g. age, gender, ethnicity, occupation',
  areThereAnyThingToAvoid:
    'Are there any elements, subjects, or themes you want to avoid in the image?',
  whoisYourTargetAudience: 'Who is your target audience?',
  whoisYourTargetAudiencePlaceholder: 'Eg. Young adults who love to read',
  typeOfImage: 'Type of image',
  whatMoodShouldImageEvoke: 'What mood or emotion should the image evoke?',
  happy: 'Happy',
  sad: 'Sad',
  calm: 'Calm',
  excited: 'Excited',
  angry: 'Angry',
  enterCustomMood: 'Enter custom mood',
  areThereAnyOrientationForImage: 'Are there any specific orientations needed for the image?',
  enterCustomOrientation: 'Enter custom orientation',
  isThereLocationYouWantImageToBeIn:
    'Is there a particular location or setting you want the image to be in?',
  no: 'No',
  yes: 'Yes',
  office: 'Office',
  beach: 'Beach',
  city: 'City',
  mountains: 'Mountains',
  enterCustomLocation: 'Enter custom location',
  doYouNeedImgWithSpaceForTextOrOtherElement:
    'Do you need an image with space for text or other elements? If so, where should this space be located?',
  center: 'Center',
  topLeft: 'Top left',
  topRight: 'Top Right',
  bottomLeft: 'Bottom Left',
  bottomRight: 'Bottom Right',
  shareWithUsAReference: 'Share with us a reference (JPG or PDF only. Max 4MB)',
  areThereAnySpecificRequirements: 'Are there any other specific requirements or preferences?',
  enterOtherComments: 'Enter other comments',
  submit: 'Submit',
  replace: 'Replace',
  fileMustBeJpgOrPdf: 'File must be either a JPG or a PDF',
  maximumFileNameAllow255: 'The maximum file name allowed is 255',
  fileSizeLessThan4MB: 'File size must be less than 4MB',
  fieldCannotEmpty: 'This field cannot be empty.',
  imageResearchContentInfoDesc2: (
    <>
      Our team combines
      {' '}
      <strong>AI technology</strong>
      {' '}
      and experience to find ideal images for you
      and advanced algorithms narrow down options from our vast library based on your input. Our
      <strong>team of dedicated and experienced researchers</strong>
      {' '}
      refine the results to meet your
      criteria perfectly.
    </>
  ),
  imageResearchContentInfoDesc3: (
    <>
      We deliver results within
      {' '}
      <strong>2 working days</strong>
      , providing a comprehensive
      explanation for image selections. Focus on your business while we take care of the rest.
    </>
  ),
  characterMaxChar255: 'The maximum characters allowed is 255',
  mood: 'Mood',
  person: 'Person',
  doNotIncludeWords_ex2: 'Eg. Green colour, straight hair',

  // Text to Image TTI page
  /// Landing page
  aiImageGenerator: 'AI Image Generator',
  aiImageGeneratorDesc: 'Delivering creativity at the speed of your imagination.',
  generate: 'Generate',
  exploreVisualsCreatedWithThePowerOfAI: 'Explore visuals created with the power of AI',
  turnYourImaginationIntoRealityIn3SimpleSteps:
    'Turn your imagination into reality in 3 simple steps',
  startWithGoodTextPrompt: (
    <>
      Start with a
      {' '}
      <span>
        good
        <br />
        text prompt
      </span>
    </>
  ),
  startWithGoodTextPromptDesc:
    'A prompt should include perspective, subject, action, keywords, lighting and style for the best results.',
  reviewYourAIGeneratedImages: (
    <>
      Review your
      {' '}
      <br />
      <span>AI-generated images</span>
    </>
  ),
  reviewYourAIGeneratedImagesDesc:
    'Our AI generator will generate and display 3 preview images that most closely matches your prompt.',
  downloadAndLicenseVisuals: (
    <>
      Download and
      {' '}
      <br />
      <span>license visuals</span>
    </>
  ),
  downloadAndLicenseVisualsDesc:
    'You can now download and license these images for personal and commercial use with $25,000 legal coverage.',
  needSomeIdeas: 'Need some ideas? Start from these samples and regenerate your ideas!',
  tryPrompt: 'Try prompt',
  creativityAtTheSpeedOfYourImagination: 'Creativity at the speed of your imagination',
  genYourOwnAIImgFromTxtPromptsWCorpInSeconds:
    'Generate your very own AI images from text prompts with Corporate+ in seconds!',
  tryNow: 'Try now',

  /// Random prompt
  prompt1:
    'Photo-realistic portrait of an astronaut floating in space with the Earth in the background. Use camera with a 200mm lens at F 1.2 aperture setting to blur the background and isolate the subject. The lighting should be dramatic and dreamlike with the sun shining on the astronaut’s face and spacesuit. Focus on photorealism mode turned on to create an ultra-realistic image that captures the vastness and beauty of space.',
  prompt2:
    'A mixed media piece featuring a spellcaster in a dramatic pose, with a flowing cape and a wand emitting sparks of magic, set against a mystical and otherworldly landscape, with a variety of textures and materials',
  prompt3:
    'A playful digital illustration of a baby with rosy cheeks and a fluffy onesie, surrounded by colorful balloons and confetti, against a cheerful and pastel-colored background',
  prompt4:
    'A graphite drawing of a blind seer with a third eye on their forehead, gazing into the distance with a serene and mystical expression, with intricate details and shading',
  prompt5:
    'A surrealistic mixed media collage of a cowboy riding a giant mechanical bull in a desert wasteland, made of vintage magazines, old maps, and rusty metal scraps, with a touch of humor and absurdity',
  prompt6:
    'A digital illustration of a vampire queen with pale skin and blood red eyes, wearing a flowing dress and surrounded by bats.',
  prompt7:
    'A portrait of a blind seer holding a crystal ball, captured in dramatic chiaroscuro lighting to accentuate the seer’s facial features and the crystal’s reflections.',
  prompt8:
    'A fantasy illustration of a mermaid queen with long hair and a crown of seashells, swimming in a mystical ocean filled with bioluminescent creatures.',
  prompt9:
    'A street portrait of a Hispanic street vendor selling fruits and vegetables, captured in candid and gritty black and white, with a shallow depth of field to emphasize the vendor’s facial expression.',
  prompt10:
    'A whimsical watercolor painting of a mermaid lounging on a rocky shore, surrounded by sparkling ocean waves and playful sea creatures.',
  prompt11:
    '3d style little girl, 4k, 8k, octane render photorealistic, hdr, photography, high definition, symmetrical face, volumetric lighting, dusty haze, photo, octane render, 24mm, 4k, 24mm, DSLR, high quality, 60 fps, ultra realistic.',
  prompt12:
    'Living room with French windows opening onto the garden, Provençal style sideboard, plants, jute carpet, parquet, sofas with cushions on top, crystal drop chandelier, interior design magazine, warm atmosphere, photorealistic, realistic light, wide angle, many details.',
  prompt13:
    'Picturesque mountain hut on mountain slope, large panoramic windows, geometric hut, full view, scenic view at twilight, dramatic twilight sky, peaks under snow, stars, snowy mountainous wilderness, beautiful view, moody, fog, volumetric lighting, mountain atmosphere, photorealism, volumetric lighting, realistic, octane render, ultra detailed, surrealistic, photorealistic, ultrarealistic, 8k, cinematic lighting.',
  prompt14: 'Watercolor painting of a cool grandma, she is smiling because she knows she’s cool.',
  prompt15:
    "Road in a middle of a deep blue forest, dense forest, leaf ceiling, by night, comic book style, children's illustration.",
  prompt16:
    'An astronaut swimming through a nebula in space + space, astronaut, dreamlike, symbolism, surrealism, symbol, surreal, abstract, texture, concept art, 8k, shadowed, lightrays, atmospheric.',

  /// Tool list
  // ratio
  ratio: 'Ratio',
  square1_1: 'Square (1:1)',
  square: 'Square',
  portrait2_3: 'Portrait (2:3)',
  portrait: 'Portrait',
  landscape3_2: 'Landscape (3:2)',
  wide16_9: 'Wide (16:9)',
  wide: 'Wide',
  // style
  none: 'None',
  enhance: 'Enhance',
  anime: 'Anime',
  photographic: 'Photographic',
  digitalArt: 'Digital art',
  comicBook: 'Comic book',
  fantasyArt: 'Fantasy art',
  analogFilm: 'Analog film',
  neonPunk: 'Neon punk',
  lowPoly: 'Low poly',
  origami: 'Origami',
  lineArt: 'Line art',
  tileTexture: 'Tile texture',
  threeDModel: '3D model',
  pixelArt: 'Pixel art',
  craftClay: 'Craft clay',
  // color
  coolTone: 'Cool tone',
  mutedColors: 'Muted colors',
  pastelColors: 'Pastel colors',
  vibrantColors: 'Vibrant colors',
  warmTone: 'Warm tone',
  // lighting
  lighting: 'Lighting',
  backlight: 'Backlight',
  crepuscularRays: 'Crepuscular rays',
  dimlyLit: 'Dimly lit',
  goldenHour: 'Golden hour',
  lowLight: 'Low light',
  rimLighting: 'Rim lighting',
  studio: 'Studio',
  sunlight: 'Sunlight',
  volumetric: 'Volumetric',
  // composition
  composition: 'Composition',
  blurryBackground: 'Blurry background',
  closeUp: 'Close up',
  macrophotograph: 'Macrophotography',
  narrowDepth: 'Narrow depth',
  shotFromAbove: 'Shot from above',
  shotFromBelow: 'Shot from below',
  wideAngle: 'Wide angle',

  /// FQAs
  allAIImageQuestionsAnswered: 'All your AI-generated image questions answered.',
  TTI_Q1: 'What are AI-generated images?',
  TTI_A1:
    'AI-generated images are images created using artificial intelligence (AI) algorithms. These algorithms are designed to simulate human creativity and can generate images similar to those produced by human artists but with unique and often surreal or abstract elements.',
  TTI_Q2: 'Is there a limit to how many images I can generate per day?',
  TTI_A2:
    'Yes, there is a limit to the number of images that you are able to generate daily. The limit ensures that all users would have fair access and a good user experience when accessing the AI image generation service.',
  TTI_Q3: 'Do you support text prompts in languages other than English?',
  TTI_A3: 'We support only the English language at the moment.',
  TTI_Q4: 'What is the difference between generating and licensing an image?',
  TTI_A4:
    'When you generate an image, you create a set of AI images to view. When you license them on Corporate+, you are officially granted access to download a high-res version of the AI image that comes with its own usage license.',
  TTI_Q5:
    'An image I generated contains a recognizable trademark, landmark, or public personality. Can I still use it?',
  TTI_A5:
    'If it contains a recognizable person or object, or it primarily focuses on a distinctive landmark, you would have to secure permission directly from the party depicted in the image, especially when you intend to use the image in a commercial setting.',
  TTI_Q6: 'Who are the people that appear in generated images?',
  TTI_A6:
    'The people that appear in AI-generated images are not real people but rather digital creations.',
  TTI_Q7:
    'What happens with the content that I generated? Will they be made available to other users?',
  TTI_A7:
    'The content that you generate may be made available to other users as well. You may refer to our license for more information.',
  TTI_Q8: 'Why does it take a while to load when I try generating an image?',
  TTI_A8:
    'The page will take a few seconds to generate the image. The time taken depends on the complexity of the prompt, the hardware and computing resources available, as well as the amount of request that the AI has to handle simultaneously.',
  TTI_Q9: 'How do I license the AI images I generated?',
  TTI_A9:
    'You will be able to license the AI images that you have created by downloading them from your Corporate+ account. With every download you will receive the high-resolution version of the generated image available and a $25,000 legal guarantee.',
  TTI_Q10: 'Can I view past generations?',
  TTI_A10:
    'Yes, you can do so by bookmarking and revisting the URL of your past generations. You may also share the results of the generations with your friends.',
  TTI_Q11: 'How do I generate high-quality images with AI?',
  TTI_A11: `1. Start with a clear objective: "High-quality image of a mountain landscape for a travel blog."

2. Specify the desired style and mood: "The image should be bright and vibrant with a natural, organic feel."

3. Describe the subject matter: Be specific about the subject matter of the image, including details such as the setting, lighting, and any other important features or elements. For example, "Feature a snow-capped mountain peak with a winding river in the foreground and a clear blue sky in the background."

4. Provide examples: Include examples of other images that have a similar style or mood to the one you are looking for. This can help guide the AI algorithm and ensure that the output meets your expectations.

5. Use relevant keywords: "mountain landscape," "snow-capped peak," or "bright and vibrant."

6. Avoid ambiguity: Be as clear and specific as possible when providing instructions, and avoid using vague or ambiguous language that could lead to unexpected results.`,

  /// Generating page
  bringingYourImagesToLife: 'Bringing your images to life...',
  cplusAiGenerator: 'AI Generator',
  generatedImages: 'Generated images',
  promptSettings: 'Prompt settings',
  pastGeneration: 'Past generations',
  generateVariation: 'Generate variation',
  iLikeThis: 'I like this',
  iDislikeThis: 'I dislike this',
  shareOptions: 'Share options',
  variations: 'Variations',
  copiedSharedUrl: 'Copied shared URL to clipboard.',

  galleryPrompt1: 'Éteri szemérmes sellő, víz alatt, kagyló korona halakkal körülvéve, portré',
  galleryPrompt2: 'kiadós vasárnapi vacsora az asztalon, tele étellel és gyertyákkal',
  galleryPrompt3: 'Aranyos bolyhos róka mosolyog egy erdőben, nagyon részletes, nagyított más erdei állatokkal együtt',
  galleryPrompt4: 'kiváló minőségű, 8K Ultra HD, hangjegyek hangszer formák egy kristályból készült tök belsejében, nagy részletességű, varázslatos töklámpa, őszi hangulat, nyugalom',
  galleryPrompt5: 'Skócia panoráma filmes táj, ragyogó szép nap',
  galleryPrompt6: '3d stílus kislány, 4k, 8k, oktán render fotorealisztikus, hdr, fotózás, nagy felbontás, szimmetrikus arc, volumetrikus világítás, poros köd, fotó, oktán render, 24mm, 4k, 24mm, DSLR, kiváló minőségű, 60 fps, ultra realisztikus',
  galleryPrompt7: 'barna bőrű srác, hős ütés bolygó Jupiter az űrben, előre néz',
  galleryPrompt8: 'süti a meleg kávéban, karácsonyi környezetben',
  galleryPrompt9: 'Művészi vizes palack fehér Minimalista háttér Montserra levelekkel',
  galleryPrompt10: 'Evanescent gyémánt fantasy sárkány',
  galleryPrompt11: 'bolygók harmatcseppekben, bolygó harmatcseppek, napfelkeltekor, közelkép, hiper részletes, éles fókusz, stúdiófotó, bonyolult részletek, rendkívül részletesek.',
  galleryPrompt12: 'egy szarka ül egy láda aranyon, a stílus a realisztikus fantasy artwork, drágakő, sötét tündérmesék, titokzatos',
  galleryPrompt13: 'egy hegy a hó és egy nyomvonal a jég a hegy tetején a hegy megy a másik irányba, ultra hd, realisztikus, élénk színek, rendkívül részletes, UHD rajz, toll és tinta, tökéletes kompozíció, gyönyörű részletes bonyolult őrülten részletes oktán render trend artstation, 8k művészi fotózás, fotórealisztikus koncept art, lágy természetes volumetrikus filmes tökéletes fény',
  galleryPrompt14: 'Csillagközi civilizáció korszak, állt magasan lefelé kilátással a titokzatos digitális tér szuper sci-fi idegen város épületek, gyönyörű gyönyörű külső szuper felhőkarcoló torony épület, utca, ég híd, ég lebegő szuper sci-fi építészet, hadigép, high-tech, számtalan elektronikus képernyőfény, szuper sci-fi vizuális ünnep űr, sci-fi, rejtély, sci-fi, szürreális, szupertiszta képek, HD pixel, ultra-HD, 5D, 8K felbontás, pixel tökéletes, tökéletes ultra finom részlet, éles fókusz, stúdiófotó, bonyolult részletek, rendkívül részletesek.',
  galleryPrompt15: 'Gyönyörű strand az óceánnal a háttérben a Florida Keys-ben éjszaka, neon színek, fekete háttér, nyugodt hullámok, természetes árnyalat, naplemente, nyugodt, pihentető zoom a hullámokban, strand, kiegyenesített horizont, rózsaszín, kék, narancssárga, lila',
  galleryPrompt16: 'legjobb minőség, dupla expozíció, mail art, művészet egy repedezett papíron, egy közeli aranyos cica játszik egy gyapjúgombolyaggal részletes hangulatos szobabelső, csillogó, napfény, napsugarak, dinamikus póz, mesekönyv illusztráció, 2d, lapos, aranyos, imádnivaló, vintage, mese, patchwork, ólomüveg, mesekönyv részletes illusztráció, filmszerű, ultra rendkívül részletes, apró részletek, gyönyörű részletek, misztikus, élénk színek, összetett háttér',

  galleryPrompt17:
    'pyramid on the water with fire reflections, mountains, dark surrealism, atmospheric portraits, sharp focus',
  galleryPrompt18: 'red panda in the forest, cinematic',
  galleryPrompt19: 'sea, moon, fish, starry sky, boat, colorful illustration',
  galleryPrompt20:
    'Vibrant urban reflections dance admist bold buildings and shimmering water in minimalist abstract style, capturing the lively evening energy, geometric abstraction, contemporary pop art style with neon lighting and acrylic puring technique',
  galleryPrompt21:
    'back view of a silhouette woman with glowing sword, circular portal in the arctic leading towards to icebergs, cinematic medium shot, monumental figure',

  /// error page
  copyrightDetected: 'Copyrighted terms / personalities or trademarks detected',
  copyrightDetectedDesc:
    'We are committed to being a responsible AI generator and will not generate results for copyrighted trademarks, terms, brands or personalities included in prompts to prevent potential infringement. Our Terms of Service stipulates that you shall NOT use the services in a way that infringes, misappropriates or violates any intellectual property rights. Kindly amend your prompt and try again.',
  errorMesTTI: 'Oops! We’re sorry, but something went wrong.',
  errorMesTTIDesc: 'The issue has been logged for investigation. Please try again later.',
  tryAgain: 'Try again',
  // hruc bulk download
  hrucBulkDownloadTitle: 'HRUC bulk download',
  hrucDownload: 'HRUC download',
  hrucBulkdownloadNotSupported: 'HRUC bulk download is not supported',
  // download feature
  downloadQuotaFinished: 'The download quota is finished',

  // search bar placeholder
  searchBarPlaceholderAllImageDesktop: 'Keresse meg a lenyűgöző képek. Keresés kulcsszavak vagy kifejezések alapján.',
  searchBarPlaceholderAllImageTablet: 'Elképesztő képek keresése.',
  searchBarPlaceholderAllImageMobile: 'Keressen képek.',
  searchBarPlaceholderPhotoDesktop: 'Keresse meg a lenyűgöző fotók. Keresés kulcsszavak vagy kifejezések alapján.',
  searchBarPlaceholderPhotoTablet: 'Elképesztő fotók keresése.',
  searchBarPlaceholderPhotoMobile: 'Keressen fotók.',
  searchBarPlaceholderVectorDesktop: 'Keresse meg a lenyűgöző vektorok. Keresés kulcsszavak vagy kifejezések alapján.',
  searchBarPlaceholderVectorTablet: 'Elképesztő vektorok keresése.',
  searchBarPlaceholderVectorMobile: 'Keressen vektorok.',
  searchBarPlaceholderFootageDesktop: 'Keresse meg a lenyűgöző videó. Keresés kulcsszavak vagy kifejezések alapján.',
  searchBarPlaceholderFootageTablet: 'Elképesztő videó keresése.',
  searchBarPlaceholderFootageMobile: 'Keressen videó.',
  searchBarPlaceholderAudioDesktop:
    'Keresse meg a lenyűgöző audio és zenefájlok. Keresés kulcsszavak vagy kifejezések alapján.',
  searchBarPlaceholderAudioTablet: 'Elképesztő audio és zenefájlok keresése.',
  searchBarPlaceholderAudioMobile: 'Keressen audio és zenefájlok.',
  searchBarPlaceholderFreeDesktop: 'Keresse meg a lenyűgöző ingyenes képek. Keresés kulcsszavak vagy kifejezések alapján.',
  searchBarPlaceholderFreeTablet: 'Elképesztő ingyenes képek keresése.',
  searchBarPlaceholderFreeMobile: 'Keressen ingyenes képek.',
  // editorial warning
  forEditorialUseOnly: 'For Editorial Use Only',
  forEditorialUseOnlyDesc:
    'Editorial Use Only: This image can only be used for editorial purpose. Use of this image in advertising, commercial or for promotional purposes is prohibited unless additional clearances are secured by the license.',

  // download comp
  downloadHiResComp: 'Download hi-res comp',
  downloadComp: 'Download comp',

  generateTerm: ({ callback }) => (
    <>
      By clicking “Generate”, you hereby agree that the generated Output is solely governed by the
      {' '}
      <span aria-hidden onClick={callback}>
        AI Generator Terms of Service.
      </span>
      {' '}
    </>
  ),

  textToImage: 'Text to image',
  descTourTTI: 'Let your imagination fly and create images with text!',
  colorAndTone: 'Color and tone',
  adultContentTitle: 'Potential adult-content terms detected',
  adultContentDesc:
    'We are committed to responsible AI and therefore do not generate results for certain phrases, concepts and/or ideas. Please amend your prompt (the description you provided to generate the image) and try again.',
  reachedTheLimit: 'Oh no! You have reached the limit to generate AI images for today.',
  backTomorrow: 'Come back tomorrow for more generations!',
  titleSuccessDownload: 'We are happy you found what you needed.',
  hangInThere: 'Hang in there! It won’t take long.',
  generateTermService: 'AI Generator Terms of Service',
  endUserLicenseAgreement: 'End user license agreement',
  aiPromptPlaceholder: 'To get started, describe your vision here, and we’ll bring it to life!',

  downloadAgain: 'Download again',
  btnClose: 'Bezár',

  variousTitle: 'Fedezze fel különböző modelljeinket',
  variousSDXL: 'Keltsen életre lenyűgözően fotórealisztikus embereket és jeleneteket.',
  variousEimisAnime: 'Generatív AI modellezés, amelyet anime stílusú képek készítésére terveztek.',
  variousDynaVisionXL: '3D-s stilizált rajzfilmfigurák generálása.',
  variousJuggernautXL: 'Építészeti elemek és tájak/háttérképek köré összpontosítva.',
  variousDreamShaperXL:
    'Nagy felbontású nagyítás, javított karakterek és sárkányok generációi, csökkentett élsimítás.',
  variousRealismEngineSDXL:
    'Ez a modell a rendkívül részletes, valósághű képekre specializálódott.',
  variousDALLE:
    'DALL-E 3, egy mindenre képes modell, amely a pontos koncepció-vizuális átalakításokban jeleskedik.',
  aiEngine: 'AI Motor',
  'DALL-E': 'DALL-E 3',

  langText: 'Text',
  langElements: 'Elements',
  langAuto: 'AutoBackground',
  langBgRemoval: 'removal',
  resetAll: 'Reset All',
  facebookImage: 'Facebook image',
  facebookLink: 'Facebook link',
  facebookCover: 'Facebook cover',
  facebookStory: 'Facebook story',
  instagramPost: 'Instagram post',
  instagramStory: 'Instagram story',
  twitterPost: 'Twitter post',
  twitterHeader: 'Twitter header',
  pinterestPost: 'Printerest post',
  emailHeader: 'Email Header',
  presentation: 'Presentation',
  presentationWide: 'Presentation wide',
  ebookCover: 'E-book cover',
  linkedinPost: 'Linkedin post',
  brochureCover: 'Brochure Cover (A4)',
  postcard: 'Post card',
  photoPrint: 'Photo print',
  youtubeProfileImage: 'Youtube profile image',
  youtubeChannelCoverPhoto: 'Youtube Channel Cover PhotoApply Background',
  langApplyBgRemoval: 'Removal',
  autoBnW: 'Auto B/W',
  autoAdjust: 'Auto Adjust',
  autoPop: 'Auto Pop',
  original: 'Original',
  addHeader: 'Add a header',
  addTitle: 'Add a title',
  addBody: 'Add a body text',
  addAssistive: 'Add an assitstive text',
  langCompare: 'Compare',
  langEnableEditor: 'Edit image',
  langSimilarStock: 'Similar stock images',
  langTextUndo: 'Undo',
  langTextRedo: 'Redo',
  langTextFont: 'Font',
  langTextFontSize: 'Font size',
  langTextColor: 'Text color',
  langTextHighlight: 'Hightlight',
  langTextAlignment: 'Alignment',
  langTextBold: 'Bold',
  langTextItalic: 'Italic',
  langTextUnderline: 'Underline',
  langTextLineSpacing: 'Line spacing',
  langTextLetterSpacing: 'Letter spacing',
  langBringForward: 'Bring to front',
  langBringBackward: 'Bring to back',
  langDuplicate: 'Duplicate',
  langOpacity: 'Opacity',
  langDelete: 'Delete',
  imageProperties: 'Image properties',
  showMore: 'Show more',
  showLess: 'Show less',
  noEditCompareMsg: 'No edits were made to compare.',
  stockPhoto: 'Stock Photo',
  basicShape: 'Basic shapes',
  chitChat: 'Chit chat',
  shapes: 'Shapes',
  elementChitChat: 'Element Chit Chat',
  organicAbstractShapes: 'Organic abstract shapes',
  elementShapes: 'Element shapes',
  langApplyingEdit: 'Applying Edit...',
  langTextAllFonts: 'All Fonts',
  langSearchFontType: 'Search Font Type',
  editorCompareImageBeforeTitle: 'Before',
  editorCompareImageAfterTitle: 'After',
  langSizePx:
    'A pixel a digitális kép alapegysége. A pixelekben mért képméret határozza meg a részletességet és a letöltési méretet.',
  langSizeDpi:
    'Dots per inch (pontok inchenként): a nyomtatási felbontást méri. A magasabb dpi nagyobb részletességet jelent. Nyomtatáshoz az ajánlott felbontás minden kép esetében 300 dpi.',
  cautionTTI:
    'Vigyázat : Észrevettük a felszólításban olyan kifejezések használatát, amelyek védjegyekhez, szerzői jogokhoz, híres nevezetességekhez és/vagy személyekhez kapcsolódnak.  Vegye figyelembe, hogy a tartalom felhasználásáért kizárólag Ön felelős, beleértve azt is, hogy az nem sérti a vonatkozó törvényeket vagy nem sért semmilyen jogot.',

  addImageReference: 'Képhivatkozás hozzáadása (nem kötelező)',
  uploadImageOnly: 'Csak JPG vagy PNG feltöltése. Max 4MB.',
  dragAndDrop: 'Húzza és tegye le a fájlt vagy',
  errorFileSize: 'Ez a fájl túl nagy. Maximum 4MB megengedett.',
  adjustScale: 'Állítsa be ezt a skálát, hogy a feltöltött képhez nagyon hasonló vagy attól eltérő eredményeket kapjon. Az ajánlott tartomány 7 és 15 között van.',
  similar: 'Hasonló',
  different: 'Különböző',
  uploadNew: 'Új feltöltés',

  imageGuideGeneration: 'A generálás során a képed fogja irányítani a promptodat.',
  notAvailableForEngine: 'Nem elérhető a kiválasztott AI Engine esetében.',

  imagePromptDescribe: 'Kezdetben írja le, hogy milyen képet szeretne a mesterséges intelligenciánkkal létrehozni, és írja le, mi jár a fejében.',
  fileTypeNotSp: 'A fájltípus nem támogatott.',
  theFileSizeTooSmall: 'A fájl mérete túl kicsi. Minimum 512px megengedett.',

  STOCK_PHOTO_PATH: 'stock-foto',
  STOCK_FOOTAGE_PATH: 'stock-mozgokep',
  STOCK_AUDIO_PATH: 'stock-hanganyag',
  CLIPART_VECTOR_PATH: 'clipartok-vektorokt',
  ALL_IMAGES: 'Minden kép',
  PHOTO: 'Photo',
  PHOTOS: 'Fotók',
  VECTORS: 'Vektorok',
  FOOTAGE: 'Mozgókép',
  AUDIO: 'Audió',
  LANG_MORETHANMILLION: 'ROYALTY FREE STOCK FOTÓK',
  LANG_SVG_OR_EPS: 'SVG vagy EPS',
  LANG_OR: 'Vagy',
  STANDARD_LICENSE: 'Sztenderd licenc',
  EXTENDED_LICENSE: 'Kiterjesztett licenc',
  CREDIT_PACK: 'Kredit Csomag',
  DOWNLOAD_PACK: 'letöltési csomagból',
  SUBSCRIPTION_PLAN: 'Előfizetés',
  LANG_DOWNLOAD: 'Letöltés',
  STOCKPHOTO: 'Stock fotó',
  STOCK_PHOTO: 'Stock Fotó',
  STOCK_VECTOR: 'Stock Vektor',
  PREMIUM_STOCKPHOTO: 'PRÉMIUM Stock Fotó',
  PLUS_STOCK_PHOTO: 'PLUS stock fotó',
  PLUS_STOCK_VECTOR: 'PLUS stock vektor',
  MOBILE_STOCKPHOTO: 'Mobil Stockfotó',
  STOCKFOOTAGE: 'Stock mozgókép',
  IMAGEID: 'Képszám',
  ID: 'ID',
  MEDIATYPE: 'Médiatípus',
  COPYRIGHT_TEXT: 'Copyright',
  KEYWORDS: 'Kulcsszavak',
  LANG_CANCEL: 'Mégsem',
  LANG_PURCHASE: 'Vásárlás',
  LANG_STOCKVECTORSANDILLUS: 'stock-vektorok és illusztrációk',
  LANG_STOCKPHOTOSANDIMAGES: 'témájú stock fotók és képek',
  LANG_MATCHES: 'az egyezéseket',
  LANG_VECTORRESULTS: '{number} {keyword} jogdíjmentes vektoros grafika találatunk van az ön számára',
  LANG_PHOTORESULTS: '{number} {keyword} jogdíjmentes stock fotó és kép találatunk van az ön számára',
  LANG_SIMILARIMGS: 'Hasonló jogdíjmentes fotók',
  LANG_COLLECTIONS: 'Kollekciók',
  LANG_ALL: 'Minden',
  LANG_STD: 'Sztenderd',
  LANG_EXTD: 'Kiterjesztett',
  LANG_COMMERCIAL: 'Kereskedelmi',
  LANG_SUBSCRIPTION: 'Előfizetés',
  LANG_EDITORIAL: 'Sajtóképek',
  LANG_UPLOADED: 'Feltöltés ideje',
  LANG_FRESHNESS: 'Frissesség',
  LANG_ANYTIME: 'Bármikor',
  LANG_2DAYS: '2 days',
  LANG_PAST2DAYS: '< Két nap',
  LANG_PASTWEEK: 'Mült hét',
  LANG_PRICE_PLUS_MONTH: "<span class='price'>{price}</span>/hónap",
  LANG_PRICE_PLUS_YEAR: "<span class='price'>{price}</span>/év",
  LANG_PASTMONTH: 'Múlt hónap',
  LANG_PAST3MONTHS: '3 hónap',
  LANG_NUMOFPPL: 'Személyek száma',
  LANG_NOT_RECEIVE_VERIFICATION_EMAIL: 'Nem kapta meg a visszaigazoló e -mailt?',
  LANG_WITHOUTPEOPLE: 'Emberek nélkül',
  LANG_PERSON: 'személy',
  LANG_PEOPLE: 'Emberek',
  LANG_INFANTS: 'Gyermek',
  LANG_TODDLERS: 'Kisgyerek',
  LANG_TEENS: 'Tinédzser',
  LANG_20S: '20s',
  LANG_30S: '30s',
  LANG_40S: '40s',
  LANG_50S: '50s',
  LANG_60S: '60s',
  LANG_70S: '70 +',
  LANG_CAUCASIAN: 'Kaukázusi',
  LANG_BLACK: 'Fekete',
  LANG_HISPANIC: 'Hispán',
  LANG_EAST_ASIAN: 'Kelet-ázsiai',
  LANG_SOUTHEAST_ASIAN: 'Délkelet-ázsiai',
  LANG_INDIAN: 'Indián',
  LANG_MIDDLE_EASTERN: 'Közel-keleti',
  LANG_AGE: 'Kor',
  LANG_ALL_SHAPE: 'Mindent látni',
  LANG_ETHNICITY: 'Etnikum',
  LANG_PEOPLEORMORE: '4 vagy több fő',
  LANG_COLOR: 'Színek',
  LANG_OTHEROPTION: 'Egyéb lehetőségek',
  SOLID_OR_TRANSPARENT_BACKGROUNDS: 'Egyszínű/Átlátszó Háterek',
  LANG_FILTER_BY: 'Szűrés alapja',
  LANG_FILTER: 'Szűrő',
  LANG_PHOTOGRAPHY: 'Fotó',
  LANG_VECTORILLUST: 'Vektoros grafika',
  LANG_SORTBY: 'Rendezés',
  LANG_MORE: 'Továbbiak',
  LANG_RELEVANCE: 'Relevancia',
  LANG_RESEND: 'Újraküldés',
  LANG_RESEND_VERIFICATION: 'Igazoló e-mail újraküldése',
  LANG_NEWEST: 'Új',
  LANG_GEORANK: 'Földrajzi helyzet',
  LANG_ORIENTATION: 'Orientáció',
  LANG_HORIZONTAL: 'Fekvő',
  LANG_PORTRAIT: 'Álló',
  LANG_SQUARE: 'Négyzet',
  LANG_PANORAMA: 'Panoráma',
  LANG_SELECTIVE_FOCUS: 'Szelektív fókusz',
  LANG_PATTERN: 'Mintázat',
  LANG_VIBRANCE: 'Élénkség',
  LANG_VERIFICATION_DESCRIPTION: 'Mielőtt tartalmat tölt le az 123RF oldalról, vissza kell igazolnia a felhasználói fiókját. Kérjük, lépjen be e-mail fiókjába és ellenőrizze, megkapta-e az emailt, vagy kattintson az alábbi linkre, hogy újraküldhessük a megerősítő e-mailt.',
  LANG_VERIFICATION_TITLE: 'Kérjük, ellenőrizze e-mail fiókját.',
  VERIFICATIONRESETUNABLE: 'Hoppá! Az e-mailt nem sikerült elküldeni. Kérjük, lépjen kapcsolatba ',
  LANG_CHOOSEUPTO3COLORS: 'Maximum 3 szín',
  LANG_ISOLATED: 'Izolált',
  LANG_STYLE: 'Stílus',
  LANG_SAFESEARCH: 'Biztonságos keresés',
  LANG_ONLY_AUTHENTIC: 'Csak hitelesek',
  LANG_EXCLUDE_PLACEHOLDER: 'pl. sajt',
  LANG_EXCLUDEWORDS: 'Ne tartalmazza az alábbi kifejezést',
  LANG_EXCLUDE: 'Kizárás',
  LANG_INCLUDE_AICONTENT: 'Tartalmazzon AI alkotásokat',
  VISUAL_SEARCH: 'Vizuális kereső',
  VISUAL_SEARCH_TOOLTIP_TITLE: 'Három egyszerű módszer, amellyel testreszabhatja a keresést a képen belül!',
  VISUAL_SEARCH_TOOLTIP_INFO_1: '1. Kattintson a kép azon részletére, amelyre keresni kíván',
  VISUAL_SEARCH_TOOLTIP_INFO_2: '2. Kicsinyítse vagy nagyítsa a vágójelet a keresési terület nagyságának megfelelően.',
  VISUAL_SEARCH_TOOLTIP_INFO_3: '3. Kattintson a X jelre, hogy visszatérjen a kereséshez.',
  VISUAL_SEARCH_NO_RESULTS: 'A keresés nem járt eredménnyel.',
  LANG_RIS_TITLE: 'Fordított képkeresés',
  LANG_RIS_NOTFOUND: 'Nem találtunk eredményt!',
  LANG_RIS_ERROR_HEADER: 'A feltöltött kép hibát tartalmaz, győződjön meg róla, hogy a kép megfelel a következő kritériumoknak:',
  LANG_RIS_REFINE_SRCH_MSG: 'Finomítsa a keresést kulcsszavak segítségével',
  LANG_RIS_UPLOADED_IMAGE: 'Feltöltött kép',
  LANG_TELL_ME_MORE: 'Többet szeretnék tudni',
  LANG_ENTERKEYWORD: 'Adjon meg egy kulcsszót',
  LANG_SIZE: 'Méret',
  LANG_STANDARD_SIZE: 'Normál méretek',
  LANG_EXTENDED_SIZE: 'EXTENDED SIZES',
  LANG_SIZE_PX: 'A pixel a digitális kép alapegysége. A pixelekben mért képméret határozza meg a részletességet és a letöltési méretet.',
  LANG_SIZE_DPI: 'Dots per inch (pontok inchenként): a nyomtatási felbontást méri. A magasabb dpi nagyobb részletességet jelent. Nyomtatáshoz az ajánlott felbontás minden kép esetében 300 dpi.',
  LANG_SOCMED_SIZE: 'Közösségi média méretek',
  LANG_COMPARE: 'Hasonlítsa össze',
  LANG_AUTO_ENHANCE: 'Automatikus javítás',
  LANG_AUTO: 'Automatikus',
  LANG_BG_REMOVAL: 'Háttér eltávolítása',
  LANG_TEXT: 'Szöveg',
  LANG_ELEMENTS: 'Elemek',
  LANG_IMAGE_INFO: 'Képinformáció',
  LANG_FOLLOW: 'Követem',
  LANG_FOLLOWED: 'Követő',
  LANG_FOLLOWING: 'Following',
  FACEBOOK_IMAGE: 'Facebook kép',
  FACEBOOK_LINK: 'Facebook link',
  FACEBOOK_COVER: 'Facebook borító',
  FACEBOOK_STORY: 'Facebook történet',
  INSTAGRAM_POST: 'Instagram poszt',
  INSTAGRAM_STORY: 'Instagram történet',
  TWITTER_POST: 'Twitter poszt',
  TWITTER_HEADER: 'Tvitter fejléc',
  PINTEREST_POST: 'Pinterest poszt',
  EMAIL_HEADER: 'Email fejléc',
  PRESENTATION: 'Prezentáció',
  PRESENTATION_WIDE: 'Széles formátumú prezentáció',
  EBOOK_COVER: 'E-könyv borító',
  LINKEDIN_POST: 'LinkedIn poszt',
  BROCHURE_COVER: 'Brosúra borító (A4)',
  POSTCARD: 'Képeslap',
  PHOTO_PRINT: 'Fotó nyomtatás',
  YOUTUBE_PROFILE_IMAGE: 'YouTube profilkép',
  YOUTUBE_CHANNEL_COVER_PHOTO: 'YouTube-csatorna borító',
  AUTO_COLOR: 'Automatikus színezés',
  BALANCE: 'Egyensúly',
  AUTO_LEVEL: 'Automatikus szint',
  AUTO_TONE: 'Automatikus tónus',
  ENRICH: 'Fokozás',
  RESET: 'Visszaállítás',
  RESET_ALL: 'Összes visszaállítása',
  FILE_DOWNLOAD_OPTIONS: 'Fájlletöltési lehetőségek',
  FILE_DOWNLOAD_INITIATED: 'Fájl letöltés elkezdődött',
  IMAGE_PROPERTIES: 'Kép tulajdonságai',
  DOWNLOAD_DETAILS: 'Tulajdonságok letöltése',
  FILE_SIZE: 'Fájl méret',
  LICENSE: 'Engedélyezés',
  FILEFORMAT: 'Fájltípus',
  CHANGE: 'Változtat',
  PRICING: 'Árazás',
  FIND_QUALITY_IMAGE: 'Find quality images now',
  SAVE_AS: 'Mentés másként',
  IMAGE_USAGE: 'Kép felhasználása',
  PRINT: 'Nyomtatott',
  ELECTRONIC: 'Elektronikus',
  COMPREHENSIVE: 'Átfogó',
  DOWNLOAD_CREDITS: '<b>{quota}</b> kredittel csökkenti az egyenlegét a(z) kreditcsomagból',
  BASIC_SHAPE: 'Alapformák',
  CHIT_CHAT: 'Csevegés',
  SHAPES: 'Alakzatok',
  ELEMENT_CHIT_CHAT: 'Csevegés alakzatok',
  ORGANIC_ABSTRACT_SHAPES: 'Organikus absztrakt formák',
  ELEMENT_SHAPES: 'Elem alakzatok',
  BUY_SINGLE_IMAGE: 'Az adott kép megvásárlása <br/><b>{quota} kredit</b>',
  BUY_CREDIT_PACK: 'Vásároljon kredit csomagot és <br/><b>spóroljon többet</b>',
  BUY_SINGLE_IMAGE_CREDIT: 'Vásároljon kreditcsomagot <br/><b>{quota} kredit</b>',
  BUY_CREDIT_PACK_POINT_1: '{quota} kredites csomag <b>{price}</b> áron',
  BUY_CREDIT_PACK_POINT_2: 'Nincs havi kötelezetsség.',
  BUY_CREDIT_PACK_POINT_3: '1 évig érvényes.',
  BUY_SINGLE_IMAGE_PSUB: 'Az adott kép megvásárlása <br/><b>{quota} letöltés</b>',
  BUY_DOWNLOAD_PACK: 'Vásároljon képcsomagot és <br/><b>takarítson meg többet</b>',
  BUY_DOWNLOAD_PACK_POINT_1: '{quota} képes csomag ezen az áron: <b>{price}</b>',
  BUY_DOWNLOAD_PACK_POINT_2: 'Nincs havi kötelezetsség.',
  BUY_DOWNLOAD_PACK_POINT_3: '1 évig érvényes.',
  LOWEST_PRICE: '<b>Legalacsonyabb ár</b> <br/>előfizetéssel',
  LOWEST_PRICE_POINT_1: 'Próbálja ki egy hónapig <b>{price}</b> áron',
  LOWEST_PRICE_POINT_2: '10 fotó vagy vektor letöltése.',
  LOWEST_PRICE_POINT_3: 'Nincs napi letöltési limit, a fel nem használt letöltéseket átvisszük a következő hónapra.',
  PER_IMAGE_SIZE: 'képenként <b>{size} méretben</b>',
  PER_IMAGE_ANY: 'képenként <b>bármelyik méretben</b>',
  PER_IMAGE: 'képenként',
  VIEW_PRICING: 'További konstrukciók és árak',
  DOWNLOAD_BEGIN: 'A letöltés pillanatokon belül elindul, kérem várjon.',
  DOWNLOAD_NOT_STARTING: 'Nem indult el a letöltés?',
  SELECT_MIRROR: '[Válasszon szervert]',
  SHOW_MORE: 'Tovább',
  SHOW_LESS: 'Kevesebb mutatása',
  LICENSE_TYPE: 'Licenc típus',
  LICENSE_SUMMARY: 'Licenc összegzés',
  BACK: 'Vissza',
  LANG_VECTOR: 'Vektor',
  PREMIUM_VECTOR: 'PRÉMIUM Stock Vektor',
  SCALE_ANY_SIZE: 'Átméretezés',
  LANDINGPAGE_VECTOR_HEADLINE: 'Stock-vektorok - royalty free illusztrációk, clip artok és grafikák',
  LANDINGPAGE_PHOTO_HEADLINE: 'Stock-fotózás - royalty free fotók és képek',
  CATEGORIES: 'Kategóriák',
  LANDINGPAGE_PRICING_1: 'Vedd és vidd',
  LANDINGPAGE_PRICING_2: 'Vásároljon előfizetést',
  LANDINGPAGE_AS_LOW_AS: 'AKÁR',
  EMPTY_SEARCH_TITLE: 'Hoppá, a keresés nem hozott eredményt.',
  LANG_SMALL: 'Kicsi',
  LANG_MEDIUM: 'Közepes',
  LANG_LARGE: 'Nagy',
  LANG_EXTRA_LARGE: 'Extra nagy',
  LANG_LOW_CREDITS: 'Úgy tűnik, kreditjei fogytán vannak!',
  LANG_TOPUP: 'Top up',
  LANG_TOPUP_NOW: 'Feltöltöm most',
  SEO_DETAILS_TITLE_STOCKPHOTO: '{description} royalty free stock-fotók, képek és stock-fotózás. Image {mediaId}',
  SEO_DETAILS_TITLE_CLIPARTVECTOR: '{description} Royalty Free Svg, Clip Artok, Vektorokt és Stock Illusztrációk. Image  {mediaId}',
  SEO_DETAILS_METADESC_STOCKPHOTO: '{description} stock fotó, képek és stock-fotózás. Image {mediaId}',
  SEO_DETAILS_METADESC_CLIPARTVECTOR: 'Illusztráció: {description} vektorképek, clipart és stock vektorok. Image {mediaId}',
  SEO_DETAILS_TITLE_FREE_PHOTO: '{description} Ingyenes Kép és Fotó {mediaId}.',
  SEO_DETAILS_METADESC_FREE_PHOTO: 'Ingyenes kép a következőről: {description}. Ingyenes kép {mediaId}.',
  SEO_DETAILS_TITLE_FREE_VECTOR: '{description} Ingyenes vektor és grafika {mediaId}.',
  SEO_DETAILS_METADESC_FREE_VECTOR: 'A(z) {description} ingyenes vektorok. Ingyenes grafika {mediaId}.',
  SEO_DETAILS_OGDESCRIPTION: '123RF -Kreatív stock fotók, vektoros grafikák, videók és zenék milliói, amelyek inspirálják és passzolnak kreatív projektjeihez.',
  LIVECHAT_LOOKINGHELP: 'Segíthetünk?',
  LIVECHAT_CHATWITHUS: 'BESZÉLJÜNK CHAT-EN!',
  FREE_TRIAL: '<b>Get 10 Free Images</b> <br/>with Subscription Plan',
  LANG_RELATEDSEARCH: 'Kapcsolódó keresések',
  LANG_RELATED_STOCKPHOTO_SEARCH: 'Kapcsolódó Stock fotó keresések',
  LANG_PROPERATTRIBUTE: 'Íme a szerző adatai:',
  LANG_ATTRIBUTEDESC: 'Kérjük, hogy tüntesse fel a kép szerzőjét oly módon, hogy az alábbi sort kimásolva elhelyezi azt a weboldalán,  kiadványán, vagy termékén.',
  LANG_ATTRIBUTE_TITLE: 'A szerző feltüntetése nem kötelező.',
  LANG_ATTRIBUTE_CREDIT: 'Ha meg szeretné nevezni a szerzőt, kérjük használja az alábbi formátumot:',
  LANG_ATTRIBUTE_COPYLINK: 'Link másolása',
  LANG_ATTRIBUTE_COPYTEXT: 'Szöveg másolása',
  LANG_LICENSE_AGREEMENT: 'A letöltéshez kapcsolódó licenc szerződés <a href={url}><b>itt</b> található</a>',
  LANG_NEXTPAGE: 'TOVÁBB',
  LANG_IMAGE_NO_AVAILABLE: 'A #{mediaId} sorszámú kép már nem elérhető',
  LANG_PHOTOGRAPHY_METAIMG_DESC: 'Keressen a több millió HD stock-fotó és royalty free kép között és töltse le őket',
  LANG_VECTOR_METAIMG_DESC: 'Keressen a több millió HD stock-fotó és royalty free kép között és töltse le őket',
  LANG_ANOTHERMETAKWDEFAULT: 'royalty free, stock-fotó, stock-fotózás, stock-képek, fényképek',
  LANG_ANOTHERMETADESCDEFAULT: 'Keresgéljen több millió HD-fotó, royalty free kép, piktogram, vektor és illusztráció között és töltse le őket!',
  EMPTY_FILTERED_RESULT_TITLE: 'Hoppá, a kereséshez beállított szűrök nem hoztak találatot. ',
  EMPTY_FILTERED_RESULT_SUBTITLE: 'Kapcsoljon ki pár szűrőt, vagy próbálja újra más keresőszavakkal.',
  CLEAR_ALL_FILTER: 'Szűrők törlése',
  PROMOBAR_FREE10_TEXT: 'Get <b>10 FREE Images</b> when you get started on our 1 Month-Free Trial.',
  PROMOBAR_FREE10_BTNLABEL: 'START NOW',
  PROMOBAR_MASSIVE_MARKDOWN_TEXT: 'Hatalmas árzuhanás minden előfizetésnél.',
  PROMOBAR_MASSIVE_MARKDOWN_BTNLABEL: 'Konstrukciók megtekintése',
  LANG_MODEL_RELEASED: 'Modellengedéllyel rendelkezik',
  LANG_PROPERTY_RELEASED: 'Vagyoni engedéllyel rendelkezik',
  LANG_MODEL_RELEASE: 'Modell endedéllyel',
  LANG_PROPERTY_RELEASE: 'Tulajdon engedéllyel',
  LANG_YES: 'Igen',
  LANG_NO: 'Nem',
  LANG_MREXPLAIN: 'A modellengedély egy, a kép modellje által aláírt engedély a fotón szereplő képmásának valamilyen formában történő megjelentetéséhez, disztribúciójához, felhasználásához.',
  LANG_PREXPLAIN: 'A tulajdoni engedély egy, a fotó helyszínéül, témájául szolgáló tárgy, helyiség, ingatlan tulajdonosa által aláírt engedély, a kép valamilyen formában történő megjelentetéséhez, disztribúciójához, felhasználásához.',
  LANG_DOWNLOADS: 'Letöltések',
  LANG_OLDFILENAME: 'Régi fájlnév',
  LANG_DOWNLOAD_PREVIEW: 'Nézőkép letöltése',
  LANG_SHARE: 'Megosztás',
  LANG_RESTRICTIONS: 'Restrictions',
  LANG_READ_MORE: 'Bővebben',
  LANG_EDITORIAL_USE: 'For Editorial Use Only',
  LANG_EDITORIALDESCP: 'Csak sajtó felhasználásra! Ez a kép kizárólag sajtó célú felhasználása engedélyezett. A kép egyéb, reklám, promóciós vagy kereskedelmi célú felhasználása TILOS további engedélyeztetési eljárás nélkül, amennyiben a szerző/jogtulajdonos nem szavatol a felhasználások jogszerűégéért.Az 123RF.com nem jár el a képek jogosítása érdekében.',
  LANG_LOGO_OWNERSHIP: 'Logo Ownership Rights',
  LANG_PHOTO_SHOT_SMARTPHONE: 'This photo was shot with a smartphone.',
  LANG_VECTORNOLOGO: 'Ha szeretné a képet céges logoként haszálni, elintézzük <b>a jogosítást</b> Ön számára. Kérjük, vegye fel velünk a kapcsolatot itt: <b>{phonenum}</b> a további információkért!',
  LANG_DOWNLOAD_QUOTA: '<b>{quota}</b> letöltéssel csökkenti a kvótáját a(z) Előfizetésből',
  LANG_DOWNLOAD_SLOT: '<b>{quota}</b> letöltéssel csökkenti az egyenlegét a(z) letöltési csomagból',
  LANG_LOGO: 'Logó',
  USING_OLDER_BROWSER: 'Régebbi böngészőt használ?',
  UPGRADE_NEWER_BROWSER: 'A jobb felhasználói élmény érdekében frissítsen egy újabb böngészőverzióra!',
  CLICK_TO_DOWNLOAD: 'Válasszon és töltse le az alábbiak közül valamelyiket:',
  LANG_VECTOR_EDITABLE: 'Szerkeszthető SVG és EPS formátum',
  PROMOBAR_GOOD15_TEXT1: 'Fizessen kevesebbet, töltsön le többet. ',
  PROMOBAR_GOOD15_TEXT2: '15% kedvezmény az előfizetési csomagokra ezzel a promóciós kóddal:',
  PROMOBAR_GOOD15_BTNLABEL: 'GOOD15',
  PROMOBAR_SUMMER25_TEXT1: 'Fizessen kevesebbet, töltsön le többet. ',
  PROMOBAR_SUMMER25_TEXT2: '25% kedvezmény az előfizetési csomagokra ezzel a <b>promóciós kóddal</b>:',
  PROMOBAR_SUMMER25_BTNLABEL: 'SUMMER25',
  PROMOBAR_HALLOW30_TEXT: '30% kedvezmény az előfizetési csomagokra ezzel a <b>promóciós kóddal</b>:',
  NO_EDIT_COMPARE_MSG: 'Nem történt szerkesztés az összehasonításhoz.',
  SWITCH_TO_VECTOR_MSG: 'A Képszerkesztő nem használhatók vektoros formátumú képeknél.Minden szerkesztés elvész, ha vektoros formátumra vált.',
  SWITCH_TO_XL_MSG: 'A Képszerkesztő nem használhatók XL méretű képeknél. Minden módosítás elvész, ha XL formátumra vált.',
  UNAVAILABLE_FOR_THIS_SIZE: '{size} méretben nem elérhető.',
  SWITCH: 'ÁTVÁLT',
  SWITCH_LICENSE_EXT_MSG: 'Szeretné elsőként {type} formátumban lementeni ezt a képet? Minden szerkesztés elvész, ha Kiterjesztett licencre vált.',
  LANG_SESSION_EXPIRED: 'A munkamenet lejárt. Kérjük, <a href={url}>jelentkezzen be itt</a>',
  LANG_TEXT_COLOR: 'Szöveg színe',
  LANG_SHAPE_ADD: 'Add Shape',
  LANG_TEXT_HIGHLIGHT: 'Kiemelés',
  LANG_TEXT_ALIGNMENT: 'Igazítás',
  LANG_TEXT_BOLD: 'Kövér',
  LANG_TEXT_ITALIC: 'Dőlt',
  LANG_TEXT_UNDERLINE: 'Aláhúzás',
  LANG_TEXT_BULLET: 'Felsorolás',
  LANG_TEXT_LINE_SPACING: 'Sorköz',
  LANG_TEXT_LETTER_SPACING: 'Betűtávolságok',
  LANG_EDIT_COLOR: 'Edit color',
  LANG_BRING_FORWARD: 'Előre hoz',
  LANG_BRING_BACKWARD: 'Háttérbe visz',
  LANG_DUPLICATE: 'Duplikál',
  LANG_OPACITY: 'Átlátszóság',
  LANG_DELETE: 'Törlés',
  LANG_TEXT_ADD: 'Szöveg hozzáadása',
  LANG_TEXT_EDIT: 'Szöveg szerkesztése',
  LANG_TEXT_FONT_TYPE: 'Betűtípus',
  LANG_TEXT_FONT_SIZE: 'Betűméret',
  LANG_TEXT_ALL_FONTS: 'Minden betűtípus',
  LANG_TEXT_UNDO: 'Visszavonás',
  LANG_TEXT_REDO: 'Újra',
  LANG_TEXT_FONT: 'Betűtípus',
  LANG_SEARCH_FONT_TYPE: 'Betűtípus keresése',
  LANG_SEARCH_SHAPE_TYPE: 'Elemek keresése',
  LANG_DONE: 'Kész',
  ADD_HEADER: 'Fejlés hozzáadása',
  ADD_TITLE: 'Cím hozzáadása',
  ADD_BODY: 'Törzsszöveg hozzáadása',
  ADD_ASSISTIVE: 'Segédszöveg hozzáadása',
  STOCK_PHOTO_BANNER_TITLE: 'Több millió jogdíjmentes stock fotó, amelyből bármit elkészíthet.',
  STOCK_PHOTO_BANNER_DESC: 'Találjon lenyűgöző képeket, hogy görgetés megállító látványelemeket hozzon létre, amelyekkel életre keltheti a nagy ötleteit. ',
  STOCK_PHOTO_SUBSCRIBE_TITLE: 'Ábrázolja történeteit a megfelelő képekkel!',
  STOCK_PHOTO_SUBSCRIBE_DESC: 'Szerezzen azonnali hozzáférést több, mint 180 millió képhez. A mi tartalmaink az Ön történeteivel alkotják a kreativitás tökéletes kombinációját.',
  STOCK_PHOTO_CARD_DOWNLOAD_PACK: 'Képcsomag',
  STOCK_PHOTO_CARD_FROM_AS_LOW_AS: 'Nem magasabb áron, mint',
  STOCK_PHOTO_CARD_CREDIT: 'credit',
  STOCK_PHOTO_CARD_SUBSCRIPTION_PLAN: 'Előfizetés',
  STOCK_PHOTO_CARD_IMAGE: 'kép',
  STOCK_PHOTO_CARD_SUBSCRIBE_NOW: 'Iratkozzon fel most',
  STOCK_PHOTO_CARD_BUY_NOW: 'Vegye meg most',
  STOCK_PHOTO_POPULAR_ROYALTY: 'Népszerű jogdíjmentes kép kategóriák',
  STOCK_PHOTO_BROWSE_BEST_SELLING: 'Browse best-selling royalty free images',
  STOCK_PHOTO_DISCOVER_AFFORABLE: 'Fedezze fel a fotók rendkívül kedvező árait és konstrukcióit',
  STOCK_PHOTO_DISCOVER_NOW: 'Tudjon meg többet',
  STOCK_PHOTO_VECTORS_FOOTAGE_AUDIO: 'Vectors, Footage, Audio for you',
  STOCK_PHOTO_VECTORS: 'Vectors',
  STOCK_PHOTO_ILLUSTRATION: 'Illustration',
  STOCK_PHOTO_PATTERN: 'Pattern',
  STOCK_PHOTO_CARTOON: 'Cartoon',
  STOCK_PHOTO_BABY: 'Baby',
  STOCK_PHOTO_FOOTAGE: 'Footage',
  STOCK_PHOTO_FOOTAGES: 'Footages',
  STOCK_PHOTO_BUILDING: 'Building',
  STOCK_PHOTO_HOTEL: 'Hotel',
  STOCK_PHOTO_CHILDREN: 'Children',
  STOCK_PHOTO_ABSTRACT: 'Abstract',
  STOCK_PHOTO_AUDIO: 'Audio',
  STOCK_PHOTO_AUDIOS: 'Audios',
  STOCK_PHOTO_TECHNO: 'Techno',
  STOCK_PHOTO_HOLIDAY: 'Holiday',
  STOCK_PHOTO_ACCOUSTIC: 'Accoustic',
  STOCK_PHOTO_LANDSCAPES: 'Tájkép',
  STOCK_PHOTO_BABIES: 'Babák',
  STOCK_PHOTO_BUSINESS: 'Üzleti élet',
  STOCK_PHOTO_FOOD: 'Ételek',
  STOCK_PHOTO_ANIMALS: 'Állatok',
  STOCK_PHOTO_TECHNOLOGY: 'Technológia',
  STOCK_PHOTO_HEALTHCARE: 'Egészségügy',
  STOCK_PHOTO_NATURE: 'Természet',
  STOCK_PHOTO_ROMANCE: 'Romantika',
  CLIPART_VECTOR_EXPLORE_MILLIONS: 'Több millió jogdíjmentes stock fotó, amelyből bármit elkészíthet.',
  CLIPART_VECTOR_NEED_AN_EYE: 'Találjon klassz vektoros illusztrációkat, infografikákat, clipart-okat, ikonokat és még sok minden mást, amelyek segítenek megvalósítani csodálatos ötleteit',
  CLIPART_VECTOR_FIND_VECTOR: 'Találja meg a megfelelő vektoros grafikákat, amellyekkel megfűszerezheti a projektjeit!',
  CLIPART_VECTOR_FIND_VECTOR_DESC: 'Legyen szó klassz infografikákról, illusztrációkról, rajzfilmekről, clipartokról, hátterekről, ikonokról vagy bármiről, amihez tartalmakra lenne szüksége; itt biztosan megtalálja. Keltse életre terveit most!',
  CLIPART_VECTOR_ON_DEMAND: 'On Demand',
  CLIPART_VECTOR_FROM_AS_LOW_AS: 'From as low as',
  CLIPART_VECTOR_PER_CREDIT: '/ credit',
  CLIPART_VECTOR_BUY_SUBSCRIPTION: 'Buy Subscription',
  CLIPART_VECTOR_PER_IMAGE: '/ image',
  CLIPART_VECTOR_SUBSCRIBE_NOW: 'Subscribe Now',
  CLIPART_VECTOR_POPULAR_ROYALTY: 'Népszerű jogdíjmentes vector kategóriák',
  CLIPART_VECTOR_DISCOVER_AFFORDABLE: 'Fedezze fel a vektoros grafikák rendkívül kedvező árait és konstrukcióit',
  CLIPART_VECTOR_DISCOVER_NOW: 'Discover Now',
  SPEND_AND_WIN_SUBTITLE: 'Minden {currency} elköltése = 1 részvétel',
  SPEND_AND_WIN_IMGALT: 'Költése után most egy iPhone 13 Pro lehet a nyereménye',
  CLIPART_VECTOR_GRAPHIC: 'Grafika',
  CLIPART_VECTOR_ADVERTISING: 'Hirdetés',
  CLIPART_VECTOR_FLOWER: 'Virág',
  CLIPART_VECTOR_ABSTRACT: 'Absztrakt',
  CLIPART_VECTOR_POP: 'Pop',
  CLIPART_VECTOR_BIRD: 'Madár',
  CLIPART_VECTOR_ILLUSTRATION: 'Illusztráció',
  CLIPART_VECTOR_PIXEL: 'Pixel',
  CLIPART_VECTOR_3D: '3D',
  LANG_VECTOR_SVG_EPS: 'Vektor, SVG és EPS',
  FREE_UPGRADE: 'Ingyenes felminősítés',
  FREE_UPGRADE_MESSAGE: 'INGYENES XL frissítés a teljes előfizetéshez vagy letöltési csomaghoz. Vegye meg most!',
  LANG_FREE_IMAGE: '123RF Ingyenes tartalmak',
  LANG_IMAGE_FREE: 'Ez a stock fotó ingyenes kereskedelmi és személyes használatra. A hozzárendelés szükséges.',
  LANG_HOW_TO_ATTRIBUTE: 'Hogyan tulajdoníthatom?',
  LANG_FREE_DOWNLOAD: 'Ingyenes letöltés',
  LANG_FREE_DOWNLOAD_BUTTON: 'FREE Letöltés',
  LANG_GO_PLUS_BUTTON: 'Gyerünk a PLUS-ra',
  LANG_ATTRIBUTION_IS_KEY: 'A hozzárendelés fontos eleme azoknak a tartalomkészítőknek, akik munkájukat nyilvánosan hozzáférhetővé teszik. Tartalom -készítőink értékelik az alkotásaikból származó expozíciót, amelyet az önhez hasonló felhasználók milliói használnak, megosztottak és jóváírnak.',
  LANG_GET_ATTRIBUTION_LINK: 'Másolja ezt a linket, és helyezze el egy olyan helyre, ahol a tartalom használata van.',
  LANG_CREDIT_OWNER: 'Kérjük, adja meg a Teremtőt:',
  LANG_USE_ATTRIBUTION: 'Használja az alábbi hozzárendelési linket.',
  LANG_ATTRIBUTION_LOCATION: 'Ha ez nem valósítható meg, akkor más jó helyek vannak a weboldal, a blog vagy a hírlevél láblécében, vagy akár a webhely egy konkrét kredit szakasza elegendő lesz a {media_type} tulajdonosának jóváírásához.',
  LANG_GOT_A_QUESTION: "Van kérdésed? <a href={url} target='_blank'><u>Lépjen kapcsolatba velünk</u></a>",
  LANG_ATTR_FREE_IMG_TITLE: 'Jóvá kell adnia A tulajdonos ennek az ingyenes képnek.',
  LANG_ATTR_FREE_DOWNLOADING: 'Kitartás! Nem fog sokáig tartani.',
  LANG_PLUS_CONTENT_1: 'Korlátlan számú letöltés',
  LANG_PLUS_CONTENT_2: 'Hozzáférés több, mint 100 millió fotójoz és vektoros grafikához',
  LANG_PLUS_CONTENT_3: 'Exkluzív 3D -s illusztrációk csak az 123RF-nél',
  LANG_PLUS_CONTENT_4: 'Korlátlan letöltések',
  LANG_PLUS_CONTENT_5: 'Korlátlan kreatív szabadság',
  FREE_VECTOR: 'FREE Stock Vektor',
  FREE_STOCKPHOTO: 'FREE Stock Fotó',
  FREE_PUBLIC_DOMAIN_CC0: 'Közösségi tartalom (Public Domain) CC0',
  BUY_5_DOWNLOAD_PACK: "<b>Korlátozott időre szóló ajánlat!</b><br/>3 + <b><span style='color:red'>2 INGYEN</span></b> = 5 fotó vagy vektor",
  BUY_5_DOWNLOAD_PACK_POINT_2: 'Bármikor letöltheti (egy éven belül)',
  BUY_5_GET_2_FREE: 'MOST KETTŐT INGYEN ADUNK!',
  LANG_ASK_DOWNLOAD_PLUS: 'Le szeretné tölteni ezt a PLUS tartalmat?',
  LANG_SIGN_UP_PLUS: 'Vegye igénybe az 123RF PLUS-t ilyen alacsony áron: ',
  LANG_BENEFIT_PLUS: 'Tegyen szert a PLUS csomagok csupán ilyen ártól:',
  LANG_FOR_ONLY: "<span class='text'>Csak</span> <span class='price'>{price} dollárért</span>/hónap",
  LANG_FOR_ONLY_2: "<span class='text'>Csak</span> <span class='price'>{price} dollárért</span>",
  LANG_RELEASES: 'Engedélyek',
  LANG_UNAVAILABLE: 'Nem elérhető',
  LANG_ATTR_FREE_IMG_TITLE2: 'Örülünk, hogy megtalálta, amire szüksége volt. 🎉',
  LANG_PLEASE_NOTE: 'Felhívjuk figyelmét, hogy ehhez a képhez nem tartozik semmilyen modell- vagy tulajdoni engedély. A kép kereskedelmi célú felhasználása saját felelősségre történik.',
  LANG_PLEASE_ADVISED: 'Felhívjuk figyelmét, hogy a felismerhető egyéneket tartalmazó képek nem kerülnek kiadásokkal. Ezeknek a képeknek a kereskedelmi felhasználásához engedélyt kell szerezni a vonatkozó felektől. Ha a kép nem tartalmaz felismerhető egyéneket, akkor nem lehet szerzői jogi problémák, ha a kereskedelmi célú felhasználásakor.',
  LANG_REACHED_LIMIT: 'Ops! Elérte a napi letöltési limitet.',
  LANG_COME_TOMORROW: 'Nagyon örülünk, hogy szereted az ingyenes képeinket! Gyere vissza holnap a letöltési limit frissítéséért. Köszönjük. 😊',
  LANG_DOWNLOAD_ERROR: 'Ops! Hiba lpett fel, nem sikerült letölteni a képet.',
  LANG_REFRESH_AND_TRY_AGAIN: "Kérjük, frissítse böngészőjét, és próbálja meg újra. <a href={url} target='_blank'>Értesítsen minket,</a> ha a probléma továbbra is fennáll.",
  LANG_REACHED_LIMIT_PLUS: 'A napi letöltési határérték elért',
  LANG_COME_TOMORROW_PLUS: 'Azta! Elérte a mai letöltési korlátját. Kérjük, gyere vissza holnap, hogy töltsön le többet. Köszönet a támogatásért!',
  SEE_YOU_TOMORROW: 'Viszlát holnap!',
  ORIGINAL: 'Eredeti',
  'AUTO_B&W': 'Autom. FF',
  AUTO_ADJUST: 'Autom. javítás',
  AUTO_POP: 'Autom. pop',
  NONE: 'Nincs',
  LANG_CROP: 'Körbevágás',
  LANG_ENABLE_EDITOR: 'Kép szerkesztése',
  LANG_DISABLE_EDITOR: 'Bezár',
  LANG_IMAGE_FREE_CC0: "Ez egy CC0 kép. A hozzárendelés nem szükséges. Használhatja a <a class='linkCC0' href={url} target='_blank'>CC0 licenc feltételei</a> szerint.",
  LANG_PREVIEW: 'Előnézet',
  LANG_ATTRIBUTION_CC0_TITLE: 'CCO kép: a szerzőt nem kell feltüntetni',
  LANG_ATTRIBUTION_TITLE: 'Fel kell tüntetni a szerzőt',
  LANG_ATTRIBUTION_CONTENT: 'A hozzárendelési hivatkozást a fájl letöltése után adjuk meg.',
  LANG_ATTRIBUTION_CC0_CONTENT: 'A forrás feltüntetése szükséges, mivel ez egy CCO kép.',
  LANG_SUBCRIBE_PREMIUM: 'Elérte napi letöltési limitjét! Ha továbbiakat szeretne letölteni, váltson 123RF PRÉMIUMRA!',
  LANG_REMOVE_ATTRIBUTION: 'Eltávolítaná a forrásmegjelölést?',
  LANG_SUBCRIBE_PLUS_ATTRIBUTION: "Fizessen elő az <img src={image} alt='123RF Plus Logo'>-ra, hogy korlátlanul hozzáférjen 100 millió stock fotókhoz és illusztrációkhoz, forrásmegjelölési köteletettség nélkül.",
  LANG_CREATOR_ATTRIBUTION: 'Kérjük, hogy végezze el a forrásmegjelölést az alábbi link segítségével.',
  LANG_GET_ATTRIBUTION_LINK_2: 'Másolja ki ezt a linket és helyezze el egy olyan helyen, amely közel van a tartalom felhasználás helyéhez, a webhely láblécében vagy a webhely forrásmegjelölésre alkalmas részében.',
  LANG_APPLY_BG_REMOVAL: 'Alkalmazza a Háttér eltávolítást',
  LANG_APPLYING_EDIT: 'Szerkesztések alkalmazása...',
  LANG_APPLYING_EDIT_ERROR: 'Valami elromlott.',
  LANG_TRY_AGAIN: 'Újra próbálja?',
  LANG_SEARCHCOLLECTION_PREMIUM: 'Teljes kollekció',
  LANG_SEARCHCOLLECTION_PLUS: 'Essentielle Kollekció',
  LANG_SEARCHCOLLECTION_FREE: 'Alap Kollekció',
  LANG_SIMILARIMAGESPAGE_TITLE: 'Stock Fotók és Royalty Free előfizetés a 123RF-től',
  LANG_SIMILARIMAGESPAGE_STOCKPHOTOS: 'Hasonló stock képek',
  LANG_SIMILARIMAGESPAGE_EMPTYRESULTS: 'Hoppá! Nincs itt semmi látnivaló.',
  LANG_SIMILARIMAGESPAGE_UNAVAILABLEIMAGE: 'Kép nem érhető el',
  LANG_PER_IMAGE: '/kép',
  LANG_PLANS_AND_PRICING: 'Konstrukciók és Árak',
  LANG_CHOOSE_BEST_PLAN: 'Válassza ki azt a konstrukciót, amely leginkább megfelel az Ön igényeinek.',
  LANG_WANT_MORE_SAVING_1: 'Többet szeretne megtakarítani?',
  LANG_WANT_MORE_SAVING_2: 'Tekintse meg további konstrukcióinkat már {price}/kép-es ártól',
  LANG_BEST_VALUE: 'Legjobb ár-érték arány, {percentage}%-os megtakarítás',
  LANG_YEARLY_PLAN: 'Éves konstrukció',
  LANG_WITH_MONTHLY_PAYMENTS: 'Havi fizetéssel',
  LANG_GRAB_YEARLY_PLAN: 'Válasszon éves konstrukciót, és takarítson meg {percentage}%-ot.',
  LANG_PRICE_PER_MONTH: '{price}/hónap',
  LANG_PRICE_PER_YEAR: '{price}/év',
  LANG_GET_X_EVERY_MONTH: '{quota} kép vagy vektoros grafika minden hónapban',
  LANG_X_EVERY_MONTH: '{quota} kép vagy vektoros grafika minden hónapban',
  LANG_SINGLE_IMAGE_PURCHASE: 'Egyszeri képvásárlás',
  LANG_GET_ONLY_WHAT_YOU_NEED: 'Szerezze be csak azt, amire pillanatnyilag szüksége van.',
  LANG_TOTAL_PRICE: 'Összesen {price}',
  LANG_DOWNLOAD_IMMEDIATELY: 'Azonnali képletöltés fizetés után Fizetés után.',
  LANG_NEXT: 'Következő',
  LANG_TITLE: 'Stock Fotók és Royalty Free előfizetés a 123RF-től',
  SIMILAR_STOCK_PHOTOS: 'Hasonló stock képek',
  EMPTY_SIMILAR_SEARCH_RESULTS: 'Hoppá! Nincs itt semmi látnivaló.',
  UNAVAILABLE_IMAGE: 'Kép nem érhető el',
  LANG_DOWNLOAD_PACK: 'Letöltési csomag',
  LANG_GET_X_ANY_TIME: '{quota} fotó vagy vektor bármikor',
  LANG_X_CREDITS: '{total} Kredit',
  LANG_PER_CREDIT: '/Kredit',
  LANG_GET_X_PHOTOS_AND_Y_VIDEOS_ANY_TIME: '{photoCount} fényképet{videoCount, plural, =0 {} other { vagy # videó}} bármikor',
  INCLUDES_STANDARD_LICENSE: 'Tartalmazza az alap licencet',
  LANG_MEDIUM_SIZE: 'Közepes méret',
  LANG_LARGE_SIZE: 'Nagy méret',
  LANG_EXTRA_LARGE_SIZE: 'Extra nagy méret',
  LANG_STANDARD_SIZES: 'Normál méretek',
  LANG_ROYALTY_FREE: 'jogdíjmentes',
  LANG_OPTIONS: 'Lehetőségek',
  LANG_BENEFIT_FROM_PLUS_PLAN: 'Tegyen szert a PLUS csomagok csupán ilyen ártól: ',
  LANG_RELATED_FONT_SEARCH: 'Kapcsolódó keresések',
  AI_GENERATED_PHOTO: 'Mesterséges intelligencia (AI) által generált kép',
  FONT_PRICING_CREDIT: 'Kredit',
  '3_FONTS_3_IMGS': '3 betűkészlet <b>vagy</b> 3 kép <b>vagy</b> 1 videó',
  '9_FONTS_9_IMG_3_VIDEOS': '9 betűkészlet <b>vagy</b> 9 kép <b>vagy</b> 3 videó',
  PURCHASE_AND_DOWNLOAD: 'Vásárlás és letöltés',
  FONT_PATH: 'fonts',
  FONT_ZIP: 'Betűtípus (OTF, TTF)',
  FONT_PACKS_LICENSE: '123RF Csomag Licencelés',
  FONT_META_DESC: 'Töltse le a {product_title} betűtípust, írásképet, vagy tipográfiát, hogy lenyűgöző kreatívokat hozzon létre nyomtatásra és webre.',
  FONT_META_TITLE: 'Betűtípus - {product_title} | 123RF',
  FONT_SIMILAR: 'Hasonló betűtípusok',
  LANG_PLANS: 'KONSTRUKCIÓK',
  LANG_I_WANT_THIS: 'I want this',
  LANG_AI_VARIATION_TOOLTIP_TITLE: 'Próbáljon variációkat létrehozni',
  LANG_AI_VARIATION_TOOLTIP_DESCRIPTION: 'Generáljon új képeket az eredeti kép alapján. Ráadásul ez egy új módja annak, hogy megjutalmazzuk 123RF alkotóinkat minden alkalommal, amikor egy AI generált képet licencelnek!',
  LANG_SIZEBAR_TOOLTIP_DESCRIPTION: 'A közösségünk köszöni ezt neked. A licencdíjakat megosztjuk a 123RF alkotókkal.',
  LANG_CONTRIBUTORS_AI_VARIATION: 'Az eredeti kép 123RF-alkotói minden egyes licencelt AI-változat után kompenzációt kapnak.',
  LANG_AI_VARIATION_LIMIT: 'Jaj, ne! Elérte a mai napra vonatkozó AI-képgenerálási limitet.',
  LANG_GENERATE_COMEBACK: 'Jöjjön vissza holnap újabb kép generálásért!',
  LANG_LICENSE_AND_DOWNLOAD: 'Licencelés és letöltés',
  LANG_CONFIRM_OK: 'Oké',
  LANG_AI_SWITCH_CONTENT: 'A Képszerkesztő  nem támogatott az AI variációkhoz. Minden szerkesztés elveszik, ha AI-variációkra vált.',
  LANG_GENERATE_VARIATIONS: 'Generáljon variációkat',
  LANG_GENERATE_WITH_AI: 'Képek generálása mesterséges intelligenciával',
  LANG_NEW: 'Új',
  LANG_AI_GENERATOR_LICENSE: '123RF AI Generator szolgáltatási feltételek (“Feltételek”)',
  LANG_I_UNDERSTAND: 'Megértettem',
  LANG_WHAT_IS_THIS: 'Mi ez?',
  LANG_AUTHENTIC: 'Autentikus',
  LANG_ABSTRACT: 'Absztrakt',
  LANG_MACRO_CLOSEUP: 'Makró/nagy felbontás',
  LANG_OBJECT: 'Objektum',
  LANG_WATERCOLOR: 'Akvarell',
  LANG_FLAT: 'Flat',
  LANG_CARTOON: 'Rajzfilm',
  LANG_GEOMETRIC: 'Geometrikus',
  LANG_GRADIENT: 'Gradiens',
  LANG_ISOMETRIC: 'Izometrikus',
  LANG_3D: '3d',
  LANG_HAND_DRAWN: 'Kézzel rajzolt',
  LANG_NATURAL: 'Természetes',
  LANG_WARM: 'Meleg',
  LANG_DRAMATIC: 'Drámai',
  LANG_VIVID_BOLD: 'Élénk és merész',
  LANG_BLACK_WHITE: 'Fekete/fehér',
  LANG_ASIAN: 'Ázsiai',
  LANG_AFRICAN_AMERICAN: 'Afroamerikai',
  LANG_STANDARD: 'Normál',
  LANG_EXTENDED: 'Kiterjesztett',
  LANG_STANDARD_CONTENT_1: 'Korlátlan számú megtekintés a közösségi, webes, e-mail és mobil felületeken.',
  LANG_STANDARD_CONTENT_2: 'Akár 500 000 nyomat.',
  LANG_STANDARD_CONTENT_3: 'Nem használható eladásra szánt termékeken.',
  LANG_EXTENDED_CONTENT_1: 'Korlátlan számú megtekintés a közösségi, webes, e-mail és mobil felületeken.',
  LANG_EXTENDED_CONTENT_2: 'Korlátlan nyomat.',
  LANG_EXTENDED_CONTENT_3: 'Használható eladásra szánt termékeken.',
  LANG_ORIGINAL_IMAGE: 'Eredeti kép',
  LANG_IMPORTANT_NOTE: 'Fontos megjegyzés',
  LANG_AI_ORIGINAL_ALTERED: 'Az AI-változatok az eredeti kép AI-technológiával módosított változatai. Ez hasonló ahhoz, mintha egy mesterséges intelligencia-szerkesztővel javíttatná ki a kiválasztott képet.',
  LANG_GENERATE_VARIATIONS_NOTE: 'Mivel jelenleg a mesterséges intelligencia által vezérelt képvariációk béta fázisában vagyunk, a generált képek váratlan eredményeket hozhatnak. Keményen dolgozunk annak biztosításán, hogy az ilyen eredmények és variációk megfeleljenek az etikus és felelős mesterséges intelligencia irányelveknek.',
  LANG_LICENSE_AI_EDITED: 'Ha licenceli az AI-szerkesztett változatok egyikét, az eredeti kép szerzői jogainak jogosultja licencdíjat kap.',
  LANG_MOOD: 'hangulat',
  LANG_AI_EXCLUDE_PLACEHOLDER: 'Pl. zöld szín, egyenes haj',
  LANG_SIMILAR_STOCK: 'Hasonló stock képek',
  LANG_PREMIUM_TAG_TOOLTIP: 'PREMIUM subscription only',

  variousStockGAI: 'A több millió készletfotóval képzett és a vállalati igényekre optimalizált, jogszabálynak megfelelő mesterséges intelligenciamodell, amely a felelősségbiztosítással biztosítja a lelki békét.',
  sizes: 'Sizes',

  downloadMail1st: 'Our Image Enlargement Service provider will email you at',
  downloadMail2nd:
    'the instructions to download the final enlarged file within the next business day',

  aiGenarated: 'AI-generated image',

  variousOpenJourney: 'Az AI Model a Midjourney jellegzetes stílusához hasonló művészetet generál.',
  variousTurboVision: 'Nagy sebességű generálás "normál" XL felbontásban, javítva az általános minőséget.',
  variousRealVisXL: 'A modell célja a fotórealisztikus eredmények precíz és pontos elérése.',
  variousPortraitPlus: 'Egységes portrékompozíciójú modell, ideális 1:1 képarányhoz.',
  enterVirifiedCode: 'Adja meg az ellenőrző kódot',
  verify: 'Ellenőrizze a  címet.',
  didNotGetCode: 'Nem kapott kódot?',
  clickToResend: 'Kattintson az újraküldéshez',
  otpCannotEmpty: 'Az OTP nem lehet üres.',
  sentCodeToMail: 'Küldtünk egy kódot a regisztrált e-mail címére.',
  invalidCode: 'Érvénytelen kód',
  expiredOTP: 'Az OTP lejárt, kérjük, küldje újra.',
  sendEmail: 'Az e-mail sikeresen elküldve, kérem, ellenőrizze a postaládáját a folytatáshoz.',
  remainingTime: 'Hátralévő idő: ',
};

export default hu;
