import { Menu, Button, Drawer } from 'antd';
import { MenuOutlined, DownOutlined } from '@ant-design/icons';
import { useRouter } from 'next/router';
import React, { useState, useEffect } from 'react';
import { getTransText } from 'common/translate';
import Link from 'next/link';
import {
  ACCESS_TOKEN, ROUTES, LANG, GET_SETTING,
} from 'constants/index';
import { LANGUAGE_CODE_DEFAULT } from 'constants/intl';
import PropTypes from 'prop-types';

import { changeLanguage, getLangRoute } from 'common/common';
import MenuSetting from 'components/organisms/Header/MenuSetting/MenuSetting';
import * as cookie from 'helpers/cookies';
import MenuLanding from 'components/organisms/Header/MenuLanding/MenuLanding';
import { getStorage } from 'helpers/storage';
import { submenu } from 'components/organisms/Header/MenuLanguage/MenuLanguage';

const SiderMenuHeader = ({ sticky }) => {
  const router = useRouter();
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [ischeckAuthen, setIscheckAuthen] = useState(false);
  const [visible, setVisible] = useState(false);
  const keyLang = router.query?.lang ?? cookie.getCookies(LANG) ?? LANGUAGE_CODE_DEFAULT;
  const { SubMenu } = Menu;
  const getSettings = getStorage(GET_SETTING);
  const [cloudFrontViewCountry, setCloudFrontViewCountry] = useState('');

  const selectLanguage = (e) => {
    const lang = e.key;
    changeLanguage(lang, router);
  };
  useEffect(() => {
    setMenuOpen(ROUTES.LOGIN === router.pathname);
    setIscheckAuthen(cookie.getCookies(ACCESS_TOKEN));
  }, [router.pathname]);

  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };

  useEffect(() => {
    setCloudFrontViewCountry(getSettings?.country);
  }, [getSettings]);

  const SiderMenuNotLogin = (
    <Menu mode="inline" className="sider-menu">
      <SubMenu key="menulanding" title={getTransText('category')}>
        <MenuLanding />
      </SubMenu>
      <SubMenu key="sub1" title={getTransText('language')} onClick={selectLanguage}>
        {submenu(cloudFrontViewCountry, keyLang)}
      </SubMenu>
      {ischeckAuthen ? (
        <SubMenu
          key="getSetting"
          title={getTransText('settings')}
          className="sider-menu-information"
        >
          <MenuSetting />
        </SubMenu>
      ) : (
        <Menu.Item className="signin-text" key="signin">
          <Link href={getLangRoute(ROUTES.LOGIN)}>{getTransText('signin')}</Link>
        </Menu.Item>
      )}
    </Menu>
  );

  const SiderMenu = (
    <Menu mode="inline" className="sider-menu">
      <SubMenu key="sub2" title={getTransText('language')} onClick={selectLanguage}>
        {submenu(cloudFrontViewCountry, keyLang)}
      </SubMenu>
    </Menu>
  );

  return (
    <div className="drawer-menu">
      {ischeckAuthen ? (
        <Button type="link" className="menu-outlined" onClick={showDrawer}>
          <MenuOutlined />
        </Button>
      ) : (
        <Button type="link" className="header-mobile" onClick={showDrawer}>
          {keyLang}
          <DownOutlined />
        </Button>
      )}

      {isMenuOpen ? (
        <Drawer className="drawer-lang" placement="right" onClose={onClose} visible={visible}>
          {SiderMenu}
        </Drawer>
      ) : (
        <Drawer
          className="drawer-lang"
          placement="right"
          onClose={onClose}
          visible={visible}
          bodyStyle={{ paddingBottom: sticky ? '170px' : '0' }}
        >
          {SiderMenuNotLogin}
        </Drawer>
      )}
    </div>
  );
};

SiderMenuHeader.propTypes = {
  sticky: PropTypes.bool,
};
SiderMenuHeader.defaultProps = {
  sticky: false,
};

export default SiderMenuHeader;
