export const GET_CREDIT_SUMMARY = 'GET_CREDIT_SUMMARY';
export const SET_CREDIT_SUMMARY = 'SET_CREDIT_SUMMARY';
export const SET_VIEW = 'SET_VIEW';
export const SET_CREDIT_SUMMARY_LOADING = 'SET_CREDIT_SUMMARY_LOADING';
export const GET_NOTI_LIST = 'GET_NOTI_LIST';
export const SET_NOTI_LIST = 'SET_NOTI_LIST';
export const SET_LOADING_NOTI = 'SET_LOADING_NOTI';

export const GET_ISADMIN = 'GET_ISADMIN';
export const SET_ISADMIN = 'SET_ISADMIN';
export const GET_AGREE_STATUS = 'GET_AGREE_STATUS';
export const SET_AGREE_STATUS = 'SET_AGREE_STATUS';
export const SET_ACTION = 'SET_ACTION';
export const GET_ACTION = 'GET_ACTION';

export const GET_ALL_LIKE_BOX = 'GET_ALL_LIKE_BOX';

export const LIKE_BOX_COLLECTION = 'LIKE_BOX_COLLECTION';

export const GET_ALL_MY_COLLECTION = 'GET_ALL_MY_COLLECTION';
export const SET_ALL_MY_COLLECTION = 'SET_ALL_MY_COLLECTION';
export const DELETE_ALL_MY_COLLECTION = 'DELETE_ALL_MY_COLLECTION';
export const SET_LOADING_MY_COLLECTION = 'SET_LOADING_MY_COLLECTION';
export const SET_LOADING_DELETE = 'SET_LOADING_MY_COLLECTION';
export const GET_DETAIL_COLLECTION = 'GET_DETAIL_COLLECTION';

export const SET_DASHBOARD_OPTION = 'SET_DASHBOARD_OPTION';
export const SET_TYPE_TAB_PORTFOLIO = 'SET_TYPE_TAB_PORTFOLIO';
export const SET_USER_DETAILS = 'SET_USER_DETAILS';
export const GET_USER_DETAILS = 'GET_USER_DETAILS';
export const SET_DOWLOAD_HISTORY_FILTER_USER = 'SET_DOWLOAD_HISTORY_FILTER_USER';
export const GET_LIST_DATA_ADDED_DEFAULT_COLLECTION = 'GET_LIST_DATA_ADDED_DEFAULT_COLLECTION';
export const SET_IMAGE_ID = 'SET_IMAGE_ID';
export const SET_DATA_GENERAL_SETTING = 'SET_DATA_GENERAL_SETTING';
export const SET_DATA_DL_IMAGE_SETTING = 'SET_DATA_DL_IMAGE_SETTING';
export const SET_DATA_DL_FOOTAGE_SETTING = 'SET_DATA_DL_FOOTAGE_SETTING';
export const SET_DATA_DL_AUDIO_SETTING = 'SET_DATA_DL_AUDIO_SETTING';

export const SET_ACCESS_USER_DASHBOARD = 'SET_ACCESS_USER_DASHBOARD';
