import React from 'react';
import PropTypes from 'prop-types';
import { BLACK_BORDER_COLOR } from 'constants/index';

const IcCopyLink = ({
  fillcolor,
  width,
  height,
}) => (
  <svg width={width} height={height} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_4076_58121)">
      <path d="M21.1253 28.9505C20.8521 28.9509 20.5815 28.8972 20.3291 28.7926C20.0767 28.6881 19.8475 28.5347 19.6546 28.3412C15.849 24.5357 15.849 18.3441 19.6546 14.5386L27.3348 6.85833C29.1781 5.01507 31.6293 4 34.2368 4C36.8442 4 39.2942 5.01507 41.1387 6.85833C44.9443 10.6639 44.9443 16.8554 41.1387 20.661L37.6276 24.1721C37.4345 24.3653 37.2052 24.5185 36.9528 24.623C36.7004 24.7276 36.43 24.7814 36.1568 24.7814C35.8837 24.7814 35.6132 24.7276 35.3609 24.623C35.1085 24.5185 34.8792 24.3653 34.6861 24.1721C34.4929 23.979 34.3397 23.7497 34.2352 23.4973C34.1306 23.245 34.0769 22.9745 34.0769 22.7014C34.0769 22.4282 34.1306 22.1578 34.2352 21.9054C34.3397 21.6531 34.4929 21.4238 34.6861 21.2306L38.1972 17.7195C39.2458 16.6684 39.8347 15.2443 39.8347 13.7597C39.8347 12.275 39.2458 10.8509 38.1972 9.79986C37.1399 8.74255 35.7331 8.16013 34.2368 8.16013C32.7404 8.16013 31.3349 8.74255 30.2763 9.79986L22.5961 17.4801C21.5475 18.5312 20.9586 19.9552 20.9586 21.4399C20.9586 22.9246 21.5475 24.3486 22.5961 25.3997C22.8876 25.6904 23.0862 26.061 23.1668 26.4647C23.2474 26.8684 23.2064 27.2869 23.0488 27.6672C22.8913 28.0476 22.6244 28.3726 22.282 28.601C21.9395 28.8295 21.537 28.9511 21.1253 28.9505Z" fill={fillcolor} />
      <path d="M13.7562 44C12.4739 44.0036 11.2036 43.7528 10.0188 43.2622C8.83411 42.7715 7.75847 42.0508 6.85417 41.1416C3.04861 37.3361 3.04861 31.1445 6.85417 27.339L10.3653 23.8278C10.5585 23.6347 10.7878 23.4815 11.0401 23.3769C11.2925 23.2724 11.5629 23.2186 11.8361 23.2186C12.1092 23.2186 12.3797 23.2724 12.6321 23.3769C12.8844 23.4815 13.1137 23.6347 13.3069 23.8278C13.5 24.021 13.6532 24.2503 13.7577 24.5026C13.8623 24.755 13.9161 25.0254 13.9161 25.2986C13.9161 25.5717 13.8623 25.8422 13.7577 26.0946C13.6532 26.3469 13.5 26.5762 13.3069 26.7693L9.79571 30.2805C8.74711 31.3316 8.15821 32.7556 8.15821 34.2403C8.15821 35.725 8.74711 37.149 9.79571 38.2001C10.853 39.2574 12.2598 39.8398 13.7562 39.8398C15.2525 39.8398 16.658 39.2574 17.7166 38.2001L25.3968 30.5199C26.4454 29.4688 27.0343 28.0447 27.0343 26.5601C27.0343 25.0754 26.4454 23.6513 25.3968 22.6003C25.2037 22.4071 25.0505 22.1778 24.946 21.9255C24.8414 21.6731 24.7876 21.4026 24.7876 21.1295C24.7876 20.8563 24.8414 20.5859 24.946 20.3335C25.0505 20.0812 25.2037 19.8519 25.3968 19.6587C25.59 19.4656 25.8193 19.3124 26.0716 19.2078C26.324 19.1033 26.5945 19.0495 26.8676 19.0495C27.1408 19.0495 27.4112 19.1033 27.6636 19.2078C27.9159 19.3124 28.1452 19.4656 28.3384 19.6587C32.1439 23.4643 32.1439 29.6558 28.3384 33.4614L20.6581 41.1416C19.7538 42.0508 18.6782 42.7715 17.4935 43.2622C16.3087 43.7528 15.0385 44.0036 13.7562 44Z" fill={fillcolor} />
    </g>
    <defs>
      <clipPath id="clip0_4076_58121">
        <rect width="48" height="48" fill="white" />
      </clipPath>
    </defs>
  </svg>

);
IcCopyLink.propTypes = {
  fillcolor: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

IcCopyLink.defaultProps = {
  fillcolor: BLACK_BORDER_COLOR,
  width: '24',
  height: '24',
};
export default IcCopyLink;
