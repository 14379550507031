/* eslint-disable react/forbid-prop-types */
import { Button, Tooltip } from 'antd';
import { getTransText } from 'common/translate';
import { HIDE_TTI_INTRODUCTION, ROUTES } from 'constants/index';
import { useEffect, useState } from 'react';
import * as cookie from 'helpers/cookies';
import propTypes from 'prop-types';
import { useRouter } from 'next/router';

const TTIIntroduction = ({ children, title, desc }) => {
  const [showIntroduction, setShowIntroduction] = useState(false);
  const router = useRouter();

  const hideIntroduction = () => {
    setShowIntroduction(false);
    cookie.setCookie(HIDE_TTI_INTRODUCTION, true, { expires: 30 });
  };

  useEffect(() => {
    const hideIntroductionStatus = cookie.getCookies(HIDE_TTI_INTRODUCTION) === 'true';
    setShowIntroduction(!hideIntroductionStatus);
  }, []);

  return showIntroduction
    && ![ROUTES.TEXT_TO_IMAGE, '/ai-image-generator/[hash]'].includes(router.pathname) ? (
      <Tooltip
        visible
        overlayClassName="tour-introduction-overlay"
        title={(
          <div className="tour-introduction-content">
            <div className="tour-introduction-content-new-title">New</div>
            <h1 className="tour-introduction-content-title">{title}</h1>
            <p className="tour-introduction-content-desc">{desc}</p>

            <div className="tour-introduction-content-btn">
              <Button
                onClick={() => {
                  hideIntroduction();
                }}
                className="global-btn secondary-btn ai-search-introduction-btn"
              >
                {getTransText('later')}
              </Button>
              <Button
                onClick={() => {
                  router.push(ROUTES.TEXT_TO_IMAGE);
                  hideIntroduction();
                }}
                className="global-btn primary-btn ai-search-introduction-btn"
              >
                {getTransText('tryNow')}
              </Button>
            </div>
          </div>
      )}
        placement="bottomLeft"
        className="tour-introduction-header-wrapper"
      >
        {children}
      </Tooltip>
    ) : (
      children
    );
};

TTIIntroduction.propTypes = {
  desc: propTypes.string,
  title: propTypes.string,
  children: propTypes.any,
};

TTIIntroduction.defaultProps = {
  desc: '',
  title: '',
  children: <></>,
};

export default TTIIntroduction;
