import languageObject from 'locales/index';
import { LANGUAGE_CODE_DEFAULT } from 'constants/intl';
import { get } from 'lodash';
import ServerSideService from 'services/ServerSide/ServerSideService';
import * as cookie from 'helpers/cookies';
import {
  CONTENT_TERM_LICENSE_1,
  CONTENT_TERM_LICENSE_2,
  CONTENT_TERM_LICENSE_3,
  CONTENT_TERM_LICENSE_ONLY_US_1,
  CONTENT_TERM_LICENSE_ONLY_US_2,
  CONTENT_TERM_LICENSE_ONLY_US_3,
  DATE_UPDATE,
  DATE_LAST_UPDATE,
  LANG,
} from 'constants/index';

export const getTransText = (nestedString) => {
  let lang = LANGUAGE_CODE_DEFAULT;
  if (typeof window !== 'undefined') {
    const urlParams = new URLSearchParams(window.location.search);
    lang = urlParams.get('lang') || cookie.getCookies(LANG);
  } else {
    lang = ServerSideService.getLang();
  }

  const langCode = lang ?? LANGUAGE_CODE_DEFAULT;
  return get(languageObject[langCode], nestedString) ?? nestedString;
};
// get lang response
export const detectedLang = (message) => {
  const enKey = Object.keys(languageObject[LANGUAGE_CODE_DEFAULT]).find(
    (key) => languageObject[LANGUAGE_CODE_DEFAULT][key] === message,
  ) ?? 'errorMesServer';
  return getTransText(enKey);
};
export const replaceString = (data) => {
  const bodyContent = data
    ?.replaceAll(CONTENT_TERM_LICENSE_1, CONTENT_TERM_LICENSE_ONLY_US_1)
    .replaceAll(CONTENT_TERM_LICENSE_2, CONTENT_TERM_LICENSE_ONLY_US_2)
    .replaceAll(CONTENT_TERM_LICENSE_3, CONTENT_TERM_LICENSE_ONLY_US_3)
    .replaceAll(DATE_UPDATE, DATE_LAST_UPDATE);
  return bodyContent;
};

export const replaceStringLicense = (data) => {
  const bodyContent = data
    ?.replaceAll(CONTENT_TERM_LICENSE_ONLY_US_1, CONTENT_TERM_LICENSE_1)
    .replaceAll(CONTENT_TERM_LICENSE_ONLY_US_2, CONTENT_TERM_LICENSE_2)
    .replaceAll(CONTENT_TERM_LICENSE_ONLY_US_3, CONTENT_TERM_LICENSE_3)
    .replaceAll(DATE_UPDATE, DATE_LAST_UPDATE);
  return bodyContent;
};
