import PropTypes from 'prop-types';
import { ICON_COLOR_DEFAULT } from 'constants/index';

const IcFootage = ({
  fillcolor,
  width,
  height,
}) => (
  <>
    <svg width={width} height={height} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M13.5605 9.68182C11.4223 9.68182 9.68182 11.1311 9.68182 12.913V35.0863C9.68182 36.8689 11.4223 38.3182 13.5605 38.3182H34.4395C36.5785 38.3182 38.3182 36.8689 38.3182 35.0863V12.913C38.3182 11.1311 36.5785 9.68182 34.4395 9.68182H13.5605ZM34.4395 41.5H13.5605C9.6667 41.5 6.5 38.6228 6.5 35.0863V12.913C6.5 9.37716 9.6667 6.5 13.5605 6.5H34.4395C38.3333 6.5 41.5 9.37716 41.5 12.913V35.0863C41.5 38.6228 38.3333 41.5 34.4395 41.5Z" fill={fillcolor} />
      <path fillRule="evenodd" clipRule="evenodd" d="M21.1427 31.1591C20.868 31.1591 20.5933 31.1065 20.336 30.9973C19.6604 30.7108 19.2273 30.0927 19.2273 29.4131V19.3804C19.2273 18.7015 19.6604 18.0826 20.336 17.7962C21.0115 17.5146 21.81 17.6093 22.3792 18.047L28.8893 23.0633C29.3199 23.3951 29.5682 23.8829 29.5682 24.3975C29.5682 24.9113 29.3199 25.3992 28.8893 25.7301L22.3792 30.7464C22.0262 31.0183 21.5865 31.1591 21.1427 31.1591Z" fill={fillcolor} />
    </svg>
  </>
);
IcFootage.propTypes = {
  fillcolor: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

IcFootage.defaultProps = {
  fillcolor: ICON_COLOR_DEFAULT,
  width: '48',
  height: '48',
};
export default IcFootage;
