import PropTypes from 'prop-types';
import { ICON_COLOR_DEFAULT } from 'constants/index';

const IcCreditUsage = ({
  fillcolor,
  width,
  height,
}) => (
  <svg width={width} height={height} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M20.6 15.9099C20.6 19.1117 16.8447 21.6198 12.05 21.6198C7.25535 21.6198 3.5 19.1117 3.5 15.9099C3.5 14.8653 3.89975 13.8945 4.60492 13.0605C4.80522 13.6731 5.1943 14.2429 5.73209 14.7454C5.51684 15.114 5.4 15.5058 5.4 15.9099C5.4 17.9731 8.4457 19.7165 12.05 19.7165C15.6543 19.7165 18.7 17.9731 18.7 15.9099C18.7 15.5058 18.5832 15.114 18.3682 14.7451C18.9058 14.2429 19.2948 13.6731 19.4954 13.0608C20.2003 13.8945 20.6 14.8653 20.6 15.9099Z" fill={fillcolor} />
    <path fillRule="evenodd" clipRule="evenodd" d="M20.6 12.1033C20.6 15.3051 16.8447 17.8132 12.05 17.8132C7.25535 17.8132 3.5 15.3051 3.5 12.1033C3.5 11.0586 3.89975 10.0878 4.60492 9.25391C4.80522 9.86648 5.1943 10.4363 5.73209 10.9387C5.51684 11.3073 5.4 11.6992 5.4 12.1033C5.4 14.1665 8.4457 15.9099 12.05 15.9099C15.6543 15.9099 18.7 14.1665 18.7 12.1033C18.7 11.6992 18.5832 11.3073 18.3682 10.9385C18.9058 10.4363 19.2948 9.86648 19.4954 9.25416C20.2003 10.0878 20.6 11.0586 20.6 12.1033Z" fill={fillcolor} />
    <path fillRule="evenodd" clipRule="evenodd" d="M12.05 4.49022C8.4457 4.49022 5.4 6.23364 5.4 8.29682C5.4 10.36 8.4457 12.1034 12.05 12.1034C15.6543 12.1034 18.7 10.36 18.7 8.29682C18.7 6.23364 15.6543 4.49022 12.05 4.49022ZM12.05 14.0067C7.25535 14.0067 3.5 11.4987 3.5 8.29682C3.5 5.09499 7.25535 2.58691 12.05 2.58691C16.8447 2.58691 20.6 5.09499 20.6 8.29682C20.6 11.4987 16.8447 14.0067 12.05 14.0067Z" fill={fillcolor} />
  </svg>
);

IcCreditUsage.propTypes = {
  fillcolor: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

IcCreditUsage.defaultProps = {
  fillcolor: ICON_COLOR_DEFAULT,
  width: '24',
  height: '24.04',
};
export default IcCreditUsage;
