import React from 'react';
import PropTypes from 'prop-types';
import { WHITE_COLOR } from 'constants/index';

const ThreeDots = ({
  fillcolor,
  width,
  height,
}) => (
  <svg width={width} height={height} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="19.7278" cy="12.1316" r="2.63157" transform="rotate(90 19.7278 12.1316)" fill={fillcolor} />
    <circle cx="12.3606" cy="12.1316" r="2.63157" transform="rotate(90 12.3606 12.1316)" fill={fillcolor} />
    <circle cx="4.99148" cy="12.1316" r="2.63157" transform="rotate(90 4.99148 12.1316)" fill={fillcolor} />
  </svg>

);
ThreeDots.propTypes = {
  fillcolor: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

ThreeDots.defaultProps = {
  fillcolor: WHITE_COLOR,
  width: '25',
  height: '24',
};
export default ThreeDots;
