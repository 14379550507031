const tw = {
  // Header
  photos: '照片',
  Photos: '照片',
  vectors: '向量',
  Vectors: '向量',
  footageupper: '影片',
  footage: '影片',
  video: 'Video',
  audio: '音檔',
  signin: '登入',
  imageLogo:
    'https://static-cdn.assetsdelivery.com/images/new_translate_corpplus_logo/logo_Chinese.png',
  language: '語言',
  // Footer
  contact: '聯繫',
  contactUs: '聯繫我們',
  privacy: '隱私協議',
  terms: '使用條例',
  cookie: 'COOKIE政策',
  corporateAgreement: 'License Agreement',
  // ForgotPassword
  forgotPwdTitle2: '忘記自己的密碼嗎',
  forgotPwdDesc2:
    "That's OK, it happens to all of us. Enter your username below and we'll send you a link to reset your password",
  forgotPwdFail: '請輸入Email',
  emailCannotEmpty: 'Email不可空白',
  returnToLogin: '回到登入畫面',
  email: '電郵',

  // share likebox
  duplicateCollection: 'Duplicate collection',
  removeFromCollection: 'Remove from collection',
  addToCollection: 'Add to collection',
  totalContentInThisCollection: 'Total content in this collection',
  duplicate: 'Duplicate',
  collectionDescription: 'Collection description',
  descriptionHere: 'Description here',
  duplicateCollectionSuccess:
    'You have successfully duplicated this collection to your personal collections.',

  // Login
  username: '用戶名稱',
  pw: '密碼',
  rememberMe: '記住我',
  login: '登入',
  forgotPassword: '忘記密碼',
  loginAsGuest: '訪客登入',
  // ResetPassword
  resetPwdDesc: '請輸入您的新密碼，我們會立即將您登錄。',
  newPw: '新的密碼',
  confirmPwd: '確認密碼',
  resetPwdButton: '重設密碼',
  resetPwdTitle: '重新設定您的密碼',
  enterYourNewPw: '請輸入你的新密碼。',
  pwMiniMumChars: '至少需要8個字元',
  pwMaxChar20: '字數限制20字內',
  pwLowerCase: '至少需要一個小寫英文字母',
  pwUpperCase: '至少需要一個英文大寫字母',
  pwNumeral: '至少需要一個數字',
  pwdSpecialChar: 'At least one special character',
  confirmPwNotMatch: '密碼不符',
  resetPwdInvalidLink: '此連結無效或已過期。',
  pwHasBeenReset: '密碼重設完畢, 請重新登入。',
  // Admin index
  companyName: '公司名字',
  phone: '聯絡電話',
  fax: '傳真',
  address: '地址',
  idd: 'IDD',
  // Dashboard Admin Credits
  myBalance: 'My balance',
  remaining: 'remaining',
  creditsRemaining: 'Credits remaining',
  topUp: 'Top up',
  groupBalance: 'Group balance',
  totalGroupBalance: 'Total group balance',
  manageGroupBalance: 'Manage group balance',
  individualBalance: 'Individual balance',
  manageIndividualBalance: 'Manage individual balance',
  totalDownloads: 'Total downloads',
  totalItems: '全部項目',
  // Update Password
  updatePassTitle: '更新 密码',
  updatePassDes: '出发前更新您的密码 出发前更新您的密码！',
  oldPassword: '目前密碼',
  confirmNewPassword: '確認新的密碼',
  returnToHome: '返回首页',
  // Cookie Policy
  langCookie01: '當您造訪%host%時，我们會在您的計算機硬碟中放置一個名為cookie的小文本文件。',
  langCookie02:
    '該文件允許我们追踪您，以便我们能夠為您提供基本功能，例如購物車。 它们還用于提供增强的線上用戶體驗，並為您提供“likebox”等功能。',
  langCookie03:
    '此外，它還允許我们分析您與我們網站的互動情况（包括訪問過的網頁，查看的搜索字彙和圖片）。 這與您是否進行購買無關。',
  langCookie04:
    '您同意使用的暫存器，也將存取您成為固定的客戶名單，而我們會介紹您有興趣的相關廣告。',
  langCookie05:
    '多数瀏覽器詢問您是否拒绝Cookie; 如果您這樣做，某些功能可能無法使用，並且某些網頁可能無法正確顯示。',

  downloadModalTitle: '圖片下載',
  bulkDownloadTitle: 'Bulk Download',
  messageFileNotSupported1stHalf: '不支援 TIFF格式圖檔, 影像媒體以及音樂檔案。以下媒體:',
  messageFileNotSupported2ndHalf: '已從批量下載中刪除。',
  licenseOnBehalf: '代理許可',
  customizedPortal: 'Customized portal',
  normalDownload: '正常下載',
  myCredit: '我的點數',
  lowCreditsReminder: '低點數',
  downloadModalId: '圖片編號:',
  downloadModalPrice: '點數:',
  clearAll: '清空',
  myActivities: '我的活動',
  info: '資料',
  idCapitialization: 'ID',
  licenseOnBehalfOfYourClient: 'License on behalf of your client (Your client owns the license)',
  downloadUsing: '下載此內容使用',
  // homepage page
  home3Title: 'Free Browsing',
  toSearchKeyword: '在這裡輸入您的搜尋內容',
  toSearchAIKeyword: '輸入關鍵字，文章標題或博客標題',
  allImages: '全部圖片',
  freeImages: 'Free Images',
  categoryImage: 'Category',
  recentViewed: '最近瀏覽過的',
  latestIMG: 'Latest Images',
  // Setting
  adminControlPanel: 'Corp+ 管理員控制面板',
  downloadHistory: '下載記錄',
  hruc: 'HRUC',
  changePassword: '更改密碼',
  logOut: '退出',
  hello: '你好,',
  viewAccount: 'View Account',
  myAccount: '我的帳戶',
  myCollections: '我的收藏',
  personalCredits: '個人點數',
  groupTotal: '群組總點數',
  toTalUnpaidLicense: '全部未付款許可證',
  highRUnwatermarkComp: '高解析度無浮水印作稿圖',
  monthSelection: 'Month selection',

  // Menu
  categories: '圖像分類',
  myLikebox: '我的收藏夾',
  settings: '設定',
  notification: '通知',
  clear: '明確',
  likebox: '收藏夹',
  credits: '點數',
  // Credit summary at header
  downloadAndUsege: '下載和使用',
  totalDlCredit: '可用下載點數',
  allocatedDlCredit: '分配下載點數',
  personalAvailableCre: '個人可用點數',
  groupAvailableCre: '團體可用點數"',
  availableDlSlot: '可用下載張數',
  uNPLD: '現在使用之後付款',
  hiResHRUC: '高解析度無浮水印作稿圖 (HRUC)',
  monthlyAllocation: '月配額',
  remainingQuota: '剩下餘額',
  remainingHRUCQ: '剩餘高解析度無浮水印作稿圖額度',
  useNowPayLater: 'Use-Now-Pay-Later (UNPL)',
  totalPendingLicense: 'Total Pending License Downloads',
  enterprisePlan: 'Corporate+ Enterprise Plan',
  unallocatedCredits: 'Unallocated Credits',
  unutilizedAllocatedCredits: 'Unutilized Allocated Credits',
  usersTitleTotalCredits: 'Users',
  groupsTitleTotalCredits: '團體',
  titleTotalCreditsAvailable: 'Total Available',
  // live chat
  liveChatLookIngHelp: '請告訴我們您所需要的幫助',
  liveChat: '線上諮詢',
  whatsApp: 'WhatsApp',
  hiThere: '您好,',
  // email response
  emailIsNotAvailable: 'Email有誤。',
  anErrorHaSoccured: '錯誤已被修正, 請重新試試看。',
  emailHasBeenSent:
    '一封歡迎信已成功發送。 我們已經寄出重新設定說明電子信件到 __email 。 如果在五分鐘後還是沒有收到信件, 請確認是否出現在垃圾桶中.',
  tooManyAttempts: '嘗試太多次，您暫時被鎖定了。',
  // INVALIDPASSWORD
  inValidPassword: '密碼有誤! 請確認你的大寫鍵是否開啟。',
  userIsBlocked: '嘗試太多次，您暫時被鎖定了。',
  blockWarrings: '10次嘗試失敗後，您將暫時被鎖定！',
  // Update Password
  resetPwdUpdated: '您的密碼已經更新。',
  updatePwDfailed: '無法更新密碼！',
  // notification
  // Notification Side Bar - user
  noticationShareLikebox: '分享收藏夾 [{VAR1}] 給你.',
  noticationOutofCredits: '下載點數已經用盡.',
  noticationAllcateCredit: '分配 [{VAR1}] 下載點數給你.',
  noticationRemoveCredits: '已從您的帳戶[{VAR1}]中移除點數。',
  noticationAssignGroups: '分配你加入 [{VAR1}] 團體.',
  noticationRemoveFromGroup: '已將您從[{VAR1}]團體中移除。',
  purcahsedCredits: '您剛剛購買了[{VAR1}]個點數。',
  noticationAssignAdmin: '指定給你作為管理員。',
  noticationRemoveAdmin: '已移除您的管理員身份。',
  noticationAssignGroupAdmin: '指定您為[{VAR1}]的團體管理員。',
  noticationRemoveGroupAdmin: '已移除您在[{VAR1}]團體管理員的身份。',
  // Notification Side Bar - admin
  adminNotificationShareLikebox: '與[{RECEIVER}]分享[{VAR1}]的收藏夾。',
  adminNotificationAllcateCredits: '將[{VAR1}]的點數分配給[{RECEIVER}]。',
  adminNotificationRemoveCredist: '已刪除[{VAR1}]點數從[{RECEIVER}]的帳戶。',
  adminNotificationAssignGroup: '將[{RECEIVER}]分配給[{VAR1}]團體。',
  adminNotificationRemoveFromGroup: '已將[{RECEIVER}]從[{VAR1}]團體中移除。',
  adminPurchasedCredits: '[{RECEIVER}]剛剛購買了[{VAR1}]個點數。',
  adminNotificationAssignAdmin: '以管理員身份分配[{RECEIVER}]。',
  adminNotificationRemoveAdmin: '以管理員身份移除[{RECEIVER}]。',
  adminNotificationAssignGroudAdmin: '指定[{RECEIVER}]作為[{VAR1}]團體的管理員。',
  adminNotificationremoveGroupAdmin: '[{VAR1}]的組管理員移除了[{RECEIVER}]。',
  // datetime
  year: '年',
  month: '個月',
  week: '星期',
  day: '天',
  hour: '小時',
  minute: '分鐘',
  second: '第二',
  ago: '前',
  justNow: '現在',
  // datetimes
  years: '年',
  months: '個月',
  weeks: '星期',
  days: '天',
  hours: '小時',
  minutes: '分鐘',
  seconds: '秒',
  // LicenseAgreement
  licenseAgreement: '許可證合約',
  licenseAgreementCaption:
    'PLEASE READ ALL THE TERMS PROVIDED IN THIS CORPORATE+ CONTENT LICENSING PLATFORM. YOUR ACCEPTANCE OF THESE TERMS IS AN ABSOLUTE CONDITION OF YOUR USE OF THE CORPORATE+ CONTENT LICENSING PLATFORM AND THE CONTENT, AND FORMS A LEGAL AGREEMENT. BY ACCESSING ANY SERVICE OR PART THEREOF UNDER THE CORPORATE+ CONTENT LICENSING PLATFORM, YOU AGREE TO BE BOUND BY THE TERMS OF THESE LEGAL DOCUMENTS. 123RF RESERVES THE RIGHT TO CHANGE THESE TERMS AT ANY TIME WITHOUT PRIOR WRITTEN NOTICE, AND YOU AGREE TO BE BOUND BY SUCH CHANGES.',
  corpplusAgreement: 'Corporate+ Agreement',
  // login
  usernameCannotEmpty: '使用者名稱不能空白',
  passWordCannotEmpty: '密碼不可空白',
  oldPasswordCannotEmpty: '舊密碼不得空白',
  confirmPasswordCannotBeEmpty: '確認密碼不得空白',
  // update password
  newpasswordDifferent: 'The new pasword and old pasword must be different.',
  // sent email not 20 times
  forgotNot20: ' 無法發送更多電子郵件，請檢查您的郵箱。',
  // errorMesServer
  errorMesServer: 'An error has occured, please try again or contact your administrators',
  // white space
  canNotContainSpaces: 'Password cannot contain spaces',
  // username is not available
  userNameNotAvailable: 'Username is not available.',
  // Username cannot contain spaces
  usernameCanNotContainSpaces: 'Username cannot contain spaces',
  // old browsers
  usingOlderBrowsers: '您使用的是舊版瀏覽器嗎？',
  upgradeBrowser: '升級新的瀏覽器版本，享受更流暢的體驗！',
  clickToDownload: '點擊下載任何一個：',
  googleChrome: 'Google Chrome',
  mozillaFirefox: 'Mozilla Firefox',
  microsoftEdge: 'Microsoft Edge',
  safari: 'Safari',
  // forgot page new description
  forgotPwdDescNew: "Enter your username below and we'll send you a link to reset your password.",
  // Notification
  readAll: 'Read All',
  viewAll: '觀看全部',
  // Filter bar
  aiGenerate: 'AI生成的圖像',
  aiContent: '包括AI生成的內容',
  filter: '過濾',
  safeSearch: '安全搜尋',
  aiAssist: '人工智能輔助',
  freshness: '新鮮度',
  gender: '性別',
  sortBy: '排列',
  mediaType: '種類',
  orientation: '方向',
  modelPreferences: '優先考慮模特兒',
  people: '人物',
  more: '更多',
  numOfPeople: '人數',
  age: '年齡',
  ethnicity: '種族',
  collection: '收藏',
  uploaded: 'Uploaded',
  style: '類型',
  cutOut: '單色/透明背景',
  doNotIncludeWords: '不包含以下字句',
  doNotIncludeWords_ex: '例如：起司',
  color: '顏色',
  max1Color: '最多一個顏色',
  license: '授權類型',
  fps: 'FPS',
  resolution: '解析度',
  clipDuration: '音檔長度 (分鐘)',
  loop: '循環',
  category: '類別',
  genre: '種類',
  tempo: '節奏',
  // Filter button text
  Relevance: '任意排序',
  New: '最新',
  Georank: '依照所在位置排序',
  DownloadHistory: '歷史下載記錄',
  Popularity: '最多下載',
  Photography: '攝影',
  VectorIllustration: '向量',
  Horizontal: '橫向',
  Landscape: '橫向',
  Portrait: '肖像',
  Square: '正方形',
  Panorama: '全景',
  numPpl: '人數',
  WithoutPeople: '無人',
  FilterNumberPeople1: '1 person',
  FilterNumberPeople2: '2 People',
  FilterNumberPeople3: '3 People',
  FilterNumberPeople4: '4 people or more',
  Exclude: '不包含字詞',
  SelectiveFocus: 'Selective Focus',
  Pattern: 'Pattern',
  Vibrance: 'Vibrance',
  Infants: 'Infants',
  Toddlers: 'Toddlers',
  Teens: 'Teens',
  PastWeek: '過去一周',
  LastMonth: 'Last month',
  PastThreeMonths: '過去三個月',
  PastTwodays: '過去兩天',
  Male: '男生',
  Female: '女生',
  Standard: '標準',
  extended: '擴展',
  Commercial: '商業使用',
  Editorial: '新聞類圖片',
  Black: 'Black',
  Caucasian: '白人',
  AfricanAmerican: '非裔美國人',
  Asian: '亞洲人',
  EastAsian: 'East Asian',
  Hispanic: '西班牙人',
  Indian: 'Indian',
  MiddleEastern: 'Middle Eastern',
  SoutheastAsian: 'Southeast Asian',
  Children: '小孩',
  Teenagers: '青年',
  Adults: '成人',
  Seniors: '長者',
  FPS1: '23.98/24',
  FPS2: '25',
  FPS3: '29.7/30',
  FPS4: '60',
  FPS5: '> 60',
  Off: 'Off',
  ZeroToOne: '0-1',
  OneToTwo: '1-2',
  TwoToThree: '2-3',
  LagerThree: '>3',
  Loopable: '是',
  Nonloopable: '否',
  Music: '音樂',
  SoundEffects: '音效',
  LogoAndIdents: 'LOGO標誌',
  Veryslow: '非常慢',
  VeryslowSubText: '(0-79)',
  Slow: '慢',
  SlowSubText: '(80-119)',
  Medium: '中等',
  MediumSubText: '(120-139)',
  Fast: '快',
  FastSubText: '(140-159)',
  Veryfast: '非常快',
  VeryfastSubText: '(160>)',
  TwoToEight: '2 - 8',
  TwentyToThirTy: '12 - 30',
  ThirtySixToSixty: '30 - 60',
  OneHundredAndTwenty: '120',
  Popular: '最受歡迎',
  MostDownload: 'Most Download',
  Credits: '點數',
  Freshness: '新鮮度',
  pickAGenre: '選一個類型',
  // Paginations
  Of: '/',
  Page: '頁數',
  // home
  recommended: '推薦給你',
  bestSellingHome: 'Browse best-selling royalty photos',
  trendingKeyWord: '熱門關鍵字',
  FAQ: 'Frequently Asked Questions (FAQ)',
  // audio
  categoriesAudio: 'HD stock audios',

  // genre list audios
  gameSounds: '遊戲',
  cartoonSounds: '卡通',
  soundPacks: '音效',
  interfaceSounds: '界面音效',
  transitionsAndMovementsSounds: '轉換與運動',
  natureSounds: '天籟',
  domesticSounds: '國內聲音',
  urbanSounds: '都會風格',
  industrialSounds: '工業類',
  futuristicSounds: '未來派',
  humanSounds: '人類音樂',
  othersSounds: '其他',
  ambientSounds: '環境',
  childrenSounds: '孩童',
  cinematicSounds: '戲劇',
  classicalSounds: '古典',
  corporateSounds: '商業',
  electronicaSounds: '電子樂',
  soulSounds: '靈魂R&B',
  jazzSounds: '爵士樂',
  popSounds: '流行樂',
  worldBeatSounds: '世界節奏',

  countrySounds: '西部與鄉村',
  drumSounds: '鼓與Bass',

  folkSounds: '民俗風',
  funkSounds: '放克音樂',

  hiphopSounds: '嘻哈',
  holidaySounds: '節慶與季節',

  rockSounds: '搖滾',

  individualSounds: '獨立',
  introSounds: '開場與謝幕',
  ascSounds: '升序&降序',
  businessSounds: '企劃與媒體',
  orchestralSounds: '交響樂',
  festiveSounds: '節日',
  percussiveSounds: '打擊樂',
  miscellaneousSounds: '其他',
  packsSounds: '懶人素材包',
  percussionSounds: '打擊樂聲音',
  sampleLoopSounds: '樣本循環',

  // Search images
  searchImage: '照片 & 向量',
  StockPhotosAndImages: '版權照片＆圖像',
  StockVectorClipartAndIllustration: '向量繪圖素材',

  matches: '符合的',
  editorial: '新聞類圖片',
  downloaded: '這張圖像已經被下載了',
  to: 'to',
  relatedSearches: '相關搜尋',
  similarTo: '相似',
  searchByImage: '按圖搜尋',
  options: '選項',
  searchByImageMsg: '搜索123RF，嘗試將圖片拉進搜索框',
  mustBeJPGorPNG: '圖片格式只能為 JPG/PNG',
  sizeLessThan5MB: '圖片大小必須小於5MB',
  sizeLessThan4MB: '圖片大小必須小於4MB',
  dragOrDropFile: '拖放文件或',
  browse: '瀏覽',
  uploadedImage: '上傳的圖片',
  similarStockPhotos: '類似素材',
  // Search footage
  searchFootage: '影片',
  StockFootage: '版權影像素材',
  businessFootage: 'Hacks to make the most of your business videos',
  // Details pages
  audioDetail: 'Audio Detail',
  imageDetail: 'Image Detail',
  footageProperties: '影片屬性',
  modalDownloadTitle: '檔案下載選項',
  credit: '點數',
  saveAs: '另存新檔',
  ownLicense: '正常下載（自有授權）',
  downloadAs: '以此下載',
  insufficientCredits: '點數不足',
  audioProperties: '音訊屬性',
  custom: '自訂',
  personalAndCom: '個人和商業用途廣告，演示，社交媒體，網路。',
  licenseType: '授權類型',
  requiredForResale: '需要用於劇院表演，付費存取，串流影片，遊戲和應用程式的轉售或分發。',
  footageDetail: '影片細節',
  stockAudioKeywords: '音檔關鍵字',
  stockFootageKeywords: '影片關鍵字',
  stockPhotoKeywords: 'Stock photo keywords',
  limitedLicenseSizes: 'Limited License Sizes',
  stereo: '立體聲',
  fileSize: '檔案大小',
  footageId: '影像素材編號',
  audioId: 'Audio ID',
  imageId: 'Image ID',
  // Search audio
  searchAudio: '音檔 (音樂 & 聲音特效)',
  StockAudio: '音檔',
  similarTracks: 'Similar Tracks',
  viewAlbum: 'View Album',
  copyLink: '複製連結',
  preview: '預覽',

  bestSellingFootage: '瀏覽最熱門影片',
  featuresAudio: 'Featured audio',
  readmore: 'Read More',
  // justified layout
  similarImage: '類似的圖片',
  addToLikebox: 'Add to Likebox',

  // recently view empty data
  rVPhoto: '近期圖片',
  rVVectors: '近期向量',
  rVFootage: '近期影片',
  rVAudio: '近期音檔',
  // recently likes empty data
  rLPhoto: 'Your recently liked images will show here',
  rLVectors: 'Your recently liked vectors will show here',
  rLFootage: 'Your recently liked video will show here',
  rLAudio: 'Your recently liked audio will show here',
  // Content Unavailable
  contentUnavailable: 'Content Unavailable',
  contentError: 'We’re sorry, there’s some error displaying this content',
  emptySearchResultTitle: '糟糕，您使用這些過濾條件的搜索未有任何結果。',
  emptySearchResultTitleWithoutFilter: 'Oops, your search did not return any results.',
  emptySearchResultSubTitle: '嘗試刪除一些過濾條件或嘗試使用其他關鍵字',
  emptyReverseSearchTitle: '沒有搜尋結果！！',
  emptyReverseSearchSubTitle1: '上傳的圖片中含有錯誤，請確保圖像符合以下條件',
  emptyReverseSearchSubTitle2: '利用關鍵字來縮小您的搜尋範圍',
  emptyReverseSearchSubTitle3: '圖片必須是JPG/PNG',
  emptyReverseSearchSubTitle4: '圖片大小必須小於5MB',
  emptySearchResultClearFilter: '清除所有過濾條件',
  emptyDownloadHistoryResult: 'Your download history will show here',
  // Please enter a keyword
  keywordEmpty: '請輸入關鍵字',
  // admin redirect to
  plansAndPricing: 'Plans and Pricing ',
  // Footer langding page
  nameUnavailable: 'Name unavailable',
  addressUnavailable: 'Company address unavailable',
  phoneUnavailable: 'Phone number unavailable',
  faxUnavailable: 'Fax unavailable',
  iddUnavailable: 'IDD unavailable',
  emailUnavailable: 'Email unavailable',
  contentTermLicense1:
    'This Agreement shall be governed by the laws of Singapore, without regard to the conflict of laws principles. ',
  contentTermLicense2:
    'the parties agree that the dispute shall be settled by arbitration in Singapore under the Singapore International Arbitration Centre (“SIAC”) administered SIAC Arbitration Rules in force when the notice of arbitration is submitted in accordance with these rules. The number of arbitrators shall be one (1). The language of the arbitration shall be English.',
  contentTermLicense3:
    'that damages and remedies under Singapore Law for any breach of any such covenant would be inadequate. You further acknowledge and agree that the arbitral tribunal may order any interim measure it deems necessary or proper in accordance with the applicable laws of Singapore,',
  contentTermUsLicense1:
    'This Agreement shall be governed by the laws of the state of Delaware, United States of America, without regard to the conflict of laws principles',
  contentTermLicenseUs2:
    ' the parties agree first to try in good faith to settle the dispute by mediation administered by the American Arbitration Association under its Commercial Mediation Procedures before resorting to arbitration, litigations or some other dispute resolution procedure. In the event if the dispute is settled via arbitration, the number of arbitrators shall be one (1) and the language of the arbitration shall be English.',
  contentTermUsLisence3:
    ' that damages and remedies under applicable law for any breach of any such covenant would be inadequate. You further acknowledge and agree that the arbitral tribunal may order any interim measure it deems necessary or proper in accordance with the applicable law,',
  contentCorporateUs:
    'This Agreement shall be governed by the laws of the State of Delaware in the United States of America, without regard to the conflict of laws principles. All disputes arising out of or in connection with the performance of this Agreement shall be settled through negotiations and if the dispute cannot be settled through negotiation, the parties agree first to try in good faith to settle the dispute by mediation administered by the American Arbitration under its Commercial Mediation Procedures before resorting to arbitration, litigation, or some other dispute resolution procedure. In the event if the dispute is settled via arbitration, the number of arbitrators shall be one (1), the language of the arbitration shall be English and the arbitral award shall be final and binding upon parties.',
  contentCorporate:
    'This Agreement shall be governed by, and shall be construed in accordance with, the laws of Singapore. All disputes arising out of or in connection with the performance of this Agreement shall be settled through friendly negotiations. If the parties are unable to resolve any such dispute within thirty (30) days after the commencement of negotiations, the parties agree that the dispute shall be settled by arbitration in Singapore under the Singapore International Arbitration Centre (“<b>SIAC</b>”) administered by SIAC Arbitration Rules in force when the notice of arbitration is submitted in accordance with the rules. The number of arbitrators shall be one (1). The decision of the arbitrator shall, except in the case of manifest error, be final and binding upon the parties hereto. The arbitration proceedings shall be conducted in English.',
  agreeLicense: 'I AGREE to abide by all the Terms and Conditions above',
  btnAgree: 'AGREE',
  btnDecline: 'DECLINE',
  messageCheckbox:
    'Please read the License Agreement above carefully and signify your agreement by clicking the "I AGREE" check box.',
  // collection search result page
  searchCollection: '搜索收藏',
  personal: '個人',
  groups: '團體',
  createNew: '建立新的',
  createNewCollection: '創建一個新集合',
  descriptionPlaceholder: 'Add an optional description for your collection here.',
  titleItem: '標題',
  selectGroup: '選擇組別',
  description: '描述',
  charactersRemaining: '剩餘180個字元',
  cancel: '取消',
  create: '創造',
  or: '或',
  defaultCollection: '收藏',
  addToMyCollections: '加入收藏夾',
  addedToCollection: '添加到集合中',
  removedFromCollection: '從收藏中刪除',
  collectionModalPlaceholder: '舉例：大自然',
  groupNameShowsHere: '組別名稱顯示在這邊',
  // like box
  btnViewCollection: 'View Collection',
  shareWithMe: '與我分享',
  addLikebox: 'Add New Likebox',
  notImageLikeBox: 'You do not have any images in your likebox yet',
  owner: '擁有者',
  images: '圖像',
  likeboxName: 'Likebox Name',
  likboxNameMaxLength50: 'The maximum characters allowed is 50',
  addLikeBoxTo: 'Add likebox to',
  personalLikebox: '個人的',
  btAdd: 'ADD',
  btCancel: 'CANCEL',
  btSave: 'SAVE',
  editLikebox: 'Edit Likebox',
  share: '分享',
  // reset password
  resetPwdTitle2: 'Reset Password',
  noFoundLikebox: '您沒有任何收藏。',
  btnUnDo: '撤消',
  // my collection
  dateAdded: '添加日期',
  collectionName: '收藏名稱',
  selectAll: '全選',
  delete: '刪除',
  seeAll: '查看全部',
  deleteCollection: '刪除收藏',
  deleteWarning: '此動作將永久刪除無法回復',
  createCollection: ' 已創建',
  shareCollection: '分享收藏',
  shareWithTeam: 'Share in team',
  selectUsers: '選擇用戶',
  searchUserName: '搜尋用戶名稱',
  copy: '拷貝',
  titleCopyModal: '複製所選項目到',
  collectionUppercase: '收藏',
  groupCollection: '小組',
  moveImage: '移動所選項目到',
  defaultSelect: '收藏名稱在這裡',
  moveLikebox: 'Move',
  messsageDeleteLikebox: '此動作將永久刪除無法回復',
  areYouSure: '你確定嗎？',
  editCollection: '編輯收藏',
  collectionNameUpper: '收藏名稱',
  deleteSuccessful: 'has been successfully deleted',
  edit: '編輯',
  messageRemoveImage: '你確定要刪除所選項目?',
  titleRemove: '刪除所選項目',
  popupActionMove: ' item(s) has been moved to ',
  popupActionCopy: ' item(s)has been copied to ',
  popupActionRemove: ' item(s) has been removed from this Collection',
  popupFailed: 'Image already exists',
  popupShared: 'Your collection has been successfully shared',
  popupActionDelete: 'Your collection has been successfully removed',
  popupActionEdit: 'Your edit has been successfully updated',
  btnSave: '儲存',
  shareLikebox: '與我分享',
  remove: '刪除',
  moveTo: '移動到',
  createNewPlaceholder: '舉例：大自然',
  shareViaEmail: '透過電子郵件分享',
  sharePublicly: 'Share publicly',
  receipnetEmail: '收件者的電子郵件',
  message: '訊息',
  insertReceipmentEmail: '輸入收件人電子郵件',
  messageText: '文字訊息',
  exportCollection: '匯出收藏',
  pdf: 'PDF',
  csv: 'CSV',
  addNewCollection: '添加新收藏',
  likeboxEditSuccessful: 'You have successfully made changes to your collection.',
  format: '格式',
  lowCredit: '低點數',
  downloadSuccess: '謝謝你的下載，檔案下載中。',
  downloadFailed: '下載錯誤: 錯誤已被修正, 請重新試試看。',
  downloadedLabel: '下載記錄',
  any: '任何',
  resetFilter: '重設過濾條件',
  enterCustomFieldTag: 'Enter custom field tags',
  // Portfolio
  follow: '追蹤',
  name: '名字',
  location: '地點',
  since: '來自於',

  // 我的帳戶
  updated: 'Update',
  userName: '用戶名稱',
  firstName: '姓',
  lastName: '名',
  myProfile: 'My Profile',
  changeProfiledetail: '更改個人資料',
  userPrivileges: 'User Privileges',
  viewAllDownload: 'View all download history',
  viewDownloadCost: 'View download cost',
  accountManagerInfo: 'Account manager info',
  describeAccountmanager: 'Describe what this account manager do',
  creditsPlan: 'Credits plan',
  myPlan: '我的購買計劃',
  myOrganisation: 'My organisation plan',
  myAccountProfileDetails:
    'This are your profile details set by the organisation admin. If you wish to change your credentials, kindly contact admin.',
  usePayLater: '現在使用之後付款',
  bodyUNPL: '使用分期付款(UNPL)',
  firstnameCannotEmpty: 'First name cannot be empty.',
  lastnameCannotEmpty: 'Lastname cannot be empty.',

  noSpecialCharacters: 'This field cannot contain special characters',
  messageUpdateProfile: 'You had successfully updated the user information',
  bodyCredit:
    'Vitae morbi tristique luctus sed eget donec nibh in duis neque donec lectus turpis turpis aliquam facilisis mattis laoreet enim',
  contactYourAccount: 'Contact your account manager for further assistane',
  emailMaxLength75: 'The maximum characters allowed is 75',

  // audio player
  categoryGenre: '類別 / 種類',
  duration: '長度',
  seeMore: '顯示更多',
  seeLess: '顯示更少',
  audioID: '音檔 ID',
  // category
  landscape: '景色',
  Babies: '婴儿',
  business: '商业',
  food: '食物',
  animals: '动物',
  technology: '动物',
  healthcare: '医疗',
  nature: '自然',
  romance: '浪漫',
  // category vector
  graphic: '繪圖',
  advertising: '繪圖',
  flower: '花',
  abstract: '抽象',
  Illustration: '插圖',
  pop: '流行音樂',
  bird: '鳥',
  pixel: '像素',
  threeD: '3D',

  // footage category
  education: '教育',
  communication: '通訊',
  beauty: '美女',
  concept: '概念',
  fitness: '健身',
  lifestyle: '生活方式',
  celebration: '慶典',
  family: '家庭',
  fashion: '時尚',

  // Download-history modal
  reDownload: '重新下载',
  chooseCustomTagOrCreate: '選擇標記或是創建標記',
  downloadDetails: '下載資訊',
  image: 'Image',
  downloadType: '下載類型',
  groupName: '組別名稱',
  size: '大小',
  dateDownloaded: '下載日期',
  reLicense: '重新許可',
  customFields: '自訂格式',
  saveAndClose: '儲存與關閉',
  characterMaxChar100: 'The maximum characters allowed is 100',
  characterNotAllow:
    'The maximum characters allowed is 100 and the minimum characters allowed is 1',
  dlwByMember: 'Dowloaded By Members',
  groupInactive: 'Group inactive',
  personalInactive: 'Personal inactive',
  date: '日期',
  export: '匯出',
  selectRange: 'select range',
  fileType: 'file type',
  downloadedLabelUNPL: 'Licensed',
  HRUCTab: '免費素材',
  searchById: 'ID搜尋',
  pleaseSelect: '請選擇',
  totalOutstanding: '總餘額',
  viewInvoices: '相關發票詳情',
  // Image detail page - select download size sidebar
  standardSizes: '標準尺寸',
  extendedLicenseSizes: 'Extended License Sizes',
  crop: '剪裁',
  // empty data
  opsNothingToSeeHere: '唷！這裡沒有東西',
  // search image
  authentic: '真實圖片',
  // search audio
  mostDownloaded: '最多下載',
  download: '下載',
  // audio category
  soulAndRB: '靈魂R&B',
  corporate: '企業',
  electronica: '電子',
  classical: '古典',
  funkAndGrooveMusic: 'Funk & Groove',
  worldBeat: '節奏音樂',
  cinematic: '電影',
  jazz: '爵士',
  flamenco: 'Flamenco',
  mothersDayLove: 'Mothers Day Love',
  happyUplifting: 'Happy Uplifting',
  epicHeroAdventure: 'Epic Hero Adventure',

  // search page
  nextPage: '下一頁',
  reverseImageSearch: '回頭搜尋圖片',

  group: '小組',
  needHelp: '需要幫忙？',
  copied: '成功複製',
  copyPortfolioLinkSuccess: '貢獻者作品集鏈接複製到剪貼板。',

  // my activities
  myActivity: '我的活動',
  recentLike: '我最近的喜愛',
  recentView: '我最近的資料',
  myActivityViewDesc: '查詢近三個月的歷史紀錄',
  myActivityLikeDesc: '只顯示三個月的歷史資料',

  // dashboard
  profile: '檔案',

  // Pricing
  pricingCreditText: '點數',

  // Dashboard - Credit Usage
  user: 'User',
  creditUsage: 'Credit usage',
  searchPlaceholder: 'Search name, username, role, group',
  all: '全部',
  active: 'Active',
  deleted: 'Deleted',
  reset: '重置',
  totalAllocated: 'Total allocated',
  totalSpent: 'Total spent',
  balance: 'Balance',
  groupLowerCase: 'group',
  roleDefinitions: 'Role definitions',
  superAdmin: 'Super admin',
  admin: 'Admin',
  historySearchPlaceholder: 'Search history',
  somethingWentWrong: 'Something went wrong',
  information: 'Information',
  maximumBulkDownloadWarning: 'Bulk download allows maximum 50 items',
  tooltipCustomLogin: 'Please use at least 8 characters',
  maxLengthTitleCustom: 'Title should not be more than 25 characters',
  maxLengthDescCustom: 'Description should not be more than 50 characters',
  selectdItems: 'Selected media',
  aiPoweredSearch: '人工智慧搜索引擎',
  aiPoweredSearchOn: 'AI搜尋功能開啟',
  aiSearchIntroduction: '嘗試更快，更強大的搜索引擎。我們的AI搜索將照顧其餘的！',
  ok: 'OK',
  // Filter bar ai

  bstract: '摘要',
  macroCloseUp: '宏觀/特冩',
  object: '對象',
  watercolor: '水彩畫',
  flat: '平麵',
  cartoon: '卡通',
  geometric: '幾何圖形',
  gradient: '梯度',
  isometric: '等距',
  '3d': '3d',
  handDrawn: '手繪的',
  natural: '自然的',
  warm: '暖色調',
  dramatic: '戲劇性的',
  vividAndBold: '生動和大膽',
  blackAndWhite: '黑與白',
  // introduce Free image research
  imageResearchContentTitle: '介紹123RF的免費圖片搜尋服務：完美圖片，零困擾',
  imageResearchContentDesc:
    '我們為企業客戶革新圖片搜尋，使您毫不費力地找到完美的圖片，並將您的願景實現。',
  submitRequest: '提交請求',
  imageResearchContentInfoTitle1: '簡化圖片搜尋',
  imageResearchContentInfoDesc1:
    '開始很簡單。只需提供您想要的圖片的詳細描述，包括風格、情緒、顏色和特定元素。您提供的細節越多，我們就能越好地滿足您的需求。',
  imageResearchContentInfoTitle2: '它是如何運作的？',
  imageResearchContentInfoTitle3: '快速可靠的服務',
  imageResearchContentInfoTitle4: '讓我們為您做這項工作',
  imageResearchContentInfoDesc4:
    '我們為您找到完美的圖片，讓您可以專注於您的專案。信任我們以高品質，精心挑選的圖片將您的願景實現。',
  requestForm: '請求表格',
  whatDoYouWantThisImageFor: '您想要將這張圖片用於什麼？',
  websiteBanner: '網站橫幅',
  socialMedia: '社交媒體',
  blogPost: '博客文章',
  advertisement: '廣告',
  print: '印刷',
  others: '其他',
  othersOutputPlaceholder: '輸入其他類型的輸出',
  whatIsPrimaryMessage: '您希望圖片傳達的主要信息或主題是什麼？',
  whatIsPrimaryMessagePlaceholder: '例如：公園裡的女人和毛茸茸的狗一起奔跑',
  areThereSubjectYouWantInThePicture: '有沒有你想要包含在圖片中的特定主題或元素？',
  egPeopleNatureTechnologyAbstract: '例如：人們，自然，技術，抽象概念',
  areThereImageOrStyleReference: '有沒有你以前看過的圖像或風格，你想讓我們用來作為靈感或參考？',
  ifPeopleIncludeWhatTheirDemographic: '如果要包括人物，他們的人口統計資訊應該是什麼？',
  egAgeGenderEthnicityOccupation: '例如：年齡，性別，種族，職業',
  areThereAnyThingToAvoid: '有沒有你想要在圖片中避免的元素，主題或主題？',
  whoisYourTargetAudience: '誰是你的目標受眾？',
  whoisYourTargetAudiencePlaceholder: '例如：喜歡閱讀的年輕成人',
  typeOfImage: '圖像類型',
  whatMoodShouldImageEvoke: '圖片應該喚起什麼情緒或情緒？',
  happy: '快樂',
  sad: '傷心',
  calm: '冷靜',
  excited: '興奮',
  angry: '生氣',
  enterCustomMood: '輸入自定義心情',
  areThereAnyOrientationForImage: '圖片需要特定的方向嗎？',
  enterCustomOrientation: '輸入自定義方向',
  isThereLocationYouWantImageToBeIn: '您希望圖片位於特定位置或設置嗎？',
  no: '不',
  yes: '是',
  shareWithUsAReference: '與我們分享一個參考（僅JPG或PDF。最大4MB）',
  replace: '替換',
  areThereAnySpecificRequirements: '有其他具體要求或偏好嗎？',
  enterOtherComments: '輸入其他評論',
  submit: '提交',
  fieldCannotEmpty: '此欄位不能為空',
  selectAtLeastOne: '請至少選擇一個',
  fileMustBeJpgOrPdf: '文件必須是JPG或PDF',
  fileSizeLessThan4MB: '文件大小必須小於4MB',
  maximumFileNameAllow255: '允許的最大字符數為255',
  updateSuccessfully: '我們已收到您的請求。',
  freeImageResearch: '免費圖片搜尋',
  newService: '新服務！',
  freeImageResearchIntroduction: '嘗試我們的圖片搜尋服務，看看為什麼我們的圖片完美符合您的需求。',
  later: '以後再說',
  letTryIt: '讓我們來試試吧！',
  viewPdfOfSampleOutput: '查看範例輸出的PDF文件',
  imageResearchContentInfoDesc2: (
    <>
      我們的團隊結合
      <strong>AI技術</strong>
      和經驗，為您找到理想的圖片，並基於您的輸入，從我們龐大的圖書館中縮小選擇。我們
      <strong>專門且經驗豐富的研究人員</strong>
      會精細調整結果，以完美滿足您的標準。
    </>
  ),
  imageResearchContentInfoDesc3: (
    <>
      我們在
      <strong>两個工作日</strong>
      內提供結果，並為圖片選擇提供全面解釋。專注於您的業務，我們會照顧剩下的。
    </>
  ),
  characterMaxChar255: '允許的最大字符數為255',

  imageResearchContentInfoDesc2Normal1: 'Our team combines',
  imageResearchContentInfoDesc2Strong1: 'AI technology',
  imageResearchContentInfoDesc2Normal2:
    'and experience to find ideal images for you and advanced algorithms narrow down options from our vast library based on your input. Our',
  imageResearchContentInfoDesc2Strong2: 'team of dedicated and experienced researchers',
  imageResearchContentInfoDesc2Normal3: 'refine the results to meet your criteria perfectly.',
  imageResearchContentInfoDesc3Normal1: 'We deliver results within',
  imageResearchContentInfoDesc3Strong1: '1 working day',
  imageResearchContentInfoDesc3Normal2:
    'providing a comprehensive explanation for image selections. Focus on your business while we take care of the rest.',
  office: 'Office',
  beach: 'Beach',
  city: 'City',
  mountains: 'Mountains',
  enterCustomLocation: 'Enter custom location',
  doYouNeedImgWithSpaceForTextOrOtherElement:
    'Do you need an image with space for text or other elements? If so, where should this space be located?',
  center: 'Center',
  topLeft: 'Top left',
  topRight: 'Top Right',
  bottomLeft: 'Bottom Left',
  bottomRight: 'Bottom Right',
  characterMin30: 'A minimum of 30 characters is required',
  required: '標籤是必須的',

  relatedSearch: 'Related searches',
  showMore: '显示更多',
  showLess: '显示更少',

  requiredWithVariable: '{{var}} 是必須的',
  mood: '心情',
  person: '人',
  doNotIncludeWords_ex2: '例如，綠色，直髮',

  // Text to Image TTI page
  /// Landing page
  aiImageGenerator: '人工智能圖像生成器',
  aiImageGeneratorDesc: '以你的想像力的速度提供創造力。',
  generate: '產生',
  exploreVisualsCreatedWithThePowerOfAI: '探索用AI的力量創建的視覺效果',
  turnYourImaginationIntoRealityIn3SimpleSteps: '通過3個簡單的步驟將你的想像力變成現實',
  startWithGoodTextPrompt: (
    <>
      從
      {' '}
      <span>
        一個好的
        <br />
        提示開始
      </span>
    </>
  ),
  startWithGoodTextPromptDesc:
    '一個好的提示可以保證很好的結果。一個提示應該包括視角、主題、動作、關鍵詞、燈光和風格，以獲得最佳效果。',
  reviewYourAIGeneratedImages: (
    <>
      審查你的
      {' '}
      <br />
      <span>AI生成的圖像</span>
    </>
  ),
  reviewYourAIGeneratedImagesDesc: '我們的AI發電機將生成並顯示3個與您的提示最匹配的預覽圖像。',
  downloadAndLicenseVisuals: (
    <>
      下載和許可
      {' '}
      <br />
      <span>視覺效果</span>
    </>
  ),
  downloadAndLicenseVisualsDesc:
    '您現在可以下載和許可這些圖像，以供個人和商業用途，並具有 25,000 美元的法律保障。',
  needSomeIdeas: '需要一些想法嗎？從這些樣本開始，然後再生您的想法！',
  tryPrompt: '嘗試提示',
  creativityAtTheSpeedOfYourImagination: '以你的想像力的速度提供創造力。',
  genYourOwnAIImgFromTxtPromptsWCorpInSeconds:
    '用Corporate+在幾秒鐘內從文本提示中生成你自己的AI圖像!',
  tryNow: '現在就試試',

  /// Random prompt
  prompt1:
    '漂浮在太空中的宇航員的照片寫實，背景是地球。使用帶有200毫米鏡頭的相機，在F 1.2光圈設置下，模糊背景並隔離主體。照明應該是戲劇性的和夢幻般的，太陽照在宇航員的臉上和太空服上。重點是打開逼真模式，創造一個超現實的圖像，捕捉太空的浩瀚和美麗。',
  prompt2:
    '一件混合媒體作品，作品中的施法者擺出了戲劇性的姿勢，飄逸的斗篷和散發著魔法火花的魔杖，背景是神秘的異世界景觀，有各種紋理和材料。',
  prompt3:
    '一幅俏皮的數字插圖，一個臉色紅潤、穿著蓬鬆連衣裙的嬰兒，被五顏六色的氣球和彩紙包圍，背景是歡快的粉色。',
  prompt4:
    '一幅石墨畫，畫的是一個額頭上有第三隻眼睛的盲人預言家，以寧靜和神秘的表情凝視著遠方，有復雜的細節和底紋',
  prompt5:
    '一幅超現實主義的混合媒體拼貼畫，一個牛仔在沙漠荒地上騎著一頭巨大的機械公牛，由複古雜誌、舊地圖和生鏽的金屬碎片組成，帶有幽默和荒誕的味道。',
  prompt6: '吸血鬼女王的數碼插圖，皮膚蒼白，眼睛血紅，穿著飄逸的裙子，周圍有蝙蝠。',
  prompt7:
    '一幅盲人預言家手持水晶球的肖像，在戲劇性的明暗照明下，突出了預言家的面部特徵和水晶的反射。',
  prompt8: '一幅幻想的插圖，一個長發和貝殼皇冠的美人魚女王，在充滿生物發光的神秘海洋中游泳。',
  prompt9:
    '一個賣水果和蔬菜的西班牙裔街頭小販的街頭肖像，以坦率和狡黠的黑白照片拍攝，用淺景深來強調小販的面部表情。',
  prompt10:
    '一幅異想天開的水彩畫，畫中的美人魚躺在岩石岸邊，周圍是閃閃發光的海浪和俏皮的海洋生物。',
  prompt11:
    '3d風格的小女孩, 4k, 8k, octane render逼真, hdr, 攝影, 高清晰度, 對稱的臉, 體積照明, 灰霧, 照片, octane render, 24mm, 4k, 24mm, DSLR, 高質量, 60 fps, 超逼真',
  prompt12:
    '帶法式窗戶的客廳，可通向花園，普羅旺斯風格的餐具櫃，植物，黃麻地毯，鑲木地板，上面有坐墊的沙發，水晶吊燈，室內設計雜誌，溫暖的氣氛，逼真的，現實的光線，廣角，許多細節',
  prompt13:
    '山坡上風景如畫的山間小屋，大型全景窗，幾何小屋，全貌，黃昏時分的風景，戲劇性的黃昏天空，雪下的山峰，星星，雪山曠野，美麗的景色，情緒化，霧，體積照明，山的氣氛，逼真，體積照明，現實，辛烷渲染，超細節，超現實主義，逼真，超現實，8K，電影照明',
  prompt14: '一個很酷的奶奶的水彩畫，她在笑，因為她知道自己很酷',
  prompt15: '在深藍色森林中間的道路，茂密的森林，葉子的天花板，在晚上，漫畫風格，兒童的插圖',
  prompt16:
    '一個宇航員在太空中的星雲中游泳+空間，宇航員，夢幻般的，象徵主義，超現實主義，符號，超現實，抽象，紋理，概念藝術，8K，陰影，光線，大氣',

  /// Tool list
  // ratio
  ratio: '比率',
  square1_1: '正方形 (1:1)',
  square: '正方形',
  portrait2_3: '肖像 (2:3)',
  portrait: '肖像',
  landscape3_2: '景觀(3:2)',
  wide16_9: '寬 (16:9)',
  wide: '寬',
  // style
  none: '沒有任何',
  enhance: '提高',
  anime: '日本動畫片',
  photographic: '攝影',
  digitalArt: '數字藝術',
  comicBook: '漫畫書',
  fantasyArt: '幻想藝術',
  analogFilm: '模擬',
  neonPunk: '霓虹燈朋克',
  lowPoly: '低聚',
  origami: '摺紙',
  lineArt: '線條藝術',
  tileTexture: '瓷磚紋理',
  threeDModel: '3D模型',
  pixelArt: '像素藝術',
  craftClay: '手工粘土',
  // color
  coolTone: '酷音',
  mutedColors: '柔和的顏色',
  pastelColors: '柔和的顏色',
  vibrantColors: '鮮豔的顏色',
  warmTone: '溫暖的語氣',
  // lighting
  lighting: '燈光',
  backlight: '背光',
  crepuscularRays: '側面射線',
  dimlyLit: '昏暗',
  goldenHour: '黃金時光',
  lowLight: '弱光',
  rimLighting: '輪輞照明',
  studio: '工作室',
  sunlight: '陽光',
  volumetric: '體積',
  // composition
  composition: '作品',
  blurryBackground: '模糊背景',
  closeUp: '特寫',
  macrophotograph: '巨型攝影',
  narrowDepth: '深度狹窄',
  shotFromAbove: '從上方拍攝',
  shotFromBelow: '從下方拍攝',
  wideAngle: '廣角',

  /// FQAs
  allAIImageQuestionsAnswered: '您所有的AI生成的圖像問題都回答了。',
  TTI_Q1: '什麼是AI生成的圖像？',
  TTI_A1:
    '人工智能生成的圖像是使用人工智能（AI）算法創建的圖像。這些算法旨在模擬人類的創造力，可以生成類似於人類藝術家製作的圖像，但具有獨特的、通常是超現實的或抽象的元素。',
  TTI_Q2: '我每天能生成多少張圖片有限制嗎？',
  TTI_A2:
    '是的，您每天能夠生成的圖像數量是有限制的。這個限制確保所有用戶在使用人工智能圖像生成服務時都能公平地使用，並獲得良好的用戶體驗。',
  TTI_Q3: 'Do you support text prompts in languages other than English?',
  TTI_A3: 'We support only the English language at the moment.',
  TTI_Q4: '生成圖片和授權圖片之間有什麼區別？',
  TTI_A4:
    '生成圖像時，您會創建一組用於查看的AI圖像。在Corporate+上許可它們，您將正式獲得訪問權限，可下載高分辨率版本的AI圖像，並附帶其自己的使用許可證。',
  TTI_Q5: '我生成的圖像包含一個可識別的商標、地標或公眾人物。我還可以使用它嗎？',
  TTI_A5:
    '如果它包含可識別的人物或物體，或者主要集中於特殊地標，您將需要直接從圖像中描繪的當事方獲得許可，尤其是當您打算在商業環境中使用該圖像時。',
  TTI_Q6: '在生成的圖像中出現的人是誰？',
  TTI_A6: '出現在人工智能生成的圖像中的人並不是真實的人，而是數字創造的。',
  TTI_Q7: '我生成的內容會怎樣？它們是否會被提供給其他用戶？',
  TTI_A7: '你產生的內容也可能被提供給其他用戶使用。你可以參考我們的許可證以了解更多信息。',
  TTI_Q8: '為什麼當我嘗試生成圖像時，需要花費一段時間來加載？',
  TTI_A8:
    '該頁面將需要幾秒鐘來生成圖像。所需時間取決於提示的複雜性、可用的硬件和計算資源，以及人工智能必須同時處理的請求量。',
  TTI_Q9: '我如何許可我生成的AI圖像？',
  TTI_A9:
    '您可以從Corporate+帳戶下載您創建的AI圖像，從而進行許可。每次下載時，您都將收到生成圖像的高分辨率版本和25,000美元的法律保證。',
  TTI_Q10: '我可以查看過去的幾代人嗎？',
  TTI_A10:
    '是的，你可以通過將過去幾代人的網址加入書籤並重新瀏覽。你也可以與你的朋友分享各代的結果。',
  TTI_Q11: '如何用人工智能生成高質量的圖像？',
  TTI_A11: `1. 開始時要有一個明確的目標："為旅遊博客提供高質量的山地景觀圖片"。

2. 指定所需的風格和情緒： "圖像應該是明亮的，充滿活力的，具有自然、有機的感覺。 "

3. 描述主題事項： 具體說明圖像的主題，包括細節，如環境、燈光和任何其他重要特徵或元素。例如，"以一座白雪皚皚的山峰為特色，前景是蜿蜒的河流，背景是清澈的藍天"。

4. 提供例子： 包括其他與你正在尋找的圖片具有類似風格或情緒的例子。這可以幫助指導人工智能算法，確保輸出結果符合你的期望。

5. 使用相關的關鍵詞： "山地景觀"、"白雪皚皚的山峰 "或 "明亮而富有活力"。

6. 避免模棱兩可： 在提供指示時要盡可能清楚和具體，避免使用模糊或模棱兩可的語言，以免導致意外的結果。`,

  /// Generating page
  bringingYourImagesToLife: '將你的圖像帶入生活...',
  cplusAiGenerator: 'AI生成器',
  generatedImages: '生成的圖像',
  promptSettings: '提示設置',
  pastGeneration: '過去幾代',
  generateVariation: '產生變化',
  iLikeThis: '我喜歡這個',
  iDislikeThis: '我不喜歡這個',
  shareOptions: '分享選項',
  variations: '變化',
  copiedSharedUrl: '複製的共享URL到剪貼板',

  galleryPrompt1: '水下的飄逸的美人魚，頭戴貝殼冠，四周環繞著魚群，肖像',
  galleryPrompt2: '桌上的豐盛周日烤肉，滿是食物與蠟燭',
  galleryPrompt3: '在森林中微笑的可愛蓬鬆狐狸，細節豐富，與其他森林動物一起',
  galleryPrompt4: '高品質，8K超高清，水晶製南瓜內的音符樂器形狀，高度細節，魔法南瓜燈，秋天感覺，平靜',
  galleryPrompt5: '蘇格蘭全景電影風景，明亮美好的一天',
  galleryPrompt6: '3D風格小女孩，4k、8k，Octane Render照片寫實，HDR，攝影，高清晰度，對稱臉孔，體積光，灰塵霾，照片，Octane Render，24mm，4k，DSLR，高品質，60 fps，超真實',
  galleryPrompt7: '深色膚色男子，英雄在太空中打擊木星，面向前方',
  galleryPrompt8: '溫暖咖啡中的曲奇，在聖誕環境中',
  galleryPrompt9: '藝術水瓶放在白色簡約背景上，配以 Montserra 葉子',
  galleryPrompt10: '絢爛鑽石幻龍',
  galleryPrompt11: '露珠中的行星，行星露珠，在日出時，特寫，超高細節，銳利焦點，工作室照片，複雜細節，高度細緻',
  galleryPrompt12: '一隻喜鵲坐在一個裝滿黃金的箱子上，風格是寫實幻想藝術，寶石，黑暗童話，神秘。',
  galleryPrompt13: '一座雪山和山頂上的一條冰道朝著相反方向走，超高清，真實感，色彩鮮豔，高度細緻，UHD繪畫，筆和墨，完美構圖，美麗的詳細複雜度瘋狂的詳細八景渲染，流行於artstation，8k藝術攝影，超現實概念藝術，柔和自然的體積感電影般完美光線',
  galleryPrompt14: '星際文明時代，高高站立俯瞰神秘數位空間：超科幻外星城市建築物，精美美麗的超級摩天大樓外觀，街道，天空橋樑，天空中漂浮的超科幻建築，戰爭機器，高科技，無數電子螢幕燈光',
  galleryPrompt15: '佛羅里達群島夜晚背景為海洋的美麗海灘，霓虹色彩，黑色背景，平靜波浪，自然色調，日落，平靜，放鬆的波浪變焦，海灘，直線地平線，粉紅色，藍色，橙色，紫色',
  galleryPrompt16: '最佳數量，雙重曝光，郵件藝術，破紙藝術，一隻可愛的小貓玩線球的特寫細節舒適的室內，閃閃發光，陽光，陽光束，動態姿勢，故事書插圖，2D，平面，可愛，迷人，復古，童話，拼布，彩色玻璃，故事書詳細插圖，電影，超高解析度，微小細節，美麗細節，神秘，鮮豔色彩，複雜背景',
  galleryPrompt17: '在水上的金字塔，帶有火反射，山脈，黑暗超現實主義，大氣肖像，敏銳的焦點',
  galleryPrompt18: '森林裡的紅熊貓，電影',
  galleryPrompt19: '海，月亮，魚，星空，船，五顏六色的插圖',
  galleryPrompt20:
    '充滿活力的城市反射舞蹈崇拜者大膽的建築物和微妙的抽像風格的閃閃發光的水，捕捉活潑的夜晚能量，幾何抽象，現代波普藝術風格，帶有霓虹燈照明和丙烯酸純化技術',
  galleryPrompt21: '帶有發光劍的輪廓女人的背景，北極的圓形門戶，通往冰山，電影中型鏡頭，巨大的身材',

  /// error page
  copyrightDetected: '檢測到受版權保護的詞語/人物或商標',
  copyrightDetectedDesc:
    '我們致力於成為一個負責任的AI生成器，並且不會為提示中的受版權保護的商標、術語、品牌或人物生成結果，以防止潛在的侵權行為。我們的服務條款規定您不得以侵犯、擅自使用或違反任何知識產權的方式使用本服務。請修改您的提示並再試一次。',
  errorMesTTI: '糟糕! 我們很抱歉，但出了點問題。',
  errorMesTTIDesc: '該問題已被記錄下來供調查。請稍後再試。',
  tryAgain: '再試一次',
  // hruc bulk download
  hrucBulkDownloadTitle: 'HRUC bulk download',
  hrucDownload: 'HRUC download',
  hrucBulkdownloadNotSupported: 'HRUC bulk download is not supported',
  // download feature
  downloadQuotaFinished: '下載配額已經用完',

  // search bar placeholder
  searchBarPlaceholderAllImageDesktop: '找到驚人的圖片。按關鍵字或短語搜索。',
  searchBarPlaceholderAllImageTablet: '找到驚人的圖片。',
  searchBarPlaceholderAllImageMobile: '找到圖片。',
  searchBarPlaceholderPhotoDesktop: '找到驚人的照片。按關鍵字或短語搜索。',
  searchBarPlaceholderPhotoTablet: '找到驚人的照片。',
  searchBarPlaceholderPhotoMobile: '找到照片。',
  searchBarPlaceholderVectorDesktop: '找到驚人的向量。按關鍵字或短語搜索。',
  searchBarPlaceholderVectorTablet: '找到驚人的向量。',
  searchBarPlaceholderVectorMobile: '找到向量。',
  searchBarPlaceholderFootageDesktop: '找到驚人的影像素材。按關鍵字或短語搜索。',
  searchBarPlaceholderFootageTablet: '找到驚人的影像素材。',
  searchBarPlaceholderFootageMobile: '找到影像素材。',
  searchBarPlaceholderAudioDesktop:
    '找到驚人的音頻和音樂文件。按關鍵字或短語搜索。',
  searchBarPlaceholderAudioTablet: '找到驚人的音頻和音樂文件。',
  searchBarPlaceholderAudioMobile: '找到音頻和音樂文件。',
  searchBarPlaceholderFreeDesktop: '找到驚人的免費圖像。按關鍵字或短語搜索。',
  searchBarPlaceholderFreeTablet: '找到驚人的免費圖像。',
  searchBarPlaceholderFreeMobile: '找到免費圖像。',
  // editorial warning
  forEditorialUseOnly: '僅供編輯使用',
  forEditorialUseOnlyDesc:
    '除非有額外的許可，否則這圖像時不被允許用於商業用途。',

  // download comp
  downloadHiResComp: '下載高解析度做稿圖',
  downloadComp: '下載小樣圖',

  generateTerm: ({ callback }) => (
    <>
      By clicking “Generate”, you hereby agree that the generated Output is solely governed by the
      {' '}
      <span aria-hidden onClick={callback}>
        AI Generator Terms of Service.
      </span>
      {' '}
    </>
  ),
  textToImage: '文本到圖像',
  descTourTTI: '讓你的想像力飛揚，用文字創造圖像!',
  colorAndTone: '顏色和色調',
  titleSuccessDownload: '我們很高興你找到了你需要的東西。',
  hangInThere: '挺住！挺住 它不會花很長時間。',
  adultContentTitle: '檢測到的潛在的成人內容術語',
  adultContentDesc:
    '我們致力於負責任的 AI，因此不會為特定詞語、概念和/或想法生成結果。請修改您的提示（用於生成圖像的描述）並重試。',
  reachedTheLimit: '哦，不！你已經達到了今天生成AI圖片的上限。',
  backTomorrow: '明天再來看看更多世代的人吧!',
  generateTermService: 'AI Generator Terms of Service',
  endUserLicenseAgreement: 'End user license agreement',
  aiPromptPlaceholder: '開始吧，請在這裡描述您的願景，我們將為您實現！',

  downloadAgain: 'Download again',
  btnClose: '關閉',

  variousTitle: '發現我們的各種模型',
  variousSDXL: '將令人驚嘆的逼真的人和場景帶入生活。',
  variousEimisAnime: '創作動漫風格的生成式AI模型圖像。',
  variousDynaVisionXL: '生成3D風格化的卡通人物。',
  variousJuggernautXL: '以建築元素和風景/背景為中心。',
  variousDreamShaperXL: '高分辨率升級，改進的角色和龍，減少了邊緣模糊。',
  variousRealismEngineSDXL: '這個模型專門於高度詳細、逼真的圖像。',
  variousDALLE: 'DALL·E 3，一個全圓形模型以精確的概念與視覺轉換為出色。',
  aiEngine: 'AI引擎',
  'DALL-E': 'DALL-E 3',

  langText: '文本',
  langElements: '元素',
  langAuto: '自動',
  langBgRemoval: '清除背景',
  resetAll: '重置所有',
  facebookImage: 'Facebook圖片',
  facebookLink: 'Facebook連結',
  facebookCover: 'Facebook封面',
  facebookStory: 'Facebook的故事',
  instagramPost: 'Instagram文章',
  instagramStory: 'Instagram的故事',
  twitterPost: 'Twitter的文章',
  twitterHeader: 'Twitter標題',
  pinterestPost: 'Pinterest的文章',
  emailHeader: '電子郵件標題',
  presentation: '介紹',
  presentationWide: '演示範圍廣',
  ebookCover: '電子書封面',
  linkedinPost: 'LinkedIn 文章',
  brochureCover: '手冊封面（A4）',
  postcard: '明信片',
  photoPrint: '照片印刷',
  youtubeProfileImage: 'YouTube個人資料圖片',
  youtubeChannelCoverPhoto: 'YouTube頻道封面照片',
  langApplyBgRemoval: '去除背景',
  autoBnW: '黑白平衡',
  autoAdjust: '自動優化',
  autoPop: '自動鮮明',
  original: '原始圖片',
  addHeader: '添加標題',
  addTitle: '添加標題',
  addBody: '添加文字',
  addAssistive: '添加輔助文本',
  langCompare: '比較',
  langEnableEditor: '編輯圖片',
  langSimilarStock: '類似的圖片',
  langTextUndo: '撤消',
  langTextRedo: '重做',
  langTextFont: '字體',
  langTextFontSize: '字體大小',
  langTextColor: '文字顏色',
  langTextHighlight: '強調',
  langTextAlignment: '对齐',
  langTextBold: '粗體',
  langTextItalic: '斜體',
  langTextUnderline: '下劃線',
  langTextLineSpacing: '間距',
  langTextLetterSpacing: '字符間距',
  langBringForward: '前移',
  langBringBackward: '后移',
  langDuplicate: '複製',
  langOpacity: '透明度',
  langDelete: '刪除',
  imageProperties: '圖片屬性',
  noEditCompareMsg: '沒有編輯可比較',
  stockPhoto: '版權商用圖片',
  basicShape: '基本形狀',
  chitChat: '閒聊',
  shapes: '形狀',
  elementChitChat: '元素聊天',
  organicAbstractShapes: '有機抽象形狀',
  elementShapes: '元素形狀',
  langApplyingEdit: '套用編輯...',
  langTextAllFonts: '所有字體',
  langSearchFontType: '搜索字体类型',
  editorCompareImageBeforeTitle: '之前',
  editorCompareImageAfterTitle: '之後',
  langSizePx: '像素是數位影像的基本單位。影像大小，以像素來衡量，這個決定細節程度和檔案下載大小。',
  langSizeDpi:
    '每英寸點數用來衡量列印解析度。dpi值越高表示細節越豐富。對於列印，所有圖像推薦的解析度是300 dpi。',
  cautionTTI:
    '警告：我們檢測到在提示中使用了與商標、版權、著名地標和/或個人相關的短語。請注意，您對內容的使用負有全部責任，包括確保它不違反任何適用法律或侵犯任何權利。',

  addImageReference: '添加圖片參考（可選）',
  uploadImageOnly: '僅上傳JPG或PNG。最大4MB。',
  dragAndDrop: '拖放文件或',
  errorFileSize: '此文件太大。最多允許4MB。',
  adjustScale: '調整此比例尺以生成與您上傳的圖片非常相似或不同的結果。建議範圍是7至15。',
  similar: '相似',
  different: '不同',
  uploadNew: '重新上傳',

  imageGuideGeneration: '您的圖片將在生成過程中指導您的提示。',
  notAvailableForEngine: '不適用於選定的 AI 引擎。',

  imagePromptDescribe: '首先，描述您的想法提示我們的 AI 產生的圖像。',
  fileTypeNotSp: '不支持的文件類型。',
  theFileSizeTooSmall: '文件大小太小。最少需要512px。',

  relatedSearchPhoto: '相關照片搜索',

  STOCK_PHOTO_PATH: '素材圖庫',
  STOCK_FOOTAGE_PATH: '影片素材',
  STOCK_AUDIO_PATH: '音效素材',
  CLIPART_VECTOR_PATH: '向量素材',
  ALL_IMAGES: '全部圖片',
  PHOTO: 'Photo',
  PHOTOS: '照片',
  VECTORS: '向量',
  FOOTAGE: '影像素材',
  AUDIO: '音檔',
  LANG_MORETHANMILLION: '張免權利金創意圖像',
  LANG_SVG_OR_EPS: 'SVG 或 EPS',
  LANG_OR: '或',
  STANDARD_LICENSE: '標準執照',
  EXTENDED_LICENSE: '擴展許可證',
  CREDIT_PACK: '點數套餐',
  DOWNLOAD_PACK: '下載配套',
  SUBSCRIPTION_PLAN: '方案',
  LANG_DOWNLOAD: '下載',
  STOCKPHOTO: '版權商用圖片',
  STOCK_PHOTO: '照片',
  STOCK_VECTOR: '向量',
  PREMIUM_STOCKPHOTO: 'PREMIUM 圖庫照片',
  PLUS_STOCK_PHOTO: 'PLUS 圖庫照片',
  PLUS_STOCK_VECTOR: 'PLUS 向量圖片',
  MOBILE_STOCKPHOTO: '手機圖片',
  STOCKFOOTAGE: '影像素材',
  IMAGEID: '圖片編號',
  FONTID: 'ID',
  MEDIATYPE: '媒體類型',
  COPYRIGHT_TEXT: '版權所屬',
  KEYWORDS: '關鍵字',
  LANG_CANCEL: '取消',
  LANG_PURCHASE: '購買',
  LANG_STOCKVECTORSANDILLUS: '向量繪圖素材',
  LANG_STOCKPHOTOSANDIMAGES: '的照片素材集',
  LANG_MATCHES: '相配',
  LANG_VECTORRESULTS: '{number} {keyword} 免版稅向量圖片的結果',
  LANG_PHOTORESULTS: '{number} {keyword} 免版稅照片/圖片的結果',
  LANG_SIMILARIMGS: '類似的免版稅照片',
  LANG_COLLECTIONS: '圖像類別',
  LANG_ALL: '全部',
  LANG_STD: '標準',
  LANG_EXTD: '擴展',
  LANG_COMMERCIAL: '商業使用',
  LANG_SUBSCRIPTION: '簽購方案',
  LANG_EDITORIAL: '新聞圖片',
  LANG_UPLOADED: 'Uploaded',
  LANG_FRESHNESS: '上傳日期',
  LANG_ANYTIME: '時間不限',
  LANG_2DAYS: '2 days',
  LANG_PAST2DAYS: '過去兩天',
  LANG_PASTWEEK: '過去1週內',
  LANG_PRICE_PLUS_MONTH: "<span class='price'>{price}</span>/月",
  LANG_PRICE_PLUS_YEAR: "<span class='price'>{price}</span>/年",
  LANG_PASTMONTH: '過去1個月內',
  LANG_PAST3MONTHS: '過去3個月內',
  LANG_NUMOFPPL: '人數',
  LANG_NOT_RECEIVE_VERIFICATION_EMAIL: '沒有收到驗證電子郵件？',
  LANG_WITHOUTPEOPLE: '無人物',
  LANG_PERSON: '人',
  LANG_PEOPLE: '人',
  LANG_INFANTS: '嬰兒',
  LANG_TODDLERS: 'Toddlers',
  LANG_TEENS: '青少年',
  LANG_20S: '20s',
  LANG_30S: '30s',
  LANG_40S: '40s',
  LANG_50S: '50s',
  LANG_60S: '60s',
  LANG_70S: '70 +',
  LANG_CAUCASIAN: '白人',
  LANG_BLACK: '黑人',
  LANG_HISPANIC: '西語裔拉丁美洲人',
  LANG_EAST_ASIAN: '東亞人',
  LANG_SOUTHEAST_ASIAN: '東南亞人',
  LANG_INDIAN: 'Indian',
  LANG_MIDDLE_EASTERN: '中東人',
  LANG_AGE: '年齡',
  LANG_ALL_SHAPE: '查看全部',
  LANG_ETHNICITY: '種族',
  LANG_PEOPLEORMORE: '4人以上',
  LANG_COLOR: '圖像色調',
  LANG_OTHEROPTION: '其它選項',
  SOLID_OR_TRANSPARENT_BACKGROUNDS: '單色/透明背景',
  LANG_FILTER_BY: '筛选条件',
  LANG_FILTER: '過濾',
  LANG_PHOTOGRAPHY: '照片',
  LANG_VECTORILLUST: '向量圖像',
  LANG_SORTBY: '排列',
  LANG_MORE: '更多',
  LANG_RELEVANCE: '貼切',
  LANG_RESEND: '重發',
  LANG_RESEND_VERIFICATION: '重新發送驗證電子郵件',
  LANG_NEWEST: '最新',
  LANG_GEORANK: 'Georank',
  LANG_ORIENTATION: '圖片形狀',
  LANG_HORIZONTAL: '橫向',
  LANG_PORTRAIT: '直立',
  LANG_SQUARE: '正方',
  LANG_PANORAMA: '週視圖',
  LANG_SELECTIVE_FOCUS: '選擇性的焦點',
  LANG_PATTERN: '樣式',
  LANG_VIBRANCE: '鮮豔',
  LANG_VERIFICATION_DESCRIPTION: '在從123rf下載內容之前，您需要驗證您的帳戶。請檢查您的電子郵件，或單擊下面提供的鏈接，如果您想重新發送它。',
  LANG_VERIFICATION_TITLE: '請驗證您的電子郵件。',
  VERIFICATIONRESETUNABLE: '哎呀! 您的電子郵件無法發送。請聯繫我們的',
  LANG_CHOOSEUPTO3COLORS: '您最多可選擇三種顏色',
  LANG_ISOLATED: '無背景',
  LANG_STYLE: '類型',
  LANG_SAFESEARCH: '安全搜尋',
  LANG_ONLY_AUTHENTIC: '僅限真實圖片',
  LANG_EXCLUDE_PLACEHOLDER: 'ex. cheese',
  LANG_EXCLUDEWORDS: '不包含這些字詞',
  LANG_EXCLUDE: '不包含字詞',
  LANG_INCLUDE_AICONTENT: '包括AI生成的內容',
  VISUAL_SEARCH: '搜尋圖片',
  VISUAL_SEARCH_TOOLTIP_TITLE: '簡單3個步驟 自訂圖像搜尋範圍 ',
  VISUAL_SEARCH_TOOLTIP_INFO_1: '1.單擊圖像區塊來標籤搜尋類別。',
  VISUAL_SEARCH_TOOLTIP_INFO_2: '2.在搜索區域移動裁剪功能框放大或縮小',
  VISUAL_SEARCH_TOOLTIP_INFO_3: '3.點擊“X”返回搜尋',
  VISUAL_SEARCH_NO_RESULTS: '您的搜尋沒有任何結果。',
  LANG_RIS_TITLE: '反向圖片搜尋',
  LANG_RIS_NOTFOUND: '未找到結果!',
  LANG_RIS_ERROR_HEADER: '上載的圖片有誤，請確保圖片符合以下標準:',
  LANG_RIS_REFINE_SRCH_MSG: '使用關鍵字檢查您的精確搜尋',
  LANG_RIS_UPLOADED_IMAGE: '已上載圖片',
  LANG_TELL_ME_MORE: '告訴我更多',
  LANG_ENTERKEYWORD: '請輸入關鍵字',
  LANG_SIZE: '尺寸',
  LANG_STANDARD_SIZE: '標準尺寸',
  LANG_EXTENDED_SIZE: '擴展尺寸',
  LANG_SIZE_PX: '像素是數位影像的基本單位。影像大小，以像素來衡量，這個決定細節程度和檔案下載大小。',
  LANG_SIZE_DPI: '每英寸點數用來衡量列印解析度。dpi值越高表示細節越豐富。對於列印，所有圖像推薦的解析度是300 dpi。',
  LANG_SOCMED_SIZE: '社群媒體大小',
  LANG_COMPARE: '比較',
  LANG_AUTO_ENHANCE: '自動增強',
  LANG_AUTO: '自動',
  LANG_BG_REMOVAL: '清除背景',
  LANG_TEXT: '文本',
  LANG_ELEMENTS: '元素',
  LANG_IMAGE_INFO: '圖片訊息',
  LANG_FOLLOW: '關注',
  LANG_FOLLOWED: '已關注',
  LANG_FOLLOWING: '以下',
  FACEBOOK_IMAGE: 'Facebook圖片',
  FACEBOOK_LINK: 'Facebook連結',
  FACEBOOK_COVER: 'Facebook封面',
  FACEBOOK_STORY: 'Facebook的故事',
  INSTAGRAM_POST: 'Instagram文章',
  INSTAGRAM_STORY: 'Instagram的故事',
  TWITTER_POST: 'Twitter的文章',
  TWITTER_HEADER: 'Twitter標題',
  PINTEREST_POST: 'Pinterest的文章',
  EMAIL_HEADER: '電子郵件標題',
  PRESENTATION: '介紹',
  PRESENTATION_WIDE: '演示範圍廣',
  EBOOK_COVER: '電子書封面',
  LINKEDIN_POST: 'LinkedIn 文章',
  BROCHURE_COVER: '手冊封面（A4）',
  POSTCARD: '明信片',
  PHOTO_PRINT: '照片印刷',
  YOUTUBE_PROFILE_IMAGE: 'YouTube個人資料圖片',
  YOUTUBE_CHANNEL_COVER_PHOTO: 'YouTube頻道封面照片',
  AUTO_COLOR: '自動上色',
  BALANCE: '平衡',
  AUTO_LEVEL: '自動水平',
  AUTO_TONE: '豐富',
  ENRICH: '豐富',
  RESET: '重啟',
  RESET_ALL: '重置所有',
  FILE_DOWNLOAD_OPTIONS: '文件下載選項',
  FILE_DOWNLOAD_INITIATED: '開始下載文件',
  IMAGE_PROPERTIES: '圖片屬性',
  DOWNLOAD_DETAILS: '下載屬性',
  FILE_SIZE: '文件大小',
  LICENSE: '執照',
  FILEFORMAT: '文件類型',
  CHANGE: '更改',
  PRICING: '價錢',
  FIND_QUALITY_IMAGE: 'Find quality images now',
  SAVE_AS: '另存為',
  IMAGE_USAGE: '圖片使用情況',
  PRINT: '打印',
  ELECTRONIC: '電子',
  COMPREHENSIVE: '全面',
  DOWNLOAD_CREDITS: '<b>{quota}</b> 個下載點數從您的 購買點數',
  BASIC_SHAPE: '基本形狀',
  CHIT_CHAT: '閒聊',
  SHAPES: '形狀',
  ELEMENT_CHIT_CHAT: '元素聊天',
  ORGANIC_ABSTRACT_SHAPES: '有機抽象形狀',
  ELEMENT_SHAPES: '元素形狀',
  BUY_SINGLE_IMAGE: '購買單張圖片 <br/><b>{quota} 點數</b>',
  BUY_SINGLE_IMAGE_CREDIT: '購買點數套餐 <br/><b>{quota} 點數</b>',
  BUY_CREDIT_PACK: '購買點數配套 <br/><b>節省更多</b>',
  BUY_CREDIT_PACK_POINT_1: '{quota}點數配套 <b>{price}</b>',
  BUY_CREDIT_PACK_POINT_2: '沒有月租限制。',
  BUY_CREDIT_PACK_POINT_3: '1年有效期。',
  BUY_SINGLE_IMAGE_PSUB: '購買單張圖片 <br/><b>{quota} 個下載</b>',
  BUY_DOWNLOAD_PACK: '購買下載配套包來 <br/><b>省更多</b>',
  BUY_DOWNLOAD_PACK_POINT_1: '{quota}張圖片下載配套 <b>{price}</b>',
  BUY_DOWNLOAD_PACK_POINT_2: '沒有月租限制。',
  BUY_DOWNLOAD_PACK_POINT_3: '1年有效期。',
  LOWEST_PRICE: '<b>最低的價格</b> <br/>的方案計畫',
  LOWEST_PRICE_POINT_1: '試用一個月 <b>{price}</b>',
  LOWEST_PRICE_POINT_2: '下載10張照片或向量圖片',
  LOWEST_PRICE_POINT_3: '未使用完畢的額度可移到下個月 <br/>(需使用信用卡付費綁定自動續約)',
  PER_IMAGE_SIZE: '每張圖片 <b>{size} 尺寸</b>',
  PER_IMAGE_ANY: '<b>任意大小</b>的圖片',
  PER_IMAGE: '一張圖片',
  VIEW_PRICING: '更多方案和價格',
  DOWNLOAD_BEGIN: '您的下載將會即刻開始，請等待',
  DOWNLOAD_NOT_STARTING: '下載仍然未開始？',
  SELECT_MIRROR: '[选择一个镜像]',
  SHOW_MORE: '显示更多',
  SHOW_LESS: '显示更少',
  LICENSE_TYPE: '許可證類型',
  LICENSE_SUMMARY: '授權協議概要',
  BACK: '返回',
  LANG_VECTOR: '向量圖像',
  PREMIUM_VECTOR: 'PREMIUM 向量圖片',
  SCALE_ANY_SIZE: '依比例至任何大小',
  LANDINGPAGE_VECTOR_HEADLINE: '向量繪圖、創意插畫、壁紙設計和網頁素材 - 123RF',
  LANDINGPAGE_PHOTO_HEADLINE: '正版授權圖像、廣告照片素材和商用攝影作品集 - 123RF',
  CATEGORIES: '種類',
  LANDINGPAGE_PRICING_1: '點數',
  LANDINGPAGE_PRICING_2: '購買下載方案',
  LANDINGPAGE_AS_LOW_AS: '最低價格',
  EMPTY_SEARCH_TITLE: '糟糕，您的搜尋沒有任何結果。',
  LANG_SMALL: '小',
  LANG_MEDIUM: '中',
  LANG_LARGE: '大',
  LANG_EXTRA_LARGE: '超大',
  LANG_LOW_CREDITS: '您似乎沒有足夠的下載點數！',
  LANG_TOPUP: 'Top up',
  LANG_TOPUP_NOW: '現在加值',
  SEO_DETAILS_TITLE_STOCKPHOTO: '{description} 免版權照片，圖片，畫像及圖片庫. Image {mediaId}',
  SEO_DETAILS_TITLE_CLIPARTVECTOR: '{description} 免版稅剪貼畫，向量插圖和庫存圖片. Image {mediaId}',
  SEO_DETAILS_METADESC_STOCKPHOTO: '圖片庫的{description} 圖片 Image {mediaId}',
  SEO_DETAILS_METADESC_CLIPARTVECTOR: '{description} 剪貼畫、和美工. Image {mediaId}',
  SEO_DETAILS_TITLE_FREE_PHOTO: '{description}免費圖像和照片{mediaId}',
  SEO_DETAILS_METADESC_FREE_PHOTO: '{description}的免費圖片。免費圖像{mediaId}。',
  SEO_DETAILS_TITLE_FREE_VECTOR: '{description}免費向量和圖形{mediaId}。',
  SEO_DETAILS_METADESC_FREE_VECTOR: '{description}的免費向量。免費圖形{mediaId}。',
  SEO_DETAILS_OGDESCRIPTION: '123RF 圖庫 - 數百萬張高創意商用圖片、向量分層素材、影像短片和音樂特效以滿足你每天的靈感創作！',
  LIVECHAT_LOOKINGHELP: '請問您需要幫忙嗎?',
  LIVECHAT_CHATWITHUS: '請與我們聯絡',
  FREE_TRIAL: '<b>Get 10 Free Images</b> <br/>with Subscription Plan',
  LANG_RELATEDSEARCH: '相關搜尋',
  LANG_RELATED_STOCKPHOTO_SEARCH: '相關照片搜索',
  LANG_PROPERATTRIBUTE: '這是我們的歸因：',
  LANG_ATTRIBUTEDESC: '請通過在產品，網站或抵押品內的任何區域下方放置此信用額度，將此圖片歸功於其攝影師',
  LANG_ATTRIBUTE_TITLE: '署名是可以選擇的',
  LANG_ATTRIBUTE_CREDIT: '如果您希望屬名於創建者，請使用以下額度：',
  LANG_ATTRIBUTE_COPYLINK: '複製鏈接',
  LANG_ATTRIBUTE_COPYTEXT: '複製文字',
  LANG_LICENSE_AGREEMENT: '<a href={url}><b>以下</b></a>是下載許可協議',
  LANG_NEXTPAGE: '下一頁',
  LANG_IMAGE_NO_AVAILABLE: '圖像＃{mediaId} 已失效',
  LANG_PHOTOGRAPHY_METAIMG_DESC: '擁有數千萬張高解析度正版圖像、廣告照片素材和商業攝影作品集任你選擇與下載。',
  LANG_VECTOR_METAIMG_DESC: '高完整度分層向量繪圖、精致插畫、無縫背景等設計素材任你選擇與下載。',
  LANG_ANOTHERMETAKWDEFAULT: '圖片, 素材, 壁紙, 插畫, 圖庫, 照片, 背景 素材, 簡報背景, 網頁素材, 素材圖庫, 圖片素材, 圖畫, 圖庫素材, 影像, 圖片庫, 圖片搜索, 圖像, 背景圖素材, 插圖, 矢量, 影像, 矢量素材, 素材 背景, 圖案素材, 矢量圖, 背景圖素材',
  LANG_ANOTHERMETADESCDEFAULT: '從123RF圖庫下載最新高解析度正版商業用途圖像、向量繪圖、網頁設計和影音素材。',
  EMPTY_FILTERED_RESULT_TITLE: '糟糕，您使用這些過濾條件的搜索未有任何結果。',
  EMPTY_FILTERED_RESULT_SUBTITLE: '嘗試刪除一些過濾條件或嘗試使用其他關鍵字',
  CLEAR_ALL_FILTER: '清除所有過濾條件',
  PROMOBAR_FREE10_TEXT: 'Get <b>10 FREE Images</b> when you get started on our 1 Month-Free Trial.',
  PROMOBAR_FREE10_BTNLABEL: 'START NOW',
  PROMOBAR_MASSIVE_MARKDOWN_TEXT: '所有訂閱皆大幅度降價。',
  PROMOBAR_MASSIVE_MARKDOWN_BTNLABEL: '查看計劃',
  LANG_MODEL_RELEASED: '模特兒肖像權',
  LANG_PROPERTY_RELEASED: '資產已被授權',
  LANG_MODEL_RELEASE: '擁有肖像授權',
  LANG_PROPERTY_RELEASE: '擁有智財授權',
  LANG_YES: '是',
  LANG_NO: '不是',
  LANG_MREXPLAIN: '模特兒版權，是一份由模特兒簽署的法律文件并授予照片肖像攝影版權以分佈、分發、使用他或她在一張或其他照片肖像',
  LANG_PREXPLAIN: '所有物版權,是一份由物體、題材或場地的擁有者所簽署的法律文件并授予照片肖像攝影版權以分佈、分發、使用在一張或其他照片',
  LANG_DOWNLOADS: '下載',
  LANG_OLDFILENAME: '原來文件名',
  LANG_DOWNLOAD_PREVIEW: '下載預覽',
  LANG_SHARE: '分享',
  LANG_RESTRICTIONS: '限制',
  LANG_READ_MORE: '閱讀更多',
  LANG_EDITORIAL_USE: '僅供編輯使用',
  LANG_EDITORIALDESCP: '僅限編輯用途：此圖像只限用於編輯上的目的。此圖像禁止使用在廣告, 商業或增進銷售目的的行為，除非擁有供應者額外提供的許可文件。123RF.com 不提供任何清關服務。',
  LANG_LOGO_OWNERSHIP: '標誌所有權',
  LANG_PHOTO_SHOT_SMARTPHONE: 'This photo was shot with a smartphone.',
  LANG_VECTORNOLOGO: 'If you wish to use this as your company logo, we may arrange for a <b>rights buy out</b> on your behalf. Please contact us at <b>{phonenum}</b> to find out more!',
  LANG_DOWNLOAD_QUOTA: '<b>{quota}</b> 個下載額度從您的 簽購方案',
  LANG_DOWNLOAD_SLOT: '<b>{quota}</b> 個下載配額從您的 下載配套',
  LANG_LOGO: 'Logo',
  USING_OLDER_BROWSER: '您使用的是舊版瀏覽器嗎？',
  UPGRADE_NEWER_BROWSER: '升級新的瀏覽器版本，享受更流暢的體驗！',
  CLICK_TO_DOWNLOAD: '點擊下載任何一個：',
  LANG_VECTOR_EDITABLE: '可編輯檔案格式包含SVG與EPS',
  PROMOBAR_GOOD15_TEXT1: '付一點點的錢，可以下載更多。',
  PROMOBAR_GOOD15_TEXT2: '享受所有方案15%折扣使用禮物代碼:',
  PROMOBAR_GOOD15_BTNLABEL: 'GOOD15',
  PROMOBAR_SUMMER25_TEXT1: '付一點點的錢，可以下載更多。',
  PROMOBAR_SUMMER25_TEXT2: '享受所有方案75折，請使用禮物代碼',
  PROMOBAR_SUMMER25_BTNLABEL: 'SUMMER25',
  PROMOBAR_HALLOW30_TEXT: '享受所有方案7折，請使用禮物代碼',
  NO_EDIT_COMPARE_MSG: '沒有編輯可比較',
  SWITCH_TO_VECTOR_MSG: '向量尺寸圖像不支照片編輯器。如果切換到向量格式，所有編輯都將遺失。',
  SWITCH_TO_XL_MSG: 'XL大小圖像不支照片編輯器。如果切換到XL格式，所有編輯都將遺失。',
  UNAVAILABLE_FOR_THIS_SIZE: '無法提供{size}的尺寸',
  SWITCH: '轉變',
  SWITCH_LICENSE_EXT_MSG: '您想將此圖片下載{type}檔嗎？如果您切換到擴展許可證，您將遺失所有編輯動作。',
  LANG_SESSION_EXPIRED: '您的排程已過期。請<a href={url}>在此處登入</a>',
  LANG_TEXT_COLOR: '文字顏色',
  LANG_SHAPE_ADD: 'Add Shape',
  LANG_TEXT_HIGHLIGHT: '強調',
  LANG_TEXT_ALIGNMENT: '对齐',
  LANG_TEXT_BOLD: '粗體',
  LANG_TEXT_ITALIC: '斜體',
  LANG_TEXT_UNDERLINE: '下劃線',
  LANG_TEXT_BULLET: '條列',
  LANG_TEXT_LINE_SPACING: '間距',
  LANG_TEXT_LETTER_SPACING: '字符間距',
  LANG_EDIT_COLOR: 'Edit color',
  LANG_BRING_FORWARD: '前移',
  LANG_BRING_BACKWARD: '后移',
  LANG_DUPLICATE: '複製',
  LANG_OPACITY: '透明度',
  LANG_DELETE: '刪除',
  LANG_TEXT_ADD: '添加文字',
  LANG_TEXT_EDIT: '編輯文本',
  LANG_TEXT_FONT_TYPE: '字體',
  LANG_TEXT_FONT_SIZE: '字體大小',
  LANG_TEXT_ALL_FONTS: '所有字體',
  LANG_TEXT_UNDO: '撤消',
  LANG_TEXT_REDO: '重做',
  LANG_TEXT_FONT: '字體',
  LANG_SEARCH_FONT_TYPE: '搜索字体类型',
  LANG_SEARCH_SHAPE_TYPE: '搜索元素',
  LANG_DONE: '完成',
  ADD_HEADER: '添加標題',
  ADD_TITLE: '添加標題',
  ADD_BODY: '添加文字',
  ADD_ASSISTIVE: '添加輔助文本',
  STOCK_PHOTO_BANNER_TITLE: '數百萬的免版稅圖片庫！',
  STOCK_PHOTO_BANNER_DESC: '找到所有讚賞的圖像，您需要創建厲害的視覺效果，創作您現在對生活的想法。',
  STOCK_PHOTO_SUBSCRIBE_TITLE: '用這些創作來敘述您的故事',
  STOCK_PHOTO_SUBSCRIBE_DESC: '立即瀏覽超過180萬授權圖像來敘述您的故事。加上我們的視覺效果，是最大化創造力的完美組合！',
  STOCK_PHOTO_CARD_DOWNLOAD_PACK: '下載配套',
  STOCK_PHOTO_CARD_FROM_AS_LOW_AS: '對於低於',
  STOCK_PHOTO_CARD_CREDIT: 'credit',
  STOCK_PHOTO_CARD_SUBSCRIPTION_PLAN: '簽購方案',
  STOCK_PHOTO_CARD_IMAGE: '圖片',
  STOCK_PHOTO_CARD_SUBSCRIBE_NOW: '現在訂閱',
  STOCK_PHOTO_CARD_BUY_NOW: '立即購買',
  STOCK_PHOTO_POPULAR_ROYALTY: '熱門免版稅圖片類別',
  STOCK_PHOTO_BROWSE_BEST_SELLING: 'Browse best-selling royalty free images',
  STOCK_PHOTO_DISCOVER_AFFORABLE: '找尋優惠的價格方案',
  STOCK_PHOTO_DISCOVER_NOW: '看看這邊',
  STOCK_PHOTO_VECTORS_FOOTAGE_AUDIO: 'Vectors, Footage, Audio for you',
  STOCK_PHOTO_VECTORS: 'Vectors',
  STOCK_PHOTO_ILLUSTRATION: 'Illustration',
  STOCK_PHOTO_PATTERN: 'Pattern',
  STOCK_PHOTO_CARTOON: 'Cartoon',
  STOCK_PHOTO_BABY: 'Baby',
  STOCK_PHOTO_FOOTAGE: 'Footage',
  STOCK_PHOTO_FOOTAGES: 'Footages',
  STOCK_PHOTO_BUILDING: 'Building',
  STOCK_PHOTO_HOTEL: 'Hotel',
  STOCK_PHOTO_CHILDREN: 'Children',
  STOCK_PHOTO_ABSTRACT: 'Abstract',
  STOCK_PHOTO_AUDIO: 'Audio',
  STOCK_PHOTO_AUDIOS: 'Audios',
  STOCK_PHOTO_TECHNO: 'Techno',
  STOCK_PHOTO_HOLIDAY: 'Holiday',
  STOCK_PHOTO_ACCOUSTIC: 'Accoustic',
  STOCK_PHOTO_LANDSCAPES: '景色',
  STOCK_PHOTO_BABIES: '嬰兒',
  STOCK_PHOTO_BUSINESS: '商業',
  STOCK_PHOTO_FOOD: '食物',
  STOCK_PHOTO_ANIMALS: '動物',
  STOCK_PHOTO_TECHNOLOGY: '科技',
  STOCK_PHOTO_HEALTHCARE: '醫療',
  STOCK_PHOTO_NATURE: '自然',
  STOCK_PHOTO_ROMANCE: '浪漫',
  CLIPART_VECTOR_EXPLORE_MILLIONS: '動動手指就可使用數百萬的免版稅圖片庫',
  CLIPART_VECTOR_NEED_AN_EYE: '查找向量圖，圖表，剪貼畫，圖標，有幫助將您的創作',
  CLIPART_VECTOR_FIND_VECTOR: '找到合適的向量圖來加入。',
  CLIPART_VECTOR_FIND_VECTOR_DESC: '無論是很酷的圖表，插圖，卡通，剪貼畫，背景，圖標還是您需要創造令人讚賞的視覺效果;我們就在這裡將您的設計完成！',
  CLIPART_VECTOR_ON_DEMAND: 'On Demand',
  CLIPART_VECTOR_FROM_AS_LOW_AS: 'From as low as',
  CLIPART_VECTOR_PER_CREDIT: '/ credit',
  CLIPART_VECTOR_BUY_SUBSCRIPTION: 'Buy Subscription',
  CLIPART_VECTOR_PER_IMAGE: '/ image',
  CLIPART_VECTOR_SUBSCRIBE_NOW: 'Subscribe Now',
  CLIPART_VECTOR_POPULAR_ROYALTY: '熱門免版稅矢量類別',
  CLIPART_VECTOR_DISCOVER_AFFORDABLE: '找尋優惠的價格方案',
  CLIPART_VECTOR_DISCOVER_NOW: '看看這邊',
  SPEND_AND_WIN_SUBTITLE: '每消費{currency}=1個抽獎機會',
  SPEND_AND_WIN_IMGALT: '消費並贏得iPhone 13 Pro',
  CLIPART_VECTOR_GRAPHIC: '繪圖',
  CLIPART_VECTOR_ADVERTISING: '廣告',
  CLIPART_VECTOR_FLOWER: '花',
  CLIPART_VECTOR_ABSTRACT: '抽象',
  CLIPART_VECTOR_POP: '流行音樂',
  CLIPART_VECTOR_BIRD: '鳥',
  CLIPART_VECTOR_ILLUSTRATION: '插圖',
  CLIPART_VECTOR_PIXEL: '像素',
  CLIPART_VECTOR_3D: '3d',
  LANG_VECTOR_SVG_EPS: '向量圖像，SVG與EPS',
  FREE_UPGRADE: '免費升級',
  FREE_UPGRADE_MESSAGE: '簽購方案與下載配套XL尺寸免費升級，立即購買!',
  LANG_FREE_IMAGE: '123RF 免費',
  LANG_IMAGE_FREE: '這張庫存照片免費用於商業和個人用途。需要歸因。',
  LANG_HOW_TO_ATTRIBUTE: '我如何歸因？',
  LANG_FREE_DOWNLOAD: '免費下載',
  LANG_FREE_DOWNLOAD_BUTTON: 'FREE 下載',
  LANG_GO_PLUS_BUTTON: '前往PLUS',
  LANG_ATTRIBUTION_IS_KEY: '歸因對於內容創作者是個極其重要的元素，尤其是選擇將其公開供公眾使用的內容。我們的內容創作者感謝從數百萬像您一樣的用戶使用、分享和認可他們的創作所獲得的曝光率。',
  LANG_GET_ATTRIBUTION_LINK: '複製此鏈接並將其放置在使用內容中或附近的位置。',
  LANG_CREDIT_OWNER: '請註明作者歸屬：',
  LANG_USE_ATTRIBUTION: '使用以下的歸因鏈接。',
  LANG_ATTRIBUTION_LOCATION: '若該位置不可行，尚有其它好的選擇，如放置在網站、博客、新聞通訊等的頁腳上或網站上的一特定位置都足以註明 {media_type} 的作者歸屬。',
  LANG_GOT_A_QUESTION: "有什么问题吗？<a href={url} target='_blank'><u>聯繫我們</u></a>",
  LANG_ATTR_FREE_IMG_TITLE: '該免費圖像需要您註明作者歸屬。',
  LANG_ATTR_FREE_DOWNLOADING: '堅持住！不會花很長時間的。',
  LANG_PLUS_CONTENT_1: '每月新增PLUS的獨家內容',
  LANG_PLUS_CONTENT_2: 'PLUS許可證 - 無需加註作者',
  LANG_PLUS_CONTENT_3: '24小時獨家客戶支援',
  LANG_PLUS_CONTENT_4: '無限下載',
  LANG_PLUS_CONTENT_5: '無限的創意自由',
  FREE_VECTOR: 'FREE 向量圖片',
  FREE_STOCKPHOTO: 'FREE 圖庫照片',
  FREE_PUBLIC_DOMAIN_CC0: '公共領域CC0',
  BUY_5_DOWNLOAD_PACK: "<b>限時優惠！</b><br/>3 + <b><span style='color:red'>2 免費</span></b> = 5張照片或向量圖",
  BUY_5_DOWNLOAD_PACK_POINT_2: '隨時下載（最多一年）',
  BUY_5_GET_2_FREE: '免費獲得2張!',
  LANG_ASK_DOWNLOAD_PLUS: 'Want to download this PLUS content?',
  LANG_SIGN_UP_PLUS: '註冊我們的123RF PLUS加上計劃',
  LANG_FOR_ONLY: "<span class='text'>僅</span><span class='price'> {price}</span>/月",
  LANG_FOR_ONLY_2: "<span class='text'>僅</span><span class='price'> {price}</span>",
  LANG_BENEFIT_PLUS: '從我們的PLUS計劃中獲益，只需',
  LANG_RELEASES: '授權證書',
  LANG_UNAVAILABLE: '不提供',
  LANG_ATTR_FREE_IMG_TITLE2: '我们很高兴你能找到你需要的东西。🎉',
  LANG_PLEASE_NOTE: '請注意，該圖片沒有附加任何模型或財產說明。商業用途的風險由你自己承擔。',
  LANG_PLEASE_ADVISED: '請注意，包含可識別個人的圖像不會帶有任何版本。要在商業上使用這些圖像，必須從相關方獲得許可。如果圖像不包含可識別的個人，則商業使用時不應存在版權問題。',
  LANG_REACHED_LIMIT: '您已達到每日下載限制。',
  LANG_COME_TOMORROW: '我們很高興您喜歡我們的免費圖片！ 明天再來更新您的下載限制。 謝謝你。 😊',
  LANG_DOWNLOAD_ERROR: 'Ops! 由於錯誤，您的圖片無法被下載。',
  LANG_REFRESH_AND_TRY_AGAIN: "請刷新您的瀏覽器並再次嘗試。如果這個問題持續存在， <a href={url} target='_blank'>請告訴我們。</a>",
  LANG_REACHED_LIMIT_PLUS: '每日下載限制達到',
  LANG_COME_TOMORROW_PLUS: '哇！您已經達到了今天的下載限制。請明天回來下載更多。謝謝您的支持！',
  SEE_YOU_TOMORROW: '明天見！',
  ORIGINAL: '原始圖片',
  'AUTO_B&W': '黑白平衡',
  AUTO_ADJUST: '自動優化',
  AUTO_POP: '自動鮮明',
  NONE: '無效果',
  LANG_CROP: '剪裁',
  LANG_ENABLE_EDITOR: '編輯圖片',
  LANG_DISABLE_EDITOR: '關閉',
  LANG_IMAGE_FREE_CC0: "這是CC0圖像。不需要歸因。您可以根據<a class='linkCC0' href={url} target='_blank'>CC0許可條款和條件</a>使用它。",
  LANG_PREVIEW: '預覽',
  LANG_ATTRIBUTION_CC0_TITLE: 'CCO圖像：不需要歸因',
  LANG_ATTRIBUTION_TITLE: '需要註記',
  LANG_ATTRIBUTION_CONTENT: '下載文件後，將提供加註連結。',
  LANG_ATTRIBUTION_CC0_CONTENT: '不需要歸因，因為這是CCO映像。',
  LANG_SUBCRIBE_PREMIUM: '您已經達到了每日下載限制！要下載更多，請訂閱123RF PREMIUM。',
  LANG_REMOVE_ATTRIBUTION: '刪除歸因？',
  LANG_SUBCRIBE_PLUS_ATTRIBUTION: "訂閱<img src={image} alt='123RF Plus Logo'>，無限訪問100m庫存照片和插圖，無需屬性。",
  LANG_CREATOR_ATTRIBUTION: '請歸功於創作者的歸因下方鏈接',
  LANG_GET_ATTRIBUTION_LINK_2: '複製此鏈接並將其放置在使用內容的位置，網站的頁腳或網站的特定信用部分。',
  LANG_APPLY_BG_REMOVAL: '去除背景',
  LANG_APPLYING_EDIT: '套用編輯...',
  LANG_APPLYING_EDIT_ERROR: '出問題了。',
  LANG_TRY_AGAIN: '再試一次？',
  LANG_SEARCHCOLLECTION_PREMIUM: '全系列',
  LANG_SEARCHCOLLECTION_PLUS: '必備系列',
  LANG_SEARCHCOLLECTION_FREE: '基本系列',
  LANG_SIMILARIMAGESPAGE_TITLE: '版權商用圖片庫、向量和插畫繪圖等創意設計素材 | 123RF',
  LANG_SIMILARIMAGESPAGE_STOCKPHOTOS: '相似素材',
  LANG_SIMILARIMAGESPAGE_EMPTYRESULTS: '哎呀！這沒東西看。',
  LANG_SIMILARIMAGESPAGE_UNAVAILABLEIMAGE: '圖片無效',
  LANG_PER_IMAGE: '/圖片',
  LANG_PLANS_AND_PRICING: '方案和定價',
  LANG_CHOOSE_BEST_PLAN: '選擇適合您需求的最佳方案。',
  LANG_WANT_MORE_SAVING_1: '想要更划算嗎？',
  LANG_WANT_MORE_SAVING_2: '我們提供最低每張圖片{price}，來看更多方案',
  LANG_BEST_VALUE: '選擇年度方案來節省{percentage}％',
  LANG_YEARLY_PLAN: '年度方案',
  LANG_WITH_MONTHLY_PAYMENTS: '每月付款',
  LANG_GRAB_YEARLY_PLAN: '抓住年度方案並節省{percentage}％。',
  LANG_PRICE_PER_MONTH: '每月{price}',
  LANG_PRICE_PER_YEAR: '每年{price}',
  LANG_GET_X_EVERY_MONTH: '每月有{quota}個照片與向量圖可下載',
  LANG_X_EVERY_MONTH: '每個月擁有{quota}張照片或向量圖片',
  LANG_SINGLE_IMAGE_PURCHASE: '單圖像購買',
  LANG_GET_ONLY_WHAT_YOU_NEED: '目前只下載所需的素材',
  LANG_TOTAL_PRICE: '總計 {price}',
  LANG_DOWNLOAD_IMMEDIATELY: '立即下載圖像結帳後。',
  LANG_NEXT: '下一個',
  LANG_TITLE: '版權商用圖片庫、向量和插畫繪圖等創意設計素材 | 123RF',
  SIMILAR_STOCK_PHOTOS: '相似素材',
  EMPTY_SIMILAR_SEARCH_RESULTS: '哎呀！這沒東西看。',
  UNAVAILABLE_IMAGE: '圖片無效',
  LANG_DOWNLOAD_PACK: '下載配套',
  LANG_GET_X_ANY_TIME: '任何時候都有{quota}張照片或插圖',
  LANG_X_CREDITS: '{total} 點數',
  LANG_PER_CREDIT: '/點數',
  LANG_GET_X_PHOTOS_AND_Y_VIDEOS_ANY_TIME: '任何時候可以下載{photoCount}張圖片{videoCount, plural, =0 {} other {或#個影片}}',
  INCLUDES_STANDARD_LICENSE: '包括標准許可證',
  LANG_MEDIUM_SIZE: '中等尺寸',
  LANG_LARGE_SIZE: '大尺寸',
  LANG_EXTRA_LARGE_SIZE: '超大尺寸',
  LANG_STANDARD_SIZES: '標準尺寸',
  LANG_ROYALTY_FREE: '免版稅',
  LANG_OPTIONS: '選項',
  LANG_BENEFIT_FROM_PLUS_PLAN: '從我們的PLUS計劃中獲益，只需',
  LANG_RELATED_FONT_SEARCH: '相關搜索',
  AI_GENERATED_PHOTO: 'AI生成的圖像',
  FONT_PRICING_CREDIT: '點數',
  '3_FONTS_3_IMGS': '3個字體<b>或</b>3張圖像<b>或</b>1個視頻',
  '9_FONTS_9_IMG_3_VIDEOS': '9個字體<b>或</b>9張圖像<b>或</b>3個視頻',
  PURCHASE_AND_DOWNLOAD: '購買與下載',
  FONT_PATH: 'fonts',
  FONT_ZIP: '字體（OTF，TTF）',
  FONT_PACKS_LICENSE: '123RF包裝許可證',
  FONT_META_DESC: '下載{product_title}字體，字體或字體，以創建用於打印和Web的驚人設計。',
  FONT_META_TITLE: '字體 -  {product_title} | 123rf',
  FONT_SIMILAR: '類似字體',
  LANG_PLANS: '方案',
  LANG_I_WANT_THIS: 'I want this',
  LANG_AI_VARIATION_TOOLTIP_TITLE: '嘗試創造變化',
  LANG_AI_VARIATION_TOOLTIP_DESCRIPTION: '在所提供的原始圖像的基礎上生成新的圖像。此外，這也是一種新的方式，在每次AI生成的圖像獲得授權時，獎勵我們的123RF貢獻者!',
  LANG_SIZEBAR_TOOLTIP_DESCRIPTION: '我們的社區感謝您。許可費將與123RF貢獻者共享。',
  LANG_CONTRIBUTORS_AI_VARIATION: '原始圖片的123RF貢獻者會因每張AI變體的授權而得到補償。',
  LANG_AI_VARIATION_LIMIT: '哦，不！你今天已經達到了生成AI圖像的上限。',
  LANG_GENERATE_COMEBACK: '明天再來吧!',
  LANG_LICENSE_AND_DOWNLOAD: '許可證和下載',
  LANG_CONFIRM_OK: '好',
  LANG_AI_SWITCH_CONTENT: 'AI變體不支持照片編輯器。如果你切換到AI變體，所有的編輯都將丟失。',
  LANG_GENERATE_VARIATIONS: '生成變化',
  LANG_GENERATE_WITH_AI: '用人工智能生成圖像',
  LANG_NEW: '全新的',
  LANG_AI_GENERATOR_LICENSE: '123RF AI 生成器的服務條款 (“條款”)',
  LANG_I_UNDERSTAND: '我理解',
  LANG_WHAT_IS_THIS: '這是什麼？',
  LANG_AUTHENTIC: '真實圖片',
  LANG_ABSTRACT: '摘要',
  LANG_MACRO_CLOSEUP: '宏觀/特冩',
  LANG_OBJECT: '對象',
  LANG_WATERCOLOR: '水彩畫',
  LANG_FLAT: '平麵',
  LANG_CARTOON: '卡通',
  LANG_GEOMETRIC: '幾何圖形',
  LANG_GRADIENT: '梯度',
  LANG_ISOMETRIC: '等值線',
  LANG_3D: '3d',
  LANG_HAND_DRAWN: '手繪的',
  LANG_NATURAL: '自然的',
  LANG_WARM: '暖色調',
  LANG_DRAMATIC: '戲劇性的',
  LANG_VIVID_BOLD: '生動和大膽',
  LANG_BLACK_WHITE: '黑色和白色',
  LANG_ASIAN: '亞洲人',
  LANG_AFRICAN_AMERICAN: '非裔美國人',
  LANG_STANDARD: '標準',
  LANG_EXTENDED: '擴展',
  LANG_STANDARD_CONTENT_1: '社交媒體、網站、電子郵件的無限瀏覽。',
  LANG_STANDARD_CONTENT_2: '高達 500,000 次印刷。',
  LANG_STANDARD_CONTENT_3: '請勿用於出售的商品。',
  LANG_EXTENDED_CONTENT_1: '社交媒體、網站、電子郵件的無限瀏覽。',
  LANG_EXTENDED_CONTENT_2: '無限印刷。',
  LANG_EXTENDED_CONTENT_3: '可用於出售的商品。',
  LANG_ORIGINAL_IMAGE: '原始圖像',
  LANG_IMPORTANT_NOTE: '重要提示',
  LANG_AI_ORIGINAL_ALTERED: 'AI變化是使用AI技術改變原始圖像的變體。這類似於讓AI編輯器修飾你選擇的圖像。',
  LANG_GENERATE_VARIATIONS_NOTE: '由於我們目前處於由AI驅動的圖像變化的Beta階段，生成的圖像可能會產生意想不到的結果。我們正在努力確保這樣的結果和變化符合道德和負責任的AI指導方針。',
  LANG_LICENSE_AI_EDITED: '當你獲得這些AI編輯版本中的一種許可時，原始圖像的版權持有人會收到許可費。',
  LANG_MOOD: '心情',
  LANG_AI_EXCLUDE_PLACEHOLDER: '例如，綠色，直髮',
  LANG_SIMILAR_STOCK: '類似的圖片',
  LANG_PREMIUM_TAG_TOOLTIP: 'PREMIUM subscription only',

  WITH_MODEL_RELEASE: '擁有肖像授權',
  WITH_PROPERTY_RELEASE: '擁有智財授權',
  MODEL_RELEASE_CONTENT: '模特兒版權，是一份由模特兒簽署的法律文件并授予照片肖像攝影版權以分佈、分發、使用他或她在一張或其他照片肖像',
  PROPERTY_RELEASE_CONTENT: '所有物版權,是一份由物體、題材或場地的擁有者所簽署的法律文件并授予照片肖像攝影版權以分佈、分發、使用在一張或其他照片',

  variousStockGAI: '通過數百萬張庫存照片訓練的合法AI模型，並針對企業需求進行了優化，提供了責任保險，讓您安心。',
  extendedLicense: '擴展許可證',
  sizes: '尺寸',

  downloadMail1st: '我哋影像放大服務供應商會傳一封電郵俾你',
  downloadMail2nd:
    '，內有指示，教你喺隔一個工作日內下載最後放大嘅檔案',

  aiGenarated: 'AI生成的圖像',

  variousOpenJourney: 'AI模型生成與Midjourney獨特風格相似的藝術品。',
  variousTurboVision: '在“正常”XL 解析度下高速生成，提高整體品質。',
  variousRealVisXL: '模型目標是精確和準確地達到照片般逼真的效果。',
  variousPortraitPlus: '具有統一肖像構圖的模型，適合 1:1 的長寬比。',
  enterVirifiedCode: '輸入驗證碼',
  verify: '驗證',
  didNotGetCode: '沒有收到代碼？',
  clickToResend: '點擊重新發送',
  otpCannotEmpty: 'OTP 不能為空。',
  sentCodeToMail: '我們已將代碼發送至您註冊的電子郵件。',
  invalidCode: '無效代碼',
  expiredOTP: 'OTP 已過期，請重新發送。',
  sendEmail: '郵件已成功發送，請檢查您的信箱以繼續。',
  remainingTime: '剩餘時間: ',
};

export default tw;
