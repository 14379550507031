import PropTypes from 'prop-types';
import { BLACK_BORDER_COLOR } from 'constants/index';

const IcCreateNewLikebox = ({
  fillcolor,
  width,
  height,
}) => (
  <svg width={width} height={height} viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.75 9.98438V13.7344C8.75 14.1488 9.08562 14.4844 9.5 14.4844C9.91438 14.4844 10.25 14.1488 10.25 13.7344V9.98438H14C14.4144 9.98438 14.75 9.64875 14.75 9.23438C14.75 8.82 14.4144 8.48438 14 8.48438H10.25V4.73438C10.25 4.32 9.91438 3.98438 9.5 3.98438C9.08562 3.98438 8.75 4.32 8.75 4.73438V8.48438H5C4.58563 8.48438 4.25 8.82 4.25 9.23438C4.25 9.64875 4.58563 9.98438 5 9.98438H8.75Z" fill={fillcolor} />
  </svg>
);
IcCreateNewLikebox.propTypes = {
  fillcolor: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

IcCreateNewLikebox.defaultProps = {
  fillcolor: BLACK_BORDER_COLOR,
  width: '48',
  height: '48',
};
export default IcCreateNewLikebox;
