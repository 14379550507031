import {
  React, useContext, useEffect, useState,
} from 'react';
import 'antd/dist/antd.css';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import Footer from 'components/organisms/Footer/Footer';
import BodyContent from 'components/organisms';
import Header from 'components/organisms/Header/Header';
import CookieService from 'services/Cookie/CookieService';
import MainContext from 'stores/context/MainContexts';
import { getStorage } from 'helpers/storage';
import {
  GET_SETTING, ACCESS_TOKEN, GET_DATA, LANG,
} from 'constants/index';
import * as cookie from 'helpers/cookies';
import { isEmpty } from 'lodash';
import TagManager from 'react-gtm-module';

const AppLayoutDefault = ({ sticky, children }) => {
  const router = useRouter();
  const [customColor, setCustomColor] = useState('');
  const {
    actions, initDataProvider, actionsUser,
  } = useContext(MainContext);
  const accessToken = cookie.getCookies(ACCESS_TOKEN);
  // action get license
  useEffect(() => {
    if (accessToken) {
      actionsUser.getAgreeStatus(GET_DATA);
    }
  }, [!isEmpty(accessToken)]);

  useEffect(() => {
    actions.getSettings();
    const getSetting = getStorage(GET_SETTING);
    setCustomColor(getSetting?.custom_color);
    let prevSession = CookieService.checkLoginSession();
    actions.getRecommendedFootage();
    // Autoload
    setInterval(() => {
      const nowSession = CookieService.checkLoginSession();
      if (prevSession !== nowSession) {
        prevSession = nowSession;
        // Only reload inactive tabs
        if (document.hidden) {
          router.reload();
        }
      }
    }, 5000);
  }, []);
  useEffect(() => {
    actions.getRecommendedImage();
    actions.getRecommendedFootage();
  }, [cookie.getCookies(LANG)]);

  // GTM
  useEffect(() => {
    TagManager.initialize(
      {
        gtmId: process.env.NEXT_PUBLIC_GTM_ID,
      },
    );
  }, []);
  return (
    <div className="layout_default">
      <Header
        sticky={sticky}
        customColor={initDataProvider?.getSettings?.custom_color ?? customColor}
      />
      <BodyContent>{children}</BodyContent>
      <Footer sticky={sticky} />
    </div>
  );
};

// Debounce Hook
export const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(
    () => {
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay],
  );
  return debouncedValue;
};

AppLayoutDefault.propTypes = {
  children: PropTypes.element,
  sticky: PropTypes.bool,
};

AppLayoutDefault.defaultProps = {
  children: null,
  sticky: false,
};
export default AppLayoutDefault;
