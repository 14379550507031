/* eslint-disable consistent-return */
import {
  SET_LOGO_CONFIG_PREVIEW,
  SET_BANNER_CONFIG,
  SET_BANNER_CONFIG_PREVIEW,
} from './constants';

export const initDataCustomizedLanding = {
  bannerConfig: {
    bannerSrc: '',
    bannerMessage: '',
  },
  bannerConfigPreview: {
    bannerSrcPreview: '',
    bannerMessagePreview: null,
  },
  logoConfigPreview: '',
};

export const reducerCustomizedLanding = (state, action) => {
  switch (action.type) {
    case SET_BANNER_CONFIG:
      return {
        ...state,
        bannerConfig: action.payload,
      };
    case SET_LOGO_CONFIG_PREVIEW:
      return {
        ...state,
        logoConfigPreview: action.payload,
      };
    case SET_BANNER_CONFIG_PREVIEW:
      return {
        ...state,
        bannerConfigPreview: action.payload,
      };
    default:
      break;
  }
};
