import PropTypes from 'prop-types';

const IconNoti = ({ fillcolor, width, height }) => (
  <svg width={width} height={height} viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.75 13.65V9.5C15.75 6.75 14.1 4.4 11.75 3.35C11.65 1.9 10.45 0.75 9 0.75C7.55 0.75 6.35 1.9 6.25 3.35C3.9 4.4 2.25 6.75 2.25 9.5V13.65C0.9 14.75 0.25 16.25 0.25 18C0.25 18.4 0.6 18.75 1 18.75H5.85C6.2 20.2 7.5 21.25 9 21.25C10.5 21.25 11.8 20.2 12.15 18.75H17C17.4 18.75 17.75 18.4 17.75 18C17.75 16.2 17.1 14.75 15.75 13.65ZM9 2.25C9.45 2.25 9.85 2.5 10.05 2.85C9.7 2.8 9.35 2.75 9 2.75C8.65 2.75 8.3 2.8 7.95 2.85C8.15 2.5 8.55 2.25 9 2.25ZM9 19.75C8.3 19.75 7.7 19.35 7.45 18.75H10.6C10.3 19.35 9.7 19.75 9 19.75ZM1.8 17.25C1.95 16.15 2.5 15.3 3.45 14.6C3.65 14.45 3.75 14.25 3.75 14V9.5C3.75 6.6 6.1 4.25 9 4.25C11.9 4.25 14.25 6.6 14.25 9.5V14C14.25 14.25 14.35 14.45 14.55 14.6C15.5 15.3 16.05 16.15 16.2 17.25H1.8Z" fill={fillcolor} />
  </svg>
);
IconNoti.propTypes = {
  fillcolor: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};
IconNoti.defaultProps = {
  fillcolor: '#FFFFFF',
  width: '17',
  height: '20',
};
export default IconNoti;
