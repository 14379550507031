/* eslint-disable jsx-a11y/alt-text */
import {
  useContext, useEffect, useRef, useState,
} from 'react';
import { Button } from 'antd';
import MainContext from 'stores/context/MainContexts';
import { isEmpty } from 'lodash';
import { getTransText } from 'common/translate';
import ICMessage from 'components/icons/HeaderMenu/ICMessage';
import ICArrowicon from 'components/icons/Page/ICArrowicon';
import ICHeadsetMic from 'components/icons/Page/ICHeadsetMic';
import ICoperator from 'components/icons/Page/ICoperator';

const LiveChat = () => {
  const context = useContext(MainContext);
  const initLiveChat = context?.initDataProvider?.getSettings?.show_live_chat;
  const TawkActive = useRef(0);
  const [isActiveLiveChat, setIsActiveLiveChat] = useState(false);
  const [isShowArrowIcon, setIsShowArrowIcon] = useState(false);
  const openCloseLiveChat = () => {
    setIsShowArrowIcon(!isShowArrowIcon);
    const hideShow = document.getElementById('pop_newchat');
    if (hideShow.style.display === 'none' || isEmpty(hideShow.style.display)) {
      hideShow.style.display = 'block';
    } else {
      hideShow.style.display = 'none';
    }
  };
  const liveChat = () => {
    openCloseLiveChat();
    window.Tawk_API = window.Tawk_API || {};
    if (TawkActive.current === 0) {
      const s1 = document.createElement('script');
      const s0 = document.getElementsByTagName('script')[0];
      s1.async = true;
      s1.src = 'https://embed.tawk.to/5ed76dc79e5f6944228fc80f/default';
      s1.charset = 'UTF-8';
      s1.setAttribute('crossorigin', '*');
      s0.parentNode.insertBefore(s1, s0);

      window.Tawk_API.onLoad = () => {
        TawkActive.current = 1;
        window.Tawk_API.hideWidget();
        window.Tawk_API.popup();
      };
    } else {
      window.Tawk_API.hideWidget();
      window.Tawk_API.popup();
    }
  };
  useEffect(() => {
    setIsActiveLiveChat(initLiveChat === 1);
  }, [initLiveChat]);
  return (
    isActiveLiveChat ? (
      <>
        <div className="fixed-action-btn">
          <span className="tooltiptext">{getTransText('needHelp')}</span>
          <Button className="btn-floating btn-large" onClick={openCloseLiveChat}>
            {isShowArrowIcon ? <i className="icon-arrow"><ICArrowicon /></i> : <i><ICMessage /></i>}
          </Button>
        </div>

        <div id="pop_newchat">
          <div className="header-live-chat">
            <div className="header-message-livechat">
              <div className="title-message-livechat">{getTransText('hiThere')}</div>
              <div className="text-message-livechat">{getTransText('liveChatLookIngHelp')}</div>
            </div>
            <div className="box-operator-livechat">
              <i className="icon-operator">
                <ICoperator />
                <i className="icon-operator-green" />
              </i>
            </div>
          </div>
          <div className="pop-text-div">
            <div id="button_pop">
              <Button className="global-btn primary-btn" onClick={liveChat}>
                <i className="icon-livechat"><ICHeadsetMic /></i>
                {getTransText('liveChat')}
              </Button>
            </div>
          </div>
        </div>
      </>
    ) : <></>
  );
};
export default LiveChat;
