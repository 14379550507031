import PropTypes from 'prop-types';

const IconAdminDashboard = ({
  fillColor,
  width,
  height,
}) => (
  <svg width={width} height={height} viewBox="0 0 20 18" fill={fillColor} xmlns="http://www.w3.org/2000/svg">
    <path d="M16.9836 7.14203L15.9586 8.6837C16.9626 10.6863 16.8938 13.0594 15.7752 15.0004H4.22522C2.75564 12.4509 3.13138 9.23955 5.14979 7.09822C7.1682 4.9569 10.3518 4.39221 12.9836 5.7087L14.5252 4.6837C11.2951 2.6124 7.06805 3.01458 4.28647 5.65788C1.50488 8.30117 0.887853 12.5022 2.79189 15.8337C3.08781 16.3463 3.63337 16.6635 4.22522 16.667H15.7669C16.3647 16.6694 16.918 16.3514 17.2169 15.8337C18.7824 13.1218 18.6955 9.7611 16.9919 7.1337L16.9836 7.14203Z" fill={fillColor} />
    <path opacity="0.48" d="M8.82475 12.8426C9.13736 13.1555 9.56157 13.3314 10.0039 13.3314C10.4463 13.3314 10.8705 13.1555 11.1831 12.8426L15.8997 5.76758L8.82475 10.4842C8.51179 10.7969 8.33594 11.2211 8.33594 11.6634C8.33594 12.1058 8.51179 12.53 8.82475 12.8426Z" fill={fillColor} />
  </svg>

);
IconAdminDashboard.propTypes = {
  fillColor: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

IconAdminDashboard.defaultProps = {
  fillColor: 'white',
  width: '20',
  height: '20',
};
export default IconAdminDashboard;
