import PropTypes from 'prop-types';
import { ICON_COLOR_DEFAULT } from 'constants/index';

const IcMyAccount = ({
  fillcolor,
  width,
  height,
}) => (
  <svg width={width} height={height} viewBox="0 0 24 24.04" fill={fillcolor} xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M19.1565 15.392C18.5317 13.9173 17.4685 12.672 16.1278 11.8254C16.2392 11.4417 16.3182 11.0442 16.3182 10.6246C16.3182 8.23987 14.3806 6.29893 12 6.29893C9.61895 6.29893 7.68182 8.23987 7.68182 10.6246C7.68182 11.0442 7.76041 11.4417 7.87182 11.8254C6.53145 12.672 5.46832 13.9173 4.84348 15.392C4.44707 14.4585 4.22727 13.432 4.22727 12.3549C4.22727 8.06165 7.71377 4.56865 12 4.56865C16.2862 4.56865 19.7727 8.06165 19.7727 12.3549C19.7727 13.432 19.5529 14.4585 19.1565 15.392ZM9.40909 10.6246C9.40909 9.19368 10.5711 8.0292 12 8.0292C13.4285 8.0292 14.5909 9.19368 14.5909 10.6246C14.5909 10.7747 14.571 10.9196 14.5464 11.0628C14.4419 11.6753 14.1215 12.2087 13.6672 12.5945C13.2151 12.9791 12.6378 13.22 12 13.22C11.3618 13.22 10.7844 12.9791 10.3323 12.5945C9.87848 12.2087 9.55764 11.6753 9.45314 11.0628C9.42895 10.9196 9.40909 10.7747 9.40909 10.6246ZM12 20.1411C9.6168 20.1411 7.48361 19.0597 6.05645 17.3632C6.35009 15.6943 7.31866 14.256 8.67932 13.3563C9.47214 14.3209 10.6579 14.9503 12 14.9503C13.3417 14.9503 14.5279 14.3209 15.3202 13.3563C16.6813 14.256 17.6499 15.6943 17.9431 17.3632C16.5164 19.0597 14.3828 20.1411 12 20.1411ZM12 2.83838C6.76161 2.83838 2.5 7.10783 2.5 12.3549C2.5 14.4061 3.15723 16.3021 4.26355 17.858C4.7895 18.5977 5.4165 19.2583 6.12641 19.8202C7.74486 21.1006 9.78261 21.8714 12 21.8714C14.2174 21.8714 16.2551 21.1006 17.8732 19.8202C18.5839 19.2583 19.2105 18.5977 19.7365 17.858C20.8423 16.3021 21.5 14.4061 21.5 12.3549C21.5 7.10783 17.2384 2.83838 12 2.83838Z" fill={fillcolor} />
  </svg>

);

IcMyAccount.propTypes = {
  fillcolor: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

IcMyAccount.defaultProps = {
  fillcolor: ICON_COLOR_DEFAULT,
  width: '24',
  height: '24.04',
};
export default IcMyAccount;
