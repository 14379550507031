import * as types from './constants';

// eslint-disable-next-line import/prefer-default-export
export const useSearchResultActions = (state, dispatch) => ({
  getListAudioPlay: (data) => {
    dispatch({
      type: types.GET_LIST_AUDIO_PLAY,
      payload: {
        data,
      },
    });
  },
  setListAudioPlay: (data) => {
    dispatch({
      type: types.SET_LIST_AUDIO_PLAY,
      payload: {
        data,
      },
    });
  },
  getCurrentAudioPlay: (data) => {
    dispatch({
      type: types.GET_CURRENT_AUDIO_PLAY,
      payload: {
        data,
      },
    });
  },
  setCurrentAudioPlay: (data) => {
    dispatch({
      type: types.SET_CURRENT_AUDIO_PLAY,
      payload: {
        data,
      },
    });
  },
  getCurrentStickyAudioPlay: (data) => {
    dispatch({
      type: types.GET_CURRENT_STICKY_AUDIO_PLAY,
      payload: {
        data,
      },
    });
  },
  setCurrentStickyAudioPlay: (data) => {
    dispatch({
      type: types.SET_CURRENT_STICKY_AUDIO_PLAY,
      payload: {
        data,
      },
    });
  },
  getPlayStatus: (data) => {
    dispatch({
      type: types.GET_PLAY_STATUS,
      payload: {
        data,
      },
    });
  },
  setPlayStatus: (data) => {
    dispatch({
      type: types.SET_PLAY_STATUS,
      payload: {
        data,
      },
    });
  },
  setCurrentProgress: (data) => {
    dispatch({
      type: types.SET_CURRENT_PROGRESS,
      payload: {
        data,
      },
    });
  },
  getFirstRenderSticky: (data) => {
    dispatch({
      type: types.GET_FIRST_RENDER_STICKY,
      payload: {
        data,
      },
    });
  },
  setFirstRenderSticky: (data) => {
    dispatch({
      type: types.SET_FIRST_RENDER_STICKY,
      payload: {
        data,
      },
    });
  },
  getLoadingAudio: (data) => {
    dispatch({
      type: types.GET_LOADING_AUDIO,
      payload: {
        data,
      },
    });
  },
  setLoadingAudio: (data) => {
    dispatch({
      type: types.SET_LOADING_AUDIO,
      payload: {
        data,
      },
    });
  },
  getIsFilterBarVisibile: (data) => {
    dispatch({
      type: types.GET_IS_FILTER_BAR_VISIBLE,
      payload: {
        data,
      },
    });
  },
  setIsFilterBarVisibile: (data) => {
    dispatch({
      type: types.SET_IS_FILTER_BAR_VISIBLE,
      payload: {
        data,
      },
    });
  },
  resetInitialState: () => {
    dispatch({
      type: types.RESET_INITIAL_STATE,
    });
  },
  setIsAddedAudio: (data) => {
    dispatch({
      type: types.SET_IS_ADDED_AUDIO,
      payload: {
        data,
      },
    });
  },
  setDataAudioClone: (data) => {
    dispatch({
      type: types.DATA_AUDIO_CLONE,
      payload: {
        data,
      },
    });
  },
  setDataIsAddedAudioDetail: (data) => {
    dispatch({
      type: types.SET_IS_ADDED_AUDIO_DETAIL,
      payload: {
        data,
      },
    });
  },
  setDataSearchResult: (data) => {
    dispatch({
      type: types.SET_DATA_SEARCH_RESULT,
      payload: {
        data,
      },
    });
  },
  setFileTypeDownload: (data) => {
    dispatch({
      type: types.SET_FILE_TYPE_DOWNLOAD,
      payload: {
        data,
      },
    });
  },
  setIsPlayed: (data) => {
    dispatch({
      type: types.SET_IS_PLAYED,
      payload: {
        data,
      },
    });
  },
  setHeaderAudio: (data) => {
    dispatch({
      type: types.SET_HEADER_AUDIO_DETAILS_PAGE,
      payload: {
        data,
      },
    });
  },
});
