import PropTypes from 'prop-types';
import { MAIN_COLOR } from 'constants/index';

const IcDownloadAudio = ({
  fillcolor,
  width,
  height,
}) => (
  <svg width={width} height={height} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M24.0001 30.8056C22.9268 30.8056 22.0557 30.0286 22.0557 29.0694V8.23611C22.0557 7.27691 22.9268 6.5 24.0001 6.5C25.0734 6.5 25.9446 7.27691 25.9446 8.23611V29.0694C25.9446 30.0286 25.0734 30.8056 24.0001 30.8056Z" fill={fillcolor} />
    <path fillRule="evenodd" clipRule="evenodd" d="M24 30.8056C23.552 30.8056 23.1049 30.6318 22.7627 30.2833L15.7625 23.1535C15.0792 22.4575 15.0792 21.3292 15.7625 20.6331C16.4459 19.9371 17.5537 19.9371 18.2371 20.6331L24 26.5027L29.7629 20.6331C30.4463 19.9371 31.5541 19.9371 32.2375 20.6331C32.9208 21.3292 32.9208 22.4575 32.2375 23.1535L25.2373 30.2833C24.8951 30.6318 24.448 30.8056 24 30.8056Z" fill={fillcolor} />
    <path fillRule="evenodd" clipRule="evenodd" d="M35.375 41.5H12.625C9.24837 41.5 6.5 38.6644 6.5 35.1806V30.6667C6.5 29.6691 7.284 28.8611 8.25 28.8611C9.216 28.8611 10 29.6691 10 30.6667V35.1806C10 36.6737 11.1778 37.8889 12.625 37.8889H35.375C36.8223 37.8889 38 36.6737 38 35.1806V30.6667C38 29.6691 38.784 28.8611 39.75 28.8611C40.716 28.8611 41.5 29.6691 41.5 30.6667V35.1806C41.5 38.6644 38.7516 41.5 35.375 41.5Z" fill={fillcolor} />
  </svg>
);
IcDownloadAudio.propTypes = {
  fillcolor: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

IcDownloadAudio.defaultProps = {
  fillcolor: MAIN_COLOR,
  width: '48',
  height: '48',
};
export default IcDownloadAudio;
