const it = {
  // Header
  photos: 'Foto',
  Photos: 'Foto',
  vectors: 'Vettoriali',
  Vectors: 'Vettoriali',
  footageupper: 'VIDEO',
  footage: 'Video',
  video: 'Video',
  audio: 'Audio',
  signin: 'Accedi',
  imageLogo:
    'https://static-cdn.assetsdelivery.com/images/new_translate_corpplus_logo/logo_Italiano.png',
  language: 'Lingua',
  // Footer
  contact: 'Contatti',
  contactUs: 'Contattaci',
  privacy: 'Privacy dei Download',
  terms: "Termini per l'Utilizzo",
  cookie: 'Politica dei cookie',
  corporateAgreement: 'License Agreement',
  // ForgotPassword
  forgotPwdTitle2: 'Hai dimenticato la Password',
  forgotPwdDesc2:
    "That's OK, it happens to all of us. Enter your username below and we'll send you a link to reset your password",
  forgotPwdFail: 'Si prega di inserire un indirizzo email valido.',
  emailCannotEmpty: 'Il campo Email non può essere vuoto',
  returnToLogin: 'Ritorna al Login',
  email: 'Email',

  // share likebox
  duplicateCollection: 'Duplicate collection',
  removeFromCollection: 'Remove from collection',
  addToCollection: 'Add to collection',
  totalContentInThisCollection: 'Total content in this collection',
  duplicate: 'Duplicate',
  collectionDescription: 'Collection description',
  descriptionHere: 'Description here',
  duplicateCollectionSuccess:
    'You have successfully duplicated this collection to your personal collections.',

  // Login
  username: 'Nome utente',
  pw: 'Password',
  rememberMe: 'Ricordami',
  login: 'Accedi',
  forgotPassword: 'Password dimenticata',
  loginAsGuest: 'Guest Login',
  // ResetPassword
  resetPwdDesc: 'Inserisci la tua nuova password, ed accederai subito al tuo account.',
  newPw: 'Nuova Password',
  confirmPwd: 'Conferma password',
  resetPwdButton: 'Reimposta la password',
  resetPwdTitle: 'Reimposta la tua password',
  enterYourNewPw: 'Inserisci la tua nuova password.',
  pwMiniMumChars: 'Minimo 8 caratteri',
  pwMaxChar20: 'Il numero massimo di caratteri consentito è di 20',
  pwLowerCase: 'Almeno una lettera minuscola',
  pwUpperCase: 'Almeno una lettera maiuscola',
  pwNumeral: 'Almeno un numero',
  pwdSpecialChar: 'At least one special character',
  confirmPwNotMatch: 'Conferma Password non corrisponde con Password',
  resetPwdInvalidLink: 'Il link non è valido o è scaduto.',
  pwHasBeenReset: 'La password è stata reimpostata, si prega di procedere al login.',
  // Admin index
  companyName: 'Società',
  phone: 'Telefono',
  fax: 'Fax',
  address: 'Indirizzo',
  idd: 'IDD',
  // Dashboard Admin Credits
  myBalance: 'My balance',
  remaining: 'remaining',
  creditsRemaining: 'Credits remaining',
  topUp: 'Top up',
  groupBalance: 'Group balance',
  totalGroupBalance: 'Total group balance',
  manageGroupBalance: 'Manage group balance',
  individualBalance: 'Individual balance',
  manageIndividualBalance: 'Manage individual balance',
  totalDownloads: 'Total downloads',
  totalItems: 'Totale articoli',
  // Update Password
  updatePassTitle: 'Update Password',
  updatePassDes: 'Update your password before you go!',
  oldPassword: 'Vecchia Password',
  confirmNewPassword: 'Conferma Nuova Password',
  returnToHome: 'Torna alla pagina principale',
  // Cookie Policy
  langCookie01:
    'Quando visiti il sito %host%, possiamo inviarti un piccolo file di testo chiamato “cookie” che viene memorizzato sul tuo computer.',
  langCookie02:
    'Questo file ci consente di tracciarti in maniera da fornire delle funzionalità di base quali il carrello acquisti. Viene anche utilizzato per fornire una migliore esperienza d’uso e funzionalità quali il “Likebox” (o “Riquadro Mi Piace”).',
  langCookie03:
    'Inoltre, il cookie ci consente di analizzare le tue interazioni con il nostro sito web (incluso le pagine visitate, i termini ricercati e le immagini visualizzate). Questo avviene indipendentemente dal fatto che tu decida di effettuare un acquisto o meno.',
  langCookie04:
    "I cookie a cui acconsenti, sono anche usati per inserirti all'interno di liste profilate e inviarti promozioni di tuo interesse.",
  langCookie05:
    'La maggior parte dei browser ti consente di rifiutare i cookie: in ogni caso, se scegli di rifiutarli, determinate funzionalità potrebbero risultare non disponibili e certe pagine del sito potrebbero non essere visualizzate in maniera corretta.',

  downloadModalTitle: 'Download immagine',
  bulkDownloadTitle: 'Bulk Download',
  messageFileNotSupported1stHalf:
    'Le immagini in TIFF, i file audio e video non sono supportati. I seguente contenuti:',
  messageFileNotSupported2ndHalf: 'sono stati rimossi dal download in blocco.',
  licenseOnBehalf: 'Licenza per conto di',
  customizedPortal: 'Customized portal',
  normalDownload: 'Download normale',
  myCredit: 'Il mio credito',
  lowCreditsReminder: 'Credito basso',
  downloadModalId: 'ID (identificativo) Immagine',
  downloadModalPrice: 'Credito:',
  clearAll: 'Cancella tutto',
  myActivities: 'Le Mie Attività',
  info: 'Informazione',
  idCapitialization: 'ID',
  licenseOnBehalfOfYourClient: 'License on behalf of your client (Your client owns the license)',
  downloadUsing: 'Scarica questo contenuto usando',
  // homepage page
  home3Title: 'Free Browsing',
  toSearchKeyword: 'Digita qui la tua ricerca',
  toSearchAIKeyword: 'Inserisci parole chiave, il titolo di un articolo o di un blog',
  allImages: 'Tutte le immagini',
  freeImages: 'Free Images',
  categoryImage: 'Category',
  recentViewed: 'Visti di recente',
  latestIMG: 'Latest Images',
  // Setting
  adminControlPanel: "Pannello di Controllo dell'Amministratore di Corp+",
  downloadHistory: 'I Miei Download',
  hruc: 'HRUC',
  changePassword: 'Cambia Password',
  logOut: 'Esci',
  hello: 'Gentile,',
  viewAccount: 'View Account',
  myAccount: 'Il mio account',
  myCollections: 'Le Mie Collezioni',
  personalCredits: 'Crediti personali',
  groupTotal: 'Crediti di gruppo',
  toTalUnpaidLicense: 'Totale licenze non pagate',
  highRUnwatermarkComp: 'Bozzetti in alta risoluzione senza watermark',
  monthSelection: 'Month selection',

  // Menu
  categories: 'Categorie',
  myLikebox: 'La mia Likebox',
  settings: 'Impostazioni',
  notification: 'Notifiche',
  clear: 'Cancella',
  likebox: '收藏夹',
  credits: 'Crediti',
  // Credit summary at header
  downloadAndUsege: 'Download e Utilizzo',
  totalDlCredit: 'Totale Crediti',
  allocatedDlCredit: 'Assegna Crediti per il Download',
  personalAvailableCre: 'Saldo crediti personale',
  groupAvailableCre: 'Saldo crediti Gruppo',
  availableDlSlot: 'Slot disponibili per il Download',
  uNPLD: 'Download Use Now, Pay Later',
  hiResHRUC: 'Bozzetti in Alta Risoluzione senza watermark (HRUC)',
  monthlyAllocation: 'Quota mensile',
  remainingQuota: 'Ammontare rimanente',
  remainingHRUCQ: 'Quota rimanente di Bozzetti in Alta Risoluzione senza watermark',
  useNowPayLater: 'Use-Now-Pay-Later (UNPL)',
  totalPendingLicense: 'Total Pending License Downloads',
  enterprisePlan: 'Corporate+ Enterprise Plan',
  unallocatedCredits: 'Unallocated Credits',
  unutilizedAllocatedCredits: 'Unutilized Allocated Credits',
  usersTitleTotalCredits: 'Users',
  groupsTitleTotalCredits: 'Gruppi',
  titleTotalCreditsAvailable: 'Total Available',
  // live chat
  liveChatLookIngHelp: 'Facci sapere come possiamo aiutarti.',
  liveChat: 'Chat dal Vivo',
  whatsApp: 'WhatsApp',
  hiThere: 'Ciao,',
  // email response
  emailIsNotAvailable: "L'Email non è disponibile.",
  anErrorHaSoccured: 'Si è verificato un errore, si prega di riprovare.',
  emailHasBeenSent:
    "L' email è stata inviata con successo, controlla la tua casella di posta per procedere. Abbiamo inviato un'email a __email con le istruzioni per il reset. Se l'email non arriva nella tua casella di posta entro 5 minuti, controlla la tua cartella degli Spam.",
  // INVALIDPASSWORD
  inValidPassword: 'Password non valida! Controlla se il tuo caps lock è attivo.',
  userIsBlocked: 'Troppi tentativi falliti, sei stato temporaneamente bloccato.',
  blockWarrings: 'Sarai temporaneamente bloccato dopo 10 tentativi!',
  // Update Password
  resetPwdUpdated: 'La tua password è stata aggiornata.',
  updatePwDfailed: 'Aggiornamento password non riuscito!',
  // notification
  // Notification Side Bar - user
  notificationShareLikebox: 'shared a Likebox named [{VAR1}] with you.',
  notificationOutofCredits: 'ran out of download credits.',
  notificationAllcateCredit: 'assigned [{VAR1}] download credits to you.',
  notificationRemoveCredits: 'removed [{VAR1}] download credits from your account.',
  notificationAssignGroups: 'assigned you into the [{VAR1}] group.',
  notificationRemoveFromGroup: 'removed you from the [{VAR1}] group.',
  purcahsedCredits: 'You have just purchased [{VAR1}] credits.',
  notificationAssignAdmin: 'assigned you as the administrator.',
  notificationRemoveAdmin: 'removed you as the administrator.',
  notificationAssignGroupAdmin: 'assigned you as the group administrator of [{VAR1}].',
  notificationRemoveGroupAdmin: 'removed you as the group administrator of [{VAR1}].',
  // Notification Side Bar - admin
  adminNotificationShareLikebox: 'shared a Likebox named [{VAR1}] with [{RECEIVER}].',
  adminNotificationAllcateCredits: 'assigned [{VAR1}] download credits to [{RECEIVER}].',
  adminNotificationRemoveCredist: "removed [{VAR1}] download credits from [{RECEIVER}]'s account.",
  adminNotificationAssignGroup: 'assigned [{RECEIVER}] into the [{VAR1}] group.',
  adminNotificationRemoveFromGroup: 'removed [{RECEIVER}] from the [{VAR1}] group.',
  adminPurchasedCredits: '[{RECEIVER}] have just purchased [{VAR1}] credits.',
  adminNotificationAssignAdmin: 'assigned [{RECEIVER}] as the administrator.',
  adminNotificationRemoveAdmin: 'removed [{RECEIVER}] as the administrator.',
  adminNotificationAssignGroudAdmin:
    'assigned [{RECEIVER}] as the group administrator of [{VAR1}].',
  adminNotificationremoveGroupAdmin: 'removed [{RECEIVER}] as the group administrator of [{VAR1}].',
  // datetime
  year: 'year',
  month: 'month',
  week: 'week',
  day: 'day',
  hour: 'hour',
  minute: 'minute',
  second: 'second',
  ago: 'ago',
  justNow: 'just now',
  // datetimes
  years: 'years',
  months: 'months',
  weeks: 'weeks',
  days: 'days',
  hours: 'hours',
  minutes: 'minuti',
  seconds: 'seconds',
  // LicenseAgreement
  licenseAgreement: 'Contratto di licenza',
  licenseAgreementCaption:
    'PLEASE READ ALL THE TERMS PROVIDED IN THIS CORPORATE+ CONTENT LICENSING PLATFORM. YOUR ACCEPTANCE OF THESE TERMS IS AN ABSOLUTE CONDITION OF YOUR USE OF THE CORPORATE+ CONTENT LICENSING PLATFORM AND THE CONTENT, AND FORMS A LEGAL AGREEMENT. BY ACCESSING ANY SERVICE OR PART THEREOF UNDER THE CORPORATE+ CONTENT LICENSING PLATFORM, YOU AGREE TO BE BOUND BY THE TERMS OF THESE LEGAL DOCUMENTS. 123RF RESERVES THE RIGHT TO CHANGE THESE TERMS AT ANY TIME WITHOUT PRIOR WRITTEN NOTICE, AND YOU AGREE TO BE BOUND BY SUCH CHANGES.',
  corpplusAgreement: 'Corporate+ Agreement',
  // login
  usernameCannotEmpty: 'Il campo Nome Utente non può essere vuoto',
  passWordCannotEmpty: 'Il campo Password non può essere vuoto',
  oldPasswordCannotEmpty: 'Il campo vecchia password non può essere vuoto',
  confirmPasswordCannotBeEmpty: 'Il campo conferma la nuova password non può essere vuoto',
  // update password
  newpasswordDifferent: 'The new pasword and old pasword must be different.',
  // sent email not 20 times
  forgotNot20: 'Non è possibile inviare altre email, per favore controlla la tua casella di posta.',
  // errorMesServer
  errorMesServer: 'An error has occured, please try again or contact your administrators',
  // white space
  canNotContainSpaces: 'Password cannot contain spaces',
  // username is not available
  userNameNotAvailable: 'Username is not available.',
  // Username cannot contain spaces
  usernameCanNotContainSpaces: 'Username cannot contain spaces',
  // old browsers
  usingOlderBrowsers: 'Stai usando un browser più vecchio?',
  upgradeBrowser: "Passa a una versione più recente per un'esperienza di navigazione migliore!",
  clickToDownload: 'Clicca per scaricare uno dei seguenti browser:',
  googleChrome: 'Google Chrome',
  mozillaFirefox: 'Mozilla Firefox',
  microsoftEdge: 'Microsoft Edge',
  safari: 'Safari',
  nameUnavailable: 'Name unavailable',
  addressUnavailable: 'Company address unavailable',
  phoneUnavailable: 'Phone number unavailable',
  faxUnavailable: 'Fax unavailable',
  iddUnavailable: 'IDD unavailable',
  emailUnavailable: 'Email unavailable',
  agreeLicense: 'I AGREE to abide by all the Terms and Conditions above',
  btnAgree: 'AGREE',
  btnDecline: 'DECLINE',
  messageCheckbox:
    'Please read the License Agreement above carefully and signify your agreement by clicking the "I AGREE" check box.',
  // forgot page new description
  forgotPwdDescNew: 'Inserisci il tuo nome utente qui sotto e ti invieremo un collegamento per reimpostare la password.',
  // Notification
  readAll: 'Read All',
  viewAll: 'Vedi tutto',
  // Filter bar
  aiGenerate: "Immagine generata con l'A.I.",
  aiContent: "Includi contenuti generati dall'A.I.",
  filter: 'Filtri',
  safeSearch: 'Ricerca sicura',
  aiAssist: 'Assistenza AI',
  freshness: 'Freschezza',
  gender: 'Sesso',
  sortBy: 'Elenca per',
  mediaType: 'Tipo di media file',
  orientation: 'Orientamento',
  modelPreferences: 'Modello Preferenze',
  people: 'Persone',
  more: 'Maggiori',
  numOfPeople: 'Numero di persone',
  age: 'Qualsiasi età',
  ethnicity: 'Etnia',
  collection: 'Collezioni',
  uploaded: 'Uploaded',
  style: 'Stile',
  cutOut: 'Sfondi uniformi/trasparenti',
  doNotIncludeWords: 'Non includere queste parole',
  doNotIncludeWords_ex: 'es. formaggio',
  color: 'Colore',
  max1Color: 'Max 1 colore',
  license: 'Tipo di licenza',
  fps: 'FPS',
  resolution: 'Risoluzione',
  clipDuration: 'Durata del Clip (minuti)',
  loop: 'Loop',
  category: 'Categoria',
  genre: 'Genere',
  tempo: 'Tempo (bpm)',
  // Filter button text
  Relevance: 'Casuale',
  New: 'Nuova',
  Georank: 'Posizione geografica',
  DownloadHistory: 'Storico dei Download',
  Popularity: 'Popolarità',
  Photography: 'Fotografie',
  VectorIllustration: 'Illustrazione vettoriale',
  Horizontal: 'Orizzontale',
  Landscape: 'Paesaggio',
  Portrait: 'Ritratto',
  Square: 'Quadrato',
  Panorama: 'Panoramica',
  numPpl: 'Numero di persone',
  WithoutPeople: 'Senza persone',
  FilterNumberPeople1: '1 person',
  FilterNumberPeople2: '2 People',
  FilterNumberPeople3: '3 People',
  FilterNumberPeople4: '4 people or more',
  Exclude: 'Esclusi',
  SelectiveFocus: 'Selective Focus',
  Pattern: 'Pattern',
  Vibrance: 'Vibrance',
  Infants: 'Infants',
  Toddlers: 'Toddlers',
  Teens: 'Teens',
  PastWeek: 'Ultima settimana',
  LastMonth: 'Last month',
  PastThreeMonths: 'Ultimo 3 mesi',
  PastTwodays: 'Ultimi 2 giorni',
  Male: 'Uomo',
  Female: 'Donna',
  Standard: 'Standard',
  extended: 'Estesa',
  Editorial: 'Editoriale',
  Commercial: 'Commerciale',
  Black: 'Black',
  Caucasian: 'Caucasico',
  AfricanAmerican: 'Africano-Americano',
  Asian: 'Asiatico',
  EastAsian: 'East Asian',
  Hispanic: 'Ispanico',
  Indian: 'Indian',
  MiddleEastern: 'Middle Eastern',
  SoutheastAsian: 'Southeast Asian',
  Children: 'Bambini',
  Teenagers: 'Teenageers',
  Adults: 'Adulti',
  Seniors: 'Anziani',
  FPS1: '23.98/24',
  FPS2: '25',
  FPS3: '29.7/30',
  FPS4: '60',
  FPS5: '> 60',
  Off: 'Off',
  ZeroToOne: '0-1',
  OneToTwo: '1-2',
  TwoToThree: '2-3',
  LagerThree: '>3',
  Loopable: 'Sì',
  Nonloopable: 'No',
  Music: 'Musica',
  SoundEffects: 'Effetti Sonori',
  LogoAndIdents: 'Loghi e Identità Visive',
  Veryslow: 'Molto Lento',
  VeryslowSubText: '(0-79)',
  Slow: 'Lento',
  SlowSubText: '(80-119)',
  Medium: 'Medio',
  MediumSubText: '(120-139)',
  Fast: 'Veloce',
  FastSubText: '(140-159)',
  Veryfast: 'Molto Veloce',
  VeryfastSubText: '(160>)',
  TwoToEight: '2 - 8',
  TwentyToThirTy: '12 - 30',
  ThirtySixToSixty: '30 - 60',
  OneHundredAndTwenty: '120',
  Popular: 'Popolare',
  MostDownload: 'Most Download',
  Credits: 'Crediti',
  Freshness: 'Freschezza',
  pickAGenre: 'Scegli Genere',
  // Paginations
  Of: 'di',
  Page: 'Pagina',
  // home
  recommended: 'Suggeriti per te',
  bestSellingHome: 'Browse best-selling royalty photos',
  trendingKeyWord: 'Keyword più usate',
  FAQ: 'Frequently Asked Questions (FAQ)',
  // audio
  categoriesAudio: 'HD stock audios',

  // genre list audios
  gameSounds: 'Suoni di Giochi',
  cartoonSounds: 'Suoni di Cartoni Animati',
  soundPacks: 'Pacchetti di Suoni',
  interfaceSounds: 'Suoni di Interfaccia',
  transitionsAndMovementsSounds: 'Transizioni e Movimenti',
  natureSounds: 'Suoni della Natura',
  domesticSounds: 'Suoni Domestici',
  urbanSounds: 'Suoni Urbani',
  industrialSounds: 'Suoni Industriali',
  futuristicSounds: 'Suoni Futuristici',
  humanSounds: 'Suoni Umani',
  othersSounds: 'Altri',
  ambientSounds: 'Ambiente',
  childrenSounds: 'Bambini',
  cinematicSounds: 'Cinematografico',
  classicalSounds: 'Classico',
  corporateSounds: 'Azienda',
  electronicaSounds: 'Elettronica',
  soulSounds: 'Soul & R&B',
  jazzSounds: 'Jazz',
  popSounds: 'Pop',
  worldBeatSounds: 'World Beat',

  countrySounds: 'Country e Western',
  drumSounds: 'Drum & Bass',

  folkSounds: 'Folk e Acustica',
  funkSounds: 'Funk & Groove',

  hiphopSounds: 'Hip Hop',
  holidaySounds: 'Vacanze e Festività',

  rockSounds: 'Rock',

  individualSounds: 'Individual',
  introSounds: 'Intro e Outro',
  ascSounds: 'Ascendente e Discendente',
  businessSounds: 'Business e Media',
  orchestralSounds: 'Orchestrale',
  festiveSounds: 'Festivo',
  percussiveSounds: 'Percussioni',
  miscellaneousSounds: 'Varie',
  packsSounds: 'Pacchetti',
  percussionSounds: 'Suoni di percussioni',
  sampleLoopSounds: 'Campione loop',

  // Search images
  searchImage: 'Foto e Vettoriali',
  StockPhotosAndImages: 'Foto e Immagini Stock',
  StockVectorClipartAndIllustration: 'Vettori stock e Illustrator',

  matches: 'corrispondenze',
  editorial: 'Editoriale',
  downloaded: 'Questa immagine è già stata scaricata',
  to: 'to',
  relatedSearches: 'Ricerche correlate',
  similarTo: 'Simile a',
  searchByImage: 'Cerca per immagine',
  options: 'Opzioni',
  searchByImageMsg:
    "Cerca in 123RF con un'immagine invece del testo. Prova a trascinare un'immagine nella casella di ricerca.",
  mustBeJPGorPNG: "L'immagine deve essere JPG/PNG",
  sizeLessThan5MB: "La dimensione dell'immagine deve essere inferiore a 5 MB",
  sizeLessThan4MB: "La dimensione dell'immagine deve essere inferiore a 4 MB",
  dragOrDropFile: 'Trascina e rilascia il file o',
  browse: 'Sfoglia',
  uploadedImage: 'Immagine caricata',
  similarStockPhotos: 'Foto simili',
  // Search footage
  searchFootage: 'Video',
  StockFootage: 'Video Stock',
  businessFootage: 'Hacks to make the most of your business videos',
  // Details pages
  audioDetail: 'Audio Detail',
  imageDetail: 'Image Detail',
  footageDetail: 'Dettagli video',
  footageProperties: 'Proprietà del file video',
  audioProperties: 'Proprietà audio',
  modalDownloadTitle: 'Opzioni di download del file',
  credit: 'Credito',
  saveAs: 'Salva come',
  ownLicense: 'Download normale (licenza propria)',
  downloadAs: 'Scarica come',
  insufficientCredits: 'Crediti insufficienti',
  custom: 'Personalizzato',
  personalAndCom: 'Uso personale e commerciale di ads, presentazioni, social media, web.',
  licenseType: 'Tipo di licenza',
  requiredForResale:
    'Richiesto per la rivendita o distribuzione in spettacoli teatrali, spettacoli con accesso a pagamento, video in streaming, giochi e app.',
  stockAudioKeywords: 'Parole chiave audio stock',
  stockFootageKeywords: 'Parole chiave dei video',
  stockPhotoKeywords: 'Stock photo keywords',
  limitedLicenseSizes: 'Limited License Sizes',
  stereo: 'Stereo',
  fileSize: 'Dimensioni del file',
  footageId: 'ID Video',
  audioId: 'Audio ID',
  imageId: 'Image ID',
  // Search audio
  searchAudio: 'Audio (Musica e Effetti sonori)',
  StockAudio: 'Audio stock',
  similarTracks: 'Similar Tracks',
  viewAlbum: 'View Album',
  copyLink: 'Copia link',
  preview: 'Anteprima',

  bestSellingFootage: 'Sfoglia i video royalty free più venduti',
  featuresAudio: 'Featured audio',
  readmore: 'Read More',
  // justified layout
  similarImage: 'Immagini simili',
  addToLikebox: 'Add to Likebox',

  // recently view empty data
  rVPhoto: 'Le tue immagini viste di recente verranno visualizzate qui',
  rVVectors: 'I tuoi vettoriali visti di recente verranno visualizzati qui',
  rVFootage: 'I tuoi video visti di recente verranno visualizzati qui',
  rVAudio: 'I tuoi audio visti di recente verranno visualizzati qui',
  // recently likes empty data
  rLPhoto: 'Your recently liked images will show here',
  rLVectors: 'Your recently liked vectors will show here',
  rLFootage: 'Your recently liked video will show here',
  rLAudio: 'Your recently liked audio will show here',
  // Content Unavailable
  contentUnavailable: 'Content Unavailable',
  contentError: 'We’re sorry, there’s some error displaying this content',
  emptySearchResultTitle: 'Ops, la tua ricerca con questi filtri non prodotto con alcun risultato.',
  emptySearchResultTitleWithoutFilter: 'Oops, your search did not return any results.',
  emptySearchResultSubTitle: 'Prova a rimuovere alcuni filtri o prova una parola chiave diversa.',
  emptyReverseSearchTitle: 'Nessun risultato trovato!',
  emptyReverseSearchSubTitle1:
    "L'immagine inserita contiene degli errori, assicurati che l'immagine abbia le seguenti caratteristiche:",
  emptyReverseSearchSubTitle2: 'Controlla la tua ricerca con palere chiave',
  emptyReverseSearchSubTitle3: "L'immagine deve essere in formato JPG / PNG",
  emptyReverseSearchSubTitle4: "Il formato dell'immagine deve essere inferiore a 5 MB",
  emptySearchResultClearFilter: 'Cancella tutti i filtri.',
  emptyDownloadHistoryResult: 'Your download history will show here',
  // Please enter a keyword
  keywordEmpty: 'Inserisci una parola chiave',
  // admin redirect to
  plansAndPricing: 'Plans and Pricing ',
  contentTermLicense1:
    'This Agreement shall be governed by the laws of Singapore, without regard to the conflict of laws principles. ',
  contentTermLicense2:
    'the parties agree that the dispute shall be settled by arbitration in Singapore under the Singapore International Arbitration Centre (“SIAC”) administered SIAC Arbitration Rules in force when the notice of arbitration is submitted in accordance with these rules. The number of arbitrators shall be one (1). The language of the arbitration shall be English.',
  contentTermLicense3:
    'that damages and remedies under Singapore Law for any breach of any such covenant would be inadequate. You further acknowledge and agree that the arbitral tribunal may order any interim measure it deems necessary or proper in accordance with the applicable laws of Singapore,',
  contentTermUsLicense1:
    'This Agreement shall be governed by the laws of the state of Delaware, United States of America, without regard to the conflict of laws principles',
  contentTermLicenseUs2:
    ' the parties agree first to try in good faith to settle the dispute by mediation administered by the American Arbitration Association under its Commercial Mediation Procedures before resorting to arbitration, litigations or some other dispute resolution procedure. In the event if the dispute is settled via arbitration, the number of arbitrators shall be one (1) and the language of the arbitration shall be English.',
  contentTermUsLisence3:
    ' that damages and remedies under applicable law for any breach of any such covenant would be inadequate. You further acknowledge and agree that the arbitral tribunal may order any interim measure it deems necessary or proper in accordance with the applicable law,',
  contentCorporateUs:
    'This Agreement shall be governed by the laws of the State of Delaware in the United States of America, without regard to the conflict of laws principles. All disputes arising out of or in connection with the performance of this Agreement shall be settled through negotiations and if the dispute cannot be settled through negotiation, the parties agree first to try in good faith to settle the dispute by mediation administered by the American Arbitration under its Commercial Mediation Procedures before resorting to arbitration, litigation, or some other dispute resolution procedure. In the event if the dispute is settled via arbitration, the number of arbitrators shall be one (1), the language of the arbitration shall be English and the arbitral award shall be final and binding upon parties.',
  contentCorporate:
    'This Agreement shall be governed by, and shall be construed in accordance with, the laws of Singapore. All disputes arising out of or in connection with the performance of this Agreement shall be settled through friendly negotiations. If the parties are unable to resolve any such dispute within thirty (30) days after the commencement of negotiations, the parties agree that the dispute shall be settled by arbitration in Singapore under the Singapore International Arbitration Centre (“<b>SIAC</b>”) administered by SIAC Arbitration Rules in force when the notice of arbitration is submitted in accordance with the rules. The number of arbitrators shall be one (1). The decision of the arbitrator shall, except in the case of manifest error, be final and binding upon the parties hereto. The arbitration proceedings shall be conducted in English.',
  // collection search result page
  searchCollection: 'Cerca selezioni',
  personal: 'Personale',
  groups: 'Gruppi',
  createNew: 'Crea nuovo',
  createNewCollection: 'Crea una nuova selezione',
  descriptionPlaceholder: 'Add an optional description for your collection here.',
  titleItem: 'Titolo',
  selectGroup: 'Seleziona gruppo',
  description: 'Descrizione',
  charactersRemaining: '180 caratteri rimanenti',
  cancel: 'Cancella',
  create: 'Crea',
  defaultCollection: 'Collezione Predefinita',
  addToMyCollections: 'Aggiungi alla Likebox',
  addedToCollection: 'Aggiunto alla likebox',
  removedFromCollection: 'Rimosso dalla likebox',
  collectionModalPlaceholder: 'es. Natura',
  groupNameShowsHere: 'Il nome del gruppo viene visualizzato qui',
  // like box
  btnViewCollection: 'View Collection',
  shareWithMe: 'Condivisi con me',
  addLikebox: 'Add New Likebox',
  notImageLikeBox: 'You do not have any images in your likebox yet',
  owner: 'Proprietario',
  images: 'Immagini',
  likeboxName: 'Likebox Name',
  likboxNameMaxLength50: 'The maximum characters allowed is 50',
  addLikeBoxTo: 'Add likebox to',
  personalLikebox: 'Personali',
  btAdd: 'ADD',
  btCancel: 'CANCEL',
  btSave: 'SAVE',
  editLikebox: 'Edit Likebox',
  share: 'Condividi',
  // reset password
  resetPwdTitle2: 'Reset Password',
  noFoundLikebox: 'Nessuna likebox disponibile',
  btnUnDo: 'Annulla',
  // my collection
  dateAdded: 'Data aggiunta',
  collectionName: 'Nome collezione',
  selectAll: 'Seleziona tutto',
  delete: 'Elimina',
  seeAll: 'Vedi tutto',
  deleteCollection: 'Cancella collezione',
  deleteWarning:
    'Il completamento di questa azione eliminerà definitivamente le tue collezioni insieme ai relativi contenuti. Tieni presente che questa azione non può essere annullata.',
  createCollection: ' è stato creato',
  shareCollection: 'Condividi collezione',
  shareWithTeam: 'Share in team',
  selectUsers: 'Seleziona utenti',
  searchUserName: 'cerca un nome utente',
  copy: 'Copia',
  titleCopyModal: 'Copia gli elementi selezionati su',
  collectionUppercase: 'COLLEZIONI',
  groupCollection: 'Gruppo',
  moveImage: 'Sposta gli elementi selezionati su',
  defaultSelect: 'Nome della collezione qui',
  moveLikebox: 'Move',
  messsageDeleteLikebox:
    'Completing this action will permanently delete your Collection(s) along with its contents. Be aware that this action cannot be undone.',
  areYouSure: 'Sei sicuro/a?',
  editCollection: 'Modifica collezione',
  collectionNameUpper: 'Nome collezione',
  deleteSuccessful: 'has been successfully deleted',
  edit: 'Modifica',
  messageRemoveImage: 'Sei sicuro/a di voler rimuovere gli elementi selezionati dalla collezione?',
  titleRemove: 'Cancella gli elementi selezionati',
  popupActionMove: ' item(s) has been moved to ',
  popupActionCopy: ' item(s)has been copied to ',
  popupActionRemove: ' item(s) has been removed from this Collection',
  popupFailed: 'Image already exists',
  popupShared: 'Your collection has been successfully shared',
  popupActionDelete: 'Your collection has been successfully removed',
  popupActionEdit: 'Your edit has been successfully updated',
  btnSave: 'Salva',
  shareLikebox: 'Condivisi con me',
  remove: 'Elimina',
  moveTo: 'Sposta a',
  createNewPlaceholder: 'es. Natura',
  shareViaEmail: 'Condividi via email',
  sharePublicly: 'Share publicly',
  receipnetEmail: 'Email destinatario',
  message: 'Messaggio',
  insertReceipmentEmail: 'Inserisci email del destinatario',
  messageText: 'Testo del messaggio',
  exportCollection: 'Esporta collezione',
  pdf: 'PDF',
  csv: 'CSV',
  addNewCollection: 'Add New Collection',
  likeboxEditSuccessful: 'You have successfully made changes to your collection.',
  format: 'Formato',
  lowCredit: 'Credito basso',
  downloadSuccess: 'Grazie per aver effettuato il download! Il download inizierà in un momento.',
  downloadFailed: 'Errore Download: Si è verificato un errore, si prega di riprovare.',
  downloadedLabel: 'Download effettuato',
  any: 'Qualsiasi',
  resetFilter: 'Ripristina filtro',
  enterCustomFieldTag: 'Enter custom field tags',
  // Portfolio
  follow: 'Segui',
  name: 'Nome',
  location: 'Posizione',
  since: 'Dal',

  // Account
  updated: 'Update',
  userName: 'NOME UTENTE',
  firstName: 'Nome',
  lastName: 'Cognome',
  myProfile: 'My Profile',
  changeProfiledetail: 'Modifica qui i dettagli del tuo profilo',
  userPrivileges: 'User Privileges',
  viewAllDownload: 'View all download history',
  viewDownloadCost: 'View download cost',
  accountManagerInfo: 'Account manager info',
  describeAccountmanager: 'Describe what this account manager do',
  creditsPlan: 'Credits plan',
  myPlan: 'Il mio piano',
  myOrganisation: 'My organisation plan',
  myAccountProfileDetails:
    'This are your profile details set by the organisation admin. If you wish to change your credentials, kindly contact admin.',
  usePayLater: 'Usa subito, paga dopo',
  bodyUNPL:
    'Usa subito. paga dopo (USPD) è un tipo di finanziamento a breve termine che consente ai clienti di effettuare acquisti e pagarli in una data futura, spesso senza interessi. Definito anche "prestito rateale del punto vendita".',
  firstnameCannotEmpty: 'First name cannot be empty.',
  lastnameCannotEmpty: 'Lastname cannot be empty.',

  noSpecialCharacters: 'This field cannot contain special characters',
  messageUpdateProfile: 'You had successfully updated the user information',
  bodyCredit:
    'Vitae morbi tristique luctus sed eget donec nibh in duis neque donec lectus turpis turpis aliquam facilisis mattis laoreet enim',
  contactYourAccount: 'Contact your account manager for further assistane',
  emailMaxLength75: 'The maximum characters allowed is 75',
  // audio player
  categoryGenre: 'Categoria / Genere',
  duration: 'Lunghezza',
  seeMore: 'Vedi di più',
  seeLess: 'Vedi di meno',
  audioID: 'Identificativo Audio',
  // category
  landscape: 'Paesaggi',
  Babies: 'Bambini',
  business: 'Business',
  food: 'Cibo',
  animals: 'Animali',
  technology: 'Tecnologia',
  healthcare: 'Salute',
  nature: 'Natura',
  romance: 'Romantico',
  // category vector
  graphic: 'Grafica',
  advertising: 'Pubblicità',
  flower: 'Fiori',
  abstract: 'Astratto',
  Illustration: 'Illustrazione',
  pop: 'Pop',
  bird: 'Uccelli',
  pixel: 'Pixel',
  threeD: '3D',

  // footage category
  education: 'Educazione',
  communication: 'Comunicazione',
  beauty: 'Bellezza',
  concept: 'Concetto',
  fitness: 'Fitness',
  lifestyle: 'Stile De Vita',
  celebration: 'Festeggiamento',
  family: 'Famiglia',
  fashion: 'Moda',

  // Download-history modal
  reDownload: 'Scarica di nuovo',
  chooseCustomTagOrCreate: 'Scegli tag personalizzati o creane uno nuovo',
  downloadDetails: 'Scarica dettagli',
  image: 'Image',
  downloadType: 'Tipo di download',
  groupName: 'Nome gruppo',
  size: 'Dimensione',
  dateDownloaded: 'Data del download',
  reLicense: 'Nuova Licenza',
  customFields: 'Campi personalizzati',
  saveAndClose: 'Salva e chiudi',
  characterMaxChar100: 'The maximum characters allowed is 100',
  characterNotAllow:
    'The maximum characters allowed is 100 and the minimum characters allowed is 1',
  dlwByMember: 'Dowloaded By Members',
  groupInactive: 'Group inactive',
  personalInactive: 'Personal inactive',
  date: 'data',
  export: 'Esporta',
  selectRange: 'select range',
  fileType: 'file type',
  downloadedLabelUNPL: 'Licensed',
  HRUCTab: 'Contenuti gratuiti',
  searchById: 'Ricerca per ID (identificativo)',
  pleaseSelect: 'Seleziona',
  totalOutstanding: 'Totale in sospeso',
  viewInvoices: 'Vedi Fattura',
  // Image detail page - select download size sidebar
  standardSizes: 'Dimensioni standard',
  extendedLicenseSizes: 'Extended License Sizes',
  crop: 'Ritaglio',
  // empty data
  opsNothingToSeeHere: 'Ops! Non c`è niente da vedere qui.',
  // search image
  authentic: 'Autentico',
  // search audio
  mostDownloaded: 'I Più Scaricati',
  download: 'Scarica',
  or: 'OPPURE',
  // audio category
  soulAndRB: 'Soul & R&B',
  corporate: 'Aziendale',
  electronica: 'Elettronica',
  classical: 'Classico',
  funkAndGrooveMusic: 'Musica Funk & Groove',
  worldBeat: 'World Beat',
  cinematic: 'Cinematografico',
  jazz: 'Jazz',
  flamenco: 'Flamenco',
  mothersDayLove: 'Mothers Day Love',
  happyUplifting: 'Happy Uplifting',
  epicHeroAdventure: 'Epic Hero Adventure',

  // search page
  nextPage: 'Pagina successiva',
  reverseImageSearch: 'Ricerca Tramite Immagini',

  group: 'Gruppo',
  needHelp: 'Hai bisogno di aiuto?',
  copied: 'Copiato!',
  copyPortfolioLinkSuccess: 'Contributor Link Portfolio copiato sugli appunti.',

  // my activities
  myActivity: 'Le Mie Attività',
  recentLike: 'I miei Mi piace recenti',
  recentView: 'Le mie visualizzazioni recenti',
  myActivityViewDesc: 'La cronologia delle visualizzazioni recenti memorizzerà solo fino a 3 mesi',
  myActivityLikeDesc: 'Mostra solo cronologia degli ultimi 3 mesi',

  // dashboard
  profile: 'Profilo',

  // Pricing
  pricingCreditText: 'Credito',

  // Dashboard - Credit Usage
  user: 'User',
  creditUsage: 'Credit usage',
  searchPlaceholder: 'Search name, username, role, group',
  all: 'Tutto',
  active: 'Active',
  deleted: 'Deleted',
  reset: 'Ripristina',
  totalAllocated: 'Total allocated',
  totalSpent: 'Total spent',
  balance: 'Balance',
  groupLowerCase: 'group',
  roleDefinitions: 'Role definitions',
  superAdmin: 'Super admin',
  admin: 'Admin',
  historySearchPlaceholder: 'Search history',
  somethingWentWrong: 'Something went wrong',
  information: 'Information',
  maximumBulkDownloadWarning: 'Bulk download allows maximum 50 items',
  tooltipCustomLogin: 'Please use at least 8 characters',
  maxLengthTitleCustom: 'Title should not be more than 25 characters',
  maxLengthDescCustom: 'Description should not be more than 50 characters',
  selectdItems: 'Selected media',
  aiPoweredSearch: "Ricerca ottimizzata con l'intelligenza artificiale",
  aiPoweredSearchOn: "La ricerca alimentata dall'A.I. è in corso",
  aiSearchIntroduction:
    'Prova il nostro motore di ricerca più veloce e più potente. La nostra ricerca A.I. farà il resto!',
  ok: 'OK',
  // Filter bar ai
  bstract: 'Astratto',
  macroCloseUp: 'Macro/Primo Piano',
  object: 'Oggetto',
  watercolor: 'Acquerello',
  flat: 'Piatto',
  cartoon: 'Cartone animato',
  geometric: 'Geometrico',
  gradient: 'Gradiente',
  isometric: 'Isometrico',
  '3d': '3D',
  handDrawn: 'Disegnato a mano',
  natural: 'Naturale',
  warm: 'Caldo',
  dramatic: 'Drammatico',
  vividAndBold: 'Vivace e audace',
  blackAndWhite: 'Bianco e nero',
  // introduce Free image research
  imageResearchContentTitle:
    'Presentiamo il servizio di ricerca immagini gratuito di 123RF: Immagini perfette, zero problemi',
  imageResearchContentDesc:
    "Rivoluzioniamo la ricerca di immagini per i clienti aziendali, rendendo semplice trovare l'immagine perfetta e dare vita alla tua visione.",
  submitRequest: 'Invia richiesta',
  imageResearchContentInfoTitle1: 'Ricerca di immagini resa semplice',
  imageResearchContentInfoDesc1:
    "Iniziare è facile. Basta fornirci una descrizione dettagliata dell'immagine desiderata, incluso lo stile, l'umore, i colori e gli elementi specifici. Più dettagli fornisci, più possiamo soddisfare al meglio le tue esigenze.",
  imageResearchContentInfoTitle2: 'Come funziona?',
  imageResearchContentInfoTitle3: 'Servizio veloce e affidabile',
  imageResearchContentInfoTitle4: 'Lascia che siamo noi a lavorare per te',
  imageResearchContentInfoDesc4:
    'Troviamo le immagini perfette per te, così tu puoi concentrarti sul tuo progetto. Affidati a noi per dare vita alla tua visione con immagini di alta qualità, accuratamente selezionate.',
  requestForm: 'Modulo di richiesta',
  whatDoYouWantThisImageFor: 'Per cosa vuoi usare questa immagine?',
  websiteBanner: 'Banner di siti web',
  socialMedia: 'Social Media',
  blogPost: 'Post di Blog',
  advertisement: 'Pubblicità',
  print: 'Stampa',
  others: 'Altro',
  othersOutputPlaceholder: 'Inserisci altri tipi di risultati',
  whatIsPrimaryMessage:
    "Qual è il messaggio principale o il tema che vuoi che l'immagine trasmetta?",
  whatIsPrimaryMessagePlaceholder: 'Esempio: Cane che corre con una donna nel parco',
  areThereSubjectYouWantInThePicture:
    "Ci sono soggetti o elementi specifici che vuoi siano inclusi nell'immagine?",
  egPeopleNatureTechnologyAbstract: 'Esempio: persone, natura, tecnologia, concetti astratti',
  areThereImageOrStyleReference:
    'Ci sono immagini o stili che hai notato in precedenza e che vorresti che usassimo come ispirazione o riferimento?',
  ifPeopleIncludeWhatTheirDemographic:
    'Se devono essere incluse delle persone, quale dovrebbe essere il loro profilo demografico?',
  egAgeGenderEthnicityOccupation: 'Esempio: età, genere, etnia, professione',
  areThereAnyThingToAvoid: "Ci sono elementi, soggetti o temi che vuoi evitare nell'immagine?",
  whoisYourTargetAudience: 'Qual è il tuo pubblico di destinazione?',
  whoisYourTargetAudiencePlaceholder: 'Esempio: Giovani adulti amanti della lettura',
  typeOfImage: 'Tipo di immagine',
  whatMoodShouldImageEvoke: "Che umore o emozione dovrebbe evocare l'immagine?",
  happy: 'Felicità',
  sad: 'Tristezza',
  calm: 'Calma',
  excited: 'Emozionante',
  angry: 'Rabbia',
  enterCustomMood: "Inserisci un'emozione personalizzata",
  areThereAnyOrientationForImage: "Ci sono formati specifici necessari per l'immagine?",
  enterCustomOrientation: 'Inserisci un formato personalizzato',
  isThereLocationYouWantImageToBeIn:
    "C'è una particolare location o ambientazione che vuoi nell'immagine?",
  no: 'No',
  yes: 'Sì',
  shareWithUsAReference: 'Condividi con noi un riferimento (solo JPG o PDF. Max 4MB)',
  replace: 'Sostituisci',
  areThereAnySpecificRequirements: 'Ci sono altri requisiti o preferenze specifiche?',
  enterOtherComments: 'Inserisci altri commenti',
  submit: 'Invia',
  fieldCannotEmpty: 'Questo campo non può essere vuoto',
  selectAtLeastOne: 'Selezionane almeno uno',
  fileMustBeJpgOrPdf: 'Il file deve essere un JPG o un PDF',
  fileSizeLessThan4MB: 'La dimensione del file deve essere inferiore a 4MB',
  maximumFileNameAllow255: 'Il numero massimo di caratteri consentiti è 255',
  updateSuccessfully: 'Abbiamo ricevuto la tua richiesta.',
  freeImageResearch: 'Ricerca gratuita di immagini',
  newService: 'Nuovo servizio!',
  freeImageResearchIntroduction:
    'Prova il nostro servizio di ricerca di immagini e scopri perché le nostre immagini corrispondono perfettamente alle tue esigenze.',
  later: 'Più tardi',
  letTryIt: 'Proviamolo!',
  viewPdfOfSampleOutput: 'Visualizza il pdf di un esempio di risultato',
  characterMaxChar255: 'Il numero massimo di caratteri consentiti è 255',

  imageResearchContentInfoDesc2Normal1: 'Our team combines',
  imageResearchContentInfoDesc2Strong1: 'AI technology',
  imageResearchContentInfoDesc2Normal2:
    'and experience to find ideal images for you and advanced algorithms narrow down options from our vast library based on your input. Our',
  imageResearchContentInfoDesc2Strong2: 'team of dedicated and experienced researchers',
  imageResearchContentInfoDesc2Normal3: 'refine the results to meet your criteria perfectly.',
  imageResearchContentInfoDesc3Normal1: 'We deliver results within',
  imageResearchContentInfoDesc3Strong1: '1 working day',
  imageResearchContentInfoDesc3Normal2:
    'providing a comprehensive explanation for image selections. Focus on your business while we take care of the rest.',
  office: 'Office',
  beach: 'Beach',
  city: 'City',
  mountains: 'Mountains',
  enterCustomLocation: 'Enter custom location',
  doYouNeedImgWithSpaceForTextOrOtherElement:
    'Do you need an image with space for text or other elements? If so, where should this space be located?',
  center: 'Center',
  topLeft: 'Top left',
  topRight: 'Top Right',
  bottomLeft: 'Bottom Left',
  bottomRight: 'Bottom Right',
  characterMin30: 'A minimum of 30 characters is required',
  required: 'is required',
  requiredWithVariable: 'Il {{var}} è richiesto',
  imageResearchContentInfoDesc2: (
    <>
      Il nostro team combina
      {' '}
      <strong>tecnologia A.I.</strong>
      {' '}
      ed esperienza per trovare le immagini
      ideali per te e gli algoritmi avanzati riducono le opzioni dalla nostra vasta libreria in base
      ai tuoi input. Il nostro team di ricercatori dedicati ed esperti affina i risultati per
      soddisfare perfettamente i tuoi criteri.
    </>
  ),
  imageResearchContentInfoDesc3: (
    <>
      Consegniamo risultati entro
      {' '}
      <strong>2 giorni lavorativi</strong>
      , fornendo una spiegazione
      completa per le selezioni di immagini. Concentrati sul tuo business mentre noi ci occupiamo
      del resto.
    </>
  ),
  mood: 'Mood',
  person: 'Persona',
  doNotIncludeWords_ex2: 'Ad es. Colore verde, capelli lisci',

  // Text to Image TTI page
  /// Landing page
  aiImageGenerator: "Generatore di Immagini con l'AI",
  aiImageGeneratorDesc: 'Creatività alla velocità della tua immaginazione.',
  generate: 'Crea',
  exploreVisualsCreatedWithThePowerOfAI: "Esplora le immagini create con il potere dell'A.I.",
  turnYourImaginationIntoRealityIn3SimpleSteps:
    'Trasforma la tua immaginazione in realtà con 3 semplici step',
  startWithGoodTextPrompt: (
    <>
      Inizia con un buon
      <br />
      {' '}
      <span>
        testo di
        <br />
        riferimento
      </span>
    </>
  ),
  startWithGoodTextPromptDesc:
    'Assicurati che includa prospettiva, soggetto, azione, parole chiave, illuminazione e stile.',
  reviewYourAIGeneratedImages: (
    <>
      Esamina le
      <br />
      immagini generate
      {' '}
      <br />
      <span>dall&apos;intelligenza artificiale</span>
    </>
  ),
  reviewYourAIGeneratedImagesDesc:
    'Il nostro generatore AI procederà con il creare e visualizzare 3 immagini di anteprima che si avvicinano maggiormente alla tua richiesta.',
  downloadAndLicenseVisuals: (
    <>
      Scarica e ottieni le
      {' '}
      <br />
      <span>
        immagini con
        {' '}
        <br />
        {' '}
        licenza d&apos;uso
      </span>
    </>
  ),
  downloadAndLicenseVisualsDesc:
    "Ora puoi scaricare e acquisire la licenza d'uso di queste immagini per uso personale e commerciale con una copertura legale di $ 25.000.",
  needSomeIdeas: 'Hai bisogno di alcune idee? Inizia da questi esempi e dai vita alle tue idee!',
  tryPrompt: 'Prova i prompt',
  creativityAtTheSpeedOfYourImagination: 'Creatività alla velocità della tua immaginazione.',
  genYourOwnAIImgFromTxtPromptsWCorpInSeconds:
    "Genera in pochi secondi le tue immagini con l'A.I. a partire da un testo con Corporate+!",
  tryNow: 'Prova ora',

  /// Random prompt
  prompt1:
    "Ritratto fotorealistico di un astronauta che fluttua nello spazio con la Terra sullo sfondo. Utilizza una fotocamera con un obiettivo da 200 mm con apertura F 1,2 per sfocare lo sfondo e isolare il soggetto. L'illuminazione deve essere drammatica e onirica, con il sole che illumina il viso e la tuta spaziale dell'astronauta. Concentrati sulla modalità fotorealismo attivata per creare un'immagine ultra-realistica che catturi la vastità e la bellezza dello spazio.",
  prompt2:
    "Un'opera a tecnica mista con un incantatore in una posa drammatica, con un mantello svolazzante e una bacchetta che emette scintille di magia, sullo sfondo di un paesaggio mistico e ultraterreno, con una varietà di texture e materiali.",
  prompt3:
    'Una giocosa illustrazione digitale di un bambino con le guance rosee e una soffice tutina, circondato da palloncini colorati e coriandoli, su uno sfondo allegro e colorato in toni pastello.',
  prompt4:
    "Disegno a grafite di un veggente cieco con un terzo occhio sulla fronte, che guarda in lontananza con un'espressione serena e mistica, con dettagli e ombreggiature intricate.",
  prompt5:
    "Un collage surrealista a tecnica mista di un cowboy che cavalca un toro meccanico gigante in una landa deserta, realizzato con riviste d'epoca, vecchie mappe e rottami metallici arrugginiti, con un tocco di umorismo e assurdità.",
  prompt6:
    'Illustrazione digitale di una regina vampiro con pelle pallida e occhi rosso sangue, che indossa un abito fluente ed è circondata da pipistrelli.',
  prompt7:
    'Un ritratto di una veggente cieca che tiene in mano una sfera di cristallo, ripreso con una drammatica illuminazione in chiaroscuro per accentuare i tratti del viso della veggente e i riflessi del cristallo.',
  prompt8:
    'Illustrazione fantasy di una regina sirena con lunghi capelli e una corona di conchiglie, che nuota in un oceano mistico pieno di creature bioluminescenti.',
  prompt9:
    "Un ritratto di strada di un venditore ambulante ispanico di frutta e verdura, catturato in un bianco e nero candido e grintoso, con una profondità di campo ridotta per enfatizzare l'espressione facciale del venditore.",
  prompt10:
    "Uno stravagante acquerello raffigurante una sirena che si adagia su una riva rocciosa, circondata dalle onde scintillanti dell'oceano e da giocose creature marine.",
  prompt11:
    'Bambina in stile 3d, 4k, 8k, rendering di ottano fotorealistico, hdr, fotografia, alta definizione, viso simmetrico, illuminazione volumetrica, foschia polverosa, foto, rendering di ottano, 24mm, 4k, 24mm, DSLR, alta qualità, 60 fps, ultra realistico',
  prompt12:
    'Soggiorno con porte-finestre che si aprono sul giardino, credenza in stile provenzale, piante, tappeto di iuta, parquet, divani con cuscini sopra, lampadario a gocce di cristallo, rivista di interior design, atmosfera calda, fotorealistico, luce realistica, grandangolo, molti dettagli',
  prompt13:
    'Pittoresca baita sul pendio della montagna, grandi finestre panoramiche, baita geometrica, vista completa, vista panoramica al crepuscolo, cielo drammatico al crepuscolo, cime sotto la neve, stelle, paesaggio montano innevato, bella vista, lunatico, nebbia, illuminazione volumetrica, atmosfera di montagna, fotorealismo, illuminazione volumetrica, realistico, rendering octane, ultra dettagliato, surrealistico, fotorealistico, ultrarealistico, 8k, illuminazione cinematografica',
  prompt14: 'Pittura ad acquerello di una nonna cool, che sorride perché sa di essere cool',
  prompt15:
    'Strada nel mezzo di una foresta blu intenso, foresta fitta, soffitto di foglie, di notte, stile fumetto, illustrazione per bambini',
  prompt16:
    'Un astronauta che nuota attraverso una nebulosa nello spazio + spazio, astronauta, onirico, simbolismo, surrealismo, simbolo, surreale, astratto, texture, concept art, 8k, ombreggiatura, raggi di luce, atmosferico',

  /// Tool list
  // ratio
  ratio: 'Formato',
  square1_1: 'Quadrato (1:1)',
  square: 'Quadrato',
  portrait2_3: 'Ritratto (2:3)',
  portrait: 'Ritratto',
  landscape3_2: 'Paesaggio (3:2)',
  wide16_9: 'Panoramico (16:9)',
  wide: 'Panoramico',
  // style
  none: 'Nessuno',
  enhance: 'Migliora',
  anime: 'Anime',
  photographic: 'Fotografico',
  digitalArt: 'Arte digitale',
  comicBook: 'Fumetto',
  fantasyArt: 'Arte fantasy',
  analogFilm: 'Pellicola analogica',
  neonPunk: 'Neon punk',
  lowPoly: 'Low poly',
  origami: 'Origami',
  lineArt: 'Line art',
  tileTexture: 'Texture',
  threeDModel: 'Modello 3D',
  pixelArt: 'Pixel art',
  craftClay: 'Plastilina',
  // color
  coolTone: 'Tonalità fredda',
  mutedColors: 'Colori tenui',
  pastelColors: 'Colori pastello',
  vibrantColors: 'Colori vibranti',
  warmTone: 'Tonalità calda',
  // lighting
  lighting: 'Illuminazione',
  backlight: 'Controluce',
  crepuscularRays: 'Raggi crepuscolari',
  dimlyLit: 'Poco illuminato',
  goldenHour: 'Tramonto',
  lowLight: 'Luce fioca',
  rimLighting: 'Contorni illuminati',
  studio: 'Studio',
  sunlight: 'Luce solare',
  volumetric: 'Volumetrico',
  // composition
  composition: 'Composizione',
  blurryBackground: 'Sfondo sfocato',
  closeUp: 'Primo piano',
  macrophotograph: 'Macrofotografia',
  narrowDepth: 'Profondità ristretta',
  shotFromAbove: "Ripreso dall'alto",
  shotFromBelow: 'Ripreso dal basso',
  wideAngle: 'Grandangolo',

  /// FQAs
  allAIImageQuestionsAnswered:
    "Tutte le tue domande sulle immagini generate con l'A.I. hanno una risposta.",
  TTI_Q1: "Cosa sono le immagini generate con l'A.I.?",
  TTI_A1:
    "Le immagini generate con l'A.I. sono immagini create utilizzando algoritmi di intelligenza artificiale (A.I.). Questi algoritmi sono progettati per simulare la creatività umana e possono generare immagini simili a quelle prodotte da artisti umani, ma con elementi unici e spesso surreali o astratti.",
  TTI_Q2: "C'è un limite al numero di immagini che posso generare ogni giorno?",
  TTI_A2:
    "Sì, esiste un limite al numero di immagini che è possibile generare ogni giorno. Il limite garantisce a tutti gli utenti un accesso equo e una buona esperienza d'uso quando si accede al servizio di generazione di immagini con l'AI.",
  TTI_Q3: "Accetta prompt in lingue diverse dall'inglese?",
  TTI_A3: 'Al momento supportiamo solo la lingua inglese.',
  TTI_Q4: "Qual è la differenza tra la generazione e la licenza di un'immagine?",
  TTI_A4:
    "Quando generi un'immagine, crei un set di immagini AI da visualizzare. Quando le acquisisci con licenza su Corporate+, ti viene garantito l'accesso al download di una versione ad alta risoluzione dell'immagine AI con la propria licenza di utilizzo.",
  TTI_Q5:
    "Un'immagine che ho generato contiene un marchio riconoscibile, un punto di riferimento o un personaggio pubblico. Posso ancora utilizzarla?",
  TTI_A5:
    "Se contiene una persona o oggetto riconoscibile o se si concentra principalmente su un edificio o monumento distintivo, dovresti ottenere il permesso di utilizzo direttamente dalla parte raffigurata nell'immagine, specialmente se intendi usarla in un contesto commerciale.",
  TTI_Q6: 'Chi sono le persone che appaiono nelle immagini generate?',
  TTI_A6:
    "Le persone che appaiono nelle immagini generate dall'intelligenza artificiale non sono persone reali, ma piuttosto creazioni digitali.",
  TTI_Q7: 'Cosa succede ai contenuti che ho generato? Saranno resi disponibili ad altri utenti?',
  TTI_A7:
    "I contenuti generati dall'utente possono essere resi disponibili anche ad altri utenti. Per ulteriori informazioni, consulta la nostra licenza.",
  TTI_Q8: "Perché il caricamento richiede un po' di tempo quando provo a generare un'immagine?",
  TTI_A8:
    "La pagina impiegherà alcuni secondi per generare l'immagine. Il tempo necessario dipende dalla complessità del prompt, dalle risorse hardware e di calcolo disponibili e dalla quantità di richieste che l'intelligenza artificiale deve gestire contemporaneamente.",
  TTI_Q9: "Come posso utilizzare le immagini AI che ho generato con licenza d'uso?",
  TTI_A9:
    "Potrai acquisire con licenza d'uso le immagini che hai creato scaricandole dal tuo account Corporate+. Con ogni download riceverai la versione ad alta risoluzione dell'immagine generata e una copertura legale di $25,000.",
  TTI_Q10: 'Posso vedere le immagini generate in precedenza?',
  TTI_A10:
    "Sì, è possibile farlo salvando nei preferiti e ripresentando l'URL delle immagini generate in passato. Puoi anche condividere i risultati con i tuoi amici.",
  TTI_Q11: "Come si generano immagini di alta qualità con l'AI?",
  TTI_A11: `1. Inizia con un obiettivo chiaro: "Immagine di alta qualità di un paesaggio montano per un blog di viaggi".

2. Specifica lo stile e l'atmosfera desiderati: "L'immagine deve essere luminosa e vibrante con un'atmosfera naturale".

3. Descrivi il soggetto: Sii specifici sul soggetto dell'immagine, includendo dettagli come l'ambientazione, l'illuminazione e qualsiasi altra caratteristica o elemento importante. Ad esempio, "Presenta una vetta innevata con un fiume tortuoso in primo piano e un cielo azzurro sullo sfondo".

4. Fornisci esempi: Includi esempi di altre immagini con uno stile o uno stato d'animo simile a quello ricercato. Questo può aiutare a guidare l'algoritmo dell'AI e a garantire che l'output soddisfi le tue aspettative.

5. Utilizza parole chiave pertinenti: "paesaggio montano", "picco innevato" o "luminoso e vibrante".

6. Evita ambiguità: cerca di essere il più possibile chiaro/a e specifico/a nel fornire istruzioni ed evita di usare un linguaggio vago o ambiguo che potrebbe portare a risultati inaspettati.`,

  /// Generating page
  bringingYourImagesToLife: 'Dare vita alle vostre immagini...',
  cplusAiGenerator: 'AI Generator',
  generatedImages: 'Immagini generate',
  promptSettings: 'Impostazioni prompt',
  pastGeneration: 'Generazioni passate',
  generateVariation: 'Genera variazione',
  iLikeThis: 'Mi piace',
  iDislikeThis: 'Non mi piace',
  shareOptions: 'Opzioni di condivisione',
  variations: 'Variazioni',
  copiedSharedUrl: 'URL condivisa copiata negli appunti.',

  galleryPrompt1: "Sirena eterea, sott'acqua, corona di conchiglie circondata da pesci, ritratto",
  galleryPrompt2: 'Arrosto della domenica a tavola, tanto cibo e candele',
  galleryPrompt3: 'Tenera volpe che sorride in una foresta, altamente dettagliata, zoom con altri animali del bosco',
  galleryPrompt4: "Alta qualità, 8K Ultra HD, note a forma di strumenti musicali all'interno di una zucca di cristallo, molto dettagliato, lampada magica a forma di zucca, mood autunnale, relax",
  galleryPrompt5: 'Panorama cinematografico della Scozia, giornata luminosa e spettacolare',
  galleryPrompt6: 'Ragazzina in stile 3D, 4k, 8k, rendering Octane fotorealistico, HDR, fotografia, alta definizione, viso simmetrico, illuminazione volumetrica, foschia polverosa, foto, rendering Octane, 24mm, 4k, 24mm, DSLR, alta qualità, 60 fps, ultra realistico',
  galleryPrompt7: 'Ragazzo dalla pelle scura, eroe che colpisce il pianeta Giove nello spazio, visto di fronte',
  galleryPrompt8: 'Biscotto nel caffè caldo, in un ambiente natalizio',
  galleryPrompt9: "Bottiglia d'acqua dall'aspetto artistico su uno sfondo bianco minimalista con foglie di Montserra",
  galleryPrompt10: 'Drago evanescente fatto di diamante',
  galleryPrompt11: 'Pianeti in gocce di rugiada, gocce di rugiada a forma di planeti, alba, primo piano, iperdettagliato, messa a fuoco nitida, foto in studio, dettagli intricati, molto dettagliato',
  galleryPrompt12: "Una gazza seduta su un cofanetto d'oro, stile arte fantasy realistica, pietre preziose, fiabe oscure, atmosfera misteriosa.",
  galleryPrompt13: 'Una montagna di neve e un sentiero di ghiaccio sulla cima della montagna che va nella direzione opposta, ultra hd, realistico, colori vivaci, altamente dettagliato, disegno UHD, penna e inchiostro, composizione perfetta, dettagli molto intricati, rendering octane, trend su artstation, 8k fotografia artistica, arte concettuale fotorealistica, luce naturale volumetrica e cinematografica',
  galleryPrompt14: "Civiltà interstellare, guardando dall'alto lo spazio digitale misterioso, edifici di città aliene, super fantascienza, Esterno bellissimo di grattacielo super, strada, ponte nel cielo, architettura super fantascientifica fluttuante nel cielo, macchina da guerra, alta tecnologia, innumerevoli luci di schermi elettronici",
  galleryPrompt15: "Bella spiaggia con l'oceano sullo sfondo nei Florida Keys di notte, colori al neon, sfondo scuro, onde calme, tonalità naturali, tramonto, relax, zoom sulle onde, spiaggia, linea dell'orizzonte diritta, rosa, blu, arancione, viola",
  galleryPrompt16: 'Migliore qualità, doppia esposizione, arte su cartoline, arte su carta rovinata, un primo piano di un tenero gattino che gioca con una palla di lana, interni dettagliati, scintillante, luce solare, raggi di sole, posa dinamica, illustrazione di un libro di fiabe, 2D, piatto, carino, adorabile, vintage, fiabesco, patchwork, vetrata, illustrazione dettagliata di un libro di fiabe, cinematografico, altamente dettagliato, piccoli dettagli, bellissimi dettagli, mistico, colori vibranti, sfondo complesso',
  galleryPrompt17:
    "piramide sull'acqua con riflessi di fuoco, montagne, surrealismo oscuro, ritratti atmosferici, fuoco",
  galleryPrompt18: 'panda rosso nella foresta, cinematografico',
  galleryPrompt19: 'mare, luna, pesce, cielo stellato, barca, illustrazione colorata',
  galleryPrompt20:
    'Riflessi urbani vivaci danzano tra  edifici e acqua scintillante in stile astratto minimalista, catturando la vivace energia della sera, astrazione geometrica, stile di arte pop contemporanea con illuminazione al neon e tecnica di versamento acrilico',
  galleryPrompt21:
    "vista posteriore di una donna in silhouette con spada luminosa, portale circolare nell'Artico che conduce a iceberg, inquadratura cinematografica, figura monumentale",

  /// error page
  copyrightDetected:
    'Termini di utilizzo per personaggi o marchi commerciali rilevati protetti da copyright',
  copyrightDetectedDesc:
    'Ci impegniamo a metter a disposizione un generatore di immagini AI responsabile e non verranno generati risultati che includono loghi, marchi, termini, brand o personalità protette da copyright nei prompt utilizzati per prevenire potenziali violazioni. I nostri Termini di Servizio stabiliscono che NON dovresti utilizzare i servizi in modo che violino qualsiasi tipo di diritto di proprietà intellettuale. Ti preghiamo di modificare i tuoi prompt e riprovare.',
  errorMesTTI: 'Ops! Ci dispiace, ma qualcosa è andato storto.',
  errorMesTTIDesc: 'Il problema è stato registrato per essere analizzato. Riprova più tardi.',
  tryAgain: 'Riprova',
  // hruc bulk download
  hrucBulkDownloadTitle: 'HRUC bulk download',
  hrucDownload: 'HRUC download',
  hrucBulkdownloadNotSupported: 'HRUC bulk download is not supported',
  // download feature
  downloadQuotaFinished: 'La quota di download è terminata',

  // search bar placeholder
  searchBarPlaceholderAllImageDesktop: 'Trova immagini incredibili. Cerca per parole chiave o frasi.',
  searchBarPlaceholderAllImageTablet: 'Trova immagini incredibili.',
  searchBarPlaceholderAllImageMobile: 'Trova immagini.',
  searchBarPlaceholderPhotoDesktop: 'Trova foto incredibili. Cerca per parole chiave o frasi.',
  searchBarPlaceholderPhotoTablet: 'Trova foto incredibili.',
  searchBarPlaceholderPhotoMobile: 'Trova foto.',
  searchBarPlaceholderVectorDesktop: 'Trova vettoriali incredibili. Cerca per parole chiave o frasi.',
  searchBarPlaceholderVectorTablet: 'Trova vettoriali incredibili.',
  searchBarPlaceholderVectorMobile: 'Trova vettoriali.',
  searchBarPlaceholderFootageDesktop: 'Trova video incredibili. Cerca per parole chiave o frasi.',
  searchBarPlaceholderFootageTablet: 'Trova video incredibili.',
  searchBarPlaceholderFootageMobile: 'Trova video.',
  searchBarPlaceholderAudioDesktop:
    'Trova file audio e musicali incredibili. Cerca per parole chiave o frasi.',
  searchBarPlaceholderAudioTablet: 'Trova file audio e musicali incredibili.',
  searchBarPlaceholderAudioMobile: 'Trova file audio e musicali.',
  searchBarPlaceholderFreeDesktop: 'Trova immagini gratuite incredibili. Cerca per parole chiave o frasi.',
  searchBarPlaceholderFreeTablet: 'Trova immagini gratuite incredibili.',
  searchBarPlaceholderFreeMobile: 'Trova immagini gratuite.',
  // editorial warning
  forEditorialUseOnly: 'Solo per uso editoriale',
  forEditorialUseOnlyDesc:
    "l'uso di questa immagine in pubblicità o per scopi promozionali è proibito a meno che non ci siano ulteriori liberatorie.",

  // download comp
  downloadHiResComp: 'SCARICA BOZZETTO IN ALTA RISOLUZIONE',
  downloadComp: 'SCARICA BOZZETTO',

  generateTerm: ({ callback }) => (
    <>
      By clicking “Generate”, you hereby agree that the generated Output is solely governed by the
      {' '}
      <span aria-hidden onClick={callback}>
        AI Generator Terms of Service.
      </span>
      {' '}
    </>
  ),
  textToImage: "Dal testo all'immagine",
  descTourTTI: 'Lascia volare la tua immaginazione e crea immagini a partire da un testo!',
  colorAndTone: 'Colore e tono',
  adultContentTitle: 'Potenziali termini di contenuti per adulti rilevati',
  adultContentDesc:
    "Siamo impegnati a fornire un'IA responsabile e pertanto non generiamo risultati per determinate frasi, concetti e/o idee. Ti preghiamo di modificare la tua richiesta (la descrizione fornita per generare l'immagine) e riprovare.",
  titleSuccessDownload: 'Siamo felici che tu abbia trovato quello di cui hai bisogno.',
  hangInThere: 'Resisti! Non ci vorrà molto',
  reachedTheLimit: "Oh no! Hai raggiunto il limite di generazione di immagini con l'AI per oggi.",
  backTomorrow: 'Torna domani per altre creazioni!',
  generateTermService: 'AI Generator Terms of Service',
  endUserLicenseAgreement: 'End user license agreement',
  aiPromptPlaceholder: 'Per iniziare, descrivi qui la tua visione e noi la daremo vita!',

  downloadAgain: 'Download again',
  btnClose: 'Chiudi',

  variousTitle: 'Scopri i nostri vari modelli',
  variousSDXL: 'Dai vita a incredibili soggetti e scenari fotorealistici.',
  variousEimisAnime: 'Modello di AI generativa pensato per creare immagini in stile anime.',
  variousDynaVisionXL: 'Genera personaggi stilizzati versione cartoon in 3D.',
  variousJuggernautXL: 'Focus su elementi architettonici e paesaggi/sfondi.',
  variousDreamShaperXL:
    'Ridimensionamento ad alta risoluzione, generazione di personaggi ed elementi migliorata, problemi di sfocature ridotti.',
  variousRealismEngineSDXL: 'Questo modello è specializzato in immagini dettagliate e realistiche.',
  variousDALLE:
    'DALL·E 3, un modello poliedrico e completo che eccelle nel trasformare concept in contenuti visivi.',
  aiEngine: 'Motore AI',
  'DALL-E': 'DALL-E 3',

  langText: 'Testo',
  langElements: 'Elementi',
  langAuto: 'Auto',
  langBgRemoval: 'Rimozione sfondo',
  resetAll: 'Resetta tutto',
  facebookImage: 'Immagine Facebook',
  facebookLink: 'Link Facebook',
  facebookCover: 'Copertina Facebook',
  facebookStory: 'Storia Facebook',
  instagramPost: 'Post Istagram',
  instagramStory: 'Storia Istagram',
  twitterPost: 'Post Twitter',
  twitterHeader: 'Header Twitter',
  pinterestPost: 'Post Pinterest',
  emailHeader: 'Header Email',
  presentation: 'Presentazione',
  presentationWide: 'Presentazione Wide',
  ebookCover: 'Copertina E-book',
  linkedinPost: 'Post LinkedIn',
  brochureCover: 'Copertina Brochure (A4)',
  postcard: 'Cartolina',
  photoPrint: 'Stampa Foto',
  youtubeProfileImage: 'Immagine Profilo YouTube',
  youtubeChannelCoverPhoto: 'Copertina Canale YouTube',
  langApplyBgRemoval: 'Attiva la Rimozione dello Sfondo',
  autoBnW: 'Auto B&N',
  autoAdjust: 'Auto Regolazione',
  autoPop: 'Auto Pop',
  original: 'Originale',
  addHeader: 'Aggiungi intestazione',
  addTitle: 'Aggiungi titolo',
  addBody: 'Aggiungi corpo del testo',
  addAssistive: 'Aggiungi testo di supporto',
  langCompare: 'Confronta',
  langEnableEditor: 'Modifica immagine',
  langSimilarStock: 'Immagini Simili',
  langTextUndo: 'Annulla',
  langTextRedo: 'Indietro',
  langTextFont: 'Font',
  langTextFontSize: 'Dimensione Font',
  langTextColor: 'Colore Testo',
  langTextHighlight: 'Evidenzia',
  langTextAlignment: 'Allineamento',
  langTextBold: 'Grassetto',
  langTextItalic: 'Corsivo',
  langTextUnderline: 'Sottolinea',
  langTextLineSpacing: 'Interlinea',
  langTextLetterSpacing: 'Spaziatura Caratteri',
  langBringForward: 'Porta in primo piano',
  langBringBackward: 'Porta indietro',
  langDuplicate: 'Duplica',
  langOpacity: 'Opacità',
  langDelete: 'Elimina',
  imageProperties: 'Proprietà immagini',
  showMore: 'Di più',
  showLess: 'Vedi meno',
  noEditCompareMsg: 'Non sono state apportate modifiche per effettuare un confronto.',
  stockPhoto: 'Foto stock',
  basicShape: 'Forma base',
  chitChat: 'Chat',
  shapes: 'Forme',
  elementChitChat: 'Elementi Chat',
  organicAbstractShapes: 'Forme astratte organiche',
  elementShapes: 'Forme elementi',
  langApplyingEdit: 'Elaborazione delle modifiche...',
  langTextAllFonts: 'Tutti i Font',
  langSearchFontType: 'Cerca Font',
  editorCompareImageBeforeTitle: 'Prima',
  editorCompareImageAfterTitle: 'Dopo',
  langSizePx:
    "Un pixel è l'unità base di un'immagine digitale. La dimensione dell'immagine, misurata in pixel, determina il livello di dettaglio e la dimensione del download.",
  langSizeDpi:
    'Dots per inch misura la risoluzione di stampa. Un livello di dpi più alto significa ottenere maggiori dettagli. Per la stampa, la risoluzione consigliata per tutte le immagini è di 300 dpi.',
  cautionTTI:
    "Attenzione: Abbiamo rilevato l'uso di frasi nel prompt che sono associate a marchi registrati, diritti d'autore, luoghi famosi e/o individui. Si noti che Lei è l'unico responsabile per l'uso del Contenuto, incluso il fatto che questo non violi alcuna legge applicabile o infranga diritti.",

  addImageReference: 'Aggiungi riferimento immagine (Opzionale)',
  uploadImageOnly: 'Carica solo JPG o PNG. Massimo 4MB.',
  dragAndDrop: 'Trascina e rilascia il file o',
  errorFileSize: 'Questo file è troppo grande. Il massimo consentito è 4MB.',
  adjustScale: "Scegli l'intervallo più adatto per generare risultati molto simili o diversi dalla tua immagine caricata. L'intervallo consigliato è da 7 a 15.",
  similar: 'Simile',
  different: 'Diverso',
  uploadNew: 'Carica nuovo',

  imageGuideGeneration: 'La tua immagine guiderà il tuo prompt durante la generazione.',
  notAvailableForEngine: 'Non disponibile per il motore AI selezionato.',

  imagePromptDescribe: "Per iniziare, fornisci un prompt dell'immagine che vuoi che la nostra IA generi descrivendo ciò che hai in mente.",
  fileTypeNotSp: 'Il tipo di file non è supportato.',
  theFileSizeTooSmall: 'La dimensione del file è troppo piccola. È consentito un minimo di 512px.',

  relatedSearchPhoto: 'Ricerche di foto stock correlate',
  STOCK_PHOTO_PATH: 'archivio-fotografico',
  STOCK_FOOTAGE_PATH: 'filmati-stock',
  STOCK_AUDIO_PATH: 'archivi-audio',
  CLIPART_VECTOR_PATH: 'clipart-vettori',
  ALL_IMAGES: 'Tutte le immagini',
  PHOTO: 'Photo',
  PHOTOS: 'Foto',
  VECTORS: 'Vettoriali',
  FOOTAGE: 'Video',
  AUDIO: 'Audio',
  LANG_MORETHANMILLION: 'DI CONTENUTI DIGITALI ROYALTY FREE',
  LANG_SVG_OR_EPS: 'SVG oppure EPS',
  LANG_OR: 'Oppure',
  STANDARD_LICENSE: 'Licenza Standard',
  EXTENDED_LICENSE: 'Licenza estesa',
  CREDIT_PACK: 'Pacchetto di Crediti',
  DOWNLOAD_PACK: 'Pacchetto di Download',
  SUBSCRIPTION_PLAN: 'Abbonamento',
  LANG_DOWNLOAD: 'Scarica',
  STOCKPHOTO: 'Archivio Fotografico',
  STOCK_PHOTO: 'Foto stock',
  STOCK_VECTOR: 'Vettoriali stock',
  PREMIUM_STOCKPHOTO: 'Foto PREMIUM',
  PLUS_STOCK_PHOTO: 'Foto PLUS',
  PLUS_STOCK_VECTOR: 'Vettoriali PLUS',
  MOBILE_STOCKPHOTO: 'Archivio di immagini',
  STOCKFOOTAGE: 'Filmati Stock',
  IMAGEID: 'ID Immagine',
  FONTID: 'ID',
  MEDIATYPE: 'Tipo di risorsa',
  COPYRIGHT_TEXT: "Diritto d'autore",
  KEYWORDS: 'Parole Chiave',
  LANG_CANCEL: 'Annulla',
  LANG_PURCHASE: 'Acquisto',
  LANG_STOCKVECTORSANDILLUS: 'Vettori stock e Illustrator',
  LANG_STOCKPHOTOSANDIMAGES: 'Foto e Immagini',
  LANG_MATCHES: 'abbinamento',
  LANG_VECTORRESULTS: '{number} {keyword} immagini vettoriali royalty free trovate per te.',
  LANG_PHOTORESULTS: '{number} {keyword} foto e immagini royalty free trovate per te.',
  LANG_SIMILARIMGS: 'Foto royalty free simili',
  LANG_COLLECTIONS: 'Collezioni',
  LANG_ALL: 'Tutto',
  LANG_STD: 'Standard',
  LANG_EXTD: 'Estesa',
  LANG_COMMERCIAL: 'Commerciale',
  LANG_SUBSCRIPTION: 'Abbonamento',
  LANG_EDITORIAL: 'Editoriali',
  LANG_UPLOADED: 'Caricati',
  LANG_FRESHNESS: 'Freschezza',
  LANG_ANYTIME: 'In qualsiasi momento',
  LANG_2DAYS: '2 days',
  LANG_PAST2DAYS: '< 2 giorni',
  LANG_PASTWEEK: 'La settimana scorsa',
  LANG_PRICE_PLUS_MONTH: "<span class='price'>{price}</span>/mese",
  LANG_PRICE_PLUS_YEAR: "<span class='price'>{price}</span>/anno",
  LANG_PASTMONTH: 'Lo scorso mese',
  LANG_PAST3MONTHS: '3 mesi',
  LANG_NUMOFPPL: 'Numero di persone',
  LANG_NOT_RECEIVE_VERIFICATION_EMAIL: "Non hai ricevuto l'email di verifica?",
  LANG_WITHOUTPEOPLE: 'Senza persone',
  LANG_PERSON: 'Persona',
  LANG_PEOPLE: 'Persone',
  LANG_INFANTS: 'Neonati',
  LANG_TODDLERS: 'Bambini',
  LANG_TEENS: 'Adolescenti',
  LANG_20S: '20s',
  LANG_30S: '30s',
  LANG_40S: '40s',
  LANG_50S: '50s',
  LANG_60S: '60s',
  LANG_70S: '70 +',
  LANG_CAUCASIAN: 'Caucasica',
  LANG_BLACK: 'Africana',
  LANG_HISPANIC: 'Ispanica',
  LANG_EAST_ASIAN: "Asia dell'Est",
  LANG_SOUTHEAST_ASIAN: 'Asia del Sud Est',
  LANG_INDIAN: 'Indiana',
  LANG_MIDDLE_EASTERN: 'Medio orientale',
  LANG_AGE: 'Età',
  LANG_ALL_SHAPE: 'Mostra tutto',
  LANG_ETHNICITY: 'Etnia',
  LANG_PEOPLEORMORE: '4 o più persone',
  LANG_COLOR: 'Colore',
  LANG_OTHEROPTION: 'Altre opzioni',
  SOLID_OR_TRANSPARENT_BACKGROUNDS: 'Sfondi uniformi/trasparenti',
  LANG_FILTER_BY: 'Filtra per',
  LANG_FILTER: 'Filtro',
  LANG_PHOTOGRAPHY: 'Fotografia',
  LANG_VECTORILLUST: 'Illustrazioni vettoriali',
  LANG_SORTBY: 'Ordina per',
  LANG_MORE: 'Di più',
  LANG_RELEVANCE: 'Rilevanza',
  LANG_RESEND: 'INVIA DI NUOVO',
  LANG_RESEND_VERIFICATION: "Invia di nuovo l'email di verifica",
  LANG_NEWEST: 'Novità',
  LANG_GEORANK: 'Posizione geografica',
  LANG_ORIENTATION: 'Orientamento',
  LANG_HORIZONTAL: 'Orizzontale',
  LANG_PORTRAIT: 'Ritratto',
  LANG_SQUARE: 'Quadrato',
  LANG_PANORAMA: 'Panorama',
  LANG_SELECTIVE_FOCUS: 'Messa a fuoco selettiva',
  LANG_PATTERN: 'Pattern',
  LANG_VIBRANCE: 'Vivace',
  LANG_VERIFICATION_DESCRIPTION: 'Devi verificare il tuo account prima di scaricare contenuti da 123RF. Controlla la tua email o clicca sul link di seguito se desideri un nuovo invio.',
  LANG_VERIFICATION_TITLE: 'Verifica la tua email.',
  VERIFICATIONRESETUNABLE: "Non è stato possibile inviare l'email. Contatta il nostro ",
  LANG_CHOOSEUPTO3COLORS: 'Massimo 3 colori',
  LANG_ISOLATED: 'Isolati',
  LANG_STYLE: 'Stile',
  LANG_SAFESEARCH: 'Ricerca sicura',
  LANG_ONLY_AUTHENTIC: 'Solo autentiche',
  LANG_EXCLUDE_PLACEHOLDER: 'es. formaggio',
  LANG_EXCLUDEWORDS: 'Non includere queste parole',
  LANG_EXCLUDE: 'Esclusi',
  LANG_INCLUDE_AICONTENT: "Includi contenuti generati dall'A.I.",
  VISUAL_SEARCH: 'Ricerca visiva',
  VISUAL_SEARCH_TOOLTIP_TITLE: '3 Modi semplici per personalizzare la ricerca visiva!',
  VISUAL_SEARCH_TOOLTIP_INFO_1: "1. Clicca sui puntini dell'immagine per identificare l'elemento di ricerca.",
  VISUAL_SEARCH_TOOLTIP_INFO_2: "2. Trascina la casella delle funzioni di ritaglio per ingrandire o ridurre l'area di ricerca.",
  VISUAL_SEARCH_TOOLTIP_INFO_3: "3. Premi 'X' per tornare alla ricerca",
  VISUAL_SEARCH_NO_RESULTS: 'La tua ricerca non ha prodotto alcun riscultato',
  LANG_RIS_TITLE: 'Ricerca Inversa Immagini',
  LANG_RIS_NOTFOUND: 'Nessun risultato trovato!',
  LANG_RIS_ERROR_HEADER: "L'immagine caricata contiene errori, assicurati che l'immagine soddisfi i seguenti criteri:",
  LANG_RIS_REFINE_SRCH_MSG: 'Ottimizza la tua ricerca con parole chiave',
  LANG_RIS_UPLOADED_IMAGE: 'Immagine caricata',
  LANG_TELL_ME_MORE: 'Dimmi di più',
  LANG_ENTERKEYWORD: 'Inserisci una parola chiave',
  LANG_SIZE: 'Dimensioni',
  LANG_STANDARD_SIZE: 'Dimensioni standard',
  LANG_EXTENDED_SIZE: 'TAGLIE ESTESE',
  LANG_SIZE_PX: "Un pixel è l'unità base di un'immagine digitale. La dimensione dell'immagine, misurata in pixel, determina il livello di dettaglio e la dimensione del download.",
  LANG_SIZE_DPI: 'Dots per inch misura la risoluzione di stampa. Un livello di dpi più alto significa ottenere maggiori dettagli. Per la stampa, la risoluzione consigliata per tutte le immagini è di 300 dpi.',
  LANG_SOCMED_SIZE: 'Formati Social media',
  LANG_COMPARE: 'Confronta',
  LANG_AUTO_ENHANCE: 'Regolazione automatica',
  LANG_AUTO: 'Auto',
  LANG_BG_REMOVAL: 'Rimozione sfondo',
  LANG_TEXT: 'Testo',
  LANG_ELEMENTS: 'Elementi',
  LANG_IMAGE_INFO: 'Informazioni Immagine',
  LANG_FOLLOW: 'Segui',
  LANG_FOLLOWED: 'Seguito',
  LANG_FOLLOWING: 'Following',
  FACEBOOK_IMAGE: 'Immagine Facebook',
  FACEBOOK_LINK: 'Link Facebook',
  FACEBOOK_COVER: 'Copertina Facebook',
  FACEBOOK_STORY: 'Storia Facebook',
  INSTAGRAM_POST: 'Post Istagram',
  INSTAGRAM_STORY: 'Storia Istagram',
  TWITTER_POST: 'Post Twitter',
  TWITTER_HEADER: 'Header Twitter',
  PINTEREST_POST: 'Post Pinterest',
  EMAIL_HEADER: 'Header Email',
  PRESENTATION: 'Presentazione',
  PRESENTATION_WIDE: 'Presentazione Wide',
  EBOOK_COVER: 'Copertina E-book',
  LINKEDIN_POST: 'Post LinkedIn',
  BROCHURE_COVER: 'Copertina Brochure (A4)',
  POSTCARD: 'Cartolina',
  PHOTO_PRINT: 'Stampa Foto',
  YOUTUBE_PROFILE_IMAGE: 'Immagine Profilo YouTube',
  YOUTUBE_CHANNEL_COVER_PHOTO: 'Copertina Canale YouTube',
  AUTO_COLOR: 'Auto-colore',
  BALANCE: 'Bilanciamento',
  AUTO_LEVEL: 'Auto-livello',
  AUTO_TONE: 'Auto-tono',
  ENRICH: 'Migliora',
  RESET: 'Ripristina',
  RESET_ALL: 'Resetta tutto',
  FILE_DOWNLOAD_OPTIONS: 'Opzioni scaricamento file',
  FILE_DOWNLOAD_INITIATED: 'Download del File Iniziato',
  IMAGE_PROPERTIES: 'Proprietà immagini',
  DOWNLOAD_DETAILS: 'Scarica proprietà',
  FILE_SIZE: 'Dimensioni file',
  LICENSE: 'Licenza',
  FILEFORMAT: 'Tipo file',
  CHANGE: 'Modifica',
  PRICING: 'Prezzi',
  FIND_QUALITY_IMAGE: 'Find quality images now',
  SAVE_AS: 'Salva come',
  IMAGE_USAGE: 'Utilizzo immagine',
  PRINT: 'Stampa',
  ELECTRONIC: 'Elettrinica',
  COMPREHENSIVE: 'Completa',
  DOWNLOAD_CREDITS: '<b>{quota}</b> Crediti dal tuo Crediti',
  BASIC_SHAPE: 'Forma base',
  CHIT_CHAT: 'Chat',
  SHAPES: 'Forme',
  ELEMENT_CHIT_CHAT: 'Elementi Chat',
  ORGANIC_ABSTRACT_SHAPES: 'Forme astratte organiche',
  ELEMENT_SHAPES: 'Forme elementi',
  BUY_SINGLE_IMAGE: 'Acquista una singola immagina <br/><b>{quota} crediti</b>',
  BUY_CREDIT_PACK: 'Acquista un pacchetto crediti e <br/><b>risparmia</b>',
  BUY_SINGLE_IMAGE_CREDIT: 'Acquista pacchetti di crediti <br/><b>{quota} crediti</b>',
  BUY_CREDIT_PACK_POINT_1: '{quota} Crediti a <b>{price}</b>',
  BUY_CREDIT_PACK_POINT_2: 'Nessun vincolo mensile.',
  BUY_CREDIT_PACK_POINT_3: '<b>12 mesi</b> di validità.',
  BUY_SINGLE_IMAGE_PSUB: 'Acquista una singola immagina <br/><b>{quota} download</b>',
  BUY_DOWNLOAD_PACK: 'Acquista un Download Pack e <br/><b>risparmia ancora di più</b>',
  BUY_DOWNLOAD_PACK_POINT_1: 'Download Pack di {quota} immagini a <b>{price}</b>',
  BUY_DOWNLOAD_PACK_POINT_2: 'Nessun vincolo mensile.',
  BUY_DOWNLOAD_PACK_POINT_3: '<b>12 mesi</b> di validità.',
  LOWEST_PRICE: 'Con gli abbonamenti, <br/><b>prezzi più competitivi</b>',
  LOWEST_PRICE_POINT_1: 'Prova 1 mese a <b>{price}</b>',
  LOWEST_PRICE_POINT_2: 'Scarica 10 foto o vettoriali.',
  LOWEST_PRICE_POINT_3: 'Nessun limite giornaliero, i download inutilizzati saranno trasferiti sul mese successivo',
  PER_IMAGE_SIZE: 'sul costo a immagine taglia <b>{size}</b>',
  PER_IMAGE_ANY: 'per immagini di <b>qualsiasi taglia</b>',
  PER_IMAGE: 'per immagini',
  VIEW_PRICING: 'Nuovi piani di acquisto e prezzi',
  DOWNLOAD_BEGIN: 'Il tuo download inizierà a breve. Si prega di attendere.',
  DOWNLOAD_NOT_STARTING: 'Il Download non è ancora iniziato?',
  SELECT_MIRROR: '[Seleziona un mirror]',
  SHOW_MORE: 'Di più',
  SHOW_LESS: 'Vedi meno',
  LICENSE_TYPE: 'Tipo di licenza',
  LICENSE_SUMMARY: 'Sommario Licenze',
  BACK: 'Indietro',
  LANG_VECTOR: 'Vettoriali',
  PREMIUM_VECTOR: 'Vettoriali PREMIUM',
  SCALE_ANY_SIZE: 'Ridimensione in qualsiasi dimensione',
  LANDINGPAGE_VECTOR_HEADLINE: 'Immagini Vettoriali, Illustrazioni, Clipart e Grafiche - 123RF',
  LANDINGPAGE_PHOTO_HEADLINE: 'Fotografie e Immagini Royalty Free ad Alta Risoluzione - 123RF',
  CATEGORIES: 'Categorie',
  LANDINGPAGE_PRICING_1: 'Crediti',
  LANDINGPAGE_PRICING_2: 'Abbonatevi',
  LANDINGPAGE_AS_LOW_AS: 'Prezzi convenientiI, a partire da',
  EMPTY_SEARCH_TITLE: 'Oops, la tua ricerca non ha prodotto alcun risultato.',
  LANG_SMALL: 'Small',
  LANG_MEDIUM: 'Medium',
  LANG_LARGE: 'Large',
  LANG_EXTRA_LARGE: 'Extra Large',
  LANG_LOW_CREDITS: 'Sembra che stai esaurendo i crediti per il download!',
  LANG_TOPUP: 'Top up',
  LANG_TOPUP_NOW: 'Ricarica adesso',
  SEO_DETAILS_TITLE_STOCKPHOTO: 'Immagini Stock - {description}. Image {mediaId}',
  SEO_DETAILS_TITLE_CLIPARTVECTOR: 'SVG, Vettoriale - {description}. Image {mediaId}',
  SEO_DETAILS_METADESC_STOCKPHOTO: 'Immagini di {description}. Image {mediaId}',
  SEO_DETAILS_METADESC_CLIPARTVECTOR: 'Illustrazioni vettoriali e clipart {description}. Image {mediaId}',
  SEO_DETAILS_TITLE_FREE_PHOTO: '{description} Immagine e Fotografia Gratis {mediaId}.',
  SEO_DETAILS_METADESC_FREE_PHOTO: 'Immagine gratis di {description}. Immagine gratuita {mediaId}.',
  SEO_DETAILS_TITLE_FREE_VECTOR: '{description} Vettoriali gratis e grafica {mediaId}.',
  SEO_DETAILS_METADESC_FREE_VECTOR: 'Vettoriale di {description} gratis. Grafica gratuita {mediaId}.',
  SEO_DETAILS_OGDESCRIPTION: '123RF - Milioni di immagini, vettoriali, video e file audio Royalty Free per ispirare i tuoi progetti creativi.',
  LIVECHAT_LOOKINGHELP: 'Hai bisogno di aiuto?',
  LIVECHAT_CHATWITHUS: 'Contattaci in Chat!',
  FREE_TRIAL: '<b>Get 10 Free Images</b> <br/>with Subscription Plan',
  LANG_RELATEDSEARCH: 'Ricerche correlate',
  LANG_RELATED_STOCKPHOTO_SEARCH: 'Ricerche di foto stock correlate',
  LANG_PROPERATTRIBUTE: 'Ecco la nostra attribuzione:',
  LANG_ATTRIBUTEDESC: "Si prega di attribuire questa immagine al suo autore inserendo la linea qui sotto in qualsiasi area all'interno del vostro prodotto, sito web o materiale collaterale",
  LANG_ATTRIBUTE_TITLE: "L'attribuzione è opzionale.",
  LANG_ATTRIBUTE_CREDIT: "Se vuoi menzionare l'autore, utilizza il testo qui sotto:",
  LANG_ATTRIBUTE_COPYLINK: 'Copia link',
  LANG_ATTRIBUTE_COPYTEXT: 'Copia il testo',
  LANG_LICENSE_AGREEMENT: '<a href={url}><b>Ecco</b></a> qui il contratto di licenza per questo download',
  LANG_NEXTPAGE: 'Pagina Successiva',
  LANG_IMAGE_NO_AVAILABLE: "L'immagine # {mediaId} non è più disponibile.",
  LANG_PHOTOGRAPHY_METAIMG_DESC: 'Cerca e scarica milioni di immagini in HD: Foto Stock, Immagini Royalty-Free.',
  LANG_VECTOR_METAIMG_DESC: 'Cerca e scarica milioni di immagini vettoriali: illustrazioni, vettoriali, clipart, disegni',
  LANG_ANOTHERMETAKWDEFAULT: 'royalty-free, foto stock, fotografia stock, immagini stock, illustrazioni, fotografie, vettoriali, audio, video, clip, filmati, stock video, stock audio, immagini, foto alta risoluzione, compra foto, acquista immagini, immagini editoriali, banca immagini',
  LANG_ANOTHERMETADESCDEFAULT: 'Cerca e scarica milioni di immagini e clip royalty-free in HD. 35000 nuovi contenuti al giorno. Pacchetti di crediti o abbonamenti su misura per tutte le tasche.',
  EMPTY_FILTERED_RESULT_TITLE: 'Ops, la tua ricerca con questi filtri non prodotto con alcun risultato.',
  EMPTY_FILTERED_RESULT_SUBTITLE: 'Prova a rimuovere alcuni filtri o prova una parola chiave diversa.',
  CLEAR_ALL_FILTER: 'Cancella tutti i filtri.',
  PROMOBAR_FREE10_TEXT: 'Get <b>10 FREE Images</b> when you get started on our 1 Month-Free Trial.',
  PROMOBAR_FREE10_BTNLABEL: 'START NOW',
  PROMOBAR_MASSIVE_MARKDOWN_TEXT: 'Prezzo super ridotto per tutti gli abbonamenti',
  PROMOBAR_MASSIVE_MARKDOWN_BTNLABEL: 'Guarda i piani',
  LANG_MODEL_RELEASED: 'Liberatoria Modelli',
  LANG_PROPERTY_RELEASED: 'Liberatoria Proprietà',
  LANG_MODEL_RELEASE: 'con Liberatoria per Modelli',
  LANG_PROPERTY_RELEASE: 'con Liberatoria per Proprietà',
  LANG_YES: 'Si',
  LANG_NO: 'No',
  LANG_MREXPLAIN: "Una liberatoria di immagine è un documento legale firmato dal/dai modello/i di un fotografo, che concede l'autorizzazione a pubblicare / diffondere / utilizzare la sua/loro immagine nella fotografia sotto una o altre forme.",
  LANG_PREXPLAIN: "Una liberatoria di immagine è un documento legale firmato dal proprietario dell'oggetto, soggetto o locale a cui è stata scattata una fotografia, che concede l'autorizzazione a pubblicare / diffondere / utilizzare la fotografia sotto una o altre forme.",
  LANG_DOWNLOADS: 'Download',
  LANG_OLDFILENAME: 'Vecchio nome del file',
  LANG_DOWNLOAD_PREVIEW: 'Scarica Bozzetto',
  LANG_SHARE: 'Condividi',
  LANG_RESTRICTIONS: 'Restrizioni',
  LANG_READ_MORE: 'Per saperne di più',
  LANG_EDITORIAL_USE: 'Solo per uso editoriale',
  LANG_EDITORIALDESCP: "Ad uso esclusivamente Editoriale: Questa immagine può essere utilizzata esclusivamente per scopi editoriali. È proibito l'utilizzo di questa immagine per scopi pubblicitari, promozionali o commerciali a meno che ulteriori liberatorie siano garantite da parte del licenziatario. 123RF.com non fornisce alcun servizio per ottenere liberatorie specifiche.",
  LANG_LOGO_OWNERSHIP: 'Diritti di proprietà del logo',
  LANG_PHOTO_SHOT_SMARTPHONE: 'Questa foto è stata scattata con uno smartphone.',
  LANG_VECTORNOLOGO: 'If you wish to use this as your company logo, we may arrange for a <b>rights buy out</b> on your behalf. Please contact us at <b>{phonenum}</b> to find out more!',
  LANG_DOWNLOAD_QUOTA: '<b>{quota}</b> Download dal tuo Piani di abbonamento',
  LANG_DOWNLOAD_SLOT: '<b>{quota}</b> Download dal tuo Pacchetto di Download',
  LANG_LOGO: 'Logo',
  USING_OLDER_BROWSER: 'Stai usando un browser più vecchio?',
  UPGRADE_NEWER_BROWSER: "Passa a una versione più recente per un'esperienza di navigazione migliore!",
  CLICK_TO_DOWNLOAD: 'Clicca per scaricare uno dei seguenti browser:',
  LANG_VECTOR_EDITABLE: 'File modificabili nei formati SVG ed EPS',
  PROMOBAR_GOOD15_TEXT1: 'Paga meno, scarica ancora di più. ',
  PROMOBAR_GOOD15_TEXT2: '15% di sconto su tutti i piani con:',
  PROMOBAR_GOOD15_BTNLABEL: 'GOOD15',
  PROMOBAR_SUMMER25_TEXT1: 'Paga meno, scarica ancora di più. ',
  PROMOBAR_SUMMER25_TEXT2: '25% di sconto su tutti i piani con il codice',
  PROMOBAR_SUMMER25_BTNLABEL: 'SUMMER25',
  PROMOBAR_HALLOW30_TEXT: '30% di sconto su tutti i piani con il codice',
  NO_EDIT_COMPARE_MSG: 'Non sono state apportate modifiche per effettuare un confronto.',
  SWITCH_TO_VECTOR_MSG: 'Il Editor di foto non è supportato per le immagini di dimensioni vettoriali. Tutte le modifiche andranno perse se si passa al formato vettoriale.',
  SWITCH_TO_XL_MSG: 'Il Editor di foto non è supportato per le immagini di dimensioni XL. Tutte le modifiche andranno perse se si passa al formato XL',
  UNAVAILABLE_FOR_THIS_SIZE: 'Non disponibile per il formato {size}.',
  SWITCH: 'CAMBIA',
  SWITCH_LICENSE_EXT_MSG: 'Vuoi prima scaricare questa immagine come {type}? Perderai tutte le possibilità di modifica se passi alla Licenza Estesa.',
  LANG_SESSION_EXPIRED: 'La tua sessione è scaduta. <a href={url}>Clicca qui</a> per accedere di nuovo',
  LANG_TEXT_COLOR: 'Colore Testo',
  LANG_SHAPE_ADD: 'Add Shape',
  LANG_TEXT_HIGHLIGHT: 'Evidenzia',
  LANG_TEXT_ALIGNMENT: 'Allineamento',
  LANG_TEXT_BOLD: 'Grassetto',
  LANG_TEXT_ITALIC: 'Corsivo',
  LANG_TEXT_UNDERLINE: 'Sottolinea',
  LANG_TEXT_BULLET: 'Elenco',
  LANG_TEXT_LINE_SPACING: 'Interlinea',
  LANG_TEXT_LETTER_SPACING: 'Spaziatura Caratteri',
  LANG_EDIT_COLOR: 'Edit color',
  LANG_BRING_FORWARD: 'Porta in primo piano',
  LANG_BRING_BACKWARD: 'Porta indietro',
  LANG_DUPLICATE: 'Duplica',
  LANG_OPACITY: 'Opacità',
  LANG_DELETE: 'Elimina',
  LANG_TEXT_ADD: 'Aggiungi Testo',
  LANG_TEXT_EDIT: 'Modifica Testo',
  LANG_TEXT_FONT_TYPE: 'Tipo Font',
  LANG_TEXT_FONT_SIZE: 'Dimensione Font',
  LANG_TEXT_ALL_FONTS: 'Tutti i Font',
  LANG_TEXT_UNDO: 'Annulla',
  LANG_TEXT_REDO: 'Indietro',
  LANG_TEXT_FONT: 'Font',
  LANG_SEARCH_FONT_TYPE: 'Cerca Font',
  LANG_SEARCH_SHAPE_TYPE: 'Cerca elementi',
  LANG_DONE: 'Fatto',
  ADD_HEADER: 'Aggiungi intestazione',
  ADD_TITLE: 'Aggiungi titolo',
  ADD_BODY: 'Aggiungi corpo del testo',
  ADD_ASSISTIVE: 'Aggiungi testo di supporto',
  STOCK_PHOTO_BANNER_TITLE: 'Milioni di immagini royalty free per creare tutto quello che vuoi!',
  STOCK_PHOTO_BANNER_DESC: 'Trovi tutte le immagini di cui hai bisogno per creare contenuti straordinari e dare vita alle tue idee.',
  STOCK_PHOTO_SUBSCRIBE_TITLE: 'Dai vita alle tue storie con le immagini giuste',
  STOCK_PHOTO_SUBSCRIBE_DESC: 'Ottieni accesso immediato a oltre 180 milioni di immagini per creare le tue storie. Le nostre immagini e le tue storie sono la combinazione perfetta per massimizzare il tuo potenziale creativo!',
  STOCK_PHOTO_CARD_DOWNLOAD_PACK: 'Pacchetto Download',
  STOCK_PHOTO_CARD_FROM_AS_LOW_AS: 'A partire da',
  STOCK_PHOTO_CARD_CREDIT: 'credit',
  STOCK_PHOTO_CARD_SUBSCRIPTION_PLAN: 'Abbonamento',
  STOCK_PHOTO_CARD_IMAGE: 'immagine',
  STOCK_PHOTO_CARD_SUBSCRIBE_NOW: 'Registrati subito',
  STOCK_PHOTO_CARD_BUY_NOW: 'Acquista subito',
  STOCK_PHOTO_POPULAR_ROYALTY: 'Categorie più richieste di immagini royalty free',
  STOCK_PHOTO_BROWSE_BEST_SELLING: 'Browse best-selling royalty free images',
  STOCK_PHOTO_DISCOVER_AFFORABLE: 'Scopri la convenienza dei prezzi e abbonamenti a disposizione per le immagini',
  STOCK_PHOTO_DISCOVER_NOW: "Dai un'occhiata",
  STOCK_PHOTO_VECTORS_FOOTAGE_AUDIO: 'Vectors, Footage, Audio for you',
  STOCK_PHOTO_VECTORS: 'Vectors',
  STOCK_PHOTO_ILLUSTRATION: 'Illustration',
  STOCK_PHOTO_PATTERN: 'Pattern',
  STOCK_PHOTO_CARTOON: 'Cartoon',
  STOCK_PHOTO_BABY: 'Baby',
  STOCK_PHOTO_FOOTAGE: 'Footage',
  STOCK_PHOTO_FOOTAGES: 'Footages',
  STOCK_PHOTO_BUILDING: 'Building',
  STOCK_PHOTO_HOTEL: 'Hotel',
  STOCK_PHOTO_CHILDREN: 'Children',
  STOCK_PHOTO_ABSTRACT: 'Abstract',
  STOCK_PHOTO_AUDIO: 'Audio',
  STOCK_PHOTO_AUDIOS: 'Audios',
  STOCK_PHOTO_TECHNO: 'Techno',
  STOCK_PHOTO_HOLIDAY: 'Holiday',
  STOCK_PHOTO_ACCOUSTIC: 'Accoustic',
  STOCK_PHOTO_LANDSCAPES: 'Paesaggi',
  STOCK_PHOTO_BABIES: 'Bambini',
  STOCK_PHOTO_BUSINESS: 'Business',
  STOCK_PHOTO_FOOD: 'Cibo',
  STOCK_PHOTO_ANIMALS: 'Animali',
  STOCK_PHOTO_TECHNOLOGY: 'Tecnologia',
  STOCK_PHOTO_HEALTHCARE: 'Salute',
  STOCK_PHOTO_NATURE: 'Natura',
  STOCK_PHOTO_ROMANCE: 'Romantico',
  CLIPART_VECTOR_EXPLORE_MILLIONS: 'Realizza le tue creazioni con milioni di vettoriali royalty free a portata di mano.',
  CLIPART_VECTOR_NEED_AN_EYE: 'Trovi illustrazioni vettoriali, infografiche, clipart, icone e molto altro per trasformare le tue fantastiche idee in realtà',
  CLIPART_VECTOR_FIND_VECTOR: 'Trovi i vettoriali giusti per esaltare i tuoi progetti.',
  CLIPART_VECTOR_FIND_VECTOR_DESC: 'Che si tratti di infografiche, illustrazioni, caricature, clipart, sfondi, icone o qualsiasi cosa tu abbia bisogno per creare immagini straordinarie: sono a disposizione proprio qui. Dai subito vita ai tuoi progetti!',
  CLIPART_VECTOR_ON_DEMAND: 'On Demand',
  CLIPART_VECTOR_FROM_AS_LOW_AS: 'From as low as',
  CLIPART_VECTOR_PER_CREDIT: '/ credit',
  CLIPART_VECTOR_BUY_SUBSCRIPTION: 'Buy Subscription',
  CLIPART_VECTOR_PER_IMAGE: '/ image',
  CLIPART_VECTOR_SUBSCRIBE_NOW: 'Subscribe Now',
  CLIPART_VECTOR_POPULAR_ROYALTY: 'Categorie popolari vettoriali royalty free',
  CLIPART_VECTOR_DISCOVER_AFFORDABLE: 'Scopri piani e prezzi convenienti per i vettoriali',
  CLIPART_VECTOR_DISCOVER_NOW: 'Discover Now',
  SPEND_AND_WIN_SUBTITLE: 'Every {currency} spent = 1 Entry',
  SPEND_AND_WIN_IMGALT: 'Spend & Win an iPhone 13 Pro',
  CLIPART_VECTOR_GRAPHIC: 'Grafica',
  CLIPART_VECTOR_ADVERTISING: 'Pubblicità',
  CLIPART_VECTOR_FLOWER: 'Fiori',
  CLIPART_VECTOR_ABSTRACT: 'Astratto',
  CLIPART_VECTOR_POP: 'Pop',
  CLIPART_VECTOR_BIRD: 'Uccelli',
  CLIPART_VECTOR_ILLUSTRATION: 'Illustrazione',
  CLIPART_VECTOR_PIXEL: 'Pixel',
  CLIPART_VECTOR_3D: '3D',
  LANG_VECTOR_SVG_EPS: 'Vettoriali, SVG ed EPS',
  FREE_UPGRADE: 'Upgrade gratuito',
  FREE_UPGRADE_MESSAGE: "Upgrade XL GRATUITO per l'intero abbonamento o pacchetto di download. Effettua subito il tuo acquisto.",
  LANG_FREE_IMAGE: '123RF Gratis',
  LANG_IMAGE_FREE: "Questa foto stock è gratuita per uso commerciale e personale. È richiesta l'attribuzione.",
  LANG_HOW_TO_ATTRIBUTE: "Come funziona l'attribuzione?",
  LANG_FREE_DOWNLOAD: 'Download gratuito',
  LANG_FREE_DOWNLOAD_BUTTON: 'FREE Scaricare',
  LANG_GO_PLUS_BUTTON: 'Scegli PLUS',
  LANG_ATTRIBUTION_IS_KEY: "L'attribuzione è un elemento importante per i creatori di contenuti che rendono il loro lavoro pubblicamente accessibile. I nostri creator apprezzano molto l'esposizione ottenuta dalle loro opere che vengono utilizzate, condivise e accreditate da milioni di utenti come te.",
  LANG_GET_ATTRIBUTION_LINK: "Copia questo link e posizionalo in un'area accanto a quella dove viene utilizzato il contenuto.",
  LANG_CREDIT_OWNER: 'Si prega di accreditare il Creator:',
  LANG_USE_ATTRIBUTION: 'Usa il link di attribuzione qui sotto.',
  LANG_ATTRIBUTION_LOCATION: 'Se non è possibile, ci sono altre aree come il footer del sito web, blog o newsletter oppure anche una specifica sezione dei credits del sito sarà sufficiente per citare il proprietario del {media_type}.',
  LANG_GOT_A_QUESTION: "Avete una domanda? <a href={url} target='_blank'><u>Contattaci</u></a>",
  LANG_ATTR_FREE_IMG_TITLE: 'Devi citare il proprietario di questa immagine gratuita.',
  LANG_ATTR_FREE_DOWNLOADING: 'Tieni duro! Non ci vorrà molto.',
  LANG_PLUS_CONTENT_1: 'Otterrai download illimitati',
  LANG_PLUS_CONTENT_2: 'Accesso a oltre 100 milioni di foto e vettoriali',
  LANG_PLUS_CONTENT_3: 'Illustrazioni 3D esclusive solo su 123RF!',
  LANG_PLUS_CONTENT_4: 'Download illimitati',
  LANG_PLUS_CONTENT_5: 'Libertà creativa senza limiti',
  FREE_VECTOR: 'Vettoriali FREE',
  FREE_STOCKPHOTO: 'Foto FREE',
  FREE_PUBLIC_DOMAIN_CC0: 'Pubblico Dominio CC0',
  BUY_5_DOWNLOAD_PACK: "<b>Offerta limitata!</b><br/>3 + <b><span style='color:red'>2 GRATIS</span></b> = 5 foto o vettoriali",
  BUY_5_DOWNLOAD_PACK_POINT_2: 'Scaricale in qualsiasi momento (fino a un anno)',
  BUY_5_GET_2_FREE: 'Ottieni 2 immagini GRATIS!',
  LANG_ASK_DOWNLOAD_PLUS: 'Vuoi scaricare questo contenuto PLUS?',
  LANG_SIGN_UP_PLUS: 'Registrati al nostro abbonamento 123RF PLUS',
  LANG_BENEFIT_PLUS: 'Approfitta del nostro piano PLUS a partire da soli',
  LANG_FOR_ONLY: "<span class='text'>Per soli</span> <span class='price'>{price}</span>/mese",
  LANG_FOR_ONLY_2: "<span class='text'>Per soli</span> <span class='price'>{price}</span>",
  LANG_RELEASES: 'Liberatoria',
  LANG_UNAVAILABLE: 'Non disponibile',
  LANG_ATTR_FREE_IMG_TITLE2: 'Siamo felici che hai trovato ciò di cui hai bisogno. 🎉',
  LANG_PLEASE_NOTE: "A questa immagine non è allegata alcuna liberatoria per modelli o proprietà. Pertanto l'uso commerciale è a tuo rischio.",
  LANG_PLEASE_ADVISED: "Si prega di notare che le immagini contenenti individui riconoscibili non vengono fornite con alcun rilascio. Per utilizzare queste immagini commercialmente, è necessario ottenere l'autorizzazione dalle parti pertinenti. Se l'immagine non contiene individui riconoscibili, non dovrebbero esserci problemi di copyright quando la usi commercialmente.",
  LANG_REACHED_LIMIT: 'Ops! Hai raggiunto il limite di download giornaliero.',
  LANG_COME_TOMORROW: 'Siamo entusiasti che vi piacciano le nostre immagini gratuite! Tornate domani per aggiornare il vostro limite di download. Grazie. 😊',
  LANG_DOWNLOAD_ERROR: "Ops! L'immagine non è stata scaricata a causa di un errore.",
  LANG_REFRESH_AND_TRY_AGAIN: "Aggiorna il browser e riprova. <a href={url} target='_blank'>Informaci</a> se il problema persiste.",
  LANG_REACHED_LIMIT_PLUS: 'Limite di download giornalieri raggiunto',
  LANG_COME_TOMORROW_PLUS: 'Hai raggiunto il limite giornaliero di download. Torna domani per continuare a scaricare contenuti. Grazie per il supporto!',
  SEE_YOU_TOMORROW: 'Ci vediamo domani!',
  ORIGINAL: 'Originale',
  'AUTO_B&W': 'Auto B&N',
  AUTO_ADJUST: 'Auto Regolazione',
  AUTO_POP: 'Auto Pop',
  NONE: 'Nessuno',
  LANG_CROP: 'Ritaglio',
  LANG_ENABLE_EDITOR: 'Modifica immagine',
  LANG_DISABLE_EDITOR: 'Chiudi',
  LANG_IMAGE_FREE_CC0: "Questa è un'immagine CC0. L'attribuzione non è richiesta. È possibile utilizzarlo in base ai <a class='linkCC0' href={url} target='_blank'>termini e alle condizioni della licenza CC0</a>.",
  LANG_PREVIEW: 'Anteprima',
  LANG_ATTRIBUTION_CC0_TITLE: 'Immagine CCO: attribuzione non richiesta',
  LANG_ATTRIBUTION_TITLE: 'Attribuzione richiesta',
  LANG_ATTRIBUTION_CONTENT: 'Il link di attribuzione verrà fornito dopo aver scaricato il file.',
  LANG_ATTRIBUTION_CC0_CONTENT: "L'attribuzione non è richiesta in quanto si tratta di un'immagine CCO.",
  LANG_SUBCRIBE_PREMIUM: 'Hai raggiunto il limite di download giornaliero! Per scaricare di più, iscriviti a 123RF PREMIUM.',
  LANG_REMOVE_ATTRIBUTION: "Vuoi rimuovere l'attribuzione?",
  LANG_SUBCRIBE_PLUS_ATTRIBUTION: "Abbonati a <img src={image} alt='123RF Plus Logo'> per avere accesso illimitato a 100 milioni di foto e illustrazioni senza attribuzioni richieste.",
  LANG_CREATOR_ATTRIBUTION: "Si prega di accreditare l'autore con il link sottostante.",
  LANG_GET_ATTRIBUTION_LINK_2: 'Copia questo link e posizionalo vicino allo spazio in cui viene utilizzato il contenuto, al footer del sito web o nella sezione che riporta le informazioni principali del sito.',
  LANG_APPLY_BG_REMOVAL: 'Attiva la Rimozione dello Sfondo',
  LANG_APPLYING_EDIT: 'Elaborazione delle modifiche...',
  LANG_APPLYING_EDIT_ERROR: 'Qualcosa è andato storto.',
  LANG_TRY_AGAIN: 'Vuoi riprovare?',
  LANG_SEARCHCOLLECTION_PREMIUM: 'Collezione completa',
  LANG_SEARCHCOLLECTION_PLUS: 'Collezione essenziale',
  LANG_SEARCHCOLLECTION_FREE: 'Collezione Base',
  LANG_SIMILARIMAGESPAGE_TITLE: 'Banca Immagini: Foto Stock e Vettoriali ad Alta Risoluzione - 123RF',
  LANG_SIMILARIMAGESPAGE_STOCKPHOTOS: 'Immagini Simili',
  LANG_SIMILARIMAGESPAGE_EMPTYRESULTS: 'Oops! Niente da vedere quì.',
  LANG_SIMILARIMAGESPAGE_UNAVAILABLEIMAGE: 'Immagine non disponibile',
  LANG_PER_IMAGE: '/Immagini',
  LANG_PLANS_AND_PRICING: 'Piani e prezzi',
  LANG_CHOOSE_BEST_PLAN: 'Scegli il miglior piano per le tue esigenze.',
  LANG_WANT_MORE_SAVING_1: 'Vuoi risparmiare di più?',
  LANG_WANT_MORE_SAVING_2: "Dai un'occhiata agli altri piani che partono da {price}/immagine",
  LANG_BEST_VALUE: 'Miglior rapporto qualità/prezzo, risparmi il {percentage}%',
  LANG_YEARLY_PLAN: 'Piano annuale',
  LANG_WITH_MONTHLY_PAYMENTS: 'con pagamenti mensili',
  LANG_GRAB_YEARLY_PLAN: 'Scegli un piano annuale e risparmia il {percentage}%.',
  LANG_PRICE_PER_MONTH: '{price} al mese',
  LANG_PRICE_PER_YEAR: "{price} all'anno",
  LANG_GET_X_EVERY_MONTH: 'Accedi a {quota} foto o vettoriali ogni mese',
  LANG_X_EVERY_MONTH: '{quota} foto o vettoriali<br>ogni mese',
  LANG_SINGLE_IMAGE_PURCHASE: "Acquisto di un'immagine singola",
  LANG_GET_ONLY_WHAT_YOU_NEED: 'Scarichi solo quello di cui hai bisogno al momento.',
  LANG_TOTAL_PRICE: 'Totale {price}',
  LANG_DOWNLOAD_IMMEDIATELY: "Scarica immediatamente l'immagine dopo il checkout.",
  LANG_NEXT: 'Successivo',
  LANG_TITLE: 'Banca Immagini: Foto Stock e Vettoriali ad Alta Risoluzione - 123RF',
  SIMILAR_STOCK_PHOTOS: 'Immagini Simili',
  EMPTY_SIMILAR_SEARCH_RESULTS: 'Oops! Niente da vedere quì.',
  UNAVAILABLE_IMAGE: 'Immagine non disponibile',
  LANG_DOWNLOAD_PACK: 'Pacchetto Download',
  LANG_GET_X_ANY_TIME: '{quota} foto o vettoriali in qualsiasi momento',
  LANG_X_CREDITS: '{total} Crediti',
  LANG_PER_CREDIT: '/Credit',
  LANG_GET_X_PHOTOS_AND_Y_VIDEOS_ANY_TIME: '{photoCount} foto{videoCount, plural, =0 {} other { o # video}} in qualsiasi momento',
  INCLUDES_STANDARD_LICENSE: 'Include la licenza standard',
  LANG_MEDIUM_SIZE: 'Formato medium',
  LANG_LARGE_SIZE: 'Formato large',
  LANG_EXTRA_LARGE_SIZE: 'Formato extra large',
  LANG_STANDARD_SIZES: 'Dimensioni standard',
  LANG_ROYALTY_FREE: 'Royalty Free',
  LANG_OPTIONS: 'Opzioni',
  LANG_BENEFIT_FROM_PLUS_PLAN: 'Approfitta del nostro piano PLUS a partire da soli',
  LANG_RELATED_FONT_SEARCH: 'Ricerche correlate',
  AI_GENERATED_PHOTO: "Immagine generata con l'A.I.",
  FONT_PRICING_CREDIT: 'Crediti',
  '3_FONTS_3_IMGS': '3 font <b>o</b> 3 immagini <b>oppure</b> 1 video',
  '9_FONTS_9_IMG_3_VIDEOS': '9 font <b>o</b> 9 immagini <b>oppure</b> 3 video',
  PURCHASE_AND_DOWNLOAD: 'Acquista e Scarica',
  FONT_PATH: 'fonts',
  FONT_ZIP: 'Font (otf, ttf)',
  FONT_PACKS_LICENSE: 'Pacchetto di Licenze 123RF',
  FONT_META_DESC: 'Scarica font e caratteri tipografici {product_title} e crea grafiche mozzafiato per stampa e  web.',
  FONT_META_TITLE: 'Font - {product_title} | 123RF',
  FONT_SIMILAR: 'Font simili',
  LANG_PLANS: 'PIANI',
  LANG_I_WANT_THIS: 'I want this',
  LANG_AI_VARIATION_TOOLTIP_TITLE: 'Prova a creare variazioni',
  LANG_AI_VARIATION_TOOLTIP_DESCRIPTION: "Genera nuove immagini sulla base delle immagini originali caricate dai nostri contributor. È un nuovo modo per premiare i nostri contributor ogni volta che un'immagine generata con l'AI viene concessa in licenza!",
  LANG_SIZEBAR_TOOLTIP_DESCRIPTION: 'La nostra community ti ringrazia. I costi delle licenze verranno ripartiti con i contributor di 123RF.',
  LANG_CONTRIBUTORS_AI_VARIATION: "I contributor di 123RF autori dell'immagine originale vengono ricompensati per ogni variazione AI concessa in licenza.",
  LANG_AI_VARIATION_LIMIT: 'Oh no! Hai raggiunto il limite di generazione di immagini AI per oggi.',
  LANG_GENERATE_COMEBACK: 'Torna domani per altre creazioni!',
  LANG_LICENSE_AND_DOWNLOAD: 'Licenza e download',
  LANG_CONFIRM_OK: 'OK',
  LANG_AI_SWITCH_CONTENT: 'Il Editor di foto  non è supportato per le variazioni AI. Tutte le modifiche andranno perse se si passa alle variazioni AI.',
  LANG_GENERATE_VARIATIONS: 'Genera variazioni',
  LANG_GENERATE_WITH_AI: "Genera immagini con l'intelligenza artificiale ",
  LANG_NEW: 'Nuovo',
  LANG_AI_GENERATOR_LICENSE: "Termini di Servizio del Generatore di Immagini con l' A.I. di 123RF (“Termini”)",
  LANG_I_UNDERSTAND: 'Ho compreso',
  LANG_WHAT_IS_THIS: "Che cos'è?",
  LANG_AUTHENTIC: 'Autentico',
  LANG_ABSTRACT: 'Astratto',
  LANG_MACRO_CLOSEUP: 'Macro/Primo Piano',
  LANG_OBJECT: 'Oggetto',
  LANG_WATERCOLOR: 'Acquerello',
  LANG_FLAT: 'Piatto',
  LANG_CARTOON: 'Cartone animato',
  LANG_GEOMETRIC: 'Geometrico',
  LANG_GRADIENT: 'Gradiente',
  LANG_ISOMETRIC: 'Isometrico',
  LANG_3D: '3D',
  LANG_HAND_DRAWN: 'Disegnato a mano',
  LANG_NATURAL: 'Naturale',
  LANG_WARM: 'Caldo',
  LANG_DRAMATIC: 'Drammatico',
  LANG_VIVID_BOLD: 'Vivace e audace',
  LANG_BLACK_WHITE: 'Bianco e nero',
  LANG_ASIAN: 'Asiatico',
  LANG_AFRICAN_AMERICAN: 'Afroamericano',
  LANG_STANDARD: 'Standard',
  LANG_EXTENDED: 'Estesa',
  LANG_STANDARD_CONTENT_1: 'Visualizzazioni illimitate per social media, web, email e dispositivi mobili.',
  LANG_STANDARD_CONTENT_2: 'Fino a 500.000 stampe.',
  LANG_STANDARD_CONTENT_3: 'Non utilizzabile per prodotti in vendita.',
  LANG_EXTENDED_CONTENT_1: 'Visualizzazioni illimitate per social media, web, email e dispositivi mobili.',
  LANG_EXTENDED_CONTENT_2: 'Stampe illimitate.',
  LANG_EXTENDED_CONTENT_3: 'Utilizzabile su prodotti in vendita.',
  LANG_ORIGINAL_IMAGE: 'Immagine originale',
  LANG_IMPORTANT_NOTE: 'Nota importante',
  LANG_AI_ORIGINAL_ALTERED: "Le variazioni AI sono varianti dell'immagine originale che sono state modificate utilizzando la tecnologia AI. È simile a avere un editor AI che ritocca l'immagine da te scelta.",
  LANG_GENERATE_VARIATIONS_NOTE: "Poiché ci troviamo attualmente nella fase Beta delle Variazioni di Immagini create con l'AI, le immagini generate possono produrre risultati inaspettati. Stiamo lavorando duramente per garantire che tali risultati e variazioni rispettino le linee guida etiche e responsabili dell'A.I.",
  LANG_LICENSE_AI_EDITED: "Quando si acquista una licenza d'uso per una di queste versioni modificate con l'intelligenza artificiale, al titolare dei diritti d'autore dell'immagine originale viene pagata una commissione.",
  LANG_MOOD: 'Mood',
  LANG_AI_EXCLUDE_PLACEHOLDER: 'Ad es. Colore verde, capelli lisci',
  LANG_SIMILAR_STOCK: 'Immagini stock simili',
  LANG_PREMIUM_TAG_TOOLTIP: 'PREMIUM subscription only',

  WITH_MODEL_RELEASE: 'con Liberatoria per Modelli',
  WITH_PROPERTY_RELEASE: 'con Liberatoria per Proprietà',
  MODEL_RELEASE_CONTENT: "Una liberatoria di immagine è un documento legale firmato dal/dai modello/i di un fotografo, che concede l'autorizzazione a pubblicare / diffondere / utilizzare la sua/loro immagine nella fotografia sotto una o altre forme.",
  PROPERTY_RELEASE_CONTENT: "Una liberatoria di immagine è un documento legale firmato dal proprietario dell'oggetto, soggetto o locale a cui è stata scattata una fotografia, che concede l'autorizzazione a pubblicare / diffondere / utilizzare la fotografia sotto una o altre forme.",

  variousStockGAI: 'Un modello di intelligenza artificiale conforme alla legge, addestrato con milioni di foto stock e ottimizzato per le esigenze aziendali, offrendo tranquillità con copertura assicurativa.',
  extendedLicense: 'Estensione Licenza',
  sizes: 'Taglie',

  downloadMail1st: "Il nostro fornitore del servizio di ingrandimento immagini vi invierà via e-mail all'indirizzo",
  downloadMail2nd:
    'le istruzioni per scaricare il file ingrandito finale entro il giorno lavorativo successivo',

  aiGenarated: "Immagine generata con l'A.I.",

  variousOpenJourney: 'Il modello AI genera arte che assomiglia allo stile distintivo di Midjourney.',
  variousTurboVision: 'Generazione ad alta velocità a risoluzioni "normali" XL, migliorando la qualità complessiva.',
  variousRealVisXL: 'Il modello mira a ottenere risultati fotorealistici con precisione ed accuratezza.',
  variousPortraitPlus: 'Modello con composizione ritratto uniforme, ideale per un rapporto di aspetto 1:1.',
  enterVirifiedCode: 'Inserisci il codice di verifica',
  verify: 'Verifica',
  didNotGetCode: 'Non hai ricevuto un codice?',
  clickToResend: 'Clicca per rinviare',
  otpCannotEmpty: 'OTP non può essere vuoto.',
  sentCodeToMail: 'Abbiamo inviato un codice alla tua email registrata.',
  invalidCode: 'Codice non valido.',
  expiredOTP: 'OTP scaduto, si prega di inviare di nuovo.',
  sendEmail: 'L\'email è stata inviata con successo, controlla la tua casella di posta per procedere.',
  remainingTime: 'Tempo rimanente: ',
  tooManyAttempts: 'Troppi tentativi. Si prega di attendere 5 minuti prima di riprovare.',

};
export default it;
