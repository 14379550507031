import React from 'react';
import PropTypes from 'prop-types';
import { BLACK_BORDER_COLOR } from 'constants/index';

const IcUnMuted = ({
  fillcolor,
  width,
  height,
}) => (
  <svg width={width} height={height} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.00098 7.375H24.8024V40.857H4.00098V7.375Z" fill="#212121" />
    <path fillRule="evenodd" clipRule="evenodd" d="M5.26474 16.7087H12.5386C12.8642 16.7087 13.1753 16.5852 13.4093 16.3667L22.6656 7.71938C23.4712 6.9665 24.8024 7.52938 24.8024 8.62187V39.6085C24.8024 40.7485 23.3771 41.29 22.6005 40.4469L14.7767 31.961C14.538 31.6997 14.1979 31.5525 13.841 31.5525H5.26474C4.56533 31.5525 4.00098 30.9944 4.00098 30.308V17.9533C4.00098 17.2669 4.56533 16.7087 5.26474 16.7087Z" fill={fillcolor} />
    <path fillRule="evenodd" clipRule="evenodd" d="M37.3539 13.3882L35.5065 15.2075C35.1712 15.5376 34.6358 15.8559 35.0337 16.452C36.4036 18.5206 43.1855 25.2205 35.2243 33.3287C34.9011 33.6565 35.0313 34.1481 35.5209 34.5519L37.3539 36.3569C37.7446 36.7416 38.4054 36.7392 38.772 36.3331C44.6639 29.8209 44.6639 19.9242 38.772 13.412C38.4054 13.0059 37.7446 13.0035 37.3539 13.3882Z" fill={fillcolor} />
    <path fillRule="evenodd" clipRule="evenodd" d="M29.2596 21.359C28.9919 21.6226 28.9992 22.0264 29.2235 22.3256C29.7709 23.0595 30.0676 23.943 30.0676 24.874C30.0676 25.8026 29.7709 26.6861 29.2235 27.42C28.9992 27.7192 28.9919 28.123 29.2596 28.3866L31.3362 30.4315C31.6304 30.7212 32.1369 30.7212 32.4046 30.4054C33.7262 28.8569 34.4449 26.9189 34.4449 24.874C34.4449 22.8268 33.7262 20.8888 32.4046 19.3403C32.1369 19.0244 31.6304 19.0244 31.3362 19.3141L29.2596 21.359Z" fill={fillcolor} />
  </svg>

);
IcUnMuted.propTypes = {
  fillcolor: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

IcUnMuted.defaultProps = {
  fillcolor: BLACK_BORDER_COLOR,
  width: '24',
  height: '24',
};
export default IcUnMuted;
