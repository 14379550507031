import React from 'react';
import PropTypes from 'prop-types';
import { BLACK_BORDER_COLOR } from 'constants/index';

const IcPauseAudio = ({
  fillcolor,
  width,
  height,
}) => (
  <svg width={width} height={height} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M20 11H14C12.8954 11 12 11.8954 12 13V34C12 35.1046 12.8954 36 14 36H20C21.1046 36 22 35.1046 22 34V13C22 11.8954 21.1046 11 20 11Z" fill={fillcolor} />
    <path d="M34.1667 11H28.1667C27.0622 11 26.1667 11.8954 26.1667 13V34C26.1667 35.1046 27.0622 36 28.1667 36H34.1667C35.2713 36 36.1667 35.1046 36.1667 34V13C36.1667 11.8954 35.2713 11 34.1667 11Z" fill={fillcolor} />
  </svg>
);
IcPauseAudio.propTypes = {
  fillcolor: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

IcPauseAudio.defaultProps = {
  fillcolor: BLACK_BORDER_COLOR,
  width: '24',
  height: '24',
};
export default IcPauseAudio;
