import PropTypes from 'prop-types';
import { ICON_COLOR_DEFAULT } from 'constants/index';

const IcPhotos = ({
  fillcolor,
  width,
  height,
}) => (
  <>
    <svg width={width} height={height} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M13.3211 9.27273C11.0883 9.27273 9.27273 11.0899 9.27273 13.3219V34.6781C9.27273 36.9101 11.0883 38.7273 13.3211 38.7273H34.6789C36.9109 38.7273 38.7273 36.9101 38.7273 34.6781V13.3219C38.7273 11.0899 36.9109 9.27273 34.6789 9.27273H13.3211ZM34.6789 42H13.3211C9.28336 42 6 38.7158 6 34.6781V13.3219C6 9.28418 9.28336 6 13.3211 6H34.6789C38.7158 6 42 9.28418 42 13.3219V34.6781C42 38.7158 38.7158 42 34.6789 42Z" fill="#BCBEC0" />
      <path fillRule="evenodd" clipRule="evenodd" d="M9.27271 33.8174L14.1539 27.2727L20.7273 33.8182L9.27271 33.8174Z" fill={fillcolor} />
      <path fillRule="evenodd" clipRule="evenodd" d="M12.524 32.1819H16.7743L14.3356 29.7561L12.524 32.1819ZM20.7346 35.4545H9.26584C8.64841 35.4545 8.08475 35.1044 7.80862 34.5505C7.53249 33.9958 7.59195 33.3322 7.96257 32.8373L12.8498 26.292C13.1325 25.9132 13.5634 25.6752 14.0342 25.6416C14.4985 25.6032 14.9644 25.7774 15.2992 26.1096L21.8807 32.6548C22.3498 33.1212 22.4924 33.828 22.2415 34.4417C21.9898 35.0536 21.3952 35.4545 20.7346 35.4545Z" fill={fillcolor} />
      <path fillRule="evenodd" clipRule="evenodd" d="M19.0908 33.8182L28.909 22.3636L38.7272 33.8182H19.0908Z" fill={fillcolor} />
      <path fillRule="evenodd" clipRule="evenodd" d="M22.6489 32.1757H35.1694L28.9087 24.8572L22.6489 32.1757ZM38.7268 35.4545H19.0906C18.4516 35.4545 17.8715 35.0816 17.604 34.5004C17.3373 33.9192 17.433 33.2339 17.8486 32.7486L27.6667 21.2726C28.2885 20.5455 29.5297 20.5455 30.1515 21.2726L39.9696 32.7486C40.3853 33.2339 40.481 33.9192 40.2143 34.5004C39.9467 35.0816 39.3666 35.4545 38.7268 35.4545Z" fill={fillcolor} />
      <path fillRule="evenodd" clipRule="evenodd" d="M22.3636 17.4546C22.3636 15.6472 20.8982 14.1818 19.0908 14.1818C17.2835 14.1818 15.8181 15.6472 15.8181 17.4546C15.8181 19.2619 17.2835 20.7273 19.0908 20.7273C20.8982 20.7273 22.3636 19.2619 22.3636 17.4546Z" fill={fillcolor} />
      <path fillRule="evenodd" clipRule="evenodd" d="M13.3211 9.27273C11.0883 9.27273 9.27273 11.0899 9.27273 13.3219V34.6781C9.27273 36.9101 11.0883 38.7273 13.3211 38.7273H34.6789C36.9109 38.7273 38.7273 36.9101 38.7273 34.6781V13.3219C38.7273 11.0899 36.9109 9.27273 34.6789 9.27273H13.3211ZM34.6789 42H13.3211C9.28336 42 6 38.7158 6 34.6781V13.3219C6 9.28418 9.28336 6 13.3211 6H34.6789C38.7158 6 42 9.28418 42 13.3219V34.6781C42 38.7158 38.7158 42 34.6789 42Z" fill="#BCBEC0" />
      <path fillRule="evenodd" clipRule="evenodd" d="M13.3211 9.27273C11.0883 9.27273 9.27273 11.0899 9.27273 13.3219V34.6781C9.27273 36.9101 11.0883 38.7273 13.3211 38.7273H34.6789C36.9109 38.7273 38.7273 36.9101 38.7273 34.6781V13.3219C38.7273 11.0899 36.9109 9.27273 34.6789 9.27273H13.3211ZM34.6789 42H13.3211C9.28336 42 6 38.7158 6 34.6781V13.3219C6 9.28418 9.28336 6 13.3211 6H34.6789C38.7158 6 42 9.28418 42 13.3219V34.6781C42 38.7158 38.7158 42 34.6789 42Z" fill={fillcolor} />
      <path fillRule="evenodd" clipRule="evenodd" d="M40.3637 33.8182V34.6789C40.3637 37.8183 37.8184 40.3636 34.679 40.3636H13.3212C10.1818 40.3636 7.63647 37.8183 7.63647 34.6789V33.8182" fill={fillcolor} />
    </svg>
  </>
);
IcPhotos.propTypes = {
  fillcolor: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

IcPhotos.defaultProps = {
  fillcolor: ICON_COLOR_DEFAULT,
  width: '48',
  height: '48',
};
export default IcPhotos;
