import PropTypes from 'prop-types';
import { MAIN_COLOR } from 'constants/index';

const IcChecked = ({
  fillcolor,
  width,
  height,
}) => (
  <svg width={width} height={height} viewBox="0 0 20 20" fill={fillcolor} xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M8.42112 13.6842C8.18532 13.6842 7.94999 13.5944 7.76992 13.4143L5.0067 10.6511C4.64702 10.2915 4.64702 9.70843 5.0067 9.34875C5.36638 8.98907 5.94942 8.98907 6.3091 9.34875L8.42112 11.4608L13.2964 6.58555C13.656 6.22587 14.2391 6.22587 14.5988 6.58555C14.9584 6.94522 14.9584 7.52826 14.5988 7.88794L9.07232 13.4143C8.89225 13.5944 8.65691 13.6842 8.42112 13.6842Z" fill={fillcolor} />
  </svg>
);
IcChecked.propTypes = {
  fillcolor: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

IcChecked.defaultProps = {
  fillcolor: MAIN_COLOR,
  width: '20',
  height: '20',
};
export default IcChecked;
