/* eslint-disable consistent-return */
import * as types from './constants';

export const initDataSearchResult = {
  listAudioPlay: {},
  currentPlaying: {},
  currentStickyPlaying: {},
  currentProgress: 0,
  playStatus: false,
  firstRenderSticky: false,
  loadingAudio: false,
  isFilterBarVisible: false,
  isAdded: null,
  dataAudio: [],
  dataIsAdded: undefined,
  dataSearchResult: {},
  fileType: {},
  isPlayed: false,
  headerAudio: false,
};
export const reducerSearchResult = (state = initDataSearchResult, action) => {
  switch (action.type) {
    case types.SET_LIST_AUDIO_PLAY:
      return {
        ...state,
        listAudioPlay: action.payload,
      };
    case types.SET_CURRENT_AUDIO_PLAY:
      return {
        ...state,
        currentPlaying: action.payload?.data,
      };
    case types.SET_CURRENT_STICKY_AUDIO_PLAY:
      return {
        ...state,
        currentStickyPlaying: action.payload?.data,
      };
    case types.SET_PLAY_STATUS:
      return {
        ...state,
        playStatus: action.payload?.data,
      };
    case types.SET_FIRST_RENDER_STICKY:
      return {
        ...state,
        firstRenderSticky: action.payload?.data,
      };
    case types.SET_LOADING_AUDIO:
      return {
        ...state,
        loadingAudio: action.payload?.data,
      };
    case types.SET_IS_FILTER_BAR_VISIBLE:
      return {
        ...state,
        isFilterBarVisible: action.payload?.data,
      };
    case types.SET_CURRENT_PROGRESS:
      return {
        ...state,
        currentProgress: action.payload?.data,
      };
    case types.RESET_INITIAL_STATE:
      return {
        ...initDataSearchResult,
      };
    case types.SET_IS_ADDED_AUDIO:
      return {
        ...state,
        isAdded: action.payload.data,
      };
    case types.DATA_AUDIO_CLONE:
      return {
        ...state,
        dataAudio: action.payload.data,
      };
    case types.SET_IS_ADDED_AUDIO_DETAIL:
      return {
        ...state,
        dataIsAdded: action.payload.data,
      };
    case types.SET_DATA_SEARCH_RESULT:
      return {
        ...state,
        dataSearchResult: action.payload.data,
      };
    case types.SET_FILE_TYPE_DOWNLOAD:
      return {
        ...state,
        fileType: action.payload,
      };
    case types.SET_IS_PLAYED:
      return {
        ...state,
        isPlayed: action.payload.data,
      };
    case types.SET_HEADER_AUDIO_DETAILS_PAGE:
      return {
        ...state,
        headerAudio: action.payload.data,
      };
    default:
      break;
  }
};
