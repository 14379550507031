import PropTypes from 'prop-types';

const IcMyCollections = ({
  fillcolor,
  width,
  height,
}) => (
  <svg width={width} height={height} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_9580_55804)">
      <path fillRule="evenodd" clipRule="evenodd" d="M8.17723 5.4323C7.17783 5.4323 6.23449 5.84054 5.52246 6.58075C4.79572 7.3379 4.39672 8.34509 4.40002 9.41537C4.40002 10.4866 4.80142 11.4928 5.53006 12.2514L11.9972 18.9825L18.4752 12.2646C19.2001 11.5103 19.6 10.5026 19.6 9.42903C19.6033 8.3531 19.2058 7.34685 18.4804 6.59299H18.48C17.7684 5.85184 16.8251 5.44313 15.8228 5.44313C14.8201 5.44313 13.8753 5.85279 13.1614 6.59676L12.0052 7.79936L10.842 6.58828C10.1262 5.84337 9.17948 5.4323 8.17723 5.4323ZM12.3368 19.3356L12.3382 19.3375C12.3377 19.3366 12.3373 19.3366 12.3368 19.3356ZM11.6518 19.3403C11.6514 19.3403 11.6504 19.3413 11.6499 19.3422L11.6518 19.3403ZM11.9943 21.0793C11.6081 21.0793 11.2315 20.9177 10.9607 20.6352L4.15399 13.5501C3.08714 12.4402 2.50002 10.9735 2.50002 9.4182C2.49577 7.86292 3.08049 6.39334 4.14639 5.28256C5.22131 4.16425 6.65249 3.54883 8.17723 3.54883C9.6065 3.54883 10.955 4.09033 12.0062 5.07915C13.0536 4.09692 14.3973 3.55966 15.8228 3.55966C17.3504 3.55966 18.783 4.17602 19.857 5.29434C20.9205 6.40182 21.5043 7.87046 21.5 9.43186C21.5 10.989 20.9134 12.4577 19.8494 13.5647L13.0255 20.6418C12.76 20.9187 12.3833 21.0793 11.9943 21.0793Z" fill={fillcolor} />
    </g>
    <defs>
      <clipPath id="clip0_9580_55804">
        <rect width="19" height="18.0313" fill="white" transform="translate(2.5 3.29883)" />
      </clipPath>
    </defs>
  </svg>

);

IcMyCollections.propTypes = {
  fillcolor: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

IcMyCollections.defaultProps = {
  fillcolor: '#A3A3A3',
  width: '24',
  height: '24.04',
};
export default IcMyCollections;
