import PropTypes from 'prop-types';
import { ICON_COLOR_DEFAULT } from 'constants/index';

const IcUsers = ({
  fillcolor,
  width,
  height,
}) => (
  <svg width={width} height={height} viewBox="0 0 24 24.04" fill={fillcolor} xmlns="http://www.w3.org/2000/svg">
    <path d="M19.3172 16.4741C18.7219 15.8778 18.0352 15.4012 17.2922 15.0561C18.354 14.1944 19.0313 12.8796 19.0313 11.4052C19.0313 8.80379 16.8657 6.66962 14.2688 6.70954C11.7118 6.74945 9.65161 8.83666 9.65161 11.4052C9.65161 12.8796 10.3313 14.1944 11.3907 15.0561C10.6477 15.4012 9.96333 15.8754 9.36567 16.4741C8.08599 17.7584 7.35942 19.4535 7.31255 21.2613C7.3102 21.367 7.39458 21.4539 7.50005 21.4539H8.81255C8.91333 21.4539 8.9977 21.374 9.00005 21.2731C9.04458 19.9113 9.59536 18.6365 10.5633 17.6692C11.5735 16.6573 12.9165 16.1008 14.3438 16.1008C15.7711 16.1008 17.1141 16.6573 18.1243 17.6692C19.0899 18.6365 19.6407 19.9113 19.6876 21.2731C19.6899 21.374 19.7743 21.4539 19.8751 21.4539H21.1876C21.293 21.4539 21.3774 21.367 21.3751 21.2613C21.3282 19.4535 20.6016 17.7584 19.3172 16.4741ZM14.3438 14.4104C13.5422 14.4104 12.7875 14.0981 12.2227 13.53C11.6485 12.9547 11.3344 12.1894 11.3438 11.3747C11.3508 10.6046 11.6579 9.86032 12.1946 9.30858C12.7571 8.73101 13.5094 8.40936 14.3133 8.39997C15.1079 8.39293 15.879 8.70284 16.4461 9.25927C17.0274 9.82979 17.3461 10.5928 17.3461 11.4052C17.3461 12.2081 17.0344 12.9618 16.4672 13.53C15.9001 14.0981 15.1454 14.4104 14.3438 14.4104Z" fill={fillcolor} />
    <path d="M8.4727 12.0247C8.45161 11.8204 8.43989 11.6138 8.43989 11.4049C8.43989 11.0316 8.47505 10.6677 8.54067 10.3131C8.55708 10.2286 8.51255 10.1417 8.4352 10.1065C8.11645 9.9633 7.82348 9.76609 7.57036 9.51722C6.96567 8.92557 6.63989 8.12496 6.66333 7.27739C6.68442 6.52374 6.98677 5.80765 7.51411 5.26766C8.09302 4.67366 8.87114 4.34966 9.69849 4.35905C10.4461 4.36609 11.168 4.65487 11.7141 5.1667C11.8993 5.34044 12.0586 5.53296 12.1922 5.73957C12.2391 5.81235 12.3305 5.84287 12.4102 5.8147C12.8227 5.67148 13.2586 5.57052 13.7063 5.52357C13.8375 5.50948 13.9125 5.36861 13.854 5.25122C13.0922 3.74157 11.536 2.69913 9.73599 2.67096C7.13677 2.63105 4.97114 4.76522 4.97114 7.36426C4.97114 8.8387 5.64848 10.1535 6.7102 11.0151C5.96489 11.3603 5.27817 11.8369 4.68286 12.4332C3.39849 13.7175 2.67192 15.4126 2.62505 17.2228C2.6227 17.3284 2.70708 17.4153 2.81255 17.4153H4.12739C4.22817 17.4153 4.31255 17.3355 4.31489 17.2345C4.35942 15.8728 4.9102 14.5979 5.87817 13.6306C6.56724 12.9403 7.41098 12.4614 8.33208 12.229C8.42349 12.2055 8.48442 12.1186 8.4727 12.0247Z" fill={fillcolor} />
  </svg>
);
IcUsers.propTypes = {
  fillcolor: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

IcUsers.defaultProps = {
  fillcolor: ICON_COLOR_DEFAULT,
  width: '24',
  height: '24.04',
};
export default IcUsers;
