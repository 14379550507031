import { Menu, Dropdown, Input } from 'antd';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { changeLanguage } from 'common/common';
import * as cookie from 'helpers/cookies';
import {
  GET_SETTING,
  LANG,
  LANG_CODE,
  WHITE_COLOR,
} from 'constants/index';
import { LANGUAGE_CODE_DEFAULT } from 'constants/intl';
import { getStorage } from 'helpers/storage';
import IcArrowUp from 'components/icons/IcArrowUp';

export const submenu = (clientCountry, keyLang) => (
  <>
    <Menu.Item key="en">
      <p className="container pl-5">
        <span className={keyLang === 'en' ? 'default-lang' : ''}>English</span>
        <Input type="radio" name="radio" />
        <span className={keyLang === 'en' ? 'checkmark default' : 'checkmark'} />
      </p>
    </Menu.Item>

    <Menu.Item key="de">
      <p className="container pl-5">
        <span className={keyLang === 'de' ? 'default-lang' : ''}>Deutsch</span>
        <Input type="radio" name="radio" />
        <span className={keyLang === 'de' ? 'checkmark default' : 'checkmark'} />
      </p>
    </Menu.Item>

    <Menu.Item key="nl">
      <p className="container pl-5">
        <span className={keyLang === 'nl' ? 'default-lang' : ''}>Dutch</span>
        <Input type="radio" name="radio" />
        <span className={keyLang === 'nl' ? 'checkmark default' : 'checkmark'} />
      </p>
    </Menu.Item>
    <Menu.Item key="es">
      <p className="container pl-5">
        <span className={keyLang === 'es' ? 'default-lang' : ''}>Español</span>
        <Input type="radio" name="radio" />
        <span className={keyLang === 'es' ? 'checkmark default' : 'checkmark'} />
      </p>

    </Menu.Item>
    <Menu.Item key="fr">
      <p className="container pl-5">
        <span className={keyLang === 'fr' ? 'default-lang' : ''}>Français</span>
        <Input type="radio" name="radio" />
        <span className={keyLang === 'fr' ? 'checkmark default' : 'checkmark'} />
      </p>

    </Menu.Item>
    <Menu.Item key="it">
      <p className="container pl-5">
        <span className={keyLang === 'it' ? 'default-lang' : ''}>Italiano</span>
        <Input type="radio" name="radio" />
        <span className={keyLang === 'it' ? 'checkmark default' : 'checkmark'} />
      </p>

    </Menu.Item>
    <Menu.Item key="pl">
      <p className="container pl-5">
        <span className={keyLang === 'pl' ? 'default-lang' : ''}>Język polski</span>
        <Input type="radio" name="radio" />
        <span className={keyLang === 'pl' ? 'checkmark default' : 'checkmark'} />
      </p>

    </Menu.Item>
    <Menu.Item key="pt">
      <p className="container pl-5">
        <span className={keyLang === 'pt' ? 'default-lang' : ''}> Português (PT)</span>
        <Input type="radio" name="radio" />
        <span className={keyLang === 'pt' ? 'checkmark default' : 'checkmark'} />
      </p>
    </Menu.Item>

    <Menu.Item key="ru">
      <p className="container pl-5">
        <span className={keyLang === 'ru' ? 'default-lang' : ''}> Pусский язык</span>
        <Input type="radio" name="radio" />
        <span className={keyLang === 'ru' ? 'checkmark default' : 'checkmark'} />
      </p>

    </Menu.Item>
    <Menu.Item key="tr">
      <p className="container pl-5">
        <span className={keyLang === 'tr' ? 'default-lang' : ''}> Türkçe</span>
        <Input type="radio" name="radio" />
        <span className={keyLang === 'tr' ? 'checkmark default' : 'checkmark'} />
      </p>

    </Menu.Item>
    <Menu.Item key="jp">
      <p className="container pl-5">
        <span className={keyLang === 'jp' ? 'default-lang' : ''}> 日本語</span>
        <Input type="radio" name="radio" />
        <span className={keyLang === 'jp' ? 'checkmark default' : 'checkmark'} />
      </p>

    </Menu.Item>
    <Menu.Item key="ko">
      <p className="container pl-5">
        <span className={keyLang === 'ko' ? 'default-lang' : ''}> 한국어</span>
        <Input type="radio" name="radio" />
        <span className={keyLang === 'ko' ? 'checkmark default' : 'checkmark'} />
      </p>

    </Menu.Item>
    <Menu.Item key="tw">
      <p className="container pl-5">
        <span className={keyLang === 'tw' ? 'default-lang' : ''}> 繁體中文</span>
        <Input type="radio" name="radio" />
        <span className={keyLang === 'tw' ? 'checkmark default' : 'checkmark'} />
      </p>
    </Menu.Item>
    <Menu.Item key="cn">
      <p className="container pl-5">
        <span className={keyLang === 'cn' ? 'default-lang' : ''}> 简体中文</span>
        <Input type="radio" name="radio" />
        <span className={keyLang === 'cn' ? 'checkmark default' : 'checkmark'} />
      </p>
    </Menu.Item>
    {clientCountry === LANG_CODE.CZ && (
    <Menu.Item key="cz">
      <p className="container pl-5">
        <span className={keyLang === 'cz' ? 'default-lang' : ''}> Česky</span>
        <Input type="radio" name="radio" />
        <span className={keyLang === 'cz' ? 'checkmark default' : 'checkmark'} />
      </p>
    </Menu.Item>
    )}
    { clientCountry === LANG_CODE.BR && (
    <Menu.Item key="br">
      <p className="container pl-5">
        <span className={keyLang === 'br' ? 'default-lang' : ''}>  Português (BR)</span>
        <Input type="radio" name="radio" />
        <span className={keyLang === 'br' ? 'checkmark default' : 'checkmark'} />
      </p>
    </Menu.Item>
    )}
  </>
);
const MenuLanguage = () => {
  const [isArrowUp, setIsArrowUp] = useState(false);
  const router = useRouter();
  const keyLang = router.query?.lang ?? cookie.getCookies(LANG) ?? LANGUAGE_CODE_DEFAULT;
  const selectLanguage = (e) => {
    const lang = e.key;
    changeLanguage(lang, router);
    setIsArrowUp(false);
  };
  const getSettings = getStorage(GET_SETTING);
  const [cloudFrontViewCountry, setCloudFrontViewCountry] = useState('');
  useEffect(() => {
    setCloudFrontViewCountry(getSettings?.country);
  }, [getSettings]);
  const onHandleClick = () => {
    setIsArrowUp(!isArrowUp);
  };
  const menu = (
    <Menu className="leng-menu" onClick={selectLanguage}>
      {submenu(cloudFrontViewCountry, keyLang)}
    </Menu>
  );
  return (
    <Dropdown
      overlay={menu}
      placement="bottomLeft"
      trigger={['click']}
      visible={isArrowUp}
      onVisibleChange={(e) => setIsArrowUp(e)}
      overlayClassName="dropdown-lang-menu"
      arrow
    >
      <div className="global-outlined" role="button" tabIndex="0" aria-hidden="true" onClick={onHandleClick}>
        <span className="lang-select">{keyLang}</span>
        <div className={isArrowUp ? 'arrow-up' : 'arrow-down'}>
          <IcArrowUp fillcolor={WHITE_COLOR} />
        </div>
      </div>
    </Dropdown>
  );
};
export default MenuLanguage;
