import {
  ACCESS_TOKEN,
  SHARED_SESSION,
  REMEMBER,
  PHPSESSID,
  CRM_AGENT,
  DOWNLOADED_IMAGE,
  DOWNLOADED_FOOTAGE,
  DOWNLOADED_AUDIO,
  FORCE_UPDATE_PASSWORD,
} from 'constants/index';
import * as cookie from 'helpers/cookies';

class CookieService {
  // eslint-disable-next-line class-methods-use-this
  clearCookies() {
    // If failed, clear cookies and redirect to login page
    cookie.removeCookie(ACCESS_TOKEN, {
      domain: `.${window.location.host}`,
    });
    cookie.removeCookie(SHARED_SESSION, {
      domain: `.${window.location.host}`,
    });
    cookie.removeCookie(REMEMBER, {
      domain: `.${window.location.host}`,
    });
    cookie.removeCookie(CRM_AGENT, {
      domain: `.${window.location.host}`,
    });
    cookie.removeCookie(PHPSESSID);
    cookie.removeCookie(FORCE_UPDATE_PASSWORD, {
      domain: `.${window.location.host}`,
    });
    cookie.removeCookie(DOWNLOADED_IMAGE);
    cookie.removeCookie(DOWNLOADED_FOOTAGE);
    cookie.removeCookie(DOWNLOADED_AUDIO);
  }

  // eslint-disable-next-line class-methods-use-this
  checkLoginSession() {
    return typeof (cookie.getCookies(ACCESS_TOKEN) + cookie.getCookies(SHARED_SESSION));
  }
}
export default new CookieService();
