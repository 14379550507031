/* eslint-disable react/jsx-props-no-spreading */
import 'antd/dist/antd.css';
import { getTransText } from 'common/translate';
import LiveChat from 'components/atoms/LiveChat/LiveChat';
import StickyAudio from 'components/atoms/StickyAudio/StickyAudio';
import AppLayoutDefault from 'components/layouts/AppLayoutDetail/AppLayoutDefault';
import BrowsersNotSupported from 'components/pages/PublicPages/Browsers/BrowersNotSupported';
import {
  CRM_ROUTE,
  DEBUG_ROUTE,
  GET_SETTING,
  LANG,
  ROUTES,
  ROUTES_DISPLAY_STICKY_AUDIO,
  SIMILAR_SEARCH_KEYWORD,
} from 'constants/index';
import { LANGUAGE_CODE_DEFAULT } from 'constants/intl';
import * as cookie from 'helpers/cookies';
import * as store from 'helpers/storage';
import languageObject from 'locales/index';
import { omit } from 'lodash';
import App from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { IntlProvider } from 'react-intl';
import MainProvider from 'src/stores/context/MainProvider';
import '../src/assets/scss/global.scss';
import '../styles/globals.css';
import { useEffect, useRef, useState } from 'react';
// Init app settings function
const exceptKey = ['imageResearchContentInfoDesc3', 'imageResearchContentInfoDesc2'];

function MyApp({
  Component, pageProps, appSettings, isIE, cloudFrontViewCountry,
}) {
  const router = useRouter();
  let locale = router.query?.lang ?? cookie.getCookies(LANG) ?? LANGUAGE_CODE_DEFAULT;
  if (!(locale in languageObject)) {
    locale = LANGUAGE_CODE_DEFAULT;
  }
  const settings = appSettings ?? store.getStorage(GET_SETTING) ?? null;
  const [sticky, setSticky] = useState(ROUTES_DISPLAY_STICKY_AUDIO.includes(router.pathname));
  let isCrm = false;

  const isHideLiveChat = [
    ROUTES.DETAIL_IMAGE_PAGE,
    ROUTES.HOME,
    ROUTES.SEARCH_AUDIO,
    ROUTES.SEARCH_FOOTAGE,
    ROUTES.SEARCH_IMAGE,
    ROUTES.TEXT_TO_IMAGE,
    '/ai-image-generator/[hash]',
  ].includes(router.pathname);

  if ([DEBUG_ROUTE, CRM_ROUTE].includes(router.pathname)) {
    isCrm = true;
  }

  const getLayout = Component?.getLayout;

  /**
   * get language
   */
  useEffect(() => {
    const cookiess = cookie.getCookies(LANG);
    if (!cookiess || cookiess !== locale) {
      cookie.setCookie(LANG, locale);
    }
  }, [locale]);

  useEffect(() => {
    setSticky(ROUTES_DISPLAY_STICKY_AUDIO.includes(router.pathname));
  }, [router.pathname]);

  const pageRef = useRef(null);
  const langMessage = omit(languageObject[locale], exceptKey);

  return (
    <IntlProvider locale={locale} messages={langMessage}>
      <Head>
        <title>
          {`${
            Component.keyword
              ? `${
                Component.keyword?.includes(SIMILAR_SEARCH_KEYWORD)
                  ? Component.keyword
                    ?.replace(SIMILAR_SEARCH_KEYWORD, `${getTransText('similarTo')} `)
                    ?.concat(' - ')
                  : Component.keyword
              } `
              : ''
          }
          ${getTransText(Component.title)} | Corporate+ ${settings?.contact_name ?? ''}`}
        </title>
      </Head>
      {isIE ? (
        <BrowsersNotSupported />
      ) : (
        <MainProvider
          cloudFrontViewCountry={cloudFrontViewCountry}
          initServerState={pageProps}
          sticky={sticky}
          setSticky={setSticky}
        >
          {isCrm ? (
            <Component {...pageProps} ref={pageRef} />
          ) : (
            <AppLayoutDefault sticky={sticky}>
              <>
                {getLayout ? (
                  getLayout(Component, { ...pageProps })
                ) : (
                  <Component {...pageProps} ref={pageRef} />
                )}
                <StickyAudio
                  sticky={sticky}
                  openDownloadModal={() => pageRef?.current?.openDownloadModal?.()}
                />
                {!isHideLiveChat && <LiveChat />}
              </>
            </AppLayoutDefault>
          )}
        </MainProvider>
      )}
    </IntlProvider>
  );
}

MyApp.propTypes = {
  Component: PropTypes.func,
  pageProps: PropTypes.objectOf(PropTypes.any),
  lang: PropTypes.string,
  appSettings: PropTypes.objectOf(PropTypes.any),
  isIE: PropTypes.any ?? PropTypes.string,
  cloudFrontViewCountry: PropTypes.objectOf(PropTypes.any),
};

MyApp.defaultProps = {
  Component: () => {},
  pageProps: {},
  lang: undefined,
  appSettings: {},
  isIE: undefined,
  cloudFrontViewCountry: undefined,
};

MyApp.getInitialProps = async (context) => {
  // getInitialProps from pages
  const pageProps = await App.getInitialProps(context);
  const appSettings = null;
  const userAgent = context.ctx?.req?.headers?.['user-agent'];
  const cloudFrontViewCountry = context.ctx?.headers?.['cloudfront-viewer-country'];
  let isIE = false;
  if (
    typeof userAgent === 'string'
    && (userAgent.indexOf('MSIE') !== -1 || userAgent.indexOf('Trident') !== -1)
  ) {
    isIE = true;
  }
  return {
    ...pageProps,
    appSettings,
    isIE,
    cloudFrontViewCountry,
  };
};

export default MyApp;
