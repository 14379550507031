import React from 'react';
import PropTypes from 'prop-types';

const ICArrowicon = ({ height, width }) => (
  <svg width={width} height={height} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M31.1785 13.8214C30.5277 13.1705 29.4726 13.1705 28.8218 13.8214L20.0001 22.6433L11.1783 13.8214C10.5275 13.1705 9.47246 13.1705 8.82163 13.8214C8.17079 14.4722 8.17079 15.5273 8.82163 16.1781L18.8217 26.1784C19.4726 26.8293 20.5276 26.8293 21.1784 26.1784L31.1785 16.1781C31.5043 15.8523 31.6668 15.4264 31.6668 14.9998C31.6668 14.5731 31.5043 14.1472 31.1785 13.8214Z" fill="#212121" />
  </svg>
);
ICArrowicon.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
};
ICArrowicon.defaultProps = {
  height: '25',
  width: '25',
};

export default ICArrowicon;
