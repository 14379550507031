/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useEffect, useContext, useState } from 'react';
import {
  Button, Menu, Modal, Progress,
} from 'antd';
import PropTypes from 'prop-types';
import * as cookie from 'helpers/cookies';
import {
  SHARED_SESSION,
  API_ROUTES,
  ROUTES,
  GET_SETTING,
  IS_ADMIN_ACCOUNT,
  ROUTES_LEGACY,
  LANG,
  CRM_AGENT,
  URL_DASHBOARD,
  ACCESS_USER_DASHBOARD,
} from 'constants/index';
import UserService from 'services/User/UserService';
import CookieService from 'services/Cookie/CookieService';
import Router, { useRouter } from 'next/router';
import { getTransText } from 'common/translate';
import { getStorage } from 'helpers/storage';
import {
  IcPersonalCd, IcGroupTotal,
  IcMyCollections, IcDownloadHistory, IcMyAccount, IcMyActivities,
} from 'components/icons/index';
import MainContexts from 'stores/context/MainContexts';
import LoaderItem from 'components/molecules/ContentLoader/LoaderItem';
import IconAdminDashboard from 'components/icons/HeaderMenu/IconAdminDashboard';
import * as store from 'helpers/storage';
import IcLogout from 'components/icons/HeaderMenu/IcLogout';

const { SubMenu } = Menu;

export const onLogout = async () => {
  const loginSession = cookie.getCookies(SHARED_SESSION);
  const crmAgent = cookie.getCookies(CRM_AGENT);
  await UserService.logout(
    API_ROUTES.LOGOUT,
    {
      login_session: loginSession,
      phpredis_session: loginSession,
      crm_agent: crmAgent,
    },
    () => {
      CookieService.clearCookies();
      Router.push(ROUTES.LOGIN);
    },
  );
};

const MenuSetting = ({ setArrowDown }) => {
  const [hideHRUC, setHideHRUC] = useState(true);
  const [hideCredit, setHideCredit] = useState(false);

  const [isAdmin, setIsAdmin] = useState(true);
  const [showGroupBalance, setShowGroupBalance] = useState(true);
  const router = useRouter();
  const [isCheckUpdatePage, setIsCheckUpdatePage] = useState(false);
  const { dataUserProfile, initDataUserProvider, actionsUser } = useContext(MainContexts);
  const [totalCredit, setTotalCredit] = useState(0);
  const [modalLegacy, setModalLegacy] = useState(false);
  const [getLang, setLang] = useState('');
  let totalGroup = 0;
  const dataUserCredit = initDataUserProvider?.credit?.open_access?.group_credits;
  const dataUNPL = initDataUserProvider?.credit?.open_access?.unpl_pending;

  useEffect(() => {
    setIsCheckUpdatePage(ROUTES.UPDATE_PASSWORD === router.pathname);
  }, [router.pathname]);
  const getSetting = getStorage(GET_SETTING);

  const pushRouterLegacy = (routerLegacy) => {
    router.push((`${routerLegacy}?lang=${cookie.getCookies(LANG)}`));
  };
  const onClickModalLegacy = () => {
    setModalLegacy(!modalLegacy);
  };
  const setAccessUserDashboard = (allow) => {
    actionsUser.setAccessUserDashboard(allow);
    store.setStorage(ACCESS_USER_DASHBOARD, allow);
  };
  const onClickMenu = (e) => {
    if (setArrowDown !== null) {
      setArrowDown(false);
    }
    let pathname;
    switch (e.key) {
      case 'my-collections':
        pathname = URL_DASHBOARD.MY_COLLECTION;
        break;
      case 'download-history':
        pathname = URL_DASHBOARD.DOWNLOAD_HISTORY;
        break;
      case 'hruc-item':
        pathname = `${URL_DASHBOARD.DOWNLOAD_HISTORY}/hruc`;
        break;
      case 'my-account':
        pathname = `${URL_DASHBOARD.MY_ACCOUNT}`;
        break;
      case 'my-activities':
        pathname = `${URL_DASHBOARD.MY_ACTIVITES}`;
        break;
      default: break;
    }

    if (e.key === 'admin-control') {
      pushRouterLegacy(ROUTES_LEGACY.DASHBOARD);
    } else {
      setAccessUserDashboard(true);
      router.push(`${pathname}${router.query.lang ? `?lang=${router.query.lang}` : ''}`);
    }
  };
  useEffect(() => {
    setHideHRUC(getSetting?.show_hide_header?.hide_hruc);
    setHideCredit(getSetting?.show_hide_header?.hide_credit);
  }, [getSetting]);
  useEffect(() => {
    setIsAdmin(IS_ADMIN_ACCOUNT.includes(dataUserProfile?.is_admin));
  }, [dataUserProfile]);
  useEffect(() => {
    if (dataUserCredit) {
      Object.values(dataUserCredit).forEach((item) => {
        totalGroup += item?.group_balance;
      });
      setTotalCredit(totalGroup);
    }
    setShowGroupBalance(initDataUserProvider?.credit?.open_access?.show_group_balance);
  }, [initDataUserProvider?.credit]);
  useEffect(() => {
    setLang(cookie.getCookies(LANG));
  }, [cookie.getCookies(LANG)]);
  return (
    <>
      {!dataUserProfile ? (
        <>
          <div className="menu-content-loader">
            <LoaderItem length={2} />
          </div>
        </>
      )
        : (
          <div className="menu-setting">
            <Menu mode="inline" onClick={(e) => onClickMenu(e)}>
              {!isCheckUpdatePage && (
                <>
                  <Menu.Item key="hello" className="view-hello">
                    {getLang === 'jp' ? (
                      <div className="spread-operator-text">
                        {' '}
                        {dataUserProfile?.first_name}
                        {' '}
                        {dataUserProfile?.last_name}
                        {' '}
                        {getTransText('hello')}
                      </div>
                    ) : (
                      <div className="spread-operator-text">
                        {' '}
                        {getTransText('hello')}
                        {' '}
                        {dataUserProfile?.first_name}
                        {' '}
                        {dataUserProfile?.last_name}
                      </div>
                    )}

                  </Menu.Item>
                  <Menu.Item key="view-hruc-total-hr-a" className="hruc-total-hr">
                    <hr className="hruc-hr" />
                  </Menu.Item>
                  {isAdmin && (
                    <>
                      <Menu.Item key="admin-control">
                        <span className="header-menu">
                          <IconAdminDashboard />
                        </span>
                        <span className="text-ellipsis">
                          {getTransText('adminControlPanel')}
                        </span>
                      </Menu.Item>
                    </>
                  )}
                  <Menu.Item key="my-collections">
                    <span className="header-menu">
                      <IcMyCollections />
                    </span>
                    <span className="text-ellipsis">
                      {getTransText('myCollections')}
                    </span>
                  </Menu.Item>
                  <Menu.Item key="my-account">
                    <span className="header-menu">
                      <IcMyAccount />
                    </span>
                    <span className="text-ellipsis">
                      {getTransText('myAccount')}
                    </span>
                  </Menu.Item>
                  <Menu.Item key="download-history">
                    <span className="header-menu">
                      <IcDownloadHistory />
                    </span>
                    <span className="text-ellipsis">
                      {getTransText('downloadHistory')}
                    </span>
                  </Menu.Item>
                  <Menu.Item key="my-activities">
                    <span className="header-menu">
                      <IcMyActivities />
                    </span>
                    <span className="text-ellipsis">
                      {getTransText('myActivities')}
                    </span>
                  </Menu.Item>
                  {
                    hideHRUC ? (
                      <Menu.Item key="hruc-item">
                        <span className="header-menu">
                          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.31815 7.27273C9.31815 6.51966 8.70759 5.90909 7.95452 5.90909C7.20145 5.90909 6.59088 6.51966 6.59088 7.27273C6.59088 8.0258 7.20145 8.63637 7.95452 8.63637C8.70759 8.63637 9.31815 8.0258 9.31815 7.27273Z" fill="white" />
                            <path fillRule="evenodd" clipRule="evenodd" d="M17.5 14.4492C17.5 16.1316 16.1316 17.5 14.4495 17.5H5.55045C3.86807 17.5 2.5 16.1316 2.5 14.4492V5.5508C2.5 3.86841 3.86807 2.5 5.55045 2.5H14.4495C16.1316 2.5 17.5 3.86841 17.5 5.5508V14.4492ZM5.55045 3.86364C4.62011 3.86364 3.86364 4.6208 3.86364 5.5508V12.9511L5.35409 10.955C5.47186 10.7972 5.6514 10.698 5.84757 10.684C6.04103 10.668 6.23516 10.7406 6.37465 10.879L8.2273 12.7214L11.5278 8.86358C11.7869 8.56062 12.3041 8.56062 12.5631 8.86358L16.1364 13.0402V5.5508C16.1364 4.6208 15.3795 3.86364 14.4495 3.86364H5.55045Z" fill="white" />
                          </svg>

                        </span>
                        <span className="text-ellipsis">
                          {getTransText('hruc')}
                        </span>
                      </Menu.Item>
                    ) : null
                  }
                </>
              )}
              <Menu.Item className="logout" key="logout" onClick={() => onLogout()}>
                <span className="header-menu">
                  <IcLogout />
                </span>
                <span className="text-ellipsis">
                  {getTransText('logOut')}
                </span>
              </Menu.Item>
              <Menu.Item key="view-hruc-total-hr-b" className="hruc-total-hr">
                <hr className="hruc-hr" />
              </Menu.Item>
              {!hideCredit ? (
                <>
                  <Menu.Item key="view-personal-credits" className="header-personal-credits">
                    <span className="dropdown-personal">
                      <span className="display-flex"><IcPersonalCd /></span>
                      <span className="personal-number">
                        {initDataUserProvider?.credit?.open_access?.user_credits_balance}
                      </span>
                      <span className="personal-credits text-ellipsis">
                        {getTransText('personalCredits')}
                      </span>
                    </span>
                  </Menu.Item>
                  {showGroupBalance ? (
                    <SubMenu
                      key="Sub-menu-setting"
                      title={(
                        <>
                          <div className="group-total">
                            <span className="groupi-con-total"><IcGroupTotal /></span>
                            <span className="group-total-number">
                              {totalCredit}
                            </span>
                            <span className="group-total-text">
                              {getTransText('groupTotal')}
                            </span>
                          </div>
                        </>
                      )}
                      className="sub-menu-infor"
                    >
                      {dataUserCredit?.map((item, index) => (
                        <Menu.Item className="header-personal-credits" key={index.toString()}>
                          <span className="group-team-total">
                            <span className="group-balance">{item?.group_balance}</span>
                            <span className="group-name">{item?.group_name}</span>
                          </span>
                        </Menu.Item>
                      ))}
                    </SubMenu>
                  ) : null}
                </>
              ) : (
                <Menu.Item key="total-unpaid" className="total-unpaid-license">
                  <span className="total-monthly-number">{dataUNPL}</span>
                  <span className="total-monthly text-ellipsis">
                    {getTransText('toTalUnpaidLicense')}
                  </span>
                </Menu.Item>
              )}
              {
                hideHRUC ? (
                  <>
                    <Menu.Item key="view-hruc-total-hr-c" className="hruc-total-hr">
                      <hr className="hruc-hr" />
                    </Menu.Item>
                    <Menu.Item key="view-hruc-high-res" className="hruc-high-res mt-4 mb-4">
                      <span className="header-high-res text-ellipsis">
                        {getTransText('highRUnwatermarkComp')}
                      </span>
                    </Menu.Item>
                    <Menu.Item key="monthly-allocation" className="monthly-allocation">
                      <span className="total-monthlyy">
                        <span className="total-monthly-number">
                          {initDataUserProvider?.credit?.vvip?.hruc_quota}
                        </span>
                        <span className="total-monthly text-ellipsis">
                          {getTransText('monthlyAllocation')}
                        </span>
                      </span>
                    </Menu.Item>
                    <Menu.Item key="view-remaining-quota" className="header-remaining-quota mt-4 mb-4">
                      <span>
                        {getTransText('remainingQuota')}
                      </span>
                    </Menu.Item>
                    <Menu.Item key="view-progress" className="header-progress mb-6">
                      <span className="progress-remaining">
                        <Progress
                          percent={initDataUserProvider?.credit?.vvip?.hruc_remainingbar}
                          format={(percent) => `${percent}`}
                          strokeColor="#ffd44d"
                          strokeWidth="5px"
                        />
                      </span>
                      <span className="progress-number">
                        {initDataUserProvider?.credit?.vvip?.hruc_balance}
                      </span>
                    </Menu.Item>
                  </>
                ) : null
              }
            </Menu>
          </div>
        )}
      <Modal
        className="modal-likebox-legacy"
        wrapClassName="modal-likebox-legacy-wrapper"
        visible={modalLegacy}
        closable={false}
        footer={<Button onClick={onClickModalLegacy} className="no-border">Close</Button>}
      >
        <iframe id="likebox-frame" name="modal-legacy" src={`https://${window.location.host}${ROUTES_LEGACY.LIKE_BOX_COLLECTION}`} />
      </Modal>
    </>
  );
};
MenuSetting.propTypes = {
  setArrowDown: PropTypes.func,
};

MenuSetting.defaultProps = {
  setArrowDown: null,
};
export default MenuSetting;
