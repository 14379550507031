import PropTypes from 'prop-types';
import { ICON_ADD_COLOR } from 'constants/index';

const IcLoadingCircle = ({
  width,
  height,
  fillcolor,
}) => (
  <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill={fillcolor} d="M9.5 2.01537C9.66536 2.00517 9.83208 2 10 2C14.4183 2 18 5.58172 18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10H0C0 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C9.83234 0 9.66564 0.00412617 9.5 0.0122825V2.01537Z" />
  </svg>

);
IcLoadingCircle.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fillcolor: PropTypes.string,
};

IcLoadingCircle.defaultProps = {
  width: '30',
  height: '30',
  fillcolor: ICON_ADD_COLOR,
};
export default IcLoadingCircle;
