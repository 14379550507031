import { Skeleton } from 'antd';
import propTypes from 'prop-types';
import React from 'react';

const LoaderItem = ({ length, nameOfClass }) => (
  <>
    {Array.from({ length }, (item, index) => <Skeleton className={nameOfClass || ''} key={index.toString()} active />)}
  </>
);
LoaderItem.propTypes = {
  length: propTypes.number,
  nameOfClass: propTypes.string,
};
LoaderItem.defaultProps = {
  length: 1,
  nameOfClass: '',
};
export default LoaderItem;
