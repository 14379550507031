import PropTypes from 'prop-types';
import { MAIN_COLOR } from 'constants/index';

const IcGroupTotal = ({
  fillcolor,
  width,
  height,
}) => (
  <svg width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="32" height="32" rx="4" fill="#333333" />
    <path d="M23.3172 20.4045C22.7219 19.8091 22.0352 19.3334 21.2922 18.9888C22.354 18.1287 23.0313 16.8162 23.0313 15.3443C23.0313 12.7474 20.8657 10.617 18.2688 10.6568C15.7118 10.6966 13.6516 12.7802 13.6516 15.3443C13.6516 16.8162 14.3313 18.1287 15.3907 18.9888C14.6477 19.3334 13.9633 19.8068 13.3657 20.4045C12.086 21.6865 11.3594 23.3787 11.3125 25.1834C11.3102 25.2888 11.3946 25.3755 11.5 25.3755H12.8125C12.9133 25.3755 12.9977 25.2959 13 25.1951C13.0446 23.8357 13.5954 22.563 14.5633 21.5974C15.5735 20.5873 16.9165 20.0318 18.3438 20.0318C19.7711 20.0318 21.1141 20.5873 22.1243 21.5974C23.0899 22.563 23.6407 23.8357 23.6876 25.1951C23.6899 25.2959 23.7743 25.3755 23.8751 25.3755H25.1876C25.293 25.3755 25.3774 25.2888 25.3751 25.1834C25.3282 23.3787 24.6016 21.6865 23.3172 20.4045ZM18.3438 18.3443C17.5422 18.3443 16.7875 18.0326 16.2227 17.4654C15.6485 16.8912 15.3344 16.1271 15.3438 15.3138C15.3508 14.5451 15.6579 13.8021 16.1946 13.2513C16.7571 12.6748 17.5094 12.3537 18.3133 12.3443C19.1079 12.3373 19.879 12.6466 20.4461 13.2021C21.0274 13.7716 21.3461 14.5334 21.3461 15.3443C21.3461 16.1459 21.0344 16.8982 20.4672 17.4654C19.9001 18.0326 19.1454 18.3443 18.3438 18.3443Z" fill={fillcolor} />
    <path d="M12.4727 15.963C12.4516 15.7591 12.4399 15.5529 12.4399 15.3443C12.4399 14.9716 12.475 14.6084 12.5407 14.2545C12.5571 14.1701 12.5125 14.0834 12.4352 14.0482C12.1165 13.9052 11.8235 13.7084 11.5704 13.4599C10.9657 12.8693 10.6399 12.0701 10.6633 11.224C10.6844 10.4716 10.9868 9.7568 11.5141 9.21774C12.093 8.62477 12.8711 8.30133 13.6985 8.31071C14.4461 8.31774 15.168 8.60602 15.7141 9.11696C15.8993 9.29039 16.0586 9.48258 16.1922 9.68883C16.2391 9.76149 16.3305 9.79196 16.4102 9.76383C16.8227 9.62086 17.2586 9.52008 17.7063 9.47321C17.8375 9.45914 17.9125 9.31852 17.854 9.20133C17.0922 7.6943 15.536 6.65367 13.736 6.62555C11.1368 6.58571 8.97114 8.71618 8.97114 11.3107C8.97114 12.7826 9.64848 14.0951 10.7102 14.9552C9.96489 15.2998 9.27817 15.7755 8.68286 16.3709C7.39849 17.6529 6.67192 19.3451 6.62505 21.1521C6.6227 21.2576 6.70708 21.3443 6.81255 21.3443H8.12739C8.22817 21.3443 8.31255 21.2646 8.31489 21.1638C8.35942 19.8045 8.9102 18.5318 9.87817 17.5662C10.5672 16.8771 11.411 16.399 12.3321 16.167C12.4235 16.1435 12.4844 16.0568 12.4727 15.963Z" fill={fillcolor} />
  </svg>
);
IcGroupTotal.propTypes = {
  fillcolor: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

IcGroupTotal.defaultProps = {
  fillcolor: MAIN_COLOR,
  width: '32',
  height: '32',
};
export default IcGroupTotal;
