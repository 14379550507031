import PropTypes from 'prop-types';
import { ICON_COLOR_DEFAULT } from 'constants/index';

const IcDownloadHistory = ({
  fillcolor,
  width,
  height,
}) => (
  <svg width={width} height={height} viewBox="0 0 24 24.04" fill={fillcolor} xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M11.9996 15.5278C11.4629 15.5278 11.0273 15.1393 11.0273 14.6597V4.24306C11.0273 3.76345 11.4629 3.375 11.9996 3.375C12.5362 3.375 12.9718 3.76345 12.9718 4.24306V14.6597C12.9718 15.1393 12.5362 15.5278 11.9996 15.5278Z" fill={fillcolor} />
    <path fillRule="evenodd" clipRule="evenodd" d="M12 15.5279C11.776 15.5279 11.5524 15.441 11.3814 15.2668L7.88127 11.7019C7.53958 11.3538 7.53958 10.7897 7.88127 10.4417C8.22297 10.0937 8.77686 10.0937 9.11855 10.4417L12 13.3765L14.8814 10.4417C15.2231 10.0937 15.777 10.0937 16.1187 10.4417C16.4604 10.7897 16.4604 11.3538 16.1187 11.7019L12.6186 15.2668C12.4476 15.441 12.224 15.5279 12 15.5279Z" fill={fillcolor} />
    <path fillRule="evenodd" clipRule="evenodd" d="M17.6875 20.8751H6.3125C4.62419 20.8751 3.25 19.4573 3.25 17.7154V15.4584C3.25 14.9597 3.642 14.5557 4.125 14.5557C4.608 14.5557 5 14.9597 5 15.4584V17.7154C5 18.462 5.58888 19.0696 6.3125 19.0696H17.6875C18.4111 19.0696 19 18.462 19 17.7154V15.4584C19 14.9597 19.392 14.5557 19.875 14.5557C20.358 14.5557 20.75 14.9597 20.75 15.4584V17.7154C20.75 19.4573 19.3758 20.8751 17.6875 20.8751Z" fill={fillcolor} />
    <rect y="0.125" width="24" height="24" fill="white" fillOpacity="0.01" />
  </svg>

);

IcDownloadHistory.propTypes = {
  fillcolor: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

IcDownloadHistory.defaultProps = {
  fillcolor: ICON_COLOR_DEFAULT,
  width: '20',
  height: '20',
};
export default IcDownloadHistory;
