/* eslint-disable jsx-a11y/anchor-is-valid */
import { getTransText } from 'common/translate';
import React, { useContext } from 'react';
import Link from 'next/link';
import { getLangRoute } from 'common/common';
import { ROUTES } from 'constants/routes';
import MainContext from 'stores/context/MainContexts';
import PropTypes from 'prop-types';

const Footer = ({ sticky }) => {
  const { initDataProvider } = useContext(MainContext);
  const showHiddenLicense = initDataProvider?.getSettings?.show_license_agreement;
  return (
    <>
      <div className={`footer${sticky ? ' hasSticky' : ''}`}>
        <div className="title-corporate">
          © Corporate+
          {' '}
          {new Date().getFullYear()}
        </div>
        {' '}
        <ul className="ul-footer">
          <li className="li-footer"><Link href={getLangRoute(ROUTES.CONTACT)}>{getTransText('contact')}</Link></li>
          <li className="li-footer"><Link href={getLangRoute(ROUTES.PRIVACY_POLICY)}>{getTransText('privacy')}</Link></li>
          <li className="li-footer"><Link href={getLangRoute(ROUTES.TERMS_OF_USE)}>{getTransText('terms')}</Link></li>
          <li className="li-footer"><Link href={getLangRoute(ROUTES.COOKIE_POLICY)}>{getTransText('cookie')}</Link></li>
          <li className={showHiddenLicense ? 'li-footer' : 'li-footer none-display'}><Link href={getLangRoute(ROUTES.CORPORATE_AGREEMENT)}>{getTransText('licenseAgreement')}</Link></li>
        </ul>
      </div>
    </>
  );
};
Footer.propTypes = {
  sticky: PropTypes.bool,
};

Footer.defaultProps = {
  sticky: false,
};
export default Footer;
