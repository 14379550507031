export const GET_LIST_AUDIO_PLAY = 'GET_LIST_AUDIO_PLAY';
export const SET_LIST_AUDIO_PLAY = 'SET_LIST_AUDIO_PLAY';
export const GET_CURRENT_AUDIO_PLAY = 'GET_CURRENT_AUDIO_PLAY';
export const SET_CURRENT_AUDIO_PLAY = 'SET_CURRENT_AUDIO_PLAY';
export const GET_CURRENT_PROGRESS = 'GET_CURRENT_PROGRESS';
export const SET_CURRENT_PROGRESS = 'SET_CURRENT_PROGRESS';
export const GET_CURRENT_STICKY_AUDIO_PLAY = 'GET_CURRENT_STICKY_AUDIO_PLAY';
export const SET_CURRENT_STICKY_AUDIO_PLAY = 'SET_CURRENT_STICKY_AUDIO_PLAY';
export const GET_PLAY_STATUS = 'GET_PLAY_STATUS';
export const SET_PLAY_STATUS = 'SET_PLAY_STATUS';
export const GET_FIRST_RENDER_STICKY = 'GET_FIRST_RENDER_STICKY';
export const SET_FIRST_RENDER_STICKY = 'SET_FIRST_RENDER_STICKY';
export const GET_LOADING_AUDIO = 'GET_LOADING_AUDIO';
export const SET_LOADING_AUDIO = 'SET_LOADING_AUDIO';
export const GET_IS_FILTER_BAR_VISIBLE = 'GET_IS_FILTER_BAR_VISIBLE';
export const SET_IS_FILTER_BAR_VISIBLE = 'SET_IS_FILTER_BAR_VISIBLE';
export const RESET_INITIAL_STATE = 'RESET_INITIAL_STATE';
export const SET_IS_ADDED_AUDIO = 'SET_IS_ADDED_AUDIO';
export const DATA_AUDIO_CLONE = 'DATA_AUDIO_CLONE';
export const SET_IS_ADDED_AUDIO_DETAIL = 'SET_IS_ADDED_AUDIO_DETAIL';
export const SET_DATA_SEARCH_RESULT = 'SET_DATA_SEARCH_RESULT';
export const SET_FILE_TYPE_DOWNLOAD = 'SET_FILE_TYPE_DOWNLOAD';
export const SET_IS_PLAYED = 'SET_IS_PLAYED';
export const SET_HEADER_AUDIO_DETAILS_PAGE = 'SET_HEADER_AUDIO_DETAILS_PAGE';
export const GET_HEADER_AUDIO_DETAILS_PAGE = 'GET_HEADER_AUDIO_DETAILS_PAGE';
