import { Menu } from 'antd';
import { getTransText } from 'common/translate';
import { ROUTES } from 'constants/routes';
import { getLangRoute } from 'common/common';
import Link from 'next/link';
import { useEffect, useState } from 'react';
import { getStorage } from 'helpers/storage';
import { CLIENT_ID_HEADER_PIXLR_DESIGN, GET_SETTING, UNPL_CLIENT } from 'constants/index';

const MenuLanding = () => {
  const [hideImage, setHideImage] = useState(0);
  const [hideFootage, setHideFootage] = useState(0);
  const [hideAudio, setHideAudio] = useState(0);
  const [pixlrAndDesignOption, setPixlrAndDesignOption] = useState(false);
  const [isShowTII, setShowTTI] = useState(true);

  useEffect(() => {
    const getSetting = getStorage(GET_SETTING);
    const isUNPL = getSetting?.show_hide_header?.hide_credit === UNPL_CLIENT;
    const hideTTI = (!getSetting?.tti_price && isUNPL) || !getSetting?.show_ai_generator;

    setHideImage(getSetting?.show_hide_header?.hide_image);
    setHideFootage(getSetting?.show_hide_header?.hide_footage);
    setHideAudio(getSetting?.show_hide_header?.hide_audio);
    setPixlrAndDesignOption(getSetting?.client_id === CLIENT_ID_HEADER_PIXLR_DESIGN);
    setShowTTI(!hideTTI);
  }, [getStorage(GET_SETTING)]);
  return (
    <Menu>
      {hideImage !== 1 && (
        <>
          <Menu.Item key="photos">
            <Link href={getLangRoute(ROUTES.HOME)}>{getTransText('photos')}</Link>
          </Menu.Item>
          <Menu.Item key="vectors">
            <Link href={getLangRoute(ROUTES.VECTOR)}>{getTransText('vectors')}</Link>
          </Menu.Item>
        </>
      )}
      {hideFootage !== 1 && (
        <Menu.Item key="footage">
          <Link href={getLangRoute(ROUTES.FOOTAGE)}>{getTransText('footage')}</Link>
        </Menu.Item>
      )}
      {hideAudio !== 1 && (
        <Menu.Item key="audio">
          <Link href={getLangRoute(ROUTES.AUDIO)}>{getTransText('audio')}</Link>
        </Menu.Item>
      )}
      {pixlrAndDesignOption && (
        <>
          <Menu.Item key="pixlr">
            <a href="https://pixlr.com/" target="_blank" rel="noreferrer">
              Pixlr
            </a>
          </Menu.Item>
          <Menu.Item key="designAi">
            <a href="https://designs.ai/" target="_blank" rel="noreferrer">
              Designs.ai
            </a>
          </Menu.Item>
        </>
      )}
      <Menu.Item key="freeImageResearch">
        <Link href={getLangRoute(ROUTES.IMAGE_RESEARCH_SERVICE)}>
          {getTransText('freeImageResearch')}
        </Link>
      </Menu.Item>

      {isShowTII && (
        <Menu.Item key="aiImageGenerator">
          <Link href={getLangRoute(ROUTES.TEXT_TO_IMAGE)} passHref>
            <a href={getLangRoute(ROUTES.TEXT_TO_IMAGE)}>
              {getTransText('aiImageGenerator')}
              <span className="new-link">{getTransText('New')}</span>
            </a>
          </Link>
        </Menu.Item>
      )}
    </Menu>
  );
};
export default MenuLanding;
