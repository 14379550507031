import PropTypes from 'prop-types';

export default function ResetIcon(props) {
  const {
    color, width, height, className,
  } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={className}
      viewBox="0 0 29 29"
    >
      <g id="reset-icon" fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g id="icons/reset">
          <path id="Rectangle" d="M0 0h29v29H0z" />
          <path id="Combined-Shape" fill={color} fillRule="nonzero" d="M4.245 13.796l-.052-.001c-.018 0-.037-.002-.056-.004l.108.005a1.204 1.204 0 01-.886-.39l-.034-.039a1.24 1.24 0 01-.043-.053l.077.092a1.245 1.245 0 01-.327-.756l-.003-.092V6.24c0-.684.545-1.238 1.216-1.238.642 0 1.167.505 1.213 1.146l.004.092-.001 3.454L8.184 7.09a10.453 10.453 0 015.968-3.027c2.258-.324 4.56.11 6.556 1.233a10.643 10.643 0 014.513 4.997c.932 2.12 1.162 4.491.656 6.756a10.747 10.747 0 01-3.463 5.801 10.42 10.42 0 01-6.192 2.518 10.382 10.382 0 01-6.435-1.778 10.688 10.688 0 01-4.094-5.358 1.244 1.244 0 01.743-1.58 1.211 1.211 0 011.552.757 8.217 8.217 0 003.147 4.12 7.981 7.981 0 004.947 1.366 8.01 8.01 0 004.76-1.936 8.262 8.262 0 002.663-4.46A8.374 8.374 0 0023 11.307a8.182 8.182 0 00-3.469-3.842 7.966 7.966 0 00-5.04-.948 8.053 8.053 0 00-4.448 2.188l-.167.165-2.56 2.449h3.133c.641 0 1.167.505 1.213 1.146l.004.093a1.23 1.23 0 01-1.126 1.235l-.09.003H4.244z" />
        </g>
      </g>
    </svg>
  );
}

ResetIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  className: PropTypes.string,
};

ResetIcon.defaultProps = {
  color: '#ffffff',
  width: '29',
  height: '29',
  className: '',
};
