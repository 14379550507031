import React, { useContext, useEffect, useState } from 'react';
import { Dropdown, Menu } from 'antd';
import { getStorage } from 'helpers/storage';
import { USER_NAME } from 'constants/index';
import IcArrowUp from 'components/icons/IcArrowUp';
import MainContext from 'stores/context/MainContexts';
import MenuSetting from '../MenuSetting/MenuSetting';

const MenuInformation = () => {
  const [userName, setUserName] = useState('');
  const [isArrowUp, setIsArrowUp] = useState(false);
  const { actionsUser } = useContext(MainContext);
  const setArrowDown = (isdown) => {
    setIsArrowUp(isdown);
  };
  const menuInformation = (
    <>
      <Menu className="leng-menu-information">
        <MenuSetting
          setArrowDown={setArrowDown}
        />
      </Menu>
    </>
  );
  const onHandleClick = () => {
    setIsArrowUp(!isArrowUp);
  };
  useEffect(() => {
    if (isArrowUp) {
      actionsUser.getCreditSummary('user');
    }
  }, [isArrowUp]);
  useEffect(() => {
    setUserName(getStorage(USER_NAME));
  }, [getStorage(USER_NAME)]);
  return (
    <>
      <Dropdown
        visible={isArrowUp}
        onVisibleChange={(e) => setIsArrowUp(e)}
        overlay={menuInformation}
        placement="bottomLeft"
        trigger={['click']}
        arrow
      >
        <div className="btn-menu" role="button" tabIndex="0" aria-hidden="true" onClick={onHandleClick}>
          <span className="text-transform-capitalize background-none-color">{userName}</span>
          <div className={isArrowUp ? 'arrow-up' : 'arrow-down'}>
            <IcArrowUp fillcolor="#fff" />
          </div>
        </div>
      </Dropdown>
    </>
  );
};

export default MenuInformation;
