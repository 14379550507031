import store from 'store';

const NAME_SPACE = 'corpplus.';

export const getStorage = (key) => {
  const realKey = NAME_SPACE + key;
  return store.get(realKey);
};

export const setStorage = (key, data) => {
  const realKey = NAME_SPACE + key;
  return store.set(realKey, data);
};

export const removeStorage = (key) => {
  const realKey = NAME_SPACE + key;

  return store.remove(realKey);
};

export const clearAllStorage = () => {
  store.clearAll();
};
