/* eslint-disable no-param-reassign */
/* eslint-disable class-methods-use-this */
import axios from 'axios';
import {
  ROUTES,
  ACCESS_TOKEN,
  LANG,
  API_ROUTES,
  SHARED_SESSION,
  CRM_AGENT,
} from 'constants/index';
import CookieService from 'src/services/Cookie/CookieService';
import * as cookie from 'helpers/cookies';
import { Router } from 'next/router';
import UserService from 'services/User/UserService';

class Axios {
  constructor() {
    const service = axios.create({
      // withCredentials: true,
    });
    service.interceptors.response.use(this.handleSuccess, this.handleError.bind(this));

    service.interceptors.request.use((config) => {
      // Do something before request is sent
      if (typeof window !== 'undefined') {
        const accessToken = cookie.getCookies(ACCESS_TOKEN);
        const getLang = cookie.getCookies(LANG);
        config.headers.lang = getLang;
        const clientDomain = window.location.host;
        if (accessToken && !config.headers.access_token) {
          config.headers.Authorization = `Bearer ${accessToken}`;
        }
        if (clientDomain && !config.headers['client-domain']) {
          config.headers['client-domain'] = clientDomain;
        }
      }
      return config;
    }, (error) => Promise.reject(error));
    this.service = service;
  }

  handleSuccess(response) {
    return response;
  }

  addHeader(name, value) {
    this.service.defaults.headers.common[name] = value;
  }

  removeHeader(name) {
    delete this.service.defaults.headers.common[name];
  }

  handleError(error) {
    if (error.response) {
      switch (error.response.status) {
        case 401:
          CookieService.clearCookies();
          this.removeHeader('Authorization');
          this.redirectTo(document, ROUTES.LOGIN);
          break;
        case 404:
          break;
        case 403:
          UserService.logout(
            API_ROUTES.LOGOUT,
            {
              login_session: cookie.getCookies(SHARED_SESSION),
              phpredis_session: cookie.getCookies(SHARED_SESSION),
              crm_agent: cookie.getCookies(CRM_AGENT),
            },
            () => {
              CookieService.clearCookies();
              Router.push(ROUTES.LOGIN);
            },
          );
          break;
        case 400:
          break;
        case 500:
          break;
        default:
          break;
      }
      return Promise.reject(error);
    }
    // abort request when redirect
    return Promise.reject();
  }

  redirectTo(document, path) {
    document.location.host = path;
  }

  get(path, callback) {
    return this.service.get(path).then(
      (response) => callback(response.status, response.data),
    );
  }

  getLastApiCall(path, callback, abort) {
    return this.service.get(path, { signal: abort }).then(
      (response) => callback(response.status, response.data),
    );
  }

  patch(path, payload, callback) {
    return this.service.request({
      method: 'PATCH',
      url: path,
      responseType: 'json',
      data: payload,
    }).then((response) => callback(response.status, response.data));
  }

  post(path, payload, callback, abortSignal) {
    return this.service.request({
      method: 'POST',
      url: path,
      responseType: 'json',
      data: payload,
      signal: abortSignal,
    }).then((response) => callback(response.status, response.data));
  }

  delete(path, callback) {
    return this.service.request({
      method: 'DELETE',
      url: path,
      responseType: 'json',
    }).then((response) => callback(response.status, response.data));
  }

  put(path, payload, callback) {
    return this.service.request({
      method: 'PUT',
      url: path,
      responseType: 'json',
      data: payload,
    }).then((response) => callback(response.status, response.data));
  }
}

export default new Axios();
