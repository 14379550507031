/* eslint-disable consistent-return */
import Axios from 'src/services/Axios/Axios';
import {
  API_ROUTES, CLIENT_COUNTRY, GET_SETTING, RECOMMENDED_FOOTAGES, RECOMMENDED_IMAGES,
} from 'constants/index';
import * as store from 'helpers/storage';
import * as types from './constants';

// eslint-disable-next-line import/prefer-default-export
export const applyMiddleware = (dispatch) => (action) => {
  switch (action.type) {
    case types.GET_IMAGES:
      dispatch({
        type: types.SET_CLICK,
        payload: action.payload.click,
      });
      return Axios.get(`${API_ROUTES.GET_LIST_IMAGES}${action.payload.click}`, (status, data) => {
        dispatch({
          type: types.SET_IMAGES,
          payload: data.content,
        });
      });
    case types.GET_SETTING:
      dispatch({ type: types.SET_SETTING_LOADING });
      return Axios.get(`${API_ROUTES.GET_SETTING}`, (status, data) => {
        Axios.addHeader(CLIENT_COUNTRY, data.content?.country);
        store.setStorage(GET_SETTING, data.content);
        dispatch({
          type: types.SET_SETTING,
          payload: data.content,
        });
      });
    case types.GET_RECOMMENDED_IMAGES:
      dispatch({ type: types.SET_RECOMMENDED_LOADING_IMAGES });
      return Axios.get(`${API_ROUTES.GET_RECOMMENDED}/${RECOMMENDED_IMAGES}`, (status, data) => {
        dispatch({
          type: types.SET_RECOMMENDED_IMAGES,
          payload: data.content,
        });
      });
    case types.GET_RECOMMENDED_FOOTAGE:
      dispatch({ type: types.SET_RECOMMENDED_LOADING_FOOTAGE });
      return Axios.get(`${API_ROUTES.GET_RECOMMENDED}/${RECOMMENDED_FOOTAGES}`, (status, data) => {
        dispatch({
          type: types.SET_RECOMMENDED_FOOTAGE,
          payload: data.content,
        });
      });
    default: dispatch(action);
  }
};
