import PropTypes from 'prop-types';
import { MAIN_COLOR } from 'constants/index';

const IcPersonalCd = ({
  fillcolor,
  width,
  height,
}) => (
  <svg width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="32" height="32" rx="2" fill="#333333" />
    <path fillRule="evenodd" clipRule="evenodd" d="M8.60463 16.9551C7.89964 17.7875 7.5 18.7565 7.5 19.7992C7.5 22.9954 11.2554 25.4992 16.05 25.4992C20.8447 25.4992 24.6 22.9954 24.6 19.7992C24.6 18.7565 24.2004 17.7875 23.4954 16.9551C23.2948 17.5662 22.9058 18.135 22.3682 18.6365C22.5832 19.0047 22.7 19.3958 22.7 19.7992C22.7 21.8588 19.6543 23.5992 16.05 23.5992C12.4457 23.5992 9.4 21.8588 9.4 19.7992C9.4 19.3958 9.51684 19.0046 9.73182 18.6364C9.1942 18.1349 8.80522 17.5662 8.60463 16.9551Z" fill={fillcolor} />
    <path fillRule="evenodd" clipRule="evenodd" d="M8.60463 13.1562C7.89964 13.9887 7.5 14.9576 7.5 16.0003C7.5 19.1966 11.2554 21.7003 16.05 21.7003C20.8447 21.7003 24.6 19.1966 24.6 16.0003C24.6 14.9577 24.2004 13.9887 23.4954 13.1563C23.2948 13.7674 22.9058 14.3362 22.3682 14.8377C22.5832 15.2059 22.7 15.597 22.7 16.0003C22.7 18.0599 19.6543 19.8003 16.05 19.8003C12.4457 19.8003 9.4 18.0599 9.4 16.0003C9.4 15.5969 9.51684 15.2058 9.73182 14.8376C9.1942 14.3361 8.80522 13.7674 8.60463 13.1562Z" fill={fillcolor} />
    <path fillRule="evenodd" clipRule="evenodd" d="M16.05 8.4C12.4457 8.4 9.4 10.1404 9.4 12.2C9.4 14.2596 12.4457 16 16.05 16C19.6543 16 22.7 14.2596 22.7 12.2C22.7 10.1404 19.6543 8.4 16.05 8.4ZM16.05 17.9C11.2554 17.9 7.5 15.3963 7.5 12.2C7.5 9.00372 11.2554 6.5 16.05 6.5C20.8447 6.5 24.6 9.00372 24.6 12.2C24.6 15.3963 20.8447 17.9 16.05 17.9Z" fill={fillcolor} />
    <rect x="4" y="4" width="24" height="24" fill="white" fillOpacity="0.01" />
  </svg>
);
IcPersonalCd.propTypes = {
  fillcolor: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

IcPersonalCd.defaultProps = {
  fillcolor: MAIN_COLOR,
  width: '32',
  height: '32',
};
export default IcPersonalCd;
