const API_PATH = `${process.env.NEXT_PUBLIC_API_URL}`;
const API_COPPLUS_BE = `${process.env.NEXT_COPPLUS_BE_API_URL}`;

export const ROUTES = {
  INDEX: '/',
  // dashboard
  DASHBOARD: '/dashboard',
  DETAIL_MY_ACTIVITIES: '/dashboard/detailMyActivities',
  DETAIL_MY_COLLECTION: '/dashboard/detailMyCollection',

  // UnAuthorizedLayout
  FORGOT_PASSWORD: '/forgotPassword',
  CONTACT: '/contact',
  CORPORATE_AGREEMENT: '/corporateAgreement',
  PRIVACY_POLICY: '/privacyPolicy',
  TERMS_OF_USE: '/termsOfUse',
  COOKIE_POLICY: '/cookiePolicy',
  RESET_PASSWORD: '/resetPassword',
  LOGIN: '/login',

  // AuthorizedLayout
  UPDATE_PASSWORD: '/updatePassword',
  LICENSE_AGREEMENT: '/licenseAgreement',
  HOME: '/home',
  CRM_LOGIN: '/crmLogin',
  DEBUG_INFO: '/debugInfo',
  VECTOR: '/vector',
  NOTIFICATION: '/notification',
  PORTFOLIO: '/portfolio',
  TWOFA: '/twoFA',

  // landing pages
  AUDIO: '/audio',
  FOOTAGE: '/footage',

  // search pages
  SEARCH_IMAGE: '/searchImage',
  SEARCH_FOOTAGE: '/searchFootage',
  SEARCH_AUDIO: '/searchAudio',
  REVERSE_SEARCH: '/reverseSearch',

  // detail pages
  DETAIL_AUDIO_PAGE: '/audio/detail',
  DETAIL_FOOTAGE_PAGE: '/footage/detail',
  DETAIL_IMAGE_PAGE: '/imageDetail',

  SHARE_LIKE_BOX: '/shareLikebox',
  // Customized Content Creation Page
  IMAGE_RESEARCH_SERVICE: '/imageResearchService',

  TEXT_TO_IMAGE: '/ai-image-generator',
};

export const API_ROUTES = {
  // get setting
  GET_SETTING: `${API_PATH}/api/app/settings`,
  GET_LIVE_CHAT: `${API_PATH}/api/app/get-show-live-chat`,

  // UnAuthorizedLayout
  LOGIN: `${API_PATH}/api/user/login`,
  GET_GG_CAPTCHA_RESPONSE: 'https://www.google.com/recaptcha/api/siteverify',
  FORGOT_PASSWORD: `${API_PATH}/api/user/forgotpassword`,
  RESET_PASSWORD: `${API_PATH}/api/user/resetpassword`,
  RE_LOGIN: `${API_PATH}/api/user/rememberme`,
  UPDATE_PASSWORD: `${API_PATH}/api/user/updatepassword`,
  LOGOUT: `${API_PATH}/api/user/logout`,
  LOGIN2FA: `${API_PATH}/api/loginOtp`,
  RESENDOTP: `${API_PATH}/api/resend-otp`,
  DELETEOTP: `${API_PATH}/api/delete-otp`,
  GET_REMAINING_OTP: `${API_PATH}/api/get-remaining-time`,

  // footer
  MENU_FOOTER_CONTENT: `${API_PATH}/api/app/footer-content`,
  AGEEMENT_STATUS: `${API_PATH}/api/user/license-agreement`,

  // GET RECENTLY VIEWED
  GET_RECENTLY_VIEWED: `${API_PATH}/api/user/recently-viewed-images`,
  GET_BROWSE_CATEGORY: `${API_PATH}/api/app/categories`,
  GET_RECENT_LIKES: `${API_PATH}/api/user/recent-likes`,

  // Notification
  GET_NOTI_LIST: `${API_PATH}/api/user/notification-list`,
  CLEAR_NOTI_LIST: `${API_PATH}/api/user/notification-list`,

  // Retrieve credit summary
  GET_CREDIT_SUMMARY: `${API_PATH}/api/user/retrieve-credit-summary`,

  // Get debug information
  GET_DEBUG_INFO: `${API_PATH}/api/app/debughelper`,

  // get recommended photo
  GET_RECOMMENDED: `${API_PATH}/api/app/recommended`,
  GET_CATEGORY: `${API_PATH}/api/app/categories`,
  GET_TRENDING_KEYWORDS: `${API_PATH}/api/keyword/trending`,
  GET_BLOG_DATA: `${API_PATH}/api/app/blogdata`,
  GET_LIST_IMAGES: `${API_PATH}/api/app/latest-images?clicked_count=`,

  // search bar
  AUTOCOMPLETE: `${API_PATH}/api/search/autocomplete`,
  UPLOAD_REVERSE_SEARCH: `${API_PATH}/api/search/reverse/upload`,
  GET_REVERSE_SEARCH: `${API_PATH}/api/search/reverse-search`,

  // search pages
  GET_RIBBON_KEYWORDS: `${API_PATH}/api/keyword/ribbon/search`,
  GET_IMAGE_SEACH_RESULTS: `${API_PATH}/api/search/search-image`,
  GET_FOOTAGE_SEACH_RESULTS: `${API_PATH}/api/search/search-footage`,
  GET_AUDIO_SEACH_RESULTS: `${API_PATH}/api/search/search-audio`,
  GET_RELATED_KEYWORDS: `${API_PATH}/api/keyword/related`,

  // get Likebox
  GET_LIKE_BOX: `${API_PATH}/api/user/likeboxes?`,
  ADD_LIKE_BOX: `${API_PATH}/api/user/add-likeboxes?`,
  DELETE_LIKE_BOX: `${API_PATH}/api/user/delete-likebox`,
  SHARE_LIKE_BOX: `${API_PATH}/api/user/list-receiver-share-likebox`,
  SHARE_LIKE_BOX_TO_USER: `${API_PATH}/api/user/share-likebox-to-userid`,
  SHARE_LIKE_BOX_TO_EMAIL: `${API_PATH}/api/user/likebox-share-email`,
  EDIT_LIKE_BOX: `${API_PATH}/api/user/edit-likebox`,
  EXPORT_LIKE_BOX: `${API_PATH}/api/user/export-likebox?`,
  ADD_IMAGE_LIKEBOX: `${API_PATH}/api/user/add-image-into-likebox`,
  REMOVE_IMAGE_LIKEBOX: `${API_PATH}/api/user/delete-images`,
  GET_ALL_LIKE_BOX: `${API_PATH}/api/user/get-likeboxes-with-groups`,
  GET_IS_ADDED_IMAGE: `${API_PATH}/api/user/get-added-likebox`,
  GET_DEFAULT_LIKEBOX: `${API_PATH}/api/user/get-default-likebox`,
  CHECK_ADDED_STATUS: `${API_PATH}/api/user/check-added-status`,

  // get images likebox images
  GET_LIKEBOX_IMAGES: `${API_PATH}/api/user/get-images-in-likebox`,

  // public share likebox
  PUBLIC_SHARE_LIKEBOX: `${API_PATH}/api/detail-shared-likebox`,
  DUPLICATE_LIKEBOX: `${API_PATH}/api/user/duplicate-likebox`,
  // shareWithMe
  GET_SHARE_WITH_ME: `${API_PATH}/api/user/likebox-share-to-me`,
  GET_DETAIL_LIKE_BOX: `${API_PATH}/api/user/detail-likebox`,
  SHARE_LIKEBOX_EMAIL: `${API_PATH}/api/user/likebox-share-email`,
  COPPY_IMAGE_LIKEBOX: `${API_PATH}/api/user/copy-likebox`,
  MOVE_IMAGE_LIKEBOX: `${API_PATH}/api/user/move-likebox`,
  DELETE_IMAGE_LIKEBOX: `${API_PATH}/api/user/delete-images`,

  // get contributor info
  GET_CONTRIBUTOR_INFO: `${API_PATH}/api/search/search-contributor`,

  // get user profile info
  GET_USER_PROFILE: `${API_PATH}/api/user/profile`,
  UPDATE_PROFILE_USER: `${API_PATH}/api/user/update-profile`,

  // get dowload history
  GET_DOWLOAD_HISTORY: `${API_PATH}/api/user/get-download-history`,
  EXPORT_DOWNLOAD_HISTORY: `${API_PATH}/api/user/export-download-history`,
  DOWNLOAD_HISTORY_HRUC: `${API_PATH}/api/user/download-history-hruc`,
  INFO_LICENSE_MODAL_DOWNLOAD_HISTORY_HRUC: `${API_PATH}/api/user/info-license-modal-download-history-hruc`,
  DELETE_IMAGE_DOWNLOAD_HISTORY_HRUC: `${API_PATH}/api/user/delete-images-download-history-hruc`,
  GET_OUTSTANDING_UNPL: `${API_PATH}/api/user/get-outstanding-unpl`,
  LICENSE_IMAGES_DOWNLOAD_HISTORY_HRUC: `${API_PATH}/api/user/license-images-download-history-hruc`,
  LICENSE_IMAGES_DOWNLOAD_HISTORY_HRUC_LICENSE_2: `${API_PATH}/api/user/license-images-download-history-hruc/license2`,
  // get data detail pages
  GET_DATA_AUDIO_DETAILS: `${API_PATH}/api/detail/audio`,
  GET_DATA_FOOTAGE_DETAILS: `${API_PATH}/api/detail/footage`,
  GET_DOWLOAD_HISTORY_DETAILS: `${API_PATH}/api/user/get-download-details`,
  GET_REDOWLOAD_HISTORY_DETAILS: `${API_PATH}/api/user/get-redownload-details`,
  GET_DOWNLOADED_USERS: `${API_PATH}/api/user/get-downloaded-users`,
  EDIT_TAG: `${API_PATH}/api/user/edit-tag`,
  POPULATE_TAG: `${API_PATH}/api/user/tag-populate`,
  POPULATE_TAG_TTI: `${API_PATH}/api/user/tti-tag-populate`,
  TAG_DETAILS: `${API_PATH}/api/user/get-tagdetails`,
  TAG_POPULATE: `${API_PATH}/api/user/tag-populate`,
  GET_DOWNLOAD_DETAILS: `${API_PATH}/api/user/get-download-details`,
  GET_CREDITS_GROUP_USER: `${API_PATH}/api/user/get-credits-of-groups-and-user`,
  // image details
  GET_IMAGE_DETAIL: `${API_PATH}/api/detail/image`,
  GET_IMAGE_BGREMOVAL: `${API_PATH}/api/detail/editor/image/bgr-remove`,
  GET_MEMBERS_GROUPS: `${API_PATH}/api/user/get-group-members`,
  DOWNLOAD_IMAGE: `${API_PATH}/api/user/download-image`,

  // Custom Tags
  GET_ALL_CUSTOM_FIELDS: `${API_PATH}/api/user/custom-fields`,
  // admin settings
  UPDATE_CUSTOMIZE: `${API_PATH}/api/user/update-customize`,
  // Company Info
  UPDATE_CONTACT_FORMS: `${API_PATH}/api/user/update-contact-form`,
  CREDIT_USAGE_USER: `${API_PATH}/api/user/credits-usage-user`,
  CREDIT_USAGE_GROUP: `${API_PATH}/api/user/credits-usage-group`,
  // get Data Setting dashboard
  GET_DATA_SETTINGS: `${API_PATH}/api/user/get-data-settings`,
  UPDATE_DATA_SETTING_DASHBOARD: `${API_PATH}/api/user/update-settings`,
  // Admin dashboard
  GET_SUMMARY_BALANCE: `${API_PATH}/api/user/summary-balance`,
  // Manage user/group
  GET_LIST_USER: `${API_PATH}/api/user/user-list`,
  GET_LIST_GROUP: `${API_PATH}/api/user/group-list`,
  ADD_GROUP: `${API_PATH}/api/user/add-group`,
  DELETE_USER: `${API_PATH}/api/user/delete-users`,
  GET_GROUP: `${API_PATH}/api/user/groups-user`,
  ASSIGN_GROUP_TO_USERS: `${API_PATH}/api/user/assign-groups-to-user`,
  ASSIGN_CREDITS_TO_USER: `${API_PATH}/api/user/assign-credit-to-user`,
  EDIT_DETAIL_USER: `${API_PATH}/api/user/edit-detail-user`,
  ADD_NEW_USER: `${API_PATH}/api/user/add-new-user`,
  MANAGE_GROUP_MEMBER: `${API_PATH}/api/user/manage-group-member`,
  EDIT_GROUP_DETAIL: `${API_PATH}/api/user/edit-detail-group`,
  ASSIGN_CREDITS_TO_GROUP: `${API_PATH}/api/user/assign-credit-to-group`,
  DELETE_GROUP: `${API_PATH}/api/user/delete-groups`,
  ASSIGN_GROUP_PERSONAL_CREDITS: `${API_PATH}/api/user/assign-group-personal-credits`,
  ASSIGN_DOWNLOAD_QUOTA_TO_USER: `${API_PATH}/api/user/assign-download-quota-to-user`,
  ASSIGN_DOWNLOAD_QUOTA_TO_GROUP: `${API_PATH}/api/user/assign-download-quota-to-group`,
  // Invoice
  GET_INVOICE_CREDITS_CLIENT: `${API_PATH}/api/user/get-invoice-credits`,

  TRANSFER_CREDITS_FROM_123RF: `${API_PATH}/api/transfer-credits-from-123rf`,
  // invoice UNPL
  GET_LIST_INVOICE_UNPL_DASHBOARD: `${API_PATH}/api/user/get-list-invoice-unpl`,
  GET_DETAIL_INVOICE_UNPL: `${API_PATH}/api/user/get-preview-invoice`,
  GET_MANAGE_INVOICE: `${API_PATH}/api/user/get-manage-invoice`,
  GET_LIST_MANAGE_ADDRESS: `${API_PATH}/api/user/get-manage-invoice-address-list`,
  CHANGE_ADDRESS_INVOICE: `${API_PATH}/api/user/change-address-invoice-preview`,
  MANAGE_INVOICE_PREVIEW: `${API_PATH}/api/user/manage-invoice-preview`,
  MANAGE_INVOICE_ADDRESS_LIST: `${API_PATH}/api/user/manage-invoice-address`,
  // Pricing Package
  GET_PRICING_PACKAGES: `${API_PATH}/api/user/get-pricing-package`,
  GET_CHECKOUT_PRICING_PACKAGES: `${API_PATH}/api/user/checkout-pricing-package`,

  // Download
  DOWNLOAD_IMAGE_SEARCH_PAGE: `${API_PATH}/api/user/download-image-search-page`,
  GET_MODAL_DOWNLOAD_LIKEBOX: `${API_PATH}/api/user/get-download-modal-likebox`,
  BULKDOWNLOAD_LIKEBOX: `${API_PATH}/api/user/bulkdownload-likebox`,
  DOWNLOAD_AUDIO: `${API_PATH}/api/user/download-audio`,
  DOWNLOAD_FOOTAGE: `${API_PATH}/api/user/download-footage`,
  RE_DOWNLOAD: `${API_PATH}/api/user/re-download`,

  // Editor tool
  GET_SHAPE_LIST: `${API_PATH}/api/detail/editor/image/shapes`,
  // Editor tools
  GET_FONTS: `${API_PATH}/api/detail/editor/font-family`,
  HRUC_BULKDOWNLOAD_LIKEBOX: `${API_PATH}/api/user/bulkdownload-likebox`,
  CONFIRM_HRUC_BULKDOWNLOAD_LIKEBOX: `${API_PATH}/api/user/confirm-bulkdownload-likebox`,

  REQUEST_IMAGE_RESEARCH: `${API_PATH}/api/user/request-image-research-service`,

  GENERATE_IMAGE: `${API_PATH}/api/user/img-generator`,
  GET_PAST_GENERATIONS: `${API_PATH}/api/past-img-generations`,

  DOWNLOAD_AI_IMAGE: `${API_PATH}/api/user/download-tti-image`,
  GET_LASTEST_HASH: `${API_PATH}/api/user/init-tti-data`,
  DOWNLOAD_COMP_AUDIO: `${API_PATH}/api/user/download-comp-audio`,
  DOWNLOAD_COMP_FOOTAGE: `${API_PATH}/api/user/download-comp-footage`,
  DOWNLOAD_HIRES_COMP: `${API_PATH}/api/user/download-hi-res-comp`,
};

export const ROUTES_LEGACY = {
  // landing pages
  PHOTO: '/new/photo.php',
  VECTOR: '/new/vector.php',
  FOOTAGE: '/new/footage.php',
  AUDIO: '/new/audio.php',
  HOME: '/new/index.php',
  LEGACY: '/new/home.php',
  // search pages
  SEARCH_AUDIO: '/new/search_audio.php',
  SEARCH_IMAGES: '/new/search_image.php',
  SEARCH_FOOTAGE: '/new/search_footage.php',
  PORTFOLIO_AUDIO: '/new/portfolio_audio.php',

  // detail pages
  FOOTATE_DETAIL: '/new/footage_detail.php',
  IMAGE_DETAIL: '/new/image_detail.php',
  AUDIO_DETAIL: '/new/audio_detail.php',

  // dashboard pages
  DOWNLOAD_HISTORY_USER: '/new/download_history_user.php',
  MANAGE_HRUC_USER: '/new/manage_hruc_user.php',
  DASHBOARD: '/new/dashboard.php',

  // collection
  LIKE_BOX_COLLECTION: '/new/lightboxpage.php',

  // other pages
  CONTACT_US: '/new/contact.php',
  PRIVACY_POLICY: '/new/privacy.php',
  TERMS: '/new/terms.php',
  COOKIE: '/new/cookie.php',
  LICENSE: '/new/license_agreement.php',

  DOWLOADHISTORY_USER: '/new/download_history_user.php',
  ADMIN_CONTROL_PANEL: '/new/dashboard.php',
  PLANSANDPRICING: '/new/pricing_package.php',
  LICENSE_AGREEMENT: '/new/license_agreement.php',
  CUSTOMIZE: '/new/customize.php',
};

export const ROUTES_API = {
  GET_BROWSE_CATEGORY: `${API_COPPLUS_BE}/api/app/categories`,
  GET_SETTING: `${API_COPPLUS_BE}/api/app/settings`,
  USER_PROFILE: `${API_COPPLUS_BE}/api/user/profile`,
};

export const ROUTES_LIVE = [
  ROUTES.LOGIN,
  ROUTES.FORGOT_PASSWORD,
  ROUTES.UPDATE_PASSWORD,
  ROUTES.RESET_PASSWORD,
  ROUTES.CRM_LOGIN,
  ROUTES.DEBUG_INFO,
  ROUTES.CONTACT,
  ROUTES.COOKIE_POLICY,
  ROUTES.TERMS_OF_USE,
  ROUTES.TWOFA,
  ROUTES.CORPORATE_AGREEMENT,
  ROUTES.LICENSE_AGREEMENT,
  ROUTES.PRIVACY_POLICY,
  ROUTES.HOME,
  ROUTES.VECTOR,
  ROUTES.AUDIO,
  ROUTES.FOOTAGE,
  ROUTES.SEARCH_IMAGE,
  ROUTES.SEARCH_FOOTAGE,
  ROUTES.SEARCH_AUDIO,
  ROUTES.REVERSE_SEARCH,
  ROUTES.PORTFOLIO,
  ROUTES.DASHBOARD,
  ROUTES.DETAIL_MY_COLLECTION,
  ROUTES.DETAIL_MY_ACTIVITIES,
  ROUTES.DETAIL_AUDIO_PAGE,
  ROUTES.DETAIL_FOOTAGE_PAGE,
  ROUTES.DETAIL_IMAGE_PAGE,
  ROUTES.SHARE_LIKE_BOX,
  ROUTES.IMAGE_RESEARCH_SERVICE,
  ROUTES.TEXT_TO_IMAGE,
];

export const ROUTES_UNAUTHORIZED = [
  ROUTES.LOGIN,
  ROUTES.FORGOT_PASSWORD,
  ROUTES.RESET_PASSWORD,
  ROUTES.CRM_LOGIN,
];

export const ROUTES_ALLOW_NOT_YET_UPDATE_PASSWORD = [
  ROUTES.UPDATE_PASSWORD,
  ROUTES.CRM_LOGIN,
  ROUTES.DEBUG_INFO,
  ROUTES.CONTACT,
  ROUTES.COOKIE_POLICY,
  ROUTES.TERMS_OF_USE,
  ROUTES.CORPORATE_AGREEMENT,
  ROUTES.LICENSE_AGREEMENT,
  ROUTES.PRIVACY_POLICY,
];

export const ROUTES_ALLOW_SEARCH_BY_IMAGE = [
  ROUTES.SEARCH_IMAGE,
  ROUTES.HOME,
  ROUTES.VECTOR,
  ROUTES.REVERSE_SEARCH,
  ROUTES.PORTFOLIO,
  ROUTES.SHARE_LIKE_BOX,
  ROUTES.DETAIL_IMAGE_PAGE,
];

export const ROUTES_DISPLAY_STICKY_AUDIO = [ROUTES.SEARCH_AUDIO, ROUTES.DETAIL_AUDIO_PAGE];

export const ROUTES_DISPLAY_STICKY_MY_COLLECTION = [ROUTES.SEARCH_AUDIO, ROUTES.DETAIL_AUDIO_PAGE];

export const ROUTES_SEARCH_PAGE = [
  ROUTES.SEARCH_AUDIO,
  ROUTES.SEARCH_FOOTAGE,
  ROUTES.SEARCH_IMAGE,
  ROUTES.REVERSE_SEARCH,
  ROUTES.SHARE_LIKE_BOX,
];
