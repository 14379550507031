const tr = {
  // Header
  photos: 'Fotoğraf',
  Photos: 'Fotoğraf',
  vectors: 'Vektörler',
  Vectors: 'Vektörler',
  footageupper: 'VIDEO',
  footage: 'Video',
  video: 'Video',
  audio: 'Ses',
  signin: 'Giriş',
  imageLogo:
    'https://static-cdn.assetsdelivery.com/images/new_translate_corpplus_logo/logo_Turkce.png',
  language: 'Dil',
  // Footer
  contact: 'İletişim',
  contactUs: 'İletişim',
  privacy: 'Gizlilik İlkesi',
  terms: 'Kullanım Şartları',
  cookie: 'ÇEREZ POLİTİKASI',
  corporateAgreement: 'License Agreement',
  // ForgotPassword
  forgotPwdTitle2: 'Şifrenizi mi unuttunuz',
  forgotPwdDesc2:
    "That's OK, it happens to all of us. Enter your username below and we'll send you a link to reset your password",
  forgotPwdFail: 'Lütfen geçerli eposta adresi giriniz.',
  emailCannotEmpty: 'E-mail boş olamaz.',
  returnToLogin: 'Giriş için geri git',
  email: 'Eposta',

  // share likebox
  duplicateCollection: 'Duplicate collection',
  removeFromCollection: 'Remove from collection',
  addToCollection: 'Add to collection',
  totalContentInThisCollection: 'Total content in this collection',
  duplicate: 'Duplicate',
  collectionDescription: 'Collection description',
  descriptionHere: 'Description here',
  duplicateCollectionSuccess:
    'You have successfully duplicated this collection to your personal collections.',

  // Login
  username: 'Kullanıcı Adı',
  pw: 'Parola',
  rememberMe: 'Beni Hatırla',
  login: 'Giriş',
  forgotPassword: 'Parolanızı mı unuttunuz',
  loginAsGuest: 'Misafir Girişi',
  // ResetPassword
  resetPwdDesc: 'Yeni şifrenizi girin ve hemen  giriş yapmanızı sağlayalım.',
  newPw: 'Yeni Parol',
  confirmPwd: 'Şifreyi Onayla',
  resetPwdButton: 'Şifre Resetle',
  resetPwdTitle: 'Şifrenizi sıfırlayın',
  enterYourNewPw: 'Lütfen yeni şifrenizi giriniz.',
  pwMiniMumChars: 'Minimum 8 karakter',
  pwMaxChar20: 'Maximum 20 karakter yazılabilir',
  pwLowerCase: 'En az bir küçük harf içermeli',
  pwUpperCase: 'En az bir büyük harf içermeli',
  pwNumeral: 'En az 1 rakam içermeli',
  pwdSpecialChar: 'At least one special character',
  confirmPwNotMatch: 'Şifre doğrulama ile şifreniz aynı değil',
  resetPwdInvalidLink: 'Bu bağlantı geçersiz veya süresi dolmuş.',
  pwHasBeenReset: 'Şifreniz yenilenmiştir, lütfen bu şifrenizle giriş yapınız.',
  // Admin index
  companyName: 'Firma Adı',
  phone: 'Telefon',
  fax: 'Faks',
  address: 'Adres',
  idd: 'IDD',
  // Dashboard Admin Credits
  myBalance: 'My balance',
  remaining: 'remaining',
  creditsRemaining: 'Credits remaining',
  topUp: 'Top up',
  groupBalance: 'Group balance',
  totalGroupBalance: 'Total group balance',
  manageGroupBalance: 'Manage group balance',
  individualBalance: 'Individual balance',
  manageIndividualBalance: 'Manage individual balance',
  totalDownloads: 'Total downloads',
  totalItems: 'Toplam Öğe',
  // Update Password
  updatePassTitle: 'Update Password',
  updatePassDes: 'Update your password before you go!',
  oldPassword: 'Eski Parola',
  confirmNewPassword: 'Yeni Parolayı Onayla',
  returnToHome: 'Ana sayfaya dön',
  // Cookie Policy
  langCookie01:
    '%host% adresini ziyaret ettiğinizde, bilgisayarınızın sabit diskine bir çerez adı verilen küçük bir metin dosyası yerleştiririz.',
  langCookie02:
    'Bu dosya alışveriş sepetleri gibi temel işlevleri sağlayabilmemiz için sizi izlememize izin veriyor. Ayrıca, gelişmiş bir çevrimiçi kullanıcı deneyimi sağlamak ve "Görsel Sepeti" gibi işlevsellik sağlamak için kullanılır.',
  langCookie03:
    'Ayrıca, web sitemizle etkileşimi analiz etmemize olanak sağlar (ziyaret edilen sayfalar, görüntülenen terimler ve görüntülenen görseller dahil). Bu, bir satın alma işlemi yapıp yapmadığınızdan bağımsızdır.',
  langCookie04:
    '"Onay verdiğiniz çerezler bir kitleye ilgi alanlarına göre özelleştirilmiş ve hedeflenmiş reklamlar sunabilmemiz kullanılmaktadır."',
  langCookie05:
    'Çoğu tarayıcı çerezleri reddetmenize izin verir; ancak bunu yaparsanız, belirli işlevler kullanılamayabilir ve belirli web sayfaları düzgün görüntülenmeyebilir.',

  downloadModalTitle: 'Görsel İndir',
  bulkDownloadTitle: 'Bulk Download',
  messageFileNotSupported1stHalf:
    'Görsel TIFF dosyası, video ve müzik desteklenmez. Aşağıdaki içerik:',
  messageFileNotSupported2ndHalf: 'Toplu İndirmeden kaldırılmıştır.',
  licenseOnBehalf: 'Adına Lisansla',
  customizedPortal: 'Customized portal',
  normalDownload: 'Normal İndirme',
  myCredit: 'Kredilerim',
  lowCreditsReminder: 'Düşük Kredi',
  downloadModalId: 'Görsel Kimliği:',
  downloadModalPrice: 'Kredit:',
  clearAll: 'Tümünü temizle',
  myActivities: 'Hareketlerim',
  info: 'Bilgi',
  idCapitialization: 'ID',
  licenseOnBehalfOfYourClient: 'License on behalf of your client (Your client owns the license)',
  downloadUsing: 'bu içeriği kullanarak indirin',
  // homepage page
  home3Title: 'Free Browsing',
  toSearchKeyword: 'Aramanızı buraya yazın',
  toSearchAIKeyword: 'Anahtar kelimeleri, makale başlığını veya blog başlığını girin',
  allImages: 'Tüm Görseller',
  freeImages: 'Free Images',
  categoryImage: 'Category',
  recentViewed: 'Son Görüntülenen',
  latestIMG: 'Latest Images',
  // Setting
  adminControlPanel: 'Corp+ Yöneticisi Kontrol Paneli',
  downloadHistory: 'İndirme Geçmişi',
  hruc: 'Ücretsiz Taslak İndirme',
  changePassword: 'Parola Değiştir',
  logOut: 'Çıkış',
  hello: 'Merhaba,',
  viewAccount: 'View Account',
  myAccount: 'Hesabım',
  myCollections: 'Koleksiyonlarım',
  personalCredits: 'Kişisel Krediler',
  groupTotal: 'Grup Toplamı',
  toTalUnpaidLicense: 'Toplam Ödenmemiş Lisans',
  highRUnwatermarkComp: 'Yüksek Çözünürlüklü Fligransız Taslak Görüntü',
  monthSelection: 'Month selection',

  // Menu
  categories: 'Kategoriler',
  myLikebox: 'Görsel Sepetim',
  settings: 'Ayarlar',
  notification: 'Bildirimler',
  clear: 'Temizle',
  likebox: '收藏夹',
  credits: 'Krediler',
  // Credit summary at header
  downloadAndUsege: 'İndirme ve Kullanım',
  totalDlCredit: 'Mevcut Krediler',
  allocatedDlCredit: 'Dağıtılmış  Krediler',
  personalAvailableCre: 'Kişisel Mevcut Kredi',
  groupAvailableCre: 'Grup Mevcut Kredi',
  availableDlSlot: 'Mevcut İndirme Slot(ları)',
  uNPLD: 'Şimdi Kullan, Sonra Öde',
  hiResHRUC: 'Yüksek Fligransız Taslak Görüntü (YFTG)',
  monthlyAllocation: 'Aylık Yükleme',
  remainingQuota: 'Kalan Kota',
  remainingHRUCQ: 'Kalan Yüksek Fligransız Taslak Görüntü Kotası',
  useNowPayLater: 'Use-Now-Pay-Later (UNPL)',
  totalPendingLicense: 'Total Pending License Downloads',
  enterprisePlan: 'Corporate+ Enterprise Plan',
  unallocatedCredits: 'Unallocated Credits',
  unutilizedAllocatedCredits: 'Unutilized Allocated Credits',
  usersTitleTotalCredits: 'Users',
  groupsTitleTotalCredits: 'Gruplar',
  titleTotalCreditsAvailable: 'Total Available',
  // live chat
  liveChatLookIngHelp: 'Lütfen yardım almak istediğiniz konu hakkında bize bilgi verin.',
  liveChat: 'Canlı Yardım',
  whatsApp: 'WhatsApp',
  hiThere: 'Merhaba,',
  // email response
  emailIsNotAvailable: 'Geçersiz E-mail.',
  anErrorHaSoccured: 'Bir hata oluştu, lütfen tekrar deneyin.',
  emailHasBeenSent:
    'E-mailiniz başarıyla gönderildi, işleme koymak için mail kutunuzu kontrol ediniz. Şifre sıfırlama bilgilerini  eposta gönderdiğimiz adres __email . E-postamız 5 dakika içinde gelen kutunuzda  görünmezse lütfen Spam klasörünüzü kontrol edin.',
  tooManyAttempts: 'Çok fazla başarısız deneme oldu. Lütfen daha sonra tekrar deneyin.',
  // INVALIDPASSWORD
  inValidPassword: 'Invalid password! Check if your caps lock is on.',
  userIsBlocked: 'Çok fazla başarısız deneme, geçici olarak engellendiniz.',
  blockWarrings: '10 denemeden sonra geçici olarak engelleneceksiniz!',
  // Update Password
  resetPwdUpdated: 'Şifreniz güncellendi.',
  updatePwDfailed: 'Şifre güncellenemedi!',
  // notification
  // Notification Side Bar - user
  notificationShareLikebox: 'shared a Likebox named [{VAR1}] with you.',
  notificationOutofCredits: 'ran out of download credits.',
  notificationAllcateCredit: 'assigned [{VAR1}] download credits to you.',
  notificationRemoveCredits: 'removed [{VAR1}] download credits from your account.',
  notificationAssignGroups: 'assigned you into the [{VAR1}] group.',
  notificationRemoveFromGroup: 'removed you from the [{VAR1}] group.',
  purcahsedCredits: 'You have just purchased [{VAR1}] credits.',
  notificationAssignAdmin: 'assigned you as the administrator.',
  notificationRemoveAdmin: 'removed you as the administrator.',
  notificationAssignGroupAdmin: 'assigned you as the group administrator of [{VAR1}].',
  notificationRemoveGroupAdmin: 'removed you as the group administrator of [{VAR1}].',
  // Notification Side Bar - admin
  adminNotificationShareLikebox: 'shared a Likebox named [{VAR1}] with [{RECEIVER}].',
  adminNotificationAllcateCredits: 'assigned [{VAR1}] download credits to [{RECEIVER}].',
  adminNotificationRemoveCredist: "removed [{VAR1}] download credits from [{RECEIVER}]'s account.",
  adminNotificationAssignGroup: 'assigned [{RECEIVER}] into the [{VAR1}] group.',
  adminNotificationRemoveFromGroup: 'removed [{RECEIVER}] from the [{VAR1}] group.',
  adminPurchasedCredits: '[{RECEIVER}] have just purchased [{VAR1}] credits.',
  adminNotificationAssignAdmin: 'assigned [{RECEIVER}] as the administrator.',
  adminNotificationRemoveAdmin: 'removed [{RECEIVER}] as the administrator.',
  adminNotificationAssignGroudAdmin:
    'assigned [{RECEIVER}] as the group administrator of [{VAR1}].',
  adminNotificationremoveGroupAdmin: 'removed [{RECEIVER}] as the group administrator of [{VAR1}].',
  // datetime
  year: 'year',
  month: 'month',
  week: 'week',
  day: 'day',
  hour: 'hour',
  minute: 'minute',
  second: 'second',
  ago: 'ago',
  justNow: 'just now',
  // datetimes
  years: 'years',
  months: 'months',
  weeks: 'weeks',
  days: 'days',
  hours: 'hours',
  minutes: 'dakika',
  seconds: 'seconds',
  // LicenseAgreement
  licenseAgreement: 'Lisans Anlaşması',
  licenseAgreementCaption:
    'PLEASE READ ALL THE TERMS PROVIDED IN THIS CORPORATE+ CONTENT LICENSING PLATFORM. YOUR ACCEPTANCE OF THESE TERMS IS AN ABSOLUTE CONDITION OF YOUR USE OF THE CORPORATE+ CONTENT LICENSING PLATFORM AND THE CONTENT, AND FORMS A LEGAL AGREEMENT. BY ACCESSING ANY SERVICE OR PART THEREOF UNDER THE CORPORATE+ CONTENT LICENSING PLATFORM, YOU AGREE TO BE BOUND BY THE TERMS OF THESE LEGAL DOCUMENTS. 123RF RESERVES THE RIGHT TO CHANGE THESE TERMS AT ANY TIME WITHOUT PRIOR WRITTEN NOTICE, AND YOU AGREE TO BE BOUND BY SUCH CHANGES.',
  corpplusAgreement: 'Corporate+ Agreement',
  // login
  usernameCannotEmpty: 'Kullanıcı adı boş olamaz',
  passWordCannotEmpty: 'Şifre boş olamaz',
  oldPasswordCannotEmpty: 'Eski şifre boş olamaz',
  confirmPasswordCannotBeEmpty: 'Yeni şifrenin boş olamayacağını onayla',
  // update password
  newpasswordDifferent: 'The new pasword and old pasword must be different.',
  // sent email not 20 times
  forgotNot20: 'Cannot send more emails, please check your mailbox.',
  // errorMesServer
  errorMesServer: 'An error has occured, please try again or contact your administrators',
  // white space
  canNotContainSpaces: 'Password cannot contain spaces',
  // username is not available
  userNameNotAvailable: 'Username is not available.',
  // Username cannot contain spaces
  usernameCanNotContainSpaces: 'Username cannot contain spaces',
  // old browsers
  usingOlderBrowsers: 'Eski bir tarayıcı mı kullanıyorsunuz?',
  upgradeBrowser: 'Daha sorunsuz bir deneyim yaşamak için tarayıcınızın sürümünü yenileyin!',
  clickToDownload: 'Bunlardan birini indirmek için tıklayın:',
  googleChrome: 'Google Chrome',
  mozillaFirefox: 'Mozilla Firefox',
  microsoftEdge: 'Microsoft Edge',
  safari: 'Safari',
  // forgot page new description
  forgotPwdDescNew: "Enter your username below and we'll send you a link to reset your password.",
  // Notification
  readAll: 'Read All',
  viewAll: 'Hepsini Gör',
  // Filter bar
  aiGenerate: 'Yapay zeka tarafından oluşturulan görüntü',
  aiContent: 'Yapay zeka tarafından oluşturulan içeriği dahil edin',
  filter: 'Filtrele',
  safeSearch: 'Güvenli arama',
  aiAssist: 'AI Yardım',
  freshness: 'Güncellik',
  gender: 'Cinsiyet',
  sortBy: 'Şuna Göre Sırala',
  mediaType: 'Görsel Tipi',
  orientation: 'Yön',
  modelPreferences: 'Model Tercihi',
  people: 'Insanlar',
  more: 'Daha fazla',
  numOfPeople: 'Kişi Sayısı',
  age: 'Yaş',
  ethnicity: 'Etnik Köken',
  collection: 'Kolleksiyonlar',
  uploaded: 'Uploaded',
  style: 'Stil',
  cutOut: 'Düz/Şeffaf Arka Planlar',
  doNotIncludeWords: 'Bu kelimeleri içermez',
  doNotIncludeWords_ex: 'Örn. peynir',
  color: 'Renk',
  max1Color: 'En fazla 1 renk',
  license: 'Lisans türü',
  fps: 'FPS',
  resolution: 'Çözünürlük',
  clipDuration: 'Klip Süresi (dakika)',
  loop: 'Döngü',
  category: 'Kategori',
  genre: 'Tarz',
  tempo: 'Tempo (bpm)',
  // Filter button text
  Relevance: 'Rasgele',
  New: 'Yeni',
  Georank: 'Coğrafi Sıralama',
  DownloadHistory: 'Download Kayıtları',
  Popularity: 'Popülerlik',
  Photography: 'Fotoğrafçılık',
  VectorIllustration: 'Vektör Çizim',
  Horizontal: 'Yatay',
  Landscape: 'Geniş',
  Portrait: 'Portre',
  Square: 'Kare',
  Panorama: 'Panorama',
  numPpl: 'Kişi Sayısı',
  WithoutPeople: 'İnsansız',
  FilterNumberPeople1: '1 person',
  FilterNumberPeople2: '2 People',
  FilterNumberPeople3: '3 People',
  FilterNumberPeople4: '4 people or more',
  Exclude: 'Hariç Tut',
  SelectiveFocus: 'Selective Focus',
  Pattern: 'Pattern',
  Vibrance: 'Vibrance',
  Infants: 'Infants',
  Toddlers: 'Toddlers',
  Teens: 'Teens',
  PastWeek: 'Son 1 hafta içinde eklenen',
  LastMonth: 'Last month',
  PastThreeMonths: 'Son 3 ay içinde eklenen',
  PastTwodays: 'Son 2 gün içinde eklenen',
  Male: 'Erkek',
  Female: 'Kadın',
  Standard: 'Stardart',
  extended: 'Genişletilmiş',
  Editorial: 'Haber Amaçlı',
  Commercial: 'Ticari',
  Black: 'Black',
  Caucasian: 'Beyaz',
  AfricanAmerican: 'Afro-Amerikan',
  Asian: 'Asyalı',
  EastAsian: 'East Asian',
  Hispanic: 'Hispanik',
  Indian: 'Indian',
  MiddleEastern: 'Middle Eastern',
  SoutheastAsian: 'Southeast Asian',
  Children: 'Çocuk',
  Teenagers: 'Ergen',
  Adults: 'Yetişkin',
  Seniors: 'Yaşlı',
  FPS1: '23.98/24',
  FPS2: '25',
  FPS3: '29.7/30',
  FPS4: '60',
  FPS5: '> 60',
  Off: 'Off',
  ZeroToOne: '0-1',
  OneToTwo: '1-2',
  TwoToThree: '2-3',
  LagerThree: '>3',
  Loopable: 'Evet',
  Nonloopable: 'Yok',
  Music: 'Müzik',
  SoundEffects: 'Ses Efektleri',
  LogoAndIdents: 'Logo & Kimlik',
  Veryslow: 'Çok Yavaş',
  VeryslowSubText: '(0-79)',
  Slow: 'Yavaş',
  SlowSubText: '(80-119)',
  Medium: 'Orta',
  MediumSubText: '(120-139)',
  Fast: 'Hızlı',
  FastSubText: '(140-159)',
  Veryfast: 'Çok Hızlı',
  VeryfastSubText: '(160>)',
  TwoToEight: '2 - 8',
  TwentyToThirTy: '12 - 30',
  ThirtySixToSixty: '30 - 60',
  OneHundredAndTwenty: '120',
  Popular: 'Popüler',
  MostDownload: 'Most Download',
  Credits: 'Krediler',
  Freshness: 'Güncellik',
  pickAGenre: 'Bir tür seçin',
  // Paginations
  Of: 'No.',
  Page: 'Sayfa',
  // home
  recommended: 'Sizin için Önerilen',
  bestSellingHome: 'Browse best-selling royalty photos',
  trendingKeyWord: 'Trend Anahtar Kelimeler',
  FAQ: 'Frequently Asked Questions (FAQ)',
  // audio
  categoriesAudio: 'HD stock audios',

  // genre list audios
  gameSounds: 'Oyun Sesleri',
  cartoonSounds: 'Çizgifilm Sesleri',
  soundPacks: 'Ses Paketleri',
  interfaceSounds: 'Arayüz Sesleri',
  transitionsAndMovementsSounds: 'Geçiş & Hareket',
  natureSounds: 'Doğa Sesleri',
  domesticSounds: 'Eve Ait Sesler',
  urbanSounds: 'Şehir Sesleri',
  industrialSounds: 'Endüstriyel Sesler',
  futuristicSounds: 'Fütüristik Sesler',
  humanSounds: 'İnsan Sesleri',
  othersSounds: 'Diğer',
  ambientSounds: 'Ortam',
  childrenSounds: 'Çocuk',
  cinematicSounds: 'Sinematik',
  classicalSounds: 'Klasik Müzik',
  corporateSounds: 'Ticari',
  electronicaSounds: 'Elektronik Müzik',
  soulSounds: 'Soul & R&B',
  jazzSounds: 'Jazz',
  popSounds: 'Pop',
  worldBeatSounds: 'World Beat',

  countrySounds: 'Batı',
  drumSounds: 'Davul & Bas',

  folkSounds: 'Yerel & Akustik',
  funkSounds: 'Funk & Groove',

  hiphopSounds: 'Hip Hop',
  holidaySounds: 'Tatil ve Mevsimler',

  rockSounds: 'Rock',

  individualSounds: 'Kişisel',
  introSounds: 'Giriş & Kapanış',
  ascSounds: 'Artan & Azalan',
  businessSounds: 'İş Dünyası & Medya',
  orchestralSounds: 'Orkestra',
  festiveSounds: 'Festival',
  percussiveSounds: 'Vurmalı',
  miscellaneousSounds: 'Çeşitli',
  packsSounds: 'Paketler',
  percussionSounds: 'Perküsyon Sesleri',
  sampleLoopSounds: 'Örnek Döngü',

  // Search images
  searchImage: 'Fotoğraf & Vektör',
  StockPhotosAndImages: 'Stok Fotoğraf & Görsel',
  StockVectorClipartAndIllustration: 'Stok Vektör Çizimler ve Çizimler',

  matches: 'Eşleşme',
  editorial: 'Haber Amaçlı',
  downloaded: 'Bu görsel önceden indirilmiş',
  to: 'to',
  relatedSearches: 'İlgili Aramalar',
  similarTo: 'Şuna benzer',
  searchByImage: 'Görselle Ara',
  options: 'Seçenekler',
  searchByImageMsg:
    "123RF'de metin yerine bir resim ile arama yapın. Bir resmi arama kutusuna sürüklemeyi deneyin.",
  mustBeJPGorPNG: 'Görsel JPG / PNG olmalıdır',
  sizeLessThan5MB: "Görsel boyutu 5 MB'den küçük olmalıdır",
  sizeLessThan4MB: "Görsel boyutu 4 MB'den küçük olmalıdır",
  dragOrDropFile: 'Dosyayı sürükleyip bırakın veya',
  browse: 'Göz at',
  uploadedImage: 'Yüklenen Resim',
  similarStockPhotos: 'Benzer Stok Fotoğraflar',
  // Search footage
  searchFootage: 'Video',
  StockFootage: 'Stok Video',
  businessFootage: 'Hacks to make the most of your business videos',
  // Details pages
  audioDetail: 'Audio Detail',
  imageDetail: 'Image Detail',
  footageDetail: 'Video Detayı',
  footageProperties: 'Video özellikleri',
  audioProperties: 'Müzik özellikleri',
  modalDownloadTitle: 'Dosya indirme seçenekleri',
  credit: 'Kredi',
  saveAs: 'Farklı kaydet',
  ownLicense: 'Normal indirme (kendi lisansı)',
  downloadAs: 'Farklı indir',
  insufficientCredits: 'Yetersiz Kredi',
  custom: 'Özel',
  personalAndCom: 'Kişisel ve Ticari Kullanım, Reklamlar, sunumlar, sosyal medya, web.',
  licenseType: 'Lisans türü',
  requiredForResale:
    'Teatral performans, ücretli erişim, video akışı, oyunlar ve uygulamalarda yeniden satış veya dağıtım için gereklidir.',
  stockAudioKeywords: 'Stok müzik anahtar kelimeleri',
  stockFootageKeywords: 'Stok video anahtar kelimeleri',
  stockPhotoKeywords: 'Stock photo keywords',
  limitedLicenseSizes: 'Limited License Sizes',
  stereo: 'Stereo',
  fileSize: 'Dosya Boyutları',
  footageId: 'Video ID',
  audioId: 'Audio ID',
  imageId: 'Image ID',
  // Search audio
  searchAudio: 'Ses (Müzik & Ses Efektleri )',
  StockAudio: 'Stok müzik',
  similarTracks: 'Similar Tracks',
  viewAlbum: 'View Album',
  copyLink: 'Bağlantıyı kopyala',
  preview: 'Önizleme',

  bestSellingFootage: 'En çok satan videolara göz atın',
  featuresAudio: 'Featured audio',
  readmore: 'Read More',
  // justified layout
  similarImage: 'Benze Görseller',
  addToLikebox: 'Add to Likebox',

  // recently view empty data
  rVPhoto: 'En son görüntülediğiniz görseller burada gösterilecek',
  rVVectors: 'En son görüntülediğiniz vektörler burada gösterilecek',
  rVFootage: 'En son görüntülediğiniz videolar burada gösterilecek',
  rVAudio: 'En son görüntülediğiniz müzikler burada gösterilecek',
  // recently likes empty data
  rLPhoto: 'Your recently liked images will show here',
  rLVectors: 'Your recently liked vectors will show here',
  rLFootage: 'Your recently liked video will show here',
  rLAudio: 'Your recently liked audio will show here',
  // Content Unavailable
  contentUnavailable: 'Content Unavailable',
  contentError: 'We’re sorry, there’s some error displaying this content',
  emptySearchResultTitle: 'Hoop, Bu filtrelerle aramanız hiçbir sonuç vermedi.',
  emptySearchResultTitleWithoutFilter: 'Oops, your search did not return any results.',
  emptySearchResultSubTitle:
    'Bazı filtreleri kaldırmayı veya farklı bir anahtar kelime kullanmayı deneyin.',
  emptyReverseSearchTitle: 'Hiçbir Sonuç Bulunamadı!!',
  emptyReverseSearchSubTitle1:
    'Yüklenen görsel hatalı. Aşağıdaki kriterlere uygun olduğundan emin olun',
  emptyReverseSearchSubTitle2: 'Check your refine search with keyword',
  emptyReverseSearchSubTitle3: 'Görsel JPG / PNG olmalıdır',
  emptyReverseSearchSubTitle4: "Görüntü boyutu 5 MB'dan az olmalıdır",
  emptySearchResultClearFilter: 'Tüm filtreleri temizle',
  emptyDownloadHistoryResult: 'Your download history will show here',
  // Please enter a keyword
  keywordEmpty: 'Lütfen bir anahtar kelime girin',
  // admin redirect to
  plansAndPricing: 'Plans and Pricing ',
  // Footer langding page
  nameUnavailable: 'Name unavailable',
  addressUnavailable: 'Company address unavailable',
  phoneUnavailable: 'Phone number unavailable',
  faxUnavailable: 'Fax unavailable',
  iddUnavailable: 'IDD unavailable',
  emailUnavailable: 'Email unavailable',
  contentTermLicense1:
    'This Agreement shall be governed by the laws of Singapore, without regard to the conflict of laws principles. ',
  contentTermLicense2:
    'the parties agree that the dispute shall be settled by arbitration in Singapore under the Singapore International Arbitration Centre (“SIAC”) administered SIAC Arbitration Rules in force when the notice of arbitration is submitted in accordance with these rules. The number of arbitrators shall be one (1). The language of the arbitration shall be English.',
  contentTermLicense3:
    'that damages and remedies under Singapore Law for any breach of any such covenant would be inadequate. You further acknowledge and agree that the arbitral tribunal may order any interim measure it deems necessary or proper in accordance with the applicable laws of Singapore,',
  contentTermUsLicense1:
    'This Agreement shall be governed by the laws of the state of Delaware, United States of America, without regard to the conflict of laws principles',
  contentTermLicenseUs2:
    ' the parties agree first to try in good faith to settle the dispute by mediation administered by the American Arbitration Association under its Commercial Mediation Procedures before resorting to arbitration, litigations or some other dispute resolution procedure. In the event if the dispute is settled via arbitration, the number of arbitrators shall be one (1) and the language of the arbitration shall be English.',
  contentTermUsLisence3:
    ' that damages and remedies under applicable law for any breach of any such covenant would be inadequate. You further acknowledge and agree that the arbitral tribunal may order any interim measure it deems necessary or proper in accordance with the applicable law,',
  contentCorporateUs:
    'This Agreement shall be governed by the laws of the State of Delaware in the United States of America, without regard to the conflict of laws principles. All disputes arising out of or in connection with the performance of this Agreement shall be settled through negotiations and if the dispute cannot be settled through negotiation, the parties agree first to try in good faith to settle the dispute by mediation administered by the American Arbitration under its Commercial Mediation Procedures before resorting to arbitration, litigation, or some other dispute resolution procedure. In the event if the dispute is settled via arbitration, the number of arbitrators shall be one (1), the language of the arbitration shall be English and the arbitral award shall be final and binding upon parties.',
  contentCorporate:
    'This Agreement shall be governed by, and shall be construed in accordance with, the laws of Singapore. All disputes arising out of or in connection with the performance of this Agreement shall be settled through friendly negotiations. If the parties are unable to resolve any such dispute within thirty (30) days after the commencement of negotiations, the parties agree that the dispute shall be settled by arbitration in Singapore under the Singapore International Arbitration Centre (“<b>SIAC</b>”) administered by SIAC Arbitration Rules in force when the notice of arbitration is submitted in accordance with the rules. The number of arbitrators shall be one (1). The decision of the arbitrator shall, except in the case of manifest error, be final and binding upon the parties hereto. The arbitration proceedings shall be conducted in English.',
  agreeLicense: 'I AGREE to abide by all the Terms and Conditions above',
  btnAgree: 'AGREE',
  btnDecline: 'DECLINE',
  messageCheckbox:
    'Please read the License Agreement above carefully and signify your agreement by clicking the "I AGREE" check box.',
  // collection search result page
  searchCollection: 'Koleksiyon Ara',
  personal: 'Kişisel',
  groups: 'Gruplar',
  createNew: 'Yeni Oluştur',
  createNewCollection: 'Yeni bir koleksiyon oluştur',
  descriptionPlaceholder: 'Add an optional description for your collection here.',
  titleItem: 'BAŞLIK',
  selectGroup: 'Grup seç',
  description: 'Açıklama',
  charactersRemaining: '180 karakter kaldı',
  cancel: 'İptal Et',
  create: 'Oluştur',
  or: 'YA DA',
  defaultCollection: 'Varsayılan Koleksiyon',
  addToMyCollections: 'Görsel Sepetine Ekle',
  addedToCollection: 'Koleksiyona eklendi',
  removedFromCollection: 'Koleksiyona eklendi',
  collectionModalPlaceholder: 'Örn. Doğa',
  groupNameShowsHere: 'Grup Adı Burada Görünüyor',
  // like box
  btnViewCollection: 'View Collection',
  shareWithMe: 'BENİMLE PAYLAŞILAN',
  addLikebox: 'Add New Likebox',
  notImageLikeBox: 'You do not have any images in your likebox yet',
  owner: 'Sahibi',
  images: 'Görsel',
  likeboxName: 'Likebox Name',
  likboxNameMaxLength50: 'The maximum characters allowed is 50',
  addLikeBoxTo: 'Add likebox to',
  personalLikebox: 'Kişisel',
  btAdd: 'ADD',
  btCancel: 'CANCEL',
  btSave: 'SAVE',
  editLikebox: 'Edit Likebox',
  share: 'Paylaş',
  // reset password
  resetPwdTitle2: 'Reset Password',
  noFoundLikebox: 'Hiç koleksiyonunuz yok.',
  btnUnDo: 'Geri al',
  // my collection
  dateAdded: 'Eklenen tarih',
  collectionName: 'Koleksiyon adı',
  selectAll: 'Tümünü Seç',
  delete: 'Sil',
  seeAll: 'Hepsini Gör',
  deleteCollection: 'Koleksiyonu Sil',
  deleteWarning:
    'Bu işlemin tamamlanması, Koleksiyonlarınızın içerikleriyle birlikte kalıcı olarak silinmesine neden olacaktır. Bu işlemin geri alınamayacağını unutmayın.',
  createCollection: ' oluşturuldu',
  shareCollection: 'Koleksiyonu Paylaş',
  shareWithTeam: 'Share in team',
  selectUsers: 'Kullanıcıları seçin',
  searchUserName: 'kullanıcı adı ara',
  copy: 'Kopyala',
  titleCopyModal: 'Seçilen Öğeleri şuraya kopyala:',
  collectionUppercase: 'KOLEKSİYON',
  groupCollection: 'Grup',
  moveImage: 'Seçilen Öğeleri Şuraya Taşı',
  defaultSelect: 'Koleksiyon Adı Burada',
  moveLikebox: 'Move',
  messsageDeleteLikebox:
    'Bu işlemin tamamlanması, Koleksiyonlarınızın içerikleriyle birlikte kalıcı olarak silinmesine neden olacaktır. Bu işlemin geri alınamayacağını unutmayın.',
  areYouSure: 'Emin misin?',
  editCollection: 'Koleksiyonu Düzenle',
  collectionNameUpper: 'Koleksiyon adı',
  deleteSuccessful: 'has been successfully deleted',
  edit: 'Düzenle',
  messageRemoveImage: 'Seçili öğeleri koleksiyondan kaldırmak istediğinizden emin misiniz?',
  titleRemove: 'Seçili Öğeyi Kaldır',
  popupActionMove: ' item(s) has been moved to ',
  popupActionCopy: ' item(s)has been copied to ',
  popupActionRemove: ' item(s) has been removed from this Collection',
  popupFailed: 'Image already exists',
  popupShared: 'Your collection has been successfully shared',
  popupActionDelete: 'Your collection has been successfully removed',
  popupActionEdit: 'Your edit has been successfully updated',
  btnSave: 'Kaydet',
  shareLikebox: 'Benimle Paylaşıldı',
  remove: 'Kaldır',
  moveTo: 'Şuraya Taşı',
  createNewPlaceholder: 'Örn. Doğa',
  shareViaEmail: 'E-posta ile Paylaş',
  sharePublicly: 'Share publicly',
  receipnetEmail: 'Alıcının E-postası',
  message: 'Mesaj',
  insertReceipmentEmail: 'Alıcı e-postası ekle',
  messageText: 'Mesaj metni.',
  exportCollection: 'Koleksiyonu Dışarı Çıkar',
  pdf: 'PDF',
  csv: 'CSV',
  addNewCollection: 'Yeni Koleksiyon Ekle',
  likeboxEditSuccessful: 'You have successfully made changes to your collection.',
  format: 'Biçim',
  lowCredit: 'Düşük Kredi',
  downloadSuccess: 'Download için teşekkür ederiz. Yükleme kısa bir süre içinde başlayacaktır.',
  downloadFailed: 'İndirme Hatası: Hata oluştu, lütfen tekrar deniyiniz.',
  downloadedLabel: 'İndirilen',
  any: 'Herhangi bir',
  resetFilter: 'Filtreyi sıfırla',
  enterCustomFieldTag: 'Enter custom field tags',
  // Portfolio
  follow: 'Takip et',
  name: 'Ad',
  location: 'Konum',
  since: 'Bu yana',

  // Hesabım
  updated: 'Update',
  userName: 'KULLANICI ADI',
  firstName: 'Adı',
  lastName: 'Soyadı',
  myProfile: 'My Profile',
  changeProfiledetail: 'Profil ayrıntılarınızı buradan değiştirin',
  userPrivileges: 'User Privileges',
  viewAllDownload: 'View all download history',
  viewDownloadCost: 'View download cost',
  accountManagerInfo: 'Account manager info',
  describeAccountmanager: 'Describe what this account manager do',
  creditsPlan: 'Credits plan',
  myPlan: 'Paketlerim',
  myOrganisation: 'My organisation plan',
  myAccountProfileDetails:
    'Bu, kuruluş yöneticisi tarafından belirlenen profil bilgilerinizdir. Kimlik bilgilerinizi değiştirmek isterseniz, lütfen yönetici ile iletişime geçin.',
  usePayLater: 'Şimdi Kullan, Sonra Öde',
  bodyUNPL:
    'Şimdi Kullan, Sonra Öde (UNPL), tüketicilerin satın alma yapmalarına ve gelecekteki bir tarihte genellikle faizsiz olarak ödeme yapmalarına olanak tanıyan bir kısa vadeli finansman türüdür. Ayrıca "satış noktası taksitli krediler" olarak da anılır.',
  firstnameCannotEmpty: 'First name cannot be empty.',
  lastnameCannotEmpty: 'Lastname cannot be empty.',

  noSpecialCharacters: 'This field cannot contain special characters',
  messageUpdateProfile: 'You had successfully updated the user information',
  bodyCredit:
    'Vitae morbi tristique luctus sed eget donec nibh in duis neque donec lectus turpis turpis aliquam facilisis mattis laoreet enim',
  contactYourAccount: 'Contact your account manager for further assistane',
  emailMaxLength75: 'The maximum characters allowed is 75',

  // audio player
  categoryGenre: 'Kategori / Tür',
  duration: 'Süre',
  seeMore: 'Daha Fazla Göster',
  seeLess: 'Daha Az Göster',
  audioID: 'Audio Kodu',
  // category
  landscape: 'Manzara',
  Babies: 'Bebekler',
  business: 'İş Dünyası',
  food: 'Yiyecek',
  animals: 'Hayvanlar',
  technology: 'Teknoloji',
  healthcare: 'Sağlık',
  nature: 'Doğa',
  romance: 'Romantizm',
  // category vector
  graphic: 'Grafik',
  advertising: 'Reklâm',
  flower: 'Çiçek',
  abstract: 'Soyut',
  Illustration: 'İllüstrasyon',
  pop: 'Kuş',
  bird: 'Piksel',
  pixel: 'Пиксель',
  threeD: '3 boyutlu',

  // footage category
  education: 'Eğitim',
  communication: 'Iletişim',
  beauty: 'Güzellik',
  concept: 'Kavram',
  fitness: 'Uygunluk',
  lifestyle: 'Yaşam Tarzı',
  celebration: 'Kutlama',
  family: 'Aile',
  fashion: 'Moda',

  // Download-history modal
  reDownload: 'Yeniden indir',
  chooseCustomTagOrCreate: 'Özel etiket(ler) seçin veya yeni bir tane oluşturun',
  downloadDetails: 'Ayrıntıları İndir',
  image: 'Image',
  downloadType: 'İndirme Türü',
  groupName: 'Grup adı',
  size: 'Boyut',
  dateDownloaded: 'İndirme Tarihi',
  reLicense: 'Yeniden Lisansla',
  customFields: 'Özel Alanlar',
  saveAndClose: 'Kaydet ve kapat',
  characterMaxChar100: 'The maximum characters allowed is 100',
  characterNotAllow:
    'The maximum characters allowed is 100 and the minimum characters allowed is 1',
  dlwByMember: 'Dowloaded By Members',
  groupInactive: 'Group inactive',
  personalInactive: 'Personal inactive',
  date: 'Tarih',
  export: 'Exporte',
  selectRange: 'select range',
  fileType: 'file type',
  downloadedLabelUNPL: 'Lisanslanmış',
  HRUCTab: 'Ücretsiz İçerik',
  searchById: 'Kimliğe göre ara',
  pleaseSelect: 'Lütfen Seçin',
  totalOutstanding: 'Toplam Ödemeler',
  viewInvoices: 'Faturayı Görüntüle',
  // Image detail page - select download size sidebar
  standardSizes: 'Standart ölçüler',
  extendedLicenseSizes: 'Extended License Sizes',
  crop: 'Kırp',
  // empty data
  opsNothingToSeeHere: 'Ops! Burada görülecek bir şey yok.',
  // search image
  authentic: 'Otantik',
  // search audio
  mostDownloaded: 'En çok indirilenler',
  download: 'İndir',
  // audio category
  soulAndRB: 'Soul & R&B',
  corporate: 'Ticari',
  electronica: 'Elektronik Müzik',
  classical: 'Klasik Müzik',
  funkAndGrooveMusic: 'Funk & Groove müzik',
  worldBeat: 'World Beat',
  cinematic: 'Sinematik',
  jazz: 'Jazz',
  flamenco: 'Flamenco',
  mothersDayLove: 'Mothers Day Love',
  happyUplifting: 'Happy Uplifting',
  epicHeroAdventure: 'Epic Hero Adventure',

  // search page
  nextPage: 'Sonraki Sayfa',
  reverseImageSearch: 'Zıt Görsel Arama',

  group: 'Grup',
  needHelp: 'Yardıma mı ihtiyacınız var?',
  copied: 'Kopyalandı!',
  copyPortfolioLinkSuccess: 'Katkıda bulunan portföy bağlantısı panoya kopyalandı.',

  // my activities
  myActivity: 'Hareketlerim',
  recentLike: 'Son Beğendiklerim',
  recentView: 'Son Görüntülediklerim',
  myActivityViewDesc: 'En son görüntüleme geçmişi yalnızca 3 aya kadar saklar',
  myActivityLikeDesc: 'Yalnızca son 3 aydaki geçmişi gösterir',

  // dashboard
  profile: 'Profil',

  // Pricing
  pricingCreditText: 'Kredit',

  // Dashboard - Credit Usage
  user: 'User',
  creditUsage: 'Credit usage',
  searchPlaceholder: 'Search name, username, role, group',
  all: 'Tümü',
  active: 'Active',
  deleted: 'Deleted',
  reset: 'Sıfırla',
  totalAllocated: 'Total allocated',
  totalSpent: 'Total spent',
  balance: 'Balance',
  groupLowerCase: 'group',
  roleDefinitions: 'Role definitions',
  superAdmin: 'Super admin',
  admin: 'Admin',
  historySearchPlaceholder: 'Search history',
  somethingWentWrong: 'Something went wrong',
  information: 'Information',
  maximumBulkDownloadWarning: 'Bulk download allows maximum 50 items',
  tooltipCustomLogin: 'Please use at least 8 characters',
  maxLengthTitleCustom: 'Title should not be more than 25 characters',
  maxLengthDescCustom: 'Description should not be more than 50 characters',
  selectdItems: 'Selected media',
  aiPoweredSearch: 'Yapay Zeka Destekli Arama',
  aiPoweredSearchOn: 'Yapay Zeka Destekli Arama Başladı',
  aiSearchIntroduction:
    'Daha hızlı ve daha güçlü arama motorumuzu deneyin. Yapay Zeka Aramamız gerisini halledecek!',
  ok: 'TAMAM',
  // Filter bar ai

  bstract: 'Soyut',
  macroCloseUp: 'Makro/Yakın çekim',
  object: 'Nesne',
  watercolor: 'Suluboya',
  flat: 'Düz',
  cartoon: 'Karikatür',
  geometric: 'Geometrik',
  gradient: 'Gradyan',
  isometric: 'İzometrik',
  '3d': '3d',
  handDrawn: 'Elle çizilmiş',
  natural: 'Doğal',
  warm: 'Sıcak',
  dramatic: 'Dramatik',
  vividAndBold: 'Canlı ve cesur',
  blackAndWhite: 'Siyah ve beyaz',
  // introduce Free image research
  imageResearchContentTitle:
    "123RF'nin ücretsiz görsel araştırma hizmetiyle tanışın: Mükemmel görseller, Sıfır zorluk",
  imageResearchContentDesc:
    'Kurumsal müşteriler için görsel araştırmada devrim yaratarak aradığınız en iyi görseli bulup vizyonunuzu hayata geçirmeyi kolay hale getiriyoruz.',
  submitRequest: 'Talep gönder',
  imageResearchContentInfoTitle1: 'Görüntü araştırması artık daha kolay',
  imageResearchContentInfoDesc1:
    'Başlamak çok kolay. Bize stil, ruh hali, renkler ve belirli unsurlar dahil olmak üzere istediğiniz görüntünün ayrıntılı bir açıklamasını vermeniz yeterlidir. Ne kadar çok ayrıntı verirseniz, ihtiyaçlarınızı o kadar iyi karşılayabiliriz.',
  imageResearchContentInfoTitle2: 'Nasıl çalışıyor?',
  imageResearchContentInfoTitle3: 'Hızlı, güvenilir hizmet',
  imageResearchContentInfoTitle4: 'İzin verin işi sizin için biz yapalım',
  imageResearchContentInfoDesc4:
    'Sizin için mükemmel görselleri buluyoruz, böylece siz projenize odaklanabiliyorsunuz. Vizyonunuzu yüksek kaliteli, özenle seçilmiş görsellerle hayata geçirmek için bize güvenin.',
  requestForm: 'Talep formu',
  whatDoYouWantThisImageFor: 'Bu görseli ne için kullanmak istiyorsunuz?',
  websiteBanner: "Web sitesi banner'ı",
  socialMedia: 'Sosyal medya',
  blogPost: 'Blog yazısı',
  advertisement: 'Reklam',
  print: 'Yazdır',
  others: 'Diğerleri',
  othersOutputPlaceholder: 'Diğer çıktı türlerini girin',
  whatIsPrimaryMessage: 'Görselin iletmesini istediğiniz birincil mesaj veya tema nedir?',
  whatIsPrimaryMessagePlaceholder: 'Örn. Parkta kadınla koşan kürklü köpek',
  areThereSubjectYouWantInThePicture:
    'Fotoğrafta yer almasını istediğiniz belirli konular veya unsurlar var mı?',
  egPeopleNatureTechnologyAbstract: 'Örneğin insanlar, doğa, teknoloji, soyut kavramlar',
  areThereImageOrStyleReference:
    'Daha önce gördüğünüz, esinlenmek istediğiniz veya referans olarak kullanmamızı istediğiniz herhangi bir görsel veya stil var mı?',
  ifPeopleIncludeWhatTheirDemographic:
    'Eğer insanlar dahil edilecekse, demografik özellikleri ne olmalıdır?',
  egAgeGenderEthnicityOccupation: 'Örneğin yaş, cinsiyet, etnik köken, meslek',
  areThereAnyThingToAvoid:
    'Görselde kaçınmak istediğiniz herhangi bir unsur, konu veya tema var mı?',
  whoisYourTargetAudience: 'Hedef kitleniz kim?',
  whoisYourTargetAudiencePlaceholder: 'Örn. Okumayı seven genç yetişkinler',
  typeOfImage: 'Görüntü türü',
  whatMoodShouldImageEvoke: 'Görüntü hangi ruh halini veya duyguyu çağrıştırmalı?',
  happy: 'Mutlu',
  sad: 'Üzgün',
  calm: 'Sakin',
  excited: 'Heyecanlı',
  angry: 'Öfkeli',
  enterCustomMood: 'Özel ruh halini girin',
  areThereAnyOrientationForImage: 'Görüntü için gereken herhangi bir özel yönlendirme var mı?',
  enterCustomOrientation: 'Özel yönü girin',
  isThereLocationYouWantImageToBeIn:
    'Görüntünün olmasını istediğiniz belirli bir yer veya ortam var mı?',
  no: 'Hayır',
  yes: 'Evet',
  shareWithUsAReference: 'Bizimle bir referans paylaşın (yalnızca JPG veya PDF. En fazla 4 MB)',
  replace: 'Değiştirin',
  areThereAnySpecificRequirements: 'Başka özel gereksinimleriniz veya tercihleriniz var mı?',
  enterOtherComments: 'Diğer yorumları girin',
  submit: 'Gönder',
  fieldCannotEmpty: 'Bu alan boş bırakılamaz',
  selectAtLeastOne: 'Lütfen en az birini seçin',
  fileMustBeJpgOrPdf: 'Dosya JPG veya PDF olmalıdır',
  fileSizeLessThan4MB: "Dosya boyutu 4 MB'tan küçük olmalıdır",
  maximumFileNameAllow255: "İzin verilen maksimum karakter sayısı 255'tir",
  updateSuccessfully: 'Talebinizi aldık.',
  freeImageResearch: 'Ücretsiz Görsel Araştırma',
  newService: 'Yeni hizmet!',
  freeImageResearchIntroduction:
    'Görsel araştırma hizmetimizi deneyin ve görsellerimizin neden gereksinimlerinize mükemmel şekilde uyduğunu görün.',
  later: 'Daha sonra',
  letTryIt: 'Hadi deneyelim!',
  viewPdfOfSampleOutput: "Örnek çıktının PDF'ini görüntüleyin",
  imageResearchContentInfoDesc2: (
    <>
      Ekibimiz sizin için ideal görselleri bulmak üzere yapay
      {' '}
      <strong>zeka teknolojisi</strong>
      {' '}
      ve
      deneyimini bir araya getiriyor ve gelişmiş algoritmalar girdilerinize göre geniş
      kütüphanemizdeki seçenekleri daraltıyor. Kendini işine adamış ve
      {' '}
      <strong>deneyimli araştırmacılardan</strong>
      {' '}
      oluşan ekibimiz, sonuçları kriterlerinize
      mükemmel şekilde uyacak şekilde iyileştirir.
    </>
  ),
  imageResearchContentInfoDesc3: (
    <>
      Sonuçları
      {' '}
      <strong>2 iş günü</strong>
      {' '}
      içinde teslim ediyor ve görüntü seçimleri için kapsamlı
      bir açıklama sunuyoruz. Biz sizin için çalışırken siz işinize odaklanın.
    </>
  ),
  characterMaxChar255: "İzin verilen maksimum karakter sayısı 255'tir",

  imageResearchContentInfoDesc2Normal1: 'Our team combines',
  imageResearchContentInfoDesc2Strong1: 'AI technology',
  imageResearchContentInfoDesc2Normal2:
    'and experience to find ideal images for you and advanced algorithms narrow down options from our vast library based on your input. Our',
  imageResearchContentInfoDesc2Strong2: 'team of dedicated and experienced researchers',
  imageResearchContentInfoDesc2Normal3: 'refine the results to meet your criteria perfectly.',
  imageResearchContentInfoDesc3Normal1: 'We deliver results within',
  imageResearchContentInfoDesc3Strong1: '1 working day',
  imageResearchContentInfoDesc3Normal2:
    'providing a comprehensive explanation for image selections. Focus on your business while we take care of the rest.',
  office: 'Office',
  beach: 'Beach',
  city: 'City',
  mountains: 'Mountains',
  enterCustomLocation: 'Enter custom location',
  doYouNeedImgWithSpaceForTextOrOtherElement:
    'Do you need an image with space for text or other elements? If so, where should this space be located?',
  center: 'Center',
  topLeft: 'Top left',
  topRight: 'Top Right',
  bottomLeft: 'Bottom Left',
  bottomRight: 'Bottom Right',
  characterMin30: 'A minimum of 30 characters is required',
  required: 'gereklidir',

  relatedSearch: 'Related searches',
  showMore: 'Daha Fazla Göster',
  showLess: 'Daha azını göster',

  requiredWithVariable: "'{{var}}' gereklidir",
  mood: 'Ruh hali',
  person: 'Kişi',
  doNotIncludeWords_ex2: 'Örn. yeşil renk, düz saç',

  // Text to Image TTI page
  /// Landing page
  aiImageGenerator: 'AI Görüntü Oluşturucu',
  aiImageGeneratorDesc: 'Hayal gücünüzün hızında yaratıcılık sunar.',
  generate: 'Oluştur',
  exploreVisualsCreatedWithThePowerOfAI: 'Yapay zekanın gücüyle oluşturulan görselleri keşfedin',
  turnYourImaginationIntoRealityIn3SimpleSteps: 'Hayal gücünüzü 3 basit adımda gerçeğe dönüştürün',
  startWithGoodTextPrompt: (
    <>
      İyi bir metin
      {' '}
      <br />
      <span>istemi ile başlayın</span>
    </>
  ),
  startWithGoodTextPromptDesc:
    'İyi bir ipucu harika sonuçları garanti eder. En iyi sonuçların elde edilebilmesi için bir ipucu perspektif, konu, eylem, anahtar kelimeler, ışık ve stil içermelidir.',
  reviewYourAIGeneratedImages: (
    <>
      Yapay zeka ile oluşturulmuş
      {' '}
      <br />
      <span>
        görüntülerinizi
        <br />
        {' '}
        gözden geçirin
      </span>
    </>
  ),
  reviewYourAIGeneratedImagesDesc:
    'Yapay zeka oluşturucumuz, isteminize en yakın 3 önizleme görüntüsü oluşturacak ve gösterecektir.',
  downloadAndLicenseVisuals: (
    <>
      Görselleri indirin
      {' '}
      <br />
      <span>ve lisanslayın</span>
    </>
  ),
  downloadAndLicenseVisualsDesc:
    'Artık bu görselleri kişisel ve ticari kullanım için 25.000 $ yasal teminatla indirebilir ve lisanslayabilirsiniz.',
  needSomeIdeas:
    'Fikre mi ihtiyacınız var? Bu örneklerden başlayın ve fikirlerinizi yeniden oluşturun!',
  tryPrompt: 'İstemi deneyin',
  creativityAtTheSpeedOfYourImagination: 'Hayal gücünüzün hızında yaratıcılık',
  genYourOwnAIImgFromTxtPromptsWCorpInSeconds:
    'Corporate+ ile metin komutlarından saniyeler içinde kendi yapay zeka görsellerinizi oluşturun!',
  tryNow: 'Şimdi dene',

  /// Random prompt
  prompt1:
    'Arka planda Dünya ile uzayda süzülen bir astronotun foto-gerçekçi portresi. Arka planı bulanıklaştırmak ve özneyi izole etmek için F 1.2 diyafram ayarında 200 mm lensli bir fotoğraf makinesi kullanın. Işıklandırma, astronotun yüzünde ve uzay giysisinde parlayan güneş ile dramatik ve rüya gibi olmalıdır. Uzayın enginliğini ve güzelliğini yakalayan ultra gerçekçi bir görüntü oluşturmak için fotogerçekçilik modunu açın.',
  prompt2:
    'Dramatik bir pozda, akan pelerini ve sihir kıvılcımları saçan asasıyla bir büyücünün yer aldığı, mistik ve uhrevi bir manzaraya karşı yerleştirilmiş, çeşitli doku ve malzemelerin kullanıldığı bir karışık medya eseri',
  prompt3:
    'Neşeli ve pastel renkli bir arka plan üzerinde, renkli balonlar ve konfetilerle çevrili, pembe yanaklı ve kabarık tulumlu bir bebeğin eğlenceli dijital illüstrasyonu',
  prompt4:
    'Alnında üçüncü bir gözü olan, dingin ve mistik bir ifadeyle uzaklara bakan kör bir kahinin karmaşık detaylar ve gölgelendirmeler içeren grafit çizimi',
  prompt5:
    'Çölün çorak arazisinde dev bir mekanik boğaya binen bir kovboyun, eski dergiler, eski haritalar ve paslı metal parçalarından oluşan, mizah ve absürd dokunuşlar içeren sürrealist bir karışık medya kolajı',
  prompt6:
    'Soluk tenli ve kan kırmızısı gözlü, dökümlü bir elbise giyen ve etrafı yarasalarla çevrili bir vampir kraliçenin dijital illüstrasyonu.',
  prompt7:
    'Elinde kristal bir küre tutan kör bir kahinin portresi, kahinin yüz hatlarını ve kristalin yansımalarını vurgulamak için dramatik chiaroscuro aydınlatma ile çekilmiştir.',
  prompt8:
    'Biyolüminesan yaratıklarla dolu mistik bir okyanusta yüzen, uzun saçlı ve deniz kabuklarından tacı olan bir denizkızı kraliçesinin fantastik illüstrasyonu.',
  prompt9:
    'Meyve ve sebze satan Hispanik bir sokak satıcısının, satıcının yüz ifadesini vurgulamak için sığ bir alan derinliği ile samimi ve cesur siyah beyaz çekilmiş bir sokak portresi.',
  prompt10:
    'Kayalık bir sahilde uzanmış, etrafı pırıl pırıl okyanus dalgaları ve oyuncu deniz canlılarıyla çevrili bir denizkızının tuhaf suluboya resmi',
  prompt11:
    '3d tarzı küçük kız, 4k, 8k, oktan render fotogerçekçi, hdr, fotoğrafçılık, yüksek çözünürlüklü, simetrik yüz, hacimsel aydınlatma, tozlu pus, fotoğraf, oktan render, 24mm, 4k, 24mm, DSLR, yüksek kalite, 60 fps, ultra gerçekçi',
  prompt12:
    'Bahçeye açılan Fransız pencereli oturma odası, Provençal tarzı büfe, bitkiler, jüt halı, parke, üstü minderli kanepeler, kristal damla avize, iç tasarım dergisi, sıcak atmosfer, fotogerçekçi, gerçekçi ışık, geniş açı, birçok detay',
  prompt13:
    'Dağ yamacında pitoresk dağ kulübesi, geniş panoramik pencereler, geometrik kulübe, tam görünüm, alacakaranlıkta doğal manzara, dramatik alacakaranlık gökyüzü, kar altında zirveler, yıldızlar, karlı dağlık vahşi doğa, güzel manzara, karamsar, sis, hacimsel aydınlatma, dağ atmosferi, fotogerçekçilik, hacimsel aydınlatma, gerçekçi, oktan render, ultra detaylı, gerçeküstü, fotogerçekçi, ultra gerçekçi, 8k, sinematik aydınlatma',
  prompt14: 'Havalı bir büyükannenin suluboya resmi, gülümsüyor çünkü havalı olduğunu biliyor',
  prompt15:
    'Masmavi bir ormanın ortasında yol, sık orman, yaprak tavanı, gece, çizgi roman tarzı, çocuk illüstrasyonu',
  prompt16:
    'Uzayda bir nebula içinde yüzen bir astronot + uzay, astronot, rüya gibi, sembolizm, sürrealizm, sembol, gerçeküstü, soyut, doku, konsept sanat, 8k, gölgeli, ışık ışınları, atmosferik',

  /// Tool list
  // ratio
  ratio: 'Oran',
  square1_1: 'Kare (1:1)',
  square: 'Kare',
  portrait2_3: 'Portre (2:3)',
  portrait: 'Portre',
  landscape3_2: 'Yatay (3:2)',
  wide16_9: 'Geniş (16:9)',
  wide: 'Geniş',
  // style
  none: 'Hiçbiri',
  enhance: 'Geliştirmek',
  anime: 'Anime',
  photographic: 'Fotoğrafik',
  digitalArt: 'Dijital sanat',
  comicBook: 'Çizgi roman',
  fantasyArt: 'Fantezi sanatı',
  analogFilm: 'Analog film',
  neonPunk: 'Neon punk',
  lowPoly: 'Düşük Poli',
  origami: 'Origami',
  lineArt: 'Çizgi sanatı',
  tileTexture: 'Karo dokusu',
  threeDModel: '3 boyutlu model',
  pixelArt: 'Piksel sanatı',
  craftClay: 'Toprak Sanatı',
  // color
  coolTone: 'Soğuk ton',
  mutedColors: 'Sessiz renkler',
  pastelColors: 'Pastel renkler',
  vibrantColors: 'Canlı renkler',
  warmTone: 'Sıcak ton',
  // lighting
  lighting: 'Aydınlatma',
  backlight: 'Arka ışık',
  crepuscularRays: 'Crepuscular ışınlar',
  dimlyLit: 'Loş ışık',
  goldenHour: 'Altın saat',
  lowLight: 'Düşük ışık',
  rimLighting: 'Jant aydınlatması',
  studio: 'Stüdyo',
  sunlight: 'Güneş ışığı',
  volumetric: 'Volumetrik',
  // composition
  composition: 'Kompozisyon',
  blurryBackground: 'Bulanık arka plan',
  closeUp: 'Yakın çekim',
  macrophotograph: 'Makrofotoğrafçılık',
  narrowDepth: 'Dar derinlik',
  shotFromAbove: 'Yukarıdan çekim',
  shotFromBelow: 'Aşağıdan çekim',
  wideAngle: 'Geniş açı',

  /// FQAs
  allAIImageQuestionsAnswered: 'Yapay zeka tarafından üretilen tüm görüntü sorularınız yanıtlandı.',
  TTI_Q1: 'Yapay zeka tarafından üretilen görüntüler nedir?',
  TTI_A1:
    'Yapay zeka tarafından üretilen görüntüler, yapay zeka (AI) algoritmaları kullanılarak oluşturulan görüntülerdir. Bu algoritmalar insan yaratıcılığını taklit etmek üzere tasarlanmıştır ve insan sanatçılar tarafından üretilenlere benzer, ancak benzersiz ve genellikle gerçeküstü veya soyut unsurlar içeren görüntüler üretebilir.',
  TTI_Q2: 'Günde kaç görsel üretebileceğime dair bir sınır var mı?',
  TTI_A2:
    'Evet, günlük olarak oluşturabileceğiniz görüntü sayısında bir sınır vardır. Bu sınır, tüm kullanıcıların Yapay Zeka Görsel  oluşturma hizmetine erişirken adil bir erişime ve iyi bir kullanıcı deneyimine sahip olmasını sağlar.',
  TTI_Q3: 'Do you support text prompts in languages other than English?',
  TTI_A3: 'We support only the English language at the moment.',
  TTI_Q4: 'Bir görüntüyü oluşturmak ve lisanslamak arasındaki fark nedir?',
  TTI_A4:
    'Bir görsel oluşturduğunuzda, görüntülemek için bir dizi Yapay Zeka görseli oluşturursunuz. Bunları Corporate+ üzerinde lisansladığınızda, Yapay Zeka görüntüsünün kendi kullanım lisansıyla birlikte gelen yüksek çözünürlüklü bir sürümünü indirmek için resmi olarak erişim izni almış olursunuz.',
  TTI_Q5:
    'Oluşturduğum bir görsel tanınabilir bir ticari marka, simge yapı veya kamuya mal olmuş bir kişilik içeriyor. Yine de kullanabilir miyim?',
  TTI_A5:
    'Tanınabilir bir kişi veya nesne içeriyorsa ya da öncelikli olarak ayırt edici bir dönüm noktasına odaklanıyorsa, özellikle de görseli ticari bir ortamda kullanmayı planlıyorsanız, doğrudan görselde tasvir edilen taraftan izin almanız gerekir.',
  TTI_Q6: 'Oluşturulan görüntülerde görünen kişiler kimlerdir?',
  TTI_A6:
    'Yapay zeka tarafından üretilen görüntülerde görünen insanlar gerçek insanlar değil, dijital yaratımlardır.',
  TTI_Q7: 'Ürettiğim içeriğe ne olacak? Diğer kullanıcıların kullanımına sunulacak mı?',
  TTI_A7:
    'Oluşturduğunuz içerik diğer kullanıcıların da kullanımına sunulabilir. Daha fazla bilgi için lisansımıza başvurabilirsiniz.',
  TTI_Q8: 'Bir görüntü oluşturmaya çalıştığımda yüklenmesi neden biraz zaman alıyor?',
  TTI_A8:
    'Sayfanın görüntüyü oluşturması birkaç saniye sürecektir. Geçen süre, istemin karmaşıklığına, mevcut donanım ve bilgi işlem kaynaklarının yanı sıra yapay zekanın aynı anda işlemesi gereken istek miktarına bağlıdır.',
  TTI_Q9: 'Ürettiğim yapay zeka görüntülerini nasıl lisanslayabilirim?',
  TTI_A9:
    'Oluşturduğunuz Yapay Zeka görsellerini Corporate+ hesabınızdan indirerek lisanslayabileceksiniz. Her indirmeyle birlikte, oluşturulan görselin yüksek çözünürlüklü versiyonunu ve $25.000 yasal garanti alacaksınız.',
  TTI_Q10: 'Geçmiş nesilleri görüntüleyebilir miyim?',
  TTI_A10:
    "Evet, geçmiş nesillerinizin URL'sini yer imlerine ekleyerek ve yeniden görüntüleyerek bunu yapabilirsiniz. Nesillerin sonuçlarını arkadaşlarınızla da paylaşabilirsiniz.",
  TTI_Q11: 'Yapay zeka ile nasıl yüksek kaliteli görüntüler oluşturabilirim?',
  TTI_A11: `"1. Net bir hedefle başlayın: "Bir seyahat blogu için yüksek kaliteli bir dağ manzarası görüntüsü."

2. İstediğiniz stili ve havayı belirtin: "Görüntü doğal, organik bir hisle birlikte parlak ve canlı olmalıdır."

3. Konuyu açıklayın: Ortam, ışıklandırma ve diğer önemli özellikler veya unsurlar gibi ayrıntılar da dahil olmak üzere görüntünün konusu hakkında net olun. Örneğin, "Ön planda kıvrımlı bir nehir ve arka planda masmavi bir gökyüzü ile karla kaplı bir dağ zirvesini öne çıkarın."

4. Örnekler verin: Aradığınız görsele benzer bir tarza veya ruh haline sahip diğer görsellerden örnekler ekleyin. Bu, yapay zeka algoritmasını yönlendirmeye ve çıktının beklentilerinizi karşılamasını sağlamaya yardımcı olabilir.

5. İlgili anahtar kelimeler kullanın: "dağ manzarası," "karla kaplı tepe," veya "parlak ve canlı."

6. Belirsizlikten kaçının: Talimatları verirken mümkün olduğunca açık ve net olun ve beklenmedik sonuçlara yol açabilecek muğlak veya belirsiz bir dil kullanmaktan kaçının."`,

  /// Generating page
  bringingYourImagesToLife: 'Görüntülerinizi hayata geçirin...',
  cplusAiGenerator: 'AI Oluşturucu',
  generatedImages: 'Oluşturulan görüntüler',
  promptSettings: 'Komut istemi ayarları',
  pastGeneration: 'Geçmiş nesiller',
  generateVariation: 'Varyasyon üretin',
  iLikeThis: 'Bunu sevdim',
  iDislikeThis: 'Bunu sevmedim',
  shareOptions: 'Options de partage.',
  variations: 'Varyasyonlar',
  copiedSharedUrl: "Paylaşılan URL'yi panoya kopyalama",

  galleryPrompt1: 'Ruhani mütevazı deniz kızı, su altı, balıklarla çevrili deniz kabuğu tacı, portre',
  galleryPrompt2: 'yemek ve mumlarla dolu masada doyurucu pazar rostosu',
  galleryPrompt3: 'Ormanda gülümseyen sevimli pofuduk tilki, son derece detaylı, diğer orman hayvanları ile yakınlaştırılmış',
  galleryPrompt4: 'yüksek kalite, 8K Ultra HD, kristalden yapılmış bir balkabağı içinde müzik notaları enstrüman şekilleri, yüksek detaylı, büyülü balkabağı lambası, sonbahar hissi, sakin',
  galleryPrompt5: 'İskoçya panoramik sinematik manzara, parlak güzel bir gün',
  galleryPrompt6: '3d tarzı küçük kız, 4k, 8k, oktan render fotogerçekçi, hdr, fotoğrafçılık, yüksek çözünürlüklü, simetrik yüz, hacimsel aydınlatma, tozlu pus, fotoğraf, oktan render, 24mm, 4k, 24mm, DSLR, yüksek kalite, 60 fps, ultra gerçekçi',
  galleryPrompt7: 'kahverengi tenli adam, uzayda Jüpiter gezegenini yumruklayan kahraman, yüzü öne dönük',
  galleryPrompt8: 'Noel ortamında sıcak kahve içinde kurabiye',
  galleryPrompt9: 'Montserra yaprakları ile beyaz Minimalist arka plan üzerinde sanatsal su şişesi',
  galleryPrompt10: 'evanescent elmas fantezi̇ ejderha',
  galleryPrompt11: 'çiy damlalarındaki gezegenler, gezegensel çiy damlaları, gündoğumu, yakın çekim, aşırı detaylı, keskin odak, stüdyo fotoğrafı, karmaşık detaylar, son derece detaylı',
  galleryPrompt12: 'altın bir sandığın üzerinde oturan bir saksağan, gerçekçi fantastik sanat eseri tarzında, değerli taş, karanlık peri masalları, gizemli',
  galleryPrompt13: "karlı bir dağ ve dağın tepesinde diğer yöne giden bir buz izi, ultra hd, gerçekçi, canlı renkler, son derece ayrıntılı, UHD çizim, kalem ve mürekkep, mükemmel kompozisyon, güzel ayrıntılı karmaşık delicesine ayrıntılı oktan render artstation'da trend, 8k sanatsal fotoğrafçılık, fotogerçekçi konsept sanatı, yumuşak doğal hacimsel sinematik mükemmel ışık",
  galleryPrompt14: 'Yıldızlararası medeniyet dönemi, gizemli dijital uzay süper bilimkurgu uzaylı şehir binalarına bakan yüksekte duran, Zarif güzel dış süper gökdelen kule binası, sokak, gökyüzü köprüsü, gökyüzünde yüzen süper bilimkurgu mimarisi, savaş makinesi, yüksek teknoloji, sayısız elektronik ekran ışığı, Süper Bilimkurgu Görsel Şöleni Uzay, Bilimkurgu, Gizem, bilimkurgu, gerçeküstü, süper net görüntüler, HD pikseller, ultra-HD, 5D, 8K çözünürlük, piksel mükemmelliği, mükemmel ultra ince ayrıntı, keskin odak, stüdyo fotoğrafı, karmaşık ayrıntılar, son derece ayrıntılı',
  galleryPrompt15: "Gece Florida Keys'de arka planda okyanus ile güzel plaj, neon renkler, siyah arka plan, sakin dalgalar, doğal renk tonu, gün batımı, sakin, dalgalarda rahatlatıcı yakınlaştırma, plaj, düzleştirilmiş ufuk, pembe, mavi, turuncu, mor",
  galleryPrompt16: 'en iyi kalite, çift pozlama, posta sanatı, kırık bir kağıt üzerinde sanat, bir yün yumağı ile oynayan yakın çekim sevimli bir kedi yavrusu detaylı rahat oda içi, ışıltılı, güneş ışığı, güneş ışınları, dinamik poz, hikaye kitabı illüstrasyonu, 2d, düz, sevimli, sevimli, vintage, masal, patchwork, vitray, hikaye kitabı detaylı illüstrasyon, sinematik, ultra yüksek detaylı, küçük detaylar, güzel detaylar, mistik, canlı renkler, karmaşık arka plan',

  galleryPrompt17:
    'ateş yansımaları ile su üzerinde piramit, dağlar, karanlık sürrealizm, atmosferik portreler, keskin odak',
  galleryPrompt18: 'kızıl panda ormanda, sinematik',
  galleryPrompt19: 'deniz, ay, balık, yıldızlı gökyüzü, tekne, renkli illüstrasyon',
  galleryPrompt20:
    'Canlı kentsel yansımalar, minimalist soyut tarzda cesur binalar ve parıldayan suların arasında dans ediyor, canlı akşam enerjisini, geometrik soyutlamayı, neon aydınlatma ve akrilik pürüzlendirme tekniği ile çağdaş pop art tarzını yakalıyor',
  galleryPrompt21:
    'egy női sziluett hátulnézetből, izzó karddal, kör alakú portál a sarkvidéken, amely jéghegyek felé vezet, filmszerű közepes felvétel, monumentális figura.',

  /// error page
  copyrightDetected: 'Tespit Edilen Telifli Terimler/Kişilikler veya Ticari Markalar',
  copyrightDetectedDesc:
    'Sorumlu bir Yapay Zeka oluşturucu olmayı taahhüt ediyoruz ve olası ihlalleri önlemek için istemlerde yer alan telif hakkıyla korunan ticari markalar, terimler, markalar veya kişilikler için sonuçlar üretmeyeceğiz. Hizmet Şartlarımız, hizmetleri herhangi bir fikri mülkiyet hakkını ihlal edecek, kötüye kullanacak veya ihlal edecek şekilde KULLANMAYACAĞINIZI öngörmektedir. Lütfen isteminizi değiştirin ve tekrar deneyin.',
  errorMesTTI: 'Oops! Üzgünüz, ama bir şeyler ters gitti.',
  errorMesTTIDesc: 'Sorun incelenmek üzere kaydedildi. Lütfen daha sonra tekrar deneyin.',
  tryAgain: 'Tekrar deneyin',
  // hruc bulk download
  hrucBulkDownloadTitle: 'HRUC bulk download',
  hrucDownload: 'HRUC download',
  hrucBulkdownloadNotSupported: 'HRUC bulk download is not supported',
  // download feature
  downloadQuotaFinished:
    'You have reached the maximum quota available. Please contact your system administrator.',

  // search bar placeholder
  searchBarPlaceholderAllImageDesktop: 'Çarpıcı görsel bulun. Anahtar kelimelere veya ifadelere göre arama yapın.',
  searchBarPlaceholderAllImageTablet: 'Çarpıcı görsel bulun.',
  searchBarPlaceholderAllImageMobile: 'görsel Bul.',
  searchBarPlaceholderPhotoDesktop: 'Çarpıcı fotoğraf bulun. Anahtar kelimelere veya ifadelere göre arama yapın.',
  searchBarPlaceholderPhotoTablet: 'Çarpıcı fotoğraf bulun.',
  searchBarPlaceholderPhotoMobile: 'fotoğraf Bul.',
  searchBarPlaceholderVectorDesktop: 'Çarpıcı vektörler bulun. Anahtar kelimelere veya ifadelere göre arama yapın.',
  searchBarPlaceholderVectorTablet: 'Çarpıcı vektörler bulun.',
  searchBarPlaceholderVectorMobile: 'vektörler Bul.',
  searchBarPlaceholderFootageDesktop: 'Çarpıcı video bulun. Anahtar kelimelere veya ifadelere göre arama yapın.',
  searchBarPlaceholderFootageTablet: 'Çarpıcı video bulun.',
  searchBarPlaceholderFootageMobile: 'video Bul.',
  searchBarPlaceholderAudioDesktop:
    'Çarpıcı ses ve müzik dosyaları bulun. Anahtar kelimelere veya ifadelere göre arama yapın.',
  searchBarPlaceholderAudioTablet: 'Çarpıcı ses ve müzik dosyaları bulun.',
  searchBarPlaceholderAudioMobile: 'ses ve müzik dosyaları Bul.',
  searchBarPlaceholderFreeDesktop: 'Çarpıcı ücretsiz resimler bulun. Anahtar kelimelere veya ifadelere göre arama yapın.',
  searchBarPlaceholderFreeTablet: 'Çarpıcı ücretsiz resimler bulun.',
  searchBarPlaceholderFreeMobile: 'ücretsiz resimler Bul.',
  // editorial warning
  forEditorialUseOnly: 'Yalnızca Editöryel Kullanım',
  forEditorialUseOnlyDesc:
    'Bu görsel özel izin alınmadan reklam ve promosyon amaçlı kullanılamaz.',

  // download comp
  downloadHiResComp: 'HI-RES TASLAK İNDİR',
  downloadComp: 'TASLAK İNDİR',

  generateTerm: ({ callback }) => (
    <>
      By clicking “Generate”, you hereby agree that the generated Output is solely governed by the
      {' '}
      <span aria-hidden onClick={callback}>
        AI Generator Terms of Service.
      </span>
      {' '}
    </>
  ),
  textToImage: 'Metinden görsele',
  descTourTTI: 'Hayal gücünüzü serbest bırakın ve metinlerle görseller oluşturun!',
  colorAndTone: 'Renk ve ton seçin',
  adultContentTitle: 'Potansiyel Yetişkin İçerikli Terimler Tespit Edildi',
  adultContentDesc:
    'Sorumlu yapay zekaya bağlıyız ve bu nedenle belirli ifadeler, kavramlar ve/veya fikirler için sonuç üretmiyoruz. Lütfen isteminizi (görüntüyü oluşturmak için sağladığınız açıklama) değiştirin ve tekrar deneyin.',
  titleSuccessDownload: 'İhtiyacınız olanı bulduğunuz için mutluyuz.',
  hangInThere: 'Biraz daha bekleyin! Çok uzun sürmeyecek.',
  reachedTheLimit: 'Olamaz! Bugün için yapay zeka görüntüsü oluşturma sınırına ulaştınız.',
  backTomorrow: 'Daha fazla oluşturmak için yarın tekrar gelin!',
  generateTermService: 'AI Generator Terms of Service',
  endUserLicenseAgreement: 'End user license agreement',
  aiPromptPlaceholder:
    'Başlamak için istediklerinizi burada tanımlayın, biz de onu hayata geçirelim!!',

  downloadAgain: 'Download again',
  btnClose: 'Kapat',

  variousTitle: 'Çeşitli modellerimizi keşfedin',
  variousSDXL: 'Çarpıcı fotogerçekçi insanları ve sahneleri hayata geçirin.',
  variousEimisAnime:
    'Anime tarzı görüntüler oluşturmak için tasarlanmış üretken yapay zeka modeli.',
  variousDynaVisionXL: '3D stilize çizgi karakterler oluşturun.',
  variousJuggernautXL: 'Mimari öğeler ve manzaralar/arka planlar etrafında odaklanmıştır.',
  variousDreamShaperXL:
    'Yüksek çözünürlüklü yükseltme, geliştirilmiş karakterler ve ejderha nesilleri, azaltılmış kenar bulanıklığı.',
  variousRealismEngineSDXL: 'Bu model son derece ayrıntılı, gerçekçi görüntülerde uzmanlaşmıştır.',
  variousDALLE:
    'Çok yönlü bir model olan DALL-E 3, hassas konseptten görsele dönüşümlerde mükemmeldir.',
  aiEngine: 'AI Motoru',
  'DALL-E': 'DALL-E 3',

  langText: 'Metin',
  langElements: 'Bileşenler',
  langAuto: 'Oto',
  langBgRemoval: 'Arka Plan kaldırma',
  resetAll: 'Hepsini Sıfırla',
  facebookImage: 'Facebook görsel',
  facebookLink: 'Facebook bağlantı',
  facebookCover: 'Facebook kapak',
  facebookStory: 'Facebook hikaye',
  instagramPost: 'Instagram gönderi',
  instagramStory: 'Instagram hikaye',
  twitterPost: 'Twitter gönderi',
  twitterHeader: 'Twitter başlığı',
  pinterestPost: 'Pinterest gönderi',
  emailHeader: 'E-posta başlığı',
  presentation: 'Sunum',
  presentationWide: 'Geniş sunum',
  ebookCover: 'E-kitap kapak',
  linkedinPost: 'LinkedIn gönderi',
  brochureCover: 'Broşür kapak (A4)',
  postcard: 'Kartpostal',
  photoPrint: 'Fotoğraf baskı',
  youtubeProfileImage: 'YouTube profil resmi',
  youtubeChannelCoverPhoto: 'YouTube kanalı kapak fotoğrafı',
  langApplyBgRemoval: 'Arka Plan Kaldırmayı Uygula',
  autoBnW: 'Otomatik B&W',
  autoAdjust: 'Otomatik Ayar',
  autoPop: 'Otomatik Açılma',
  original: 'Orijinal',
  addHeader: 'Başlık ekle',
  addTitle: 'Başlık ekle',
  addBody: 'Gövde metni ekleyin',
  addAssistive: 'Yardımcı metin ekleyin',
  langCompare: 'Karşılaştır',
  langEnableEditor: 'Görüntüyü düzenle',
  langSimilarStock: 'Benzer Stok Görseller',
  langTextUndo: 'Geri al',
  langTextRedo: 'yinele',
  langTextFont: 'Yazı tipi',
  langTextFontSize: 'Yazı Boyutu',
  langTextColor: 'Metin rengi',
  langTextHighlight: 'Vurgula',
  langTextAlignment: 'Hizala',
  langTextBold: 'Koyu',
  langTextItalic: 'İtalik',
  langTextUnderline: 'Altını çiz',
  langTextLineSpacing: 'Satır aralığı',
  langTextLetterSpacing: 'Harf boşluğu',
  langBringForward: 'İleriye getir',
  langBringBackward: 'Geriye getir',
  langDuplicate: 'Çoğalt',
  langOpacity: 'Opaklık',
  langDelete: 'Sil',
  imageProperties: 'Görüntü özellikleri',
  noEditCompareMsg: 'Karşılaştırmak için herhangi bir düzenleme yapılmadı.',
  stockPhoto: 'Stok Fotoğraf',
  basicShape: 'Temel Şekil',
  chitChat: 'Sohbet',
  shapes: 'Şekiller',
  elementChitChat: 'Sohbet Bileşeni',
  organicAbstractShapes: 'Organik Soyut Şekiller',
  elementShapes: 'Bileşen Şekilleri',
  langApplyingEdit: 'Düzenlemeler uygulanıyor...',
  langTextAllFonts: 'Tüm Yazı Tipleri',
  langSearchFontType: 'Yazı Tipi Ara',
  editorCompareImageBeforeTitle: 'Önce',
  editorCompareImageAfterTitle: 'Sonra',
  langSizePx:
    'Piksel, dijital bir görüntünün temel birimidir. Piksel cinsinden ölçülen görüntü boyutu, ayrıntı düzeyini ve indirme boyutunu belirler.',
  langSizeDpi:
    "İnç başına nokta baskı çözünürlüğünü ölçer. Daha yüksek dpi daha fazla ayrıntı anlamına gelir. Baskı için, tüm görüntüler için önerilen çözünürlük 300 dpi'dir",
  LANG_SOCMED_SIZE: 'Sosyal Medya Boyutları',
  cautionTTI:
    'Dikkat: Bilgi isteminde ticari markalar, telif hakları, ünlü simge yapılar ve/veya kişilerle ilişkili ifadelerin kullanıldığını tespit ettik.  İçeriğin kullanımından, yürürlükteki herhangi bir yasayı ihlal etmemesi veya herhangi bir hakkı ihlal etmemesi de dahil olmak üzere, yalnızca Sizin sorumlu olduğunuzu unutmayın.',

  addImageReference: 'Görsel referansı ekleyin (İsteğe bağlı)',
  uploadImageOnly: 'Yalnızca JPG veya PNG yükleyin. Maksimum 4MB.',
  dragAndDrop: 'Dosyayı sürükleyip bırakın veya',
  errorFileSize: "Bu dosya çok büyük. Maksimum 4MB'a izin verilir.",
  adjustScale: "Yüklediğiniz görüntüden çok benzer veya farklı sonuçlar elde etmek için bu ölçeği ayarlayın. Önerilen aralık 7 ila 15'tir.",
  similar: 'Benzer',
  different: 'Farklı',
  uploadNew: 'Yeni yükle',

  imageGuideGeneration: 'İmajınız, üretim sırasında isteminize rehberlik edecektir.',
  notAvailableForEngine: 'Seçili AI Motoru için mevcut değildir.',

  imagePromptDescribe: 'Başlamak için, aklınızdan geçenleri anlatarak yapay zekamızın oluşturmasını istediğiniz görüntüyü belirtin.',
  fileTypeNotSp: 'Dosya türü desteklenmiyor.',
  theFileSizeTooSmall: 'Dosya boyutu çok küçük. En az 512 piksele izin verilir.',

  relatedSearchPhoto: 'İlgili Stok Fotoğraf Aramaları',

  STOCK_PHOTO_PATH: 'stok-fotoğraf',
  STOCK_FOOTAGE_PATH: 'stok-video',
  STOCK_AUDIO_PATH: 'stok-audio',
  CLIPART_VECTOR_PATH: 'klipart-vektör',
  ALL_IMAGES: 'Tüm Görseller',
  PHOTO: 'Photo',
  PHOTOS: 'Fotoğraf',
  VECTORS: 'Vektörler',
  FOOTAGE: 'Video',
  AUDIO: 'Ses',
  LANG_MORETHANMILLION: 'ROYALTY FREE STOK FOTOĞRAF',
  LANG_SVG_OR_EPS: 'SVG ya da EPS',
  LANG_OR: 'Ya da',
  STANDARD_LICENSE: 'Standart Lisans',
  EXTENDED_LICENSE: 'Genişletilmiş Lisans',
  CREDIT_PACK: 'Kredi Paketi',
  DOWNLOAD_PACK: 'Görsel Download Paketi',
  SUBSCRIPTION_PLAN: 'Abonelik Planı',
  LANG_DOWNLOAD: 'İndir',
  STOCKPHOTO: 'Stok Fotoğraf',
  STOCK_PHOTO: 'Stok Fotoğraf',
  STOCK_VECTOR: 'Stok Vektör',
  PREMIUM_STOCKPHOTO: 'PREMIUM Stok Fotoğraf',
  PLUS_STOCK_PHOTO: 'PLUS Stok Fotoğraf',
  PLUS_STOCK_VECTOR: 'PLUS Stok Vektör',
  MOBILE_STOCKPHOTO: 'Mobil Stok Görsel',
  STOCKFOOTAGE: 'Stok Video',
  IMAGEID: 'Görsel Kimliği',
  FONTID: 'ID',
  MEDIATYPE: 'Medya türü',
  COPYRIGHT_TEXT: 'Telif hakkı',
  KEYWORDS: 'Anahtar Sözcükler',
  LANG_CANCEL: 'İptal Et',
  LANG_PURCHASE: 'Satın Al',
  LANG_STOCKVECTORSANDILLUS: 'Stok Vektör Çizimler ve Çizimler',
  LANG_STOCKPHOTOSANDIMAGES: 'Stok Fotoğraf ve Görseller',
  LANG_MATCHES: 'gösterme',
  LANG_VECTORRESULTS: 'Sizin için {number} adet {keyword} telifsiz vektör görseli bulundu.',
  LANG_PHOTORESULTS: 'Sizin için {number} adet {keyword} telifsiz stok fotoğraf ve görseli bulundu.',
  LANG_SIMILARIMGS: 'Benzer Telifsiz Fotoğraflar',
  LANG_COLLECTIONS: 'Koleksiyonlar',
  LANG_ALL: 'Tümü',
  LANG_STD: 'Standart',
  LANG_EXTD: 'Genişletilmiş',
  LANG_COMMERCIAL: 'Ticari',
  LANG_SUBSCRIPTION: 'Abonelik',
  LANG_EDITORIAL: 'Editöryel',
  LANG_UPLOADED: 'Uploaded',
  LANG_FRESHNESS: 'Yenilik',
  LANG_ANYTIME: 'Herhangi bir zaman',
  LANG_2DAYS: '2 days',
  LANG_PAST2DAYS: 'Son 2 gün',
  LANG_PASTWEEK: 'Geçen hafta',
  LANG_PRICE_PLUS_MONTH: "<span class='price'>{price}</span>/ay",
  LANG_PRICE_PLUS_YEAR: "<span class='price'>{price}</span>/yıl",
  LANG_PASTMONTH: 'Geçen ay',
  LANG_PAST3MONTHS: 'Son 3 ay',
  LANG_NUMOFPPL: 'Kişi sayısı',
  LANG_NOT_RECEIVE_VERIFICATION_EMAIL: 'Doğrulama e -postasını almadınız mı?',
  LANG_WITHOUTPEOPLE: 'İnsansız',
  LANG_PERSON: 'Kişi',
  LANG_PEOPLE: 'kişi',
  LANG_INFANTS: 'Bebekler',
  LANG_TODDLERS: 'Toddlers',
  LANG_TEENS: 'Gençler',
  LANG_20S: '20s',
  LANG_30S: '30s',
  LANG_40S: '40s',
  LANG_50S: '50s',
  LANG_60S: '60s',
  LANG_70S: '70 +',
  LANG_CAUCASIAN: 'Beyaz',
  LANG_BLACK: 'Siyahi',
  LANG_HISPANIC: 'Hispanik',
  LANG_EAST_ASIAN: 'Doğu Asyalı',
  LANG_SOUTHEAST_ASIAN: 'Güneydoğu Asyalı',
  LANG_INDIAN: 'Indian',
  LANG_MIDDLE_EASTERN: 'Ortadoğulu',
  LANG_AGE: 'Yaş',
  LANG_ALL_SHAPE: 'Hepsini Gör',
  LANG_ETHNICITY: 'Etnik Köken',
  LANG_PEOPLEORMORE: '4 kişi ya da daha fazla',
  LANG_COLOR: 'Renk',
  LANG_OTHEROPTION: 'Diğer Seçenekler',
  SOLID_OR_TRANSPARENT_BACKGROUNDS: 'Düz/Şeffaf Arka Planlar',
  LANG_FILTER_BY: 'Şununla Filtrele',
  LANG_FILTER: 'Filtrele',
  LANG_PHOTOGRAPHY: 'Fotoğraf',
  LANG_VECTORILLUST: 'Vektör Çizim',
  LANG_SORTBY: 'Sıralama kriteri',
  LANG_MORE: 'Diğer',
  LANG_RELEVANCE: 'Ilgililik',
  LANG_RESEND: 'Yeniden gönder',
  LANG_RESEND_VERIFICATION: 'Doğrulama e-postasını tekrar gönder',
  LANG_NEWEST: 'Yeni',
  LANG_GEORANK: 'Georank',
  LANG_ORIENTATION: 'Yön',
  LANG_HORIZONTAL: 'Yatay',
  LANG_PORTRAIT: 'Dikey',
  LANG_SQUARE: 'Kare',
  LANG_PANORAMA: 'Panoramik',
  LANG_SELECTIVE_FOCUS: 'Seçici Odaklanma',
  LANG_PATTERN: 'Desen',
  LANG_VIBRANCE: 'Titreşim',
  LANG_VERIFICATION_DESCRIPTION: "123RF'den içerik indirmeden önce hesabınızı doğrulamanız gerekir. Lütfen e-postanızı kontrol edin veya yeniden göndermek isterseniz aşağıdaki bağlantıya tıklayın.",
  LANG_VERIFICATION_TITLE: 'Lütfen e -postanızı doğrulayın.',
  VERIFICATIONRESETUNABLE: 'Oops! E-postanız gönderilemedi. ',
  LANG_CHOOSEUPTO3COLORS: '3 renge kadar seçim yapabilirsiniz',
  LANG_ISOLATED: 'Yalıtılmış',
  LANG_STYLE: 'Stil',
  LANG_SAFESEARCH: 'Güvenli arama',
  LANG_ONLY_AUTHENTIC: 'Sadece özgün',
  LANG_EXCLUDE_PLACEHOLDER: 'ex. cheese',
  LANG_EXCLUDEWORDS: 'Bu sözcükleri dahil etme',
  LANG_EXCLUDE: 'Hariç Tut',
  LANG_INCLUDE_AICONTENT: 'Yapay zeka tarafından oluşturulan içeriği dahil edin',
  VISUAL_SEARCH: 'Görsel Arama',
  VISUAL_SEARCH_TOOLTIP_TITLE: 'Bir Görüntü İçinde Aramayı Özelleştirmenin 3 Kolay Yolu!',
  VISUAL_SEARCH_TOOLTIP_INFO_1: '1. Arama yapılacak öğeyi tanımlamak için görüntüsü kullanılacak bölgenin noktalarına tıklayın.',
  VISUAL_SEARCH_TOOLTIP_INFO_2: '2. Arama alanını büyütmek veya küçültmek için kırpma kutusunu sürükleyin.',
  VISUAL_SEARCH_TOOLTIP_INFO_3: "3. Aramaya geri dönmek için 'X' butonuna tıklayın",
  VISUAL_SEARCH_NO_RESULTS: 'Aramanızda sonuç bulunamadı.',
  LANG_RIS_TITLE: 'Görselle Arama',
  LANG_RIS_NOTFOUND: 'Sonuç Bulunamadı!',
  LANG_RIS_ERROR_HEADER: 'Yüklenen Resim hata içeriyor, resmin aşağıdaki kriterlere sahip olduğundan emin olun:',
  LANG_RIS_REFINE_SRCH_MSG: 'Aramanızı anahtar kelime ile daraltın',
  LANG_RIS_UPLOADED_IMAGE: 'Yüklenen Resim',
  LANG_TELL_ME_MORE: 'vBana daha fazla bilgi ver',
  LANG_ENTERKEYWORD: 'Lütfen bir anahtar kelime girin',
  LANG_SIZE: 'Boyut',
  LANG_STANDARD_SIZE: 'Standart Boyutlar',
  LANG_EXTENDED_SIZE: 'GENIŞLETILMIŞ BEDENLER',
  LANG_SIZE_PX: 'Piksel, dijital bir görüntünün temel birimidir. Piksel cinsinden ölçülen görüntü boyutu, ayrıntı düzeyini ve indirme boyutunu belirler.',
  LANG_SIZE_DPI: "İnç başına nokta baskı çözünürlüğünü ölçer. Daha yüksek dpi daha fazla ayrıntı anlamına gelir. Baskı için, tüm görüntüler için önerilen çözünürlük 300 dpi'dir",
  LANG_COMPARE: 'Karşılaştır',
  LANG_AUTO_ENHANCE: 'Oto iyileştirme',
  LANG_AUTO: 'Oto',
  LANG_BG_REMOVAL: 'Arka Plan kaldırma',
  LANG_TEXT: 'Metin',
  LANG_ELEMENTS: 'Bileşenler',
  LANG_IMAGE_INFO: 'Görüntü bilgisi',
  LANG_FOLLOW: 'Takip et',
  LANG_FOLLOWED: 'Takip edildi',
  LANG_FOLLOWING: 'Following',
  FACEBOOK_IMAGE: 'Facebook görsel',
  FACEBOOK_LINK: 'Facebook bağlantı',
  FACEBOOK_COVER: 'Facebook kapak',
  FACEBOOK_STORY: 'Facebook hikaye',
  INSTAGRAM_POST: 'Instagram gönderi',
  INSTAGRAM_STORY: 'Instagram hikaye',
  TWITTER_POST: 'Twitter gönderi',
  TWITTER_HEADER: 'Twitter başlığı',
  PINTEREST_POST: 'Pinterest gönderi',
  EMAIL_HEADER: 'E-posta başlığı',
  PRESENTATION: 'Sunum',
  PRESENTATION_WIDE: 'Geniş sunum',
  EBOOK_COVER: 'E-kitap kapak',
  LINKEDIN_POST: 'LinkedIn gönderi',
  BROCHURE_COVER: 'Broşür kapak (A4)',
  POSTCARD: 'Kartpostal',
  PHOTO_PRINT: 'Fotoğraf baskı',
  YOUTUBE_PROFILE_IMAGE: 'YouTube profil resmi',
  YOUTUBE_CHANNEL_COVER_PHOTO: 'YouTube kanalı kapak fotoğrafı',
  AUTO_COLOR: 'Otomatik renk',
  BALANCE: 'Denge',
  AUTO_LEVEL: 'Otomatik seviye',
  AUTO_TONE: 'Otomatik ton',
  ENRICH: 'Zenginleştir',
  RESET: 'Sıfırla',
  RESET_ALL: 'Hepsini Sıfırla',
  FILE_DOWNLOAD_OPTIONS: 'Dosya indirme seçenekleri',
  FILE_DOWNLOAD_INITIATED: 'Başlatılmış Dosya İndirme İşlemi',
  IMAGE_PROPERTIES: 'Görüntü özellikleri',
  DOWNLOAD_DETAILS: 'Özellikleri indir',
  FILE_SIZE: 'Dosya boyutu',
  LICENSE: 'Lisans',
  FILEFORMAT: 'Filetype',
  CHANGE: 'Deği̇şti̇r',
  PRICING: 'Fiyatlandırma',
  FIND_QUALITY_IMAGE: 'Şimdi kaliteli görüntü bulun',
  SAVE_AS: 'Farklı kaydet',
  IMAGE_USAGE: 'Görüntü Kullanımı',
  PRINT: 'Baskı',
  ELECTRONIC: 'Elektronik',
  COMPREHENSIVE: 'Kapsamlı',
  DOWNLOAD_CREDITS: '<b>{quota}</b> Kredilerinizden indirin Kredi Paketi',
  BASIC_SHAPE: 'Temel Şekil',
  CHIT_CHAT: 'Sohbet',
  SHAPES: 'Şekiller',
  ELEMENT_CHIT_CHAT: 'Sohbet Bileşeni',
  ORGANIC_ABSTRACT_SHAPES: 'Organik Soyut Şekiller',
  ELEMENT_SHAPES: 'Bileşen Şekilleri',
  BUY_SINGLE_IMAGE: 'Tek görsel satın al <br/><b>{quota} kredi</b>',
  BUY_SINGLE_IMAGE_CREDIT: 'Kredi Paketi Satın Alın <br/><b>{quota} kredi</b>',
  BUY_CREDIT_PACK: 'Kredi paketi satın alın ve <br/><b>daha fazla tasarruf edin</b>',
  BUY_CREDIT_PACK_POINT_1: '{quota} Kredi <b>{price}</b>',
  BUY_CREDIT_PACK_POINT_2: 'Aylık taahhüt yok.',
  BUY_CREDIT_PACK_POINT_3: '<b>1 yıl</b> geçerli.',
  BUY_SINGLE_IMAGE_PSUB: 'Tek görsel satın al <br/><b>{quota} indirme kotası</b>',
  BUY_DOWNLOAD_PACK: 'İndirme paketi satın alın ve <br/><b>daha fazla tasarruf edin</b>',
  BUY_DOWNLOAD_PACK_POINT_1: '{quota} görsel indirme paketi <b>{price}</b>',
  BUY_DOWNLOAD_PACK_POINT_2: 'Aylık taahhüt yok.',
  BUY_DOWNLOAD_PACK_POINT_3: '<b>1 yıl</b> geçerli.',
  LOWEST_PRICE: 'Abonelik Paketleriyle ile <br/><b>en uygun fiyat</b> ',
  LOWEST_PRICE_POINT_1: '1 ay deneyin: <b>{price}</b>',
  LOWEST_PRICE_POINT_2: '10 fotoğraf veya vektör indir.',
  LOWEST_PRICE_POINT_3: 'Günlük indirme limiti yok, indirilmeyen görseller bir sonraki aya devreder.',
  PER_IMAGE_SIZE: 'görsel başına <b>{size} boyut</b>',
  PER_IMAGE_ANY: 'görsel başına <b>herhangi bir boyut</b>',
  PER_IMAGE: 'görsel başına',
  VIEW_PRICING: 'Diğer paketler ve fiyatlar',
  DOWNLOAD_BEGIN: 'Download kısa süre içinde başlayacaktır. Lütfen bekleyiniz.',
  DOWNLOAD_NOT_STARTING: 'Download hala başlamadı mı?',
  SELECT_MIRROR: '[Başka sunucu seçin]',
  SHOW_MORE: 'Daha Fazla Göster',
  SHOW_LESS: 'Daha azını göster',
  LICENSE_TYPE: 'Lisans türü',
  LICENSE_SUMMARY: 'Lisans özeti',
  BACK: 'Geri',
  LANG_VECTOR: 'Vektör',
  PREMIUM_VECTOR: 'PREMIUM Stok Vektör',
  SCALE_ANY_SIZE: 'Herhangi bir boyuta ölçeklendir',
  LANDINGPAGE_VECTOR_HEADLINE: 'Stok Vektör Çizimler - Royalty Free Çizimler, Klipartlar ve Grafikler',
  LANDINGPAGE_PHOTO_HEADLINE: 'Stok Fotoğrafçılık - Royalty Free Fotoğraflar, Görseller ve Resimler',
  CATEGORIES: 'Kategoriler',
  LANDINGPAGE_PRICING_1: 'Kredili Kullanım',
  LANDINGPAGE_PRICING_2: 'Abonelik Satın Al',
  LANDINGPAGE_AS_LOW_AS: 'İnanılmaz alt fiyatlar',
  EMPTY_SEARCH_TITLE: 'Oops, Aramanız herhangi bir sonuç vermedi.',
  LANG_SMALL: 'Küçük',
  LANG_MEDIUM: 'Orta',
  LANG_LARGE: 'Büyük',
  LANG_EXTRA_LARGE: 'Ekstra büyük',
  LANG_LOW_CREDITS: 'Kredileriniz azalmış görünüyor!',
  LANG_TOPUP: 'Tamamla',
  LANG_TOPUP_NOW: 'Şimdi yeni kredi alın',
  SEO_DETAILS_TITLE_STOCKPHOTO: '{description} Royalty Free Stok Fotoğraf, Resimler, Görseller ve Stok Fotoğrafçılık. Image {mediaId}',
  SEO_DETAILS_TITLE_CLIPARTVECTOR: '{description} Royalty Free SVG, Klipartlar, Vektör Çizimler ve Stok Çizim. Image {mediaId}',
  SEO_DETAILS_METADESC_STOCKPHOTO: '{description} stok fotoğraf resmi, görseller ve stok fotoğrafçılık. Image {mediaId}',
  SEO_DETAILS_METADESC_CLIPARTVECTOR: '{description} vektor sanat, klipart ve stok vektör çizimleri. Image {mediaId}',
  SEO_DETAILS_TITLE_FREE_PHOTO: '{description} Ücretsiz Resim ve Fotoğraf {mediaId}.',
  SEO_DETAILS_METADESC_FREE_PHOTO: "{description}'nın ücretsiz resmi. Ücretsiz Resim {mediaId}.",
  SEO_DETAILS_TITLE_FREE_VECTOR: '{description} Ücretsiz Vektör ve grafik {mediaId}.',
  SEO_DETAILS_METADESC_FREE_VECTOR: '{description} için ücretsiz vektör. Ücretsiz grafik {mediaId}.',
  SEO_DETAILS_OGDESCRIPTION: '123RF - Yaratıcı Çalışmalarınız için Milyonlarca Kaliteli Stok Fotoğraf, Vektör, Video ve Müzik Dosyaları.',
  LIVECHAT_LOOKINGHELP: 'Yardım almak ister misiniz?',
  LIVECHAT_CHATWITHUS: 'BİZİMLE SOHBET EDİN!',
  FREE_TRIAL: '<b>Get 10 Free Images</b> <br/>with Subscription Plan',
  LANG_RELATEDSEARCH: 'İlgili Aramalar',
  LANG_RELATED_STOCKPHOTO_SEARCH: 'İlgili Stok Fotoğraf Aramaları',
  LANG_PROPERATTRIBUTE: 'İşte atfımız:',
  LANG_ATTRIBUTEDESC: 'Lütfen ürününüzün, web sitenizin veya broşürünüzdeki herhangi bir alana aşağıdaki kredi bilgisini yerleştirerek bu görseli fotoğrafçısına atfedin',
  LANG_ATTRIBUTE_TITLE: 'Atıf isteğe bağlıdır.',
  LANG_ATTRIBUTE_CREDIT: 'İçerik oluşturucuyu ilişkilendirmek istiyorsanız, lütfen aşağıdaki kredi yazısını kullanın:',
  LANG_ATTRIBUTE_COPYLINK: 'Bağlantıyı kopyala',
  LANG_ATTRIBUTE_COPYTEXT: 'Metni kopyala',
  LANG_LICENSE_AGREEMENT: '<a href={url}><b>İşte bu</b></a> indirme için lisans sözleşmesi',
  LANG_NEXTPAGE: 'Sonraki Sayfa',
  LANG_IMAGE_NO_AVAILABLE: "# {mediaId} no'lu görüntü artık mevcut değil.",
  LANG_PHOTOGRAPHY_METAIMG_DESC: 'Milyonlarca HD stok fotoğraf, royalty free görsel ve resim arayın ve indirin ',
  LANG_VECTOR_METAIMG_DESC: 'Milyonlarca HD stok fotoğraf, royalty free görsel ve resim arayın ve indirin ',
  LANG_ANOTHERMETAKWDEFAULT: 'royalty free, stok fotoğraf, stok fotoğrafçılık, stok görseller, resimler, fotoğraflar',
  LANG_ANOTHERMETADESCDEFAULT: 'Milyonlarca HD stok fotoğraf, royalty free görsel, klip, vektör ve çizim arayın ve indirin ',
  EMPTY_FILTERED_RESULT_TITLE: 'Hoop, Bu filtrelerle aramanız hiçbir sonuç vermedi.',
  EMPTY_FILTERED_RESULT_SUBTITLE: 'Bazı filtreleri kaldırmayı veya farklı bir anahtar kelime kullanmayı deneyin.',
  CLEAR_ALL_FILTER: 'Tüm filtreleri temizle',
  PROMOBAR_FREE10_TEXT: 'Get <b>10 FREE Images</b> when you get started on our 1 Month-Free Trial.',
  PROMOBAR_FREE10_BTNLABEL: 'START NOW',
  PROMOBAR_MASSIVE_MARKDOWN_TEXT: 'Tüm aboneliklerde büyük indirimler',
  PROMOBAR_MASSIVE_MARKDOWN_BTNLABEL: 'Planları görüntüle',
  LANG_MODEL_RELEASED: 'Model İzinli',
  LANG_PROPERTY_RELEASED: 'Mülkiyet İzinli',
  LANG_MODEL_RELEASE: 'Model İzinli',
  LANG_PROPERTY_RELEASE: 'Mülkiyet İzinli',
  LANG_YES: 'Evet',
  LANG_NO: 'Hayır',
  LANG_MREXPLAIN: 'Model izni, fotoğraftaki model ve kişilerin imzaladığı ve bu fotoğrafın veya yakın halinin basılabileceği, dağıtılabileceği ve değişik şekillerde kullanılabileceğini ifade eden hukuksal bir dökümandır.',
  LANG_PREXPLAIN: 'Mülkiyet izni, fotoğraflanmış obje, konu, bina, mekan vb. fotoğraflanan şeyin sahibi tarafından imzalanan ve bu fotoğrafın basılabileceği, dağıtılabileceği ve değişik şekil ve formlarda kullanılabileceğini ifade eden hukuksal bir dökümandır.',
  LANG_DOWNLOADS: 'İndirmeler',
  LANG_OLDFILENAME: 'Eski dosya adı',
  LANG_DOWNLOAD_PREVIEW: 'Ön izlemeyi İndir',
  LANG_SHARE: 'Paylaş',
  LANG_RESTRICTIONS: 'Kısıtlamalar',
  LANG_READ_MORE: 'Daha fazla bilgi edinin',
  LANG_EDITORIAL_USE: 'Yalnızca Editöryel Kullanım',
  LANG_EDITORIALDESCP: 'Yalnızca Editöryel Kullanım: Bu görsel yalnızca editöryel amaçla kullanılabilir. Bu görselin reklamda, ticari yada promosyon amaçlarıyla kullanımı, ek izinler alınmadığı sürece yasaktır. 123RF.com izin alınması konusunda hizmet vermemektedir.',
  LANG_LOGO_OWNERSHIP: 'Logo Mülkiyet Hakları',
  LANG_PHOTO_SHOT_SMARTPHONE: 'Bu fotoğraf bir akıllı telefonla çekildi.',
  LANG_VECTORNOLOGO: "Bu görseli firma logonuz olarak kullanmak isterseniz, bu görselin <b>tüm lisans haklarının</b> size devrini sağlayabiliriz. Daha fazla seçenek için lütfen <b>{phonenum}</b>'de bizimle irtibata geçin!",
  LANG_DOWNLOAD_QUOTA: '<b>{quota}</b> Kotanızdan indirin Abonelik Planı',
  LANG_DOWNLOAD_SLOT: '<b>{quota}</b> Hakkınızdan indirin Görsel Download Paketi',
  LANG_LOGO: 'Logo',
  USING_OLDER_BROWSER: 'Eski bir tarayıcı mı kullanıyorsunuz?',
  UPGRADE_NEWER_BROWSER: 'Daha sorunsuz bir deneyim yaşamak için tarayıcınızın sürümünü yenileyin!',
  CLICK_TO_DOWNLOAD: 'Bunlardan birini indirmek için tıklayın:',
  LANG_VECTOR_EDITABLE: 'SVG ve EPS formatında düzenlenebilir dosyalar',
  PROMOBAR_GOOD15_TEXT1: 'Daha az ödeyerek daha fazla indirin. ',
  PROMOBAR_GOOD15_TEXT2: 'Abonelik Paketlerinde geçerli 15 % indirim kodu:',
  PROMOBAR_GOOD15_BTNLABEL: 'GOOD15',
  PROMOBAR_SUMMER25_TEXT1: 'Daha az ödeyerek daha fazla indirin. ',
  PROMOBAR_SUMMER25_TEXT2: 'Tüm paketlerde geçerli 25 % <b>indirim kodu</b>:',
  PROMOBAR_SUMMER25_BTNLABEL: 'SUMMER25',
  PROMOBAR_HALLOW30_TEXT: 'Tüm paketlerde geçerli 30 % <b>indirim kodu</b>:',
  NO_EDIT_COMPARE_MSG: 'Karşılaştırmak için herhangi bir düzenleme yapılmadı.',
  SWITCH_TO_VECTOR_MSG: 'Vektör boyutundaki resimler için fotoğraf editörü kullanılamaz. Vektör biçimine geçerseniz tüm düzenlemeler kaybolacaktır.',
  SWITCH_TO_XL_MSG: 'XL boyutlu resimler için fotoğraf editörü kullanılamaz. XL biçimine geçerseniz tüm düzenlemeler kaybolacaktır.',
  UNAVAILABLE_FOR_THIS_SIZE: '{size} boyutu için mevcut değil.',
  SWITCH: 'DEĞİŞTİR',
  SWITCH_LICENSE_EXT_MSG: 'Bu görüntüyü önce {type} olarak indirmek ister misiniz? Genişletilmiş Lisansa geçerseniz tüm düzenlemeleri kaybedersiniz.',
  LANG_SESSION_EXPIRED: 'Oturumunuz sona erdi. Lütfen <a href={url}>buradan giriş yapın</a>',
  LANG_TEXT_COLOR: 'Metin rengi',
  LANG_SHAPE_ADD: 'Add Shape',
  LANG_TEXT_HIGHLIGHT: 'Vurgula',
  LANG_TEXT_ALIGNMENT: 'Hizala',
  LANG_TEXT_BOLD: 'Koyu',
  LANG_TEXT_ITALIC: 'İtalik',
  LANG_TEXT_UNDERLINE: 'Altını çiz',
  LANG_TEXT_BULLET: 'Madde imi ekle',
  LANG_TEXT_LINE_SPACING: 'Satır aralığı',
  LANG_TEXT_LETTER_SPACING: 'Harf boşluğu',
  LANG_EDIT_COLOR: 'Edit color',
  LANG_BRING_FORWARD: 'İleriye getir',
  LANG_BRING_BACKWARD: 'Geriye getir',
  LANG_DUPLICATE: 'Çoğalt',
  LANG_OPACITY: 'Opaklık',
  LANG_DELETE: 'Sil',
  LANG_TEXT_ADD: 'Yazı ekle',
  LANG_TEXT_EDIT: 'Metni düzenle',
  LANG_TEXT_FONT_TYPE: 'Yazı tipi',
  LANG_TEXT_FONT_SIZE: 'Yazı Boyutu',
  LANG_TEXT_ALL_FONTS: 'Tüm Yazı Tipleri',
  LANG_TEXT_UNDO: 'Geri al',
  LANG_TEXT_REDO: 'yinele',
  LANG_TEXT_FONT: 'Yazı tipi',
  LANG_SEARCH_FONT_TYPE: 'Yazı Tipi Ara',
  LANG_SEARCH_SHAPE_TYPE: 'Bileşen Ara',
  LANG_DONE: 'Tamamlandı',
  ADD_HEADER: 'Başlık ekle',
  ADD_TITLE: 'Başlık ekle',
  ADD_BODY: 'Gövde metni ekleyin',
  ADD_ASSISTIVE: 'Yardımcı metin ekleyin',
  STOCK_PHOTO_BANNER_TITLE: 'İstediğiniz her şeyi tasarlayabileceğiniz milyonlarca telifsiz stok görsel',
  STOCK_PHOTO_BANNER_DESC: 'Büyük fikirlerinizi hayata geçirmek için ihtiyaç duyduğunuz tüm çarpıcı görselleri şimdi bulun.',
  STOCK_PHOTO_SUBSCRIBE_TITLE: 'En doğru görüntülerle hikayelerinizi görselleştirin',
  STOCK_PHOTO_SUBSCRIBE_DESC: 'Hikaye anlatımınızı güçlendirmek için 180 milyondan fazla görsele hemen erişin. Görsellerimiz ve hikayeniz, yaratıcı potansiyelinizi en üst düzeye çıkarmak için mükemmel bir kombinasyondur!',
  STOCK_PHOTO_CARD_DOWNLOAD_PACK: 'İndirme paketi',
  STOCK_PHOTO_CARD_FROM_AS_LOW_AS: 'Kadar düşük',
  STOCK_PHOTO_CARD_CREDIT: 'credit',
  STOCK_PHOTO_CARD_SUBSCRIPTION_PLAN: 'Abonelik Planı',
  STOCK_PHOTO_CARD_IMAGE: 'görüntü',
  STOCK_PHOTO_CARD_SUBSCRIBE_NOW: 'Şimdi abone olun',
  STOCK_PHOTO_CARD_BUY_NOW: 'Şimdi al',
  STOCK_PHOTO_POPULAR_ROYALTY: 'Popüler telifsiz görsel kategorileri',
  STOCK_PHOTO_BROWSE_BEST_SELLING: 'Browse best-selling royalty free images',
  STOCK_PHOTO_DISCOVER_AFFORABLE: 'Fotoğraflar için uygun fiyatlı planları ve fiyatlandırmayı keşfedin',
  STOCK_PHOTO_DISCOVER_NOW: 'Göz atın',
  STOCK_PHOTO_VECTORS_FOOTAGE_AUDIO: 'Vectors, Footage, Audio for you',
  STOCK_PHOTO_VECTORS: 'Vectors',
  STOCK_PHOTO_ILLUSTRATION: 'Illustration',
  STOCK_PHOTO_PATTERN: 'Pattern',
  STOCK_PHOTO_CARTOON: 'Cartoon',
  STOCK_PHOTO_BABY: 'Baby',
  STOCK_PHOTO_FOOTAGE: 'Footage',
  STOCK_PHOTO_FOOTAGES: 'Footages',
  STOCK_PHOTO_BUILDING: 'Building',
  STOCK_PHOTO_HOTEL: 'Hotel',
  STOCK_PHOTO_CHILDREN: 'Children',
  STOCK_PHOTO_ABSTRACT: 'Abstract',
  STOCK_PHOTO_AUDIO: 'Audio',
  STOCK_PHOTO_AUDIOS: 'Audios',
  STOCK_PHOTO_TECHNO: 'Techno',
  STOCK_PHOTO_HOLIDAY: 'Holiday',
  STOCK_PHOTO_ACCOUSTIC: 'Accoustic',
  STOCK_PHOTO_LANDSCAPES: 'Manzara',
  STOCK_PHOTO_BABIES: 'Bebekler',
  STOCK_PHOTO_BUSINESS: 'İş Dünyası',
  STOCK_PHOTO_FOOD: 'Yiyecek',
  STOCK_PHOTO_ANIMALS: 'Hayvanlar',
  STOCK_PHOTO_TECHNOLOGY: 'Teknoloji',
  STOCK_PHOTO_HEALTHCARE: 'Sağlık',
  STOCK_PHOTO_NATURE: 'Doğa',
  STOCK_PHOTO_ROMANCE: 'Romantizm',
  CLIPART_VECTOR_EXPLORE_MILLIONS: 'Parmaklarınızın ucundaki milyonlarca telifsiz vektörlerle tasarımlar hazırlayın',
  CLIPART_VECTOR_NEED_AN_EYE: 'Harika fikirlerinizi gerçeğe dönüştürmenize yardımcı olacak çarpıcı vektör illüstrasyonlar, infografikler, clipartlar, simgeler ve çok daha fazlasını bulun',
  CLIPART_VECTOR_FIND_VECTOR: 'Projelerinizi renklendirmek için doğru vektörleri bulun.',
  CLIPART_VECTOR_FIND_VECTOR_DESC: 'Harika infografikler, illüstrasyonlar, çizgi filmler, clipartlar, arka planlar, simgeler veya çarpıcı tasarımlar oluşturmak için neye ihtiyacınız varsa; hepsi burada. Tasarımlarınızı hayata geçirmek için vektörleştirin!',
  CLIPART_VECTOR_ON_DEMAND: 'On Demand',
  CLIPART_VECTOR_FROM_AS_LOW_AS: 'From as low as',
  CLIPART_VECTOR_PER_CREDIT: '/ credit',
  CLIPART_VECTOR_BUY_SUBSCRIPTION: 'Buy Subscription',
  CLIPART_VECTOR_PER_IMAGE: '/ image',
  CLIPART_VECTOR_SUBSCRIBE_NOW: 'Subscribe Now',
  CLIPART_VECTOR_POPULAR_ROYALTY: 'Popüler telifsiz Vektör Kategorileri',
  CLIPART_VECTOR_DISCOVER_AFFORDABLE: 'Vektörler için uygun fiyatlı planları ve fiyatlandırmayı keşfedin',
  CLIPART_VECTOR_DISCOVER_NOW: 'Göz atın',
  SPEND_AND_WIN_SUBTITLE: "Her {currency}'lık Harcamaya: 1 Çekiliş Hakkı",
  SPEND_AND_WIN_IMGALT: 'Alışveriş Yapın & iPhone 13 Pro Kazanın',
  CLIPART_VECTOR_GRAPHIC: 'Grafik',
  CLIPART_VECTOR_ADVERTISING: 'Reklâm',
  CLIPART_VECTOR_FLOWER: 'Çiçek',
  CLIPART_VECTOR_ABSTRACT: 'Soyut',
  CLIPART_VECTOR_POP: 'Pop',
  CLIPART_VECTOR_BIRD: 'Kuş',
  CLIPART_VECTOR_ILLUSTRATION: 'İllüstrasyon',
  CLIPART_VECTOR_PIXEL: 'Piksel',
  CLIPART_VECTOR_3D: '3 boyutlu',
  LANG_VECTOR_SVG_EPS: 'Vektör, SVG ve EPS',
  FREE_UPGRADE: 'Ücretsiz yükselt',
  FREE_UPGRADE_MESSAGE: 'Tüm abonelik veya indirme paketleri için ÜCRETSİZ XL boyutuna yükselt. Şimdi satın al.',
  LANG_FREE_IMAGE: '123RF Ücretsiz',
  LANG_IMAGE_FREE: 'Bu stok fotoğraf ticari ve kişisel kullanım için ücretsizdir. Atıf gereklidir.',
  LANG_HOW_TO_ATTRIBUTE: 'Nasıl atıfta bulunurum?',
  LANG_FREE_DOWNLOAD: 'Ücretsiz indirin',
  LANG_FREE_DOWNLOAD_BUTTON: 'FREE Indirin',
  LANG_GO_PLUS_BUTTON: "PLUS'a Git",
  LANG_ATTRIBUTION_IS_KEY: 'Atıfta bulunmak, çalışmalarını herkese açık hale getiren içerik hazırlayıcılar  için çok önemlidir. İçerik hazırlayıcılarımız, yarattıkları içeriklerin sizin gibi milyonlarca kullanıcı tarafından kullanılmasından, paylaşılmasından ve takdir edilmesinden çok memnun olurlar.',
  LANG_GET_ATTRIBUTION_LINK: 'Bu bağlantıyı kopyalayın ve içeriğin kullanıldığı yere yakın bir yere yerleştirin.',
  LANG_CREDIT_OWNER: 'Lütfen Yaratıcıya Kredi:',
  LANG_USE_ATTRIBUTION: 'Aşağıdaki atıfta bulunma bağlantısını kullanın.',
  LANG_ATTRIBUTION_LOCATION: 'Bu mümkün değilse, diğer iyi yerler web sitesinin, blogun veya haber bülteninin alt kısmında yer alabilir veya sitenin belirli bir krediler bölümü bile {media_type} sahibini belirtmek için yeterli olacaktır.',
  LANG_GOT_A_QUESTION: "Bir sorunuz mu var? <a href={url} target='_blank'><u>Bize Ulaşın</u></a>",
  LANG_ATTR_FREE_IMG_TITLE: 'Bu ücretsiz görsel için sahibine kredi vermeniz gerekmektedir.',
  LANG_ATTR_FREE_DOWNLOADING: 'Vazgeçme! Uzun sürmez.',
  LANG_PLUS_CONTENT_1: 'Sınırsız indirme alacaksınız',
  LANG_PLUS_CONTENT_2: '100 milyondan fazla fotoğraf ve vektöre erişim',
  LANG_PLUS_CONTENT_3: "Özel 3D Çizimler sadece 123RF'de!",
  LANG_PLUS_CONTENT_4: 'Sınırsız İndirme',
  LANG_PLUS_CONTENT_5: 'Sınırsız Yaratıcı Özgürlük',
  FREE_VECTOR: 'FREE Stok Vektör',
  FREE_STOCKPHOTO: 'FREE Stok Fotoğraf',
  FREE_PUBLIC_DOMAIN_CC0: 'Kamu malı CC0',
  BUY_5_DOWNLOAD_PACK: "<b>Kısa süreli fırsat!</b><br/>3 + <b><span style='color:red'>2 ÜCRETSİZ</span></b> = 5 fotoğraf veya vektör",
  BUY_5_DOWNLOAD_PACK_POINT_2: 'İstediğiniz zaman indirin (bir yıl içinde)',
  BUY_5_GET_2_FREE: '2 adet ÜCRETSİZ alın!',
  LANG_ASK_DOWNLOAD_PLUS: 'Bu PLUS içeriği indirmek ister misiniz?',
  LANG_SIGN_UP_PLUS: '123RF PLUS planımıza en düşük fiyattan kaydolun',
  LANG_FOR_ONLY: "<span class='text'>Sadece</span> <span class='price'>{price}</span> <span class='text'>karşılığında</span>/ay ",
  LANG_FOR_ONLY_2: "<span class='text'>Sadece</span> <span class='price'>{price}</span> <span class='text'>karşılığında</span>",
  LANG_BENEFIT_PLUS: 'Yalnızca PLUS planımızdan faydalanın',
  LANG_RELEASES: 'İzni',
  LANG_UNAVAILABLE: 'Kullanılamıyor',
  LANG_ATTR_FREE_IMG_TITLE2: 'İhtiyacınız olanı bulduğunuz için mutluyuz. 🎉',
  LANG_PLEASE_NOTE: 'Lütfen bu resmin herhangi bir model veya mülk bültenine sahip olmadığını unutmayın. Ticari kullanım riski size aittir.',
  LANG_PLEASE_ADVISED: 'Lütfen tanınabilir bireyleri içeren görüntülerin herhangi bir sürümle gelmediği konusunda tavsiye alın. Bu görüntüleri ticari olarak kullanmak için, ilgili taraflardan izin alınmalıdır. Görüntü tanınabilir bireyler içermiyorsa, ticari olarak kullanılırken hiçbir telif hakkı sorunu olmamalıdır.',
  LANG_REACHED_LIMIT: 'Ops! Günlük indirme limitinize ulaştınız.',
  LANG_COME_TOMORROW: 'Ücretsiz görsellerimizi beğendiğiniz için çok mutluyuz! İndirme limitinizi yenilemek için yarın tekrar gelin. Teşekkürler 😊',
  LANG_DOWNLOAD_ERROR: 'Ops! Görseliniz bir hata nedeniyle indirilemedi.',
  LANG_REFRESH_AND_TRY_AGAIN: "Lütfen tarayıcınızı yenileyin veya tekrar deneyin. Sorun devam ederse lütfen <a href={url} target='_blank'> Bize iletin.</a>",
  LANG_REACHED_LIMIT_PLUS: 'Günlük İndirme Sınırı ulaşıldı',
  LANG_COME_TOMORROW_PLUS: 'Vay! Bugün için indirme sınırınıza ulaştınız. Daha fazla indirmek için lütfen yarın geri gelin. Destek için teşekkürler!',
  SEE_YOU_TOMORROW: 'Yarın görüşürüz!',
  ORIGINAL: 'Orijinal',
  'AUTO_B&W': 'Otomatik B&W',
  AUTO_ADJUST: 'Otomatik Ayar',
  AUTO_POP: 'Otomatik Açılma',
  NONE: 'Hiçbiri',
  LANG_CROP: 'Kırp',
  LANG_ENABLE_EDITOR: 'Görüntüyü düzenle',
  LANG_DISABLE_EDITOR: 'Kapat',
  LANG_IMAGE_FREE_CC0: "Bu bir CC0 görselidir. Atıf gerekli değildir. <a class='linkCC0' href={url} target='_blank'>CC0 Lisans Hüküm ve Koşullarına</a> göre kullanabilirsiniz.",
  LANG_PREVIEW: 'Önizleme',
  LANG_ATTRIBUTION_CC0_TITLE: 'CCO Görseli: Atıf Gerekli Değil',
  LANG_ATTRIBUTION_TITLE: 'Atıf Gerekli',
  LANG_ATTRIBUTION_CONTENT: 'Dosyayı indirdikten sonra atıf bağlantısı sağlanacaktır.',
  LANG_ATTRIBUTION_CC0_CONTENT: 'Bu bir CCO görseli olduğu için atıf gerekli değildir.',
  LANG_SUBCRIBE_PREMIUM: "Günlük indirme limitinize ulaştınız! Daha fazla indirmek için 123RF PREMIUM'a abone olun.",
  LANG_REMOVE_ATTRIBUTION: 'Atıf Kaldırılsın mı?',
  LANG_SUBCRIBE_PLUS_ATTRIBUTION: "100 milyon stok fotoğraf ve ilüstrasyona hiçbir atıfta bulunmadan sınırsız erişim için <img src={image} alt='123RF Plus Logo'>'a abone olun.",
  LANG_CREATOR_ATTRIBUTION: 'Lütfen eser sahibine atıfta bulunun, bağlantı linki aşağıdadır.',
  LANG_GET_ATTRIBUTION_LINK_2: 'Bu bağlantıyı kopyalayın ve içeriğin kullanıldığı yere, web sitesinin alt bilgisine veya sitenin belirli bir kredi bölümüne yakın bir yere yerleştirin.',
  LANG_APPLY_BG_REMOVAL: 'Arka Plan Kaldırmayı Uygula',
  LANG_APPLYING_EDIT: 'Düzenlemeler uygulanıyor...',
  LANG_APPLYING_EDIT_ERROR: 'Bir şeyler yanlış gitti.',
  LANG_TRY_AGAIN: 'Tekrar deneyin?',
  LANG_SEARCHCOLLECTION_PREMIUM: 'Tüm Koleksiyon',
  LANG_SEARCHCOLLECTION_PLUS: 'Ana Koleksiyon',
  LANG_SEARCHCOLLECTION_FREE: 'Temel Koleksiyon',
  LANG_SIMILARIMAGESPAGE_TITLE: "123RF'ten Stok Fotoğrafçılık'tan Stok Fotoğraf ve Royalty Free Görsel Aboneliği",
  LANG_SIMILARIMAGESPAGE_STOCKPHOTOS: 'Benzer Stok Fotoğraflar',
  LANG_SIMILARIMAGESPAGE_EMPTYRESULTS: 'Oops! Burada görülecek bir şey yok.',
  LANG_SIMILARIMAGESPAGE_UNAVAILABLEIMAGE: 'Görsel Bulunamadı',
  LANG_PER_IMAGE: '/görsel',
  LANG_PLANS_AND_PRICING: 'Planlar ve Fiyatlar',
  LANG_CHOOSE_BEST_PLAN: 'İhtiyacınıza uygun en iyi planı seçin.',
  LANG_WANT_MORE_SAVING_1: 'Daha fazla indirim almak mı istiyorsunuz?',
  LANG_WANT_MORE_SAVING_2: "Görsel başı {price}'dan başlayan planları görüntüleyin",
  LANG_BEST_VALUE: 'En İyi Fiyat, %{percentage} Daha az ödeyin',
  LANG_YEARLY_PLAN: 'Yıllık Plan',
  LANG_WITH_MONTHLY_PAYMENTS: 'Aylık ödemeli',
  LANG_GRAB_YEARLY_PLAN: 'Yıllık plan alın ve %{percentage} daha az ödeyin.',
  LANG_PRICE_PER_MONTH: 'Aylık {price}',
  LANG_PRICE_PER_YEAR: 'Yıllık {price}',
  LANG_GET_X_EVERY_MONTH: 'Her ay {quota} fotoğraf veya vektör alın',
  LANG_X_EVERY_MONTH: 'Her ay {quota} fotoğraf veya vektör',
  LANG_SINGLE_IMAGE_PURCHASE: 'Tek Görüntü Satın Alma',
  LANG_GET_ONLY_WHAT_YOU_NEED: 'Sadece o an için ihtiyacınız olanı alın.',
  LANG_TOTAL_PRICE: 'Toplam {price}',
  LANG_DOWNLOAD_IMMEDIATELY: 'Ödeme yaptıktan sonra Görseli hemen indirin.',
  LANG_NEXT: 'Sonraki',
  LANG_TITLE: "123RF'ten Stok Fotoğrafçılık'tan Stok Fotoğraf ve Royalty Free Görsel Aboneliği",
  SIMILAR_STOCK_PHOTOS: 'Benzer Stok Fotoğraflar',
  EMPTY_SIMILAR_SEARCH_RESULTS: 'Oops! Burada görülecek bir şey yok.',
  UNAVAILABLE_IMAGE: 'Görsel Bulunamadı',
  LANG_DOWNLOAD_PACK: 'Görsel İndirme Paketi',
  LANG_GET_X_ANY_TIME: 'Her zaman {quota} fotoğraf veya vektör.',
  LANG_X_CREDITS: '{total} Kredi',
  LANG_PER_CREDIT: '/Kredi',
  LANG_GET_X_PHOTOS_AND_Y_VIDEOS_ANY_TIME: 'Dilediğiniz zaman {photoCount} fotoğraf{videoCount, plural, =0 {} other { veya # video}}',
  INCLUDES_STANDARD_LICENSE: 'Standart Lisans içerir',
  LANG_MEDIUM_SIZE: 'Orta boyut',
  LANG_LARGE_SIZE: 'Büyük boyut',
  LANG_EXTRA_LARGE_SIZE: 'Ekstra büyük boyut',
  LANG_STANDARD_SIZES: 'Standart Boyutlar',
  LANG_ROYALTY_FREE: 'Telifsiz',
  LANG_OPTIONS: 'Seçenekler',
  LANG_BENEFIT_FROM_PLUS_PLAN: 'Yalnızca PLUS planımızdan faydalanın',
  LANG_RELATED_FONT_SEARCH: 'İlgili Aramalar',
  AI_GENERATED_PHOTO: 'Yapay zeka tarafından oluşturulan görüntü',
  FONT_PRICING_CREDIT: 'kredi',
  '3_FONTS_3_IMGS': '3 yazı tipi <b>veya</b> 3 fotoğraf <b>veya</b> 1 video',
  '9_FONTS_9_IMG_3_VIDEOS': '9 yazı tipi <b>veya</b> 9 fotoğraf <b>veya</b> 3 video',
  PURCHASE_AND_DOWNLOAD: 'Satın al ve indir',
  FONT_PATH: 'fonts',
  FONT_ZIP: 'Yazı Tipi (OTF, TTF)',
  FONT_PACKS_LICENSE: '123RF Paket Lisansı',
  FONT_META_DESC: 'Baskı ve web için çarpıcı tasarımlar oluşturmak üzere {product_title} yazı tipi, yazı karakteri veya tipografi indirin.',
  FONT_META_TITLE: 'Yazı Tipi - {product_title} | 123RF',
  FONT_SIMILAR: 'Benzer yazı tipleri',
  LANG_PLANS: 'PLANLAR',
  LANG_I_WANT_THIS: 'I want this',
  LANG_AI_VARIATION_TOOLTIP_TITLE: 'Varyasyonlar oluşturmayı deneyin',
  LANG_AI_VARIATION_TOOLTIP_DESCRIPTION: 'Mevcut orijinal görsele dayalı olarak yeni görseller oluşturun. Ayrıca, yapay zeka tarafından oluşturulan bir görsel her lisanslandığında 123RF’de eser sahibi katılımcılara kazanç sağlamanın yeni bir yolu!',
  LANG_SIZEBAR_TOOLTIP_DESCRIPTION: 'Topluluğumuz size teşekkür ediyor. Lisanslama ücretleri 123RF Katılımcısı ile paylaşılacaktır.',
  LANG_CONTRIBUTORS_AI_VARIATION: 'Orijinal görüntünün 123RF katılımcılarına, lisanslanan her Yapay Zeka varyasyonu için ödeme yapılır.',
  LANG_AI_VARIATION_LIMIT: 'Olamaz! Bugün için yapay zeka görüntüsü oluşturma sınırına ulaştınız.',
  LANG_GENERATE_COMEBACK: 'Daha fazla gorüntü oluşturmak için yarın tekrar gelin!',
  LANG_LICENSE_AND_DOWNLOAD: 'Lisansla ve indir',
  LANG_CONFIRM_OK: 'Tamam.',
  LANG_AI_SWITCH_CONTENT: 'Fotoğraf editörü Yapay Zeka varyasyonları için desteklenmez. Yapay Zeka varyasyonlarına geçerseniz tüm düzenlemeler kaybolacaktır.',
  LANG_GENERATE_VARIATIONS: 'Varyasyonlar oluşturun',
  LANG_GENERATE_WITH_AI: 'Yapay zeka ile görüntüler oluşturun',
  LANG_NEW: 'Yeni',
  LANG_AI_GENERATOR_LICENSE: '123RF AI Generator Hizmet Koşulları (“Koşullar”)',
  LANG_I_UNDERSTAND: 'Anladım',
  LANG_WHAT_IS_THIS: 'Bu nedir?',
  LANG_AUTHENTIC: 'Otantik',
  LANG_ABSTRACT: 'Soyut',
  LANG_MACRO_CLOSEUP: 'Makro/Yakın çekim',
  LANG_OBJECT: 'Nesne',
  LANG_WATERCOLOR: 'Suluboya',
  LANG_FLAT: 'Düz',
  LANG_CARTOON: 'Karikatür',
  LANG_GEOMETRIC: 'Geometrik',
  LANG_GRADIENT: 'Gradyan',
  LANG_ISOMETRIC: 'İzometrik',
  LANG_3D: '3d',
  LANG_HAND_DRAWN: 'Elle çizilmiş',
  LANG_NATURAL: 'Doğal',
  LANG_WARM: 'Sıcak',
  LANG_DRAMATIC: 'Dramatik',
  LANG_VIVID_BOLD: 'Canlı ve cesur',
  LANG_BLACK_WHITE: 'Siyah ve beyaz',
  LANG_ASIAN: 'Asyalı',
  LANG_AFRICAN_AMERICAN: 'Afro-Amerikan',
  LANG_STANDARD: 'Standart',
  LANG_EXTENDED: 'Genişletilmiş',
  LANG_STANDARD_CONTENT_1: 'Sosyal medya, web, e-posta ve mobil için sınırsız görüntüleme.',
  LANG_STANDARD_CONTENT_2: "500.000'e kadar baskı adedi.",
  LANG_STANDARD_CONTENT_3: 'Satışa sunulan ürünlerin içeriğinde kullanılamaz.',
  LANG_EXTENDED_CONTENT_1: 'Sosyal medya, web, e-posta ve mobil için sınırsız görüntüleme.',
  LANG_EXTENDED_CONTENT_2: 'Sınırsız sayıda baskı.',
  LANG_EXTENDED_CONTENT_3: 'Satışa sunulan ürünlerde kullanılabilir.',
  LANG_ORIGINAL_IMAGE: 'Orijinal görüntü',
  LANG_IMPORTANT_NOTE: 'Önemli not',
  LANG_AI_ORIGINAL_ALTERED: 'AI varyasyonları, orijinal görüntünün AI teknolojisi kullanılarak değiştirilmiş varyantlarıdır. Bu, bir yapay zeka editörünün seçtiğiniz görsele rötuş yapmasına benzer.',
  LANG_GENERATE_VARIATIONS_NOTE: 'Şu anda Yapay Zeka destekli Görsel Varyasyonlarının Beta aşamasında olduğumuz için, oluşturulan görsel(ler) beklenmedik sonuçlar üretebilir. Bu tür sonuçların ve varyasyonların etik ve sorumlu AI yönergelerine uygun olmasını sağlamak için çok çalışıyoruz.',
  LANG_LICENSE_AI_EDITED: 'Bu yapay zeka ile düzenlenmiş versiyonlardan birini lisansladığınızda, orijinal görselin telif hakkı sahibine bir lisans ücreti ödenir.',
  LANG_MOOD: 'Ruh hali',
  LANG_AI_EXCLUDE_PLACEHOLDER: 'Örn. yeşil renk, düz saç',
  LANG_SIMILAR_STOCK: 'Benzer Stok Görseller',
  LANG_PREMIUM_TAG_TOOLTIP: 'PREMIUM subscription only',

  WITH_MODEL_RELEASE: 'Model İzinli',
  WITH_PROPERTY_RELEASE: 'Mülkiyet İzinli',
  MODEL_RELEASE_CONTENT: 'Model izni, fotoğraftaki model ve kişilerin imzaladığı ve bu fotoğrafın veya yakın halinin basılabileceği, dağıtılabileceği ve değişik şekillerde kullanılabileceğini ifade eden hukuksal bir dökümandır.',
  PROPERTY_RELEASE_CONTENT: 'Mülkiyet izni, fotoğraflanmış obje, konu, bina, mekan vb. fotoğraflanan şeyin sahibi tarafından imzalanan ve bu fotoğrafın basılabileceği, dağıtılabileceği ve değişik şekil ve formlarda kullanılabileceğini ifade eden hukuksal bir dökümandır.',

  variousStockGAI: 'Milyonlarca stok fotoğrafla eğitilmiş ve kurumsal ihtiyaçlar için optimize edilmiş yasal olarak uyumlu bir AI modeli, sorumluluk kapsamı ile gönül rahatlığı sağlar.',
  extendedLicense: 'Genişletilmiş Lisans',
  sizes: 'Bedenler',

  downloadMail1st: 'Resim Büyütme Hizmeti sağlayıcımız, bir sonraki iş günü içinde büyütülmüş son dosyayı indirmeniz için gereken talimatları',
  downloadMail2nd: 'adresinden size e-posta ile gönderecektir',

  aiGenarated: 'Yapay zeka tarafından oluşturulan görüntü',

  variousOpenJourney: "AI Model, Midjourney'in kendine özgü tarzına benzeyen sanat eserleri üretir.",
  variousTurboVision: '"Normal" XL çözünürlüklerde yüksek hızlı üretim, genel kaliteyi artırır.',
  variousRealVisXL: 'Model, hassasiyet ve doğrulukla fotogerçekçi sonuçlar elde etmeyi hedefler.',
  variousPortraitPlus: 'Tek tip portre kompozisyonuna sahip model, 1:1 en boy oranı için ideal.',
  enterVirifiedCode: 'Doğrulama kodunu girin',
  verify: 'Doğrulama',
  didNotGetCode: 'Kod alamadınız mı?',
  clickToResend: 'Yeniden göndermek için tıklayın',
  otpCannotEmpty: 'OTP boş olamaz.',
  sentCodeToMail: 'Kayıtlı e-postanıza bir kod gönderdik.',
  invalidCode: 'Geçersiz kod.',
  expiredOTP: 'OTP süresi doldu, lütfen tekrar gönderin.',
  sendEmail: 'E-posta başarıyla gönderildi, devam etmek için lütfen posta kutunuzu kontrol edin.',
  remainingTime: 'Kalan süre: ',
};

export default tr;
