const IcWarning = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.99997 14.2222C11.431 14.2222 14.2222 11.431 14.2222 8.00001C14.2222 4.56902 11.431 1.77779 7.99997 1.77779C4.56898 1.77779 1.77775 4.56902 1.77775 8.00001C1.77775 11.431 4.56898 14.2222 7.99997 14.2222ZM8.56563 9.13133H7.43432V4.60607H8.56563V9.13133ZM8.56563 11.394H7.43432V10.2626H8.56563V11.394Z"
      fill="#E91D35"
    />
  </svg>
);

export default IcWarning;
