/* eslint-disable no-unused-vars */
/* eslint-disable no-plusplus */
import React, { useContext, useState } from 'react';
import { getTransText } from 'common/translate';
import { Button, Layout, Form } from 'antd';
import Axios from 'src/services/Axios/Axios';
import {
  API_ROUTES, LANG, SUCCESSFUL, DISPLAY_NONE,
  NOTI_READ_COLOR, STYLE_UNSET,
} from 'constants/index';
import PropTypes from 'prop-types';
import * as cookies from 'helpers/cookies';
import Router from 'next/router';
import { ROUTES } from 'constants/routes';
import MainContext from 'stores/context/MainContexts';

export const listNotifications = (notificationsMessage, isReadAll) => {
  const var1 = '[{VAR1}]';
  const receiverName = '[{RECEIVER}]';
  // language
  const lang = cookies.getCookies(LANG);
  return (
    <>
      {Object.values(notificationsMessage).map((item, index) => (
        <>
          <div className="notification-text" key={index?.toString()}>
            {item.isNew ? (
              <Form.Item key={index?.toString()} className={`form-noti list ${isReadAll ? 'read-all' : ''}`}>
                <>
                  <div className="content-notifi">
                    <div className="title-notifi">
                      <b key={index.toString()}>{`${item.sender} `}</b>
                      {(item.message).replace(var1, item.var1).replace(receiverName, item.receiver)}
                    </div>
                    <div className="time-history">
                      {lang === 'cz' ? `${item.ago} ${item.timeValue} ${item.timeContent}` : `${item.timeValue} ${item.timeContent} ${item.ago}`}
                    </div>
                  </div>
                  <div className={`form-message-list ${isReadAll ? 'read-all' : ''}`} key={index?.toString()}>.</div>
                </>
              </Form.Item>
            ) : (
              <Form.Item key={index?.toString()} className="form-noti">
                <div className="content-notifi read">
                  <div className="title-notifi read">
                    <b key={index.toString()}>{`${item.sender} `}</b>
                    {(item.message).replace(var1, item.var1).replace(receiverName, item.receiver)}
                  </div>
                  <div className="time-history ml-5">
                    {lang === 'cz' ? `${item.ago} ${item.timeValue} ${item.timeContent}` : `${item.timeValue} ${item.timeContent} ${item.ago}`}
                  </div>
                </div>
              </Form.Item>
            )}
          </div>
        </>
      ))}
    </>
  );
};

const NotificationList = ({ notifications, isNewNoti, newNotiMessage }) => {
  const idNoti = [];
  Object.keys(notifications).forEach((key) => {
    idNoti.push(notifications[key]?.id);
  });
  const [isReadAll, setIsReadAll] = useState(false);
  const { actionsUser } = useContext(MainContext);
  // read and clear notification
  const readAll = async () => {
    await Axios.put(API_ROUTES.CLEAR_NOTI_LIST, {
      notification_id: idNoti,
    }, (status, data) => {
      if (data.status === SUCCESSFUL) {
        newNotiMessage(false);
        setIsReadAll(true);
      }
    });
  };
  // const pushNotiPage = () => {
  //   Router.push(ROUTES.NOTIFICATION);
  // };
  return (
    <Layout className="dropdown-noti-list">
      <div className="body-notifi">
        <div className="notifi">
          <span className="notification">{getTransText('notification')}</span>
          <Button className="read-noti" disabled={!isNewNoti} onClick={readAll}>{getTransText('readAll')}</Button>
        </div>
        <div className="notification-list">
          {listNotifications(notifications, isReadAll)}
        </div>
        {/* <Button className="btn-view-all">
          {getTransText('viewAll')}
        </Button> */}
      </div>
    </Layout>
  );
};

NotificationList.propTypes = {
  notifications: PropTypes.arrayOf(PropTypes.any),
  isNewNoti: PropTypes.bool,
  newNotiMessage: PropTypes.func,
};

NotificationList.defaultProps = {
  notifications: [],
  isNewNoti: false,
  newNotiMessage: null,
};
export default NotificationList;
