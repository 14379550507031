import PropTypes from 'prop-types';
import { ICON_COLOR_DEFAULT } from 'constants/index';

const IcCustomizedPortal = ({
  fillcolor,
  width,
  height,
}) => (
  <svg width={width} height={height} viewBox="0 0 24 24.04" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M13.125 7.92573L16.625 11.4318L7.875 20.197L3.5 21.0735L4.375 16.6909L13.125 7.92573ZM20.125 4.41964C21.0912 5.38786 21.0913 6.95682 20.1255 7.92524L20.125 7.92573L18.375 9.67877L16.6348 7.93553C16.6316 7.93228 16.6283 7.92901 16.625 7.92573L16.615 7.91569L14.875 6.17269L16.6152 4.4295C16.6185 4.42621 16.6217 4.42292 16.625 4.41964C17.5913 3.45126 19.1587 3.45126 20.125 4.41964Z" fill={fillcolor} />
  </svg>

);

IcCustomizedPortal.propTypes = {
  fillcolor: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

IcCustomizedPortal.defaultProps = {
  fillcolor: ICON_COLOR_DEFAULT,
  width: '24',
  height: '24.04',
};
export default IcCustomizedPortal;
