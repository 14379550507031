/* eslint-disable consistent-return */
import * as types from './constants';

export const initState = {
  loading: false,
  click: 0,
  recommendedImagesLoading: false,
  recommendedFootageLoading: false,
  recommendedImages: [],
  recommendedFootage: [],
  getSettings: [],
  cloudFrontViewCountry: '',
  defaultLb: null,
  displayBtn: false,
};

export const reducer = (state = initState, action) => {
  switch (action.type) {
    case types.SET_LOADING:
      return {
        ...state,
        loading: true,
      };
    case types.SET_CLICK:
      return {
        ...state,
        loading: true,
        click: action.payload,
      };
    case types.SET_SETTING:
      return {
        ...state,
        loadingSettings: false,
        getSettings: action.payload,
      };
    case types.SET_SETTING_LOADING:
      return {
        ...state,
        loadingSettings: true,
      };
    case types.SET_RECOMMENDED_IMAGES:
      return {
        ...state,
        recommendedImages: action.payload,
        recommendedImagesLoading: false,
      };
    case types.SET_RECOMMENDED_LOADING_IMAGES:
      return {
        ...state,
        recommendedImagesLoading: true,
      };
    case types.SET_RECOMMENDED_FOOTAGE:
      return {
        ...state,
        recommendedFootage: action.payload,
        recommendedFootageLoading: false,
      };
    case types.SET_RECOMMENDED_LOADING_FOOTAGE:
      return {
        ...state,
        recommendedFootageLoading: true,
      };
    case types.SET_DEFAULT_LIKEBOX:
      return {
        ...state,
        defaultLb: action.payload.data,
      };
    case types.SET_DISPLAY_BTN:
      return {
        ...state,
        displayBtn: action.payload.data,
      };
    default:
      break;
  }
};
