import PropTypes from 'prop-types';
import { ICON_COLOR_DEFAULT } from 'constants/index';

const Ic123RF = ({
  fillcolor,
  width,
  height,
}) => (
  <>
    <svg width={width} height={height} viewBox="0 0 82 65" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M25.3731 31.4818C25.3787 28.4523 26.2833 25.4925 27.9726 22.9765C29.6618 20.4604 32.0599 18.5012 34.8637 17.3463C37.6674 16.1915 40.751 15.893 43.7246 16.4885C46.6982 17.0839 49.4282 18.5467 51.5697 20.6919C53.7113 22.837 55.168 25.5682 55.7559 28.5403C56.3438 31.5123 56.0364 34.5917 54.8726 37.3893C53.7088 40.1869 51.7408 42.577 49.2174 44.2575C46.6941 45.9379 43.7286 46.8334 40.6958 46.8306C38.68 46.8306 36.6841 46.4333 34.8223 45.6614C32.9604 44.8896 31.2694 43.7583 29.846 42.3325C28.4226 40.9067 27.2948 39.2143 26.5273 37.3523C25.7597 35.4904 25.3675 33.4955 25.3731 31.4818Z" fill={fillcolor} />
      <path d="M72.5722 14.1691C70.2518 13.1232 69.8981 9.99975 69.8981 9.97148V9.70295C68.3559 2.39603 62.8239 0.346694 59.9942 0.290161H21.9916C19.1619 0.290161 13.6723 2.39603 12.0877 9.73122V9.97148C11.9813 10.8502 11.6697 11.6916 11.1782 12.428C10.6868 13.1645 10.029 13.7753 9.25804 14.2115C2.12725 17.024 0.570923 20.84 0.570923 23.5677V55.6786C0.574665 58.0727 1.52838 60.3677 3.22305 62.0605C4.91773 63.7534 7.21512 64.7061 9.61175 64.7098H72.4165C74.8094 64.7023 77.1017 63.7475 78.7911 62.0547C80.4804 60.3618 81.4291 58.069 81.4291 55.6786V23.5677C81.4291 20.84 79.8727 17.024 72.5722 14.1691ZM77.2411 55.6786C77.2467 56.3171 77.1264 56.9504 76.8869 57.5424C76.6475 58.1345 76.2937 58.6736 75.8457 59.129C75.3977 59.5844 74.8642 59.9472 74.2759 60.1966C73.6875 60.4461 73.0557 60.5774 72.4165 60.5829H64.7481C62.8239 56.5408 57.1645 51.481 53.8963 51.481H27.9481C24.694 51.481 19.0487 56.5408 17.1246 60.5546H9.61175C8.33078 60.536 7.1088 60.0137 6.21089 59.1009C5.31299 58.188 4.81158 56.9584 4.81544 55.6786V23.5677C4.81544 20.9813 8.16862 19.144 11.0124 18.0557C12.4295 17.3284 13.6448 16.2629 14.5506 14.9537C15.4564 13.6445 16.0247 12.1322 16.2049 10.551C17.4358 4.81282 21.5955 4.45949 22.0482 4.43122H59.9942C60.4045 4.43122 64.55 4.81282 65.7951 10.551C65.9656 12.126 66.5273 13.6336 67.4292 14.9368C68.331 16.24 69.5444 17.2975 70.9592 18.0133C73.888 19.144 77.227 20.9813 77.227 23.5677L77.2411 55.6786Z" fill={fillcolor} />
    </svg>
  </>
);
Ic123RF.propTypes = {
  fillcolor: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

Ic123RF.defaultProps = {
  fillcolor: ICON_COLOR_DEFAULT,
  width: '30',
  height: '30',
};
export default Ic123RF;
