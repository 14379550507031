import {
  Form,
} from 'antd';
import Text from 'antd/lib/typography/Text';
import { getTransText } from 'common/translate';

const BrowsersNotSupported = () => (
  <>
    <div className="index-bg" />
    <div className="fullscreen-layout banner-box text-center">
      <Form
        className="banner box-shadow-form"
        layout="vertical"
      >
        <div className="first-title text-center">{getTransText('usingOlderBrowsers')}</div>
        <div className="second-title text-center">
          <p className="line-distance">{getTransText('upgradeBrowser')}</p>
          <p className="line-distance">{getTransText('clickToDownload')}</p>
        </div>
        <div className="third-title mb-10 mt-4">
          <div className="browser-img">
            <a href="https://www.google.com/chrome/" target="_blank" rel="noreferrer">
              <img src="https://www.google.com/chrome/static/images/chrome-logo.svg" alt="Chrome" />
              <Text className="browser-link">{getTransText('googleChrome')}</Text>
            </a>
          </div>
          <div className="browser-img text-center">
            <a href="https://www.mozilla.org/" target="_blank" rel="noreferrer">
              <img src="https://www.mozilla.org/media/protocol/img/logos/firefox/browser/logo.eb1324e44442.svg" alt="FireFox" />
              <Text className="browser-link text-center">{getTransText('mozillaFirefox')}</Text>
            </a>
          </div>
          <div className="browser-img text-center">
            <a href="https://www.microsoft.com/edge" target="_blank" rel="noreferrer">
              <img src="https://img-prod-cms-rt-microsoft-com.akamaized.net/cms/api/am/imageFileData/RE4nqTh" alt="Edge" />
              <Text className="browser-link text-center">{getTransText('microsoftEdge')}</Text>
            </a>
          </div>
          <div className="browser-img text-center">
            <a href="https://www.apple.com/safari/" target="_blank" rel="noreferrer">
              <img src="https://km.support.apple.com/resources/sites/APPLE/content/live/IMAGES/0/IM26/en_US/safari-120.png" alt="Safari" />
              <Text className="browser-link text-center">{getTransText('safari')}</Text>
            </a>
          </div>
        </div>
      </Form>
    </div>
  </>
);

export default BrowsersNotSupported;
