/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
import { Button, Skeleton } from 'antd';
import { getTransText } from 'common/translate';
import IcDownloadAudio from 'components/icons/Audio/IcDownloadAudio';
import IcNextAudio from 'components/icons/Audio/IcNextAudio';
import IcPauseAudio from 'components/icons/Audio/IcPauseAudio';
import IcPlayAudio from 'components/icons/Audio/IcPlayAudio';
import IcPrevAudio from 'components/icons/Audio/IcPrevAudio';
import {
  ACCESS_TOKEN,
  API_ROUTES,
  MAIN_COLOR,
  ROUTES,
  ROUTES_DISPLAY_STICKY_AUDIO,
  WHITE_COLOR,
  WIDTH_MOBILE,
  WIDTH_SMALL_DESKTOP,
  WIDTH_TABLET,
} from 'constants/index';
import React, {
  useCallback, useContext, useEffect, useMemo, useRef, useState,
} from 'react';
import * as cookie from 'helpers/cookies';
import Axios from 'services/Axios/Axios';
import MainContext from 'stores/context/MainContexts';
import IcLoadingCircle from 'components/icons/Page/IcLoadingCircle';
import PropTypes from 'prop-types';
import IcUnMuted from 'components/icons/Audio/IcUnMuted';
import IcCopyLink from 'components/icons/Audio/IcCopyLink';
import IcMuted from 'components/icons/Audio/IcMuted';
import { formatTime, handleCopyLink, openSuccessNotification } from 'common/common';
import MessagePopup from 'components/molecules/DefaultCollection/MessagePopup';
import IconAdd from 'components/molecules/DefaultCollection/IconAdd';
import { useRouter } from 'next/router';
import DropdownCollection from 'components/molecules/DefaultCollection/DropdownCollection';
import PopUpMessageForCollectionsCreated from 'components/pages/AuthorizedPages/Dashboard/MyCollection/MyCollectionMessage/PopUpMessageForCollectionsCreated';
import { getStorage } from 'helpers/storage';
import IcHeart from 'components/icons/IcHeart';
import Link from 'next/link';
import LinkLegacy from '../LinkLegacy/LinkLegacy';

const formWaveSurferOptions = (ref) => ({
  container: ref,
  waveColor: '#595959',
  progressColor: '#FFC54D',
  barWidth: 0,
  barRadius: 3,
  height: 37,
  normalize: true,
  hideScrollbar: true,
  responsive: true,
  cursorWidth: 0,
});

const StickyAudio = ({ sticky, openDownloadModal }) => {
  const {
    initDataSearchResultProvider,
    actionsSearchResult, actionsUser,
  } = useContext(MainContext);
  const isCN = getStorage('getSetting')?.country === 'CN';
  const {
    currentPlaying,
    playStatus,
    loadingAudio,
    firstRenderSticky,
    isFilterBarVisible,
    isPlayed,
    currentProgress,
    headerAudio,
  } = initDataSearchResultProvider;
  const abortControllerRef = useRef(null);
  const listAudioPlay = initDataSearchResultProvider?.listAudioPlay?.data;
  const accessToken = cookie.getCookies(ACCESS_TOKEN);
  const [currentSeconds, setCurrentSeconds] = useState(0);
  const [hiddenMutedBtn, setHiddenMutedBtn] = useState(false);
  const [displayProgress, setDisplayProgress] = useState(false);
  const [isDetailPage, setIsDetailPage] = useState(false);
  const [displayThreeDots, setDisplayThreeDots] = useState(false);
  const [percent, setPercent] = useState(0);
  const [mutedStatus, setMutedStatus] = useState(false);
  const [selectedLB, setSelectedLB] = useState({});
  const [likedAudios, setLikedAudios] = useState([]);
  const waveformStickyRef = useRef(null);
  const wavesurferSticky = useRef(null);
  const router = useRouter();
  const copyLinkBtn = useRef(null);
  const handleContributerPortfolio = (e) => {
    if (isCN) return;
    e.preventDefault();
    actionsUser.setTypeTabPortfolio('audio');
    router.push(
      `/portfolio?contributorid=${currentPlaying?.data?.contributor}&page=1&lang=${
        router.query?.lang ? router.query.lang : 'en'
      }`,
    );
  };
  const create = async () => {
    const WaveSurfer = (await import('wavesurfer.js')).default;
    const options = formWaveSurferOptions(waveformStickyRef.current);
    wavesurferSticky.current = WaveSurfer.create(options);

    await wavesurferSticky.current.load(
      currentPlaying?.data?.src ?? currentPlaying?.data?.audiopreview,
    );
    await wavesurferSticky.current.setMute(mutedStatus);
    return wavesurferSticky.current;
  };

  const iconLike = useMemo(
    () => ({ isAdded }) => <IcHeart fillColor="#FFFFFF" solid={isAdded} solidFillColor="#FFD44D" />,
    [],
  );

  const createMinimap = async (audioId) => {
    if (!wavesurferSticky?.current?.initialisedPluginList?.minimap) {
      const MinimapPlugin = (await import('wavesurfer.js/src/plugin/minimap')).default;
      if (document.querySelector(`#waveform-${audioId}`)) {
        wavesurferSticky?.current
          ?.addPlugin(
            MinimapPlugin.create({
              container: `#waveform-${audioId}`,
              waveColor: isDetailPage ? '#595959' : '#e8e8e8',
              progressColor: '#FFC54D',
              barWidth: 0,
              barRadius: 3,
              height: 100,
              normalize: true,
              hideScrollbar: true,
              responsive: true,
              cursorWidth: 0,
            }),
          )
          .initPlugin('minimap');
      }
    }
  };

  // Handle action in sticky audio
  const callbackPlaying = (action = undefined) => {
    if (firstRenderSticky) {
      actionsSearchResult.setFirstRenderSticky(false);
    }
    if (!action) {
      actionsSearchResult.setCurrentStickyAudioPlay({
        audioId: currentPlaying?.audioid,
      });
      actionsSearchResult.setCurrentProgress(currentSeconds);
      actionsSearchResult.setPlayStatus(!playStatus);
      wavesurferSticky?.current?.playPause();
    } else {
      wavesurferSticky?.current?.stop();
      const i = listAudioPlay?.indexOf(currentPlaying?.audioid.toString());
      if (i === -1 && !isDetailPage) {
        actionsSearchResult.setCurrentStickyAudioPlay({
          audioId: parseInt(listAudioPlay[0], 10),
        });
        actionsSearchResult.setCurrentProgress(currentSeconds);
      } else if (action === 'next') {
        // Handle next track button
        actionsSearchResult.setCurrentStickyAudioPlay({
          audioId: parseInt(listAudioPlay[i === listAudioPlay.length - 1 ? 0 : i + 1], 10),
          prevAudioId: currentPlaying?.audioid,
        });
      } else if (action === 'prev') {
        // Handle previous track button
        actionsSearchResult.setCurrentStickyAudioPlay({
          audioId: parseInt(listAudioPlay[(i === 0 ? listAudioPlay.length : i) - 1], 10),
          prevAudioId: currentPlaying?.audioid,
        });
      }
    }
  };

  // Handle event for muted unmted button
  const handleMutedAudio = () => {
    wavesurferSticky?.current?.toggleMute();
    setMutedStatus(wavesurferSticky?.current?.getMute());
  };

  // For create waveform of sticky
  useEffect(() => {
    let removeAudioProcess;
    let removeOnReady;
    let removeOnSeek;
    let removeOnFinish;
    let wavesufer;
    const createWaveSurfer = async () => {
      wavesufer = await create();
      removeAudioProcess = wavesufer?.on('audioprocess', () => {
        if (!wavesurferSticky?.current?.isDestroyed) {
          setPercent(
            (wavesurferSticky?.current?.getCurrentTime()
              / wavesurferSticky?.current?.getDuration())
              * 100,
          );
        }
        if (!wavesurferSticky?.current?.isDestroyed) {
          setCurrentSeconds(parseInt(wavesurferSticky?.current?.getCurrentTime(), 10));
        }
      })?.un;
      removeOnReady = wavesufer?.on('ready', () => {
        actionsSearchResult.setLoadingAudio(false);
        if (!firstRenderSticky) {
          actionsSearchResult.setPlayStatus(true);
          wavesurferSticky?.current?.play();
        }
      })?.un;
      removeOnSeek = wavesufer?.on('seek', () => {
        setPercent(
          (wavesurferSticky?.current?.getCurrentTime() / wavesurferSticky?.current?.getDuration())
            * 100,
        );
        setCurrentSeconds(parseInt(wavesurferSticky?.current?.getCurrentTime(), 10));
      })?.un;
      removeOnFinish = wavesufer?.on('finish', () => {
        wavesurferSticky?.current?.stop();
        setPercent(0);
        setCurrentSeconds(0);
        actionsSearchResult.setPlayStatus(false);
      })?.un;

      await actionsSearchResult.setCurrentStickyAudioPlay({ audioId: currentPlaying?.audioid });
      await createMinimap(currentPlaying?.audioid);
    };
    if (currentPlaying?.audioid) {
      setCurrentSeconds(0);
      actionsSearchResult.setLoadingAudio(true);
      createWaveSurfer();
    }

    return () => {
      removeAudioProcess?.();
      removeOnReady?.();
      removeOnSeek?.();
      removeOnFinish?.();
      wavesufer?.destroy();
    };
  }, [currentPlaying?.audioid]);

  // Set content loading to audio sticky

  // Check if sticky is in audio detail page
  useEffect(() => {
    setIsDetailPage(router.pathname === ROUTES.DETAIL_AUDIO_PAGE);
    if (currentProgress) actionsSearchResult.setIsPlayed(true);
    else actionsSearchResult.setIsPlayed(false);
  }, [router?.pathname]);

  // Change the status of the play button
  useEffect(() => {
    if (playStatus) {
      if (wavesurferSticky?.current?.isReady) wavesurferSticky?.current?.play();
    } else if (wavesurferSticky?.current?.isReady) wavesurferSticky?.current?.pause();
  }, [playStatus]);

  // For create the minimap of sticky
  useEffect(() => {
    if (wavesurferSticky?.current?.initialisedPluginList?.minimap) {
      wavesurferSticky?.current?.destroyPlugin('minimap');
    }
    if (
      listAudioPlay?.includes(currentPlaying?.audioid?.toString())
      || listAudioPlay?.includes(currentPlaying?.audioid)
    ) {
      createMinimap(currentPlaying?.audioid);
    }
  }, [listAudioPlay]);

  // Detect the width of screen to display the progress bar of the audio
  const onResizeSticky = () => {
    setHiddenMutedBtn(window?.innerWidth < WIDTH_TABLET);
    setDisplayProgress(window?.innerWidth <= WIDTH_MOBILE);
    setDisplayThreeDots(window?.innerWidth < WIDTH_SMALL_DESKTOP);
  };

  useEffect(() => {
    if (+currentPlaying?.audioid === +router.query?.audioid) {
      if (wavesurferSticky?.current?.initialisedPluginList?.minimap) {
        wavesurferSticky?.current?.destroyPlugin('minimap');
      }
      createMinimap(currentPlaying?.audioid);
    } else if (wavesurferSticky?.current?.initialisedPluginList?.minimap) {
      wavesurferSticky?.current?.destroyPlugin('minimap');
    }
  }, [headerAudio, router?.query?.audioid]);

  useEffect(() => {
    actionsSearchResult.setCurrentProgress(currentSeconds);
  }, [currentSeconds]);

  useEffect(() => {
    onResizeSticky();
    window?.addEventListener('resize', onResizeSticky);
    return () => {
      window?.removeEventListener('resize', onResizeSticky);
    };
  }, []);

  // Redraw minimap when open or close filter bar
  useEffect(() => {
    if (
      !wavesurferSticky?.current?.isDestroyed
      && wavesurferSticky?.current?.initialisedPluginList?.minimap
    ) {
      wavesurferSticky?.current?.destroyPlugin('minimap');
      createMinimap(currentPlaying?.audioid);
    }
  }, [isFilterBarVisible]);

  // For add to likebox button
  const [isAdd, setIsAdd] = useState(false);
  const [isRemove, setIsRemove] = useState(false);
  const [idImageAdd, setIdImageAdd] = useState('');
  const onChangeAdd = useCallback((add, imageIdAdd) => {
    setIsAdd(add);
    setIdImageAdd(imageIdAdd);
  }, []);
  const onChangeRemove = useCallback((remove, imageIdAdd) => {
    setIsRemove(remove);
    setIdImageAdd(imageIdAdd);
  }, []);
  const actionLikeboxRef = useRef(null);
  const undoAdded = () => {
    setIsAdd(false);
    actionLikeboxRef?.current?.handleRemoveToLikebox(idImageAdd, false);
  };

  const undoRemoved = () => {
    setIsRemove(false);
    actionLikeboxRef?.current?.handleAddtoLikebox(idImageAdd, false);
  };
  const [defaultLB, setDefaultLB] = useState('');
  const [description, setDescription] = useState('');
  const getDefaultLikebox = useCallback((defaultName, imgDescription) => {
    setDefaultLB(defaultName);
    setDescription(imgDescription);
  }, []);

  const [isCreated, setIsCreated] = useState(false);
  const [likeboxName, setLBname] = useState('');

  const isLiked = likedAudios.includes(currentPlaying?.audioid);

  // Message Create Collection
  const onCreateNewLikebox = useCallback((created, lbName) => {
    setIsCreated(created);
    setLBname(lbName);
  }, []);
  const handleTimeout = (timeout) => {
    setIsCreated(timeout);
  };

  const audioid = useMemo(() => ({ audioid: currentPlaying?.audioid }), [currentPlaying?.audioid]);

  const isStickyHide = isDetailPage ? !isPlayed : false;

  if (isDetailPage) {
    const changedElement = document.getElementsByClassName('footer');
    if (isStickyHide) {
      changedElement[0].classList.remove('hasSticky');
    } else if (!changedElement[0].classList.contains('hasSticky')) {
      changedElement[0].classList.add('hasSticky');
    }
  }

  const getLikedAudios = async () => {
    const likeboxId = selectedLB.lightbox_id;
    let url = API_ROUTES.GET_LIKEBOX_IMAGES;

    if (likeboxId) {
      url += `?likebox_id=${likeboxId}`;
    }
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    abortControllerRef.current = new AbortController();
    const { signal } = abortControllerRef.current;

    Axios.getLastApiCall(url, (status, data) => {
      if (!signal.aborted) {
        setLikedAudios(data.content);
      }
    }, signal);
  };

  useEffect(() => {
    if (accessToken) {
      getLikedAudios();
    }
  }, [accessToken, selectedLB, selectedLB?.lightbox_id]);

  const setDefaultLikebox = (likeBox) => {
    if (!likeBox) return;
    setSelectedLB(likeBox);
    cookie.setCookie('lbId', likeBox.lightbox_id);
    if (likeBox.groupid) {
      cookie.setCookie('groupId', likeBox.groupid);
    } else {
      cookie.setCookie('groupId', 0);
    }
  };

  const onAddToLikebox = (imgDescription) => {
    setLikedAudios([...likedAudios, currentPlaying?.audioid]);
    setDescription(imgDescription || '');
  };

  const onRemoveFromLikebox = (imgDescription) => {
    setLikedAudios(likedAudios.filter((imageId) => imageId !== currentPlaying?.audioid));
    setDescription(imgDescription || '');
  };

  return (
    <>
      {(sticky
        || (currentPlaying?.audioid && ROUTES_DISPLAY_STICKY_AUDIO.includes(router.pathname))) && (
        <>
          <div
            className={`sticky-audio-container ${
              isStickyHide ? 'detail-audio hide-sticky-audio' : 'detail-audio'
            }`}
          >
            {!currentPlaying?.audioid ? (
              <Skeleton className="content-loading" active />
            ) : (
              <>
                {displayProgress && (
                  <div className="audio-wave-progress-wrapper">
                    <div className="audio-wave-progress" style={{ width: `${percent}%` }} />
                  </div>
                )}
                <div className="audio-wave-wrapper">
                  <div className="audio-wave" ref={waveformStickyRef} />
                </div>
                <div className="audio-control-wrapper">
                  <div className="player-wrapper">
                    <div className="player-control">
                      {!listAudioPlay
                      || listAudioPlay?.length < 2
                      || router?.pathname === ROUTES.DETAIL_AUDIO_PAGE ? (
                        <div className="player-btn-prev">
                          <IcPrevAudio fillcolor="#A3A3A3" />
                        </div>
                        ) : (
                          <div
                            className="player-btn-prev"
                            onClick={() => callbackPlaying('prev')}
                            role="button"
                            tabIndex="0"
                            aria-hidden="true"
                          >
                            <IcPrevAudio fillcolor={WHITE_COLOR} />
                          </div>
                        )}
                      <div
                        className="player-btn-play"
                        onClick={() => callbackPlaying()}
                        role="button"
                        tabIndex="0"
                        aria-hidden="true"
                      >
                        {loadingAudio ? (
                          <div className="loading-icon-add">
                            <IcLoadingCircle width="24" height="24" fillcolor={WHITE_COLOR} />
                          </div>
                        ) : !playStatus ? (
                          <IcPlayAudio fillcolor={WHITE_COLOR} />
                        ) : (
                          <IcPauseAudio fillcolor={WHITE_COLOR} />
                        )}
                      </div>
                      {!listAudioPlay
                      || listAudioPlay?.length < 2
                      || router?.pathname === ROUTES.DETAIL_AUDIO_PAGE ? (
                        <div className="player-btn-next">
                          <IcNextAudio fillcolor="#A3A3A3" />
                        </div>
                        ) : (
                          <div
                            className="player-btn-next"
                            onClick={() => callbackPlaying('next')}
                            role="button"
                            tabIndex="0"
                            aria-hidden="true"
                          >
                            <IcNextAudio fillcolor={WHITE_COLOR} />
                          </div>
                        )}
                    </div>
                    <div className="player-title">
                      <div className="sticky-audio-title">
                        {currentPlaying?.data?.desc ?? currentPlaying?.data?.description}
                      </div>
                      <div
                        className={`sticky-audio-contributor hover-text-link ${isCN && 'disable'}`}
                        role="button"
                        aria-hidden="true"
                        onClick={handleContributerPortfolio}
                      >
                        {currentPlaying?.data?.contributor}
                      </div>
                    </div>
                    <div className="player-duration">
                      <div className="sticky-audio-duration">
                        {formatTime(currentSeconds)}
                        /
                        {currentPlaying?.details?.duration.length === 8
                          ? currentPlaying?.details?.duration.substring(3)
                          : currentPlaying?.details?.duration}
                      </div>
                    </div>
                    <div className="player-option">
                      <DropdownCollection
                        imageId={currentPlaying?.audioid}
                        onCreateNewLikebox={onCreateNewLikebox}
                        threeDotsDisplay={displayThreeDots}
                        fillcolor={WHITE_COLOR}
                        onChangeAdd={onChangeAdd}
                        onChangeRemove={onChangeRemove}
                        description={
                          currentPlaying?.data?.desc ?? currentPlaying?.data?.description
                        }
                        dataClone={initDataSearchResultProvider?.dataAudio}
                        setDefaultLikebox={setDefaultLikebox}
                        selectedLB={selectedLB}
                      />
                      {!hiddenMutedBtn && (
                        <div
                          className="sticky-audio-volume"
                          onClick={handleMutedAudio}
                          role="button"
                          tabIndex="0"
                          aria-hidden="true"
                        >
                          {mutedStatus ? (
                            <IcMuted width="24" height="24" fillcolor={WHITE_COLOR} />
                          ) : (
                            <IcUnMuted width="24" height="24" fillcolor={WHITE_COLOR} />
                          )}
                        </div>
                      )}
                      <IconAdd
                        image={audioid}
                        onChangeRemove={onChangeRemove}
                        onChangeAdd={onChangeAdd}
                        ref={actionLikeboxRef}
                        getDefaultLikebox={getDefaultLikebox}
                        description={
                          currentPlaying?.data?.desc ?? currentPlaying?.data?.description
                        }
                        width="24"
                        height="24"
                        stickyIconAdd
                        dataClone={initDataSearchResultProvider?.dataAudio}
                        iconLike={iconLike}
                        isLiked={isLiked}
                        onAddToLikebox={onAddToLikebox}
                        onRemoveFromLikebox={onRemoveFromLikebox}
                        selectedLB={selectedLB}
                      />
                      <div
                        className="sticky-audio-copy-link"
                        onClick={() => {
                          handleCopyLink(
                            `${window.location.origin}${ROUTES.DETAIL_AUDIO_PAGE}?audioid=${currentPlaying?.audioid}`,
                          );
                          openSuccessNotification(getTransText('copied'), copyLinkBtn.current);
                        }}
                        role="button"
                        tabIndex="0"
                        aria-hidden="true"
                        ref={copyLinkBtn}
                      >
                        <IcCopyLink width="24" height="24" fillcolor={WHITE_COLOR} />
                      </div>
                      <LinkLegacy href={`/new/audio_dlcomp.php?audioid=${currentPlaying?.audioid}`}>
                        <div className="sticky-audio-download-preview">
                          <IcDownloadAudio width="24" height="24" fillcolor={MAIN_COLOR} />
                          <span className="download-preview-text">{getTransText('preview')}</span>
                        </div>
                      </LinkLegacy>
                      {router.pathname === ROUTES.DETAIL_AUDIO_PAGE
                      && +router?.query?.audioid === +audioid?.audioid ? (
                        <Button onClick={openDownloadModal} className="sticky-audio-license">
                          {getTransText('download')}
                        </Button>
                        ) : (
                          <Link
                            href={`${ROUTES.DETAIL_AUDIO_PAGE}?audioid=${currentPlaying?.audioid}&lang=${router?.query?.lang}`}
                          >
                            <div className="sticky-audio-license">{getTransText('download')}</div>
                          </Link>
                        )}
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          <MessagePopup
            defaultLB={defaultLB}
            description={description}
            undoAdded={undoAdded}
            undoRemoved={undoRemoved}
            isAdd={isAdd}
            isRemove={isRemove}
            onChangeAdd={onChangeAdd}
            onChangeRemove={onChangeRemove}
          />
        </>
      )}
      <PopUpMessageForCollectionsCreated
        isCreated={isCreated}
        likeboxName={likeboxName}
        handleTimeout={handleTimeout}
      />
    </>
  );
};
StickyAudio.propTypes = {
  sticky: PropTypes.bool,
  openDownloadModal: PropTypes.func,
};

StickyAudio.defaultProps = {
  sticky: false,
  openDownloadModal: () => {},
};
export default StickyAudio;
