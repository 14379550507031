import React from 'react';
import PropTypes from 'prop-types';
import { MAIN_COLOR } from 'constants/index';

const IcEllipse = ({ fillcolor }) => (
  <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="5" cy="5.3125" r="5" fill={fillcolor} />
  </svg>
);
IcEllipse.propTypes = {
  fillcolor: PropTypes.string,
};
IcEllipse.defaultProps = {
  fillcolor: MAIN_COLOR,
};
export default IcEllipse;
