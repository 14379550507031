/* eslint-disable react/forbid-prop-types */
import { DownOutlined } from '@ant-design/icons';
import { Dropdown } from 'antd';
import { getTransText } from 'common/translate';
import {
  ACCESS_TOKEN,
  SHARED_SESSION,
  WHITE_COLOR,
  WIDTH_MOBILE,
} from 'constants/index';
import { ROUTES_DISPLAY_STICKY_MY_COLLECTION } from 'constants/routes';
import React, {
  memo, useContext, useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import ThreeDots from 'components/icons/ThreeDots';
import { useRouter } from 'next/router';
import { isEmpty } from 'lodash';
import * as cookie from 'helpers/cookies';
import UseWindowWidth from 'helpers/CustomHooks/useWindowWidth';
import MainContext from 'stores/context/MainContexts';
import Collection from './Collection';
import IcArrowLeft from '../../icons/MyCollection/IcArrowLeft';

const DropdownCollection = memo(
  ({
    imageId,
    sizeImage,
    onCreateNewLikebox,
    fillcolor,
    threeDotsDisplay,
    audioDetail,
    onChangeAdd,
    onChangeRemove,
    setDefaultLikebox,
    description,
    dataClone,
    isDetailCollection,
    haveBackBtn,
    containerWidth,
    iconArrow,
    selectedLB,
  }) => {
    const {
      initDataUserProvider,
    } = useContext(MainContext);
    const dataCollection = initDataUserProvider?.likeboxDataAll;
    const likeboxGroups = dataCollection?.group;
    const likeboxData = dataCollection?.personal;
    const defaultGroupLB = parseInt(cookie.getCookies('groupId'), 10);
    const defaultLB = parseInt(cookie.getCookies('lbId'), 10);
    const [visible, setVisible] = useState(false);
    const [popupContainer, setPopupContainer] = useState(false);
    const router = useRouter();
    const IconArrow = iconArrow;
    const windowWidth = UseWindowWidth();
    const onChangeVisible = (isVisible) => {
      setVisible(isVisible);
    };

    const checkAuthentication = () => {
      const checkToken = cookie.getCookies(ACCESS_TOKEN);
      const checkLoginSession = cookie.getCookies(SHARED_SESSION);
      if (!checkToken || isEmpty(checkToken) || isEmpty(checkLoginSession)) {
        return false;
      }
      return true;
    };
    const searchAndSetDefaultLikebox = () => {
      let defaultLikebox = null;

      if (defaultGroupLB) {
        // Search in filteredLikeboxGroupData
        // eslint-disable-next-line max-len, no-shadow
        const group = likeboxGroups.find((group) => group.group_id === defaultGroupLB);

        if (group) {
          // eslint-disable-next-line radix
          defaultLikebox = group?.likebox.find((lb) => parseInt(lb.lb_id) === defaultLB);

          if (defaultLikebox) {
            defaultLikebox = {
              description: defaultLikebox.description,
              lightbox_id: defaultLikebox.lb_id,
              lightbox_name: defaultLikebox.lb_name,
              total_images: defaultLikebox.total_images,
              groupid: group.group_id,
            };
          }
        }
      } else {
        // Search in filteredLikeboxData
        defaultLikebox = likeboxData?.find((lb) => lb.lightbox_id === defaultLB);
      }

      if (defaultLikebox && setDefaultLikebox) {
        // eslint-disable-next-line no-unused-expressions
        setDefaultLikebox && setDefaultLikebox(defaultLikebox, description);
      } else {
        // eslint-disable-next-line max-len
        const firstLikeBox = likeboxData.sort((a, b) => new Date(b.datecreated) - new Date(a.datecreated))[0];
        // eslint-disable-next-line no-unused-expressions
        setDefaultLikebox && setDefaultLikebox(firstLikeBox, description);
      }
    };
    const onBtnBackClicked = () => {
      setVisible(false);
    };

    useEffect(() => {
      if (likeboxGroups && likeboxData) {
        searchAndSetDefaultLikebox();
      }
    }, [dataCollection]);

    useEffect(() => {
      if (ROUTES_DISPLAY_STICKY_MY_COLLECTION.includes(router.pathname) || isDetailCollection) {
        setPopupContainer(true);
      }
    }, []);

    return (
      <>
        <Dropdown
          transitionName={windowWidth <= WIDTH_MOBILE ? '' : undefined}
          overlay={
            checkAuthentication() ? (
              <Collection
                imageId={imageId}
                onChangeVisible={onChangeVisible}
                onCreateNewLikebox={onCreateNewLikebox}
                onChangeAdd={onChangeAdd}
                onChangeRemove={onChangeRemove}
                selectedLB={selectedLB}
                setDefaultLikebox={setDefaultLikebox}
                description={description}
                dataClone={dataClone}
                haveBackBtn={haveBackBtn}
                onBtnBackClicked={onBtnBackClicked}
                likeboxData={likeboxData}
                likeboxGroups={likeboxGroups}
              />
            ) : (
              <></>
            )
          }
          overlayClassName="dropdown-collection-overlay responsive-dropdown"
          trigger={['click']}
          placement="bottomCenter"
          // arrow
          visible={visible}
          onVisibleChange={(isVisible) => setVisible(isVisible)}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...(popupContainer && {
            getPopupContainer: (trigger) => trigger.parentElement.parentElement.parentElement,
          })}
        >
          {
            // check ratio and min max contaner width to display 3 dots
            parseFloat(sizeImage) <= 1 || (containerWidth && containerWidth < 310) ? (
              <div className="display-flex">
                <ThreeDots />
              </div>
            ) : (
              <div className="default-collection-wrapper">
                {typeof window !== 'undefined' && (
                  <>
                    {window?.innerWidth < 1200 && threeDotsDisplay ? (
                      <ThreeDots fillcolor={fillcolor ?? WHITE_COLOR} />
                    ) : (
                      <>
                        <div
                          className={`default-collection${visible ? ' open' : ''}${
                            audioDetail ? ' detail-page' : ''
                          }`}
                        >
                          {selectedLB?.lightbox_name ? selectedLB?.lightbox_name : getTransText('defaultCollection')}
                        </div>
                        {iconArrow ? (
                          <IconArrow fillColor="#212121" up={visible} />
                        ) : (
                          <>
                            {!isDetailCollection ? (
                              <DownOutlined className={audioDetail ? 'arrow-up-down' : ''} />
                            ) : (
                              <span className="detail-collection-icon">
                                <IcArrowLeft />
                              </span>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              </div>
            )
          }
        </Dropdown>
      </>
    );
  },
);
DropdownCollection.propTypes = {
  imageId: PropTypes.number,
  sizeImage: PropTypes.number,
  onCreateNewLikebox: PropTypes.func,
  setDefaultLikebox: PropTypes.func,
  fillcolor: PropTypes.string,
  threeDotsDisplay: PropTypes.bool,
  audioDetail: PropTypes.bool,
  selectedLB: PropTypes.any,
  onChangeAdd: PropTypes.func,
  onChangeRemove: PropTypes.func,
  getDefaultLikebox: PropTypes.func,
  description: PropTypes.string,
  dataClone: PropTypes.arrayOf(PropTypes.any),
  isDetailCollection: PropTypes.bool,
  haveBackBtn: PropTypes.bool,
  containerWidth: PropTypes.number,
  iconArrow: PropTypes.any,
};

DropdownCollection.defaultProps = {
  imageId: null,
  sizeImage: null,
  setDefaultLikebox: null,
  onCreateNewLikebox: null,
  fillcolor: null,
  threeDotsDisplay: false,
  audioDetail: false,
  onChangeAdd: null,
  onChangeRemove: null,
  getDefaultLikebox: null,
  description: '',
  selectedLB: {},
  dataClone: null,
  isDetailCollection: false,
  haveBackBtn: false,
  containerWidth: null,
  iconArrow: false,
};
export default DropdownCollection;
