import * as types from './constants';

// eslint-disable-next-line import/prefer-default-export
export const useUserActions = (state, dispatch) => ({
  getCreditSummary: (view) => {
    dispatch({
      type: types.GET_CREDIT_SUMMARY,
      payload: {
        view,
      },
    });
  },
  setCreditSummary: (data) => {
    dispatch({
      type: types.SET_CREDIT_SUMMARY,
      payload: {
        data,
      },
    });
  },
  setView: (view) => {
    dispatch({
      type: types.SET_CREDIT_SUMMARY,
      payload: {
        view,
      },
    });
  },
  getNotiList: (limitNoti) => {
    dispatch({
      type: types.GET_NOTI_LIST,
      payload: {
        limitNoti,
      },
    });
  },
  setNotiList: (data) => {
    dispatch({
      type: types.SET_NOTI_LIST,
      payload: {
        data,
      },
    });
  },
  getAgreeStatus: (actionGet) => {
    dispatch({
      type: types.GET_AGREE_STATUS,
      payload: {
        actionGet,
      },
    });
  },
  setAgreeStatus: (actionUpdate) => {
    dispatch({
      type: types.SET_AGREE_STATUS,
      payload: {
        actionUpdate,
      },
    });
  },
  getAllLikeBox: (data) => {
    dispatch({
      type: types.GET_ALL_LIKE_BOX,
      payload: {
        data,
      },
    });
  },
  setCollectionData: (data) => {
    dispatch({
      type: types.LIKE_BOX_COLLECTION,
      payload: {
        data,
      },
    });
  },
  getAllCollectionPer: (data) => {
    dispatch({
      type: types.GET_ALL_MY_COLLECTION,
      payload: {
        data,
      },
    });
  },
  setAllCollectionPer: (data) => {
    dispatch({
      type: types.SET_ALL_MY_COLLECTION,
      payload: {
        data,
      },
    });
  },
  delete: (data) => {
    dispatch({
      type: types.DELETE_ALL_MY_COLLECTION,
      payload: {
        data,
      },
    });
  },
  getDetailCollections: (data) => {
    dispatch({
      type: types.GET_DETAIL_COLLECTION,
      payload: {
        data,
      },
    });
  },
  setTypeTabPortfolio: (data) => {
    dispatch({
      type: types.SET_TYPE_TAB_PORTFOLIO,
      payload: {
        data,
      },
    });
  },
  setDashboardOption: (data) => {
    dispatch({
      type: types.SET_DASHBOARD_OPTION,
      payload: {
        data,
      },
    });
  },
  setUserDetails: (data) => {
    dispatch({
      type: types.SET_USER_DETAILS,
      payload: {
        data,
      },
    });
  },
  setDataUserFilterDlw: (data) => {
    dispatch({
      type: types.SET_DOWLOAD_HISTORY_FILTER_USER,
      payload: {
        data,
      },
    });
  },
  getDataAddedDefaultLb: (data) => {
    dispatch({
      type: types.GET_LIST_DATA_ADDED_DEFAULT_COLLECTION,
      payload: {
        data,
      },
    });
  },
  setImageIdCurrent: (data) => {
    dispatch({
      type: types.SET_IMAGE_ID,
      payload: {
        data,
      },
    });
  },
  setDataGeneralSetting: (data) => {
    dispatch({
      type: types.SET_DATA_GENERAL_SETTING,
      payload: {
        data,
      },
    });
  },
  setDataDlImageSetting: (data) => {
    dispatch({
      type: types.SET_DATA_DL_IMAGE_SETTING,
      payload: {
        data,
      },
    });
  },
  setDataDlFootageSetting: (data) => {
    dispatch({
      type: types.SET_DATA_DL_FOOTAGE_SETTING,
      payload: {
        data,
      },
    });
  },
  setDataDlAudioSetting: (data) => {
    dispatch({
      type: types.SET_DATA_DL_AUDIO_SETTING,
      payload: {
        data,
      },
    });
  },
  setAccessUserDashboard: (data) => {
    dispatch({
      type: types.SET_ACCESS_USER_DASHBOARD,
      payload: {
        data,
      },
    });
  },
});
