/* eslint-disable import/prefer-default-export */

import {
  SET_LOGO_CONFIG_PREVIEW,
  SET_BANNER_CONFIG,
  SET_BANNER_CONFIG_PREVIEW,
} from './constants';

export const useCustomizedLandingActions = (dispatch) => (
  {
    setBannerConfig(data) {
      dispatch({
        type: SET_BANNER_CONFIG,
        payload: data,
      });
    },
    setLogoConfigPreview(data) {
      dispatch({
        type: SET_LOGO_CONFIG_PREVIEW,
        payload: data,
      });
    },
    setBannerConfigPreview(data) {
      dispatch({
        type: SET_BANNER_CONFIG_PREVIEW,
        payload: data,
      });
    },
  }
);
