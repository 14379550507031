import React, { useEffect, useReducer, useState } from 'react';
import * as cookies from 'helpers/cookies';
import {
  LANG, CLIENT_COUNTRY, API_ROUTES, ACCESS_TOKEN, USER_RIGHT,
} from 'constants/index';
import { LANGUAGE_CODE_DEFAULT } from 'constants/intl';
import MainContexts from 'stores/context/MainContexts';
import PropTypes from 'prop-types';
import Axios from 'src/services/Axios/Axios';
// implement redux
import { useRouter } from 'next/router';
import { isEmpty } from 'lodash';
import { reducerCustomizedLanding, initDataCustomizedLanding } from 'stores/modules/customized/reducer';
import { useCustomizedLandingActions } from 'stores/modules/customized/action';
import * as store from 'helpers/storage';
import {
  initState, reducer,
} from '../modules/home/reducer';
import {
  reducerUser, initDataUser,
} from '../modules/user/reducer';

import {
  reducerSearchResult, initDataSearchResult,
} from '../modules/searchResult/reducer';

import { useActions } from '../modules/home/action';
import { applyMiddleware } from '../modules/home/middleware';
import { useUserActions } from '../modules/user/action';
import { applyUserMiddleware } from '../modules/user/middleware';
import { useSearchResultActions } from '../modules/searchResult/action';
import { applySearchResultMiddleware } from '../modules/searchResult/middleware';

const MainProvider = ({
  initServerState, cloudFrontViewCountry, children, sticky, setSticky,
}) => {
  const [loading, setLoading] = useState(false);
  const [footerContent, setFooterContent] = useState([]);
  // user profile menu header
  const [dataUserProfile, setdataUserProfile] = useState(null);
  // user credit menu header
  // redux for homepage
  const [initDataProvider, dispatchHome] = useReducer(reducer, {
    ...initState,
    browseCategory: initServerState?.homeData ?? [],
  });
  const router = useRouter();
  const [initDataUserProvider, dispatchDataUser] = useReducer(reducerUser, initDataUser);

  const [initDataSearchResultProvider,
    dispatchDataSearchResult] = useReducer(reducerSearchResult, initDataSearchResult);

  const [initDataCustomizedLandingProvider, dispatchDataCustomizedLangding] = useReducer(
    reducerCustomizedLanding, initDataCustomizedLanding,
  );

  // add country header if cloudfront exists
  if (cloudFrontViewCountry) {
    Axios.addHeader(CLIENT_COUNTRY, cloudFrontViewCountry);
  }
  const actions = useActions(initDataProvider, applyMiddleware(dispatchHome));

  const actionsUser = useUserActions(initDataUser, applyUserMiddleware(dispatchDataUser));

  const actionsSearchResult = useSearchResultActions(initDataSearchResult,
    applySearchResultMiddleware(dispatchDataSearchResult));

  const actionsCustomizedLanding = useCustomizedLandingActions(dispatchDataCustomizedLangding);

  const getContentFooter = async () => {
    const GetLang = cookies.getCookies(LANG);
    Axios.addHeader(LANG, GetLang);
    await Axios.get(API_ROUTES.MENU_FOOTER_CONTENT, (status, data) => {
      setFooterContent(data.content);
      setLoading(false);
    });
  };
  const getUserProfile = async () => {
    const lang = router?.query?.lang ?? cookies.getCookies(LANG) ?? LANGUAGE_CODE_DEFAULT;
    Axios.addHeader(LANG, lang);
    await Axios.get(API_ROUTES.GET_USER_PROFILE, (status, data) => {
      store.setStorage(USER_RIGHT, data.content.is_admin);
      setdataUserProfile(data.content);
      actionsUser.setUserDetails(data.content);
    });
  };
  // run when innit Provider and change language
  useEffect(() => {
    getContentFooter();
    setLoading(true);
  }, [cookies.getCookies(LANG)]);

  const accessToken = cookies.getCookies(ACCESS_TOKEN);
  useEffect(() => {
    if (accessToken) {
      actionsUser.getAllLikeBox();
      getUserProfile();
    }
  }, [accessToken]);

  /**
   * Action of sticky audio
   */
  useEffect(() => {
    if (!sticky) {
      actionsSearchResult?.resetInitialState();
    }
  }, [router.pathname]);

  useEffect(() => {
    if (!isEmpty(dataUserProfile)) {
      actions.setDefaultLb(dataUserProfile?.current_lightbox);
    }
  }, [dataUserProfile]);

  return (
    <MainContexts.Provider value={{
      getContentFooter,
      footerContent,
      loading,
      dataUserProfile,
      // init data for homePage
      actions,
      initDataProvider,
      // init data user
      actionsUser,
      initDataUserProvider,
      // init data search result page
      actionsSearchResult,
      initDataSearchResultProvider,
      // init data customized landing page
      actionsCustomizedLanding,
      initDataCustomizedLandingProvider,
      setSticky,
    }}
    >
      {children}
    </MainContexts.Provider>
  );
};

MainProvider.propTypes = {
  children: PropTypes.element,
  initServerState: PropTypes.objectOf(PropTypes.any),
  cloudFrontViewCountry: PropTypes.objectOf(PropTypes.any),
  sticky: PropTypes.bool,
  setSticky: PropTypes.func,
};

MainProvider.defaultProps = {
  children: null,
  initServerState: null,
  cloudFrontViewCountry: null,
  sticky: false,
  setSticky: () => {},
};
export default MainProvider;
