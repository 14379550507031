import PropTypes from 'prop-types';
import { ICON_COLOR_DEFAULT } from 'constants/index';

const IcAudio = ({
  fillcolor,
  width,
  height,
}) => (
  <>
    <svg width={width} height={height} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M41.2727 34.3636C40.3193 34.3636 39.5455 33.5889 39.5455 32.6323V23.8353C39.5455 15.3595 32.5716 8.46269 24 8.46269C15.4284 8.46269 8.45455 15.3595 8.45455 23.8353V32.6323C8.45455 33.5889 7.68073 34.3636 6.72727 34.3636C5.77382 34.3636 5 33.5889 5 32.6323V23.8353C5 13.449 13.5232 5 24 5C34.4768 5 43 13.449 43 23.8353V32.6323C43 33.5889 42.2262 34.3636 41.2727 34.3636Z" fill={fillcolor} />
      <path fillRule="evenodd" clipRule="evenodd" d="M13.6364 25.9466C10.6603 26.7144 8.45455 29.4228 8.45455 32.6364C8.45455 35.85 10.6603 38.5592 13.6364 39.3261V25.9466ZM15.3636 43C9.64895 43 5 38.3511 5 32.6364C5 26.9217 9.64895 22.2727 15.3636 22.2727C16.3171 22.2727 17.0909 23.0457 17.0909 24V41.2727C17.0909 42.2279 16.3171 43 15.3636 43Z" fill={fillcolor} />
      <path fillRule="evenodd" clipRule="evenodd" d="M34.3637 25.9466V39.3261C37.3398 38.5592 39.5455 35.85 39.5455 32.6364C39.5455 29.4228 37.3398 26.7144 34.3637 25.9466ZM32.6365 43C31.683 43 30.9092 42.2279 30.9092 41.2727V24C30.9092 23.0457 31.683 22.2727 32.6365 22.2727C38.3511 22.2727 43.0001 26.9217 43.0001 32.6364C43.0001 38.3511 38.3511 43 32.6365 43Z" fill={fillcolor} />
    </svg>
  </>
);
IcAudio.propTypes = {
  fillcolor: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

IcAudio.defaultProps = {
  fillcolor: ICON_COLOR_DEFAULT,
  width: '48',
  height: '48',
};
export default IcAudio;
