/* eslint-disable import/extensions */
import en from './lang/en.js';
import br from './lang/br.js';
import cn from './lang/cn.js';
import cz from './lang/cz.js';
import de from './lang/de.js';
import es from './lang/es.js';
import fr from './lang/fr.js';
import hu from './lang/hu.js';
import it from './lang/it.js';
import jp from './lang/jp.js';
import ko from './lang/ko.js';
import nl from './lang/nl.js';
import pl from './lang/pl.js';
import pt from './lang/pt.js';
import ru from './lang/ru.js';
import tr from './lang/tr.js';
import tw from './lang/tw.js';

const languageObject = {
  br, cn, cz, de, en, es, fr, hu, it, jp, ko, nl, pl, pt, ru, tr, tw,
};
export default languageObject;
