import React from 'react';
import PropTypes from 'prop-types';
import { BLACK_BORDER_COLOR } from 'constants/index';

const IcPrevAudio = ({
  fillcolor,
  width,
  height,
}) => (
  <svg width={width} height={height} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M32.4589 36C32.9667 36 33.4746 35.9028 33.9504 35.7008C35.1994 35.1713 36 34.0285 36 32.7721V14.2242C36 12.9693 35.1994 11.825 33.9504 11.2955C32.7014 10.7749 31.2252 10.9499 30.1729 11.7592L18.1374 21.0331C17.3414 21.6464 16.8824 22.5483 16.8824 23.4997C16.8824 24.4495 17.3414 25.3514 18.1374 25.9632L30.1729 35.2371C30.8256 35.7397 31.6384 36 32.4589 36Z" fill={fillcolor} />
    <rect width="4" height="25" rx="2" transform="matrix(-1 0 0 1 17 11)" fill={fillcolor} />
  </svg>

);
IcPrevAudio.propTypes = {
  fillcolor: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

IcPrevAudio.defaultProps = {
  fillcolor: BLACK_BORDER_COLOR,
  width: '24',
  height: '24',
};
export default IcPrevAudio;
