import PropTypes from 'prop-types';

export default function IconBgRemoval(props) {
  const {
    color, width, height, className,
  } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={className} width={width} height={height}>
      <g id="editor-icon/background-removal" fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g id="cutout" fill={color} fillRule="nonzero">
          <path id="path68235" d="M16 12.755c-.504 0-.985.113-1.43.31l-2.376-2.68 4.368-4.927c.79-.892 1.43-2.65 1.43-3.926v-.327c0-1.276-.64-1.588-1.43-.697L10 7.91 3.438.508c-.79-.891-1.431-.58-1.431.697v.327c0 1.276.64 3.034 1.43 3.925l4.368 4.927-2.376 2.68A3.521 3.521 0 004 12.756C1.792 12.756 0 14.824 0 17.378 0 19.931 1.79 22 4 22c2.209 0 4-2.069 4-4.622 0-.668-.125-1.3-.345-1.873L10 12.859l2.345 2.646A5.224 5.224 0 0012 17.378C12 19.931 13.79 22 16 22c2.209 0 4-2.069 4-4.622 0-2.554-1.791-4.623-4-4.623zM4 19.688c-1.105 0-2-1.033-2-2.31 0-1.277.895-2.311 2-2.311 1.104 0 2 1.034 2 2.311s-.896 2.31-2 2.31zm12 0c-1.105 0-2-1.033-2-2.31 0-1.277.895-2.311 2-2.311s2 1.034 2 2.311-.896 2.31-2 2.31z" />
        </g>
      </g>
    </svg>
  );
}

IconBgRemoval.propTypes = {
  color: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  className: PropTypes.string,
};

IconBgRemoval.defaultProps = {
  color: '#ffffff',
  width: '20',
  height: '22',
  className: '',
};
