import {
  Tooltip, Dropdown, Menu, Skeleton,
} from 'antd';
import React, {
  useState, useContext, useEffect,
} from 'react';
import { getTransText } from 'common/translate';
import NotificationList from 'components/organisms/Sider/RightSider/NotificationList/NotificationList';
import MainContext from 'stores/context/MainContexts';
import IconNoti from 'components/icons/IconNoti';
import IcNewNoti from 'components/icons/IcNewNoti';

const UserInformationMenu = () => {
  // Menu Notification
  const [visibleNotifi, setVisibleNotifi] = useState(false);
  const { initDataUserProvider, actionsUser } = useContext(MainContext);
  const loading = initDataUserProvider?.loadingNoti;

  const showDrawerNotifi = () => {
    if (!visibleNotifi) {
      actionsUser.getNotiList(10);
    }
    setVisibleNotifi(!visibleNotifi);
  };
  const notifcation = initDataUserProvider?.notification;
  const listStatusNoti = [];
  Object.keys(notifcation).forEach((key) => {
    listStatusNoti.push(notifcation[key]?.isNew);
  });
  const [isNewNoti, setIsNewNoti] = useState(false);
  const newNotiMessage = (isNew) => {
    setIsNewNoti(isNew);
  };
  useEffect(() => {
    if (listStatusNoti.includes(1)) {
      setIsNewNoti(true);
    }
  }, [notifcation]);
  const noti = (
    <>
      <Menu className="leng-menu-information">
        {loading ? (
          <div className="skelaton-noti">
            <Skeleton />
          </div>
        ) : (
          <>
            <NotificationList
              notifications={notifcation}
              isNewNoti={isNewNoti}
              newNotiMessage={newNotiMessage}
            />
          </>
        )}
      </Menu>
    </>
  );
  return (
    <div className="ul-right ul-header-forgot">
      <div className="li-header-dropdown">
        <Dropdown
          visible={visibleNotifi}
          onVisibleChange={(isVisible) => setVisibleNotifi(isVisible)}
          overlay={noti}
          trigger={['click']}
          placement="bottomCenter"
          arrow
        >
          <Tooltip onClick={showDrawerNotifi} overlayClassName="lang-tooltip" title={getTransText('notification')} placement="bottomRight">
            <div className="btn-menu">
              {isNewNoti ? <IcNewNoti />
                : <IconNoti />}
            </div>
          </Tooltip>
        </Dropdown>
      </div>
    </div>
  );
};
export default UserInformationMenu;
