import PropTypes from 'prop-types';

const IcArrowUp = ({ fillcolor }) => (
  <svg width="15" height="15" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M10.9125 4.83748C10.6847 4.60968 10.3155 4.60968 10.0877 4.83748L7.00005 7.92516L3.91243 4.83748C3.68464 4.60968 3.31539 4.60968 3.08759 4.83748C2.8598 5.06527 2.8598 5.43454 3.08759 5.66233L6.58763 9.16244C6.81542 9.39024 7.18467 9.39024 7.41247 9.16244L10.9125 5.66233C11.0265 5.54829 11.0834 5.39924 11.0834 5.24991C11.0834 5.10057 11.0265 4.95152 10.9125 4.83748Z" fill={fillcolor} />
  </svg>
);
IcArrowUp.propTypes = {
  fillcolor: PropTypes.string,
};

IcArrowUp.defaultProps = {
  fillcolor: '#212121',
};
export default IcArrowUp;
