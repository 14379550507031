/* eslint-disable consistent-return */
import * as types from './constants';

export const initDataUser = {
  loadingCreditSummary: false,
  credit: [],
  loadingNoti: false,
  notification: [],
  view: '',
  agreeStatus: 1,
  likeboxData: [],
  likeboxDataAll: [],
  loadingLikebox: false,
  getAllCollection: [],
  loadingGetCollection: false,
  loadingDelete: false,
  detailCollection: [],
  dashboardOptions: 'downloadHistory',
  userDetails: [],
  dataUserFilterDlw: [],
  dataAddedDefaultCollection: {},
  imageIdCurrent: null,
  dataSettingGeneral: {},
  dataSettingDLImage: {},
  dataSettingDLFootage: {},
  dataSettingDLAudio: {},
  accessUserDashboard: null,
};
export const reducerUser = (state = initDataUser, action) => {
  switch (action.type) {
    case types.SET_VIEW:
      return {
        ...state,
        loading: true,
        view: action.payload,
      };
    case types.SET_LOADING_NOTI:
      return {
        ...state,
        loadingNoti: true,
      };
    case types.SET_CREDIT_SUMMARY_LOADING:
      return {
        ...state,
        loadingCreditSummary: true,
      };
    case types.SET_CREDIT_SUMMARY:
      return {
        ...state,
        loadingCreditSummary: false,
        credit: action.payload,
      };
    case types.SET_NOTI_LIST:
      return {
        ...state,
        loadingNoti: false,
        notification: action.payload,
      };
    case types.SET_ACTION:
      return {
        ...state,
      };
    case types.SET_AGREE_STATUS:
      return {
        ...state,
        agreeStatus: action.payload,
      };
    case types.GET_ALL_LIKE_BOX:
      return {
        ...state,
        likeboxDataAll: action.payload,
      };
    case types.LIKE_BOX_COLLECTION:
      return {
        ...state,
        likeboxCollected: action.payload,
      };
    case types.GET_ALL_MY_COLLECTION:
      return {
        ...state,
        loadingGetCollection: true,
        getAllCollection: [...state.getAllCollection, ...action.payload.data],
        totalPage: action.payload,
      };
    case types.SET_ALL_MY_COLLECTION:
      return {
        ...state,
        getAllCollection: [],
      };
    case types.SET_LOADING_MY_COLLECTION:
      return {
        ...state,
        loadingGetCollection: false,
      };
    case types.SET_LOADING_DELETE:
      return {
        ...state,
        loadingDelete: false,
      };
    case types.GET_DETAIL_COLLECTION:
      return {
        ...state,
        detailCollection: [...state.detailCollection, ...action.payload],
      };
    case types.SET_TYPE_TAB_PORTFOLIO:
      return {
        ...state,
        tabPortfolio: action.payload.data,
      };
    case types.SET_DASHBOARD_OPTION:
      return {
        ...state,
        dashboardOptions: action.payload.data,
      };
    case types.SET_USER_DETAILS:
    case types.GET_USER_DETAILS:
      return {
        ...state,
        userDetails: action.payload.data,
      };
    case types.SET_DOWLOAD_HISTORY_FILTER_USER:
      return {
        ...state,
        dataUserFilterDlw: action.payload.data,
      };
    case types.GET_LIST_DATA_ADDED_DEFAULT_COLLECTION:
      return {
        ...state,
        dataAddedDefaultCollection: action.payload.data,
      };
    case types.SET_IMAGE_ID:
      return {
        ...state,
        imageIdCurrent: action.payload.data,
      };
    case types.SET_DATA_GENERAL_SETTING:
      return {
        ...state,
        dataSettingGeneral: action.payload.data,
      };
    case types.SET_DATA_DL_IMAGE_SETTING:
      return {
        ...state,
        dataSettingDLImage: action.payload.data,
      };
    case types.SET_DATA_DL_FOOTAGE_SETTING:
      return {
        ...state,
        dataSettingDLFootage: action.payload.data,
      };
    case types.SET_DATA_DL_AUDIO_SETTING:
      return {
        ...state,
        dataSettingDLAudio: action.payload.data,
      };
    case types.SET_ACCESS_USER_DASHBOARD:
      return {
        ...state,
        accessUserDashboard: action.payload.data,
      };
    default:
      break;
  }
};
