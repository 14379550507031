import PropTypes from 'prop-types';

const IcArrowLeft = ({
  fillcolor,
  width,
  height,
}) => (
  <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M13.0893 15.5892C13.4147 15.2637 13.4147 14.7362 13.0893 14.4108L8.67829 9.99995L13.0893 5.58908C13.4147 5.26366 13.4147 4.73615 13.0893 4.41073C12.7638 4.08531 12.2363 4.08531 11.9109 4.41073L6.91074 9.41078C6.58531 9.7362 6.58531 10.2637 6.91074 10.5891L11.9109 15.5892C12.0738 15.7521 12.2867 15.8333 12.5001 15.8333C12.7134 15.8333 12.9263 15.7521 13.0893 15.5892Z" fill={fillcolor} />
  </svg>
);
IcArrowLeft.propTypes = {
  fillcolor: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

IcArrowLeft.defaultProps = {
  fillcolor: '#212121',
  width: '20',
  height: '20',
};
export default IcArrowLeft;
