import PropTypes from 'prop-types';
import { ICON_COLOR_DEFAULT } from 'constants/index';

const IcVector = ({
  fillcolor,
  width,
  height,
}) => (
  <>
    <svg width={width} height={height} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M30.9092 37.8182L34.3637 32.6364L24.0001 15.3636L13.6365 32.6364L17.091 37.8182H30.9092Z" fill={fillcolor} />
      <path fillRule="evenodd" clipRule="evenodd" d="M26.591 30.0455C26.591 31.4765 25.4311 32.6364 24.0001 32.6364C22.569 32.6364 21.4092 31.4765 21.4092 30.0455C21.4092 28.6144 22.569 27.4546 24.0001 27.4546C25.4311 27.4546 26.591 28.6144 26.591 30.0455Z" fill="white" />
      <path fillRule="evenodd" clipRule="evenodd" d="M17.0908 43H30.909V39.5455H17.0908V43Z" fill={fillcolor} />
      <path fillRule="evenodd" clipRule="evenodd" d="M39.0377 15.0145C38.6153 15.0145 38.1922 14.8607 37.8588 14.549C37.8165 14.5118 32.9896 10.1816 24 10.1816C14.9197 10.1816 10.1887 14.5049 10.1412 14.549C9.44688 15.2019 8.35265 15.1647 7.70061 14.4661C7.04856 13.77 7.0857 12.6766 7.78352 12.0254C8.01411 11.8095 13.5716 6.72702 24 6.72702C34.4276 6.72702 39.9859 11.8095 40.2165 12.0254C40.9143 12.6766 40.9515 13.77 40.2994 14.4661C39.9592 14.8305 39.4988 15.0145 39.0377 15.0145Z" fill={fillcolor} />
      <path fillRule="evenodd" clipRule="evenodd" d="M27.4545 8.45455C27.4545 10.3623 25.9077 11.9091 24 11.9091C22.0922 11.9091 20.5454 10.3623 20.5454 8.45455C20.5454 6.54677 22.0922 5 24 5C25.9077 5 27.4545 6.54677 27.4545 8.45455Z" fill={fillcolor} />
      <path fillRule="evenodd" clipRule="evenodd" d="M42.9999 13.6364C42.9999 15.5441 41.4531 17.0909 39.5454 17.0909C37.6376 17.0909 36.0908 15.5441 36.0908 13.6364C36.0908 11.7286 37.6376 10.1818 39.5454 10.1818C41.4531 10.1818 42.9999 11.7286 42.9999 13.6364Z" fill={fillcolor} />
      <path fillRule="evenodd" clipRule="evenodd" d="M11.9091 13.6364C11.9091 15.5441 10.3623 17.0909 8.45455 17.0909C6.54677 17.0909 5 15.5441 5 13.6364C5 11.7286 6.54677 10.1818 8.45455 10.1818C10.3623 10.1818 11.9091 11.7286 11.9091 13.6364Z" fill={fillcolor} />
    </svg>
  </>
);
IcVector.propTypes = {
  fillcolor: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

IcVector.defaultProps = {
  fillcolor: ICON_COLOR_DEFAULT,
  width: '48',
  height: '48',
};
export default IcVector;
