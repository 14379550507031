import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
const SAMPLE_RATE = process.env.NEXT_PUBLIC_SENTRY_TRACE_SAMPLE_RATE
  ? parseFloat(process.env.NEXT_PUBLIC_SENTRY_TRACE_SAMPLE_RATE)
  : 0.01;

Sentry.init({
  dsn: SENTRY_DSN,
  tracesSampleRate: SAMPLE_RATE,
  environment: process.env.APP_ENV,
  debug: process.env.NEXT_PUBLIC_SENTRY_DEBUG,
});
