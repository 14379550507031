import PropTypes from 'prop-types';
import { ICON_COLOR_DEFAULT } from 'constants/index';

const IcLicenseOnBehalf = ({
  fillcolor,
  width,
  height,
}) => (
  <svg width={width} height={height} viewBox="0 0 24 24.04" fill={fillcolor} xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_4787_39323)">
      <path d="M23 3.68799C23.55 3.68799 24 4.13799 24 4.68799V9.73799C24 10.238 23.65 10.638 23.2 10.738C22.5 10.838 22 11.488 22 12.188C22 12.888 22.5 13.538 23.2 13.638C23.65 13.738 24 14.138 24 14.638V19.688C24 20.238 23.55 20.688 23 20.688H1C0.45 20.688 0 20.238 0 19.688V14.638C0 14.138 0.35 13.738 0.8 13.638C1.5 13.538 2 12.888 2 12.188C2 11.488 1.5 10.838 0.8 10.738C0.35 10.638 0 10.238 0 9.73799V4.68799C0 4.13799 0.45 3.68799 1 3.68799H23ZM22 9.03799V5.68799H2V9.03799C3.2 9.58799 4 10.838 4 12.188C4 13.538 3.2 14.788 2 15.338V18.688H22V15.338C20.8 14.788 20 13.538 20 12.188C20 10.838 20.8 9.58799 22 9.03799Z" fill={fillcolor} />
      <path d="M17.25 13.188C17.8 13.188 18.25 12.738 18.25 12.188C18.25 11.638 17.8 11.188 17.25 11.188C16.7 11.188 16.25 11.638 16.25 12.188C16.25 12.738 16.7 13.188 17.25 13.188ZM13.75 13.188C14.3 13.188 14.75 12.738 14.75 12.188C14.75 11.638 14.3 11.188 13.75 11.188C13.2 11.188 12.75 11.638 12.75 12.188C12.75 12.738 13.2 13.188 13.75 13.188ZM10.25 13.188C10.8 13.188 11.25 12.738 11.25 12.188C11.25 11.638 10.8 11.188 10.25 11.188C9.7 11.188 9.25 11.638 9.25 12.188C9.25 12.738 9.7 13.188 10.25 13.188ZM6.75 13.188C7.3 13.188 7.75 12.738 7.75 12.188C7.75 11.638 7.3 11.188 6.75 11.188C6.2 11.188 5.75 11.638 5.75 12.188C5.75 12.738 6.2 13.188 6.75 13.188Z" fill={fillcolor} />
    </g>
    <defs>
      <clipPath id="clip0_4787_39323">
        <rect width="24" height="24" fill="white" transform="translate(0 0.187988)" />
      </clipPath>
    </defs>
  </svg>

);

IcLicenseOnBehalf.propTypes = {
  fillcolor: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

IcLicenseOnBehalf.defaultProps = {
  fillcolor: ICON_COLOR_DEFAULT,
  width: '24',
  height: '24.04',
};
export default IcLicenseOnBehalf;
